import React, { useEffect, useState, useContext } from "react";
import { useSnackbar } from "notistack";
import CircularProgress from '@material-ui/core/CircularProgress';
import TarjetaEcampus from "../componentes/TarjetaEcampus";
import api from "../lib/api";
import utils from "../util/utils";
import { BotonContext } from "../BotonContext";
import { useSession } from "../util/session";
import { useRouteHelper } from "../util/routeHelper";

const MENSAJE_NO_PLANS = "¡ No tienes productos para administrar !";

export default function MyPlans() {

    const { data_user, token } = useContext(BotonContext);
    const { isLogged } = useSession();
    const { gotoLogin } = useRouteHelper();
    const { enqueueSnackbar } = useSnackbar();
    const [planes, setPlanes] = useState(/** @type {api.Plan[]} */(null));
    const logged = isLogged();

    /**
     * @returns {Promise<api.Plan[]>}
     */
    const getMisPlanes = async function () {
        try {
            const respuesta = await api.getMisPlanes({
                id_usuario: data_user.id_usuario
            });
            if (!respuesta.success) throw new Error(respuesta.message || respuesta.mensaje);
            return respuesta.data;
        } catch (error) {
            console.error("Error: " + error);
            enqueueSnackbar("Error en la descarga de planes", {
                variant: 'error'
            });
        }
    };

    useEffect(function () {
        if (!logged) {
            gotoLogin();
            return;
        }
        api.pagos.validarPagos({
            token: token,
            userId: data_user.id_usuario
        })
            .catch(() => {
                // fallar en silencio
            })
            .finally(() => {
                getMisPlanes().then((planesApi) => {
                    setPlanes(planesApi);
                });
            });
    }, []);

    return (<>
        <div style={{ padding: '0px 2rem', marginTop: '90px' }}>
            <h2 className="title_section s24 fontb">Mis Administraciones</h2>
            <div style={{ display: 'flex', justifyContent: 'center', minHeight: '30rem', marginTop: '1.5rem', marginBottom: '3rem', alignItems: 'center', gap: '2rem' }}>
                {!!planes?.length && planes.map(p => <>
                    <TarjetaEcampus
                        image={utils.getImagenCurso(p)}
                        text1={p.nombre}
                        text2={p.descripcion}
                        plan={p}
                        href={`/manage-plan/${p.id}/${p.nombre}`}
                    />
                </>)}
                {logged && planes === null &&
                    <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '1rem' }}>
                        <CircularProgress className="CircularProgress" style={{ alignSelf: 'center' }} />
                    </div>
                }
                {planes !== null && !planes?.length &&
                    <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '1rem' }}>
                        <div style={{ display: 'flex', justifyContent: 'center' }} className="title_section s30 fontb c333">{MENSAJE_NO_PLANS}</div>
                    </div>
                }
            </div>
        </div>
    </>);
}
