import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useSnackbar } from "notistack";

import PanelCursos from "./PanelCursos";
import routeHelper from "../util/routeHelper";
import i18n from "../lib/i18n";
import api from "../lib/api";

const scroll2Top = function () {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
};

/**
 * @param {Object} params
 * @param {number} params.categoryId
 * @param {(curso: api.Curso) => void} params.handleShowVideo
 * @param {boolean} [params.requestCursos=true] - default = true
 * @param {api.Curso[]} [params.cursosExternos] - para pasar los cursos cuando se configura `requestCursos` = false. Hay que pasarlos al inicio, no sirve pararlos luego de inicializado el estado.
 * @returns {JSX.Element}
 */
export default function PanelCursosCategory({ categoryId, handleShowVideo, requestCursos, cursosExternos }) {

    if (!(cursosExternos instanceof Array)) {
        cursosExternos = [];
    }

    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();

    const [isLoading, setIsLoading] = useState(false);
    const [cursos, setCursos] = useState(/** @type {api.Curso[]} */(cursosExternos));

    const getCursos = async function () {
        setIsLoading(true);
        try {
            const response = await api.getCursosCategory({
                categoryId,
                nPag: 1,
                id_pais: i18n.getCountry().id_pais
            });
            if (response.success) {
                setCursos(response.data.cursos);
            } else {
                if (response.success === false) {
                    // FIX(api): cuando no hay datos no trae "success". Debería ser success = true.
                    console.log("Error: ", response.message || response.mensaje );
                    enqueueSnackbar("Error en la descarga de cursos", {
                        variant: 'error'
                    });
                }
            }
        } finally {
            setIsLoading(false);
        }
    };

    const handleShowMore = function () {
        history.push(routeHelper.rutaCursosCategory(categoryId));
        scroll2Top();
    };

    useEffect(() => {
        requestCursos && getCursos();
    }, []);

    return (<>

        {isLoading &&
            <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '1rem' }}>
                <CircularProgress className="CircularProgress" style={{ alignSelf: 'center' }} />
            </div>
        }
        {!isLoading && <>
            <PanelCursos cursos={cursos} handleShowVideo={handleShowVideo} />
            <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '1rem' }}>
                <button
                    style={{ display: 'flex', alignItems: 'center', paddingRight: '0.6rem' }}
                    onClick={() => {
                        handleShowMore();
                    }}
                >
                    <KeyboardArrowDownIcon />Ver más
                </button>
            </div>
        </>}
    </>);
}
