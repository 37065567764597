import { useContext } from "react";
import _ from 'lodash';
import { BotonContext } from "../BotonContext";
import { theme as tema, saveTheme } from "../util/theme";
import { TIPO_USUARIO } from "../lib/constants";

const SESSION_STORAGE_USER_DATA_KEY = "user_data";
const SESSION_STORAGE_TOKEN_KEY = "token";
const LOCAL_STORAGE_CART_KEY = "cart";

export function clearCart() {
    localStorage.removeItem(LOCAL_STORAGE_CART_KEY);
}

export function clearSession() {
    sessionStorage.clear();
}

/**
 * @param {Object} [obj]
 * @param {boolean} [obj.reload] - default = false
 * @param {string} [obj.redirTo]
 */
export const goToLogin = function ({ reload, redirTo } = {}) {
    let hash = '#/login';
    if (/\?redir=/.test(redirTo)) {
        const match = /\?redir=(.+)/.exec(redirTo);
        redirTo = match ? match[1] : '';
    }
    if (redirTo) {
        hash += '?redir=' + (redirTo.startsWith('#') ? redirTo.slice(1) : redirTo);
    }
    window.location.assign(hash);
    reload && window.location.reload();
};

/**
 * @param {Object} [obj]
 * @param {boolean} [obj.reload] - default = false
 */
export const goToLoginAndRedirectBack = function ({ reload } = {}) {
    goToLogin({ reload, redirTo: location.hash });
};

/**
 * @param {Object} obj
 * @param {string} [obj.token]
 * @param {api.UserData} [obj.data_user]
 */
export const saveSessionData = function ({ token, data_user }) {
    console.assert(typeof token === 'undefined' || !!token, "empty token");
    data_user && sessionStorage.setItem(SESSION_STORAGE_USER_DATA_KEY, JSON.stringify(data_user));
    token && sessionStorage.setItem(SESSION_STORAGE_TOKEN_KEY, token);
};

export const useSession = function () {
    const { data_user, token, setToken, setDataUser } = useContext(BotonContext);

    /**
     * @returns {boolean}
     */
    const isLogged = function () {
        return !!token && token !== 'null' && data_user && typeof data_user === 'object';
    };

    /**
     * @returns {boolean}
     */
    const ownsAtLeastOnePlan = function () {
        return data_user?.menu?.id === 1;
    };

    const invalidate = function () {
        setToken('');
        setDataUser(null);
        sessionStorage.removeItem(SESSION_STORAGE_TOKEN_KEY);
        sessionStorage.removeItem(SESSION_STORAGE_USER_DATA_KEY);
    };

    const logout = function () {
        invalidate();
        const actualTheme = tema.current;
        sessionStorage.clear();
        localStorage.clear();
        saveTheme(actualTheme);
        goToLogin({ reload: true });
    };

    const handleNotAuthorized = function () {
        invalidate();
        goToLoginAndRedirectBack();
    };

    /**
     * Para ser usado luego de completar exitosamente el registro como tutor, sin necesidad de solicitar completamente la data de usuario.
     * Usando la estructura actual, y la respuesta del registro.
     * La función modificará directamente la estructura del parámetro `dataUser`.
     * @param {Object} obj
     * @param {api.RespuestaRegistroInstructor} obj.dataUserInstructor
     */
    const enableTutorFlag = function({ dataUserInstructor }) {
        data_user.tipo_usuario = TIPO_USUARIO.AUTHOR;
        const commonKeys = /** @type {Array<keyof api.UserData & keyof api.RespuestaRegistroInstructor>} */(_.intersection(Object.keys(data_user), Object.keys(dataUserInstructor)));
        commonKeys.forEach(key => {
            /** @type {Record<key, typeof dataUserInstructor[key]>} */(data_user)[key] = dataUserInstructor[key];
        });
        saveSessionData({ data_user });
        setDataUser({ ...data_user });
    };

    /**
     * @param {Object} obj
     * @param {string} obj.token
     * @param {api.UserData} obj.data_user
     * @param {boolean} [obj.save] - default = true
     */
    const setData = function ({ token: newToken, data_user: newDataUser, save = true }) {
        console.assert(typeof newToken === 'undefined' || !!newToken, "empty token");
        setDataUser(newDataUser);
        setToken(newToken);
        save && saveSessionData({ token: newToken, data_user: newDataUser });
    };

    return {
        enableTutorFlag,
        handleNotAuthorized,
        invalidate,
        isLogged,
        logout,
        ownsAtLeastOnePlan,
        setData
    };
};

const Session = { clearSession, clearCart, useSession };

export default Session;
