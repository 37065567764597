import React, { useState, useEffect, useContext } from "react";
//ICONOS, BOTONES Y ESTILOS
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';
import CircularProgress from '@material-ui/core/CircularProgress';

//Modal
import { makeStyles, withStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';

import { useHistory } from "react-router";
//REACT CONTEXT
import { BotonContext } from "../BotonContext";
import AuthContext from "../AuthContext";

//Breadcrumbs
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';

//AXIOS
import axios from 'axios';

import TextField from '@material-ui/core/TextField';
import Avatar from '@material-ui/core/Avatar';
import CardHeader from '@material-ui/core/CardHeader';
import Box from '@material-ui/core/Box';
import { Hidden } from "@material-ui/core";

//EDITOR DE TEXTO
import ReactQuill from "react-quill";
import EditorToolbar, { modules, formats } from "../componentes/EditorToolbar";
import "react-quill/dist/quill.snow.css";

import { useSnackbar } from 'notistack';
import { ListaAcordeonesUnidad } from "../componentes/AccordionUnidad";
import ImagePreview from "../componentes/ImagePreview";
import { CourseRatingEditor } from "../componentes/CourseRating";
import i18n from "../lib/i18n";
import api from "../lib/api";
import utils from "../util/utils";

const useStyles3 = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > * + *': {
      marginLeft: theme.spacing(2)
    }
  }
}));

function OwnCoursePage(props) {

  const courseID = props.match.params.courseid;
  const section = props.match.params.section;
  const certifiedID = props.match.params.certifiedid;
  const { enqueueSnackbar } = useSnackbar();

  const history = useHistory();
  const data_user = JSON.parse(sessionStorage.getItem("user_data"));
  const token = sessionStorage.getItem("token");

  const userID = data_user.id_usuario;
  const [course, setCourse] = useState(/** @type {{ [id_unidad: string]: api.MyUnidad }} */({}));

  const [openSnackbarWarningDatos, setOpenSnackbarWarningDatos] = React.useState(false);
  const [openSnackbarError, setOpenSnackbarError] = React.useState(false);
  const { isAuth } = useContext(AuthContext);

  //const [openSnackbarError, setOpenSnackbarError] = React.useState(false);
  //const [mensajeerror, setMensajeError] = useState("");
  const [openSnackbarSuccessMens, setOpenSnackbarSuccessMens] = React.useState(false);
  const [mensajesuccess, setMensajeSuccess] = useState("");

  const [valueRating, setValueRating] = React.useState(0);
  const [actualizarValoracion, setActualizarValoracion] = useState([]);

  var [disableLoadingDetalles, setDisableLoadingDetalles] = React.useState(false);

  const classes3 = useStyles3();

  const handleCloseSnackbarWarningDatos = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackbarWarningDatos(false);
  };

  const handleCloseSnackbarError = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackbarError(false);
  };

  const handleCloseSnackbarSuccessMens = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbarSuccessMens(false);
  };

  {/*Obtener valoración del curso*/ }

  useEffect(() => {

    fetch(process.env.REACT_APP_URL_API + '/valoracion?id_curso=' + courseID + '&id_usuario=' + data_user.id_usuario, {
      method: 'get',
      headers: {
        'token': token,
        'Content-Type': 'application/json'
      }
    })
      .then(response => response.json()
        .then(data => {

          if (data.success === true) {

            setValueRating(data.data.param_valoracion);

          }
          if (data.success === false && data.data === null) {
            //setMens(true);
            sessionStorage.setItem("token", null);
            sessionStorage.setItem("user_data", null);
          }
          if (data.success === false) {
            //setMens(true);
            //setMensajeError(data.mensaje);
            //setOpenSnackbarError(true);
          }

        })
      )
  }, [actualizarValoracion]);

  {/*Fin Obtener valoración del curso*/ }

  function handleSendRating(newValue) {

    let SendRating = {
      "id_curso": courseID,
      "id_usuario": data_user.id_usuario,
      "valoracion": newValue
    };

    axios.post(process.env.REACT_APP_URL_API + '/valoracion', SendRating, {
      headers: {
        'Content-Type': 'application/json',
        'token': token
      }
    })
      .then(response => {
        if (response.data.success === true) {
          setMensajeSuccess(response.data.mensaje);
          setOpenSnackbarSuccessMens(true);
          setValueRating(newValue);
          setActualizarValoracion(newValue);
        }
        if (response.data.success === false) {
          handleUpdateRating(newValue);
        }
      }).catch(e => {
        console.log(e);
      });

  }


  {/*Modificar Valoracion*/ }

  const handleUpdateRating = (newValue) => {

    let putUpdateRating = {};
    putUpdateRating = {
      "id_curso": courseID,
      "id_usuario": data_user.id_usuario,
      "valoracion": newValue
    };

    fetch(process.env.REACT_APP_URL_API + '/valoracion', {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'token': token
      },
      body: JSON.stringify(putUpdateRating)
    })
      .then(data => data.json()
        .then(data => {
          if (data.success === true) {
            setMensajeSuccess("Su valoración ha sido actualizada");
            setOpenSnackbarSuccessMens(true);
            setValueRating(newValue);
            setActualizarValoracion(newValue);
          }
          if (data.success === false && data.data === null) {
            sessionStorage.setItem("token", null);
            sessionStorage.setItem("user_data", null);
          }
          if (data.success === false) {
            //setMensajeError(data.mensaje);
            setOpenSnackbarError(true);
          }
        })

      );

  };

  const ValidarAuth = () => {
    const data_user = JSON.parse(sessionStorage.getItem("user_data"));
    const token = sessionStorage.getItem("token");
    if (isAuth && (token === null || token === 'null' || token === "" || token === undefined
      || data_user === null || data_user === 'null' || data_user === "" || data_user === undefined)) {
      //setOpenmensnoauth(true);
      localStorage.removeItem("cart");
      sessionStorage.clear();
      window.location.href = "#/login";
      window.location.reload();
    }
  };

  ValidarAuth();

  useEffect(() => {
    api.getMisClases(token, userID, courseID)
      .then(data => {
        if (data.success) {
          if (data.data?.n_clases > 0) {
            setCourse(data.data.unidades);
          } else {
            setCourse({});
            enqueueSnackbar('No se encontraron clases para este curso.', {
              variant: 'info'
            });
          }
        } else {
          enqueueSnackbar('Error al descargar información de clases', {
            variant: 'error'
          });
        }
        setDisableLoadingDetalles(true);
      });
  }, []);

  /** @type {JSX.Element} */
  let acordeonesUnidades;
  if (course && Object.keys(course).length) {
    const unidades = Object.values(course);
    acordeonesUnidades = <ListaAcordeonesUnidad
      publicAccordion={false}
      unidades={unidades}
      certifiedId={certifiedID}
      courseId={courseID}
      section={section}
      disabledCheckboxes={true}
    />;
  }

  useEffect(() => {
    document.title = "Curso";
  });


  const [openload, setOpenLoad] = React.useState(true);

  const handleCloseLoad = () => {
    setOpenLoad(false);
  };

  const [curso, setCurso] = useState(/** @type {api.Curso} */({}));

  useEffect(() => {
    api.getCurso({
      courseId: courseID,
      id_pais: i18n.getCountry().id_pais,
      token
    })
      .then(data => {
        if (data.success) {
          setCurso(data.data);
        } else {
          history.push("/login");
        }
        setOpenLoad(false);
      });
  }, []);

  /********** Agregado para funcion del boton agregar al carrito   ***********/

  const { cart, setCart } = useContext(BotonContext);

  const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')));
  const dbUrl = (user !== null) ? `#/add-cart/${user.localId}.json` : '';

  let newCart = (cart !== null) ? cart.slice(0,) : [];
  let carting;

  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const storage = localStorage.getItem('cart');
    if (user !== null) {
      fetch(dbUrl, { method: 'GET' }).then(resp => resp.json()).then(resp => {
        if (resp !== null) {
          setCart(resp);
          localStorage.removeItem('cart');
          localStorage.setItem('cart', JSON.stringify(resp));
        } else {
          fetch(dbUrl, { method: 'PUT', body: storage }).then(data => data.json()).then(data => {
            setCart((storage !== null) ? data : []);
          });
        }
      });
    } else {
      setCart((storage !== null) ? JSON.parse(storage) : []);
    }
  }, []);

  //Boton de carrito que suma cantidad total - Form abajo
  const [pathname, setPathname] = useState(window.location.pathname.slice(18,));

  const [cartQuantity, setCartQuantity] = useState();

  useEffect(() => {
    const inputVal = pathname.split('-').join(' ');
    let quantity = 0;
    carting = newCart;
    if (carting !== null && carting.length > 0) {
      carting.map(course => quantity += course.quantity);
    }
    setCartQuantity(quantity);
    if (window.location.pathname.includes('search')) {
      document.getElementsByTagName('input').item(0).setAttribute('value', inputVal);
      document.getElementsByTagName('input').item(1).setAttribute('value', inputVal);
    }
  });
  //FIN Boton de carrito que suma cantidad total - Form abajo

  /********** FIN Agregado para funcion del boton agregar al carrito   ***********/

  const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2)
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500]
    }
  });

  const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });

  /**Comentarios del curso**/

  const [textValue, setTextValue] = useState("");

  const [coursetitle, setCoursetitle] = useState("");
  const [errorCoursetitle, setErrorCoursetitle] = useState(false);
  const [mensCoursetitle, setMensCoursetitle] = useState("");

  const [errorTextValue, setErrorTextValue] = useState(false);
  const [mensTextValue, setMensTextValue] = useState("");

  const [openSnackbarSuccess, setOpenSnackbarSuccess] = React.useState(false);
  const [openSnackbarWarning, setOpenSnackbarWarning] = React.useState(false);
  const [mensopenSnackbarWarning, setMensOpenSnackbarWarning] = useState("Error al intentar guardar.");

  const [botonstatus, setBotonstatus] = useState("button_accept_disable");
  const [disabledAddClass, setDisabledAddClass] = useState(true);

  const [questionList, setQuestionList] = useState(/** @type {api.Comentario[]} */([]));
  const [opcionpregunta, setOpcionpregunta] = useState("comentario");
  const [opcionrespuesta, setOpcionrespuesta] = useState("respuesta");
  const [opcion, setOpcion] = useState(opcionpregunta);
  const [opciontitulopregunta, setOpciontitulopregunta] = useState("Todos los comentarios del curso:");
  const [opciontitulo, setOpciontitulo] = useState(opciontitulopregunta);
  const [hiddenquestions, setHiddenQuestions] = useState(false);
  const [hiddenasnwer, setHiddenAnswer] = useState(true);
  const [actualizarpreguntas, setActualizarpreguntas] = useState([]);
  const [mensajesindatos, setMensajesindatos] = useState("");


  const handleCloseSnackbarSuccess = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackbarSuccess(false);
  };

  const handleCloseSnackbarWarning = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackbarWarning(false);
  };

  const ResponderCancel = () => {
    setHiddenAnswer(true);
    setOpcion(opcionpregunta);
    setHiddenQuestions(false);
    setOpciontitulo(opciontitulopregunta);
  };

  const ValidarForm = () => {

    if (!coursetitle || coursetitle == '' || coursetitle == undefined) {

      setErrorCoursetitle(true);
      setMensCoursetitle("Se requiere ingresar el título");
      document.getElementById('question-title').focus();
      return;
    }

    if (coursetitle.length > 250) {
      setErrorCoursetitle(true);
      setMensCoursetitle("No se permite mas de 250 carácteres para este campo.");
      document.getElementById('question-title').focus();
      return;
    }


    if (!textValue || textValue == '' || textValue == undefined || textValue === 'null' || textValue === null || textValue === '<p><br></p>') {
      setErrorTextValue(true);
      setMensTextValue("Se requiere ingresar la descripción");
      document.getElementById('quill_descripcion').focus();
      return;
    }
    save();

  };


  const save = e => {

    let Comentario = {

      "id_usuario": parseInt(data_user.id_usuario),
      "id_curso": courseID,
      "texto": textValue,
      "titulo": document.getElementById('question-title').value
      //"fecha_creacion": "21-01-2022 10:42:15"
    };

    axios.post(process.env.REACT_APP_URL_API + '/curso/' + courseID + '/comentario', Comentario, {
      headers: {
        'Content-Type': 'application/json',
        'token': token
      }
    })
      .then(response => {
        if (response.data.success === true) {

          if (actualizarpreguntas === "0") {
            setActualizarpreguntas("1");
          } else {
            setActualizarpreguntas("0");
          }
          LimpiarFormQA();
          setOpenSnackbarSuccess(true).show();

        }
        if (response.data.success === false) {

          setOpenSnackbarWarning(true);
          setMensOpenSnackbarWarning("Error al intentar guardar.");
        }
        if (response.data.success === false && response.data.mensaje === "Ya has comentado este curso") { //colocar error 200 en el backend

          setOpenSnackbarWarning(true);
          setMensOpenSnackbarWarning(response.data.mensaje);
        }

      })
      .catch(e => {
        console.log(e);
      });


    //setQuestionList(Pregunta);
  };

  const LimpiarFormQA = () => {

    setCoursetitle("");
    setErrorCoursetitle(false);
    setMensCoursetitle("");

    setTextValue("");
    setErrorTextValue(false);
    setMensTextValue("");

    setBotonstatus("button_accept_disable");
    setDisabledAddClass(true);

  };

  useEffect(() => {

    fetch(process.env.REACT_APP_URL_API + '/curso/' + courseID + '/comentario', {
      method: 'get',
      headers: {
        'token': token,
        'Content-Type': 'application/json'
      }
    })
      .then(response => response.json()
        .then(data => {
          if (data.success === true) {
            if (data.data.n_comentarios > 0) {
              setQuestionList(data.data.comentarios);
              setHiddenAnswer(true);
              setOpcion(opcionpregunta);
              setHiddenQuestions(false);
              setOpciontitulo(opciontitulopregunta);
            }
            if (data.data.n_comentarios === 0) {
              setHiddenQuestions(true);
              setOpcion(opcionpregunta);
              setOpciontitulo(opciontitulopregunta);
              setHiddenAnswer(true);
              setMensajesindatos(data.mensaje);
            }
          }
          if (data.success === false && data.data === null) {
            //setMens(true);
            sessionStorage.setItem("token", null);
            sessionStorage.setItem("user_data", null);
            ValidarAuth();
          }
          if (data.success === false) {
            setHiddenQuestions(true);
            setOpcion(opcionpregunta);
            setOpciontitulo(opciontitulopregunta);
            setHiddenAnswer(true);
          }

        })
      );
  }, [actualizarpreguntas]);


  let listadoPreguntas = [];
  if (questionList && questionList instanceof Array && questionList.length) {

    Object.keys(questionList).forEach(key => {

      listadoPreguntas.push(

        <Grid container key={questionList[key].id}>



          <Grid item xs={12}>

            <div className="CardEfect" /*raised={true}*/ hidden={hiddenquestions}>
              <CardContent id={questionList[key].id} className="CardEfect" >
                <CardHeader
                  //className="Pointer"
                  //onClick={() => setIdpadrefunc(questionList[key].id)}
                  avatar={
                    <Avatar aria-label="recipe" className="MuiAvatar-colorForced">
                      {questionList[key].nombres.charAt(0)}
                    </Avatar>
                  }
                  title={questionList[key].titulo}
                  subheader={questionList[key].nombres + " " + questionList[key].apellidos + ". " + questionList[key].fecha_creacion}
                  style={{ height: '17%' }}
                />


                <p style={{ marginLeft: '80px', marginRight: '80px', marginBottom: '15px', marginTop: '0px' }} className="s15 subtitle_title jtx" dangerouslySetInnerHTML={{ __html: questionList[key].texto }}></p>


              </CardContent>


            </div>


          </Grid>
        </Grid>

      );


    });
  }


  var classQA = [];
  // for(let i = 0; i < course.videos.length; i++){
  classQA.push(
    <div key="QA0">
      <div className="id s18 fontn cfff"></div>
      <div className="meta rel">


        <Grid container>
          <Snackbar open={openSnackbarSuccess} autoHideDuration={5000} onClose={handleCloseSnackbarSuccess} /*style = {styles.snakbar}*/ anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
            <Alert severity="success" variant="filled" >
              El {opcion} se ha añadido correctamente.
            </Alert>
          </Snackbar>

          <Snackbar open={openSnackbarWarning} autoHideDuration={5000} onClose={handleCloseSnackbarWarning} /*style = {styles.snakbar}*/ anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
            <Alert severity="warning" variant="filled" >
              {mensopenSnackbarWarning}
            </Alert>
          </Snackbar>

          <Grid item xs={12}>

            {/*listadoPreguntas*/}
            {/*listadoRespuestas*/}
            <div className="section section-b " >
              <h1 className="s18 lbl title_section fontb c333" style={{ marginLeft: '0' }}>Agregar {opcion}:</h1>
              <TextField className="InputText" id="question-title" label="Título o resumen" variant="outlined" fullWidth
                InputProps={{
                  className: "InputText"
                }}
                InputLabelProps={{
                  className: "InputText",
                }}

                error={errorCoursetitle}
                //errormessage={errorCoursetitle}
                onBlur={() => {
                  if (coursetitle || coursetitle != '' || coursetitle != undefined) {
                    setErrorCoursetitle(false);
                    setMensCoursetitle("");
                  } else {
                    setErrorCoursetitle(true);
                    setMensCoursetitle("Este campo es obligatorio");
                  }
                }}
                onChange={(e) => {
                  setCoursetitle(e.target.value)
                  if (e.target.value != '' && e.target.value.length < 251
                    && textValue && textValue != '' && textValue != undefined && textValue != 'null' && textValue != null && textValue != '<p><br></p>') {
                    setBotonstatus("button_accept");
                    setDisabledAddClass(false);
                  } else {
                    setBotonstatus("button_accept_disable");
                    setDisabledAddClass(true);
                  }

                }}
                helperText={mensCoursetitle}
                type="text"
                value={coursetitle}
                style={{ marginTop: '0.5rem' }}
              />
            </div>

            <h3 className="title_section" style={{ marginTop: '1rem', marginBottom: '0.5rem' }}>Detalles:</h3>
            <EditorToolbar shortId="Descripcion" />
            <ReactQuill
              id="quill_descripcion"
              key="1"
              item="item1"
              theme="snow"
              value={textValue}
              placeholder={"Ingrese los detalles."}
              modules={modules("Descripcion")}
              formats={formats}

              error={errorTextValue}
              //errormessage={errorTextValue}
              onBlur={(e) => {
                if (!textValue || textValue == '' || textValue == undefined || textValue === null || textValue === '<p><br></p>') {
                  setErrorTextValue(true);
                  setMensTextValue("Este campo es obligatorio");
                } else {

                  setErrorTextValue(false);
                  setMensTextValue("");
                }
              }}
              onChange={(e) => {
                setTextValue(e);
                if (coursetitle != '' && coursetitle.length < 251
                  && e && e != '' && e != undefined && e != null && e != '<p><br></p>') {
                  setBotonstatus("button_accept");
                  setDisabledAddClass(false);
                } else {
                  setBotonstatus("button_accept_disable");
                  setDisabledAddClass(true);
                }
              }}
              helperText={mensTextValue}
              type="text"
            />

            <div >
              {opcion == opcionrespuesta &&
                <Button type="button" variant="contained" style={{ float: 'right', marginLeft: '15px', marginTop: '15px' }} className="button_cancel" onClick={ResponderCancel}>
                  Volver
                </Button>

              }

              <Button type="button" disabled={disabledAddClass} onClick={ValidarForm} variant="contained" color="primary" style={{ float: 'right', marginLeft: '15px', marginTop: '15px' }} className={botonstatus} /*onClick={save}*/>
                Publicar {opcion}
              </Button>


            </div>
          </Grid>
        </Grid>

      </div>
      <h1 className="s13 dur fontn c777"></h1>
    </div>
  );

  // for(let i = 0; i < course.videos.length; i++){
  classQA.push(
    <div key="QA1">
      <div className="id s18 fontn cfff"></div>
      <div className="meta rel">
        <h1 className="s18 lbl title_section fontb c333">{opciontitulo}</h1>
        <div className="rel">

          {mensajesindatos != "" && mensajesindatos}

          <Grid container>
            <Grid item xs={12}>
              {listadoPreguntas}
            </Grid>
          </Grid>

        </div>
        <h1 className="s13 dur fontn c777"></h1>
      </div>
    </div>
  );

  /**Fin Comentarios del curso**/

  function handleClickCursoSectionMisDiplomados(event) {

    event.preventDefault();
    history.push("/my-certified");
  }

  function handleClickCursoMiDiplomado(event) {

    event.preventDefault();
    history.push("/owncertified/" + certifiedID + "/Mis programas");
  }

  function handleClickCursoMisCursos(event) {

    event.preventDefault();
    history.push("/my-courses");
  }

  function ComenzarCurso(_course) {
    if (!Object.values(_course).length) {
      enqueueSnackbar('Curso actualmente deshabilitado', {
        variant: 'info'
      });
      return;
    }
    const unidades_clases = Object.entries(_course);
    history.push('/class/' + courseID + '/' + unidades_clases[0][1].clases[0].id + '/' + unidades_clases[0][1].clases[0].id_unidad + '/' + section + '/' + certifiedID);
  }

  return (

    <div className="App rel">
      <Breadcrumbs aria-label="breadcrumb" className="breadcrumbs_link_inneret">

        {section == "Mi programa" &&
          <Link color="inherit" onClick={handleClickCursoSectionMisDiplomados} className="breadcrumbs_link_inneret">
            Mis programas
          </Link>
        }
        {section == "Mi programa" &&
          <Link color="inherit" onClick={handleClickCursoMiDiplomado} className="breadcrumbs_link_inneret">
            {section}
          </Link>
        }
        {section == "Mis cursos" &&
          <Link color="inherit" onClick={handleClickCursoMisCursos} className="breadcrumbs_link_inneret">
            Mis cursos
          </Link>
        }

        <Typography color="textPrimary" className="breadcrumbs_link">
          Curso
        </Typography>
      </Breadcrumbs>

      <Snackbar open={openSnackbarWarningDatos} autoHideDuration={2000} onClose={handleCloseSnackbarWarningDatos} /*style = {styles.snakbar}*/ anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
        <Alert severity="warning" variant="filled" >
          No se encontraron datos.
        </Alert>
      </Snackbar>
      <Snackbar open={openSnackbarError} autoHideDuration={3000} onClose={handleCloseSnackbarError} /*style = {styles.snakbar}*/ anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
        <Alert severity="error" variant="filled" >
          No se encontraron datos.
        </Alert>
      </Snackbar>
      <Snackbar open={openSnackbarSuccessMens} autoHideDuration={3000} onClose={handleCloseSnackbarSuccessMens} /*style = {styles.snakbar}*/ anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
        <Alert severity="success" variant="filled" >
          {mensajesuccess}
        </Alert>
      </Snackbar>


      <Hidden mdUp>
        <Grid container>

          <Grid item xs={12} sm={12} md={4} lg={4} xl={3} style={{ cursor: 'default' }} >


            <div className="section">

              <ImagePreview image={utils.getImagenCurso(curso.imagen)} alt="imagen curso" className="stacked-block" />

              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}  >
                <Button variant="contained" style={{ width: '100%' }} color="secondary" className="button_accept aic" onClick={() => ComenzarCurso(course)}>
                  <b>Comenzar</b>&nbsp; &nbsp;
                </Button>
              </Grid>

              <div className="flex stacked-block">
                <p className="contenido-label-detalle">
                  <span className="content-highlighted-label">Instructor: </span><span style={{ fontStyle: "italic" }}>{curso.nombre_instructor || 'N/A'}</span>
                </p>
              </div>

              <div className="course-meta">
                <h2 className="title_section sub-title hide-on-xs always-colored">{curso.titulo}</h2>
                <p className="content-description jtx" dangerouslySetInnerHTML={{ __html: curso.descripcion || 'Curso sin descripción.' }} />
              </div>

              <Card id="tarjeta-rating" className="stacked-block2" elevation={5} >

                <Box sx={{ minWidth: '90%' }} style={{ marginLeft: '5px', marginBottom: '0px' }}>
                  <CardHeader title="Mi valoración" />
                </Box>
                <Box style={{ marginBottom: '15px' }}>
                  <div style={{ display: 'flex', justifyContent: 'center' }} >
                    <CourseRatingEditor value={valueRating} handleRatingChange={handleSendRating} readOnly={true} />
                  </div>
                </Box>
              </Card>
              <div className="section">

                <h2 className="title_section s24 fontb">Detalles del curso</h2>
                <div className="course-videos aic flex">
                  {disableLoadingDetalles != true &&
                    <CircularProgress className="CircularProgress" style={{ alignSelf: 'center' }} />
                  }
                  <div className="stack-acordeones">
                    {acordeonesUnidades}
                  </div>
                </div>

                {classQA}

                <Dialog
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="customized-dialog-title"
                >
                  <DialogTitle id="alert-dialog-title" onClose={handleClose} className="modal_title">{"Advertencia!"}</DialogTitle>
                  <DialogContent dividers className="modal_content">

                    <Typography gutterBottom>
                      Este curso ya se encuentra en su carro de compras.
                    </Typography>

                  </DialogContent>

                  <DialogActions className="modal_footer">
                    <Button onClick={handleClose} variant="contained" className="button_cancel">
                      Cancelar
                    </Button>
                  </DialogActions>
                </Dialog>

              </div>
              <div id="cargando" className={classes3.root}>
                <Dialog
                  open={openload}
                  onClose={handleCloseLoad}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogContent className="CentrarLoading">
                    <CircularProgress className="CircularProgress" />
                  </DialogContent>

                </Dialog>
              </div>

            </div>

          </Grid>


        </Grid>
      </Hidden>


      <Hidden smDown>

        <Grid container spacing={3}>

          <Grid item xs={6} sm={6} md={8} lg={8} xl={9} >

            <h1 className="title_section s24 fontb c333">Curso</h1>

            <div className="section" >
              <div className="extras-block">
                <div className="tutor rel aic flex">
                  <p className="contenido-label-detalle">
                    <span className="content-highlighted-label">Instructor: </span><span style={{ fontStyle: "italic" }}>{curso.nombre_instructor || 'N/A'}</span>
                  </p>
                </div>

                <div className="course-meta">
                  <h2 className="title_section sub-title hide-on-xs always-colored">{curso.titulo}</h2>
                  <p className="content-description jtx" dangerouslySetInnerHTML={{ __html: curso.descripcion || 'Curso sin descripción.' }} />
                  <hr size="1" style={{ marginTop: '4rem' }} />
                  {classQA}
                </div>
              </div>
            </div>

          </Grid>

          <Grid item xs={6} sm={6} md={4} lg={4} xl={3} style={{ cursor: 'default' }} >

            <div className="section" style={{ marginTop: '25px' }}>
              <div className="extras-block">
                <ImagePreview image={utils.getImagenCurso(curso.imagen)} alt="imagen curso" />

                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ paddingTop: '0.5rem' }} >
                  <Button variant="contained" style={{ width: '100%' }} color="secondary" className="button_accept aic" onClick={() => ComenzarCurso(course)}>
                    <b>Comenzar</b>&nbsp; &nbsp;
                  </Button>
                </Grid>

                <Card style={{ width: '100%', marginTop: '1rem' }} id="tarjeta-rating" elevation={5} >

                  <Box sx={{ minWidth: '90%' }} style={{ marginLeft: '5px', marginBottom: '0px' }}>
                    <CardHeader title="Mi valoración" />
                  </Box>

                  <Box style={{ marginBottom: '15px' }} >
                    <div style={{ display: 'flex', justifyContent: 'center' }} >
                      <CourseRatingEditor value={valueRating} handleRatingChange={handleSendRating} readOnly={true} />
                    </div>
                  </Box>

                </Card>
                <div className="section">

                  <h2 className="title_section s24 fontb">Detalles del curso</h2>
                  <div className="course-videos aic flex" style={{ marginTop: '0.5rem' }} >
                    {disableLoadingDetalles != true &&
                      <CircularProgress className="CircularProgress" style={{ alignSelf: 'center' }} />
                    }
                    <div className="stack-acordeones">
                      {acordeonesUnidades}
                    </div>
                  </div>

                  <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="customized-dialog-title"
                  >
                    <DialogTitle id="alert-dialog-title" onClose={handleClose} className="modal_title">{"Advertencia!"}</DialogTitle>
                    <DialogContent dividers className="modal_content">

                      <Typography gutterBottom>
                        Este curso ya se encuentra en su carro de compras.
                      </Typography>

                    </DialogContent>

                    <DialogActions className="modal_footer">
                      <Button onClick={handleClose} variant="contained" className="button_cancel">
                        Cancelar
                      </Button>
                    </DialogActions>
                  </Dialog>

                </div>
                <div id="cargando" className={classes3.root}>
                  <Dialog
                    open={openload}
                    onClose={handleCloseLoad}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <DialogContent className="CentrarLoading">
                      <CircularProgress className="CircularProgress" />
                    </DialogContent>

                  </Dialog>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </Hidden>
    </div>

  );

}

export default OwnCoursePage;
