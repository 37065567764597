import React, { useState, useContext } from 'react';
import { useHistory } from "react-router";
import { NavLink } from "react-router-dom";
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import { Hidden } from "@material-ui/core";
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import logoEnapsisBlanco from '../ui/logo-enapsis-blanco.png';

import { BotonContext } from "../BotonContext";
import Botonflotante from "../componentes/botonflotante";
import Cajon from "../componentes/Cajon";
import AuthContext from "../AuthContext";
import { theme as tema } from "../util/theme";
import env from '../util/env';
import routeHelper from '../util/routeHelper';
import { FLAGS } from '../lib/constants';

const drawerWidth = 200;
const hideDiplomados = env.flags.HIDE_DIPLOMADOS || FLAGS.DISABLE_PROGRAMS;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex'
    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
        [theme.breakpoints.down('xs')]: {
            paddingLeft: '4px !important'
        }
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '0 8px',
        ...theme.mixins.toolbar
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        })
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        })
    },
    menuButtonHidden: {
        display: 'none'
    },
    title: {
        flexGrow: 1,
        display: 'none',
        marginLeft: '0.7rem !important',
        [theme.breakpoints.up('sm')]: {
            display: 'block'
        }
    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        })
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        width: theme.spacing(0),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(0)
        }
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto'
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4)
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column'
    },
    fixedHeight: {
        height: 240
    },
    grow: {
        flexGrow: 1
    },
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up('lg')]: {
            display: 'none'
        }
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        marginRight: '4rem',
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: 'auto'
        },
        color: tema.current === "light" ? 'white' : 'gray',
        backgroundColor: 'transparent'
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: tema.current === "light" ? 'white' : 'white',
        backgroundColor: 'transparent',
        zIndex: 99
    },
    inputRoot: {
        color: 'inherit',
        border: tema.current === "light" ? 'white 1px solid' : 'white 1px solid',
        backgroundColor: tema.current === "light" ? 'transparent' : 'transparent'
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '14ch'
        }
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex'
        }
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none'
        }
    }
}));

export default function Dashboard() {

    const history = useHistory();
    const { cart, setCart } = useContext(BotonContext);
    const [abrir, setAbrir] = useState(false);
    const { isAuth, isAuthTypeTutor } = useContext(AuthContext);
    const [openmensnoauth, setOpenmensnoauth] = React.useState(false);

    /**
     * @param {string} query
     */
    const handleSearchText = function (query) {
        if (query) {
            history.push('/search-courses/' + encodeURIComponent(query));
        }
    };

    const ValidarAuth = () => {
        const data_user = JSON.parse(sessionStorage.getItem("user_data"));
        const token = sessionStorage.getItem("token");
        if (isAuth && (token === null || token === 'null' || token === "" || token === undefined
            || data_user === null || data_user === 'null' || data_user === "" || data_user === undefined)) {
            setOpenmensnoauth(true);
            localStorage.removeItem("cart");
            sessionStorage.clear();
            window.location.href = "#/login";
            window.location.reload();
        }
    };

    const nav = [
        { label: "Inicio", slug: "/home", icon: "icon-home" },
        { label: "Cursos", slug: "/courses", icon: "icon-tag" },
        { label: "Programas", slug: "/certified", icon: "icon-certificate", disabled: hideDiplomados },
        { label: "Planes multiusuario", slug: routeHelper.multiusuario, icon: "icon-group" },
        { label: "Nosotros", slug: routeHelper.nosotros, icon: "icon-user-bubble" },
        { label: "Enseña con nosotros", slug: "/tutor-registration", icon: "icon-graduation", disabled: isAuthTypeTutor || !isAuth }
    ];

    const navigation = [];

    for (let i = 0; i < nav.length; i++) {
        const item = nav[i];
        if (item.disabled) continue;
        navigation.push(
            <li key={"nav-" + i + "-" + item.slug}>
                <NavLink to={item.slug} className={"aic link noul flex c333"} >
                    <div className={"ico s20 cfff appbar-active" + item.icon} />
                    <h2 className="lbl s20 appbar-active">{item.label}</h2>
                    &nbsp; &nbsp;
                </NavLink>
            </li>
        );
    }

    const classes = useStyles();
    const [open, setOpen] = React.useState(false);

    const accionAbrir = () => {
        setAbrir(!abrir);
    };

    return (
        <div className={classes.root}>
            <CssBaseline />
            <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
                <Toolbar className={classes.toolbar}>
                    <ul>
                        <IconButton
                            id="button-open-sidebar"
                            style={{ color: 'white' }}
                            aria-label="menu"
                            className={classes.menuButton}
                            onClick={() => accionAbrir()}
                        >
                            <MenuIcon />
                        </IconButton>
                    </ul>

                    {/* logo e-napsis */}
                    <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title} >

                        <Hidden xsDown smDown mdDown >
                            <a href="#" className="logo bl" onClick={ValidarAuth} >
                                <img src={logoEnapsisBlanco} className="logo bl" />
                            </a>
                        </Hidden>
                        <Hidden lgUp>
                            <a href="#" className="logocel bl" onClick={ValidarAuth} >
                                <img src={logoEnapsisBlanco} className="logocel bl" />
                            </a>
                        </Hidden>

                    </Typography>

                    {/* barra de navegación superior */}
                    <Hidden xsDown smDown mdDown >

                        <Typography className={classes.title} variant="h6" noWrap>
                            <ul>
                                <Toolbar className='nav' style={{ justifyContent: 'center' }}>
                                    {navigation}
                                </Toolbar>
                            </ul>
                        </Typography>

                    </Hidden>

                    {/** barra de botones (top-right) */}
                    <Toolbar id="top-action-toolbar" style={{ flexGrow: 1, justifyContent: 'end' }}>

                        {/* search bar */}
                        <Hidden smDown >
                            <Toolbar className={classes.search} >
                                <Toolbar className={classes.searchIcon}>
                                    <SearchIcon />
                                </Toolbar>
                                <InputBase
                                    placeholder="Buscar…"
                                    classes={{
                                        root: classes.inputRoot,
                                        input: [classes.inputInput, 'placeholder-over-color'].join(' ')
                                    }}
                                    inputProps={{ 'aria-label': 'search' }}
                                    onKeyPress={(e) => {
                                        if (e.key === 'Enter') {
                                            handleSearchText(e.target.value);
                                            e.target.value = ''; // limpiar input de búsqueda del topbar (estará en el searchbar propio de la página de búsqueda)
                                        }
                                    }}
                                />
                            </Toolbar>
                        </Hidden>

                        {/* barra de botones */}
                        <Botonflotante style={{ float: 'left', marginRight: '5px' }} />

                    </Toolbar>

                </Toolbar>

                <Hidden xsDown>
                    <Cajon
                        variant="permanente"
                        open={false}
                    />
                </Hidden>

                <Hidden lgUp>
                    <Cajon
                        variant="temporary"
                        open={abrir}
                        onClose={accionAbrir}
                    />
                </Hidden>


            </AppBar>


        </div>

    );
}
