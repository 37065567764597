import React, { useContext, useEffect, useState } from "react";
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import { Hidden } from "@material-ui/core";

import { BotonContext } from "../BotonContext";

import api from "../lib/api";

function ProfileEdit() {

    useEffect(() => {
        document.title = "Cambiar contraseña";
    });

    const { token } = useContext(BotonContext);

    const [oldPassword, setOldPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [newPassword2, setNewPassword2] = useState("");

    const [messageOldPassword, setMessageOldPassword] = useState("");
    const [messageNewPassword2, setMessageNewPassword2] = useState("");

    const [waitingResponse, setWaitingResponse] = useState(false);

    const [toast, setToast] = useState({
        open: false,
        message: '',
        /** @type {import("@material-ui/lab").Color} */
        severity: 'success'
    });


    const useStyles = makeStyles((theme) => ({
        formControl: {
            margin: theme.spacing(1)
        },
        selectEmpty: {
            marginTop: theme.spacing(2)
        },
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        },
        paper: {
            backgroundColor: theme.palette.background.paper,
            borderRadius: '5px',
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3)
        }
    }));


    const useStyles2 = makeStyles({
        root: {
            minWidth: 280,
            maxWidth: 398
        },
        bullet: {
            display: 'inline-block',
            margin: '0 2px',
            transform: 'scale(0.8)'
        },
        title: {
            fontSize: 14
        },
        pos: {
            marginBottom: 12
        }
    });

    // corregir inconsistencias en el ancho de formularios de un módulo a otro (login / recovery / password change)
    const useStylesFixAncho = makeStyles((theme) => ({
        formControl: {
            [theme.breakpoints.up('sm')]: {
                width: 'calc(100% - 16px)'
            },
            [theme.breakpoints.down('sm')]: {
                maxWidth: '327px',
                width: '100%'
            }
        },
        card: {
            width: '398px'
        }
    }));

    const classes = useStyles();
    const classes2 = useStyles2();
    const classesFixAncho = useStylesFixAncho();


    const hideToast = function () {
        setToast({
            open: false,
            message: toast.message,
            severity: toast.severity
        });
    };

    /**
     * @returns {boolean}
     */
    const validar = function () {
        let valid = true;
        setMessageOldPassword("");
        setMessageNewPassword2("");
        if (!oldPassword) {
            setMessageOldPassword('Debe especificar la contraseña anterior');
            valid = false;
        }
        if (!newPassword) {
            setMessageNewPassword2('Debe especificar una nueva contraseña');
            valid = false;
        } else if (newPassword !== newPassword2) {
            setMessageNewPassword2('Las contraseñas no coinciden');
            valid = false;
        }
        return valid;
    };

    const handleAplicar = function () {
        if (waitingResponse || !validar()) return;
        const data_user = /** @type {api.UserData} */( JSON.parse(sessionStorage.getItem("user_data")) ); // TODO: hacer uso de "data_user" del contexto (una vez que se corrija para que guarde objeto en lugar de JSON serializado)
        setWaitingResponse(true);
        api.changePassword(token, data_user.email, oldPassword, newPassword)
            .then((response) => {
                if (response.success) {
                    setToast({
                        open: true,
                        message: response.mensaje || "Contraseña actualizada exitosamente",
                        severity: 'success'
                    });
                    setOldPassword("");
                    setNewPassword("");
                    setNewPassword2("");
                } else {
                    setToast({
                        open: true,
                        message: response.mensaje || "No se pudo cambiar la contraseña",
                        severity: 'warning'
                    });
                    if (/contraseña.*incorrecta/i.test(response.mensaje)) {
                        setMessageOldPassword('Contraseña incorrecta');
                    }
                }
            })
            .catch(() => {
                setToast({
                    open: true,
                    message: "Error al cambiar la contraseña",
                    severity: 'error'
                });
            })
            .finally(() => {
                setWaitingResponse(false);
            });
    };

    const editPasswordForm = (
        <FormControl variant="outlined" className={[classes.formControl, classesFixAncho.formControl].join(' ')} >
            <h3 style={{ marginTop: '-0.7rem' }}>CAMBIAR CONTRASEÑA</h3>
            <TextField id="old-password" name="old-password" label="Contraseña actual"
                InputProps={{
                    className: "InputText"
                }}
                InputLabelProps={{
                    className: "InputText"
                }}
                variant="outlined"
                fullWidth
                error={!!messageOldPassword}
                onChange={(e) => setOldPassword(e.target.value)}
                helperText={messageOldPassword}
                type="password"
                value={oldPassword}
                required
                autoComplete="off"
                style={{ marginTop: '30px' }}
            />
            <div style={{ paddingTop: '1rem', paddingBottom: '1.5rem' }}>Nueva contraseña</div>
            <TextField id="password" name="password" label="Nueva contraseña"
                InputProps={{
                    className: "InputText"
                }}
                InputLabelProps={{
                    className: "InputText"
                }}
                variant="outlined"
                fullWidth
                onChange={(e) => setNewPassword(e.target.value)}
                type="password"
                value={newPassword}
                required
                autoComplete="off"
            />
            <TextField id="password2" name="password2" label="Repetir contraseña"
                InputProps={{
                    className: "InputText"
                }}
                InputLabelProps={{
                    className: "InputText"
                }}
                maxRows={12}
                minRows={7} variant="outlined"
                fullWidth
                error={!!messageNewPassword2}
                onChange={(e) => setNewPassword2(e.target.value)}
                helperText={messageNewPassword2}
                type="password"
                value={newPassword2}
                required
                autoComplete="off"
                style={{ marginTop: '30px' }}
            />
            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '50px' }}>
                <Button type="button" onKeyPress={handleAplicar} onClick={handleAplicar} variant="contained" color="primary" className="form__custom-button" fullWidth style={{ minWidth: '125px', width: '100%' }}>
                    Aplicar
                </Button>
            </div>
        </FormControl>
    );

    return (
        <div className="Login_register">

            <Snackbar open={toast.open} autoHideDuration={3000} onClose={hideToast} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
                <Alert severity={toast.severity} variant="filled" style={{ maxWidth: '300px' }} >
                    {toast.message}
                </Alert>
            </Snackbar>

            <form className={classes.modal} autoComplete="off">

                <Hidden smUp>
                    {editPasswordForm}
                </Hidden>

                <Hidden xsDown>
                    <Card className={[classes2.root, classesFixAncho.card].join(' ')} variant="outlined">
                        <CardContent className="logincard logincard-bottom">
                            {editPasswordForm}
                        </CardContent>
                    </Card>
                </Hidden>

            </form>

        </div>
    );
}

export default ProfileEdit;
