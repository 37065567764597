import React from "react";
import { useHistory } from "react-router";
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '../componentes/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import { makeStyles } from '@material-ui/core/styles';

import YouTubeEmbeddedPlayer from "./YouTubeEmbeddedPlayer";
import AddToCartButton from "./AddToCartButton";
import { useCart } from "../util/Cart";
import routeHelper from "../util/routeHelper";

const useStyles = makeStyles(theme => ({
    "ResponsiveDialogContent": {
        paddingBottom: '4rem !important', // se hizo por esta vía para poder usar "!important" sin tener que definir el estilo en el .css (el problema viene por la mala practica de forzar la mayoría de estilos en el .css base mediante !important)
        [theme.breakpoints.down('sm')]: {
            padding: '1.5rem !important'
        },
        [theme.breakpoints.down('xs')]: {
            padding: '0 !important'
        }
    },
    "ResponsiveDialog": {
        [theme.breakpoints.down('xs')]: {
            '& .MuiDialog-paper': {
                margin: '0.5rem',
                width: '100%'
            }
        }
    }
}));

/**
 * @param {Object} params
 * @param {boolean} params.open
 * @param {string} params.video
 * @param {api.Curso} [params.curso]
 * @param {() => void} [params.handleClose]
 * @returns {JSX.Element}
 */
export default function ModalVideoPlayer({ open, video, curso, handleClose }) {
    const classes = useStyles();
    const history = useHistory();
    const { addCourse, hasItem } = useCart();
    if (!open) return <></>;
    return (
        <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth={'md'} className={ classes.ResponsiveDialog } >
            <DialogTitle id="alert-dialog-title" onClose={handleClose} className="modal_title">{"Vista previa del curso"}</DialogTitle>
            <DialogContent dividers className={["modal_content", classes.ResponsiveDialogContent].join(' ')} >
                <YouTubeEmbeddedPlayer video={video} autoplay={false} />
                {curso &&
                    <div style={{ display: 'flex', flexDirection: 'column', padding: '0.5rem', gap: '0.3rem', width: '100%', paddingLeft: '70%', marginTop: '0.3rem' }}>
                        <AddToCartButton text="Agregar al carrito" course={curso} style={{ height: '1.5rem' }} />
                        <button className="button_accept MuiButton-contained" style={{ height: '1.5rem' }} onClick={() => {
                            const inCart = hasItem(curso);
                            !inCart && addCourse(curso, false);
                            history.push(routeHelper.cart);
                        }}>Comprar ahora</button>
                    </div>
                }
            </DialogContent>
        </Dialog>
    );
}
