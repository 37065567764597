import React from "react";
import { useHistory } from "react-router";

const scroll2Top = function () {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
};

export const BANNER_TYPE = {
    imagen: 1
};

export const BANNER_POSITION = {
    top: 1,
    middle: 2,
    bottom: 3
};

/**
 * @param {Object} params
 * @param {string} params.image
 * @param {Function | string} params.link
 * @param {React.CSSProperties} [params.style]
 * @returns {JSX.Element}
 */
export default function Banner({ image, link, style }) {
    const hasLink = !!link;
    const history = useHistory();
    return <>
        <div>
            <img src={image} style={{ width: '100%', cursor: hasLink ? 'pointer' : null, ...style }} onClick={() => {
                if (!hasLink) return;
                if (typeof link === 'function') {
                    link();
                } else if (typeof link === 'string') {
                    history.push(link);
                    scroll2Top();
                }
            }} />
        </div>
    </>;
}
