import React, {createContext, useState} from "react";
import i18n from "./lib/i18n";

/**
 * @typedef TBotonContext
 * @property {number} countryId
 * @property {React.Dispatch<React.SetStateAction<number>>} setCountryId
 * @property {api.PaísPasarela[]} countryList
 * @property {React.Dispatch<React.SetStateAction<api.PaísPasarela[]>>} setCountryList
 * @property {api.ItemCarrito[]} cart
 * @property {React.Dispatch<React.SetStateAction<Array>>} setCart
 * @property {string} token
 * @property {React.Dispatch<React.SetStateAction<string>>} setToken
 * @property {api.UserData} data_user
 * @property {React.Dispatch<React.SetStateAction<api.UserData>>} setDataUser
 * @todo encontrar manera de inferir tipo. O cuando menos declarar de manera distribuida. Evitar necesidad de declarar todo explícitamente.
 */
export const BotonContext = /** @type {React.Context<TBotonContext>} */( createContext({}) );

export const BotonProvider = ({ children }) => {

    const [cart, setCart] = useState(JSON.parse(localStorage.getItem('cart')));
    const [token, setToken] = useState(sessionStorage.getItem('token'));
    const [data_user, setDataUser] = useState(JSON.parse(sessionStorage.getItem('user_data')));
    const [countryId, setCountryId] = useState( i18n.getCountry().id_pais );
    const [countryList, setCountryList] = useState(/** @type {api.PaísPasarela[]} */([]));

    return (
        <BotonContext.Provider value={{
            cart,
            setCart,
            token,
            setToken,
            data_user,
            setDataUser,
            countryId,
            setCountryId,
            countryList,
            setCountryList
        }}>
            { children }
        </BotonContext.Provider>
    );

};
