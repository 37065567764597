import commonIO from "../util/common.io";
import api from "./api";

const LOCAL_STORAGE_COUNTRY_KEY = 'country';

/**
 * @type {api.PaísPasarela}
 * @todo definir solo el ID predeterminado y el resto tomarlo de la BD.
 */
const DEFAULT_COUNTRY = {
    id_pais: 56,
    nombre: "Chile",
    codigo: "CL",
    iniciales: "CHL",
    time_zone: "-4",
    estado: 1,
    nombre_moneda: "Peso Chileno",
    abreviatura: "CLP",
    simbolo: "$"
};

const ISO_CODES = {
    Argentina: "ARG",
    Brasil:    "BRA",
    Chile:     "CHL",
    Colombia:  "COL",
    Peru:      "PER",
    Uruguay:   "URY"
};

/**
 * @returns {api.PaísPasarela}
 */
const getCountry = function () {
    const value = localStorage.getItem(LOCAL_STORAGE_COUNTRY_KEY);
    if (value) {
        try {
            return JSON.parse(value);
        } catch { /* empty catch */ }
    }
    return DEFAULT_COUNTRY;
};

/**
 * @param {api.PaísPasarela} country
 * @param {string} [token] - Permite guardar la opción en la BD. Solo especificar si hay sesión de usuario activa.
 * @todo utilizar API dedicado para guardar el país. Es ineficiente tener que recurrir a otro API. Se está usando el de suscripciones ya que, entre los disponibles, es generalmente el que tiene la respuesta más rápida y de menor peso.
 */
const setCountry = function (country, token) {
    localStorage.setItem(LOCAL_STORAGE_COUNTRY_KEY, JSON.stringify(country));
    if (typeof token !== 'undefined') {
        api.getSuscripciones(token, country.id_pais); // guardar opción // FIX: usar API dedicada.
    }
};

/**
 * @param {api.PaísPasarela} country
 */
const getCountryCurrencyLabel = function (country) {
    return country.abreviatura + ' ' + country.simbolo;
};

/**
 * @returns {string}
 */
const getCountryIsoCode = function () {
    return getCountry().iniciales;
};

/**
 * @returns {string}
 */
const getCurrencyLabel = function () {
    const country = getCountry();
    return getCountryCurrencyLabel(country);
};

/**
 * @returns {string}
 */
const getCurrencySymbol = function () {
    return getCountry().simbolo;
};

const getCurrencyCode = function () {
    return getCountry().abreviatura;
};

const COUNTRIES_WITH_NO_DECIMALS = ["CHL"]; // ISO codes
const DECIMAL_COUNT = 2;

/**
 * @param {number | string} price
 * @returns {number}
 * @todo optimización: determinar si el país tiene o no decimales al momento de cambiar país. Evitar tener que determinarlo para cada ejecución de esta función.
 */
const roundPrice = function (price) {
    if (typeof price === 'string') {
        price = parseFloat(price);
    }
    const countryISO = getCountry().iniciales;
    if (COUNTRIES_WITH_NO_DECIMALS.indexOf(countryISO) > -1) {
        price = Math.round(price);
    }
    if (price % 1) {
        const zeros = Math.pow(10, DECIMAL_COUNT);
        price = Math.round((price + Number.EPSILON) * zeros) / zeros;
    }
    return price;
};

/**
 * @param {number} price
 * @param {boolean} [agregarSeparadorMiles=true] - default = true
 * @returns {string}
 * @todo optimización: determinar si el país tiene o no decimales al momento de cambiar país. Evitar tener que determinarlo para cada ejecución de esta función.
 */
const formatPrice = function (price, agregarSeparadorMiles = true) {
    if (typeof price !== 'number') {
        price = parseFloat(price);
    }
    const countryISO = getCountry().iniciales;
    if (COUNTRIES_WITH_NO_DECIMALS.indexOf(countryISO) > -1) {
        price = Math.round(price);
    }
    const priceStr = price % 1 ? price.toFixed(DECIMAL_COUNT) : price.toFixed(0); // no incluir decimales si parte decimal es cero
    return agregarSeparadorMiles ? commonIO.agregarSeparadorMiles(priceStr) : priceStr;
};

export const strings = {
    COURSE_UPDATED_TAG: 'Actualizado',
    MIS_TUTORIAS: 'Mis Tutorías'
};

export default {
    getCountry,
    setCountry,
    getCountryCurrencyLabel,
    getCurrencyLabel,
    getCountryIsoCode,
    getCurrencySymbol,
    getCurrencyCode,
    roundPrice,
    formatPrice,
    DEFAULT_COUNTRY,
    ISO_CODES
};
