import React from "react";
import { makeStyles, Drawer } from "@material-ui/core";
import Listas from "./Listas";

const styles = makeStyles((theme) => ({
    drawer: {
        width: 240, //drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: 240, //drawerWidth,
    },
    offset: theme.mixins.toolbar
}))


const Cajon = (props) => {

    const classes = styles();
    return(
        <Drawer 
            className={classes.drawer}            
            classes={{
                paper: classes.drawerPaper,
            }}
            anchor="left"
            //variant= {props.variant} //"permanent" //"persistent"
            open={props.open}
            onClose={props.onClose ? props.onClose : null}
        >
            
            <Listas/>
        </Drawer>
        
    )
}

export default Cajon;