import React, {useState, useEffect} from "react";
import {
    SwitchContainer,
    SwitchWrapper,
    SwitchBGC,
    ButtonSwitch
} from "./Switch.elements";
import { theme as tema } from "../../util/theme";


const Switch = () => {
    const [position, setPosition] = useState(tema.isDark);
    const [checked/*, setChecked*/] = useState(tema.isDark);

    useEffect(() => {
        document
        .getElementsByTagName("HTML")[0]
        .setAttribute("data-theme", tema.current);
    }, [checked]);


    const toggleThemeChange = ()=>{
        const actualTheme = tema.current;
        if( !actualTheme || actualTheme === "light") {
            localStorage.setItem("theme", "dark");
            changePosition(position);
        } else {
            localStorage.setItem("theme", "light");
            changePosition();
        }
        window.location.reload(true);
    };

    const changePosition = ()=>{
        setPosition(!position);
    };


    return (
        <>
            <SwitchContainer>
                <SwitchWrapper style={{ paddingTop: 0 }}>
                    <SwitchBGC>
                        <ButtonSwitch
                            onClick={ ()=>toggleThemeChange() }
                            position={position}></ButtonSwitch>
                    </SwitchBGC>
                </SwitchWrapper>
            </SwitchContainer>
        </>
    );
};

export default Switch;

