import React from "react";

/**
 * @param {string} video
 */
const getYouTubeVideoId = function (video) {
    if (video.indexOf('/') === -1) {
        return video; // si no hay '/' se considera que `video` representa directamente el id.
    } else {
        const match = /(?:\/|v=)([\w\-_]{11,})(?:\?|$)/i.exec(video);
        return match ? match[1] : null;
    }
};

/**
 * @param {Object} params
 * @param {string} params.video
 * @param {boolean} [params.autoplay] - default=false
 * @returns {JSX.Element}
 */
export default function YouTubeEmbeddedPlayer({ video, autoplay }) {
    const videoId = getYouTubeVideoId(video);
    return (
        // el layout y los estilos se definen de manera de ajustar el iframe al tamaño del contenedor padre, a la vez que se mantiene la relación de aspecto del iframe.
        // detalles en https://jameshfisher.com/2017/08/30/how-do-i-make-a-full-width-iframe/
        <div>
            <div style={{ position: 'relative', paddingTop: '56.25%' }}>
                <iframe
                    style={{ position: 'absolute', top: '0', left: '0', width: '100%', height: '100%' }}
                    src={`https://www.youtube.com/embed/${videoId}` + (autoplay ? '?autoplay=1' : '') }
                    title="YouTube video player" frameBorder={0}
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen={false}>
                </iframe>
            </div>
        </div>
    );
}
