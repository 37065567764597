import React, { useEffect, useState, useContext, useRef } from "react";
import { useSnackbar } from "notistack";
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import BoltIcon from '@mui/icons-material/Bolt';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { Popover } from "@material-ui/core";

import "@yaireo/tagify/dist/tagify.css"; // Tagify CSS
import "../css/tagify.custom.style.css"; // customization imported from backoffice
import ScriptTag from 'react-script-tag';
import "../css/fonts/feather/style.min.css";
import WebFont from 'webfontloader';
import $ from 'jquery';

import ButtonCircularProgress from "../componentes/ButtonCircularProgress";
import DialogTitle from '../componentes/DialogTitle';
import api from "../lib/api";
import { BotonContext } from "../BotonContext";
import { useSession } from "../util/session";
import { useRouteHelper } from "../util/routeHelper";
import commonIO from "../util/common.io";
import { TIPO_PRODUCTO } from "../lib/constants";
import { DataTableWrapper } from "../util/common.ui";
import { Dropdown } from "bootstrap";

const LIMITE_VINCULACIONES = 15;
const MESSAGE_NO_VINCULACIONES = 'No se encontraron vinculaciones';
const ERROR_DATA_DOWNLOAD = 'Error al descargar vinculaciones';

/**
 * @param {HTMLElement} input
 */
const TagManager = function (input) {

    /** @type {import("@yaireo/tagify")} */
    // @ts-ignore
    const tagify = new global.Tagify(input, /** @type {import("@yaireo/tagify").TagifySettings}*/({
        placeholder: 'correo',
        keepInvalidTags: true,
        texts: {
            duplicate: 'correo duplicado'
        },
        validate(tagData) {
            if (tagify.value.length > (LIMITE_VINCULACIONES - 1)) return false;
            return commonIO.isValidEmail(tagData.value);
        }
    }));

    this.clear = function () {
        tagify.removeAllTags();
    };

    /** @type {boolean} */
    this.hasInvalidTags = null;
    Object.defineProperty(this, 'hasInvalidTags', {
        /** @returns {boolean} */
        get() {
            /** aparentemente Tagify no incluye una manera de determinar esto con un método */
            const invalidTagCount = document.querySelectorAll('.tagify .tagify__tag.tagify--notAllowed').length;
            return invalidTagCount > 0;
        }
    });

    /**
     * @param {boolean} disabled
     */
    this.setDisabled = function (disabled) {
        tagify.setDisabled(disabled);
    };

    /**
     * Incluyendo la cantidad de tags inválidos.
     * @type {number}
     */
    this.fullTagCount = null;
    Object.defineProperty(this, 'fullTagCount', {
        /** @returns {number} */
        get() {
            /** aparentemente Tagify no incluye una manera de determinar esto con un método */
            return document.querySelectorAll('.tagify .tagify__tag').length;
        }
    });

    /** @type {string[]} */
    this.tagList = null;
    Object.defineProperty(this, 'tagList', {
        /** @returns {string[]} */
        get() {
            return tagify.value.map(t => t.value);
        }
    });

};

/**
 * @param {Object} obj
 * @param {React.MutableRefObject<InstanceType<typeof TagManager>>} obj.tagifyRef
 * @returns {JSX.Element}
 */
const Tags = function ({ tagifyRef }) {
    const inputRef = useRef();
    useEffect(() => {
        tagifyRef.current = new TagManager(inputRef.current);
    }, []);
    return <input type="text" ref={inputRef} />;
};

/**
 * @param {any} value
 * @param {string} [fallback]
 * @returns {number | string}
 */
const renderBaseValue = function (value, fallback = '') {
    switch (typeof value) {
        case 'string':
            return value;
        case 'number':
            return Number.isSafeInteger(value) ? value : fallback;
        default:
            return fallback;
    }
};

/**
 * @typedef DataTableVinculadosMethods
 * @property {() => void} update
 */

/**
 * @param {Object} obj
 * @param {number} obj.planId
 * @param {React.MutableRefObject<DataTableVinculadosMethods>} obj.methodsRef
 * @returns {JSX.Element}
 */
const DataTableVinculados = function ({ planId, methodsRef }) {

    const { enqueueSnackbar } = useSnackbar();
    const { data_user, token } = useContext(BotonContext);
    /** @type {React.MutableRefObject<InstanceType<typeof DataTableWrapper>>} */
    const dataTableRef = useRef();
    /** @type {React.MutableRefObject<HTMLTableElement>} */
    const nodeRef = useRef();
    const [registroDesvincular, setRegistroDesvincular] = useState(/** @type {{ row: HTMLTableRowElement, registro: api.RegistroVinculación }} */(null));
    const [desvinculando, setDesvinculando] = useState(false);
    const [showModalDesvincular, setShowModalDesvincular] = useState(false);

    const update = function () {
        dataTableRef.current.clearAll();
        getVinculados();
    };
    methodsRef.current = { update };

    const actionButtonsTPL = () => {
        let html = '';
        html += `
        <div class="btn-group">
            <button class="btn btn-primary dropdown-toggle m-0" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" title="Acciones">
                <i class="ft-menu cursor-hand mb-0"></i>
            </button>
            <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                <a class="dropdown-item pl-2" role="action-button" data-action="remove">
                    <i class="ft-trash mb-0 mx-1"></i>
                    Desvincular
                </a>
            </div>
        </div>`;
        return html;
    };

    /**
     * @param {api.RegistroVinculación[]} data
     */
    const fillTable = function (data) {
        const rows = [];
        data.forEach((r) => {
            /** @type {(import("../util/common.ui.js").CustomDataTableCellData | string | number)[]} */
            let newRow = [
                {
                    showData: r.id_usuario_organizacion,
                    attachData: r
                },
                renderBaseValue(r.fecha_asignacion),
                renderBaseValue(r.identificacion),
                renderBaseValue(r.email),
                renderBaseValue(r.institucion),
                renderBaseValue(r.estado),
                renderBaseValue(r.cursos_iniciados),
                renderBaseValue(r.certificado),
                {
                    showData: actionButtonsTPL(),
                    onCreateCell(cell) {
                        const button = $(cell).find('.dropdown-toggle')[0];
                        const dropdown = new Dropdown(button, {
                            autoClose: true // FIX: not working
                        });
                        button.onclick = () => {
                            dropdown.toggle();
                        };
                    }
                }
            ];
            rows.push(newRow);
        });
        dataTableRef.current.addRows(rows);
        dataTableRef.current.draw();
    };

    const getVinculados = async function () {
        try {
            dataTableRef.current.showCustomLoader();
            const respuesta = await api.getVinculaciones({ id_usuario: data_user.id_usuario, token });
            if (respuesta.success) {
                if (respuesta.data.length) {
                    dataTableRef.current.removeCustomLoaderOrMessage();
                    fillTable(respuesta.data);
                } else {
                    dataTableRef.current.showCustomMessage(MESSAGE_NO_VINCULACIONES);
                }
            } else {
                throw new Error(respuesta.mensaje || respuesta.mensaje);
            }
        } catch (error) {
            enqueueSnackbar('Error en la descarga de vinculados', {
                variant: 'error'
            });
            console.error('Error: ' + error);
            dataTableRef.current.showCustomMessage(ERROR_DATA_DOWNLOAD);
        }
    };

    const doRemoverVinculado = async function () {
        setDesvinculando(true);
        const registro = registroDesvincular.registro;
        try {
            const respuesta = await api.removerVinculado({
                token, requestData: {
                    id_usuario: data_user.id_usuario,
                    id_usuario_organizacion: registro.id_usuario_organizacion,
                    email: registro.email,
                    id_organizacion: registro.id_organizacion,
                    id_producto: planId
                }
            });
            const mensaje = respuesta.data.mensaje || respuesta.data.message;
            if (respuesta.data.success) {
                setShowModalDesvincular(false);
                dataTableRef.current.removeRows([registroDesvincular.row]);
                dataTableRef.current.draw(false);
                enqueueSnackbar("Acción ejecuta con éxito", {
                    variant: 'success'
                });
            } else if (respuesta.status === 200) {
                enqueueSnackbar(mensaje, {
                    variant: 'warning'
                });
            }
            else {
                throw new Error(mensaje);
            }
        } catch (error) {
            enqueueSnackbar("Error al desvincular usuario", {
                variant: 'error'
            });
            console.log('Error: ' + error);
        } finally {
            setDesvinculando(false);
        }
    };

    /**
     * @param {HTMLElement} element
     * @returns {{ row: HTMLTableRowElement, registro: api.RegistroVinculación }}
     */
    const getRegistroFromActionElement = function (element) {
        const cell = element.parentElement.parentElement.parentElement; // FIX: eliminar dependencia de estructura DOM
        const row = /** @type {HTMLTableRowElement} */(cell.parentElement);
        return {
            registro: /** @type {api.RegistroVinculación} */(dataTableRef.current.getRowData(row)),
            row: row
        };
    };

    useEffect(() => {
        dataTableRef.current = new DataTableWrapper(nodeRef.current, [
            {
                title: 'Nº Asignación',
                classes: "text-center",
                useCustomCellData: true
            },
            {
                title: 'Fecha',
                classes: "text-center"
            },
            {
                title: 'Identificador',
                classes: "text-center"
            },
            {
                title: 'Email',
                classes: "text-center"
            },
            {
                title: 'Institución',
                classes: "text-center"
            },
            {
                title: 'Estado',
                classes: "text-center"
            },
            {
                title: 'Cursos iniciados',
                classes: "text-center"
            },
            {
                title: 'Certificados',
                classes: "text-center"
            },
            {
                title: 'Acciones',
                classes: "text-center",
                useCustomCellData: true
            }
        ], {
            dataTableOptions: {
                columnDefs: [
                    {
                        className: 'align-middle',
                        targets: [0, 1, 2, 3, 4, 5, 6, 7, 8]
                    },
                    {
                        className: 'text-center',
                        targets: [0, 1, 5, 6, 7]
                    },
                    {
                        orderable: false,
                        targets: [8]
                    }
                ]
            }
        });
        $(nodeRef.current).on('click', '[role="action-button"]', function () {
            const element = /** @type {HTMLElement} */(this);
            const registro = getRegistroFromActionElement(element);
            switch (element.dataset['action']) {
                case 'remove':
                    handleDesvincularMenuOption(registro);
                    break;
            }
        });
        $(document).on('click', function () {
            // fix para ocultar dropdowns.
            // TODO: conseguir mejor alternativa y no dejar este handler al salir de este módulo
            $(document).find('.dropdown-toggle:not(:focus)').each((i, element) => {
                new Dropdown(element).hide();
            });
        });
        dataTableRef.current.init();
        getVinculados();
    }, []);

    /**
     * @param {{ row: HTMLTableRowElement, registro: api.RegistroVinculación }} registro
     */
    const handleDesvincularMenuOption = function (registro) {
        if (registro.registro.certificado > 0) {
            enqueueSnackbar("No es posible eliminar la vinculación, el usuario posee certificados asociados", {
                variant: 'warning'
            });
            return;
        }
        setRegistroDesvincular(registro);
        setShowModalDesvincular(true);
    };

    const confirmationCancel = function () {
        if (desvinculando) return;
        setShowModalDesvincular(false);
    };

    const confirmationAccept = function () {
        doRemoverVinculado();
    };

    return (<>
        <div style={{ margin: '2rem 1rem', padding: '1rem', border: '1px solid gray', borderRadius: '2px' }} className="has-bootstrap" >
            <table ref={nodeRef} id="dataTableVinculados" className="table custom-sorter cursor-pointer table-striped table-bordered mt-1">
                <thead><tr></tr></thead>
                <tbody></tbody>
            </table>
        </div>
        <Dialog open={showModalDesvincular} style={{ cursor: 'default' }} onClose={confirmationCancel} disableScrollLock={true} >
            <DialogTitle id="form-dialog-title" onClose={confirmationCancel} className="modal_title" >Desvincular</DialogTitle>
            <DialogContent className="modal_content">
                <p>¿Está seguro que desea desvincular el siguiente usuario?</p>
                <ul style={{ marginBottom: '0.8rem' }}>
                    <li><span style={{ display: 'inline-block', minWidth: '5.6rem' }}>Email: </span><span style={{ fontWeight: 'bold' }}>{registroDesvincular?.registro?.email}</span></li>
                    <li><span style={{ display: 'inline-block', minWidth: '5.6rem' }}>Institución: </span><span style={{ fontWeight: 'bold' }}>{registroDesvincular?.registro?.institucion}</span></li>
                    <li><span style={{ display: 'inline-block', minWidth: '5.6rem' }}>Identificador: </span><span style={{ fontWeight: 'bold' }}>{registroDesvincular?.registro?.identificacion}</span></li>
                </ul>
            </DialogContent>
            <DialogActions className="modal_footer">
                <Button onClick={confirmationCancel} variant="contained" color="primary" className="button_cancel" disabled={desvinculando} >
                    Cancelar
                </Button>
                <Button onClick={confirmationAccept} variant="contained" color="primary" className="button_delete" disabled={desvinculando} >
                    {desvinculando
                        ?
                        <ButtonCircularProgress />
                        :
                        <DeleteForeverIcon style={{ marginRight: '4px', marginLeft: '-6px' }} />
                    }
                    Desvincular
                </Button>
            </DialogActions>
        </Dialog>
    </>);
};

/**
 * @typedef OrganizationData
 * @property {string} nombre
 * @property {string} identificador
 * @property {string} correo
 * @property {string} tlf
 * @property {number} country
 */

/**
 * @param {Object} obj
 * @param {(data: OrganizationData) => void} obj.onChange
 * @returns {JSX.Element}
 */
const FormCreateOrganization = function ({ onChange }) {
    const [nombre, setNombre] = useState('');
    const [identificador, setIdentificador] = useState('');
    const [correo, setCorreo] = useState('');
    const [tlf, setTlf] = useState('');
    const [country, setCountry] = useState(/** @type {number} */(null));
    const { countryList } = useContext(BotonContext);
    onChange({ nombre, identificador, correo, tlf, country });
    return <>
        <TextField id="nombre" label="Nombre institución"
            InputProps={{ className: "InputText" }}
            InputLabelProps={{ className: "InputText" }}
            maxRows={12} minRows={7} variant="outlined" fullWidth
            onChange={(e) => { setNombre(e.target.value); }}
            type="text"
            value={nombre}
            required
        />

        <TextField id="identificador" label="Identificador"
            InputProps={{ className: "InputText" }}
            InputLabelProps={{ className: "InputText" }}
            maxRows={12} minRows={7} variant="outlined" fullWidth
            onChange={(e) => { setIdentificador(e.target.value); }}
            type="text"
            value={identificador}
            required
        />

        <TextField id="correo" label="Correo"
            InputProps={{ className: "InputText" }}
            InputLabelProps={{ className: "InputText" }}
            maxRows={12} minRows={7} variant="outlined" fullWidth
            onChange={(e) => { setCorreo(e.target.value); }}
            type="text"
            value={correo}
            required
        />

        <TextField id="tlf" label="Teléfono"
            InputProps={{ className: "InputText" }}
            InputLabelProps={{ className: "InputText" }}
            maxRows={12} minRows={7} variant="outlined" fullWidth
            onChange={(e) => { setTlf(e.target.value); }}
            type="text"
            value={tlf}
            required
        />

        <FormControl variant="outlined">
            <InputLabel id="demo-simple-select-outlined-label" className="InputText">País</InputLabel>
            <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={country}
                onChange={(e) => setCountry(/** @type {number} */(e.target.value))}
                label="País"
                MenuProps={{ disableScrollLock: true }}
            >
                <MenuItem value="" ><em>Seleccionar</em></MenuItem>
                {countryList.map(p =>
                    <MenuItem value={p.id_pais} key={p.id_pais}>{p.nombre}</MenuItem>
                )}
            </Select>
        </FormControl>
    </>;
};

const HelpPopover = function () {
    const [anchorEl, setAnchorEl] = React.useState(/** @type {HTMLElement}*/(null));
    /**
     * @param {React.MouseEvent<HTMLElement>} event
     */
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    return <>
        <div>
            <div onClick={handleClick} style={{ marginTop: '-0.5rem', color: 'var(--color-always-colored)' }}>
                <HelpOutlineIcon style={{ cursor: 'pointer' }} />
            </div>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                }}
                className="font-medium-2"
                style={{ cursor: 'default', borderRadius: '3px' }}
                onClick={handleClose}
            >
                <div style={{ backgroundColor: '#d872186b', padding: '0.2rem 0.5rem' }}>Ayuda</div>
                <div style={{ padding: '0.3rem 0.5rem' }}>
                    • Puede pegar varios correos separados por comas directamente en el campo.<br />
                    • Puede editar con doble click un correo ya agregado.
                </div>
            </Popover>
        </div>
    </>;
};

/**
 * @param {Object} obj
 * @param {number} obj.tipo
 * @returns {JSX.Element}
 */
const IconResultado = function ({ tipo }) {
    let iconClass = '';
    let iconColor = '';
    switch (tipo) {
        case 1:
            iconClass = 'ft-check';
            iconColor = 'var(--bs-green)';
            break;
        case 2:
            iconClass = 'ft-info';
            iconColor = 'var(--bs-blue)';
            break;
        case 3:
            iconClass = 'ft-alert-octagon';
            iconColor = 'var(--bs-orange)';
            break;
    }
    return tipo <= 0 ?
        <span style={{ width: '1rem' }}></span>
        :
        <span style={{ width: '1rem', display: 'flex', alignItems: 'center' }}>
            <i className={iconClass} style={{ color: iconColor }}></i>
        </span>
        ;
};

/**
 * @param {Object} obj
 * @param {string} obj.label
 * @param {number} obj.value
 * @param {JSX.Element} [obj.post]
 * @returns {JSX.Element}
 */
const Total = function ({ label, value, post }) {
    return <>
        <div className="d-flex pl-2">
            <div className="d-flex justify-content-between" style={{ width: '14rem' }}>
                <span>• {label}: </span><span>{value}</span>
            </div>
            <span className="d-flex ml-2 d-flex align-items-center">{post || ''}</span>
        </div>
    </>;
};

/**
 * @param {Object} obj
 * @param {boolean} obj.show
 * @param {number} obj.organizationId
 * @param {(vinculacionesCount: number) => void} obj.handleCancelModal
 * @param {() => Promise<boolean>} obj.handleForcedCloseRequest
 * @returns {JSX.Element}
 */
const ModalVincular = function ({ show, organizationId, handleCancelModal, handleForcedCloseRequest }) {

    const { data_user, token } = useContext(BotonContext);
    const { enqueueSnackbar } = useSnackbar();
    const [vinculando, setVinculando] = useState(false);
    const [respuestaVincular, setRespuestaVincular] = useState(/** @type {api.RespuestaVincular} */(null));
    /** @type { React.MutableRefObject<InstanceType<typeof TagManager>>} */
    const tagifyRef = useRef();
    const vinculacionesCount = useRef(0);

    useEffect(() => {
        WebFont.load({
            custom: {
                families: ['feather']
            }
        });
    }, []);

    useEffect(() => {
        if (show) {
            setRespuestaVincular(null);
            vinculacionesCount.current = 0;
        }
    }, [show]);

    const handleClose = async function () {
        if (vinculando) return;
        if (tagifyRef.current.tagList.length) {
            const close = await handleForcedCloseRequest();
            if (!close) return;
        }
        handleCancelModal(vinculacionesCount.current);
    };

    /**
     * @returns {boolean}
     */
    const validar = function () {
        let error = "";
        if (tagifyRef.current.tagList.length > LIMITE_VINCULACIONES || tagifyRef.current.fullTagCount > LIMITE_VINCULACIONES) {
            error = `El límite por asignación es de ${LIMITE_VINCULACIONES} correos`;
        }
        else if (tagifyRef.current.hasInvalidTags) {
            error = 'Hay correos inválidos que debe remover';
        }
        else if (!tagifyRef.current.tagList.length) {
            error = 'Debe especificar los correos a asignar';
        }
        if (error) {
            enqueueSnackbar(error, {
                variant: 'warning'
            });
            return false;
        }
        return true;
    };

    const handleVincularMore = function () {
        setRespuestaVincular(null);
    };

    const handleVincular = async function () {
        if (vinculando || !validar()) return;
        const correos = tagifyRef.current.tagList;
        setVinculando(true);
        tagifyRef.current.setDisabled(true);
        try {
            const respuesta = await api.vincularPlan({
                token,
                requestData: {
                    emails: correos,
                    id_organizacion: organizationId,
                    id_tipo_producto: TIPO_PRODUCTO.PLAN,
                    id_usuario: data_user.id_usuario
                }
            });
            const mensaje = respuesta.data.mensaje || respuesta.data.message;
            if (respuesta.data.success) {
                tagifyRef.current.clear();
                vinculacionesCount.current += respuesta.data.data.estados.vinculados;
                setRespuestaVincular(respuesta.data.data);
            } else if (respuesta.status === 200) {
                enqueueSnackbar(mensaje, {
                    variant: 'warning'
                });
            } else {
                throw new Error(mensaje);
            }
        } catch (error) {
            console.log("Error: ", error);
            enqueueSnackbar("Error al ejecutar vinculaciones", {
                variant: 'error'
            });
        } finally {
            setVinculando(false);
            tagifyRef.current.setDisabled(false);
        }
    };

    return <>
        <Dialog open={show} onClose={handleClose} aria-labelledby="customized-dialog-title" disableScrollLock={true} style={{ cursor: 'default' }} fullWidth={true} maxWidth={"md"}>
            <DialogTitle id="form-dialog-title" onClose={handleClose} className="modal_title">Vincular</DialogTitle>
            <DialogContent className="modal_content">

                <div className="has-bootstrap" style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '1.1rem',
                    margin: '0.8rem 0px',
                    overflow: 'hidden', // overflow hidden resuelve un problema al usar campos de MUI donde hay un elemento oculto con un alto mayor al del propio campo
                    paddingTop: '0.3rem' // evitar que se oculte parte del primer label debido al overflow hidden
                }}>
                    <div id="panel-tag-manager" className="flex-column" style={{ display: respuestaVincular ? 'none' : undefined }}>
                        <div className="d-flex p-3 mt-1" style={{ gap: '1rem' }}>
                            <button id="limpiar-tags" type="button" className="btn btn-secondary m-0 d-flex align-items-center" title="Remover correos" style={{ height: '3.5rem' }} disabled={vinculando}
                                onClick={() => {
                                    tagifyRef.current.clear();
                                }}
                            >
                                <svg height="32" viewBox="0 0 32 32" width="32" xmlns="http://www.w3.org/2000/svg" style={{ fill: 'white' }}>
                                    <path d="m20 18h6v2h-6z" transform="matrix(-1 0 0 -1 46 38)" /><path d="m24 26h6v2h-6z" transform="matrix(-1 0 0 -1 54 54)" /><path d="m22 22h6v2h-6z" transform="matrix(-1 0 0 -1 50 46)" /><path d="m17.0029 20a4.8952 4.8952 0 0 0 -2.4044-4.1729l7.4015-12.8271-1.7309-1-7.5758 13.126a5.6988 5.6988 0 0 0 -5.2433 1.5029c-3.7436 3.6111-3.4537 12.0532-3.44 12.4111a1 1 0 0 0 1 .96h14.9912a1 1 0 0 0 .6-1.8c-3.5397-2.6561-3.5983-8.1463-3.5983-8.2zm-5.0729-3.0029a3.11 3.11 0 0 1 3.0741 3.0029c0 .0381.0019.208.0168.4688l-5.8994-2.6236a3.8 3.8 0 0 1 2.8085-.8481zm3.5194 11.0029a5.2 5.2 0 0 1 -1.4494-3h-2a6.4993 6.4993 0 0 0 .9684 3h-2.2233a16.6166 16.6166 0 0 1 -.7451-4h-2a17.3424 17.3424 0 0 0 .6652 4h-2.6652c.031-1.8364.29-5.8921 1.8027-8.5527l7.533 3.35a13.0253 13.0253 0 0 0 2.2611 5.2027z" /><path d="m0 0h32v32h-32z" fill="none" />
                                </svg>
                            </button>
                            <Tags tagifyRef={tagifyRef} />
                            <button id="asignar" type="button" className="btn btn-primary m-0 d-flex align-items-center" onClick={handleVincular} disabled={vinculando} >
                                {vinculando
                                    ?
                                    <ButtonCircularProgress />
                                    :
                                    <BoltIcon style={{ marginRight: '0.1rem', fontSize: '1.5rem', marginLeft: '-0.2rem' }} />
                                }
                                Vincular
                            </button>
                        </div>
                        <HelpPopover />
                    </div>
                    {respuestaVincular &&
                        <div id="panel-respuesta" className="flex-column p-3" style={{ gap: '1rem', display: 'flex' }}>
                            <div>
                                <div className="p-1" style={{ borderBottom: '1px #ffffff85 solid' }}>Resultado asignación</div>
                                <hr style={{ margin: 0 }} />
                                <div id="detalles" className="d-flex flex-column pt-2 pl-2" style={{ gap: '0.5rem' }}>
                                    {respuestaVincular?.vinculaciones?.map(v => {
                                        return (
                                            <div className="d-flex pl-2" key={v.id_usuario}>
                                                <div style={{ minWidth: '20rem' }} className="mr-1">{v.email}</div>
                                                <div className="d-flex" style={{ gap: '0.5rem' }}>
                                                    <IconResultado tipo={v.estado.tipo} />
                                                    <span data-tipo={v.estado.tipo}>{v.estado.estado}</span>
                                                </div>
                                            </div>);
                                    })}
                                </div>
                            </div>
                            <div>
                                <div className="p-1" style={{ borderBottom: '1px #ffffff85 solid' }}>Totales</div>
                                <hr style={{ margin: 0 }} />
                                <div id="totales" className="pt-2 pl-2">
                                    <Total label="Usuarios asignados" value={respuestaVincular.estados.vinculados} />
                                    <Total label="Previamente vinculados" value={respuestaVincular.estados.previamente_vinculados} />
                                    <Total label="Invitados" value={respuestaVincular.estados.invitados} />
                                    {respuestaVincular.estados.error
                                        ?
                                        <Total label="Errores" value={respuestaVincular.estados.error} post={<i className="ft-alert-octagon" style={{ color: 'var(--orange)' }}></i>} />
                                        :
                                        <Total label="Errores" value={respuestaVincular.estados.error} post={<i className="ft-check" style={{ color: 'var(--bs-green)' }}></i>} />
                                    }
                                    <Total label="Total" value={respuestaVincular.estados.total} />
                                </div>
                            </div>
                            <div className="d-flex justify-content-end">
                                <button id="more" type="button" className="btn btn-primary m-0 d-flex align-items-center" onClick={handleVincularMore}>
                                    <AddCircleOutlineOutlinedIcon style={{ paddingRight: '0.3rem', marginRight: '0.1rem' }} />
                                    Agregar Más
                                </button>
                            </div>
                        </div>
                    }
                </div>

            </DialogContent>
            <DialogActions className="modal_footer">
                <Button onClick={handleClose} variant="contained" color="primary" className="button_cancel" disabled={vinculando} >
                    Cancelar
                </Button>
            </DialogActions>
        </Dialog >
    </>;
};

export default function PlanManager(props) {

    const planId = props.match.params["planId"];
    const planName = props.match.params["planName"];

    const { data_user, token } = useContext(BotonContext);
    const { isLogged } = useSession();
    const { gotoLogin } = useRouteHelper();
    const { enqueueSnackbar } = useSnackbar();
    const [organizations, setOrganizations] = useState(/** @type {api.Organization[]} */(null));
    const [selectedOrgId, setSelectedOrgId] = useState(/** @type {number} */(null));
    const [showModalCreateOrg, setShowModalCreateOrg] = useState(false);
    const [showModalVincular, setShowModalVincular] = useState(false);
    const [confirmationDialog, setConfirmationDialog] = useState({
        show: false,
        title: '',
        /** @type {string | JSX.Element} */
        content: null,
        /** @type {() => void} */
        onAccept: null,
        /** @type {() => void} */
        onCancel: null,
        /** @type {string} */
        acceptClassName: null,
        /** @type {string} */
        acceptLabel: null
    });
    const organizationData = useRef(/** @type {OrganizationData} */({}));
    const [tagifyLoaded, setTagifyLoaded] = useState(false);
    const [modalBusy, setModalBusy] = useState(false);
    const logged = isLogged();
    /** @type {React.MutableRefObject<DataTableVinculadosMethods>} */
    const tableVinculadosRef = useRef();

    /**
     * @returns {Promise<api.Organization[]>}
     */
    const getOrganizations = async function () {
        try {
            const respuesta = await api.getOrganizations({
                id_usuario: data_user.id_usuario,
                token
            });
            if (!respuesta.success) throw new Error(respuesta.message || respuesta.mensaje);
            return respuesta.data;
        } catch (error) {
            console.error("Error: " + error);
            enqueueSnackbar("Error en la descarga de organizaciones", {
                variant: 'error'
            });
        }
    };

    /**
     * @return {boolean}
     */
    const validateCreateForm = function () {
        const data = organizationData.current;
        if (!data.correo || !data.nombre || !data.tlf || !data.identificador || typeof data.country !== 'number') {
            enqueueSnackbar("Debe especificar todos los datos de la organización", {
                variant: 'warning'
            });
            return false;
        }
        if (!commonIO.isValidEmail(data.correo)) {
            enqueueSnackbar("Debe especificar un correo válido", {
                variant: 'warning'
            });
            return false;
        }
        return true;
    };

    useEffect(function () {
        if (!logged) {
            gotoLogin();
            return;
        }
        getOrganizations().then((data) => {
            setOrganizations(data);
        });
    }, []);

    const handleCancelModal = function () {
        if (modalBusy) {
            enqueueSnackbar("Debe esperar a que finalize la acción actual", {
                variant: 'warning'
            });
            return;
        }
        setShowModalCreateOrg(false);
        setShowModalVincular(false);
    };

    const handleCrear = async function () {
        if (!validateCreateForm()) return;
        setModalBusy(true);
        const data = organizationData.current;
        try {
            const response = await api.createOrganization({
                requestData: {
                    id_usuario: data_user.id_usuario,
                    razon_social: data.nombre,
                    identificador: data.identificador,
                    id_pais: data.country,
                    email: data.correo,
                    telefono: data.tlf
                },
                token
            });
            const mensaje = response.data.mensaje || response.data.message;
            if (response.data.success) {
                getOrganizations().then((d) => {
                    setOrganizations(d);
                });
                enqueueSnackbar("Acción ejecuta con éxito", {
                    variant: 'success'
                });
                setShowModalCreateOrg(false);
            } else if (response.status === 200) {
                enqueueSnackbar(mensaje, {
                    variant: 'warning'
                });
            }
            else {
                throw new Error(mensaje);
            }
        } catch (error) {
            console.error("Error: " + error);
            enqueueSnackbar("Error al crear organización", {
                variant: 'error'
            });
        } finally {
            setModalBusy(false);
        }
    };

    const confirmationCancel = function () {
        confirmationDialog.onCancel();
        setConfirmationDialog({ ...confirmationDialog, show: false });
    };

    const confirmationAccept = function () {
        confirmationDialog.onAccept();
        setConfirmationDialog({ ...confirmationDialog, show: false });
    };

    const handleShowModalVincular = function () {
        if (!selectedOrgId) {
            enqueueSnackbar("Debe seleccionar una institución", {
                variant: 'warning'
            });
            return;
        }
        setShowModalVincular(true);
    };

    return (<>
        <ScriptTag type="text/javascript" src="/libs/tagify.js" onLoad={() => {
            setTagifyLoaded(true);
        }} />
        <div style={{ padding: '0px 2rem', marginTop: '90px' }}>

            <Breadcrumbs aria-label="breadcrumb" className="breadcrumbs_link_inneret">
                <Link color="inherit" onClick={null} className="breadcrumbs_link_inneret">
                    Mis Administraciones
                </Link>
                <Typography color="textPrimary" className="breadcrumbs_link">
                    {planName}
                </Typography>
            </Breadcrumbs>

            <div style={{ width: '100%', display: 'flex', alignItems: 'center', margin: '1rem', justifyContent: 'space-between' }}>

                <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>

                    <FormControl variant="outlined" style={{ width: '30rem' }}>
                        <InputLabel id="demo-simple-select-outlined-label" className="InputText">Institución</InputLabel>
                        <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            value={selectedOrgId}
                            onChange={(e) => setSelectedOrgId(/** @type {number} */(e.target.value))}
                            label="País"
                            MenuProps={{ disableScrollLock: true }}
                        >
                            <MenuItem value="" >
                                <em>Seleccionar</em>
                            </MenuItem>
                            {organizations && organizations.map(o =>
                                <MenuItem value={o.id} key={o.id}>{o.razon_social}</MenuItem>
                            )}
                        </Select>
                    </FormControl>

                    <Button type="button" id="create-organization" onClick={() => { setShowModalCreateOrg(true); }} variant="contained" color="primary" className="form__custom-button">
                        <AddCircleOutlineOutlinedIcon style={{ paddingRight: '0.3rem', marginRight: '0.1rem' }} />
                        Institución
                    </Button>

                </div>

                <div style={{ marginRight: '2rem' }}>

                    <Button type="button" id="vincular" onClick={handleShowModalVincular} variant="contained" color="primary" className="form__custom-button">
                        <AddCircleOutlineOutlinedIcon style={{ paddingRight: '0.3rem', marginRight: '0.1rem' }} />
                        Vincular
                    </Button>

                </div>

            </div>

            <div style={{ cursor: 'default', minHeight: '20rem' }}>
                <DataTableVinculados planId={planId} methodsRef={tableVinculadosRef} />
            </div>

            <Dialog open={showModalCreateOrg} onClose={handleCancelModal} aria-labelledby="customized-dialog-title" disableScrollLock={true} style={{ cursor: 'default' }}>
                <DialogTitle id="form-dialog-title" onClose={handleCancelModal} className="modal_title" >Crear institución</DialogTitle>
                <DialogContent className="modal_content">

                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '1.1rem',
                        margin: '0.8rem 0px',
                        overflow: 'hidden', // overflow hidden resuelve un problema al usar campos de MUI donde hay un elemento oculto con un alto mayor al del propio campo
                        paddingTop: '0.3rem' // evitar que se oculte parte del primer label debido al overflow hidden
                    }}>
                        <FormCreateOrganization onChange={data => {
                            organizationData.current = data;
                        }} />
                    </div>

                </DialogContent>
                <DialogActions className="modal_footer">
                    <Button onClick={handleCancelModal} variant="contained" color="primary" className="button_cancel" disabled={modalBusy} >
                        Cancelar
                    </Button>
                    <Button onClick={handleCrear} variant="contained" color="primary" className="button_accept" disabled={modalBusy} >
                        {modalBusy && <ButtonCircularProgress />}
                        Crear
                    </Button>
                </DialogActions>
            </Dialog>

            {tagifyLoaded && <ModalVincular
                show={showModalVincular}
                organizationId={selectedOrgId}
                handleCancelModal={(vinculacionesCount) => {
                    if (vinculacionesCount > 0) {
                        tableVinculadosRef.current.update();
                    }
                    handleCancelModal();
                }}
                handleForcedCloseRequest={() => {
                    return new Promise((resolve) => {
                        setConfirmationDialog({
                            show: true,
                            title: '¿Está seguro?',
                            content: <>¿Está seguro de querer cerrar el diálogo?<p>Se perderán los correos introducidos</p></>,
                            onAccept() {
                                resolve(true);
                            },
                            onCancel() {
                                resolve(false);
                            },
                            acceptClassName: 'button_delete',
                            acceptLabel: 'Sí, Cerrar'
                        });
                    });
                }}
            />}

            <Dialog open={confirmationDialog.show} style={{ cursor: 'default' }} onClose={confirmationCancel}>
                <DialogTitle id="form-dialog-title" onClose={confirmationCancel} className="modal_title" >{confirmationDialog.title}</DialogTitle>
                <DialogContent className="modal_content">
                    {confirmationDialog.content}
                </DialogContent>
                <DialogActions className="modal_footer">
                    <Button onClick={confirmationCancel} variant="contained" color="primary" className="button_cancel">
                        Cancelar
                    </Button>
                    <Button onClick={confirmationAccept} variant="contained" color="primary" className={confirmationDialog.acceptClassName || "button_accept"} >
                        {confirmationDialog.acceptLabel || "Aceptar"}
                    </Button>
                </DialogActions>
            </Dialog>

        </div>
    </>);
}
