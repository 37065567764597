import React, { useEffect, useState, useContext } from "react";
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import { deepOrange, deepPurple, blue, cyan, green, amber, red, pink, yellow, blueGrey, grey } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { useHistory } from "react-router";

//Breadcrumbs
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';

import AuthContext from "../AuthContext";

import Container from '@material-ui/core/Container';
import StarIcon from '@material-ui/icons/StarBorder';
import { Hidden } from "@material-ui/core";
import { theme as tema } from "../util/theme";
import i18n from "../lib/i18n";
import commonIO from "../util/common.io";
import api from "../lib/api";


const useStyles2 = makeStyles((theme) => ({
    '@global': {
        ul: {
            margin: 0,
            padding: 0,
            listStyle: 'none',
        },
    },
    appBar: {
        borderBottom: `1px solid`,
    },
    toolbar: {
        flexWrap: 'wrap',
    },
    toolbarTitle: {
        flexGrow: 1,
    },
    link: {
        //margin: theme.spacing(1, 1.5),
    },
    heroContent: {
        //padding: theme.spacing(8, 0, 6),
    },
    cardHeader: {
        color: '#fff',
        backgroundColor: tema.current === "light" ? '#d87218' : '#1a1a1a',
        //theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[700],
    },
    cardPricing: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'baseline'
    },
}));



const useStyles = makeStyles((theme) => ({
    card: {
        borderRadius: '1rem',
        boxShadow: 'none',
        position: 'relative',
        minWidth: 350,
        minHeight: 200,
        '&:after': {
            content: '""',
            display: 'block',
            position: 'absolute',
            width: '100%',
            height: '64%',
            bottom: 0,
            zIndex: 1,
            background: 'linear-gradient(to top, #000, rgba(0,0,0,0))',
        },
    },
    content: {
        position: 'absolute',
        zIndex: 2,
        bottom: 0,
        width: '100%',
    },
    root: {
        maxWidth: 345,
    },
    media: {
        height: '100%',
        width: '100%',
        objectFit: 'cover',
        paddingTop: '13%',
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    avatar: {
        backgroundColor: blue[900],
    },
}));

function SubscriptionPage(props) {

    const section = props.match.params.section;

    const history = useHistory();
    const data_user = JSON.parse(sessionStorage.getItem("user_data"));
    const token = sessionStorage.getItem("token");

    useEffect(() => {
        document.title = "Suscripciones";
    });

    const [myCourses, setMyCourses] = useState([])
    const classes = useStyles();
    const classes2 = useStyles2();
    const [expanded, setExpanded] = React.useState(false);
    const [mens, setMens] = useState(false);
    const { isAuth, isAuthTypeTutor } = useContext(AuthContext);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    const ValidarAuth = () => {

        const data_user = JSON.parse(sessionStorage.getItem("user_data"));
        const token = sessionStorage.getItem("token");

        if (isAuth && (token === null || token === 'null' || token === "" || token === undefined
            || data_user === null || data_user === 'null' || data_user === "" || data_user === undefined)) {
            localStorage.removeItem("cart");
            sessionStorage.clear();
            window.location.href = "#/login";
            window.location.reload();
        }

    };


    ValidarAuth();

    useEffect(() => {

        api.getSuscripciones(token, i18n.getCountry().id_pais)
            .then(data => {
                if (data.success === true) {
                    if (data.data.suscripcion.length > 0) {
                        setMyCourses(data.data.suscripcion);
                    } else {
                        setMens(true);
                    }
                }
                if (data.success === false && data.data === null) {
                    sessionStorage.setItem("token", null);
                    sessionStorage.setItem("user_data", null);
                    ValidarAuth();
                }
            });

    }, []);

    var myCourseList = [];
    var nCursosAlumno = 0
    if (myCourses) {
        nCursosAlumno = myCourses.length
    }
    //for (let i = 0; i < nCursosAlumno; i++) {
    /*
    let duration;
    if (myCourses[i].duration == null) {
        duration = "-"
    } else {
        duration = myCourses[i].duration
    }
    */

    let space;
    if (nCursosAlumno == 1) {
        space = 1;
    }
    if (nCursosAlumno == 2) {
        space = 2;
    }
    if (nCursosAlumno >= 3) {
        space = 3;
    }

    myCourseList.push(

        <div key="the_key">

            <Hidden smUp>

                <Container maxWidth="md" component="main">

                    <Typography component="h2" variant="h4" align="center" color="textPrimary" /*className="title_section s24 fontb c333"*/>
                        Suscripciones
                    </Typography>
                    <div style={{ height: '30px' }}></div>
                    <Typography variant="h5" align="center" color="textSecondary" component="p">
                        Lo invitamos a elegir una de nuestras suscripciones y así disfrutar de nuestros servicios premium.
                    </Typography>

                    <div style={{ height: '60px' }}></div>

                    <Grid container alignItems="center" alignContent="space-around" style={{ height: '100%' }} /*className="MuiCard-root-subscription"*/>
                        {myCourses.map((tier) => (
                            // Enterprise card is full width at sm breakpoint
                            <Grid item key={tier.id} xs={12} sm={(space === 1 || space === 2 ? 12 : 6)} md={(space === 1 || space === 2 ? 12 : 6)} lg={(space === 1 || space === 2 ? 12 : 4)} xl={(space === 1 || space === 2 ? 12 : 4)} >
                                <Card style={{ height: '100%' }} className="MuiCard-root-subscription">
                                    <CardHeader
                                        title={tier.titulo}
                                        //subheader={tier.subheader}
                                        titleTypographyProps={{ align: 'center' }}
                                        subheaderTypographyProps={{ align: 'center' }}
                                        action={tier.titulo === 'Pro' ? <StarIcon /> : null}
                                        className={classes2.cardHeader}
                                    />
                                    <CardContent className="MuiCardContent-root-subscription" style={{ height: '50%' }}>
                                        <div >
                                            <div className={classes2.cardPricing}>
                                                <Typography component="h2" variant="h3" color="textPrimary" className="MuiCardContent-root-subscription">
                                                    ${ commonIO.agregarSeparadorMiles(tier.precio) }
                                                </Typography>
                                                <Typography variant="h6" color="textPrimary" className="MuiCardContent-root-subscription">
                                                    /año
                                                </Typography>
                                            </div>
                                            <ul >
                                                <Typography component="li" variant="subtitle1" align="center" className="MuiCardContent-root-subscription">
                                                    {tier.descripcion}
                                                </Typography>
                                            </ul>
                                        </div>
                                        <div style={{ height: '30px' }}></div>


                                    </CardContent>

                                    <CardActions className="MuiCardContent-root-subscription" style={{ marginBottom: '20px' }} >
                                        <Button href={"#/detail-subscription/" + tier.id + "/" + "Suscripciones"} fullWidth variant="contained" color="primary" className="button_accept_subscription">
                                            Suscribir
                                        </Button>
                                    </CardActions>

                                </Card>
                                <div style={{ height: '50px' }}></div>
                            </Grid>

                        ))}
                    </Grid>

                </Container>

            </Hidden>

            <Hidden xsDown>

                <Container maxWidth="md" component="main">

                    <Typography component="h2" variant="h3" align="center" color="textPrimary" /*className="title_section s24 fontb c333"*/>
                        Suscripciones
                    </Typography>
                    <div style={{ height: '30px' }}></div>
                    <Typography variant="h5" align="center" color="textSecondary" component="p">
                        Lo invitamos a elegir una de nuestras suscripciones y así disfrutar de nuestros servicios premium.
                    </Typography>

                    <div style={{ height: '60px' }}></div>

                    <Grid container spacing={space} alignItems="center" alignContent="space-around" style={{ height: '100%' }} /*className="MuiCard-root-subscription"*/>
                        {myCourses.map((tier) => (
                            // Enterprise card is full width at sm breakpoint
                            <Grid item key={tier.id} xs={12} sm={(space === 1 || space === 2 ? 12 : 6)} md={(space === 1 || space === 2 ? 12 : 6)} lg={(space === 1 || space === 2 ? 12 : 4)} xl={(space === 1 || space === 2 ? 12 : 4)} >
                                <Card style={{ height: '100%' }} className="MuiCard-root-subscription">
                                    <CardHeader
                                        title={tier.titulo}
                                        //subheader={tier.subheader}
                                        titleTypographyProps={{ align: 'center' }}
                                        subheaderTypographyProps={{ align: 'center' }}
                                        action={tier.titulo === 'Pro' ? <StarIcon /> : null}
                                        className={classes2.cardHeader}
                                    />
                                    <CardContent className="MuiCardContent-root-subscription" style={{ height: '50%' }}>
                                        <div >
                                            <div className={classes2.cardPricing}>
                                                <Typography component="h2" variant="h3" color="textPrimary" className="MuiCardContent-root-subscription">
                                                    ${ commonIO.agregarSeparadorMiles(tier.precio) }
                                                </Typography>
                                                <Typography variant="h6" color="textPrimary" className="MuiCardContent-root-subscription">
                                                    /año
                                                </Typography>
                                            </div>
                                            <ul >
                                                <Typography component="li" variant="subtitle1" align="center" className="MuiCardContent-root-subscription">
                                                    {tier.descripcion}
                                                </Typography>
                                            </ul>
                                        </div>
                                        <div style={{ height: '30px' }}></div>


                                    </CardContent>

                                    <CardActions className="MuiCardContent-root-subscription" style={{ marginBottom: '20px' }} >
                                        <Button href={"#/detail-subscription/" + tier.id + "/" + "Suscripciones"} fullWidth variant="contained" color="primary" className="button_accept_subscription">
                                            Suscribir
                                        </Button>
                                    </CardActions>

                                </Card>
                                <div style={{ height: '50px' }}></div>
                            </Grid>

                        ))}
                    </Grid>

                </Container>

            </Hidden>

        </div>
        /* <div className="block rel" style={{
            background: "#757474 url( data:image/png;base64," + myCourses[i].imagen + ") no-repeat center"
        }}>
            <div className="user abs aic flex">
                <div className="meta rel">
                    <h2 className="s15 name fontb cfff">{myCourses[i].nombre_instructor} {myCourses[i].apellido_instructor}</h2>
                </div>
            </div>
            <div className="dura abs">
                <h2 className="s13 name fontb cfff">{duration}</h2>
            </div>
            <div className="course-title abs">
                <h2 className="s15 name fontb cfff">{myCourses[i].titulo}</h2>
            </div>
        </div> */
    );
    //}


    function handleClickCursoSection(event) {
        event.preventDefault();
        history.goBack();
        //href={"#/my-courses"}
    }



    return (
        <div className="App rel">

            <Breadcrumbs aria-label="breadcrumb" className="breadcrumbs_link_inneret">

                <Link color="inherit" onClick={handleClickCursoSection} className="breadcrumbs_link_inneret">
                    {section}
                </Link>
                <Typography color="textPrimary" className="breadcrumbs_link">
                    Suscripciones
                </Typography>
            </Breadcrumbs>

            <div className="tituloInicial rel">
            <h1 className="title_section s24 fontb c333">Suscripciones</h1>
            </div>
            <div className="section section-b rel">
                <div className="courses rel flex">


                    {myCourseList && myCourseList != null && myCourseList != '' && myCourseList != undefined &&
                        <Grid container spacing={3} >



                            <Grid item xs={1} sm={1} md={3} lg={1} xl={2}  >


                            </Grid>

                            <Grid item xs={12} sm={10} md={6} lg={10} xl={8}>

                                {myCourseList}

                            </Grid>

                            <Grid item xs={1} sm={1} md={3} lg={1} xl={2}  >


                            </Grid>

                        </Grid>
                    }

                    {mens === true &&

                        <Grid container spacing={3}>



                            <Grid item xs={1} sm={1} md={2} lg={3} xl={3}  >


                            </Grid>

                            <Grid item xs={10} sm={10} md={8} lg={6} xl={6}>

                                <div style={{ height: '100px' }}></div>

                                <div style={{ display: 'flex', justifyContent: 'center' }} className="title_section s30 fontb c333">¡ Aún no existen suscripciones cargadas !</div>

                                <div style={{ height: '100px' }}></div>

                            </Grid>

                            <Grid item xs={1} sm={1} md={2} lg={3} xl={3}  >


                            </Grid>

                        </Grid>

                    }

                </div>
            </div>
        </div>

    );
}

export default SubscriptionPage;
