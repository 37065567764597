import React, { useState, useEffect, useContext } from "react";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import Grid from '@material-ui/core/Grid';
import Carousel from "react-elastic-carousel";
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { useHistory } from "react-router";
import CircularProgress from '@material-ui/core/CircularProgress';
import Container from '@material-ui/core/Container';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useSnackbar } from "notistack";

import { BotonContext } from "../BotonContext";
import TarjetaEcampus from "../componentes/TarjetaEcampus";

import commonIO from '../util/common.io';
import i18n from "../lib/i18n";
import utils from "../util/utils";
import { isStructureUpdated } from "../lib/api";
import { TIPO_PRODUCTO, TagStyles } from "../lib/constants";

import routeHelper from "../util/routeHelper";

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > * + *': {
      marginLeft: theme.spacing(2)
    }
  }
}));

export default function DetailMultiuserPage (props) {

  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { data_user, token } = useContext(BotonContext);
  const courseID = props.match.params.certifiedid;

  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('xs'));

  useEffect(() => {
    document.title = "Planes multiusuario";
  });



  const gotoForm = function () {
    history.push(routeHelper.formaContactoPrograma);
  };

  const userID = data_user?.id_usuario;

  const classes = useStyles();

  const [openload, setOpenLoad] = React.useState(true);

  const handleCloseLoad = () => {
    setOpenLoad(false);
  };

  const [curso, setCurso] = useState([]);
  const [ruta, setRuta] = useState([]);

  useEffect(() => {
    fetch(process.env.REACT_APP_URL_API + '/plan-detalle/' + courseID+ '?id_pais=' + i18n.getCountry().id_pais, {
      method: 'get',
      headers: {
        'token': token,
        'Content-Type': 'application/json'
      }
    })
      .then(response => response.json()
        .then(data => {
          if (data.success === true) {
            setCurso(data.data)
            setRuta(data.data.ruta_aprendizaje);
            setOpenLoad(false);
          } else {
            setMensajeError(data.mensaje);
            setOpenSnackbarError(true);
            setOpenLoad(false);
            let intervalId = setInterval(() => {
              history.goBack();
              clearInterval(intervalId);
            }, 2000);
          }
        })
      )

  }, []);

  const { cart, setCart } = useContext(BotonContext);

  let newCart = (cart !== null) ? cart.slice(0,) : [];
  let carting;

  function addCart(id) {

    let newCart = (cart !== null) ? cart.slice(0,) : [];

    if (cart !== null && cart.filter(course => course.id === curso.id).length > 0 && cart.filter(course => course.tipo === 2).length > 0) {
      // item ya existente (aumentar cantidad)
      newCart.map((course, i) => {
        if (course.id === curso.id) {
          newCart[i].quantity += 0; //1
          handleClickOpen();
        }
      });
    } else {
      // item nuevo
      curso.length = 1;
      for (let i = 0; i < curso.length; i++) {
        if (id === id) {
          newCart.push({
              id: curso.id,
              name: curso.titulo,
              price: curso.precio,
              img: curso.imagen,
              discount: curso.precio,
              quantity: 1,
              tipo: TIPO_PRODUCTO.PLAN,
              ruta: 'detail-certified', //añadido nuevo para redirigir por la imagen del diplomado
              param: 'Inicio',          //añadido nuevo para redirigir por el titulo del diplomado
              listado: curso.lista_curso
            }
          );
        }
      }
      enqueueSnackbar('Item agregado al carro de compras', {
        variant: 'success'
      });
    }
    setCart(newCart);
    localStorage.setItem('cart', JSON.stringify(newCart));

  }

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const storage = localStorage.getItem('cart');
    setCart((storage !== null) ? JSON.parse(storage) : []);
  }, []);

  const [pathname, setPathname] = useState(window.location.pathname.slice(18,));

  const [cartQuantity, setCartQuantity] = useState();

  useEffect(() => {

    const inputVal = pathname.split('-').join(' ');
    let quantity = 0;
    carting = newCart;

    if (carting !== null && carting.length > 0) {
      carting.map(course => quantity += course.quantity);
    }

    setCartQuantity(quantity);

    if (window.location.pathname.includes('search')) {
      document.getElementsByTagName('input').item(0).setAttribute('value', inputVal);
      document.getElementsByTagName('input').item(1).setAttribute('value', inputVal);
    }

  });

  const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

  const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });

  const [openSnackbarError, setOpenSnackbarError] = React.useState(false);
  const [mensajeerror, setMensajeError] = useState("");
  const [openSnackbarSuccessMens, setOpenSnackbarSuccessMens] = React.useState(false);
  const [mensajesuccess, setMensajeSuccess] = useState("");

  const handleCloseSnackbarError = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbarError(false);
  };

  const handleCloseSnackbarSuccessMens = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbarSuccessMens(false);
  };

  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 600, itemsToShow: 2 },
    { width: 960, itemsToShow: 3 },
    { width: 1280, itemsToShow: 4 },
    { width: 1920, itemsToShow: 5 }
  ];

  const [diplomados, setDiplomados] = useState([]);

  useEffect(() => {
    fetch(process.env.REACT_APP_URL_API + '/diplomados')
      .then(response => response.json()
        .then(data => {
          setDiplomados(data.data.diplomados);
          if (data.success === false) {
          }
        })
        .catch(function (error) {
          const respuesta = error;
          console.log("Error:", respuesta);
        })
      )
  }, []);


  var diplomadosList = [];

  if (ruta && ruta != '' && ruta !== undefined && ruta != null) {

    if (ruta.length) {
      diplomadosList.push(
        <hr size="1" style={{ marginTop: '4rem' }} key={ruta.length}/>
      );
    }

    diplomadosList.push(
      <div key={curso.id + 'a'} className="hide-on-xxs">
        <h2 className="title_section s24 fontb sub-section">Ruta de aprendizaje</h2>

        <div className="home-page rel">
          <div className="extra-block rel">
            <div className="courses rel flex">
              <Grid container spacing={1} direction="row">
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}  >
                  <Carousel breakPoints={breakPoints} enableAutoPlay={true} autoPlaySpeed={3000}>
                    {/* <!-- tarjetas cursos --> */}
                    {ruta.map(item => {
                      return <Container className="carrusel-contenedor-tarjeta" key={item['id']}>
                        <Grid container spacing={3} alignContent="center" alignItems="center">
                          <TarjetaEcampus
                            image={utils.getImagenCurso(item)}
                            text1={commonIO.shortenText(item['titulo'], smallScreen ? 60 : 72)}
                            text2={item['instructor']}
                            href={"/course/" + item['id'] + "/" + "Plan%20multiusuario"}
                            price={item['precio']}
                            showTag={isStructureUpdated(item)}
                            tagOptions={TagStyles.TAG_ACTUALIZADO}
                          />
                        </Grid>
                      </Container>;
                    })}

                  </Carousel>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>

      </div>

    );
  }

  function handleClickMultiuser(event) {
    event.preventDefault();
    history.push("/multiuser");
  }

  let botonesDiplomado;
  if (curso.tipo_plan === 2){
    botonesDiplomado = <>
        <Button variant="contained" style={{ float: 'right', width: '100%' }} color="secondary" className="button_accept aic" onClick={gotoForm}>
          <b>Ir a formulario</b>&nbsp; &nbsp;
          <AddShoppingCartIcon />
        </Button>
      </>;
  } else {
    botonesDiplomado = <>
        <Button variant="contained" style={{ float: 'right', width: '100%' }} color="secondary" className="button_accept aic" onClick={() => addCart(courseID)}>
          <b>Agregar plan</b>&nbsp; &nbsp;
          <AddShoppingCartIcon />
        </Button>
      </>;
  }

  const imagenDiplomado = <img width="100%" src={utils.getImagenCurso(curso.imagen)} alt="imagen curso" />;

  return (

    <div className="App rel">
      <Breadcrumbs aria-label="breadcrumb" className="breadcrumbs_link_inneret">

        <Link color="inherit" onClick={handleClickMultiuser} className="breadcrumbs_link_inneret">
          Planes multiusuario
        </Link>
        <Typography color="textPrimary" className="breadcrumbs_link">
          {curso.titulo}
        </Typography>
      </Breadcrumbs>

      <Snackbar open={openSnackbarError} autoHideDuration={3000} onClose={handleCloseSnackbarError} /*style = {styles.snakbar}*/ anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
        <Alert severity="error" variant="filled" >
          {mensajeerror}
        </Alert>
      </Snackbar>

      <Snackbar open={openSnackbarSuccessMens} autoHideDuration={3000} onClose={handleCloseSnackbarSuccessMens} /*style = {styles.snakbar}*/ anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
        <Alert severity="success" variant="filled" >
          {mensajesuccess}
        </Alert>
      </Snackbar>

      <Grid container className="primary-column">

        <h1 className="title_section s24 fontb c333 hide-on-xs">{curso.titulo}</h1>
        <h2 className="title_section sub-title only-on-xs always-colored">{curso.titulo}</h2>
        <Grid item xs={12} sm={8} md={8} lg={8} xl={9} >
          <div className="section" >
            <div className="extras-block">

              <div className="only-on-xs">{ imagenDiplomado }</div>
              <div className="only-on-xs">
                { botonesDiplomado }
              </div>

              {/* <div className="tutor rel aic flex">
                <p className="contenido-label-detalle">
                  <span className="content-highlighted-label">Instructor: </span><span style={{ fontStyle: "italic" }}>{ curso.instructor || 'N/A' }</span>
                </p>
              </div> */}

              <div className="course-meta">
                <h2 className="title_section sub-title hide-on-xs always-colored">Descripción</h2>
                <p className="content-description jtx" dangerouslySetInnerHTML={{ __html: curso.descripcion || 'Plan sin descripción.' }} />
                { diplomadosList }
              </div>

            </div>
          </div>
        </Grid>

        <Grid item sm={4} md={4} lg={4} xl={3} className="secondary-column" >

          <div className="section" style={{ marginTop: '-25px' }}>
            <div className="extras-block">
              { imagenDiplomado }
              <div className="section stacked-block">
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                  {botonesDiplomado}
                </Grid>
                <Dialog
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="customized-dialog-title"
                >
                  <DialogTitle id="alert-dialog-title" onClose={handleClose} className="modal_title">{"Advertencia!"}</DialogTitle>
                  <DialogContent dividers className="modal_content">

                    <Typography gutterBottom>
                      Este plan ya se encuentra en su carro de compras.
                    </Typography>

                  </DialogContent>

                  <DialogActions className="modal_footer">
                    <Button onClick={handleClose} variant="contained" className="button_cancel">
                      Cancelar
                    </Button>
                  </DialogActions>
                </Dialog>

              </div>
            </div>
          </div>


        </Grid>
      </Grid>

      <div id="cargando" className={classes.root}>
        <Dialog
          open={openload}
          onClose={handleCloseLoad}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent className="CentrarLoading">
            <CircularProgress className="CircularProgress" />
          </DialogContent>
        </Dialog>
      </div>

    </div>

  );

}
