//=========================================================================================
// FLAGS
//=========================================================================================
export const FLAGS = {
    DISABLE_PROGRAMS: true
};

//=========================================================================================
// RIBBONS / FLAGS
//=========================================================================================
/** @type {import("../componentes/TarjetaEcampus").TagOptions} */
const RIBBON_PUBLISHED = {
    text: 'Publicado',
    fontSize: '11px',
    textColor: 'white',
    color: '#288ed2'
};

/** @type {import("../componentes/TarjetaEcampus").TagOptions} */
const RIBBON_EDITED = {
    text: 'Editado',
    fontSize: '11px',
    textColor: 'white',
    color: '#F47530'
};

export const RibbonStyles = {
    RIBBON_PUBLISHED, RIBBON_EDITED
};

/** @type {import("../componentes/TarjetaEcampus").TagOptions} */
const TAG_ACTUALIZADO = {
    text: 'Actualizado',
    fontSize: '0.7rem',
    textColor: 'white',
    color: '#288ed2',
    width: 72,
    style: {
        fontWeight: 'bold'
    }
};

export const TagStyles = {
    TAG_ACTUALIZADO
};

//=========================================================================================
// TIPO RECURSOS
//=========================================================================================
export const TIPO_CLASE = {
    VIDEO: 1,
    ZIP: 2,
    TEST: 3,
    TEST_FORMATIVO: 4
};

/**
 * @type {{[x: number | string]: string}}
 */
const _NOMBRE_TIPO_CLASE = {
    [TIPO_CLASE.VIDEO]: "Video",
    [TIPO_CLASE.ZIP]: "Recursos",
    [TIPO_CLASE.TEST]: "Test",
    [TIPO_CLASE.TEST_FORMATIVO]: "Evaluación final"
};

export const NOMBRE_TIPO_CLASE = new Proxy(_NOMBRE_TIPO_CLASE, {
    get(target, prop) {
        if (typeof prop === 'symbol') return '- tipo inválido -';
        return Object.hasOwn(target, prop) ? target[prop] : '- tipo no definido -';
    }
});

//=========================================================================================
// ESTADOS
//=========================================================================================
export const ESTADO_CURSO = {
    INICIAL: 0,
    PUBLICADO: 1
};

//=========================================================================================
// CLASES / EVALUACIONES / RECURSOS CLASE
//=========================================================================================
export const TIPO_PREGUNTA = {
    VERDADERO_FALSO: 1,
    SELECCION_MULTIPLE: 2
};

export const TIPO_EVALUATION = {
    TEST: 1,
    TEST_FINAL: 2
};

export const TIPO_RECURSO = {
    video: 1,
    zip: 2
};

//=========================================================================================
// PLANES
//=========================================================================================
export const TIPO_PLAN = {
    STANDARD: 1,
    FULL: 2
};

//=========================================================================================
// CARRITO
//=========================================================================================
export const TIPO_PRODUCTO = {
    CURSO: 1,
    PROGRAMA: 2,
    SUBSCRIPTION: 3,
    PLAN: 4
};

//=========================================================================================
// USUARIO
//=========================================================================================
export const TIPO_USUARIO = {
    USER: 1,
    AUTHOR: 2
};
