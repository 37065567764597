import React from "react";
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import { Divider } from "@material-ui/core";
import AccessTime from '@material-ui/icons/AccessTime';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';
import commonIO from "../util/common.io";
import { TIPO_CLASE } from "../lib/constants";

const SHOW_CHECKBOX_UNIDAD = false;
const SHOW_TITULO_UNIDAD_AUTO_INCREMENTAL = false;
const SUM_NON_VISIBLE_CLASES = false;

const _SHOW_DURATION = {
    [TIPO_CLASE.VIDEO]: true,
    [TIPO_CLASE.TEST]: false,
    [TIPO_CLASE.TEST_FORMATIVO]: false,
    [TIPO_CLASE.ZIP]: false
};
const SHOW_DURATION = new Proxy(_SHOW_DURATION, {
    get(target, prop) {
        return Object.hasOwn(target, prop) ? target[prop] : false;
    }
});

const useStyles = makeStyles((theme) => ({
    heading: {
        fontSize: theme.typography.pxToRem(15)
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15)
    },
    thirdHeading: {
        fontSize: theme.typography.pxToRem(14)
    }
}));

/**
 * @param {Object} params
 * @param {(currentValue: boolean) => void} params.handleClick
 * @param {boolean} params.value
 * @param {boolean} [params.disabled=false] - Default = false
 * @returns {JSX.Element}
 */
const CheckboxClase = function ({ handleClick, value, disabled = false }) {
    /**
     * @param {React.MouseEvent} e
     */
    const onClick = function (e) {
        e.preventDefault();
        e.stopPropagation();
        handleClick(value);
    };
    return <Checkbox checked={value} className="checkbox2" onClick={onClick} disabled={disabled} />;
};

/**
 * @param {Object} params
 * @param {boolean} params.publicAccordion
 * @param {api.Clase} params.clase
 * @param {boolean} params.disabledCheckboxes
 * @param {boolean} [params.selected]
 * @param {(currentValue: boolean, clase: api.Clase) => void} params.handleCheckboxClick - debe retornar el nuevo valor del check
 * @returns {JSX.Element}
 */
const Clase = function ({ publicAccordion, clase, disabledCheckboxes, selected, handleCheckboxClick }) {

    const clasesEstilo = useStyles();

    return <div className="meta rel h1 span span svg interactive-accordion-item" >
        <Divider />
        <AccordionSummary className="container-detalle-unidad" aria-controls="additional-actions1-content" id="additional-actions1-header" >
            <div>
                <Typography className={clasesEstilo.heading} variant="subtitle1" gutterBottom style={{ minWidth: '10px', flexShrink: 0 }}>
                    {!publicAccordion &&
                        <CheckboxClase
                            handleClick={(currentValue) => {
                                typeof handleCheckboxClick === 'function' && handleCheckboxClick(currentValue, clase);
                            }}
                            value={clase['estado'] == 1}
                            disabled={disabledCheckboxes}
                        />
                    }
                </Typography>
                <Typography id={clase.id.toString()} className={[clasesEstilo.secondaryHeading, "s15 subtitle_title_accordion", selected ? 'AccordionDetails-selected' : ''].join(' ')} variant="subtitle2" gutterBottom>
                    {clase['titulo']}
                </Typography>
            </div>
            <Typography
                id={(clase['id'] + 'a')}
                style={{ padding: '5px', margin: 0, alignItems: 'center', minWidth: '5.9rem', justifyContent: 'right' }}
                className={[clasesEstilo.thirdHeading, "s15 subtitle_title_accordion fontn color_p_accordion"].join(' ')}
                variant="subtitle2"
                gutterBottom
            >
                {SHOW_DURATION[clase.param_tipo]
                    ? <><AccessTime fontSize="small" style={{ marginBottom: '0px', marginRight: '0.4rem' }} />{commonIO.convertMS(clase['duracion'])}</>
                    : <div>-</div>
                }
            </Typography>
        </AccordionSummary>
    </div>;
};

/**
 * @param {Object} params
 * @param {boolean} params.publicAccordion
 * @param {api.Unidad} params.unidad
 * @param {number} params.index
 * @param {number} params.courseId
 * @param {number} [params.certifiedId]
 * @param {string} params.section
 * @param {boolean} [params.defaultExpanded=false] - Default = false
 * @param {boolean} [params.disabledCheckboxes=false] - Default = false
 * @param {number} [params.idSelectedClass]
 * @param {(clase_id: number) => void} [params.handleClaseClick] - NOTA: este handle es independiente del href que abre la clase al hacer click. No lo sustituye. Es un handler adicional. NOTA: por ahora no aplica si `publicAccordion` = true (porque se desactivan los eventos del puntero para evitar interacción con el hipervínculo (<a/>)).
 * @param {(currentValue: boolean, clase: api.Clase) => void} [params.handleCheckboxClick] - debe retornar el nuevo valor del check
 * @returns {JSX.Element}
 * @todo separar versión pública (read-only) y la versión para cursos comprados. Para simplificar manejo de tipos, parámetros y evitar errores. Simplificaría usar la interfaz "MyClase" (en lugar de "Clase") para la versión privada.
 */
export default function AccordionUnidad({ publicAccordion, unidad, index, courseId, certifiedId, section, defaultExpanded = false, disabledCheckboxes = false, idSelectedClass, handleClaseClick, handleCheckboxClick }) {

    if (!publicAccordion && !certifiedId && certifiedId !== 0) {
        throw new Error('certifiedId required');
    }

    const clasesVistas = unidad.clases.map(clase => clase['estado']).reduce((a, v) => a + v, 0);
    const clases_totales = unidad.n_clases;
    const duracion_unidad = unidad.clases.reduce((a, clase) => {
        return a + (SUM_NON_VISIBLE_CLASES || SHOW_DURATION[clase.param_tipo] ? clase.duracion : 0);
    }, 0);
    const checked_unit = (clasesVistas === clases_totales);

    const clasesEstilo = useStyles();

    return (
        <Grid container>

            <div key={unidad.id_unidad} style={{ width: '100%' }}>

                <Accordion className="InputText" defaultExpanded={defaultExpanded} square={false} elevation={5} >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon className="InputText" />}
                        aria-label="Expand"
                        aria-controls="additional-actions1-content"
                        id="additional-actions1-header"
                    >
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                            <Typography className={clasesEstilo.heading} variant="subtitle1" gutterBottom style={{ flexShrink: 0 }}>
                                <div className="jtx">
                                    {SHOW_CHECKBOX_UNIDAD && !publicAccordion && (<Checkbox checked={checked_unit} disabled className="checkbox2" />)}
                                    {SHOW_TITULO_UNIDAD_AUTO_INCREMENTAL && (`Unidad ${index + 1}:`)}
                                </div>
                            </Typography>

                            <Typography style={{ padding: '10px' }} className={clasesEstilo.secondaryHeading} variant="subtitle2" gutterBottom>
                                <div>
                                    {unidad.nombre_unidad}
                                </div>
                            </Typography>

                            {!publicAccordion &&
                                <Typography className={clasesEstilo.thirdHeading} variant="subtitle2" gutterBottom style={{ textAlign: 'right' }}>
                                    <div>
                                        {clasesVistas}/{clases_totales} &nbsp;{commonIO.convertMS(duracion_unidad)}
                                    </div>
                                </Typography>
                            }

                        </Grid>
                    </AccordionSummary>

                    <AccordionDetails>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                            <div style={{ width: '100%' }}>
                                {unidad.clases.map(clase => {
                                    const href = publicAccordion ? '' : '#/class/' + courseId + '/' + clase['id'] + '/' + clase['id_unidad'] + '/' + section + '/' + certifiedId;
                                    return <a
                                        href={href}
                                        key={"course-video-" + clase['id']}
                                        onClick={typeof handleClaseClick === 'function' ? () => handleClaseClick(clase.id) : null}
                                        className="noul aic rel"
                                        style={{ pointerEvents: publicAccordion ? 'none' : undefined }}
                                    >
                                        <Clase
                                            publicAccordion={publicAccordion}
                                            clase={clase}
                                            handleCheckboxClick={handleCheckboxClick}
                                            disabledCheckboxes={disabledCheckboxes}
                                            selected={idSelectedClass === clase.id}
                                        />
                                    </a>;
                                })}
                            </div>
                        </Grid>
                    </AccordionDetails>
                </Accordion>

            </div>
        </Grid>
    );
}

/**
 * @param {Object} params
 * @param {boolean} params.publicAccordion
 * @param {api.Unidad[]} params.unidades
 * @param {number} params.courseId
 * @param {number} [params.certifiedId]
 * @param {string} params.section
 * @param {boolean} [params.defaultExpanded=false] - Default = false
 * @param {boolean} [params.disabledCheckboxes=false] - Default = false
 * @param {number} [params.idSelectedClass]
 * @param {(clase_id: number) => void} [params.handleClaseClick] - NOTA: este handle es independiente del href que abre la clase al hacer click. No lo sustituye. Es un handler adicional.
 * @param {(currentValue: boolean, clase: api.Clase) => void} [params.handleCheckboxClick] - debe retornar el nuevo valor del check
 * @returns {JSX.Element}
 * @todo separar versión pública (read-only) y la versión para cursos comprados. Para simplificar manejo de tipos, parámetros y evitar errores. Simplificaría usar la interfaz "MyClase" (en lugar de "Clase") para la versión privada.
 */
export function ListaAcordeonesUnidad({ publicAccordion, unidades, courseId, certifiedId, section, defaultExpanded = false, disabledCheckboxes = false, idSelectedClass, handleClaseClick, handleCheckboxClick }) {
    return (<>
        {
            unidades.map((unidad, i) => {
                return <AccordionUnidad
                    publicAccordion={publicAccordion}
                    key={unidad.id_unidad + "i"}
                    unidad={unidad}
                    index={i}
                    certifiedId={certifiedId}
                    courseId={courseId}
                    section={section}
                    disabledCheckboxes={disabledCheckboxes}
                    handleClaseClick={handleClaseClick}
                    handleCheckboxClick={handleCheckboxClick}
                    defaultExpanded={defaultExpanded}
                    idSelectedClass={idSelectedClass}
                />;
            })
        }
    </>);
}
