import React, { useEffect, useState, useContext } from "react";
import { makeStyles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import { Hidden } from "@material-ui/core";
import DialogContent from '@material-ui/core/DialogContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import Container from '@material-ui/core/Container';

import AuthContext from "../AuthContext";
import TarjetaEcampus from "../componentes/TarjetaEcampus";

import api, { isStructureUpdated } from "../lib/api";
import i18n, { strings } from "../lib/i18n";
import utils from "../util/utils";
import { TagStyles } from "../lib/constants";


const useStyles3 = makeStyles({
    root: {
        display: 'flex'
    }
});

function CertifiedPage() {

    const data_user = JSON.parse(sessionStorage.getItem("user_data"));
    const token = sessionStorage.getItem("token");

    useEffect(() => {
        document.title = "Programas";
    });

    const [myCourses, setMyCourses] = useState([]);
    const classes3 = useStyles3();
    const [mens, setMens] = useState(false);
    const { isAuth, isAuthTypeTutor } = useContext(AuthContext);

    const [disableLoadingDiplomados, setDisableLoadingDiplomados] = useState(false);

    const getDiplomados = function (userId) {
        api.getDiplomados(userId, token, i18n.getCountry().id_pais)
            .then(data => {
                if (data.success === true) {
                    if (data.data.diplomados.length > 0) {
                        setMyCourses(data.data.diplomados);
                    } else {
                        setMens(true);
                    }
                }
                if (data.success === false && data.data === null) {
                    sessionStorage.setItem("token", null);
                    sessionStorage.setItem("user_data", null);
                    ValidarAuth();
                }
                setDisableLoadingDiplomados(true);
            });
    };

    useEffect(() => {

        if (data_user && data_user !== '' && data_user !== undefined && data_user !== null) {

            const userID = data_user.id_usuario;

            api.getUserSubscription(userID, token)
                .then(data => {
                    if (data.success === true) {
                        const data_usuario = data.data.usuario;
                        sessionStorage.setItem("user_data", JSON.stringify(data_usuario));
                    }
                    if (data.success === false && data.data === null) {
                        sessionStorage.setItem("token", null);
                        sessionStorage.setItem("user_data", null);
                        //ValidarAuth();
                    }
                    if (data.success === false) {
                        sessionStorage.setItem("token", null);
                        sessionStorage.setItem("user_data", null);
                    }
                })
                .catch(function (error) {
                    const respuesta = error;
                    console.log("Error:", respuesta);
                });

            getDiplomados(userID);

        } else {
            const userID = '';
            getDiplomados(userID);
        }

    }, []);

    const ValidarAuth = () => {

        const data_user = JSON.parse(sessionStorage.getItem("user_data"));
        const token = sessionStorage.getItem("token");

        if (isAuth && (token === null || token === 'null' || token === "" || token === undefined
            || data_user === null || data_user === 'null' || data_user === "" || data_user === undefined)) {

            localStorage.removeItem("cart");
            sessionStorage.clear();
            window.location.href = "#/login";
            window.location.reload();
        }

    };

    ValidarAuth();
    //var userID = data_user.id_usuario;  //La fecha de inscripcion del curso no puede ser: 0000-00-00 00:00:00

    function limitarcaracteres(value) {
        if (value.length > 72) {
            value = value.slice(0, 72) + '...';
            return value;
        } else {
            return value;
        }
    }

    function limitarcaracteres28(value) {
        if (value.length > 28) {
            value = value.slice(0, 28) + '...';
            return value;
        } else {
            return value;
        }
    }

    var myCourseList = [];
    var nCursosAlumno = 0;
    if (myCourses) {
        nCursosAlumno = myCourses.length;
    }
    for (let i = 0; i < nCursosAlumno; i++) {

        myCourseList.push(

            <Grid container item xs={12} sm={12} md={6} lg={4} xl={3} spacing={0} direction="column" alignItems="center" justifyContent="center" key={myCourses[i].id}>
                <Hidden smUp>
                    <div className="course-small">

                        <Container maxWidth="sm">

                            <Grid container spacing={3} alignContent="center" alignItems="center">

                                <TarjetaEcampus
                                    image={utils.getImagenCurso(myCourses[i])}
                                    text1={'aaa'}
                                    text2={myCourses[i].instructor}
                                    href={"/detail-certified/" + myCourses[i].id + "/" + "Programas"}
                                    price={myCourses[i].precio}
                                    small={true}
                                    showTag={isStructureUpdated(myCourses[i])}
                                    tagOptions={TagStyles.TAG_ACTUALIZADO}
                                    curso={myCourses[i]}
                                />

                            </Grid>
                        </Container>

                    </div>
                </Hidden>

                <Hidden xsDown>
                    <div className="course rel">

                        <Container maxWidth="md">

                            <Grid container spacing={3} alignContent="center" alignItems="center">

                                <TarjetaEcampus
                                    image={utils.getImagenCurso(myCourses[i])}
                                    text1={limitarcaracteres(myCourses[i].titulo)}
                                    text2={myCourses[i].instructor}
                                    href={"/detail-certified/" + myCourses[i].id + "/" + "Programas"}
                                    price={myCourses[i].precio}
                                    showTag={isStructureUpdated(myCourses[i])}
                                    tagOptions={TagStyles.TAG_ACTUALIZADO}
                                    curso={myCourses[i]}
                                />

                            </Grid>

                        </Container>

                    </div>
                </Hidden>
            </Grid>

        );
    }

    return (
        <div className="App rel">

            <Breadcrumbs aria-label="breadcrumb" className="breadcrumbs_link_inneret">
                <Typography color="textPrimary" className="breadcrumbs_link">
                    Programas
                </Typography>
            </Breadcrumbs>

            <div className="tituloInicial rel">
            <h1 className="title_section s24 fontb c333">Programas</h1>
            </div>
            <div className="section section-b rel">

                {disableLoadingDiplomados != true &&
                    <div id="cargando" className={classes3.root}>
                        <DialogContent className="CentrarLoadingSinModal">
                            <CircularProgress className="CircularProgress" style={{ alignSelf: 'center' }} />
                        </DialogContent>
                    </div>
                }

                <div className="courses rel flex">

                    {myCourseList && myCourseList != null && myCourseList != '' && myCourseList != undefined &&
                        <Grid container spacing={1} item xs={12} direction="row" >

                            {myCourseList}

                        </Grid>

                    }

                    {mens === true &&

                        <Grid container spacing={3}>

                            <Grid item xs={2} sm={1} md={2} lg={3} xl={3}  >

                            </Grid>

                            <Grid item xs={8} sm={10} md={8} lg={6} xl={6}>

                                <div style={{ height: '100px' }}></div>
                                <div style={{ display: 'flex', justifyContent: 'center' }} className="title_section s30 fontb c333">¡ Aún no existen programas cargados !</div>
                                <div style={{ height: '100px' }}></div>

                            </Grid>

                            <Grid item xs={2} sm={1} md={2} lg={3} xl={3}  >

                            </Grid>

                        </Grid>

                    }

                </div>

            </div>

        </div>

    );
}

export default CertifiedPage;
