import React, { useState, useEffect, useContext } from "react";
import Button from '@material-ui/core/Button';
import Badge from '@material-ui/core/Badge';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import Fab from '@material-ui/core/Fab';
import MenuItem from '@material-ui/core/MenuItem';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Edit from '@material-ui/icons/Edit';
import ExitToApp from '@material-ui/icons/ExitToApp';
import OpenInBrowser from '@material-ui/icons/OpenInBrowser';
import SettingsBrightnessIcon from '@material-ui/icons/SettingsBrightness';
import FolderSharedIcon from '@material-ui/icons/FolderShared';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import SchoolOutlinedIcon from '@material-ui/icons/SchoolOutlined';
import StarIcon from '@material-ui/icons/Star';
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { deepPurple, blue, cyan, green, red, blueGrey, grey } from '@material-ui/core/colors';
import { useHistory } from "react-router";
import Grid from '@material-ui/core/Grid';
import { Switch } from ".";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Menu from '@material-ui/core/Menu';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from "./DialogTitle";

import AuthContext from "../AuthContext";
import { BotonContext } from "../BotonContext";
import { theme as tema } from "../util/theme";
import i18n, { strings } from '../lib/i18n';
import env from "../util/env";
import { useCart } from "../util/Cart";
import { FLAGS } from "../lib/constants";
import { useSession } from "../util/session";
import routeHelper from "../util/routeHelper";

const hideDiplomados = env.flags.HIDE_DIPLOMADOS || FLAGS.DISABLE_PROGRAMS;
const ALWAYS_SHOW_MIS_ADMINISTRACIONES = true;
/** @type {number} */
let pendingCountryId;

export default function Botonflotante () {

    const history = useHistory();
    const { countryId, setCountryId, countryList, cart, data_user } = useContext(BotonContext);
    const { isAuth, isAuthTypeTutor } = useContext(AuthContext);
    const [openmensnoauth, setOpenmensnoauth] = React.useState(false);
    const { getItemCount } = useCart();
    const { isLogged, ownsAtLeastOnePlan, logout } = useSession();

    const handleCloseMensNoAuth = () => {
        setOpenmensnoauth(false);
    };

    const [pathname, setPathname] = useState(window.location.pathname.slice(18,));
    const [cartQuantity, setCartQuantity] = useState(0);

    useEffect(() => {
        const inputVal = pathname.split('-').join(' ');
        if (window.location.pathname.includes('search')) {
            document.getElementsByTagName('input').item(0).setAttribute('value', inputVal);
            document.getElementsByTagName('input').item(1).setAttribute('value', inputVal);
        }
    });

    useEffect(() => {
        setCartQuantity(getItemCount());
    }, [cart]);

    const useStyles = makeStyles((theme) => ({
        root: {
            background: 'linear-gradient(45deg, #771cca 30%, ##3f51b5 90%)',
            borderRadius: 24,
            border: 0,
            color: 'white',
            height: 48,
            padding: '0 50px',
        },
        label: {
            textTransform: 'capitalize',
        },
        outlined: {
            border: '5px solid red'
        },
        small: {
            width: theme.spacing(3),
            height: theme.spacing(3),
        },
        large: {
            width: theme.spacing(7),
            height: theme.spacing(7),
        },
    }));

    const classes = useStyles();

    const useStyles2 = makeStyles(() => ({
        root: {
            background: 'linear-gradient(45deg, #771cca 30%, ##3f51b5 90%)',
            borderRadius: 24,
            border: 1,
            color: 'white',
            height: 10,
            padding: '0 50px',
        },
        label: {
            textTransform: 'capitalize',
        },
        outlined: {
            border: '0px solid red'
        }
    }));

    const classes2 = useStyles2();

    /* Boton menu */

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    function handleClickIniciarSesion() {
        window.location.assign(`#/login`);
        handleClose();
    }

    function handleClickCerrarSesion() {
        logout();
        handleClose();
    }

    function handleClickMisCompras() {
        history.push("/my-shopping");
        handleClose();
    }

    function handleClickMisDiplomados() {
        history.push("/my-certified");
        handleClose();
    }

    function handleClickEditarPerfil() {
        history.push("/profile-edit");
        handleClose();
    }

    function handleClickMisCursos() {
        history.push("/my-courses");
        handleClose();
    }

    function handleClickMisTutorias() {
        history.push("/my-courses-tutor");
        handleClose();
    }

    function goToMisAdministraciones() {
        history.push(routeHelper.misPlanes);
        handleClose();
    }

    const gotoTutorSubscription = function () {
        history.push("/tutor-registration");
        handleClose();
    };

    const StyledMenu = withStyles({
        paper: {
            border: '1px solid #282c34',
            marginLeft: '-1px' // para que no se oculte 1 pixel a la derecha. Que corresponde al borde del menú.
        }
    })((props) => (
        <Menu
            disableScrollLock={true}
            elevation={0}
            getContentAnchorEl={null}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center'
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center'
            }}
            {...props}
        />
    ));


    const StyledMenuItem = withStyles((theme) => ({

        root: {
            //className: "menu_items",
            '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                //color: theme.palette.common.black,
                color: tema.current === "light" ? theme.palette.common.black : theme.palette.common.white,
            },
            '&:focus': {
                //className: "checkbox",
                backgroundColor: grey[900], //theme.palette.primary.main, //red[500],
                '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                    color: theme.palette.common.white,
                },
            },
        },
    }))(MenuItem);

    /* Boton menu */


    /* Avatar */

    const StyledBadge = withStyles((theme) => ({
        badge: {
            backgroundColor: '#44b700',
            color: '#44b700',
            boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
            '&::after': {
                position: 'absolute',
                top: 0,
                left: 0,
                width: '120%',
                height: '120%',
                borderRadius: '50%',
                animation: '$ripple 1.2s infinite ease-in-out',
                border: '1px solid currentColor',
                content: '""',
            },
        },
        '@keyframes ripple': {
            '0%': {
                transform: 'scale(.8)',
                opacity: 1,
            },
            '100%': {
                transform: 'scale(2.4)',
                opacity: 0,
            },
        },
    }))(Badge);


    const StyledBadgeOut = withStyles((theme) => ({
        badge: {
            backgroundColor: red[500],//'#ffffff',
            color: red[500],//'#ffffff',
            boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
            '&::after': {
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                borderRadius: '50%',
                //animation: '$ripple 1.2s infinite ease-in-out', //Efecto de dot
                border: '1px solid currentColor',
                content: '""',
            },
        },
        '@keyframes ripple': {
            '0%': {
                transform: 'scale(.8)',
                opacity: 1,
            },
            '100%': {
                transform: 'scale(2.4)',
                opacity: 0,
            },
        },
    }))(Badge);

    const SmallAvatar = withStyles((theme) => ({
        root: {
            width: 22,
            height: 22,
            border: `2px solid ${theme.palette.background.paper}`,
        },
    }))(Avatar);

    const useStyles4 = makeStyles((theme) => ({
        root: {
            display: 'flex',
            '& > *': {
                margin: theme.spacing(2),
            },
        },
        orange: {
            color: '#fff',
            backgroundColor: tema.current === "light" ? '#000000' : '#d87218',
        },
        purple: {
            color: theme.palette.getContrastText(deepPurple[500]),
            backgroundColor: deepPurple[500],
        },
        darkblue: {
            color: theme.palette.getContrastText(blue[500]),
            backgroundColor: blue[900],
        },
        blue: {
            color: theme.palette.getContrastText(blue[500]),
            backgroundColor: blue[500],
        },
        cyan: {
            color: theme.palette.getContrastText(cyan[500]),
            backgroundColor: cyan[500],
        },
        green: {
            color: theme.palette.getContrastText(green[500]),
            backgroundColor: green[500],
        },
        blueGrey: {
            color: theme.palette.getContrastText(blueGrey[500]),
            backgroundColor: blueGrey[500],
        },
        red: {
            color: theme.palette.getContrastText(red[500]),
            backgroundColor: red[500],
        },
        teal: {
            color: theme.palette.getContrastText(blueGrey[500]),
            backgroundColor: blueGrey[500],
        },
        grey: {
            color: theme.palette.getContrastText(red[500]),
            backgroundColor: red[500],
        },
    }));

    const classes4 = useStyles4();

    //return:
    //<Avatar src="/broken-image.jpg" />
    /* Avatar */

    const opcionesFont = [
        { title: 'Normal', n: 1 },
        { title: 'Mediana', n: 2 },
        { title: 'Grande', n: 3 }
    ];

    const [openAdvertenciaCambioPais, setOpenAdvertenciaCambioPais] = useState(false);

    const handleCloseAdvertencia = function () {
        setOpenAdvertenciaCambioPais(false);
    };

    const handleAceptarCambioPais = function () {
        setOpenAdvertenciaCambioPais(false);
        setCountryId(pendingCountryId);
    };

    /**
     * @param {number} id_pais
     */
    const validarCambioPais = function (id_pais) {
        const itemsCarrito = cart instanceof Array ? cart.length : 0;
        if (!itemsCarrito) {
            setCountryId(id_pais);
        } else {
            pendingCountryId = id_pais;
            setOpenAdvertenciaCambioPais(true);
        }
    };

    return (

        <>
            <Snackbar open={openmensnoauth} autoHideDuration={3000} onClose={handleCloseMensNoAuth} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
                <Alert onClose={handleCloseMensNoAuth} severity="error" variant="filled">
                    La sesión ha caducado.
                </Alert>
            </Snackbar>

            <div id="botones-top-bar" >

                <Grid container direction="row" justifyContent="flex-end" alignItems="flex-end">

                    <Grid style={{ alignSelf: "center", marginRight: "1rem" }}>

                        {/* selector país / moneda */}
                        <FormControl id="main-country-selector" variant="outlined" className={classes2.formControl} >
                            <Select
                                MenuProps={{ disableScrollLock: true }}
                                value={countryId}
                                onChange={(e) => validarCambioPais(parseInt(e.target.value))}
                            >
                                { countryList.length ? countryList.map(country =>

                                    <MenuItem value={country.id_pais} key={country.id_pais}>
                                        <div className="main-country-option">
                                            <span>{country.nombre}</span>
                                            <span className="main-country-option-code">{country.abreviatura}</span>
                                        </div>
                                    </MenuItem>

                                ) : (() => {
                                    const country = i18n.getCountry();
                                    return [
                                        <MenuItem value={country.id_pais} key={country.id_pais} className="placeholder-menu-option">
                                            <div className="main-country-option">
                                                <span>{country.nombre}</span>
                                                <span className="main-country-option-code">{country.abreviatura}</span>
                                            </div>
                                        </MenuItem>,
                                        <MenuItem key="loading" disabled>
                                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                <CircularProgress className="CircularProgress" style={{ maxWidth: '1rem', maxHeight: '1rem' }} />
                                            </div>
                                        </MenuItem>
                                    ];
                                    })()
                                }
                            </Select>
                        </FormControl>

                    </Grid>

                    <Grid>

                        {isLogged() ?

                            (
                                <div style={{ float: 'right' }} className="button-perfil" >
                                    <Button
                                        aria-controls="customized-menu"
                                        aria-haspopup="true"
                                        //variant="rounded"
                                        //color="transparent"
                                        //anchorEl={anchorEl}
                                        //keepMounted
                                        open={Boolean(anchorEl)}
                                        onClose={handleClose}
                                        onClick={handleClick}
                                    >
                                        {/*
                                        {isLogged() && data_user.suscrito == 1 &&
                                            <>
                                                <Chip size="small" label="Suscrito" fontSize="small" style={{ float: 'right', marginTop: '0px', marginRight: '0px', color: 'white', backgroundColor: 'darkgreen'}}></Chip>  &nbsp;
                                                <StarIcon fontSize="small" style={{ color: 'darkorange', float: 'right', marginTop: '-40px', marginRight: '-50px', zIndex:99}}/>
                                            </>
                                        }
                                        */}
                                        <StyledBadge
                                            overlap="circular"
                                            anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'right',
                                            }}
                                            variant="dot"
                                        >
                                            <Avatar className={classes4.orange}>{data_user.nombres.substr(0, 1)}</Avatar>

                                        </StyledBadge>
                                        <ArrowDropDownIcon className="arrow-avatar"/>
                                    </Button>

                                    <StyledMenu
                                        id="customized-menu"
                                        anchorEl={anchorEl}
                                        keepMounted
                                        open={Boolean(anchorEl)}
                                        onClose={handleClose}

                                    >

                                        <StyledMenuItem >
                                            <ListItemIcon>
                                                <AccountCircle fontSize="small" />  &nbsp; &nbsp;
                                                <ListItemText primary={data_user.nombres} /> &nbsp;
                                                {isLogged() && data_user.suscrito == 1 &&
                                                    <>
                                                        <Chip size="small" label="Suscrito" fontSize="small" style={{ float: 'right', marginTop: '0px', marginRight: '0px', color: 'white', backgroundColor: green[600] }}></Chip>  &nbsp;
                                                    </>
                                                }
                                                {isLogged() && data_user.suscrito == 0 &&
                                                    <>
                                                        <Chip size="small" label="Suscripción vencida" fontSize="small" style={{ float: 'right', marginTop: '0px', marginRight: '0px', color: 'white', backgroundColor: red[600] }}></Chip>  &nbsp;
                                                    </>
                                                }

                                                {/*isLogged() && data_user.suscrito == null &&
                                                <>
                                                <Chip size="small" label="No Suscrito" fontSize="small" style={{ float: 'right', marginTop: '0px', color: 'white', backgroundColor: orange[600]}}></Chip>  &nbsp;
                                                </>
                                                */}

                                            </ListItemIcon>
                                        </StyledMenuItem>

                                        <StyledMenuItem>
                                            <ListItemIcon onClick={handleClickEditarPerfil}>
                                                <Edit fontSize="small" />           &nbsp; &nbsp;
                                                <ListItemText primary="Editar perfil" />
                                            </ListItemIcon>
                                        </StyledMenuItem>

                                        {isAuth && !isAuthTypeTutor &&
                                            <StyledMenuItem>
                                                <ListItemIcon onClick={() => { gotoTutorSubscription(); }}>
                                                    <SchoolOutlinedIcon fontSize="small" />      &nbsp; &nbsp;
                                                    <ListItemText primary="Enseña con nosotros" />
                                                </ListItemIcon>
                                            </StyledMenuItem>
                                        }

                                        <StyledMenuItem>
                                            <ListItemIcon onClick={handleClickMisCursos}>
                                                <FolderSharedIcon fontSize="small" />      &nbsp; &nbsp;
                                                <ListItemText primary="Mis Cursos" />
                                            </ListItemIcon>
                                        </StyledMenuItem>

                                        {!hideDiplomados &&
                                            <StyledMenuItem>
                                                <ListItemIcon onClick={handleClickMisDiplomados}>
                                                    <FolderSharedIcon fontSize="small" />      &nbsp; &nbsp;
                                                    <ListItemText primary="Mis programas" />
                                                </ListItemIcon>
                                            </StyledMenuItem>
                                        }

                                        {isAuthTypeTutor &&

                                            <StyledMenuItem>
                                                <ListItemIcon onClick={handleClickMisTutorias}>
                                                    <FolderSharedIcon fontSize="small" />      &nbsp; &nbsp;
                                                    <ListItemText primary={strings.MIS_TUTORIAS} />
                                                </ListItemIcon>
                                            </StyledMenuItem>

                                        }

                                        {(ownsAtLeastOnePlan() || ALWAYS_SHOW_MIS_ADMINISTRACIONES) &&
                                            <StyledMenuItem>
                                                <ListItemIcon onClick={goToMisAdministraciones}>
                                                    <ShoppingBasketIcon fontSize="small" />      &nbsp; &nbsp;
                                                    <ListItemText primary="Mis administraciones" />
                                                </ListItemIcon>
                                            </StyledMenuItem>
                                        }

                                        <StyledMenuItem>
                                            <ListItemIcon onClick={handleClickMisCompras}>
                                                <ShoppingBasketIcon fontSize="small" />      &nbsp; &nbsp;
                                                <ListItemText primary="Mis compras" />
                                            </ListItemIcon>
                                        </StyledMenuItem>

                                        <StyledMenuItem >
                                            <Grid container spacing={2}>
                                                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}  >
                                                    <ListItemIcon>
                                                        <SettingsBrightnessIcon fontSize="small" />           &nbsp; &nbsp;
                                                        <ListItemText primary="Modo oscuro" />                  &nbsp; &nbsp; &nbsp;
                                                    </ListItemIcon>
                                                </Grid>
                                                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}  >
                                                    <Switch />
                                                </Grid>
                                            </Grid>
                                        </StyledMenuItem>

                                        <StyledMenuItem>
                                            <ListItemIcon onClick={handleClickCerrarSesion}>
                                                <ExitToApp fontSize="small" />      &nbsp; &nbsp;
                                                <ListItemText primary="Cerrar sesión" />
                                            </ListItemIcon>
                                        </StyledMenuItem>

                                        {/*
                                        <StyledMenuItem>
                                            <Autocomplete
                                                id="combo-box-demo"
                                                options={opcionesFont}
                                                getOptionLabel={(option) => option.title}
                                                style={{ width: 300 }}
                                                renderInput={(params) => <TextField {...params} label="Tamaño de letra" variant="outlined" />}
                                            />
                                        </StyledMenuItem>
                                        */}

                                    </StyledMenu>

                                </div>

                            ) : (

                                    <div style={{ float: 'right' }} className="button-perfil">

                                        <Button
                                            id="customized-menu"
                                            aria-controls="customized-menu"
                                            aria-haspopup="true"
                                            //variant="rounded"
                                            //color="transparent"
                                            //variant="outlined"
                                            color="inherit"
                                            //anchorEl={anchorEl}
                                            //keepMounted
                                            open={Boolean(anchorEl)}
                                            onClose={handleClose}
                                            onClick={handleClick}
                                            size='medium'
                                        >

                                            <StyledBadgeOut
                                                overlap="circular"
                                                anchorOrigin={{
                                                    vertical: 'bottom',
                                                    horizontal: 'right',
                                                }}

                                                variant="dot"
                                            //aria-controls="customized-menu"
                                            //aria-haspopup="true"
                                            //onClick={handleClick}

                                            >
                                                <Avatar  /*src="/broken-image.jpg"*/ />


                                            </StyledBadgeOut>
                                            <ArrowDropDownIcon className="arrow-avatar"/>
                                        </Button>


                                        <StyledMenu
                                            id="customized-menu"
                                            anchorEl={anchorEl}
                                            keepMounted
                                            open={Boolean(anchorEl)}
                                            onClose={handleClose}
                                        >
                                            <StyledMenuItem >
                                                <ListItemIcon>
                                                    <AccountCircle fontSize="small" />  &nbsp; &nbsp;

                                                    <ListItemText primary="Invitado" />
                                                </ListItemIcon>
                                            </StyledMenuItem>

                                            <StyledMenuItem >
                                                <Grid style={{ display: 'flex' }} >
                                                    <ListItemIcon>
                                                        <SettingsBrightnessIcon fontSize="small" />           &nbsp; &nbsp;
                                                        <ListItemText primary="Modo oscuro" />                  &nbsp; &nbsp; &nbsp;
                                                    </ListItemIcon>
                                                    <Switch />
                                                </Grid>
                                            </StyledMenuItem>

                                            <StyledMenuItem >
                                                <ListItemIcon onClick={handleClickIniciarSesion}>
                                                    <OpenInBrowser fontSize="small" />      &nbsp; &nbsp;
                                                    <ListItemText primary="Iniciar sesión" />
                                                </ListItemIcon>
                                            </StyledMenuItem>


                                            {/*
                                    <StyledMenuItem>
                                        <Autocomplete
                                            id="combo-box-demo"
                                            options={opcionesFont}
                                            getOptionLabel={(option) => option.title}
                                            style={{ width: 300 }}
                                            renderInput={(params) => <TextField {...params} label="Tamaño de letra" variant="outlined" />}
                                        />
                                    </StyledMenuItem>
                                    */}
                                        </StyledMenu>

                                    </div>

                            )}
                    </Grid>

                    <Grid item>
                        <div style={{ float: 'right', marginRight: '5px' }}>
                            <Button href={"#/add-cart"}>
                                <Badge color="secondary" badgeContent={cartQuantity} style={{ marginTop: '4px' }}>
                                    <ShoppingCartIcon fontSize="large" href={"#/add-cart"} />   &nbsp;
                                </Badge>
                            </Button>
                        </div>

                    </Grid>
                </Grid>
            </div>

            <div hidden={true} className="sidebar rel">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <Fab variant="extended"
                    color="primary"
                    href={"#/add-cart"}
                    classes={{
                        root: classes.root,
                        label: classes.label,
                        //outlined: classes.outlined
                    }}
                >
                    <Badge color="secondary" badgeContent={cartQuantity}>
                        <ShoppingCartIcon />   &nbsp;
                    </Badge>
                </Fab>
            </div>

            <div hidden={true} className="sidebar rel">
                <Button variant="outlined" color="primary"  >
                    <Badge color="secondary" badgeContent={cartQuantity}>
                        Mi Carrito
                        <ShoppingCartIcon />
                    </Badge>
                </Button>
            </div>

            <Dialog
                open={openAdvertenciaCambioPais}
                onClose={handleCloseAdvertencia}
                aria-labelledby="customized-dialog-title"
            >
                <DialogTitle id="alert-dialog-title" onClose={handleCloseAdvertencia} className="modal_title">Advertencia</DialogTitle>
                <DialogContent dividers className="modal_content">
                    <div style={{ fontSize: '1rem', cursor: 'default' }}>
                        Si cambia el país, se vaciará el carro de compra y deberá agregar los productos nuevamente. ¿Desea continuar?
                    </div>
                </DialogContent>
                <DialogActions className="modal_footer">
                    <Button onClick={handleCloseAdvertencia} variant="contained" className="button_cancel">Cancelar</Button>
                    <Button onClick={handleAceptarCambioPais} variant="contained" color="primary" autoFocus className="button_accept">Aceptar</Button>
                </DialogActions>
            </Dialog>

        </>
    );
}
