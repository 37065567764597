import axios from "axios";
import { TIPO_CLASE } from "./constants";
import { BASE_API_URL, buildParamString } from "./api.common";

/**
 * @typedef DatedStructure
 * @property {string | Date} fecha_creacion
 * @property {string | Date} fecha_modificacion
 */

/**
 * @param {DatedStructure} data
 * @param {number | false} lowerThreshold - en ms. Especificar `false` para deshabilitar este threshold.
 * @param {number | false} upperThreshold - en ms. Especificar `false` para deshabilitar este threshold.
 * @returns {boolean}
 */
const _isStructureUpdated = function (data, lowerThreshold, upperThreshold) {
    const nameCreationField = 'fecha_creacion';
    const nameModificationField = 'fecha_modificacion';
    if (Object.hasOwn(data, nameCreationField) && Object.hasOwn(data, nameModificationField)) {
        const delta = parseApiDate(data[nameModificationField]).valueOf() - parseApiDate(data[nameCreationField]).valueOf();
        return (lowerThreshold === false || delta > lowerThreshold) && (upperThreshold === false || delta < upperThreshold);
    }
    return false;
};

/**
 * @param {DatedStructure} data
 * @returns {boolean}
 */
export function isStructureUpdated(data) {
    const lowerThreshold = 24 * 3600 * 1000; // 1 día
    const upperThreshold = 14 * 24 * 3600 * 1000; // 2 semanas
    return _isStructureUpdated(data, lowerThreshold, upperThreshold);
}

/**
 * @param {api.CursoInstructor} curso
 * @returns {boolean}
 */
export function isEdited(curso) {
    return _isStructureUpdated(curso, 5 * 60 * 1000 /* 5 minutos */, false);
}

/**
 * @param {api.CursoInstructor} curso
 * @returns {boolean}
 */
export function isPublished(curso) {
    return curso.estado === 1;
}

/**
 * @param {string | Date} date
 * @returns {Date}
 */
export function parseApiDate(date) {
    if (date instanceof Date) return date;
    const match = /^(\d{2})[-/](\d{2})[-/](\d{4})((?:[^\d]|$).*)/.exec(date);
    if (match) {
        return new Date(`${match[3]}/${match[2]}/${match[1]}${match[4]}`); // colocar de forma YYYY/MM/DD... (asumiendo que en `date` está el día primero).
    } else {
        return new Date(date);
    }
}

/**
 * @param {api.ClaseInstructor} clase
 */
export function detectarTipoClase(clase) {
    if (typeof clase.param_tipo === 'number') return clase.param_tipo;
    if (clase.recursos?.ext === 'zip') return TIPO_CLASE.ZIP;
    if (clase.recursos?.ext === 'm3u8') return TIPO_CLASE.VIDEO;
    if (!!clase.evaluacion?.id_evaluacion || Object.keys(clase.evaluacion).length) return TIPO_CLASE.TEST; // ¿será posible determinar entre los dos tipos de tests posibles?
    return clase.param_tipo;
}

const api = {

    /**
     * @returns {Promise<api.SuccessResponse<api.Category[]>>}
     */
    getCategories() {
        return new Promise((resolve, reject) => {
            fetch(BASE_API_URL + '/categorias')
                .then(response => {
                    return response.json();
                })
                .then(data => {
                    resolve(data);
                })
                .catch(error => {
                    console.error(error);
                    reject();
                });
        });
    },

    /**
     * @returns {Promise<api.SuccessResponse<api.Category[]>>}
     */
    getCategoriesHome() {
        return new Promise((resolve, reject) => {
            fetch(BASE_API_URL + '/categorias-home')
                .then(response => {
                    return response.json();
                })
                .then(data => {
                    resolve(data);
                })
                .catch(error => {
                    console.error(error);
                    reject();
                });
        });
    },

    /**
     * @param {Object} obj
     * @param {number} obj.courseId
     * @param {number} obj.id_pais
     * @param {string} obj.token
     * @returns {Promise<api.SuccessResponse<api.Curso>>}
     */
    getCurso({ courseId, id_pais, token }) {
        return new Promise((resolve, reject) => {
            fetch(BASE_API_URL + '/curso/' + courseId + '?id_pais=' + id_pais, {
                method: 'GET',
                headers: {
                    'token': token,
                    'Content-Type': 'application/json'
                }
            })
                .then(response => {
                    return response.json();
                })
                .then(data => {
                    resolve(data);
                })
                .catch(error => {
                    console.error(error);
                    reject();
                });
        });
    },

    /**
     * @param {Object} obj
     * @param {string} obj.query
     * @param {number} [obj.nPag]
     * @param {number} [obj.category]
     * @param {number} [obj.id_pais]
     * @returns {Promise<api.SuccessResponse<api.CursosPaginados>>}
     */
    getCursosFiltrados({ query, nPag, category, id_pais }) {
        let url;
        if (category || typeof category === 'number') {
            url = BASE_API_URL + '/categoria/' + category + '/buscar-cursos/' + query;
        } else {
            url = BASE_API_URL + '/buscar-cursos/' + query;
        }
        const params = [];
        if (nPag) {
            params.push('pagina=' + nPag);
        }
        if (id_pais) {
            params.push('id_pais=' + id_pais);
        }
        url += buildParamString(params);
        return new Promise((resolve, reject) => {
            fetch(url)
                .then(response => {
                    return response.json();
                })
                .then(data => {
                    resolve(data);
                })
                .catch(error => {
                    const respuesta = error;
                    console.error(respuesta);
                    reject();
                });
        });
    },

    /**
     * @param {Object} obj
     * @param {number} obj.categoryId
     * @param {number} [obj.nPag]
     * @param {number} [obj.id_pais]
     * @returns {Promise<api.SuccessResponse<api.CursosPaginados>>}
     */
    getCursosCategory({ categoryId, nPag, id_pais }) {
        let url = BASE_API_URL + '/categoria/' + categoryId + '/cursos';
        const params = [];
        if (nPag) {
            params.push('pagina=' + nPag);
        }
        if (id_pais) {
            params.push('id_pais=' + id_pais);
        }
        url += buildParamString(params);
        return new Promise((resolve, reject) => {
            fetch(url)
                .then(response => {
                    return response.json();
                })
                .then(data => {
                    resolve(data);
                })
                .catch(error => {
                    const respuesta = error;
                    console.error(respuesta);
                    reject();
                });
        });
    },

    /**
     * @param {Object} obj
     * @param {number} [obj.id_usuario] se usa para filtrar los cursos que el usuario ya tenga en su cuenta
     * @returns {Promise<api.SuccessResponse<api.CursosHome>>}
     */
    getCursosCategoryHome({ id_usuario }) {
        let url = BASE_API_URL + '/cursos/categorias/home';
        const params = [];
        if (typeof id_usuario === 'number') {
            params.push('id_usuario=' + id_usuario);
        }
        url += buildParamString(params);
        return new Promise((resolve, reject) => {
            fetch(url)
                .then(response => {
                    return response.json();
                })
                .then(data => {
                    resolve(data);
                })
                .catch(error => {
                    const respuesta = error;
                    console.error(respuesta);
                    reject();
                });
        });
    },

    /**
     * @param {*} userId
     * @param {number} [id_pais]
     * @returns {Promise<api.SuccessResponse<*>>}
     */
    getCursosPopulares(userId, id_pais) {
        return new Promise((resolve, reject) => {
            fetch(BASE_API_URL + '/cursos/populares?id_usuario=' + userId + (typeof id_pais !== 'undefined' ? '&id_pais=' + id_pais : ''))
                .then(response => {
                    return response.json();
                })
                .then(data => {
                    resolve(data);
                })
                .catch((error) => {
                    console.error(error);
                    reject();
                });
        });
    },

    /**
     * @param {number} userId
     * @returns {Promise<api.SuccessResponse<*>>}
     */
    getMisCursos(userId) {
        return new Promise((resolve, reject) => {
            fetch(BASE_API_URL + '/cursos-recientes/'+userId+'/6')
                .then(response => {
                    return response.json();
                })
                .then(data => {
                    resolve(data);
                })
                .catch((error) => {
                    console.error(error);
                    reject();
                });
        });
    },

    /**
     * @param {*} userId
     * @param {string} [token]
     * @param {number} [id_pais]
     * @returns {Promise<api.SuccessResponse<*>>}
     */
    getDiplomados(userId, token, id_pais) {
        const requestInit = {
            method: 'get',
            headers: {
                'Content-Type': 'application/json'
            }
        };
        if (token) {
            requestInit.headers['token'] = token;
        }
        return new Promise((resolve, reject) => {
            fetch(BASE_API_URL + '/diplomados?id_usuario=' + userId + (typeof id_pais !== 'undefined' ? '&id_pais=' + id_pais : ''), requestInit)
                .then(response => {
                    return response.json();
                })
                .then(data => {
                    resolve(data);
                })
                .catch(error => {
                    console.error(error);
                    reject();
                });
        });
    },

    /**
     * @param {string} token
     * @param {number} userId
     * @param {number} courseId
     * @returns {Promise<api.SuccessResponse<api.ListaMisClases>>}
     */
    getMisClases(token, userId, courseId) {
        return new Promise((resolve, reject) => {
            fetch(BASE_API_URL + '/alumno/' + userId + '/curso/' + courseId + '/clases', {
                method: 'get',
                headers: {
                    'token': token,
                    'Content-Type': 'application/json'
                }
            })
                .then(response => {
                    return response.json();
                })
                .then(data => {
                    resolve(data);
                })
                .catch(error => {
                    console.error(error);
                    reject();
                });
        });
    },

    /**
     * @param {string} token
     * @param {number} courseId
     * @returns {Promise<api.ListaClases>}
     * @todo normalizar este API. No está retornando la respuesta estándar.
     */
    getClases(token, courseId) {
        return new Promise((resolve, reject) => {
            fetch(BASE_API_URL + '/curso/' + courseId + '/clases/unidad', {
                method: 'GET',
                headers: {
                    'token': token,
                    'Content-Type': 'application/json'
                }
            })
                .then(response => {
                    return response.json();
                })
                .then(data => {
                    resolve(data);
                })
                .catch(error => {
                    console.error(error);
                    reject();
                });
        });
    },

    /**
     * @returns {Promise<api.SuccessResponse<api.PaísPasarela[]>>}
     */
    getPaisesPasarela() {
        return new Promise((resolve, reject) => {
            fetch(BASE_API_URL + '/paises-pasarela')
                .then(response => {
                    return response.json();
                })
                .then(data => {
                    resolve(data);
                })
                .catch(error => {
                    console.error(error);
                    reject();
                });
        });
    },

    /**
     * @param {Array} productos
     */
    // eslint-disable-next-line no-unused-vars
    gerPrecioProductos(productos) {
        // TODO: api aún no implementado
    },

    /**
     *
     * @param {string} token
     * @param {number} id_pais
     * @returns {Promise<api.SuccessResponse<*>>}
     */
    getSuscripciones(token, id_pais) {
        return new Promise((resolve, reject) => {
            fetch(BASE_API_URL + '/suscripciones' + '?id_pais=' + id_pais, {
                method: 'get',
                headers: {
                    'token': token,
                    'Content-Type': 'application/json'
                }
            })
                .then(response => {
                    return response.json();
                })
                .then(data => {
                    resolve(data);
                })
                .catch(error => {
                    console.error(error);
                    reject();
                });
        });
    },

    /**
     * @param {Object} obj
     * @param {number} obj.id_pais
     * @param {number} [obj.id_usuario]
     * @returns {Promise<api.SuccessResponse<api.Plan[]>>}
     */
    getPlanesMultiUsuario({ id_pais, id_usuario }) {
        let url = BASE_API_URL + '/planes';
        const params = ['id_pais=' + id_pais];
        id_usuario && params.push('id_usuario=' + id_usuario);
        url += buildParamString(params);
        return new Promise((resolve, reject) => {
            fetch(url, {
                method: 'get'
            })
                .then(response => {
                    return response.json();
                })
                .then(data => {
                    resolve(data);
                })
                .catch(error => {
                    console.error(error);
                    reject();
                });
        });
    },

    /**
     * @param {Object} obj
     * @param {number} obj.id_pais
     * @param {number} [obj.id_usuario]
     * @returns {Promise<api.SuccessResponse<api.PlanConCursos[]>>}
     */
    getPlanesMultiUsuarioConCursos({ id_pais, id_usuario }) {
        let url = BASE_API_URL + '/planes-cursos';
        const params = ['id_pais=' + id_pais];
        id_usuario && params.push('id_usuario=' + id_usuario);
        url += buildParamString(params);
        return new Promise((resolve, reject) => {
            fetch(url, {
                method: 'get'
            })
                .then(response => {
                    return response.json();
                })
                .then(data => {
                    resolve(data);
                })
                .catch(error => {
                    console.error(error);
                    reject();
                });
        });
    },

    /**
     * @param {Object} obj
     * @param {number} obj.id_usuario
     * @returns {Promise<api.SuccessResponse<api.Plan[]>>}
     */
    getMisPlanes({ id_usuario }) {
        let url = BASE_API_URL + '/planes-usuario?id_usuario=' + id_usuario;
        return new Promise((resolve, reject) => {
            fetch(url, {
                method: 'get'
            })
                .then(response => {
                    return response.json();
                })
                .then(data => {
                    resolve(data);
                })
                .catch(error => {
                    console.error(error);
                    reject();
                });
        });
    },

    /**
     * @param {*} userId
     * @param {string} token
     * @returns {Promise<api.SuccessResponse<*>>}
     */
    getUserSubscription(userId, token) {
        return new Promise((resolve, reject) => {
            fetch(BASE_API_URL + '/usuario/' + userId + '/suscripcion', {
                method: 'get',
                headers: {
                    'token': token,
                    'Content-Type': 'application/json'
                }
            })
                .then(response => {
                    return response.json();
                })
                .then(data => {
                    resolve(data);
                })
                .catch(error => {
                    console.error(error);
                    reject();
                });
        });
    },

    /**
     * @param {string} email
     * @returns {Promise<api.DatalessSuccessResponse>}
     */
    recoverPassword(email) {
        return new Promise((resolve, reject) => {
            axios.post(BASE_API_URL + '/recover?email=' + email)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.error(error);
                    reject();
                });
        });
    },

    /**
     * @param {string} token
     * @param {string} email
     * @param {string} oldPassword
     * @param {string} newPassword
     * @returns {Promise<api.DatalessSuccessResponse>}
     */
    changePassword(token, email, oldPassword, newPassword) {
        return new Promise((resolve, reject) => {
            const data = {
                // TODO: nunca enviar password en plain text. Solo enviar un hash derivado (ej: usando PBKDF2). En el back se le agregaría salt y se calcularía otro hash antes de guardar.
                email: email,
                old: oldPassword,
                new: newPassword
            };
            axios.post(BASE_API_URL + '/change-password', data, {
                headers: {
                    'Content-Type': 'application/json',
                    'token': token
                }
            })
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.error(error);
                    reject();
                });
        });
    },

    /**
     * @param {Object} obj
     * @param {api.DatosRegistroInstructor} obj.requestData
     * @param {string} obj.token
     * @returns {Promise<api.SuccessResponse<api.RespuestaRegistroInstructor>>}
     */
    registrarInstructor({ requestData, token }) {
        return new Promise((resolve, reject) => {
            fetch(BASE_API_URL + '/instructor', {
                method: 'POST',
                headers: {
                    'token': token,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(requestData)
            })
                .then(response => {
                    return response.json();
                })
                .then(data => {
                    resolve(data);
                })
                .catch(error => {
                    console.error(error);
                    reject();
                });
        });
    },

    /**
     * @param {Object} obj
     * @param {api.DatosRegistroUsuario} obj.datosRegistro
     * @param {string} obj.token
     * @returns {Promise<api.SuccessResponse<api.RespuestaRegistroUsuario>>}
     */
    registrarUsuario({ datosRegistro, token }) {
        return new Promise((resolve, reject) => {
            axios.post(BASE_API_URL + '/insertar-registro', datosRegistro, {
                headers: {
                    'Content-Type': 'application/json',
                    'token': token
                }
            })
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.error(error);
                    reject();
                });
        });
    },

    /**
     * @param {number} id_pais
     * @returns {Promise<api.SuccessResponse<api.BannerAPI[]>>}
     */
    banners(id_pais) {
        return new Promise((resolve, reject) => {
            fetch(BASE_API_URL + '/banner?id_pais=' + id_pais, {
                method: 'GET'
            })
                .then(response => {
                    return response.json();
                })
                .then(data => {
                    resolve(data);
                })
                .catch(error => {
                    console.error(error);
                    reject();
                });
        });
    },

    /**
     * @param {Object} obj
     * @param {number} obj.id_usuario
     * @param {string} obj.token
     * @returns {Promise<api.SuccessResponse<api.Organization[]>>}
     */
    getOrganizations({ id_usuario, token }) {
        return new Promise((resolve, reject) => {
            fetch(BASE_API_URL + '/organizacion?id_usuario=' + id_usuario, {
                method: 'GET',
                headers: {
                    'token': token
                }
            })
                .then(response => {
                    return response.json();
                })
                .then(data => {
                    resolve(data);
                })
                .catch(error => {
                    console.error(error);
                    reject();
                });
        });
    },

    /**
     * @param {Object} obj
     * @param {api.CreateOrganizationData} obj.requestData
     * @param {string} obj.token
     * @returns {Promise<api.DatalessSuccessResponseExt>}
     */
    createOrganization({ requestData, token }) {
        return new Promise((resolve, reject) => {
            /** @type {number} */
            let status;
            fetch(BASE_API_URL + '/organizacion', {
                method: 'POST',
                headers: {
                    'token': token,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(requestData)
            })
                .then(response => {
                    status = response.status;
                    return response.json();
                })
                .then(data => {
                    resolve({
                        data: data,
                        status: status
                    });
                })
                .catch(error => {
                    console.error(error);
                    reject();
                });
        });
    },

    /**
     * @param {Object} obj
     * @param {api.VincularPlanData} obj.requestData
     * @param {string} obj.token
     * @returns {Promise<api.SuccessResponseExt<api.RespuestaVincular>>}
     */
    vincularPlan({ requestData, token }) {
        return new Promise((resolve, reject) => {
            /** @type {number} */
            let status;
            fetch(BASE_API_URL + '/plan/vincular', {
                method: 'POST',
                headers: {
                    'token': token,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(requestData)
            })
                .then(response => {
                    status = response.status;
                    return response.json();
                })
                .then(data => {
                    resolve({
                        data: data,
                        status: status
                    });
                })
                .catch(error => {
                    console.error(error);
                    reject();
                });
        });
    },

    /**
     * @param {Object} obj
     * @param {number} obj.id_usuario
     * @param {string} obj.token
     * @returns {Promise<api.SuccessResponse<api.RegistroVinculación[]>>}
     */
    getVinculaciones({ id_usuario, token }) {
        return new Promise((resolve, reject) => {
            fetch(BASE_API_URL + '/reporte-vinculacion?id_usuario=' + id_usuario, {
                method: 'GET',
                headers: {
                    'token': token,
                    'Content-Type': 'application/json'
                }
            })
                .then(response => {
                    return response.json();
                })
                .then(data => {
                    resolve(data);
                })
                .catch(error => {
                    console.error(error);
                    reject();
                });
        });
    },

    /**
     * @param {Object} obj
     * @param {api.DesvincularPlanData} obj.requestData
     * @param {string} obj.token
     * @returns {Promise<api.DatalessSuccessResponseExt>}
     */
    removerVinculado({ requestData, token }) {
        return new Promise((resolve, reject) => {
            /** @type {number} */
            let status;
            fetch(BASE_API_URL + '/desvincular/usuario/plan', {
                method: 'DELETE',
                headers: {
                    'token': token,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(requestData)
            })
                .then(response => {
                    return response.json();
                })
                .then(data => {
                    resolve({
                        status: status,
                        data: data
                    });
                })
                .catch(error => {
                    console.error(error);
                    reject();
                });
        });
    },

    /** @namespace */
    instructor: {

        /**
         * @param {Object} obj
         * @param {string} obj.token
         * @param {number} obj.courseId
         * @param {number} obj.userId
         * @returns {Promise<api.SuccessResponse<api.ListaClasesInstructor>>}
         * @todo verificar estructuras retornadas por este API.
         * @todo api para obtener solo una clase.
         */
        getClasesInstructor({ token, courseId, userId }) {
            return new Promise((resolve, reject) => {
                fetch(BASE_API_URL + `/instructor/${userId}/curso/${courseId}/clases`, {
                    method: 'GET',
                    headers: {
                        'token': token,
                        'Content-Type': 'application/json'
                    }
                })
                    .then(response => {
                        return response.json();
                    })
                    .then(data => {
                        // workaround para el problema que tiene actualmente el API (por mal diseño). Cuando no hay unidades trae una vacía con id_unidad = al string "default".
                        // TODO: corregir en el back. Si no hay unidades, "n_unidades" es cero al menos, pero "unidades" debería estar vacío ({}).
                        const payload = /** @type {api.SuccessResponse<api.ListaClases>} */(data).data;
                        if (payload.n_unidades === 0 && !payload.unidades["0"]?.id_unidad) {
                            delete payload.unidades["0"];
                        }
                        resolve(data);
                    })
                    .catch(error => {
                        console.error(error);
                        reject();
                    });
            });
        },

        /**
         * @param {Object} obj
         * @param {number} obj.userId
         * @param {string} obj.token
         * @returns {Promise<api.SuccessResponse<api.CursoInstructor[]>>}
         */
        getCursos({ userId, token }) {
            return new Promise((resolve, reject) => {
                fetch(BASE_API_URL + '/instructor/' + userId + '/cursos', {
                    method: 'GET',
                    headers: {
                        'token': token,
                        'Content-Type': 'application/json'
                    }
                })
                    .then(response => {
                        return response.json();
                    })
                    .then(data => {
                        resolve(data);
                    })
                    .catch(error => {
                        console.error(error);
                        reject();
                    });
            });
        },

        /**
         * @param {Object} obj
         * @param {number} obj.courseId
         * @param {string} obj.token
         * @returns {Promise<api.SuccessResponseExt<api.UnidadGetUnidadesInstructor[]>>}
         */
        getUnidades({ courseId, token }) {
            return new Promise((resolve, reject) => {
                /** @type {number} */
                let status;
                fetch(BASE_API_URL + `/curso/${courseId}/unidades`, {
                    method: 'GET',
                    headers: {
                        'token': token,
                        'Content-Type': 'application/json'
                    }
                })
                    .then(response => {
                        status = response.status;
                        return response.json();
                    })
                    .then(data => {
                        resolve({
                            data: data,
                            status: status
                        });
                    })
                    .catch(error => {
                        console.error(error);
                        reject();
                    });
            });
        },

        /**
         * @param {Object} obj
         * @param {number} obj.courseId
         * @param {string} obj.token
         * @returns {Promise<api.SuccessResponseExt<*>>}
         */
        publicarCurso({ courseId, token }) {
            return new Promise((resolve, reject) => {
                /** @type {number} */
                let status;
                fetch(BASE_API_URL + '/publicar-curso?id_curso=' + courseId, {
                    method: 'PUT',
                    headers: {
                        'token': token
                    }
                })
                    .then(response => {
                        status = response.status;
                        return response.json();
                    })
                    .then(data => {
                        resolve({
                            data: data,
                            status: status
                        });
                    })
                    .catch(error => {
                        console.error(error);
                        reject();
                    });
            });
        },

        /**
         * @param {Object} obj
         * @param {string} obj.token
         * @param {number} obj.claseId
         * @returns {Promise<api.SuccessResponse<*>>}
         */
        eliminarClase({ token, claseId }) {
            return new Promise((resolve, reject) => {
                fetch(BASE_API_URL + '/clase/' + claseId, {
                    method: 'DELETE',
                    headers: {
                        'token': token
                    }
                })
                    .then(response => {
                        return response.json();
                    })
                    .then(data => {
                        resolve(data);
                    })
                    .catch(error => {
                        console.error(error);
                        reject();
                    });
            });
        },

        /**
         * @param {Object} obj
         * @param {string} obj.token
         * @param {number} obj.unidadId
         * @returns {Promise<api.SuccessResponse<*>>}
         */
        eliminarUnidad({ token, unidadId }) {
            return new Promise((resolve, reject) => {
                fetch(BASE_API_URL + '/unidad/' + unidadId, {
                    method: 'DELETE',
                    headers: {
                        'token': token
                    }
                })
                    .then(response => {
                        return response.json();
                    })
                    .then(data => {
                        resolve(data);
                    })
                    .catch(error => {
                        console.error(error);
                        reject();
                    });
            });
        },

        /**
         * @param {Object} obj
         * @param {string} obj.token
         * @param {number} obj.unidadId
         * @param {string} obj.nuevoNombre
         * @returns {Promise<api.SuccessResponse<*>>}
         * @todo permitir modificar cualquier atributo de la unidad (o crear otra función genérica).
         */
        renombrarUnidad({ token, unidadId, nuevoNombre }) {
            return new Promise((resolve, reject) => {
                fetch(BASE_API_URL + '/unidad/' + unidadId, {
                    method: 'PUT',
                    headers: {
                        'token': token,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        nombre: nuevoNombre
                    })
                })
                    .then(response => {
                        return response.json();
                    })
                    .then(data => {
                        resolve(data);
                    })
                    .catch(error => {
                        console.error(error);
                        reject();
                    });
            });
        },

        /**
         * @param {Object} obj
         * @param {string} obj.token
         * @param {number} obj.cursoId
         * @param {api.OrdenClases} obj.nuevoOrden
         * @returns {Promise<api.SuccessResponse<*>>}
         * @todo permitir modificar cualquier atributo de la unidad (o crear otra función genérica).
         */
        reordenarClases({ token, cursoId, nuevoOrden }) {
            return new Promise((resolve, reject) => {
                fetch(BASE_API_URL + '/clases-orden', {
                    method: 'PUT',
                    headers: {
                        'token': token,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        id_curso: cursoId,
                        unidades: nuevoOrden
                    })
                })
                    .then(response => {
                        return response.json();
                    })
                    .then(data => {
                        resolve(data);
                    })
                    .catch(error => {
                        console.error(error);
                        reject();
                    });
            });
        },

        /**
         * @returns {Promise<api.SuccessResponse<api.Tematica[]>>}
         */
        getListaTematicas() {
            return new Promise((resolve, reject) => {
                fetch(BASE_API_URL + '/tematicas', {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(response => {
                        return response.json();
                    })
                    .then(data => {
                        resolve(data);
                    })
                    .catch(error => {
                        console.error(error);
                        reject();
                    });
            });
        },

        /**
         * @param {Object} obj
         * @param {string} obj.token
         * @param {number} obj.id_usuario
         * @param {number} obj.id_curso
         * @param {File} obj.video
         * @param {(progress: number) => void} [obj.onProgress] - progress: decimal entre 0 y 1
         * @returns {Promise<api.SuccessResponse<string> & { url_youtube: string }>} - el string en "data" corresponde al URL del video.
         */
        subirVideoPromocional({ token, id_usuario, id_curso, video, onProgress }) {
            /**
             * @param {ProgressEvent} evt
             */
            const progressHandler = (evt) => {
                const progress = evt.loaded / evt.total;
                onProgress(progress);
            };
            return new Promise((resolve, reject) => {
                let formData = new FormData();
                formData.append("video", video, video.name);
                axios.post(BASE_API_URL + `/video-promocional?id_usuario=${id_usuario}&id_curso=${id_curso}`, formData, {
                    headers: {
                        'token': token
                    },
                    onUploadProgress: typeof onProgress === 'function' ? progressHandler : undefined
                })
                    .then(response => {
                        resolve(response.data);
                    })
                    .catch(error => {
                        console.error(error);
                        reject();
                    });
            });
        },

        /**
         * @param {Object} obj
         * @param {string} obj.token
         * @param {number} obj.id_usuario
         * @param {number} obj.id_curso
         * @returns {Promise<api.DatalessSuccessResponseExt>}
         */
        eliminarVideoPromocional({ token, id_usuario, id_curso }) {
            return new Promise((resolve, reject) => {
                /** @type {number} */
                let status;
                fetch(BASE_API_URL + `/video-promocional?id_usuario=${id_usuario}&id_curso=${id_curso}`, {
                    method: 'DELETE',
                    headers: {
                        'token': token
                    }
                })
                    .then(response => {
                        status = response.status;
                        return response.json();
                    })
                    .then(data => {
                        resolve({
                            data: data,
                            status: status
                        });
                    })
                    .catch(error => {
                        console.error(error);
                        reject();
                    });
            });
        }

    },

    /** @namespace */
    pagos: {

        /**
         * Ejecutar script parche de validación de pagos. Para resolver problemas de verificación y actualización de estado de pagos en la pasarela.
         * @param {Object} obj
         * @param {number} obj.userId
         * @param {string} obj.token
         * @returns {Promise<api.DatalessSuccessResponse>}
         */
        validarPagos({ userId, token }) {
            return new Promise((resolve, reject) => {
                fetch(BASE_API_URL + '/validarpagos/' + userId, {
                    method: 'POST',
                    headers: {
                        'token': token,
                        'Content-Type': 'application/json'
                    }
                })
                    .then(response => {
                        if (response.status === 401 || response.status === 403) {
                            reject({ code: response.status });
                            return;
                        }
                        return response.json();
                    })
                    .then(data => {
                        resolve(data);
                    })
                    .catch(error => {
                        console.error(error);
                        reject(error);
                    });
            });
        },

        /**
         * Validación de cupón de descuento.
         * @param {Object} obj
         * @param {string} obj.token
         * @param {Object} obj.payload
         * @returns {Promise<api.SuccessResponseExt<api.SuccessResponse<api.DataCoupon>>>}
         */
        validarCupon({ token, payload }) {
            return new Promise((resolve, reject) => {
                /** @type {number} */
                let status;
                fetch(BASE_API_URL + '/validar-cupon', {
                    method: 'POST',
                    headers: {
                        'token': token,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(payload)
                })
                    .then(response => {
                        status = response.status;
                        return response.json();
                    })
                    .then(data => {
                        resolve({
                            data: data,
                            status: status
                        });
                    })
                    .catch(error => {
                        console.error(error);
                        reject();
                    });
            });
        },

        /**
         * @param {Object} obj
         * @param {string} obj.token
         * @param {api.DataFormularioCoupon} obj.payload
         * @returns {Promise<api.DatalessSuccessResponse>}
         */
        enviarFormularioCupon({ token, payload }) {
            return new Promise((resolve, reject) => {
                fetch(BASE_API_URL + '/formulario-cupon', {
                    method: 'POST',
                    headers: {
                        'token': token,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(payload)
                })
                    .then(response => {
                        return response.json();
                    })
                    .then(data => {
                        resolve(data);
                    })
                    .catch(error => {
                        console.error(error);
                        reject();
                    });
            });
        }

    },

    /** @namespace */
    courseEdit: {
        /**
         * @returns {Promise<api.SuccessResponse<api.CategoryCourse[]>>}
         */
        getCategories() {
            return new Promise((resolve, reject) => {
                fetch(BASE_API_URL + '/categorias')
                    .then(response => {
                        return response.json();
                    })
                    .then(data => {
                        resolve(data);
                    })
                    .catch(error => {
                        console.error(error);
                        reject();
                    });
            });
        },

        /**
         * @param {Object} obj
         * @param {api.CursoUpload} obj.CourseUpload
         * @param {string} obj.token
         * @returns {Promise<api.SuccessResponse<*>>}
         */
        createCurso({ CourseUpload, token }) {
            return new Promise((resolve, reject) => {
                axios.post(BASE_API_URL + '/cursos', CourseUpload, {
                    headers: {
                        'token': token,
                        'Content-Type': 'application/json'
                    }
                })
                    .then(response => {
                        resolve(response.data);
                    })
                    .catch(error => {
                        console.error(error);
                        reject();
                    });
            });
        },

        /**
         * @param {Object} obj
         * @param {api.CursoUpload} obj.CourseUpload
         * @param {number} obj.courseId
         * @param {string} obj.token
         * @returns {Promise<api.SuccessResponse<*>>}
         */
        updateCurso({ CourseUpload, courseId, token }) {
            return new Promise((resolve, reject) => {
                axios.put(BASE_API_URL + `/curso/${courseId}/actualizar`, CourseUpload, {
                    headers: {
                        'token': token,
                        'Content-Type': 'application/json'
                    }
                })
                    .then(response => {
                        resolve(response.data);
                    })
                    .catch(error => {
                        console.error(error);
                        reject();
                    });
            });
        }
    },

    autoriaUnidades: {

        /**
         * @param {Object} obj
         * @param {number} obj.courseId
         * @param {string} obj.token
         * @param {api.PayloadCrearUnidad} obj.unidad
         * @returns {Promise<api.SuccessResponse<api.RespuestaCrearCurso>>}
         */
        crearUnidad({ courseId, token, unidad }) {
            return new Promise((resolve, reject) => {
                axios.post(BASE_API_URL + '/curso/' + courseId + '/unidad', unidad, {
                    headers: {
                        'token': token,
                        'Content-Type': 'application/json'
                    }
                })
                    .then(response => {
                        resolve(response.data);
                    })
                    .catch(error => {
                        console.error(error);
                        reject();
                    });
            });
        }

    },

    autoriaClases: {

        /**
         * @param {Object} obj
         * @param {string} obj.token
         * @param {api.DataCrearClase} obj.payload
         * @returns {Promise<api.SuccessResponse<api.RespuestaCrearClase>>}
         */
        crearClase({ token, payload }) {
            return new Promise((resolve, reject) => {
                axios.post(BASE_API_URL + '/clases', payload, {
                    headers: {
                        'token': token,
                        'Content-Type': 'application/json'
                    }
                })
                    .then(response => {
                        resolve(response.data);
                    })
                    .catch(error => {
                        console.error(error);
                        reject();
                    });
            });
        },

        /**
         * @param {Object} obj
         * @param {string} obj.token
         * @param {number} obj.claseId
         * @param {api.DataCrearClase} obj.payload
         * @returns {Promise<api.SuccessResponse<api.RespuestaCrearClase>>}
         */
        actualizarClase({ token, claseId, payload }) {
            return new Promise((resolve, reject) => {
                axios.put(BASE_API_URL + '/clase/' + claseId, payload, {
                    headers: {
                        'token': token,
                        'Content-Type': 'application/json'
                    }
                })
                    .then(response => {
                        resolve(response.data);
                    })
                    .catch(error => {
                        console.error(error);
                        reject();
                    });
            });
        },

        /**
         * @param {Object} obj
         * @param {string} obj.token
         * @param {api.DataCrearClaseEvaluation} obj.payload
         * @returns {Promise<api.SuccessResponse<api.RespuestaCrearClaseEvaluation>>}
         */
        crearClaseEvaluacion({ token, payload }) {
            return new Promise((resolve, reject) => {
                axios.post(BASE_API_URL + '/test', payload, {
                    headers: {
                        'token': token,
                        'Content-Type': 'application/json'
                    }
                })
                    .then(response => {
                        resolve(response.data);
                    })
                    .catch(error => {
                        console.error(error);
                        reject();
                    });
            });
        },

        /**
         * @param {Object} obj
         * @param {string} obj.token
         * @param {api.DataCrearClaseEvaluation} obj.payload
         * @returns {Promise<api.SuccessResponse<api.RespuestaCrearClaseEvaluation>>}
         */
        crearClaseEvaluacionFormativa({ token, payload }) {
            return new Promise((resolve, reject) => {
                axios.post(BASE_API_URL + '/evaluacion', payload, {
                    headers: {
                        'token': token,
                        'Content-Type': 'application/json'
                    }
                })
                    .then(response => {
                        resolve(response.data);
                    })
                    .catch(error => {
                        console.error(error);
                        reject();
                    });
            });
        },

        /**
         * @param {Object} obj
         * @param {string} obj.token
         * @param {number} obj.claseId
         * @param {string} obj.nuevoTitulo
         * @returns {Promise<api.SuccessResponse<*>>}
         * @todo permitir modificar cualquier atributo de la clase (o crear otra función genérica).
         */
        renombrarClase({ token, claseId, nuevoTitulo }) {
            return new Promise((resolve, reject) => {
                fetch(BASE_API_URL + '/clase/' + claseId, {
                    method: 'PUT',
                    headers: {
                        'token': token,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        titulo: nuevoTitulo
                    })
                })
                    .then(response => {
                        return response.json();
                    })
                    .then(data => {
                        resolve(data);
                    })
                    .catch(error => {
                        console.error(error);
                        reject();
                    });
            });
        },

        /**
         * @param {Object} obj
         * @param {string} obj.token
         * @param {*} obj.payload
         * @returns {Promise<api.SuccessResponse<api.Recurso>>}
         */
        registrarRecurso({ token, payload }) {
            return new Promise((resolve, reject) => {
                axios.post(BASE_API_URL + '/recursos', payload, {
                    headers: {
                        'token': token,
                        'Content-Type': 'application/json'
                    }
                })
                    .then(response => {
                        resolve(response.data);
                    })
                    .catch(error => {
                        console.error(error);
                        reject();
                    });
            });
        },

        /**
         * El API verifica que el usuario sea el owner de la clase, llama al API de aws que elimina recurso y retorna el success dependiendo de la respuesta de AWS.
         * Al eliminar exitosamente un recurso, lo marca en la BD con estado = 3.
         * @param {Object} obj
         * @param {string} obj.token
         * @param {number} obj.id_curso
         * @param {number} obj.id_clase
         * @param {number} obj.id_usuario
         * @param {'zip' | 'video'} obj.tipo
         * @returns {Promise<api.SuccessResponseExt<*>>}
         */
        eliminarRecurso({ id_curso, id_clase, id_usuario, tipo, token }) {
            return new Promise((resolve, reject) => {
                /** @type {number} */
                let status;
                fetch(BASE_API_URL + '/reemplazar-recurso', {
                    method: 'DELETE',
                    headers: {
                        'token': token,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        id_curso, id_clase, id_usuario, tipo
                    })
                })
                    .then(response => {
                        status = response.status;
                        return response.json();
                    })
                    .then(data => {
                        resolve({
                            data: data,
                            status: status
                        });
                    })
                    .catch(error => {
                        console.error(error);
                        reject();
                    });
            });
        }

    },

    evaluacionesInstructor: {

        /**
         * @param {Object} obj
         * @param {string} obj.token
         * @param {number} obj.evaluationId
         * @returns {Promise<api.SuccessResponse<api.ClaseEvaluationInstructor>>}
         */
        getEvaluacion({ token, evaluationId }) {
            return new Promise((resolve, reject) => {
                fetch(BASE_API_URL + '/evaluacion/' + evaluationId + '/instructor', {
                    method: 'GET',
                    headers: {
                        'token': token
                    }
                })
                    .then(response => {
                        return response.json();
                    })
                    .then(data => {
                        resolve(data);
                    })
                    .catch(error => {
                        console.error(error);
                        reject();
                    });
            });
        },

        /**
         * @param {Object} obj
         * @param {string} obj.token
         * @param {number} obj.evaluationId
         * @param {string} obj.nombre
         * @returns {Promise<api.SuccessResponse<api.ClaseEvaluationInstructor>>}
         * @todo permitir actualizar el nombre de la evaluación como parte de `autoriaClases.actualizarClase` (api '/clase/:id_clase')
         */
        updateEvaluation({ token, evaluationId, nombre }) {
            return new Promise((resolve, reject) => {
                fetch(BASE_API_URL + '/evaluacion/' + evaluationId + '/instructor', {
                    method: 'PUT',
                    headers: {
                        'token': token,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        nombre
                    })
                })
                    .then(response => {
                        return response.json();
                    })
                    .then(data => {
                        resolve(data);
                    })
                    .catch(error => {
                        console.error(error);
                        reject();
                    });
            });
        },

        /**
         * @param {Object} obj
         * @param {string} obj.token
         * @param {number} obj.evaluationId
         * @returns {Promise<api.SuccessResponse<*>>}
         */
        eliminarEvaluation({ token, evaluationId }) {
            return new Promise((resolve, reject) => {
                fetch(BASE_API_URL + '/evaluacion/' + evaluationId + '/instructor', {
                    method: 'DELETE',
                    headers: {
                        'token': token
                    }
                })
                    .then(response => {
                        return response.json();
                    })
                    .then(data => {
                        resolve(data);
                    })
                    .catch(error => {
                        console.error(error);
                        reject();
                    });
            });
        },

        /**
         * @param {Object} obj
         * @param {string} obj.token
         * @param {api.DataCrearClaseEvaluation & { id_clase: number }} obj.payload
         * @returns {Promise<api.SuccessResponse<{ id_evaluacion: number }>>}
         * @todo permitir actualizar con esta API data de la clase. Para no tener que llamar dos APIs distintas al crear nueva evaluación al editar una clase existente (editar > eliminar evaluación > agregar preguntas > guardar clase).
         * @todo que retorne los datos de la evaluación para no tener que recargar la información.
         */
        crearEvaluationClaseExistente({ token, payload }) {
            return new Promise((resolve, reject) => {
                fetch(BASE_API_URL + '/evaluacion-Test-Actualizar', {
                    method: 'POST',
                    headers: {
                        'token': token,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(payload)
                })
                    .then(response => {
                        return response.json();
                    })
                    .then(data => {
                        resolve(data);
                    })
                    .catch(error => {
                        console.error(error);
                        reject();
                    });
            });
        },

        /**
         * @param {Object} obj
         * @param {string} obj.token
         * @param {number} obj.preguntaId
         * @returns {Promise<api.DatalessSuccessResponse>}
         */
        eliminarPregunta({ token, preguntaId }) {
            return new Promise((resolve, reject) => {
                fetch(BASE_API_URL + `/pregunta/${preguntaId}/alternativa/instructor`, {
                    method: 'DELETE',
                    headers: {
                        'token': token
                    }
                })
                    .then(response => {
                        return response.json();
                    })
                    .then(data => {
                        resolve(data);
                    })
                    .catch(error => {
                        console.error(error);
                        reject();
                    });
            });
        },

        /**
         * @param {Object} obj
         * @param {string} obj.token
         * @param {number} obj.evaluationId
         * @param {api.PreguntaEdición} obj.payload
         * @returns {Promise<api.SuccessResponse<api.RespuestaAgregarPregunta>>}
         */
        agregarPregunta({ token, evaluationId, payload }) {
            return new Promise((resolve, reject) => {
                fetch(BASE_API_URL + `/pregunta/evaluacion/${evaluationId}/alternativa/instructor`, {
                    method: 'POST',
                    headers: {
                        'token': token,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ ...payload, alternativas: [payload.alternativas] }) // enviar alternativas dentro de doble arreglo
                })
                    .then(response => {
                        return response.json();
                    })
                    .then(data => {
                        resolve(data);
                    })
                    .catch(error => {
                        console.error(error);
                        reject();
                    });
            });
        },

        /**
         * @param {Object} obj
         * @param {string} obj.token
         * @param {number} obj.evaluationId - actualmente este API lo ignora
         * @param {number} obj.preguntaId
         * @param {string} obj.enunciado
         * @param {string} obj.justificacion
         * @param {number} obj.tipo - actualmente este API lo ignora
         * @param {api.Alternativa[]} obj.nuevasAlternativas
         * @returns {Promise<api.DatalessSuccessResponse>}
         * @todo hacer opcionales `enunciado` y `justificacion`. Ignorar en el API si no se incluyen. No requerir `evaluationId` en el back.
         * @todo que el API responda con la nueva estructura de la pregunta.
         */
        remplazarAlternativas({ token, evaluationId, preguntaId, enunciado, justificacion, nuevasAlternativas, tipo }) {
            return new Promise((resolve, reject) => {
                fetch(BASE_API_URL + `/pregunta/evaluacion/${evaluationId}/alternativa/instructor`, {
                    method: 'PUT',
                    headers: {
                        'token': token,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        id_pregunta: preguntaId,
                        enunciado: enunciado,
                        justificacion: justificacion,
                        tipo: tipo,
                        alternativas: [nuevasAlternativas] // enviar alternativas dentro de doble arreglo
                    })
                })
                    .then(response => {
                        return response.json();
                    })
                    .then(data => {
                        resolve(data);
                    })
                    .catch(error => {
                        console.error(error);
                        reject();
                    });
            });
        }

    }

};
Object.freeze(api);
Object.freeze(api.instructor);
Object.freeze(api.pagos);
Object.freeze(api.courseEdit);
Object.freeze(api.autoriaUnidades);
Object.freeze(api.autoriaClases);
Object.freeze(api.evaluacionesInstructor);

export default api;
