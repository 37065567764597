import React, { useEffect, useState } from "react";
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { CardActionArea } from "@material-ui/core";
import Grid from '@material-ui/core/Grid';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';

//Breadcrumbs
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';

//MODAL MATERIAL UI
import DialogContent from '@material-ui/core/DialogContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import Container from '@material-ui/core/Container';
import { theme as tema } from "../util/theme";

import api from "../lib/api";

const useStyles3 = makeStyles((theme) => ({
    root: {
        display: 'flex',
        '& > * + *': {
            marginLeft: theme.spacing(2),
        },
    },
}));



function CategoriesPage() {

    useEffect(() => {
        document.title = "Categorías";
    });

    const classes3 = useStyles3();

    const [openSnackbarError, setOpenSnackbarError] = React.useState(false);
    const [openmensbackend, setOpenmensbackend] = React.useState(false);
    const [mensajebackend, setMensajebackend] = React.useState("");

    var [disableLoading, setDisableLoading] = useState(false);

    const handleCloseMens = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenmensbackend(false);
    };

    const handleCloseSnackbarError = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenSnackbarError(false);
    };

    const [cate, setCate] = useState(/** @type {api.Category[]} */([]));

    useEffect(() => {
        api.getCategories()
            .then(data => {
                setCate(data.data);
                if (data.success === false) {
                    setMensajebackend(data.mensaje);
                    setOpenmensbackend(true);
                }
                setDisableLoading(true);
            })
            .catch(function (error) {
                const respuesta = error;
                console.log("Error:", respuesta);
                setOpenSnackbarError(true);
                setDisableLoading(true);
            });
    }, []);


    var categoryList = [];
    for (let i = 0; i < cate.length; i++) {
        // let duration;
        // if (cursos[i].duration == null) {
        //     duration = "-"
        // } else {
        //     duration = cursos[i].duration
        // }
        var nom_imagen = new String(cate[i].imagen);
        categoryList.push(

            <Grid container item xs={12} sm={12} md={6} lg={4} xl={3} spacing={0} direction="column" alignItems="center" justifyContent="center" key={"popular-course-" + i}>



                <div style={{ height: '70px' }}></div>
                <div className="categorySmall rel">

                    <Container sx={{ py: 1 }} maxWidth="sm">

                        <Grid container spacing={3} alignContent="center" alignItems="center">

                            <Card
                                sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}
                                style={{ maxWidth: '255px', height: '272px' }}
                                className="tarjeta-ecampus"
                            >
                                <CardActionArea href={"#/category/" + cate[i].id + "/" + cate[i].nombre} style={{ height: '100%' }} className="cardareacategories">
                                    <div className="wrapper-imagen-tarjeta">
                                        <div className="imagen-tarjeta">
                                            <img src={require('../ui/imagenes_categorias/' + nom_imagen)}></img>
                                        </div>
                                    </div>
                                    <CardContent sx={{ flexGrow: 1 }} className="description-tarjeta">
                                        <Typography variant="body2" style={{ color: tema.current === "light" ? '#d87218' : '#ffffff' }}>
                                            {cate[i].nombre}
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>

                        </Grid>
                    </Container>
                </div>

                <div style={{ height: '70px' }}></div>
            </Grid>
        );
    }



    return (

        <div className="App rel">

            <Breadcrumbs aria-label="breadcrumb" className="breadcrumbs_link_inneret">

                <Typography color="textPrimary" className="breadcrumbs_link">
                    Categorías
                </Typography>
            </Breadcrumbs>

            <Snackbar open={openSnackbarError} autoHideDuration={5000} onClose={handleCloseSnackbarError} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
                <Alert severity="error" variant="filled" >
                    No se encontraron datos.
                </Alert>
            </Snackbar>
            <Snackbar open={openmensbackend} autoHideDuration={3000} onClose={handleCloseMens} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
                <Alert onClose={handleCloseMens} severity="error" variant="filled">
                    {mensajebackend}
                </Alert>
            </Snackbar>

            <div className="tituloInicial rel">
            <h1 className="title_section s24 fontb c333">Categorías</h1>
            </div>
            <br />
            <div className="categories">

                {disableLoading != true &&
                    <div id="cargando" className={classes3.root}>

                        <DialogContent className="CentrarLoadingSinModal">
                            <CircularProgress className="CircularProgress" style={{ alignSelf: 'center' }} />
                        </DialogContent>
                    </div>
                }
                <Grid container spacing={1} item xs={12} direction="row" >
                    {categoryList}
                </Grid>
            </div>

        </div>

    )


}

export default CategoriesPage;