import React, { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router";
import { useSnackbar } from "notistack";
import CircularProgress from '@material-ui/core/CircularProgress';
import TarjetaEcampus from "../componentes/TarjetaEcampus";
import api from "../lib/api";
import routeHelper from "../util/routeHelper";
import utils from "../util/utils";
import { TIPO_PLAN } from "../lib/constants";
import i18n from "../lib/i18n";
import { useSession } from "../util/session";
import { BotonContext } from "../BotonContext";

export default function MultiUser() {

    const { data_user } = useContext(BotonContext);
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();
    const { isLogged } = useSession();
    const [planes, setPlanes] = useState(/** @type {api.Plan[]} */(null));

    const gotoForm = function () {
        history.push(routeHelper.formaContactoPrograma);
    };

    /**
     * @returns {Promise<api.Plan[]>}
     */
    const getPlanes = async function () {
        try {
            const respuesta = await api.getPlanesMultiUsuario({
                id_pais: i18n.getCountry().id_pais,
                id_usuario: isLogged() ? data_user.id_usuario : undefined
            });
            if (!respuesta.success) throw new Error(respuesta.message || respuesta.mensaje);
            return respuesta.data;
        } catch (error) {
            console.error("Error: " + error);
            enqueueSnackbar("Error en la descarga de planes", {
                variant: 'error'
            });
        }
    };

    useEffect(function () {
        getPlanes().then((planesApi) => {
            setPlanes(planesApi);
        });
    }, []);

    return (<>
        <div style={{ padding: '0px 2rem', marginTop: '90px' }}>
            <h2 className="title_section s24 fontb">Planes multiusuario</h2>
            <div style={{ display: 'flex', justifyContent: 'center', minHeight: '30rem', marginTop: '1.5rem', marginBottom: '3rem', alignItems: 'center', gap: '2rem' }}>
                {!!planes?.length && planes.map(p => <>
                    <TarjetaEcampus
                        image={utils.getImagenCurso(p)}
                        text1={p.nombre}
                        text2={p.descripcion}
                        href={p.comprado ? `/manage-plan/${p.id}/${p.nombre}` : `/detail-multiuser/${p.id}/Plan%20multiusuario`}
                        price={p.tipo_plan === 1 ? p.precio : undefined}
                        showBuyButtons={p.tipo_plan === 1}
                        plan={p}
                    >
                        {(p.tipo_plan === TIPO_PLAN.FULL || !!p.comprado) &&
                            <div style={{ width: '100%', display: 'flex', justifyContent: 'center', paddingTop: '1.4rem' }}>
                                <button className="button_accept MuiButton-contained" onClick={gotoForm} style={{ width: '10rem', padding: '0.2rem' }}>{p.comprado ? "Contáctate con nosotros" : "Ir a formulario"}</button>
                            </div>
                        }
                    </TarjetaEcampus>
                </>)}
                {planes === null &&
                    <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '1rem' }}>
                        <CircularProgress className="CircularProgress" style={{ alignSelf: 'center' }} />
                    </div>
                }
            </div>
        </div>
    </>);
}
