import React, { useState } from "react";
import CircularProgress from '@material-ui/core/CircularProgress';

/**
 * @param {Object} params
 * @param {boolean} [params.center=true] - default = true
 * @returns {JSX.Element}
 */
export default function LoadingPanel({ center = true }) {
    const [position, setPosition] = useState(/** @type {import("csstype").Property.Position} */(null));
    const [top, setTop] = useState(null);
    if (center) {
        setTimeout(() => {
            const panel = document.querySelector('#cargando');
            if (!panel) return;
            const CUSTOM_OFFSET = -10; // en pixeles (ajustado empíricamente para que se vea mejor centrado verticalmente)
            const containerTop = panel.getBoundingClientRect().top + window.scrollY; // igual a $().offset().top ??
            const computed = window.getComputedStyle(panel, null);
            const padding = parseInt(computed.paddingTop) + parseInt(computed.paddingBottom);
            const containerHeight = panel.clientHeight - padding; // igual a $().innerHight() ??
            const viewportHeight = window.innerHeight; // excluyendo scrollbars
            if (containerTop + containerHeight < viewportHeight) {
                // centrado automático del flex (manejado directamente en el html) (dejar "position" y "top" del spinner vacíos)
            } else {
                // centrado manual según parte visible del panel
                const spinner = document.querySelector('#spinner-cargando');
                if (!spinner) return;
                const visibleHeight = viewportHeight - containerTop;
                const computed2 = window.getComputedStyle(spinner, null);
                const padding2 = parseInt(computed2.paddingTop) + parseInt(computed2.paddingBottom);
                const spinnerHeight = spinner.clientHeight - padding2; // igual a $().innerHight() ??
                const manualTop = ( visibleHeight / 2 ) - (spinnerHeight / 2) + CUSTOM_OFFSET;
                setPosition('absolute');
                setTop(manualTop > 0 ? `calc(${manualTop}px - 4%)` : '2px');
            }
        }, 0);
    }
    return (
        <div id="cargando" style={{ display: 'flex', position: 'absolute', left: 0, top: 0, backgroundColor: 'var(--secondary-color)', width: '100%', height: '100%', zIndex: 5000 }}>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <CircularProgress id='spinner-cargando' className="CircularProgress" style={{ position: position, top: top }}/>
            </div>
        </div>
    );
}
