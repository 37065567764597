function ObsoleteRequest(message = "") {
    this.name = "ObsoleteRequest";
    this.message = message;
}
ObsoleteRequest.prototype = Error.prototype;

/**
 * @param {string} message
 */
function CustomError(message) {
    this.name = "CustomError";
    this.userMessage = message;
}
ObsoleteRequest.prototype = Error.prototype;

export { ObsoleteRequest, CustomError };
