import React from "react";
import Alert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';

const fixEstilosImportantes = makeStyles({
    // fue necesario porque en App.css están todos los estilos sobre-escritos con !important usando las clases base de Material-UI. Lo que hace imposible usar estilos inline o cualquier técnica que no involucre también "!important".
    info: {
        backgroundColor: 'rgb(232, 244, 253) !important',
        border: '1px solid #349ff3bd',
        '& .MuiAlert-icon': {
            color: '#2196f3 !important'
        },
        '& .MuiAlert-message': {
            color: 'rgb(13, 60, 97) !important'
        },
        '& .MuiAlert-action': {
            color: 'rgb(13, 60, 97) !important'
        }
    },
    success: {
        backgroundColor: 'rgb(237, 247, 237) !important',
        border: '1px solid #5bb65f',
        '& .MuiAlert-icon': {
            color: '#5bb65f !important'
        },
        '& .MuiAlert-message': {
            color: 'rgb(30, 70, 32) !important'
        },
        '& .MuiAlert-action': {
            color: 'rgb(13, 60, 97) !important'
        }
    },
    warning: {
        backgroundColor: 'rgb(255, 244, 229) !important',
        border: '1px solid #d87218ad',
        '& .MuiAlert-icon': {
            color: '#ff9800 !important'
        },
        '& .MuiAlert-message': {
            color: 'rgb(102, 60, 0) !important'
        },
        '& .MuiAlert-action': {
            color: 'rgb(102, 60, 0) !important'
        }
    }
});

/**
 * @param {Object} params
 * @param {import("@material-ui/lab/Alert").Color} params.severity
 * @param {import("@material-ui/lab/Alert").Color} params.color
 * @param {JSX.Element} [params.children]
 * @param {import("react").CSSProperties | undefined} [params.style]
 * @param {Partial<import("@material-ui/lab").AlertProps>} [params.AlertProps]
 * @returns {JSX.Element}
 */
export default function StyledAlert({ severity, color, children, style = {}, AlertProps = {} }) {
    const clasesFixEstilos = fixEstilosImportantes();
    /** @type {string} */
    const fixedStyleClass = clasesFixEstilos[color] || clasesFixEstilos[AlertProps.color] || clasesFixEstilos[severity] || clasesFixEstilos[AlertProps.severity] || clasesFixEstilos.info;
    return <>
        <Alert severity={severity} color={color} className={fixedStyleClass} style={{ cursor: 'default', display: 'flex', alignItems: 'center', ...style }} {...AlertProps} >
            {children}
        </Alert>
    </>;
}
