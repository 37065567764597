import React, { useEffect } from "react";
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/styles';
import { CardActionArea } from "@material-ui/core";
import Typography from '@material-ui/core/Typography';
import { Hidden } from "@material-ui/core";
import Container from '@material-ui/core/Container';

import MainFeaturedPost2 from './mainfeaturedpost2';
import { theme as tema } from "../util/theme";

// IMÁGENES
import potenciamosHabilidades from '../ui/potenciamos-habilidades.png';
import capacitacionOnline from '../ui/capacitacion-online.png';
import certificacion from '../ui/certificacion.png';
import logoEnapsisColor from '../ui/logo-enapsis-color.png';
import bannerCapacitaDocente from '../ui/banner-capacita-docente.png';

const MAIN_POST_TITLE = 'Docentes formando docentes';
const MAIN_POST_TEXT = 'Nuestros cursos e-napsis han sido diseñados con el propósito de contribuir a la formación de los profesores de latinoamérica. Nuestra meta es  compatibilizar el crecimiento profesional con el desarrollo personal, brindando un ambiente digital intuitivo, simple y actualizado a las temáticas relevantes que exige la enseñanza-aprendizaje del siglo XXI.';
const INFO_CARD_1_TITLE = 'Potenciamos habilidades';
const INFO_CARD_1_TEXT = 'Impulsamos las habilidades que todo profesor del siglo XXI necesita. Te entregamos las herramientas requeridas para que avancemos e impulsemos el crecimiento de la educación latinoamericana.';
const INFO_CARD_2_TITLE = 'Cursos en línea';
const INFO_CARD_2_TEXT = 'Todos nuestros cursos son 100% online, asincrónicos y con evaluaciones de autocorrección. ¡Solo necesitas Internet y motivación para avanzar!';
const INFO_CARD_3_TITLE = 'Soluciones para ti';
const INFO_CARD_3_TEXT = 'Ponemos a tu disposición los mejores cursos, tanto para instituciones de educación, como para docentes. Puedes elegir uno a uno, plan de suscripción o programas de especialización. ¡Únete!';
const END_OF_PAGE_TEXT = '<b>e-napsis</b> es nuestro portal de formación de docentes para docentes, desarrollado por <b>Napsis</b>.\nSus principales características se encuentran en la <b>calidad de los cursos</b>, los que se ocupan del desarrollo de <b>habilidades para el siglo XXI</b>; la <b>facilidad en el uso</b> de la plataforma y el <b>respaldo de un equipo experto</b> que ha cuidado cada detalle para que tu experiencia sea la más satisfactoria.';

const useStyles = makeStyles({
    root: {
        maxWidth: 345
    },
    media: {
        height: 0
    },
    content: {
        position: 'relative',
        zIndex: 2,
        bottom: 0,
        width: '100%',
        justifyContent: 'start !important'
    },
    actionarea: {
        backgroundColor: 'none',
        position: 'unset'
    }
});

/**
 * Envuelve cada línea en un tag <p>.
 * @param {string} text
 * @param {boolean} [allowHtml] - default = false. Usar con cuidado. No usar para texto provisto por el usuario o de la BD.
 * @returns {JSX.Element[]}
 * @todo sanitize `text` al usar `allowHtml`.
 */
const wrapParagraphs = function (text, allowHtml) {
    const lines = text.split('\n');
    return lines.map((line, i) => {
        if (allowHtml) {
            return <p key={i} dangerouslySetInnerHTML={{ __html: line }}></p>;
        } else {
            return <p key={i}>{line}</p>;
        }
    });
};

export default function HomePage() {

    const classes = useStyles();

    useEffect(() => {
        document.title = "Nosotros";
    });

    /** @type {enapsis.Post} */
    const capacitaDocente = {
        title: MAIN_POST_TITLE,
        description: MAIN_POST_TEXT,
        image: bannerCapacitaDocente,
        imgText: 'Capacita a los docentes',
    };

    return (<>

        <div className="App rel" style={{ paddingBottom: 0 }}>
            <div className="tituloInicial rel" style={{ paddingBottom: 0 }}>
                <h1 className="title_section s24 fontb c333">Nosotros</h1>
            </div>
        </div>

        <Container maxWidth='xl' className='fix-position-of-first-page-item' style={{ marginTop: 0 }}>
            <MainFeaturedPost2 post={capacitaDocente} />
        </Container>

        <Container maxWidth='lg'>

            <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} alignItems="center" justifyContent="center" key="home2" style={{ marginTop: '3.5rem', marginBottom: '1rem', alignItems: 'start' }} >

                <div className={classes.root}>

                    <CardActionArea className={[classes.actionarea, 'fix-media-card-at-home-page'].join(' ')} > { /* no usar 'CardActionArea' y remover style hack (fix-media-card-at-home-page) */}
                        <CardMedia
                            image={potenciamosHabilidades}
                            title="Potenciamos habilidades"
                        />
                        <CardContent className={classes.content}>
                            <Typography gutterBottom variant="inherit" component="h1" align="center" style={{ color: tema.current === "light" ? '#d87218' : '#d87218' }}>
                                {INFO_CARD_1_TITLE}
                            </Typography>
                            <Typography variant="body2" color="textSecondary" component="p" align="center">
                                {INFO_CARD_1_TEXT}
                            </Typography>
                        </CardContent>
                    </CardActionArea>

                </div>

                <div className={classes.root} >
                    <CardActionArea className={[classes.actionarea, 'fix-media-card-at-home-page'].join(' ')} > { /* no usar 'CardActionArea' y remover style hack (fix-media-card-at-home-page) */}
                        <CardMedia
                            className={classes.media}
                            image={capacitacionOnline}
                            title="Capacitación online"
                        />
                        <CardContent className={classes.content}>
                            <Typography gutterBottom variant="inherit" component="h1" align="center" style={{ color: tema.current === "light" ? '#d87218' : '#d87218' }}>
                                {INFO_CARD_2_TITLE}
                            </Typography>
                            <Typography variant="body2" color="textSecondary" component="p" align="center">
                                {INFO_CARD_2_TEXT}
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                </div>

                <div className={classes.root} >
                    <CardActionArea className={[classes.actionarea, 'fix-media-card-at-home-page'].join(' ')} > { /* no usar 'CardActionArea' y remover style hack (fix-media-card-at-home-page) */}
                        <CardMedia
                            className={classes.media}
                            image={certificacion}
                            title="Certificación"
                        />
                        <CardContent className={classes.content}>
                            <Typography gutterBottom variant="inherit" component="h1" align="center" style={{ color: tema.current === "light" ? '#d87218' : '#d87218' }}>
                                {INFO_CARD_3_TITLE}
                            </Typography>
                            <Typography variant="body2" color="textSecondary" component="p" align="center">
                                {INFO_CARD_3_TEXT}
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                </div>

            </Grid>

            <div style={{ marginTop: '70px' }} >

                <div className="home-page rel">
                    <div className="extra-block rel" style={{ paddingBottom: '3rem' }}>


                        <Grid container spacing={1} item xs={12} direction="column" justifyContent="center" alignItems="center" >

                            <Grid container spacing={1} item xs={12} direction="row" justifyContent="center" alignItems="center">

                                <Grid item xs={12} sm={12} md={6} lg={4} xl={4} >
                                    {/*
                                        // @ts-ignore */}
                                    <Hidden xsDown smDown  >
                                        <div align='center' style={{ backgroundColor: 'white', borderRadius: '70px' }}>
                                            <a href="#" className="logo-pagina-principal bl" style={{ pointerEvents: 'none' }} >
                                                <img src={logoEnapsisColor} className="logo-pagina-principal bl" />
                                            </a>
                                        </div>
                                    </Hidden>
                                    {/*
                                        // @ts-ignore */}
                                    <Hidden mdUp>
                                        <div align='center' className="jtx">
                                            <div style={{ whiteSpace: 'pre-line' }}>{wrapParagraphs(END_OF_PAGE_TEXT, true)}</div>
                                        </div>
                                    </Hidden>

                                </Grid>

                                <Grid item xs={12} sm={12} md={6} lg={8} xl={8} >
                                    {/*
                                        // @ts-ignore */}
                                    <Hidden xsDown smDown  >
                                        <div align='center' className="jtx" style={{ justifyContent: 'center', padding: '30px' }} >
                                            <div style={{ whiteSpace: 'pre-line' }}>{wrapParagraphs(END_OF_PAGE_TEXT, true)}</div>
                                        </div>
                                    </Hidden>
                                    {/*
                                        // @ts-ignore */}
                                    <Hidden mdUp>
                                        <div align='center' >
                                            <a href="#" className="logocel-pagina-principal bl" style={{ backgroundColor: 'white', borderRadius: '70px', pointerEvents: 'none' }}>
                                                <img src={logoEnapsisColor} className="logocel-pagina-principal bl" />
                                            </a>
                                        </div>
                                    </Hidden>

                                </Grid>

                            </Grid>

                        </Grid>

                    </div>

                </div>

            </div>

        </Container>
    </>);
}
