import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from "react-router";
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';

import { BotonContext } from "../BotonContext";
import api from '../lib/api';

function SuccessShop() {

  useEffect(() => {
    document.title = "Detalle de compra";
  });

  const history = useHistory();
  const { cart, setCart, token, data_user } = useContext(BotonContext);

  useEffect(() => {
    if (!token || !data_user) return;
    api.pagos.validarPagos({
      token: token,
      userId: data_user.id_usuario
    })
      .catch(() => {
        // fallar en silencio
      });
  }, []);

  const [subtotal, setSubtotal] = useState();
  const [discount, setDiscount] = useState();
  const [total, setTotal] = useState();

  useEffect(() => {
    let subtotalVar = 0;
    let discountVar = 0;
    carting != null && carting.map(course => {
      subtotalVar += course.price * course.quantity;
      if (course.discount) {
        //discountVar += course.price * .2 * course.quantity
        discountVar += 2;
      }
    });
    setSubtotal(subtotalVar);
    setDiscount(discountVar);
    setTotal(subtotalVar - discountVar);
  }, [cart]);

  const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      '& > *': {
        marginBottom: theme.spacing(2)
      },
      '& .MuiBadge-root': {
        marginRight: theme.spacing(4)
      }
    }
  }));

  const classes = useStyles();

  //Boton de carrito que suma cantidad total - Form abajo
  const [pathname, setPathname] = useState(window.location.pathname.slice(18,));

  const [cartQuantity, setCartQuantity] = useState();

  useEffect(() => {
    const inputVal = pathname.split('-').join(' ');
    let quantity = 0;
    if (carting !== null && carting.length > 0) {
      carting.map(course => quantity += course.quantity);
    }
    setCartQuantity(quantity);
    if (window.location.pathname.includes('search')) {
      document.getElementsByTagName('input').item(0).setAttribute('value', inputVal);
      document.getElementsByTagName('input').item(1).setAttribute('value', inputVal);
    }
  });

  const [courserepeat, setOpenCourseRepeat] = useState([]);

  const cache_storage = localStorage.getItem('cart');
  const carting = JSON.parse(cache_storage);

  const misCursosComprados = [];
  for (let i = 0; i < courserepeat.length; i++) {
    const curso_comprado = courserepeat[i];
    misCursosComprados.push(
      <div className={classes.root}>
        {<li>{curso_comprado}</li>}
      </div>
    );
  }

  function handleClickToCar(event) {
    event.preventDefault();
    history.push("/add-cart");
  }

  useEffect(() => {
      localStorage.removeItem('cart');
      setCart(JSON.parse(localStorage.getItem('cart')));
  });

  return (
    <div className="App rel">
      <Breadcrumbs aria-label="breadcrumb" className="breadcrumbs_link_inneret">
        <Link color="inherit" onClick={handleClickToCar} className="breadcrumbs_link_inneret">
          Detalle de compra
        </Link>
        <Typography color="textPrimary" className="breadcrumbs_link">
          Compra exitosa
        </Typography>
      </Breadcrumbs>
      <br></br>
      <h1 className="title_section s24 fontb c333">Compra exitosa</h1>

      <div style={{ display: 'flex', justifyContent: 'center' }}>

        <Grid item xs={12} sm={10} md={6} lg={5} xl={5}>

          <div style={{ paddingTop: '5rem' }}>
          {/*
            // comentado temporalmente porque las compras no son "exitosas". Hay problemas al procesar pagos.
            <div style={{display: 'flex', justifyContent: 'center'}} className="title_section s30 fontb c333">¡ Su compra ha sido exitosa !</div>
            <div style={{display: 'flex', justifyContent: 'center'}} className="title_section s15 fontb c333">Recibirá los detalles de la misma en su correo.</div>
          */}
            <div style={{display: 'flex', justifyContent: 'center'}} className="title_section s30 fontb c333">Su compra está siendo procesada.</div>
            <div style={{display: 'flex', justifyContent: 'center'}} className="title_section s15 fontb c333">Revise en unos momentos la sección &quot;Mis cursos&quot; para ver su curso asignado en esta vista.</div>
          </div>

        </Grid>

      </div>
    </div>
  );
}

export default SuccessShop;
