import React, { useState } from 'react';
import { SnackbarProvider, useSnackbar } from 'notistack';
import CacheBuster from 'react-cache-buster';
import Fade from '@material-ui/core/Fade';
import Button from '@material-ui/core/Button';
import './css/scoped.bootstrap.min.css';
import './css/scoped.bootstrap.fixes.css';

import AppRoot from "./AppRoot";
import AppLoader from "./AppLoader";
import { BotonProvider } from "./BotonContext";
//import Loading from './loading';
import { version } from '../package.json';

import env from './util/env';

function TransitionFade(props) {
  return <Fade {...props} />;
}

function SnackbarCloseButton({ snackbarKey }) {
  const { closeSnackbar } = useSnackbar();
  const [closing, setClosing] = useState(false);
  return (
    <Button
      style={{ cursor: 'pointer', opacity: 0, padding: 0, position: 'absolute', left: 0, top: 0, height: '100%', width: '100%', pointerEvents: closing ? 'none' : null }}
      onClick={() => {
        closeSnackbar(snackbarKey);
        setClosing(true);
      }}
    />
  );
}

function App() {
  const isProduction = env.isProduction;
  return (
    <CacheBuster
      currentVersion={version}
      isEnabled={isProduction} //If false, the library is disabled.
      isVerboseMode={false} //If true, the library writes verbose logs to console.
      //loadingComponent={<Loading />} //If not pass, nothing appears at the time of new version check.
    >
      <SnackbarProvider
        maxSnack={3}
        TransitionComponent={TransitionFade}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        disableWindowBlurListener={true}
        className='notistack-customized'
        autoHideDuration={3000}
        action={snackbarKey => <SnackbarCloseButton snackbarKey={snackbarKey} />}
      >

        <BotonProvider>
          <AppRoot>

            <AppLoader />

          </AppRoot>
        </BotonProvider>

      </SnackbarProvider>

    </CacheBuster>

  );
}

export default App;
