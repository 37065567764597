import React, { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useSnackbar } from 'notistack';

import AuthContext from "../AuthContext";
import { BotonContext } from "../BotonContext";
import TarjetaEcampus from "../componentes/TarjetaEcampus";
import LoadingPanel from "../componentes/LoadingPanel";
import utils from "../util/utils";
import { strings } from "../lib/i18n";
import * as constants from "../lib/constants";
import api, { isEdited, isPublished, parseApiDate } from "../lib/api";
import { clearSession } from "../util/session";
import routeHelper from "../util/routeHelper";

const SHOW_BUTTON_DELETE_COURSE = false;
const SHOW_BUTTON_AGREGAR_CLASE = true;
const NON_PUBLISHED_AS_EDITED = true;

function limitarCaracteres(value, count) {
    if (value.length > count) {
        value = value.slice(0, count) + '...';
        return value;
    } else {
        return value;
    }
}

/**
 * @param {api.CursoInstructor[]} cursos
 * @returns {api.CursoInstructor[]}
 */
const sortCursos = function (cursos) {
    return cursos.sort((a, b) => {
        return (b.estado - a.estado) /* primero cursos publicados */ || (parseApiDate(b.fecha_creacion).valueOf() - parseApiDate(a.fecha_creacion).valueOf()); /* y luego ordenar por fecha de creación */
    });
};

/**
 * @param {Object} params
 * @param {api.CursoInstructor} [params.curso]
 * @param {boolean} [params.small] - default = false
 * @returns {JSX.Element}
 */
const ActionAreaTarjeta = function ({ curso, small }) {
    const hrefAgregarClase = "#" + routeHelper.rutaAgregarClase(curso.id);
    const history = useHistory();
    const handleEditClick = function () {
        history.push({
            pathname: '/edit-course/' + curso.id,
            state: curso
        });
    };
    const handleDeleteClick = function () {
        // TODO: implementar
    };
    return (
        <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%', marginBottom: '5px', gap: '4px' }}>
            {SHOW_BUTTON_AGREGAR_CLASE &&
                <Button fullWidth href={hrefAgregarClase} variant="contained" className="button_accept">
                    <AddCircleOutlineOutlinedIcon style={{ marginRight: '7px' }} /> {small ? 'Clase' : 'Añadir clase'}
                </Button>
            }
            <Button variant="contained" onClick={handleEditClick} className="button_cancel">
                <EditOutlinedIcon />
            </Button>
            {SHOW_BUTTON_DELETE_COURSE &&
                <Button variant="contained" onClick={handleDeleteClick} className="button_cancel">
                    <DeleteForeverOutlinedIcon />
                </Button>
            }
        </div>
    );
};

export default function MyCoursesTutorPage () {

    const theme = useTheme();
    const smallScreen = useMediaQuery(theme.breakpoints.down('xs'));

    const { enqueueSnackbar } = useSnackbar();

    const { isAuth } = useContext(AuthContext);
    const { data_user, token } = useContext(BotonContext);

    useEffect(() => {
        document.title = strings.MIS_TUTORIAS;
    });

    const [instructorCourses, setInstructorCourses] = useState(/** @type {api.CursoInstructor[]} */([]));
    const [disableLoading, setDisableLoading] = useState(false);

    const ValidarAuth = function () {
        if (!isAuth) {
            clearSession();
            window.location.href = "#/login";
            window.location.reload();
        }
    };

    ValidarAuth();

    const userID = data_user.id_usuario;

    useEffect(() => {
        api.instructor.getCursos({ userId: userID, token })
            .then(data => {
                if (data.success) {
                    const cursos = sortCursos(data.data);
                    setInstructorCourses(cursos);
                    if (cursos.length === 0) {
                        // TODO: mostrar mensaje en línea con la interfaz (en lugar de usar una notificación).
                        enqueueSnackbar('Actualmente no posee cursos creados en el sistema', {
                            variant: 'info'
                        });
                    }
                } else {
                    if (data.data === null) {
                        // FIX: ¿que pasa si success es false pero data.data != null?, ¿por qué no se maneja el caso?.
                        sessionStorage.setItem("token", null);
                        sessionStorage.setItem("user_data", null);
                        ValidarAuth();
                    }
                }
                setDisableLoading(true);
            })
            .catch(() => {
                enqueueSnackbar('No se encontraron datos', {
                    variant: 'error'
                });
            });
    }, []);

    const myCourseList = [];
    for (let i = 0; i < instructorCourses.length; i++) {
        const course = instructorCourses[i];
        const _isPublised = isPublished(course);
        const coursePublished = _isPublised;
        const courseEdited = NON_PUBLISHED_AS_EDITED ? !_isPublised : isEdited(course);
        const showTag = coursePublished || courseEdited;
        myCourseList.push(
            <Grid container item xs={12} sm={12} md={6} lg={4} xl={3} spacing={0} direction="column" alignItems="center" justifyContent="center" key={instructorCourses[i].id}>

                <div className="rel" style={{ height: '415px', padding: '10px' }}>

                    <Grid container spacing={3} alignContent="center" alignItems="center">

                        <TarjetaEcampus
                            image={utils.getImagenCurso(course)}
                            text1={limitarCaracteres(course.titulo, smallScreen ? 28 : 50)}
                            text2={false}
                            small={smallScreen}
                            className='tarjeta-ecampus-tutor'
                            customActionArea={<ActionAreaTarjeta curso={course} small={smallScreen} />}
                            showTag={showTag}
                            tagOptions={coursePublished ? constants.RibbonStyles.RIBBON_PUBLISHED : courseEdited ? constants.RibbonStyles.RIBBON_EDITED : undefined}
                        />

                    </Grid>

                </div>

            </Grid>
        );
    }

    return (
        <div className="App rel">

            <Breadcrumbs aria-label="breadcrumb" className="breadcrumbs_link_inneret">
                <Typography color="textPrimary" className="breadcrumbs_link">
                    {strings.MIS_TUTORIAS}
                </Typography>
            </Breadcrumbs>

            <div className="tituloInicial rel">
                <h1 className="title_section s24 fontb c333">{strings.MIS_TUTORIAS}</h1>
            </div>

            <div className="section section-b rel" style={{ marginTop: '20px' }}>

                <Button variant="contained" style={{ float: 'left', marginLeft: '15px', marginTop: '5px' }} className="button_accept" href={"#/course-upload"} >
                    Crear curso
                </Button>
                <div className="section rel"></div>

                <div style={{ height: '110px' }}></div>

                <Grid container spacing={1} item xs={12} direction="row" >
                    {myCourseList}
                </Grid>

                {!disableLoading &&
                    <LoadingPanel/>
                }

            </div>

        </div>

    );
}
