import React, { useContext, useRef, useState } from "react";
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import PublishIcon from '@material-ui/icons/Publish';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import VisibilityIcon from '@material-ui/icons/Visibility';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress, LinearProgress } from "@material-ui/core";
import { useSnackbar } from "notistack";
import ReactPlayer from "react-player";

import { BotonContext } from "../BotonContext";
import ModalVideoPlayer from "./ModalVideoPlayer";
import DialogTitle from './DialogTitle';
import ButtonCircularProgress from "./ButtonCircularProgress";
import api from "../lib/api";
import StyledAlert from "./StyledAlert";

const SHOW_VIEW = true;
const SHOW_VIEW_NEW_TAB = true;
const MAX_VIDEO_LENGTH = 120; // 2 minutos
const MAX_VIDEO_LENGTH_STRING = (() => {
    /** @type {string[]} */
    const tokens = [];
    let minutos = 0;
    let segundos = 0;
    if (MAX_VIDEO_LENGTH > 60) {
        minutos = Math.floor(MAX_VIDEO_LENGTH / 60);
        tokens.push(minutos + (minutos > 1 ? ' minutos' : ' minuto'));
    }
    segundos = Math.floor(MAX_VIDEO_LENGTH - (minutos * 60));
    if (segundos >= 2) {
        tokens.push(segundos + ' segundos');
    }
    return tokens.join(', ');
})();
const ERROR_DURATION = "La duración del video no debe ser mayor de " + MAX_VIDEO_LENGTH_STRING;
const MAX_VIDEO_SIZE = 200; // en MB

const estilos = makeStyles({
    videoPreview: {
        '& video': {
            maxHeight: '640px'
        }
    }
});

/**
 * @typedef TVideo
 * @property {File} file
 * @property {string} url
 * @property {number} duration
 */

/**
 * @typedef State
 * @property {'loading' | 'uploading' | 'idle'} status
 * @property {TVideo} video
 * @property {(courseIdOverride?: number) => Promise<string>} uploadTrigger - `courseIdOverride` para ser usado cuando el course_id aún no está en el estado del componente (pasa durante la creación de cursos).
 */

/**
 * @param {number} duration
 * @returns {boolean}
 */
const isValidDuration = function (duration) {
    return duration <= MAX_VIDEO_LENGTH;
};

/**
 * @param {TVideo} video
 * @returns {string} - null si no hay error de validación
 */
export function validateVideo(video) {
    if (!isValidDuration(video.duration)) {
        return ERROR_DURATION;
    }
    if (!/\.mp4$/i.test(video.file.name)) {
        return "El video debe tener formato .MP4";
    }
    if ((video.file.size / 1024 / 1024) > MAX_VIDEO_SIZE) {
        return `El tamaño del video no debe superar los ${MAX_VIDEO_SIZE}MB`;
    }
    return null;
}

const AlertaInfoVideo = <>
    <StyledAlert severity="info" color="info">
        <span>NOTA: una vez finalizada la subida, el video tomará un tiempo en ser procesado y estar disponible para su visualización.</span>
    </StyledAlert>
</>;

/**
 * @param {Object} params
 * @param {number} params.courseId
 * @param {boolean} params.showPreview
 * @param {string} params.currentUploadedVideoURL
 * @param {Function} params.onVideoDeleted
 * @param {(state: State) => void} params.onStateChange
 * @returns {JSX.Element}
 */
export default function FormVideoUpload({ courseId, showPreview, currentUploadedVideoURL, onVideoDeleted, onStateChange }) {

    const { enqueueSnackbar } = useSnackbar();
    const clasesEstilos = estilos();
    const { data_user, token } = useContext(BotonContext);
    const [openVideoModal, setOpenVideoModal] = useState(false);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [showButtonSpinnerModal, setShowButtonSpinnerModal] = useState(false);
    const [waitingForDeleteResponse, setWaitingForDeleteResponse] = useState(false);
    const [newVideoWrapper, setNewVideoWrapper] = useState({
        /** @type {TVideo} */
        newVideo: {
            file: null,
            url: '',
            duration: null
        }
    });
    const [percentLoad, setPercentLoad] = useState(0);
    const [percentUpload, setPercentUpload] = useState(/** @type {number}*/(null));
    const [errorVideo, setErrorVideo] = useState('');
    const [showUploadingModal, setShowUploadingModal] = useState(false);
    const [showUploadSuccessAlert, setShowUploadSuccessAlert] = useState(false);
    const newVideo = newVideoWrapper.newVideo;
    const hasUploadedVideo = !!currentUploadedVideoURL;
    const hasNewVideo = !!newVideo?.file;
    const isLoading = hasNewVideo && (percentLoad < 100);
    const refs = useRef({ percentUpload, showUploadingModal });
    refs.current = { percentUpload, showUploadingModal };

    const incrementPercentUpload = function () {
        const { percentUpload: _percentUpload, showUploadingModal: _showUploadingModal } = refs.current;
        if (!_showUploadingModal) return;
        window.setTimeout(() => {
            if (!_showUploadingModal) return;
            if (_percentUpload < 97) {
                setPercentUpload(_percentUpload + 2);
                incrementPercentUpload();
            }
        }, 1500);
    };

    /**
     * @param {number} [courseIdFallback]
     * @returns {Promise<string>}
     */
    const uploadVideo = function (courseIdFallback) {
        return new Promise((resolve, reject) => {
            if (!newVideo.file) reject();
            setPercentUpload(0);
            setShowUploadingModal(true);
            onStateChange({
                status: 'uploading',
                video: newVideo,
                uploadTrigger: uploadVideo
            });
            api.instructor.subirVideoPromocional({
                token,
                id_usuario: data_user.id_usuario,
                id_curso: typeof courseId === 'number' ? courseId : courseIdFallback,
                video: newVideo.file,
                onProgress(progress) {
                    setPercentUpload(progress * 70); // no llegar hasta 100. El resto incrementarlo con timer una vez completada la subida; mientras se espera respuesta.
                    if (progress === 1) {
                        // se culminó la subida al back, pero ahora hay que esperar que el back suba a YouTube y responda con el URL.
                        incrementPercentUpload();
                    }
                }
            })
                .then((response) => {
                    if (response.success) {
                        setPercentUpload(100);
                        clearVideoSelection();
                        resolve(response.url_youtube);
                    } else {
                        reject();
                    }
                })
                .catch(() => {
                    reject();
                })
                .finally(() => {
                    setShowUploadingModal(false);
                    onStateChange({
                        status: 'idle',
                        video: newVideo,
                        uploadTrigger: uploadVideo
                    });
                });
        });
    };

    const eliminarVideo = function () {
        setShowButtonSpinnerModal(true);
        setWaitingForDeleteResponse(true);
        api.instructor.eliminarVideoPromocional({ token, id_usuario: data_user.id_usuario, id_curso: courseId })
            .then(({ data, status }) => {
                if (data.success) {
                    enqueueSnackbar('Acción ejecutada con éxito', {
                        variant: 'success'
                    });
                    (typeof onVideoDeleted === 'function') && onVideoDeleted();
                    closeModalEliminar();
                } else {
                    if (status === 200) {
                        enqueueSnackbar(data.mensaje || data.message, {
                            variant: 'warning'
                        });
                    } else {
                        throw new Error(data.mensaje);
                    }
                }
            })
            .catch(() => {
                setShowButtonSpinnerModal(false);
                enqueueSnackbar('Error al eliminar video', {
                    variant: 'error'
                });
            })
            .finally(() => {
                setWaitingForDeleteResponse(false);
            });
    };

    const handleEliminarClick = function () {
        !waitingForDeleteResponse && setShowButtonSpinnerModal(false);
        setOpenDeleteModal(true);
    };

    const closeModalEliminar = function () {
        setOpenDeleteModal(false);
    };

    const viewVideo = function () {
        if (!hasUploadedVideo) return;
        setOpenVideoModal(true);
    };

    const viewVideoNewTab = function () {
        if (!hasUploadedVideo) return;
        window.open(currentUploadedVideoURL, '_blank').focus();
    };

    const clearVideoSelection = function () {
        newVideo.file = null;
        newVideo.url = '';
        newVideo.duration = null;
        setNewVideoWrapper({ ...newVideoWrapper });
        setPercentLoad(0);
        setErrorVideo('');
        onStateChange({
            status: 'idle',
            video: newVideo,
            uploadTrigger: uploadVideo
        });
    };

    return (<>
        <div style={{ display: 'flex', gap: '0.8rem' }}>
            <div style={{ position: 'relative', width: '100%' }} >
                <TextField className="InputText" id="video-motivacional" label="Video motivacional" variant="outlined" fullWidth
                    InputProps={{
                        className: "InputText"
                    }}
                    InputLabelProps={{
                        className: "InputText"
                    }}
                    type="text"
                    contentEditable={false}
                    disabled={true}
                    value={currentUploadedVideoURL || newVideo.file?.name || ''}
                />
                {isLoading &&
                    <LinearProgress style={{ position: 'absolute', bottom: '2px', width: 'calc(100% - 6px)', borderRadius: '3px', marginLeft: '3px' }} />
                }
            </div>
            {!hasUploadedVideo &&
                <Button variant="contained" component="label" className="button_accept" title="Cargar video" >
                    <PublishIcon />
                    <input hidden type="file" id="course-image" accept="video/mp4"
                        onChange={(e) => {
                            const files = e.target.files;
                            clearVideoSelection();
                            if (!files.length) {
                                return;
                            }
                            onStateChange({
                                status: 'loading',
                                video: newVideo,
                                uploadTrigger: uploadVideo
                            });
                            if (files.length > 1) {
                                enqueueSnackbar('Debe seleccionar solo un archivo', {
                                    variant: 'warning'
                                });
                                return;
                            }
                            const file = files[0];
                            if (file.type !== 'video/mp4') {
                                enqueueSnackbar('El video debe tener formato .mp4', {
                                    variant: 'warning'
                                });
                                return;
                            }
                            const reader = new FileReader();
                            reader.onloadend = () => {
                                newVideo.url = reader.result.toString();
                                setNewVideoWrapper({ ...newVideoWrapper });
                                setPercentLoad(50);
                            };
                            reader.onprogress = (evt) => {
                                const percent = evt.loaded / evt.total * 50; // NOTA: no llega a 100. Asumir 100 en el evento onloadend.
                                setPercentLoad(percent);
                            };
                            reader.readAsDataURL(file);
                            newVideo.file = file;
                            setNewVideoWrapper({ ...newVideoWrapper });
                        }}
                        key={newVideo?.file?.name + newVideo?.file?.size || ''} // permite que el input se limpie automáticamente al limpiar `newVideo` (luego de guardar exitosamente)
                    />
                </Button>
            }
            {!hasUploadedVideo && newVideo.url &&
                <Button variant="contained" component="label" className="button_cancel" title="Remover" onClick={clearVideoSelection} >
                    <CloseIcon />
                </Button>
            }
            {hasUploadedVideo &&
                <Button variant="contained" component="label" className="button_delete" title="Eliminar video" onClick={handleEliminarClick} >
                    <DeleteForeverIcon />
                </Button>
            }
            {hasUploadedVideo && SHOW_VIEW &&
                <Button variant="contained" component="label" className="button_cancel" title="Ver video" onClick={viewVideo} >
                    <VisibilityIcon />
                </Button>
            }
            {hasUploadedVideo && SHOW_VIEW_NEW_TAB &&
                <Button variant="contained" component="label" className="button_cancel" title="Ver video (nueva pestaña)" onClick={viewVideoNewTab} >
                    <OpenInNewIcon />
                </Button>
            }
        </div>
        {!!errorVideo &&
            <p className="fieldInfoLabel" style={{ fontSize: 12, color: "red" }}>
                {errorVideo}
            </p>
        }
        {showUploadSuccessAlert &&
            <StyledAlert severity="success" color="success" style={{ marginTop: '0.8rem' }} AlertProps={{ onClose() { setShowUploadSuccessAlert(false); } }}>
                <span>Su video ha sido subido exitosamente!<br />NOTA: el video puede demorar un tiempo en ser procesado y estar disponible para su visualización. Sin embargo, ya ha sido subido y continuará siendo procesado aunque salga de esta página.</span>
            </StyledAlert>
        }
        {showPreview && hasNewVideo && newVideo.url &&
            <div style={{ marginTop: '1rem', cursor: 'default' }} className={clasesEstilos.videoPreview}>
                <div style={{ marginBottom: '0.3rem' }}>Vista previa:</div>
                <ReactPlayer
                    url={newVideo.url}
                    width='100%'
                    height='' // si se especifica 100% el alto no se ajusta correctamente al video
                    controls
                    playing={false}
                    volume={0.2}
                    style={{ maxHeight: '640px', backgroundColor: 'black' }}
                    onProgress={(state) => {
                        if (percentLoad === 100) return; // sí ya "onReady" disparó
                        const percent = 50 + (state.loaded * 50);
                        setPercentLoad(percent);
                    }}
                    onReady={() => {
                        setPercentLoad(100);
                        onStateChange({
                            status: 'idle',
                            video: newVideo,
                            uploadTrigger: uploadVideo
                        });
                    }}
                    onError={() => {
                        setPercentLoad(100);
                        onStateChange({
                            status: 'idle',
                            video: newVideo,
                            uploadTrigger: uploadVideo
                        });
                    }}
                    onDuration={(duration) => {
                        newVideo.duration = duration; // NOTA: no se actualiza newVideoWrapper intencionalmente (actualmente no es necesario renderizar con cambios de `duration`)
                        if (!isValidDuration(duration)) {
                            setErrorVideo(ERROR_DURATION);
                        }
                        onStateChange({
                            status: 'idle',
                            video: newVideo,
                            uploadTrigger: uploadVideo
                        });
                    }}
                />
            </div>
        }
        {hasUploadedVideo && <>
            {/* Modal Eliminar Video */}
            <Dialog open={openDeleteModal} onClose={closeModalEliminar} aria-labelledby="customized-dialog-title" disableScrollLock={true} style={{ cursor: 'default' }}>

                <DialogTitle id="form-dialog-title" onClose={closeModalEliminar} className="modal_title" >Eliminar Video</DialogTitle>

                <DialogContent className="modal_content">
                    <p>¿Está seguro que desea eliminar el video? Esta acción es definitiva e inmediata.</p>
                    <StyledAlert severity="warning" color="warning" style={{ marginTop: '-30px', margin: '0.8rem 0' }}>
                        <span>El video será eliminado al confirmar este mensaje. Sin requerir guardar el curso.</span>
                    </StyledAlert>
                </DialogContent>

                <DialogActions className="modal_footer">
                    <Button onClick={closeModalEliminar} variant="contained" color="primary" className="button_cancel" disabled={showButtonSpinnerModal} >
                        Cancelar
                    </Button>
                    <Button onClick={eliminarVideo} variant="contained" color="primary" className="button_delete" disabled={showButtonSpinnerModal} >
                        {showButtonSpinnerModal && <ButtonCircularProgress />}
                        <DeleteOutlineOutlinedIcon style={{ marginRight: '4px', marginLeft: '-6px' }} />
                        Eliminar Video
                    </Button>
                </DialogActions>

            </Dialog>
            {/* Modal Video Player */}
            <ModalVideoPlayer video={currentUploadedVideoURL} open={openVideoModal} handleClose={() => { setOpenVideoModal(false); }} />
        </>}
        {/* Modal Uploading Progress */}
        {!hasUploadedVideo &&
            <Dialog open={showUploadingModal} onClose={() => { }} disableScrollLock={true} style={{ cursor: 'default' }}>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '1.5rem', paddingBottom: '1rem', gap: '1rem' }}>
                    <div>Subiendo video promocional</div>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <CircularProgress variant="determinate" value={percentUpload} style={{ color: 'var(--color-colored-only-on-light)' }} />
                        <div style={{ position: 'absolute', opacity: 0.6 }}>{Math.round(percentUpload)}%</div>
                    </div>
                </div>
                <div style={{ margin: '0px 1.2rem 1rem', marginTop: 0 }}>
                    {AlertaInfoVideo}
                </div>
            </Dialog>
        }
    </>);

}
