// NOTA: se definen valores como propiedades de una función para asistir con la detección de errores por parte de TypeScript.
// Si se se definen como un objeto (así se congele mediante Object.freeze()), accesos a propiedades no existentes no serán detectadas como error, sino como acceso a una propiedad desconocida con valor "any".

const env = new (function Env() {

    //=========================================================================================== isProduction
    /** @type {boolean} */
    this.isProduction = undefined;
    Object.defineProperty(this, 'isProduction', {
        /** @returns {boolean} */
        get() {
            return process.env.NODE_ENV === 'production';
        }
    });

    this.flags = new (function Flags() {

        const _HIDE_DIPLOMADOS = /true/i.test(process.env.REACT_APP_HIDE_DIPLOMADOS);

        //======================================================================================= HIDE_DIPLOMADOS
        /** @type {boolean} */
        this.HIDE_DIPLOMADOS = undefined;
        Object.defineProperty(this, 'HIDE_DIPLOMADOS', {
            /** @returns {boolean} */
            get() {
                return _HIDE_DIPLOMADOS;
            }
        });

    })();

})();

export default env;
