import noImage from '../ui/no_disponible_1.png';
import env from "./env";

const PREFER_BINARY_IMAGE = true;

/**
 * @param {string} url
 * @param {boolean} [onlyInProduction] - default = true
 */
export const silentFetch = function (url, onlyInProduction = true) {
    if (onlyInProduction && !env.isProduction) return;
    let timeout;
    let iframe = document.createElement('iframe');
    iframe.setAttribute('sandbox', ''); // deshabilitar javascript en el iframe para optimizar y minimizar peticiones adicionales innecesarias
    iframe.setAttribute("src", url);
    iframe.style.display = "none";
    document.body.appendChild(iframe);
    const destroyIframe = function () {
        iframe && iframe.remove();
        iframe = undefined;
        clearTimeout(timeout);
    };
    iframe.onabort = destroyIframe;
    iframe.onload = destroyIframe;
    timeout = setTimeout(destroyIframe, 3000);
};

/**
 * @param {string | api.HasImage} param
 * @returns {string}
 */
export const getImagenCurso = function (param) {
    if (typeof param === 'undefined') return noImage;
    if (typeof param === 'string') {
        return param ? 'data:image/png;base64,' + param : noImage;
    } else {
        if (PREFER_BINARY_IMAGE && param?.imagen) return 'data:image/png;base64,' + param.imagen;
        if (param?.ruta_imagen) return param.ruta_imagen;
        return param?.imagen ? 'data:image/png;base64,' + param.imagen : noImage;
    }
};

export const rutHelper = {
    /**
     * Valida el rut con su cadena completa "XXXXXXXX-X"
     * @param {string} rutCompleto
     * @returns {boolean}
     */
    validar: function (rutCompleto) {
        if (!/^[0-9]+[-|‐]{1}[0-9kK]{1}$/.test(rutCompleto))
            return false;
        const tmp = rutCompleto.split('-');
        const rut = tmp[0];
        let digv = tmp[1];
        if (digv == 'K') digv = 'k';
        return (rutHelper.dv(rut) == digv);
    },
    dv: function (T) {
        let M = 0, S = 1;
        for (; T; T = Math.floor(T / 10))
            S = (S + T % 10 * (9 - M++ % 6)) % 11;
        return S ? S - 1 : 'k';
    }
};
Object.freeze(rutHelper);

/**
 * Helper para asegurar que transcurra determinado tiempo desde que se crea este objeto hasta que se dispare su trigger.
 * Útil por ejemplo para evitar ocultar un elemento muy rápido luego de mostrarlo.
 * @param {Object} obj
 * @param {{(data?: any, param?: any): void}} obj.onTrigger - callback
 * @param {any} [obj.data] - data (opcional) a pasar como parámetro al callback `onTrigger`
 * @param {number} [obj.minVisibleTime=2000] - cantidad mínima de milisegundos que debe transcurrir desde la creación de la clase hasta el disparo de trigger [default = 2000]
 * @todo agregar métodos 'cancel' y 'flush'.
 * @todo considerar remplazar por una función 'throttle'. Ej: implementación de lodash: _.throttle(). O hacerla más similar a una función 'throttle' estándar. La diferencia principal es que esta soportaría tipos (generic types vía TypeScript y JSDoc) y llamada asincrónica (vía promesa). NOTA: en el caso de _.debounce, la librería complementaria 'futil-js' implementa una versión asincrónica (debounceAsync), pero no implementa una para throttle.
 */
export function assureDelayBeforeTrigger({ onTrigger, data, minVisibleTime = 2000 }) {

    let shownTimestamp = new Date();
    /** @type {number} */
    let timer = undefined;

    const doTrigger = function (resolve, reject, param) {
        try {
            onTrigger(data, param);
            resolve();
        } catch (e) {
            reject();
        } finally {
            timer = undefined;
        }
    };

    /**
     * @param {*} [param] - parámetro adicional a pasar (luego de "data") al callback onTrigger
     * @param {Object} [options]
     * @param {boolean} [options.force] - permite ejecutar el trigger sin esperar el tiempo definido en `minVisibleTime`.
     * @param {number} [options.timeOverride]
     * @param {boolean} [options.deDuplicateTrigger=true] - default = false / WARNING: al de-duplicar, el valor de `param` usado al ejecutar `onTrigger` será el especificado en la primera ejecución.
     */
    this.trigger = async function (param, { force, timeOverride, deDuplicateTrigger } = {}) {
        if (deDuplicateTrigger && timer) return;
        const newTimestamp = new Date();
        const shownTime = newTimestamp.valueOf() - shownTimestamp.valueOf();
        const time = typeof timeOverride === 'number' ? timeOverride : minVisibleTime;
        const promise = new Promise((resolve, reject) => {
            if (shownTime >= time || force) {
                doTrigger(resolve, reject, param);
            } else {
                const delta = time - shownTime;
                timer = window.setTimeout(() => {
                    doTrigger(resolve, reject, param);
                }, delta);
            }
        });
        return /** @type {Promise<void>} */(promise);
    };

    /**
     * reinicia el timestamp inicial
     */
    this.resetTime = function () {
        shownTimestamp = new Date();
    };

    /** @type {number} */
    this.minVisibleTime = undefined;
    Object.defineProperty(this, 'minVisibleTime', {
        get() {
            return minVisibleTime;
        },
        set(value) {
            minVisibleTime = value;
        }
    });

}

const utils = { silentFetch, getImagenCurso, rutHelper, assureDelayBeforeTrigger };

export default utils;
