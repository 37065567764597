import React, { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
//MATERIAL UI
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/styles';
import { Hidden } from "@material-ui/core";
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import AttachFile from '@material-ui/icons/AttachFile';
import Card from '@material-ui/core/Card';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import CancelIcon from '@material-ui/icons/Cancel';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import Alert from '@material-ui/lab/Alert';
import { useSnackbar } from "notistack";
import { useIsFirstRender } from "usehooks-ts";

//EDITOR DE TEXTO
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import DialogTitle from '../componentes/DialogTitle';
import { BotonContext } from "../BotonContext";
import EditorToolbar, { modules, formats } from "../componentes/EditorToolbar";
import TarjetaEcampus from "../componentes/TarjetaEcampus";
import imageSubir from '../ui/subir-img.png';
import commonIO from "../util/common.io";
import api from "../lib/api";
import { strings } from "../lib/i18n";
import LoadingPanel from "../componentes/LoadingPanel";
import ListadoUnidades from "../componentes/ListadoUnidades";
import { ESTADO_CURSO, NOMBRE_TIPO_CLASE } from "../lib/constants";
import routeHelper from "../util/routeHelper";
import ButtonCircularProgress from "../componentes/ButtonCircularProgress";
import PanelOrdenarClases from "../componentes/PanelOrdenarClases";
import FormVideoUpload, { validateVideo } from "../componentes/FormVideoUpload";
import * as CustomErrors from '../util/custom.errors';

const config = {
    tarjetaPreview: {
        show: true,
        showRibbon: true
    },
    redirectAfterUpdate: false, // editar curso
    redirectAfterCreate: true,  // agregar curso <- IMPORTANTE: antes de desactivar el redirigido al crear, hay que hacer que el modo de la página cambie de agregar a editar (con una redirección a esta misma vista pero con el URL de edición; y pasando los datos en el "state" de la ruta, para que no se tengan que descargar).
    /** @type {'youtube' | 'url'} */
    tipoVideoPromocional: 'youtube'
};

/** @type {api.ListaClases} */
let estructuraTemporalReordenanda = null; // fuera de la vista para evitar renderizar cada vez que cambie

const fixEstilosImportantes = makeStyles({
    // fue necesario porque en App.css están todos los estilos sobre-escritos con !importante usando las clases base de Material-UI. Lo que hace imposible usar estilos inline o cualquier técnica que no involucre también "!important".
    fixAlertaWarning: {
        backgroundColor: 'rgb(255, 244, 229) !important',
        border: '1px solid #d87218ad',
        '& .MuiAlert-icon': {
            color: '#ff9800 !important'
        },
        '& .MuiAlert-message': {
            color: 'rgb(102, 60, 0) !important'
        },
        '& .MuiAlert-action': {
            color: 'rgb(102, 60, 0) !important'
        }
    },
    fixAlertaInfo: {
        backgroundColor: 'rgb(232, 244, 253) !important',
        border: '1px solid #349ff3bd',
        '& .MuiAlert-icon': {
            color: '#2196f3 !important'
        },
        '& .MuiAlert-message': {
            color: 'rgb(13, 60, 97) !important'
        },
        '& .MuiAlert-action': {
            color: 'rgb(13, 60, 97) !important'
        }
    }
});

const AlertaCursoPublicado = function () {
    const clasesFixEstilos = fixEstilosImportantes();
    return (
        <Alert severity="warning" color="info" className={clasesFixEstilos.fixAlertaWarning}
            style={{ marginTop: '-30px', cursor: 'default', display: 'flex', alignItems: 'center', margin: '0.8rem 0' }}>
            <span>El curso que está editando ya ha sido publicado.</span>
        </Alert>
    );
};

const HtmlTooltip = withStyles({
    tooltip: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 220,
        fontSize: '12px',
        border: '1px solid #dadde9'
    }
})(Tooltip);

const useStyles = makeStyles({
    mainForm: {
        '& .fieldInfoLabel': {
            fontSize: 12,
            color: "red",
            paddingLeft: '0.4rem',
            display: 'flex',
            /** @param {{ guardarPressed: boolean }} props */
            lineHeight: ({ guardarPressed }) => {
                return guardarPressed ? 'unset' : '0';
            }
        }
    }
});

/**
 * @param {Object} obj
 * @param {number} obj.courseId
 * @param {number} obj.userId
 * @param {string} obj.token
 * @returns {Promise<api.CursoInstructor>}
 * @todo API: implementar método para solicitar solo un curso de instructor (por id). Por ahora se están descargando todos.
 */
const cargarDatosCurso = async function ({ courseId, userId, token }) {
    try {
        const response = await api.instructor.getCursos({
            userId,
            token
        });
        const cursos = response.data;
        for (let i = 0, l = cursos.length; i < l; i++) {
            const curso = cursos[i];
            if (curso.id === courseId) {
                return curso;
            }
        }
        throw new Error('Curso no encontrado');
    } finally {
        // empty
    }
};

let textoInputModal = '';

const InputTextoModal = function () {
    const [value, setValue] = useState(textoInputModal);
    return (<>
        <TextField className="InputText" label="Nuevo nombre" variant="outlined" fullWidth
            InputProps={{
                className: "InputText"
            }}
            InputLabelProps={{
                className: "InputText"
            }}
            onChange={(e) => {
                const text = e.target.value;
                textoInputModal = text; // para acceder desde afuera sin necesidad de compartir estado (para evitar el re-render del modal padre)
                setValue(text);
            }}
            type="text"
            value={value}
        />
    </>);
};

export default function UploadPage(props) {

    const isFirst = useIsFirstRender();
    const location = useLocation();
    const courseData = /** @type {api.CursoInstructor} */(location.state);
    const courseId = /\d+/.test(props.match.params.courseId) ? parseInt(props.match.params.courseId) : null;
    if (courseData && courseData.id !== courseId) {
        throw new Error('aborting, data inconsistency detected');
    }
    const isEdit = !!courseId || courseId === 0;
    const moduleTitle = "Datos del Curso" + (isEdit ? " (Edición)" : '');
    const shouldRedirectOnSave = config.redirectAfterCreate && !isEdit || config.redirectAfterUpdate && isEdit;

    const [showLoading, setShowLoading] = useState(isEdit && !courseData);
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();
    const { data_user, token } = useContext(BotonContext);

    const clasesFixEstilos = fixEstilosImportantes();

    /**
     * @returns {Promise<api.SuccessResponse<api.ListaClases>>}
     */
    const refreshUnidades = function () {
        const promesa = api.instructor.getClasesInstructor({ token, courseId, userId: data_user.id_usuario });
        promesa
            .then((data) => {
                if (data.success) {
                    setUnidades(data.data);
                } else {
                    throw new Error(data.mensaje);
                }
            })
            .catch((e) => {
                console.log(e);
            });
        return promesa;
    };

    if (isEdit && isFirst) {
        window.scrollTo(0, 0);
        /** @type {Promise[]} */
        const promesas = [];
        if (!courseData) {
            const promesaCurso = cargarDatosCurso({ courseId, userId: data_user.id_usuario, token });
            promesaCurso
                .then((data) => {
                    setImageBase64(data.imagen);
                    setImageDataURL(commonIO.getDataURLFromImageBinaryB64(data.imagen));
                    setNameAdjunto(data.imagen ? 'imagen' : '');
                    setCourseTitle(data.titulo || '');
                    setCategory(typeof data.id_categoria === 'number' ? data.id_categoria : null);
                    setVideoMotivacionalURL(data.video || '');
                    setDescription(data.descripcion || '');
                    setRequirements(data.requisitos || '');
                    setIsPublished(data.estado === ESTADO_CURSO.PUBLICADO);
                    setDuration(data.duracion?.toString() || '');
                })
                .catch((e) => {
                    console.log(e);
                });
            promesas.push(promesaCurso);
        }
        const promesaUnidades = refreshUnidades();
        promesas.push(promesaUnidades);
        Promise.all(promesas)
            .catch(() => {
                enqueueSnackbar('Error al descargar los datos del curso seleccionado', {
                    variant: 'error'
                });
            })
            .finally(() => {
                setShowLoading(false);
            });
    }

    const [categoryList, setCategoryList] = useState(/** @type {api.CategoryCourse[]} */([])); // TODO?: mostrar loader mientras se carga lista?

    const [imageDataURL, setImageDataURL] = useState((courseData && courseData.imagen && commonIO.getDataURLFromImageBinaryB64(courseData.imagen)) || '');
    const [imageBase64, setImageBase64] = useState(courseData?.imagen || '');
    const [errorBase64, setErrorBase64] = useState('');
    const [nameAdjunto, setNameAdjunto] = useState(courseData?.imagen ? 'imagen' : '');

    const [courseTitle, setCourseTitle] = useState(courseData?.titulo || '');
    const [errorCourseTitle, setErrorCourseTitle] = useState('');

    const [category, setCategory] = useState(typeof courseData?.id_categoria === 'number' ? courseData.id_categoria : null);
    const [errorCategory, setErrorCategory] = useState('');

    const [videoMotivacionalURL, setVideoMotivacionalURL] = useState(courseData?.video || '');
    const [guardarPressed, setGuardarPressed] = useState(false); // flag usado para evitar que se muestren los errores de validación (mensajes) antes de presionar guardar

    const [duration, setDuration] = useState(courseData?.duracion?.toString() || '');
    const [errorDuration, setErrorDuration] = useState('');

    const [description, setDescription] = useState(courseData?.descripcion || null);
    const [errorDescription, setErrorDescription] = useState('');

    const [requirements, setRequirements] = useState(courseData?.requisitos || null);
    const [errorRequirements, setErrorRequirements] = useState('');

    const [unidades, setUnidades] = useState(/** @type {api.ListaClases} */(null));
    const [isPublished, setIsPublished] = useState(courseData?.estado === ESTADO_CURSO.PUBLICADO);

    const [openModal, setOpenModal] = useState(false);
    const [tipoModal, setTipoModal] = useState(/** @type {TipoModal} */('publicar'));
    const [showButtonSpinnerModal, setShowButtonSpinnerModal] = useState(false);
    const [unidadAModificar, setUnidadAModificar] = useState(/** @type {api.Unidad} */(null));
    const [claseAModificar, setClaseAModificar] = useState(/** @type {api.Clase} */(null));

    const [isSaving, setIsSaving] = useState(false);
    const [videoState, setVideoState] = useState(/** @type {import("../componentes/FormVideoUpload").State} */(null));

    const hasNewPromotionalVideo = typeof videoState?.video?.duration === 'number';
    let disabledGuardarReason = '';
    (videoState?.status === 'loading') && (disabledGuardarReason = 'Cargando Video');

    const MemoFormVideoUpload = React.useMemo(() => {
        if (config.tipoVideoPromocional !== 'youtube') return <></>;
        return <FormVideoUpload
            courseId={courseId}
            showPreview={true}
            currentUploadedVideoURL={videoMotivacionalURL}
            onVideoDeleted={() => {
                setVideoMotivacionalURL('');
            }}
            onStateChange={state => {
                setVideoState(state);
            }}
        />;
    }, [courseId, videoMotivacionalURL]);

    useEffect(() => {
        document.title = moduleTitle;
    });

    useEffect(() => {
        api.courseEdit.getCategories()
            .then(data => {
                setCategoryList(data.data);
            })
            .catch(() => {
                enqueueSnackbar("Error al obtener lista de categorías", {
                    variant: 'error'
                });
            });
    }, [category]);

    const classes = useStyles({ guardarPressed: guardarPressed });

    /**
     * @param {string} newValue
     * @returns {boolean}
     */
    const validateImage = function (newValue) {
        let error = '';
        if (!newValue) {
            error = "Se requiere adjuntar imagen";
        }
        setErrorBase64(error);
        return !error;
    };

    /**
     * @param {string} newValue
     * @returns {boolean}
     */
    const validateTitle = function (newValue) {
        let error = '';
        if (!newValue) {
            error = "Se requiere ingresar el título";
        }
        setErrorCourseTitle(error);
        return !error;
    };

    /**
     * @param {number} newValue
     * @returns {boolean}
     */
    const validateCategory = function (newValue) {
        let error = '';
        if (!newValue && newValue != 0) {
            error = "Se requiere seleccionar una categoría";
        }
        setErrorCategory(error);
        return !error;
    };

    /**
     * @param {string} newValue
     * @returns {boolean}
     */
    const validateDescription = function (newValue) {
        let error = '';
        if (!newValue || newValue === '<p><br></p>') {
            error = "Se requiere ingresar la descripción";
        }
        setErrorDescription(error);
        return !error;
    };

    /**
     * @param {string} newValue
     * @returns {boolean}
     */
    const validateRequirements = function (newValue) {
        let error = '';
        if (!newValue || newValue === '<p><br></p>') {
            error = "Se requiere ingresar requerimientos";
        }
        setErrorRequirements(error);
        return !error;
    };

    /**
     * @param {string} newValue
     * @returns {boolean}
     */
    const validateDuration = function (newValue) {
        let error = '';
        if (!newValue) {
            error = "Se requiere ingresar duración";
        } else if (!/^\d*$/.test(newValue)) {
            error = "Duración inválida";
        } else {
            const horas = parseInt(newValue);
            if (horas <= 0) {
                error = "La duración debe ser mayor que cero";
            }
        }
        setErrorDuration(error);
        return !error;
    };

    /**
     * @returns {boolean}
     */
    const ValidarForm = function () {

        let valid = true;

        if (!validateImage(imageBase64)) {
            valid && document.getElementById('button_base64').focus();
            valid = false;
        }

        if (!validateTitle(courseTitle)) {
            valid && document.getElementById('course-title').focus();
            valid = false;
        }

        if (!validateCategory(category)) {
            valid && document.getElementById('select-cate').focus();
            valid = false;
        }

        if (!validateDescription(description)) {
            valid && document.getElementById('quill_descripcion').focus();
            valid = false;
        }

        if (!validateRequirements(requirements)) {
            valid && document.getElementById('quill_req').focus();
            valid = false;
        }

        if (!validateDuration(duration)) {
            valid && document.getElementById('input-duration').focus();
            valid = false;
        }

        if (hasNewPromotionalVideo) {
            const errorVideo = validateVideo(videoState.video);
            if (errorVideo) {
                enqueueSnackbar(errorVideo, {
                    variant: 'warning'
                });
                valid = false;
            }
        }

        return valid;

    };

    const handleGuardar = function () {
        if (isSaving) return;
        setGuardarPressed(true);
        if (ValidarForm()) {
            if (isPublished) {
                setTipoModal('confirmar-editar-publicado');
                abrirModal();
            } else {
                doSave();
            }
        }
    };

    const redirectBack = function () {
        setTimeout(() => {
            history.push("/my-courses-tutor");
        }, 2000);
    };

    const showSaveSuccessToast = function () {
        enqueueSnackbar(`El curso ha sido ${isEdit ? 'actualizado' : 'creado'} satisfactoriamente`, {
            variant: 'success'
        });
    };

    const doSave = async function () {
        setIsSaving(true);
        /** @type {api.CursoUpload} */
        const CourseUpload = {
            id_categoria: category,
            id_instructor: data_user.id_usuario,
            titulo: courseTitle,
            descripcion: description,
            id_moneda: 1,
            precio: null,
            imagen: imageBase64,
            requisitos: requirements,
            video: videoMotivacionalURL,
            duracion: parseInt(duration)
        };
        const promise = isEdit ? api.courseEdit.updateCurso({ CourseUpload, courseId, token }) : api.courseEdit.createCurso({ CourseUpload, token });
        let courseCreated = false;
        try {
            const response = await promise;
            if (!response?.success) {
                setIsSaving(false);
                throw new Error();
            }
            courseCreated = !isEdit;
            if (hasNewPromotionalVideo) {
                const _idCurso = isEdit ? undefined : response.data.id; // TODO: definir tipos de la data de repuesta de `updateCurso` y `createCurso`.
                try {
                    const newVideoUrl = await videoState.uploadTrigger(_idCurso);
                    setVideoMotivacionalURL(newVideoUrl);
                } catch (error) {
                    throw new CustomErrors.CustomError("Error al subir video promocional");
                }
            }
            if (shouldRedirectOnSave) {
                redirectBack();
            }
            setGuardarPressed(false);
            isEdit && setIsSaving(false);
            showSaveSuccessToast();
        } catch (error) {
            const message = error instanceof CustomErrors.CustomError ? error.userMessage : "Error al guardar curso";
            console.log(error);
            if (courseCreated && config.redirectAfterCreate) {
                redirectBack();
            } else {
                setIsSaving(false);
            }
            enqueueSnackbar(message, {
                variant: 'error'
            });
            courseCreated && showSaveSuccessToast(); // mostrar success de curso creado junto con el toast de error // TODO: mostrar por más tiempo estos toasts (override `autoHideDuration`). Es importante que quede claro que se creó el curso.
        }
    };

    function cancel() {
        history.push("/my-courses-tutor");
    }

    function handleClickMisCursosTutor(event) {
        event.preventDefault();
        history.push("/my-courses-tutor");
    }

    const LimpiarCampoImagen = () => {
        setImageBase64('');
        setErrorBase64('');
        setImageDataURL('');
        setNameAdjunto('');
        validateImage('');
    };

    /**
     * @param {React.ChangeEvent<HTMLInputElement>} event
     */
    const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = () => {
                const dataURL = reader.result.toString();
                const b64String = commonIO.getBinaryB64FromImageDataURL(dataURL);
                setImageDataURL(dataURL);
                setImageBase64(b64String);
                setNameAdjunto(file.name);
                validateImage(b64String);
            };
            reader.readAsDataURL(file);
        } else {
            setImageDataURL('');
            setImageBase64('');
            setNameAdjunto('');
        }
    };

    /**
     * @param {Object} params
     * @param {boolean} [params.fixedWidth]
     * @returns {JSX.Element}
     */
    const TemplateListadoUnidades = function ({ fixedWidth }) {
        return (
            <ListadoUnidades
                curso={unidades}
                loading={unidades === null && isEdit}
                handleAgregarClase={() => {
                    if (!isEdit) {
                        enqueueSnackbar('Primero debe crear el curso presionando Guardar', {
                            variant: 'warning'
                        });
                        return;
                    }
                    history.push(routeHelper.rutaAgregarClase(courseId));
                    window.scrollTo(0, 0);
                }}
                handleReordenar={() => {
                    if (!isEdit) {
                        enqueueSnackbar('Primero debe crear el curso presionando Guardar', {
                            variant: 'warning'
                        });
                        return;
                    }
                    if (unidades.n_unidades === 0) {
                        enqueueSnackbar('No hay unidades o clases que ordenar', {
                            variant: 'info'
                        });
                        return;
                    }
                    estructuraTemporalReordenanda = null; // limpiar posibles valores dejados de un reordenado anterior
                    setTipoModal('reordenar-clases');
                    abrirModal();
                }}
                handleEditarClase={(clase) => {
                    history.push({
                        pathname: `/edit-course/edit-class/${courseId}/${clase.id}`,
                        state: clase
                    });
                    window.scrollTo(0, 0);
                }}
                handleRenombrarClase={(clase) => {
                    setTipoModal('renombrar-clase');
                    setClaseAModificar(clase);
                    textoInputModal = clase.titulo;
                    abrirModal();
                }}
                handleEliminarClase={(clase) => {
                    setTipoModal('eliminar-clase');
                    setClaseAModificar(clase);
                    abrirModal();
                }}
                handleRenombrarUnidad={(unidad) => {
                    setTipoModal('renombrar-unidad');
                    setUnidadAModificar(unidad);
                    textoInputModal = unidad.nombre_unidad;
                    abrirModal();
                }}
                handleEliminarUnidad={(unidad) => {
                    setTipoModal('eliminar-unidad');
                    setUnidadAModificar(unidad);
                    abrirModal();
                }}
                fixedWidth={fixedWidth}
            />
        );
    };

    const abrirModal = function () {
        setShowButtonSpinnerModal(false);
        setOpenModal(true);
    };

    function publicarCurso() {
        setShowButtonSpinnerModal(true);
        api.instructor.publicarCurso({
            courseId: courseId,
            token: token
        })
            .then(({ data, status }) => {
                if (data.success) {
                    enqueueSnackbar('Curso publicado exitosamente', {
                        variant: 'success'
                    });
                    setIsPublished(true);
                    closeModal();
                } else {
                    if (status === 200) {
                        enqueueSnackbar(data.mensaje, {
                            variant: 'warning'
                        });
                        setShowButtonSpinnerModal(false);
                    } else {
                        throw new Error(data.mensaje);
                    }
                }
            })
            .catch(() => {
                setShowButtonSpinnerModal(false);
                enqueueSnackbar('Error al publicar curso', {
                    variant: 'error'
                });
            });
    }

    function handlePublicar() {
        if (isPublished) return;
        setTipoModal('publicar');
        abrirModal();
    }

    /**
     * Actualizar el campo "n_clases" de las unidades. Para reflejar el valor correcto luego de reordenar clases (se pueden cambiar de unidad).
     * @param {api.Unidad<api.Clase>[]} _unidades
     */
    const actualizarContadorDeClases = function (_unidades) {
        _unidades.forEach(u => {
            u.n_clases = u.clases.length;
        });
    };

    function handleReordenar() {
        if (!estructuraTemporalReordenanda) {
            // si "estructuraTemporalReordenanda" es null implica que no se reordenó (NOTA: el contrario no es cierto).
            closeModal();
            return;
        }
        setShowButtonSpinnerModal(true);
        /** @type {api.OrdenClases} */
        const nuevoOrden = {};
        Object.values(estructuraTemporalReordenanda.unidades).forEach((unidad) => {
            nuevoOrden[unidad.id_unidad.toString()] = unidad.clases.map((clase) => {
                return clase.id;
            });
        });
        api.instructor.reordenarClases({ token, cursoId: courseId, nuevoOrden })
            .then((data) => {
                if (data.success) {
                    enqueueSnackbar('Acción ejecutada con éxito', {
                        variant: 'success'
                    });
                    actualizarContadorDeClases(Object.values(estructuraTemporalReordenanda.unidades));
                    setUnidades({ ...estructuraTemporalReordenanda });
                    closeModal();
                } else {
                    throw new Error(data.mensaje);
                }
            })
            .catch(() => {
                setShowButtonSpinnerModal(false);
                enqueueSnackbar('Error al reordenar clases', {
                    variant: 'error'
                });
            });
    }

    function handleEliminar() {
        setShowButtonSpinnerModal(true);
        if (tipoModal === 'eliminar-clase') {
            api.instructor.eliminarClase({ token, claseId: claseAModificar.id })
                .then((data) => {
                    if (data.success) {
                        enqueueSnackbar('Acción ejecutada con éxito', {
                            variant: 'success'
                        });
                        setUnidades(null);
                        refreshUnidades();
                        closeModal();
                    } else {
                        throw new Error(data.mensaje);
                    }
                })
                .catch(() => {
                    setShowButtonSpinnerModal(false);
                    enqueueSnackbar('Error al eliminar clase', {
                        variant: 'error'
                    });
                });
        } else if (tipoModal === 'eliminar-unidad') {
            api.instructor.eliminarUnidad({ token, unidadId: unidadAModificar.id_unidad })
                .then((data) => {
                    if (data.success) {
                        enqueueSnackbar('Acción ejecutada con éxito', {
                            variant: 'success'
                        });
                        setUnidades(null);
                        refreshUnidades();
                        closeModal();
                    } else {
                        throw new Error(data.mensaje);
                    }
                })
                .catch(() => {
                    setShowButtonSpinnerModal(false);
                    enqueueSnackbar('Error al eliminar unidad', {
                        variant: 'error'
                    });
                });
        }
    }

    function handleRenombrar() {
        if (!textoInputModal) {
            enqueueSnackbar('Debe especificar un nuevo nombre', {
                variant: 'warning'
            });
            return;
        }
        setShowButtonSpinnerModal(true);
        if (tipoModal === 'renombrar-clase') {
            const claseId = claseAModificar.id;
            const unidadId = claseAModificar.id_unidad;
            const nuevoTitulo = textoInputModal;
            api.autoriaClases.renombrarClase({ token, claseId, nuevoTitulo })
                .then((data) => {
                    if (data.success) {
                        enqueueSnackbar('Acción ejecutada con éxito', {
                            variant: 'success'
                        });
                        unidades.unidades[unidadId].clases.some((clase) => {
                            if (clase.id === claseId) {
                                clase.titulo = nuevoTitulo;
                                return true; // break loop
                            }
                        });
                        setUnidades({ ...unidades });
                        closeModal();
                    } else {
                        throw new Error(data.mensaje);
                    }
                })
                .catch(() => {
                    setShowButtonSpinnerModal(false);
                    enqueueSnackbar('Error al renombrar clase', {
                        variant: 'error'
                    });
                });
        } else if (tipoModal === 'renombrar-unidad') {
            const unidadId = unidadAModificar.id_unidad;
            const nuevoNombre = textoInputModal;
            api.instructor.renombrarUnidad({ token, unidadId, nuevoNombre })
                .then((data) => {
                    if (data.success) {
                        enqueueSnackbar('Acción ejecutada con éxito', {
                            variant: 'success'
                        });
                        unidades.unidades[unidadId].nombre_unidad = nuevoNombre;
                        setUnidades({ ...unidades });
                        closeModal();
                    } else {
                        throw new Error(data.mensaje);
                    }
                })
                .catch(() => {
                    setShowButtonSpinnerModal(false);
                    enqueueSnackbar('Error al renombrar unidad', {
                        variant: 'error'
                    });
                });
        }
    }

    const closeModal = function () {
        setOpenModal(false);
    };

    /**
     * @typedef {'publicar' | 'renombrar-clase' | 'renombrar-unidad' | 'eliminar-clase' | 'eliminar-unidad' | 'reordenar-clases' | 'confirmar-editar-publicado'} TipoModal
     */

    /** @returns {JSX.Element} */
    const ModalContent = function () {
        if (tipoModal === 'publicar') {
            return <>
                <div>
                    <p>Está seguro que desea publicar el curso. Una vez publicado, será visible en la plataforma.</p>
                    <p><b>Nombre del curso:</b> <span className="title_section">{courseTitle}</span></p>
                </div>
            </>;
        } else if (tipoModal === 'eliminar-clase') {
            return <>
                <p>Está seguro que desea eliminar la clase seleccionada? Esta acción es definitiva.</p>
                <p><b>Título de la clase:</b> <span className="title_section always-colored">{claseAModificar.titulo}</span></p>
                <p><b>Tipo:</b> <span>{NOMBRE_TIPO_CLASE[claseAModificar.param_tipo]}</span></p>
                {isPublished && <AlertaCursoPublicado />}
            </>;
        } else if (tipoModal === 'eliminar-unidad') {
            return <>
                <p>Está seguro que desea eliminar la unidad seleccionada? Esta acción es definitiva.</p>
                <p><b>Nombre:</b> <span className="title_section always-colored">{unidadAModificar.nombre_unidad}</span></p>
                <p><b>Número de clases:</b> <span>{unidadAModificar.n_clases}</span></p>
                {unidadAModificar.n_clases > 0 &&
                    <Alert severity="info" color="info" className={clasesFixEstilos.fixAlertaInfo}
                        style={{ marginTop: '-30px', cursor: 'default', display: 'flex', alignItems: 'center', margin: '0.8rem 0' }}>
                        <span>Las clases serán movidas a la unidad anterior. No se serán eliminadas.</span>
                    </Alert>
                }
                {isPublished && <AlertaCursoPublicado />}
            </>;
        } else if (tipoModal === 'renombrar-clase') {
            return <>
                <p>Nombre actual: <span className="title_section always-colored">{claseAModificar.titulo}</span></p>
                <InputTextoModal />
                {isPublished && <AlertaCursoPublicado />}
            </>;
        } else if (tipoModal === 'renombrar-unidad') {
            return <>
                <p>Nombre actual: <span className="title_section always-colored">{unidadAModificar.nombre_unidad}</span></p>
                <InputTextoModal />
                {isPublished && <AlertaCursoPublicado />}
            </>;
        } else if (tipoModal === 'reordenar-clases') {
            return <>
                <PanelOrdenarClases estructuraCurso={estructuraTemporalReordenanda || unidades} onChange={changedStructure => {
                    estructuraTemporalReordenanda = changedStructure;
                }} />
            </>;
        } else if (tipoModal === 'confirmar-editar-publicado') {
            return <>
                <p style={{ fontSize: '1rem' }}><span>El curso que está modificando ya está publicado.</span><br /><span style={{ color: 'var(--color-always-colored)' }}>¿Está seguro que desea continuar con la edición?</span></p>
            </>;
        }
    };

    /**
     * @param {Object} params
     * @param {TipoModal} params.tipo
     * @returns {JSX.Element}
     */
    const ModalActions = function ({ tipo }) {
        if (tipo === 'publicar') {
            return <>
                <Button onClick={closeModal} variant="contained" color="primary" className="button_cancel" disabled={showButtonSpinnerModal} >
                    Cancelar
                </Button>
                <Button onClick={publicarCurso} variant="contained" color="primary" className="button_accept" disabled={showButtonSpinnerModal} >
                    {showButtonSpinnerModal && <ButtonCircularProgress />}
                    Publicar
                </Button>&nbsp;
            </>;
        } else if (tipo === 'eliminar-clase' || tipo === 'eliminar-unidad') {
            return <>
                <Button onClick={closeModal} variant="contained" color="primary" className="button_cancel" disabled={showButtonSpinnerModal} >
                    Cancelar
                </Button>
                <Button onClick={handleEliminar} variant="contained" color="primary" className="button_delete" disabled={showButtonSpinnerModal} >
                    {showButtonSpinnerModal && <ButtonCircularProgress />}
                    <DeleteOutlineOutlinedIcon style={{ marginRight: '4px', marginLeft: '-6px' }} />
                    Eliminar
                </Button>&nbsp;
            </>;
        } else if (tipo === 'renombrar-clase' || tipo === 'renombrar-unidad') {
            return <>
                <Button onClick={closeModal} variant="contained" color="primary" className="button_cancel" disabled={showButtonSpinnerModal} >
                    Cancelar
                </Button>
                <Button onClick={handleRenombrar} variant="contained" color="primary" className="button_accept" disabled={showButtonSpinnerModal} >
                    {showButtonSpinnerModal && <ButtonCircularProgress />}
                    Renombrar
                </Button>&nbsp;
            </>;
        } else if (tipo === 'reordenar-clases') {
            return <>
                <Button onClick={closeModal} variant="contained" color="primary" className="button_cancel" disabled={showButtonSpinnerModal} >
                    Cancelar
                </Button>
                <Button onClick={handleReordenar} variant="contained" color="primary" className="button_accept" disabled={showButtonSpinnerModal} >
                    {showButtonSpinnerModal && <ButtonCircularProgress />}
                    Guardar
                </Button>&nbsp;
            </>;
        } else if (tipo === 'confirmar-editar-publicado') {
            return <>
                <Button onClick={closeModal} variant="contained" color="primary" className="button_cancel" disabled={isSaving} >
                    Cancelar
                </Button>
                <Button onClick={() => { closeModal(); doSave(); }} variant="contained" color="primary" className="button_accept" disabled={isSaving} >
                    Guardar
                </Button>&nbsp;
            </>;
        }
    };

    /**
     * @returns {string}
     */
    const getModalTitle = function () {
        switch (tipoModal) {
            case 'eliminar-clase':
                return "Eliminar Clase";
            case 'eliminar-unidad':
                return "Eliminar Unidad";
            case 'renombrar-clase':
                return "Renombrar Clase";
            case 'renombrar-unidad':
                return "Renombrar Unidad";
            case 'publicar':
                return "Publicar Curso";
            case 'reordenar-clases':
                return "Reordenar";
            case 'confirmar-editar-publicado':
                return "Curso Publicado";
            default:
                return '';
        }
    };

    return (<>
        <div className="App rel">

            <Breadcrumbs aria-label="breadcrumb" className="breadcrumbs_link_inneret">
                <Link color="inherit" onClick={handleClickMisCursosTutor} className="breadcrumbs_link_inneret">
                    {strings.MIS_TUTORIAS}
                </Link>
                <Typography color="textPrimary" className="breadcrumbs_link">
                    {moduleTitle}
                </Typography>
            </Breadcrumbs>

            <div className="tituloInicial rel">
                <h1 className="title_section s24 fontb c333">{moduleTitle}</h1>
            </div>

            <div className="section section-b rel" style={{ marginTop: '20px', display: 'flex', gap: '1rem' }}>
                <div className="rel" >
                    <form className={classes.mainForm}>
                        <Grid container spacing={1} item xs={12}>

                            {/* Imagen */}
                            <Grid item xs={12}>
                                <div className="section title_section content">

                                    <h3 className="title_section">Adjuntar imagen: </h3>
                                    <div style={{ marginTop: '20px' }}></div>

                                    <div style={{ display: 'flex', gap: '2rem' }}>

                                        {/* vista previa imagen */}
                                        <label htmlFor="course-image">
                                            <Card
                                                className="themed-border"
                                                style={{ maxWidth: '330px', cursor: 'pointer' }}
                                                elevation={2}
                                            >
                                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                    <img src={imageDataURL || imageSubir} style={{ maxWidth: '100%', display: 'block' }} className="checkerboard-background"></img>
                                                </div>
                                            </Card>
                                        </label>

                                        {/* vista previa tarjeta */}
                                        {config.tarjetaPreview.show && imageDataURL &&
                                            // @ts-ignore
                                            <Hidden xsDown>
                                                <TarjetaEcampus
                                                    image={imageDataURL || imageSubir}
                                                    text1={commonIO.shortenText(courseTitle, 72, { maxDelta: 0 })}
                                                    text2={data_user.nombres}
                                                    showRibbon={true}
                                                    ribbonOptions={{ text: "vista previa" }}
                                                    elevation={2}
                                                />
                                            </Hidden>
                                        }

                                    </div>

                                    <div style={{ marginTop: '5px' }}>
                                        {nameAdjunto}

                                        {!!imageDataURL &&

                                            <Button
                                                variant="contained"
                                                component="label"
                                                className="button_accept"
                                                id="button_remove_image"
                                                size="small"
                                                style={{ marginLeft: '5px', marginTop: '0px' }}
                                                onClick={LimpiarCampoImagen}
                                            >
                                                <CancelIcon />
                                            </Button>
                                        }

                                    </div>

                                    <div style={{ marginTop: '20px' }}></div>

                                    <HtmlTooltip
                                        title={
                                            <React.Fragment>
                                                <Typography color="inherit">Adjuntar imagen</Typography>
                                                <em>{"Puede adjuntar una sola imagen por curso, debe ser en formato"}</em> <b>{'png, jpeg o jpg'}</b>.
                                            </React.Fragment>
                                        }
                                    >
                                        <Button
                                            variant="contained"
                                            component="label"
                                            className="button_accept"
                                            id="button_base64"
                                        >
                                            <AttachFile />
                                            <input hidden type="file" id="course-image" accept="image/png, image/jpeg, image/jpg"
                                                onChange={handleImageChange}
                                                style={{ color: "white", backgroundColor: "transparent" }}
                                            />
                                            Adjuntar imagen
                                        </Button>
                                    </HtmlTooltip>

                                    <p className="fieldInfoLabel" style={{ fontSize: 12, color: "red", visibility: errorBase64 && guardarPressed ? "visible" : "hidden" }}>
                                        {errorBase64}
                                    </p>
                                </div>
                            </Grid>

                            {/* Título */}
                            <Grid item xs={12}>
                                <TextField className="InputText" id="course-title" label="Título" variant="outlined" fullWidth
                                    InputProps={{
                                        className: "InputText"
                                    }}
                                    InputLabelProps={{
                                        className: "InputText"
                                    }}
                                    error={!!errorCourseTitle && guardarPressed}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        setCourseTitle(value);
                                        validateTitle(value);
                                    }}
                                    type="text"
                                    value={courseTitle}
                                />
                                <p className="fieldInfoLabel" style={{ fontSize: 12, color: "red", visibility: errorCourseTitle && guardarPressed ? "visible" : "hidden" }}>
                                    {errorCourseTitle}
                                </p>
                            </Grid>

                            {/* Categoría */}
                            <Grid item xs={12}>
                                <FormControl variant="outlined" style={{ minWidth: '100%' }}>
                                    <InputLabel id="select-cate-label" className="InputText">Categoría</InputLabel>
                                    <Select
                                        labelId="select-cate-label"
                                        id="select-cate"
                                        value={typeof category === 'number' ? category : ''}
                                        label="Categoría"
                                        onChange={(e) => {
                                            const value = /** @type {number} */(e.target.value);
                                            setCategory(value);
                                            validateCategory(value);
                                        }}
                                        error={!!errorCategory && guardarPressed}
                                        required
                                    >
                                        {categoryList.map(cateValues =>
                                            <MenuItem key={cateValues.id} value={cateValues.id}>{cateValues.nombre}</MenuItem>
                                        )}
                                    </Select>
                                    <p className="fieldInfoLabel" style={{ fontSize: 12, color: "red", visibility: errorCategory && guardarPressed ? "visible" : "hidden" }}>
                                        {errorCategory}
                                    </p>
                                </FormControl>
                            </Grid>

                            {/* Duración */}
                            <Grid item xs={12}>
                                <div style={{ marginTop: '0' }} className="section section-b " >
                                    <TextField className="InputText" id='input-duration' label="Duración (horas)" variant="outlined" fullWidth
                                        InputProps={{
                                            className: "InputText"
                                        }}
                                        InputLabelProps={{
                                            className: "InputText"
                                        }}
                                        error={!!errorDuration && guardarPressed}
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            if (/^\d*$/.test(value)) {
                                                setDuration(value);
                                                validateDuration(value);
                                            }
                                        }}
                                        type="text"
                                        value={duration}
                                    />
                                </div>
                                <p className="fieldInfoLabel" style={{ fontSize: 12, color: "red", visibility: errorDuration && guardarPressed ? "visible" : "hidden" }}>
                                    {errorDuration}
                                </p>
                            </Grid>

                            {/* Video Motivacional */}
                            {config.tipoVideoPromocional === 'url' &&
                                <Grid item xs={12}>
                                    <div style={{ marginTop: '0' }} className="section section-b" >
                                        <TextField className="InputText" id="video-motivacional" label="URL de video motivacional" variant="outlined" fullWidth
                                            InputProps={{
                                                className: "InputText"
                                            }}
                                            InputLabelProps={{
                                                className: "InputText"
                                            }}
                                            onChange={(e) => {
                                                const value = e.target.value;
                                                setVideoMotivacionalURL(value);
                                            }}
                                            type="text"
                                            value={videoMotivacionalURL}
                                        />
                                    </div>
                                </Grid>
                            }
                            {config.tipoVideoPromocional === 'youtube' &&
                                <Grid item xs={12}>
                                    {MemoFormVideoUpload}
                                </Grid>
                            }

                            {/* Descripción */}
                            <Grid item xs={12} style={{ marginTop: '1rem' }} >
                                <h3 className="title_section" style={{ marginBottom: '0.3rem' }}>Descripción</h3>
                                <EditorToolbar shortId="Descripcion" />
                                <ReactQuill
                                    id="quill_descripcion"
                                    key="1"
                                    theme="snow"
                                    value={description}
                                    onChange={(value) => {
                                        setDescription(value);
                                        validateDescription(value);
                                    }}
                                    placeholder={"Ingrese la descripción del curso."}
                                    modules={modules("Descripcion")}
                                    formats={formats}
                                />
                                <p className="fieldInfoLabel" style={{ fontSize: 12, color: "red", visibility: errorDescription && guardarPressed ? "visible" : "hidden" }}>
                                    {errorDescription}
                                </p>
                            </Grid>

                            {/* Requerimientos */}
                            <Grid item xs={12}>
                                <h3 className="title_section" style={{ marginBottom: '0.3rem' }}>Requerimientos</h3>
                                <EditorToolbar shortId="Requerimientos" />
                                <ReactQuill
                                    id="quill_req"
                                    key="2"
                                    theme="snow"
                                    value={requirements}
                                    onChange={(value) => {
                                        setRequirements(value);
                                        validateRequirements(value);
                                    }}
                                    placeholder={"Ingrese los requerimientos del curso."}
                                    modules={modules("Requerimientos")}
                                    formats={formats}
                                />
                                <p className="fieldInfoLabel" style={{ fontSize: 12, color: "red", visibility: errorRequirements && guardarPressed ? "visible" : "hidden" }}>
                                    {errorRequirements}
                                </p>
                            </Grid>

                            {/*
                            // @ts-ignore */}
                            <Hidden mdUp>
                                <TemplateListadoUnidades fixedWidth={false} />
                            </Hidden>

                            {/* Botones Guardar / Cancelar */}
                            <Grid item xs={12}>
                                {/*
                                // @ts-ignore */}
                                <Hidden smUp>
                                    <div className="section section-b content " style={{ marginTop: '20px' }}>

                                        <Button fullWidth type="button" onClick={handleGuardar} variant="contained" color="primary" style={{ marginTop: '15px' }}
                                            disabled={!!disabledGuardarReason}
                                            className={disabledGuardarReason ? 'button_accept_disable' : null} // TODO: evitar necesidad de clase "button_accept_disable". Que baste con establecer `disabled={true}` (clase: 'Mui-disabled').
                                        >
                                            {isSaving && <ButtonCircularProgress />}
                                            {disabledGuardarReason ? disabledGuardarReason + '...' : "Guardar"}
                                        </Button>

                                        {isEdit &&
                                            <Button fullWidth type="button" variant="contained" style={{ marginTop: '15px', opacity: isPublished || !isEdit ? '0.5' : null }} className="button_blue" onClick={handlePublicar} disabled={isSaving || !isEdit} >
                                                {isPublished ? "Curso ya publicado" : "Publicar Curso"}
                                            </Button>
                                        }

                                        <Button fullWidth type="button" variant="contained" style={{ marginTop: '15px' }} className="button_cancel" onClick={cancel} disabled={isSaving} >
                                            Cancelar
                                        </Button>

                                    </div>
                                </Hidden>
                                {/*
                                // @ts-ignore */}
                                <Hidden xsDown>
                                    <div className="section section-b content" style={{ textAlign: 'right' }}>

                                        <Button type="button" variant="contained" style={{ marginLeft: '15px' }} className="button_cancel" onClick={cancel} disabled={isSaving} >
                                            Cancelar
                                        </Button>

                                        {isEdit &&
                                            <Button type="button" variant="contained" style={{ marginLeft: '15px', opacity: isPublished || !isEdit ? '0.5' : null }} className="button_blue" onClick={handlePublicar} disabled={isSaving || !isEdit} >
                                                {isPublished ? "Curso ya publicado" : "Publicar Curso"}
                                            </Button>
                                        }

                                        <Button type="button" onClick={handleGuardar} variant="contained" color="primary" style={{ marginLeft: '15px' }}
                                            disabled={!!disabledGuardarReason}
                                            className={disabledGuardarReason ? 'button_accept_disable' : null}
                                        >
                                            {isSaving && <ButtonCircularProgress />}
                                            {disabledGuardarReason ? disabledGuardarReason + '...' : "Guardar"}
                                        </Button>

                                    </div>
                                </Hidden>
                            </Grid>
                        </Grid>
                    </form>
                </div>
                {showLoading &&
                    <LoadingPanel />
                }
                {/*
                // @ts-ignore */}
                <Hidden smDown>
                    <TemplateListadoUnidades />
                </Hidden>
            </div>
        </div>
        <Dialog open={openModal} onClose={closeModal} aria-labelledby="customized-dialog-title" disableScrollLock={true} style={{ cursor: 'default' }}>
            <DialogTitle id="form-dialog-title" onClose={closeModal} className="modal_title" >{getModalTitle() || <span>&nbsp;</span>}</DialogTitle>
            <DialogContent className="modal_content">
                <ModalContent />
            </DialogContent>
            <DialogActions className="modal_footer">
                <ModalActions tipo={tipoModal} />
            </DialogActions>
        </Dialog>
    </>);
}
