import React, { useEffect, useState, useContext } from "react";
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import { useHistory } from "react-router";

//Breadcrumbs
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';

//AXIOS
import axios from 'axios';
//REACT CONTEXT
import { BotonContext } from "../BotonContext";
import InputAdornment from "@material-ui/core/InputAdornment";

import api from "../lib/api";
import commonIO from "../util/common.io";
import { rutHelper } from "../util/utils";

//const passwordRegexp = new RegExp(/^(?=.*\d)(?=.*[\u0021-\u002b\u003c-\u0040])(?=.*[A-Z])(?=.*[a-z])\S{4,6}$/);  //4,6: Rango de cantidad de caracteres de 4-6
//const passwordRegexp = new RegExp(/^(?=.*\d)(?=.*[\u0021-\u002b\u003c-\u0040])(?=.*[A-Z])(?=.*[a-z])\S{8,10}$/);  //8,10: Rango de cantidad de caracteres de 8-10
//passwordRegexp, extraido de: http://w3.unpocodetodo.info/utiles/regex-ejemplos.php?type=psw

function Contact(props) {

  const section = props.match.params.section;

  useEffect(() => {
    document.title = "Contacto";
  });

  const history = useHistory();
  const { token } = useContext(BotonContext);


  const [nombre, setNombre] = useState("");
  const [errorNombre, setErrorNombre] = useState(false);
  const [mensNombre, setMensNombre] = useState("");

  const [apellido, setApellido] = useState("");
  const [errorApellido, setErrorApellido] = useState(false);
  const [mensApellido, setMensApellido] = useState("");

  const [rut, setRut] = useState("");
  const [errorRut, setErrorRut] = useState(false);
  const [mensRut, setMensRut] = useState("");

  const [f_nac, setFnac] = useState("");
  const [errorFnac, setErrorFnac] = useState(false);
  const [mensFnac, setMensFnac] = useState("");

  const [sexo, setSexo] = useState("");
  const [errorSexo, setErrorSexo] = useState(false);
  const [mensSexo, setMensSexo] = useState("");

  const [pais, setPais] = useState("");
  const [errorPais, setErrorPais] = useState(false);
  const [mensPais, setMensPais] = useState("");

  const [email, setEmail] = useState("");
  const [errorEmail, setErrorEmail] = useState(false);
  const [mensEmail, setMensEmail] = useState("");

  const [open, setOpen] = useState(false);
  const [mensajesuccess, setMensajeSuccess] = useState("");

  const [openmensbackend, setOpenmensbackend] = useState(false);
  const [mensajebackend, setMensajebackend] = useState("");

  const [nombreinstitucion, setNombreInstitucion] = useState("");
  const [errorNombreInstitucion, setErrorNombreInstitucion] = useState(false);
  const [mensNombreinstitucion, setMensNombreInstitucion] = useState("");

  const [cargo, setCargo] = useState("");
  const [errorCargo, setErrorCargo] = useState(false);
  const [mensCargo, setMensCargo] = useState("");

  const [region, setRegion] = useState("");
  const [errorRegion, setErrorRegion] = useState(false);
  const [mensRegion, setMensRegion] = useState("");

  const [ciudad, setCiudad] = useState("");
  const [errorCiudad, setErrorCiudad] = useState(false);
  const [mensCiudad, setMensCiudad] = useState("");

  const [codPais, setCodPais] = useState("");
  const [errorCodPais, setErrorCodPais] = useState(false);
  const [mensCodPais, setMensCodPais] = useState("");

  const [telefono, setTelefono] = useState("");
  const [errorTelefono, setErrorTelefono] = useState(false);
  const [mensTelefono, setMensTelefono] = useState("");

  const [comentario, setComentario] = useState("");
  const [errorComentario, setErrorComentario] = useState(false);
  const [mensComentario, setMensComentario] = useState("");

  const [disabledAddContact, setDisabledAddContact] = useState(false);


  const [paisesApi, setPaisesApi] = useState(/** @type {api.PaísPasarela[]} */([]));

  useEffect(() => {
    api.getPaisesPasarela()
      .then(data => {
        if (data.success) {
          setPaisesApi(data.data);
        }
      });
  }, []);

  /*
  const [regionesApi, setRegionesApi] = useState([])

  useEffect(() => {
      fetch(process.env.REACT_APP_URL_API+'/paises')
      .then(response => response.json()
      .then(data => {
        if (data.success === true){
          //console.log(data.data)
          setRegionesApi(data.data)
        }else{

        }
      })
  )}, []);

  const [ciudadesApi, setCiudadesApi] = useState([])

  useEffect(() => {
      fetch(process.env.REACT_APP_URL_API+'/paises')
      .then(response => response.json()
      .then(data => {
        if (data.success === true){
          //console.log(data.data)
          setCiudadesApi(data.data)
        }else{

        }
      })
  )}, []);
  */

  /*
  const handleClick = () => {
    setOpen(true);
  };
  */

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const handleCloseMens = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenmensbackend(false);
  };


  const handleChangeSexo = (e) => {
    setSexo(e.target.value);
  };


  const handleSubmit = (e) => {

    e.preventDefault();
    setDisabledAddContact(true);

    if (!nombre || nombre == '' || nombre == undefined) {
      setErrorNombre(true);
      setMensNombre("Se requiere ingresar nombre");
      document.getElementById('nombre').focus();
      setDisabledAddContact(false);
      return;
    }
    if (nombre.length > 250) {
      setErrorNombre(true);
      setMensNombre("El nombre no debe pasar de 250 caracteres");
      document.getElementById('nombre').focus();
      setDisabledAddContact(false);
      return;
    } else {
      setErrorNombre(false);
      setMensNombre("");
    }

    if (!apellido || apellido == '' || apellido == undefined) {
      setErrorApellido(true);
      setMensApellido("Se requiere ingresar apellido");
      document.getElementById('apellido').focus();
      setDisabledAddContact(false);
      return;
    }
    if (apellido.length > 250) {
      setErrorApellido(true);
      setMensApellido("El apellido no debe pasar de 250 caracteres");
      document.getElementById('apellido').focus();
      setDisabledAddContact(false);
      return;
    } else {
      setErrorApellido(false);
      setMensApellido("");
    }

    /*
    if (!rut || rut == '' || rut == undefined) {
      setErrorRut(true);
      setMensRut("Se requiere ingresar identificación");
      document.getElementById('rut').focus();
      setDisabledAddContact(false);
      return;
    }

    const hasErrorRut = rutHelper.validaRut(rut);
    if (hasErrorRut == false) {
      setErrorRut(true);
      setMensRut("Su identificación no es válida. El formato es con digito verificador y sin puntos. Ejemplo: 11111111-1");
      document.getElementById('rut').focus();
      setDisabledAddContact(false);
      return;
    } else {
      setErrorRut(false);
      setMensRut("");
    }
    */

    /*
    if(!f_nac || f_nac == '' || f_nac == undefined){
      setErrorFnac(true);
      setMensFnac("Se requiere ingresar Fecha de nacimiento");
      return;
    }


    if(sexo || sexo != '' || sexo == undefined){

      setErrorSexo(false);
      setMensSexo("");
    }else{

      setErrorSexo(true);
      setMensSexo("Este campo es obligatorio");
    }
    */

    if (pais || pais != '' || pais == undefined) {

      setErrorPais(false);
      setMensPais("");
    } else {

      setErrorPais(true);
      setMensPais("Este campo es obligatorio");
      document.getElementById('demo-simple-select-outlined').focus();
      setDisabledAddContact(false);
      return;
    }

    /*
    if(region || region != '' || region == undefined){

      setErrorRegion(false);
      setMensRegion("");
    }else{

      setErrorRegion(true);
      setMensRegion("Este campo es obligatorio");
    }

    if(ciudad || ciudad != '' || ciudad == undefined){

      setErrorCiudad(false);
      setMensCiudad("");
    }else{

      setErrorCiudad(true);
      setMensCiudad("Este campo es obligatorio");
    }


    if (!nombreinstitucion || nombreinstitucion == '' || nombreinstitucion == undefined) {
      setErrorNombreInstitucion(true);
      setMensNombreInstitucion("Se requiere ingresar nombre de la institución");
      document.getElementById('nombreinstitucion').focus();
      setDisabledAddContact(false);
      return;
    }
    if (nombreinstitucion.length > 250) {
      setErrorNombreInstitucion(true);
      setMensNombreInstitucion("El nombre no debe pasar de 250 caracteres");
      document.getElementById('nombreinstitucion').focus();
      setDisabledAddContact(false);
      return;
    } else {
      setErrorNombreInstitucion(false);
      setMensNombreInstitucion("");
    }

    if (!cargo || cargo == '' || cargo == undefined) {
      setErrorCargo(true);
      setMensCargo("Se requiere ingresar nombre de la institución");
      document.getElementById('cargo').focus();
      setDisabledAddContact(false);
      return;
    }
    if (cargo.length > 250) {
      setErrorCargo(true);
      setMensCargo("El nombre no debe pasar de 250 caracteres");
      document.getElementById('cargo').focus();
      setDisabledAddContact(false);
      return;
    } else {
      setErrorCargo(false);
      setMensCargo("");
    }
    */

    if (!email) {
      setErrorEmail(true);
      setMensEmail("Se requiere ingresar correo");
      document.getElementById('email').focus();
      setDisabledAddContact(false);
      return;
    }
    const hasErrorEmail = !commonIO.isValidEmail(email);
    if (hasErrorEmail == false) {
      setErrorEmail(false);
      setMensEmail("");
    } else {
      setErrorEmail(true);
      setMensEmail("Por favor introduzca un correo válido");
      document.getElementById('email').focus();
      setDisabledAddContact(false);
      return;
    }

    /*
    if (!codPais || codPais == '' || codPais == undefined) {
      setErrorCodPais(true);
      setMensCodPais("Se requiere ingresar código telefónico del pais");
      document.getElementById('cod_pais').focus();
      setDisabledAddContact(false);
      return;
    }
    if (codPais.length > 4) {
      setErrorCodPais(true);
      setMensCodPais("El código no debe pasar de 4 dígitos");
      document.getElementById('cod_pais').focus();
      setDisabledAddContact(false);
      return;
    } else {
      setErrorCodPais(false);
      setMensCodPais("");
    }

    if (!telefono || telefono == '' || telefono == undefined) {
      setErrorTelefono(true);
      setMensTelefono("Se requiere ingresar número telefónico");
      document.getElementById('telefono').focus();
      setDisabledAddContact(false);
      return;
    }
    if (telefono.length > 10) {
      setErrorTelefono(true);
      setMensTelefono("El número no debe pasar de 11 dígitos");
      document.getElementById('telefono').focus();
      setDisabledAddContact(false);
      return;
    } else {
      setErrorTelefono(false);
      setMensTelefono("");
    }
    */
    if (!comentario || comentario == '' || comentario == undefined) {
      setErrorComentario(true);
      setMensComentario("Se requiere ingresar comentario");
      document.getElementById('comentario').focus();
      setDisabledAddContact(false);
      return;
    }
    /*
    if (comentario.length > 400) {
      setErrorComentario(true);
      setMensComentario("El comentario no debe pasar de 400 caracteres");
      document.getElementById('comentario').focus();
      setDisabledAddContact(false);
      return;
    } else {
      setErrorComentario(false);
      setMensComentario("");
    }
    */

    let tipo = 0;
    if (section != 'Programa' || section == 'Subscripción') { tipo = 1 }
    if (section == 'Programa') { tipo = 2 }
    if (section == 'Subscripción') { tipo = 3 }

    const json_contacto = {
      "nombre": nombre,
      "apellido": apellido,
      "identificacion": rut, //null
      //"fecha_nacimiento" : f_nac,
      //"sexo"  : sexo,
      "id_pais": pais,
      "region": region,
      "ciudad": ciudad,
      "institucion": nombreinstitucion,
      "cargo": cargo,
      "email": email,
      "movil": '+' + codPais + '-' + telefono,
      "comentario": comentario,
      //"password" : password,
      "tipo": tipo,
      "estado": 1
    }

    //console.log("json_contacto: ", json_contacto);



    axios.post(process.env.REACT_APP_URL_API + '/contacto-ventas', json_contacto, {
      headers: {
        'Content-Type': 'application/json',
        'token': token
      }
    })
      .then(async (response) => {
        //console.log("contacto_venta0: ", response.data);
        //console.log("Success: ", response.data.success);
        if (response.data.success === true) {
          //console.log("contacto_venta: ", response.data);


          setMensajeSuccess(response.data.mensaje);
          setOpen(true);
          LimpiarForm();
          setDisabledAddContact(false);
          let intervalId = setInterval(() => {
            history.goBack();
            clearInterval(intervalId);
          }, 2000);

        }
        if (response.data.success === false) {
          setMensajebackend(response.data.mensaje);
          setOpenmensbackend(true)
        }
        //else{
        //  history.push("/register");
        //  setOpen(true);
        //}

      })
      .catch(function (error) {
        const respuesta = error;
        console.log("Error:", respuesta);

      });



  }


  function LimpiarForm() {

    setNombre("");
    setErrorNombre(false);
    setMensNombre("");

    setApellido("");
    setErrorApellido(false);
    setMensApellido("");

    setRut("");
    setErrorRut(false);
    setMensRut("");

    setFnac("");
    setErrorFnac(false);
    setMensFnac("");

    setSexo("");
    setErrorSexo(false);
    setMensSexo("");

    setPais("");
    setErrorPais(false);
    setMensPais("");

    setEmail("");
    setErrorEmail(false);
    setMensEmail("");

    //setOpen(false);
    //setMensajeSuccess("");

    //setOpenmensbackend(false);
    //setMensajebackend("");

    setNombreInstitucion("");
    setErrorNombreInstitucion(false);
    setMensNombreInstitucion("");

    setCargo("");
    setErrorCargo(false);
    setMensCargo("");

    /*
    setRegion("");
    setErrorRegion(false);
    setMensRegion("");

    setCiudad("");
    setErrorCiudad(false);
    setMensCiudad("");
    */

    setCodPais("");
    setErrorCodPais(false);
    setMensCodPais("");

    setTelefono("");
    setErrorTelefono(false);
    setMensTelefono("");

    setComentario("");
    setErrorComentario(false);
    setMensComentario("");

    setPaisesApi([]);

    //setRegionesApi([]);

    //setCiudadesApi([]);

    setDisabledAddContact(false);

  }

  const useStyles = makeStyles((theme) => ({
    formControl: {
      //margin: theme.spacing(1),
      minWidth: '100%',
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      borderRadius: '5px',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
  }));


  const useStyles2 = makeStyles({
    root: {
      minWidth: '100%',
      maxWidth: '100%',
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
  });

  const useStyles3 = makeStyles((theme) => ({
    container: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 200,
    }
  }));

  const classes = useStyles();
  const classes2 = useStyles2();
  const classes3 = useStyles3();


  function handleClickInicio(event) {
    event.preventDefault();
    history.push("/home")
    //href={"#/my-courses"}
  }

  function handleClickDiplomados(event) {
    event.preventDefault();
    history.push("/certified")
    //href={"#/my-courses"}
  }

  function handleClickSubscripcion(event) {
    event.preventDefault();
    history.push("/subscription/Inicio")
    //href={"#/my-courses"}
  }

  function handleClickCursoSection(event) {
    event.preventDefault();
    history.goBack();
    //href={"#/my-courses"}
  }



  return (

    <div className="App rel" >

      <Breadcrumbs aria-label="breadcrumb" className="breadcrumbs_link_inneret">

        {section != "Programa" &&
          <Link color="inherit" onClick={handleClickInicio} className="breadcrumbs_link_inneret">
            Inicio
          </Link>
        }
        {section != "Programa" &&
          <Link color="inherit" onClick={handleClickSubscripcion} className="breadcrumbs_link_inneret">
            Subscripciones
          </Link>

        }
        {section != "Subscripción" &&
          <Link color="inherit" onClick={handleClickDiplomados} className="breadcrumbs_link_inneret">
            Programas
          </Link>
        }
        <Link color="inherit" onClick={handleClickCursoSection} className="breadcrumbs_link_inneret">
          {section}
        </Link>

        <Typography color="textPrimary" className="breadcrumbs_link">
          Contacto
        </Typography>
      </Breadcrumbs>

      <h1 className="title_section s24 fontb c333">Contacto</h1>
      <div className="section section-c rel bloque-contenido fix-app-padding flex-content-centered">
        <div className="rel content-narrow">
          <form id="form" className={classes.modal}>

            <Grid container spacing={1} >


              <Snackbar open={open} autoHideDuration={3000} onClose={handleClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
                <Alert severity="success" variant="filled">
                  {mensajesuccess}
                </Alert>
              </Snackbar>
              <Snackbar open={openmensbackend} autoHideDuration={3000} onClose={handleCloseMens} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
                <Alert severity="error" variant="filled">
                  {mensajebackend}
                </Alert>
              </Snackbar>

              <Grid item xs={12}>
                <div className="section title_section content">
                  <TextField id="nombre" label="Nombre"
                    InputProps={{
                      className: "InputText"
                    }}
                    InputLabelProps={{
                      className: "InputText",
                    }}
                    maxRows={12}
                    minRows={7} variant="outlined" fullWidth
                    error={errorNombre}
                    //errormessage={errorNombre.toString()}
                    onBlur={(e) => {
                      if (nombre || nombre != '' || nombre == undefined) {
                        setErrorNombre(false);
                        setMensNombre("");
                      } else {
                        setErrorNombre(true);
                        setMensNombre("Este campo es obligatorio");
                      }
                    }}
                    onChange={(e) => {
                      setNombre(e.target.value)
                      if (nombre.length > 250) {
                        setErrorNombre(true);
                        setMensNombre("El nombre no debe pasar de 250 caracteres");
                      } else {
                        setErrorNombre(false);
                        setMensNombre("");
                      }
                    }}
                    helpertext={mensNombre}
                    type="text"
                    value={nombre}
                    required
                  />
                  <p
                    id="nombresID"
                    style={{ fontSize: 12, color: "red", visibility: mensNombre == "" ? "hidden" : "visible" }}
                  >
                    {mensNombre}
                  </p>
                </div>
              </Grid>
              <br></br>

              <Grid item xs={12}>
                <div style={{ marginTop: '25px' }} className="section title_section content">
                  <TextField id="apellido" label="Apellido"
                    InputProps={{
                      className: "InputText"
                    }}
                    InputLabelProps={{
                      className: "InputText",
                    }}
                    maxRows={12}
                    minRows={7} variant="outlined" fullWidth
                    //handleChange={handleChange}
                    error={errorApellido}
                    onBlur={(e) => {
                      if (apellido || apellido != '' || apellido == undefined) {
                        setErrorApellido(false);
                        setMensApellido("");
                      } else {
                        setErrorApellido(true);
                        setMensApellido("Este campo es obligatorio");
                      }
                    }}
                    onChange={(e) => {
                      setApellido(e.target.value)
                      if (apellido.length > 250) {
                        setErrorApellido(true);
                        setMensApellido("El apellido no debe pasar de 250 caracteres");
                      } else {
                        setErrorApellido(false);
                        setMensApellido("");
                      }
                    }}
                    helpertext={mensApellido}
                    type="text"
                    value={apellido}
                    required
                  />
                  <p
                    id="apellidosID"
                    style={{ fontSize: 12, color: "red", visibility: mensApellido == "" ? "hidden" : "visible" }}
                  >
                    {mensApellido}
                  </p>
                </div>
              </Grid>
              <br></br>


              <Grid item xs={12}>
                <div style={{ marginTop: '25px' }} className="section title_section content">
                  <TextField id="rut" label="Identificación"
                    InputProps={{
                      className: "InputText"
                    }}
                    InputLabelProps={{
                      className: "InputText",
                    }}
                    maxRows={12}
                    minRows={7} variant="outlined" fullWidth
                    error={errorRut}
                    /*
                    onBlur={(e) => {

                      if (rut || rut != '' || rut == undefined) {

                        const hasError = rutHelper.validaRut(e.target.value);
                        if (hasError == false) {
                          setErrorRut(true);
                          setMensRut("Su identificación no es válida. El formato es con digito verificador y sin puntos. Ejemplo: 11111111-1");
                        } else {
                          setErrorRut(false);
                          setMensRut("");
                        }

                        setRut(e.target.value);
                        setErrorRut(false);
                        setMensRut("");
                      } else {
                        setErrorRut(true);
                        setMensRut("Este campo es obligatorio");
                      }
                    }}
                    */
                    onChange={(e) => setRut(e.target.value)}
                    helpertext={mensRut}
                    type="text"
                    value={rut}
                    required
                  />
                  <p
                    id="identID"
                    style={{ fontSize: 12, color: "red", visibility: mensRut == "" ? "hidden" : "visible" }}
                  >
                    {mensRut}
                  </p>
                </div>
              </Grid>
              <br></br>

              <Grid item xs={12}>
                <div style={{ marginTop: '25px' }} className="section title_section content">
                  <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel id="demo-simple-select-outlined-label" className="InputText">Pais</InputLabel>
                    <Select

                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={pais}
                      error={errorPais}
                      onBlur={(e) => {

                        if (pais || pais != '' || pais == undefined) {

                          setErrorPais(false);
                          setMensPais("");
                        } else {

                          setErrorPais(true);
                          setMensPais("Este campo es obligatorio");
                        }
                      }}
                      onChange={(e) => setPais(e.target.value)}
                      helpertext={mensPais}
                      errormessage={mensPais.toString()}
                      label="Pais"
                    >
                      <MenuItem value="" >
                        <em>Seleccionar</em>
                      </MenuItem>
                      {paisesApi.map(paisesValues =>
                        <MenuItem value={paisesValues.id_pais} key={paisesValues.id_pais}>{paisesValues.nombre}</MenuItem>
                      )}
                    </Select>
                    <p
                      id="paisID"
                      style={{ fontSize: 12, color: "red", visibility: mensPais == "" ? "hidden" : "visible" }}
                    >
                      Este campo es obligatorio
                    </p>
                  </FormControl>
                </div>
              </Grid>

              <Grid item xs={12}>
                <div style={{ marginTop: '0px' }} className="section title_section content">
                  <TextField id="nombreinstitucion" label="Nombre de la institución"
                    InputProps={{
                      className: "InputText"
                    }}
                    InputLabelProps={{
                      className: "InputText",
                    }}
                    maxRows={12}
                    minRows={7} variant="outlined" fullWidth
                    error={errorNombreInstitucion}
                    errormessage={errorNombreInstitucion.toString()}
                    /*
                    onBlur={(e) => {
                      if (nombreinstitucion || nombreinstitucion != '' || nombreinstitucion == undefined) {
                        setErrorNombreInstitucion(false);
                        setMensNombreInstitucion("");
                      } else {
                        setErrorNombreInstitucion(true);
                        setMensNombreInstitucion("Este campo es obligatorio");
                      }
                    }}
                    */
                    onChange={(e) => {
                      setNombreInstitucion(e.target.value)
                      if (nombreinstitucion.length > 250) {
                        setErrorNombreInstitucion(true);
                        setMensNombreInstitucion("El nombre no debe pasar de 250 caracteres");
                      } else {
                        setErrorNombreInstitucion(false);
                        setMensNombreInstitucion("");
                      }
                    }}
                    helpertext={mensNombreinstitucion}
                    type="text"
                    value={nombreinstitucion}
                    required
                  />
                  <p
                    id="nombresID"
                    style={{ fontSize: 12, color: "red", visibility: mensNombreinstitucion == "" ? "hidden" : "visible" }}
                  >
                    {mensNombreinstitucion}
                  </p>
                </div>
              </Grid>
              <br></br>


              <Grid item xs={12}>
                <div style={{ marginTop: '25px' }} className="section title_section content">
                  <TextField id="cargo" label="Cargo"
                    InputProps={{
                      className: "InputText"
                    }}
                    InputLabelProps={{
                      className: "InputText",
                    }}
                    maxRows={12}
                    minRows={7} variant="outlined" fullWidth
                    error={errorCargo}
                    errormessage={errorCargo.toString()}
                    /*
                    onBlur={(e) => {
                      if (cargo || cargo != '' || cargo == undefined) {
                        setErrorCargo(false);
                        setMensCargo("");
                      } else {
                        setErrorCargo(true);
                        setMensCargo("Este campo es obligatorio");
                      }
                    }}
                    */
                    onChange={(e) => {
                      setCargo(e.target.value)
                      if (cargo.length > 250) {
                        setErrorCargo(true);
                        setMensCargo("El nombre no debe pasar de 250 caracteres");
                      } else {
                        setErrorCargo(false);
                        setMensCargo("");
                      }
                    }}
                    helpertext={mensCargo}
                    type="text"
                    value={cargo}
                    required
                  />
                  <p
                    id="cargoID"
                    style={{ fontSize: 12, color: "red", visibility: mensCargo == "" ? "hidden" : "visible" }}
                  >
                    {mensCargo}
                  </p>
                </div>
              </Grid>
              <br></br>

              <Grid item xs={12}>
                <div style={{ marginTop: '25px' }} className="section title_section content">
                  <TextField id="email" name="email" label="Correo"
                    InputProps={{
                      className: "InputText"
                    }}
                    InputLabelProps={{
                      className: "InputText",
                    }}
                    maxRows={12}
                    minRows={7} variant="outlined" fullWidth
                    //handleChange={handleChange}
                    error={errorEmail}
                    onBlur={(e) => {

                      if (email) {

                        const hasError = !commonIO.isValidEmail(email);
                        if (hasError == false) {
                          setErrorEmail(false);
                          setMensEmail("");
                        } else {
                          setErrorEmail(true);
                          setMensEmail("Por favor introduzca un correo válido");
                        }
                        setEmail(e.target.value);
                      } else {
                        setErrorEmail(true);
                        setMensEmail("Este campo es obligatorio");
                      }
                    }}
                    //onBlur={(e) => handleBlurEmail(e.target.value)}
                    onChange={(e) => setEmail(e.target.value)}
                    helpertext={mensEmail}
                    type="mail"
                    value={email}
                    required
                  />
                  <p
                    id="correoID"
                    style={{ fontSize: 12, color: "red", visibility: mensEmail == "" ? "hidden" : "visible" }}
                  >
                    {mensEmail}
                  </p>
                </div>
              </Grid>
              <br></br>

              <Grid item xs={5} sm={3} md={2} lg={2} xl={2}>
                <div style={{ marginTop: '25px' }} className="section title_section content">
                  <TextField id="cod_pais" name="cod_pais" label="Código país"
                    InputProps={{
                      startAdornment: <InputAdornment position="start">
                        +
                      </InputAdornment>,
                    }}
                    InputLabelProps={{
                      className: "InputText",
                    }}
                    maxRows={12}
                    minRows={7} variant="outlined" fullWidth
                    placeholder="Ej.: 56"
                    //handleChange={handleChange}
                    error={errorCodPais}
                    onChange={(e) => {
                      setCodPais(e.target.value);
                      if (e.target.value.length > 4) {
                        setErrorCodPais(true);
                        setMensCodPais("No puede ingresar más de 4 dígitos");
                      }
                      else {
                        setErrorCodPais(false);
                        setMensCodPais("");
                      }
                    }}
                    /*
                    onBlur={(e) => {

                      if (codPais || codPais != '' || codPais == undefined) {

                        if (e.target.value.length > 4) {
                          setErrorCodPais(true);
                          setMensCodPais("No puede ingresar más de 4 dígitos");
                        }
                        else {
                          setErrorCodPais(false);
                          setMensCodPais("");
                        }
                      } else {
                        setErrorCodPais(true);
                        setMensCodPais("Este campo es obligatorio");
                      }
                    }}
                    */
                    helpertext={mensCodPais}
                    type="number"
                    value={codPais}
                    required
                  />
                  <p
                    id="correoID"
                    style={{ fontSize: 12, color: "red", visibility: mensCodPais == "" ? "hidden" : "visible" }}
                  >
                    {mensCodPais}
                  </p>
                </div>
              </Grid>

              <Grid item xs={7} sm={9} md={10} lg={10} xl={10}>
                <div style={{ marginTop: '25px' }} className="section title_section content">
                  <TextField id="telefono" name="telefono" label="Teléfono"
                    InputProps={{
                      className: "InputText",
                    }}
                    InputLabelProps={{
                      className: "InputText",
                    }}
                    maxRows={12}
                    minRows={7} variant="outlined" fullWidth
                    error={errorTelefono}
                    onChange={(e) => {
                      setTelefono(e.target.value);
                      if (e.target.value.length > 10) {
                        setErrorTelefono(true);
                        setMensTelefono("No puede ingresar más de 10 dígitos");
                      }
                      else {
                        setErrorTelefono(false);
                        setMensTelefono("");
                      }
                    }}

                    /*
                    onBlur={(e) => {

                      if (telefono || telefono != '' || telefono == undefined) {

                        if (e.target.value.length > 10) {
                          setErrorTelefono(true);
                          setMensTelefono("No puede ingresar más de 10 dígitos");
                        }
                        else {
                          setErrorTelefono(false);
                          setMensTelefono("");
                        }
                      } else {
                        setErrorTelefono(true);
                        setMensTelefono("Este campo es obligatorio");
                      }
                    }}
                    */
                    helpertext={mensTelefono}
                    type="tel"
                    value={telefono}
                    required
                  />
                  <p
                    id="correoID"
                    style={{ fontSize: 12, color: "red", visibility: mensTelefono == "" ? "hidden" : "visible" }}
                  >
                    {mensTelefono}
                  </p>
                </div>
              </Grid>

              <Grid item xs={12}>
                <div style={{ marginTop: '25px' }} className="section title_section content">
                  <TextField id="comentario" label="Comentario" className="InputText"
                    InputProps={{
                      className: "InputText"
                    }}
                    InputLabelProps={{
                      className: "InputText",
                    }}
                    maxRows="10"
                    minRows="6" variant="outlined" fullWidth
                    multiline={true}

                    error={errorComentario}
                    errormessage={errorComentario.toString()}

                    onBlur={(e) => {
                      if (comentario || comentario != '' || comentario == undefined) {
                        setErrorComentario(false);
                        setMensComentario("");
                      } else {
                        setErrorComentario(true);
                        setMensComentario("Este campo es obligatorio");
                      }
                    }}

                    //onChange={(e) => {
                    //  setComentario(e.target.value)
                    //}}
                    onChange={(e) => {
                      setComentario(e.target.value)
                      /*
                      if (comentario.length > 400) {
                        setErrorComentario(true);
                        setMensComentario("El comentario no debe pasar de 400 caracteres");
                      } else {
                        setErrorComentario(false);
                        setMensComentario("");
                      }
                      */
                    }}
                    helpertext={mensComentario}
                    type="text"
                    value={comentario}
                    required
                  />
                  <p
                    id="comentario_p"
                    style={{ fontSize: 12, color: "red", visibility: (mensComentario == "") ? "hidden" : "visible" }}
                  >
                    {mensComentario}
                  </p>
                </div>
              </Grid>
              <br></br>

              <Grid item xs={12}>
                <div className="section content module-content-button-bar">

                  <Button style={{ float: 'right', marginLeft: '15px' }} type="button" onKeyPress={handleClickCursoSection} onClick={handleClickCursoSection} variant="contained" color="primary" className="button_cancel" >
                    Cancelar
                  </Button>

                  <Button style={{ float: 'right', marginLeft: '15px' }} type="button" id="Guardar" disabled={disabledAddContact} onKeyPress={handleSubmit} onClick={handleSubmit} variant="contained" color="primary" className="form__custom-button" >
                    Enviar
                  </Button>

                </div>
              </Grid>


            </Grid>
          </form>
        </div></div>

    </div>
  );
}

export default Contact;
