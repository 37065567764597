import { useContext } from "react";
import { useSnackbar } from "notistack";
import { BotonContext } from "../BotonContext";
import { TIPO_PRODUCTO } from "../lib/constants";

export const useCart = function () {

    const { enqueueSnackbar } = useSnackbar();
    const { cart, setCart } = useContext(BotonContext);

    /**
     * @param {api.Curso} curso
     * @param {boolean} showNotification
     */
    const addCourse = function (curso, showNotification = true) {
        const newCart = (cart !== null) ? cart.slice(0,) : [];
        if (cart !== null && cart.filter(course => course.id === curso.id).length > 0 && cart.filter(course => course.tipo === 1).length > 0) {
            // item ya existente (aumentar cantidad)
            newCart.map((course, i) => {
                if (course.id === curso.id) {
                    newCart[i].quantity += 0; //1
                }
            });
        } else {
            // item nuevo
            newCart.push({
                id: curso.id,
                name: curso.titulo,
                price: curso.precio,
                img: curso.imagen,
                discount: curso.precio,
                quantity: 1,
                tipo: TIPO_PRODUCTO.CURSO,
                ruta: 'course',       // para redirigir por la imagen del curso
                param: 'Inicio'       // para redirigir por el titulo del curso
            });
            showNotification && enqueueSnackbar('Item agregado al carro de compras', {
                variant: 'success'
            });
        }
        setCart(newCart);
        localStorage.setItem('cart', JSON.stringify(newCart));
    };

    /**
     * @param {api.Plan} plan
     * @param {boolean} showNotification
     */
    const addPlan = function (plan, showNotification = true) {
        const newCart = (cart !== null) ? cart.slice(0,) : [];
        newCart.push({
            id: plan.id,
            name: plan.nombre,
            price: plan.precio,
            img: plan.imagen,
            discount: plan.precio,
            quantity: 1,
            tipo: TIPO_PRODUCTO.PLAN
        });
        setCart(newCart);
        localStorage.setItem('cart', JSON.stringify(newCart));
        showNotification && enqueueSnackbar('Item agregado al carro de compras', {
            variant: 'success'
        });
    };

    /**
     * @param {api.Curso | api.Plan} item
     * @returns {boolean}
     */
    const hasItem = function (item) {
        if (!item || !cart || !(cart instanceof Array) || !cart.length) return false;
        let found = false;
        for (let i = 0, l = cart.length; i < l; i++) {
            if (cart[i].id === item.id) {
                found = true;
                break;
            }
        }
        return found;
    };

    /**
     * @returns {boolean}
     */
    const hasItemPlan = function () {
        if (!cart || !(cart instanceof Array) || !cart.length) return false;
        let found = false;
        for (let i = 0, l = cart.length; i < l; i++) {
            if (cart[i].tipo === TIPO_PRODUCTO.PLAN) {
                found = true;
                break;
            }
        }
        return found;
    };

    /**
     * @returns {number}
     */
    const getItemCount = function () {
        let quantity = 0;
        if (cart !== null && cart.length > 0) {
            cart.map(course => quantity += course.quantity);
        }
        return quantity;
    };

    return {
        addCourse,
        hasItem,
        addPlan,
        getItemCount,
        hasItemPlan
    };

};
