import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import { deepOrange, deepPurple, blue, cyan, green, amber, red, pink, yellow, blueGrey, teal, grey, orange } from '@material-ui/core/colors';
import { Hidden } from "@material-ui/core";


const useStyles = makeStyles((theme) => ({
  mainFeaturedPost: {
    position: 'relative',
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.white,
    //marginBottom: theme.spacing(4),
    //backgroundImage: 'url(https://source.unsplash.com/random)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top',
    [theme.breakpoints.up('xs')]: {
      padding: theme.spacing(0),
      paddingRight: 0,
    },
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(8),
      paddingRight: 0,
    },
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(2),
      paddingRight: 0,
    },
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(12),
      paddingRight: 0,
    },
    [theme.breakpoints.up('xl')]: {
      //padding: theme.spacing(28),
      padding: '224px 0px 224px 0px',
      paddingRight: 0,
    },

  },
  overlay: {
    //position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    backgroundColor: 'rgba(0,0,0,.3)'
  },
  mainFeaturedPostContent: {
    position: 'relative',
    padding: theme.spacing(3),
    [theme.breakpoints.up('xs')]: {
      padding: theme.spacing(0),
      paddingRight: 0,
    },
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(8),
      paddingRight: 0,
    },
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(6),
      paddingRight: 0,
    },
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(4),
      paddingRight: 0,
    },
    [theme.breakpoints.up('xl')]: {
      padding: theme.spacing(12),
      paddingRight: 0,
    }
  },
  increasedLineSpacing: {
    lineHeight: '1.3em !important'
  }
}));

/**
 * @param {Object} obj
 * @param {enapsis.Post} obj.post
 */
export default function MainFeaturedPost({ post }) {
  const classes = useStyles();

  return (
    <>
    <Hidden xsDown smDown  >
    <Grid className={classes.mainFeaturedPost} style={{ backgroundImage: `url(${post.image})` }}>
      {/* Increase the priority of the hero background image */}
      {<img style={{ display: 'none' }} src={post.image} />}
      <div className={classes.overlay} />
      <Grid container >

        <Grid item md={5} >
          <div className={classes.mainFeaturedPostContent}>
            <Typography component="h1" variant="h4" color="inherit" gutterBottom align='center' style={{ color: '#d87218' }}>
              {post.title}
            </Typography>
            <Typography variant="h5" color="inherit" paragraph align='center' className={ classes.increasedLineSpacing } style={{ color: grey[600] }}>
              {post.description}
            </Typography>
            {/*
            <Link variant="subtitle1" href="#">
              {post.linkText}
            </Link>
            */}
          </div>
        </Grid>

      </Grid>
    </Grid>
    </Hidden>

    <Hidden mdUp>
        <Grid item xs={12} sm={12}>
          <div >
            <br></br><br></br>
            <Typography component="h1" variant="h5" color="inherit" gutterBottom align='center' style={{padding: '10px', marginTop: '0px', color: '#d87218'}}>
              {post.title}
            </Typography>
            <Typography variant="h5" color="inherit" paragraph align='center' style={{color: grey[600], padding: '5px',}}>
              {post.description}
            </Typography>
            {/*
            <Link variant="subtitle1" href="#">
              {post.linkText}
            </Link>
            */}
          </div>
        </Grid>
    </Hidden>
    </>
  );
}

MainFeaturedPost.propTypes = {
  post: PropTypes.object,
};
