import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import { List, Divider } from "@material-ui/core";

import logoEnapsisColor from '../ui/logo-enapsis-color.png';

import AuthContext from "../AuthContext";
import { theme as tema } from "../util/theme";
import env from "../util/env";
import routeHelper from "../util/routeHelper";
import { FLAGS } from "../lib/constants";

const hideDiplomados = env.flags.HIDE_DIPLOMADOS || FLAGS.DISABLE_PROGRAMS;

export default function Listas () {

    const { isAuth, isAuthTypeTutor } = useContext(AuthContext);

    const nav = [
        { label: "Inicio", slug: "/home", icon: "icon-home" },
        // {label: "Descubrir", slug: "discover", icon: "icon-ul"},
        { label: "Cursos", slug: "/courses", icon: "icon-tag" },
        { label: "Programas", slug: "/certified", icon: "icon-certificate", disabled: hideDiplomados },
        { label: "Planes multiusuario", slug: routeHelper.multiusuario, icon: "icon-group" },
        { label: "Nosotros", slug: routeHelper.nosotros, icon: "icon-user-bubble" },
        { label: "Enseña con nosotros", slug: "/tutor-registration", icon: "icon-graduation", disabled: isAuthTypeTutor || !isAuth },
        //{ label: "Mis Cursos", slug: "/my-courses", icon: "icon-briefcase" },
        //{label: "Mis Compras", slug: "/my-shopping", icon: "icon-bag-full"},
        //{ label: "Mis Cursos (Tutor)", slug: "/my-courses-tutor", icon: "icon-briefcase" }
        //{label: "Salir", slug: "/my-shopping", icon: "icon-exit"} //icon-contacts  icon-user icon-user-info
    ];

    const navigation = [];
    if(tema.isLight) {
        for (let i = 0; i < nav.length; i++) {
            const item = nav[i];
            if (item.disabled) continue;
            navigation.push(
                <li key={"nav-" + i + "-" + item.slug}>
                    <br></br>
                    <NavLink to={item.slug} className={"aic link noul flex c033"}>
                        <div className={"ico s20 c033 " + item.icon} />
                        <h2 className="lbl s20 c033">{item.label}</h2>
                    </NavLink>
                    <br></br>
                    <Divider />
                </li>
            );
        }
    } else {
        for (let i = 0; i < nav.length; i++) {
            navigation.push(
                <li key={"nav-" + i + "-" + nav[i].slug}>
                    <br></br>
                    <NavLink to={nav[i].slug} className={"aic link noul flex cfff"}>
                        <div className={"ico s20 cfff " + nav[i].icon} />
                        <h2 className="lbl s20 cfff">{nav[i].label}</h2>
                    </NavLink>
                    <br></br>
                    <Divider />
                </li>
            );
        }
    }

    /*
    if (token && token != "" && token != undefined && data_user && data_user != "" && data_user != undefined && data_user.tipo_usuario == 1) {
        for (let i = 0; i < 4; i++) {
            navigation.push(
                <li key={"nav-" + i + "-" + nav[i].slug}>
                    <br></br>
                    <NavLink to={nav[i].slug} className={"aic link noul flex c333"}>
                        <div className={"ico s20 " + nav[i].icon} />
                        <h2 className="lbl s20 c033">{nav[i].label}</h2>
                    </NavLink>
                    <br></br>
                    <Divider />
                </li>
            );
        }
    }
    if (token && token != "" && token != undefined && data_user && data_user != "" && data_user != undefined && data_user.tipo_usuario == 2) {
        for (let i = 0; i < nav.length; i++) {
            navigation.push(
                <li key={"nav-" + i + "-" + nav[i].slug}>
                    <br></br>
                    <NavLink to={nav[i].slug} className={"aic link noul flex c333"}>
                        <div className={"ico s20 " + nav[i].icon} />
                        <h2 className="lbl s20 c033">{nav[i].label}</h2>
                    </NavLink>
                    <br></br>
                    <Divider />
                </li>
            );
        }
    }
    if (token === null) {
        for (let i = 0; i < 3; i++) {
            navigation.push(
                <li key={"nav-" + i + "-" + nav[i].slug}>
                    <br></br>
                    <NavLink to={nav[i].slug} className={"aic link noul flex c333"}>
                        <div className={"ico s20 " + nav[i].icon} />
                        <h2 className="lbl s20 c033">{nav[i].label}</h2>
                    </NavLink>
                    <br></br>
                    <Divider />
                </li>
            );
        }
    }
    */

    return (
        <div className="sidebar rel MuiPaper-root_sider_responsive">
            <a href="#/home" className="logo bl" >
                <img src={logoEnapsisColor} className="logo bl" alt="e-napsis"/>
            </a>

            <Divider />
            <List component='nav' className="MuiPaper-root_sider_responsive">

                <ul >
                    {navigation}
                </ul>
                <br></br>
            </List>
        </div>
    );

}
