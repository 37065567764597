import React, { useEffect, useState, useContext } from "react";
import { green, yellow } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import Chip from '@material-ui/core/Chip';
import StarIcon from '@material-ui/icons/Star';
import LinearProgress from '@material-ui/core/LinearProgress';
import Box from '@material-ui/core/Box';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import { Hidden } from "@material-ui/core";
import DialogContent from '@material-ui/core/DialogContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import Container from '@material-ui/core/Container';

import AuthContext from "../AuthContext";
import TarjetaEcampus from "../componentes/TarjetaEcampus";

import utils from "../util/utils";
import { isStructureUpdated } from "../lib/api";
import api from "../lib/api";
import { TagStyles } from "../lib/constants";
import { BotonContext } from "../BotonContext";
import { useSession } from "../util/session";

const useStyles3 = makeStyles({
    root: {
        display: 'flex'
    }
});

const useStyles4 = makeStyles({
    root: {
        height: 6,
        borderRadius: 0
    }
});

export default function MyCoursesPage() {

    const { data_user, token } = useContext(BotonContext);

    const fetchMisCursos = function () {
        fetch(process.env.REACT_APP_URL_API + '/alumno/' + userID + '/cursos', {
            method: 'get',
            headers: {
                'token': token,
                'Content-Type': 'application/json'
            }
        })
            .then(response => {
                if (response.status === 401 || response.status === 403) {
                    session.handleNotAuthorized();
                    return;
                }
                response.json()
                    .then(data => {
                        if (data.success === true) {
                            if (data.data.n_cursos > 0) {
                                setMyCourses(data.data.cursos);
                            } else {
                                setMens(true);
                            }
                        }
                        if (data.success === false && data.data === null) {
                            ValidarAuth();
                        }
                        setDisableLoading(true);
                    });
            });
    };

    useEffect(() => {
        document.title = "Mis Cursos";
    });

    useEffect(() => {
        api.pagos.validarPagos({
            token: token,
            userId: userID
        })
            .catch((err) => {
                (err?.code == 401 || err?.code == 403) && session.handleNotAuthorized();
            })
            .finally(() => {
                fetchMisCursos();
            });
    }, []);

    const [myCourses, setMyCourses] = useState([]);
    const classes3 = useStyles3();
    const classes4 = useStyles4();
    const [mens, setMens] = useState(false);
    const { isAuth } = useContext(AuthContext);
    const session = useSession();

    const [disableLoading, setDisableLoading] = useState(false);

    const ValidarAuth = () => {
        if (!isAuth) {
            session.handleNotAuthorized();
        }
    };
    ValidarAuth();
    const userID = data_user?.id_usuario;  // La fecha de inscripcion del curso no puede ser: 0000-00-00 00:00:00

    function limitarcaracteres(value) {
        if (value.length > 72) {
            value = value.slice(0, 72) + '...';
            return value;
        } else {
            return value;
        }
    }

    function limitarcaracteres28(value) {
        if (value.length > 28) {
            value = value.slice(0, 28) + '...';
            return value;
        } else {
            return value;
        }
    }

    const myCourseList = [];
    const nCursosAlumno = myCourses?.length ? myCourses.length : 0;
    for (let i = 0; i < nCursosAlumno; i++) {

        myCourseList.push(

            <Grid container item xs={12} sm={12} md={6} lg={4} xl={3} spacing={0} direction="column" alignItems="center" justifyContent="center" key={myCourses[i].id}>

                <Hidden smUp>
                    <div className="course-small rel">

                        <Container maxWidth="sm">

                            <Grid container spacing={3} alignContent="center" alignItems="center">

                                <TarjetaEcampus
                                    image={utils.getImagenCurso(myCourses[i])}
                                    text1={limitarcaracteres28(myCourses[i].titulo)}
                                    text2={myCourses[i].nombre_instructor}
                                    href={"/owncourse/" + myCourses[i].id + "/" + "Mis cursos" + "/" + 0}
                                    price={myCourses[i].precio}
                                    small={true}
                                    showTag={isStructureUpdated(myCourses[i])}
                                    tagOptions={TagStyles.TAG_ACTUALIZADO}
                                />

                            </Grid>
                            {/* Porcentaje de progreso del curso*/}
                            {/*
                            <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '-25px' }} >
                                <Box sx={{ width: '90%', mr: 1 }} >
                                    <LinearProgress variant="determinate" value={myCourses[i].porcentaje_avance} className={classes4.root}/>
                                </Box>
                                <Box sx={{ minWidth: 25, zIndex: 99, position: 'relative'}}>
                                    <Typography variant="body2" color="secondary">{`${Math.round(
                                    myCourses[i].porcentaje_avance,
                                    )}%`}</Typography>
                                </Box>
                            </Box>
                            */}
                        </Container>

                        {/*
                    <Chip label="Suscrito" fontSize="large" style={{ float: 'right', marginTop: '-335px', marginRight: '3px'}} avatar={<StarIcon  fontSize="large" style={{ color: 'darkorange', float: 'right', marginTop: '0px'}}/> }></Chip>
                    */}
                        {/*
                    <Chip size="small" label="Suscrito" fontSize="small" style={{ float: 'right', marginTop: '-330px', marginRight: '3px', color: 'white', backgroundColor: green[600], zIndex: 99}}></Chip>  &nbsp;
                    */}

                    </div>
                </Hidden>

                <Hidden xsDown>
                    <div className="course rel" >

                        <Container maxWidth="md">

                            <Grid container spacing={3} alignContent="center" alignItems="center">

                                <TarjetaEcampus
                                    image={utils.getImagenCurso(myCourses[i])}
                                    text1={limitarcaracteres(myCourses[i].titulo)}
                                    text2={myCourses[i].nombre_instructor}
                                    href={"/owncourse/" + myCourses[i].id + "/" + "Mis cursos" + "/" + 0}
                                    price={myCourses[i].precio}
                                    showTag={isStructureUpdated(myCourses[i])}
                                    tagOptions={TagStyles.TAG_ACTUALIZADO}
                                />

                            </Grid>
                            {/* Porcentaje de progreso del curso*/}
                            {/*
                            <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '-25px' }} >
                                <Box sx={{ width: '80%', mr: 1 }} >
                                    <LinearProgress variant="determinate" value={myCourses[i].porcentaje_avance} className={classes4.root}/>
                                </Box>
                                <Box sx={{ minWidth: 55, zIndex: 99, position: 'relative'}}>
                                    <Typography variant="body2" color="secondary">{`${Math.round(
                                    myCourses[i].porcentaje_avance,
                                    )}%`}</Typography>
                                </Box>
                            </Box>
                            */}
                        </Container>

                        {/*
                    <Chip label="Suscrito" fontSize="large" style={{ float: 'right', marginTop: '-335px', marginRight: '3px'}} avatar={<StarIcon  fontSize="large" style={{ color: 'darkorange', float: 'right', marginTop: '0px'}}/> }></Chip>

                    <Chip size="medium" label="70%" fontSize="small" style={{ float: 'right', marginTop: '-42px', marginRight: '38px', color: 'white', backgroundColor: yellow[900], zIndex: 99, position: 'relative', borderRadius: '0px', border: '1px solid white'}}></Chip>  &nbsp;
                    */}
                        {/*marginTop: '-338px',*/}
                    </div>
                </Hidden>

            </Grid>

        );
    }

    return (
        <div className="App rel">
            <Breadcrumbs aria-label="breadcrumb" className="breadcrumbs_link_inneret">
                <Typography color="textPrimary" className="breadcrumbs_link">
                    Mis cursos
                </Typography>
            </Breadcrumbs>

            <div className="tituloInicial rel">
                <h1 className="title_section s24 fontb c333">Mis cursos</h1>
            </div>
            <div className="section section-b rel">
                {disableLoading != true &&
                    <div id="cargando" className={classes3.root}>
                        <DialogContent className="CentrarLoadingSinModal">
                            <CircularProgress className="CircularProgress" style={{ alignSelf: 'center' }} />
                        </DialogContent>
                    </div>
                }
                <div className="courses rel flex">

                    {myCourseList &&
                        <Grid container spacing={1} item xs={12} xl={12} direction="row" >
                            {myCourseList}
                        </Grid>
                    }

                    {mens === true &&
                        <Grid container spacing={3}>
                            <Grid item xs={2} sm={1} md={2} lg={3} xl={3} ></Grid>
                            <Grid item xs={8} sm={10} md={8} lg={6} xl={6}>
                                <div style={{ height: '100px' }}></div>
                                <div style={{ display: 'flex', justifyContent: 'center' }} className="title_section s30 fontb c333">¡ Aún no estas inscrito en un curso !</div>
                                <div style={{ height: '100px' }}></div>
                            </Grid>
                            <Grid item xs={2} sm={1} md={2} lg={3} xl={3}  ></Grid>
                        </Grid>
                    }

                </div>
            </div>

        </div>

    );
}
