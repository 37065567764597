import { makeStyles } from '@material-ui/core/styles';

export const useCommonStyles = makeStyles((theme) => ({
    alternateDisabled: {
        "&.Mui-disabled": {
            backgroundColor: 'var(--color-background-form-butons)',
            color: 'var(--color-text-form-butons)',
            opacity: '0.7'
        }
    }
}));
