import React, { useMemo, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ButtonCircularProgress from './ButtonCircularProgress';
import utils from '../util/utils';

/**
 * @param {Object} params
 * @param {(coupon: string) => void} params.onCouponApply
 * @param {() => void} params.onCouponClear
 * @param {boolean} params.showSpinner
 * @param {boolean} params.applied
 * @returns {JSX.Element}
 */
export default function CouponCampo({ onCouponApply, onCouponClear, showSpinner, applied }) {

    const [coupon, setCoupon] = useState('');
    const [delayedSpinner, setDelayedSpinner] = useState(false);
    const unreactive = useMemo(() => {
        return {
            /**
             * @type {import("../util/utils").assureDelayBeforeTrigger}
             * @todo crear custom hook para simplificar uso de `assureDelayBeforeTrigger` bajo React.
             */
            delayHelper: undefined
        };
    }, []);

    const aplicarCoupon = function () {
        onCouponApply(coupon);
    };

    const clearCoupon = function () {
        setCoupon('');
        onCouponClear();
    };

    if (showSpinner && !delayedSpinner) {
        setDelayedSpinner(true);
        if (!unreactive.delayHelper) {
            unreactive.delayHelper = new utils.assureDelayBeforeTrigger({
                onTrigger() {
                    setDelayedSpinner(false);
                },
                minVisibleTime: 1000
            });
        } else {
            unreactive.delayHelper.resetTime();
        }
    }
    if (!showSpinner && delayedSpinner) {
        unreactive.delayHelper.trigger(undefined, {
            deDuplicateTrigger: true
        });
    }

    return <>
        <TextField className="InputText" label="Cupón" variant="outlined" style={{}}
            size='small'
            InputProps={{
                className: "InputText"
            }}
            InputLabelProps={{
                className: "InputText"
            }}
            onChange={(e) => {
                const text = e.target.value;
                setCoupon(text);
            }}
            type="text"
            value={coupon}
            disabled={delayedSpinner || applied}
        />
        {!applied
            ?
            <Button onClick={aplicarCoupon} variant="contained" className="button_cancel" style={{ marginLeft: '0.8rem' }} disabled={delayedSpinner || !coupon || applied} >
                {delayedSpinner && <ButtonCircularProgress color='gray' />}
                Aplicar descuento
            </Button>
            :
            <>
                <Button variant="contained" color="primary" className="button_cancel" style={{ marginLeft: '0.6rem' }} onClick={clearCoupon} >Limpiar</Button>
                <div style={{ display: 'flex', paddingLeft: '0.6rem', color: '#3dae42' }}>
                    <CheckCircleIcon />
                </div>
            </>
        }
    </>;

}
