// eslint-disable-next-line no-unused-vars
import DataTables from 'datatables.net';
import $ from 'jquery';
import "datatables.net-dt/css/jquery.dataTables.min.css";

/**
 * @typedef CustomDataTableCellData
 * @property {string | number} showData
 * @property {string | number} [sortData]
 * @property {string | number} [exportData]
 * @property {string} [cellTitle]
 * @property {string} [cellExtraStyles] - facilita agregar estilos dinámicamente a celdas específicas. Separar múltiples clases con espacio.
 * @property {boolean} [applyPopover] - default = false
 * @property {Object} [attachData] - asignar a la primera celda. El objeto se adjunta a la fila correspondiente. Colocar en la primera celda, es la única que se verifica para la existencia de `attachData` en `createdRow`.
 * @property {(cell: HTMLElement) => void} [onCreateCell]
 * @todo definir el tipo genérico para poder indicar el tipo de attachData. Tal vez sea necesario usar un archivo .d.ts.
 */

/**
 * @typedef ColumnDefinition
 * @property {string} title
 * @property {string} [classes]
 * @property {string} [style]
 * @property {boolean} [useCustomCellData=false] - default = false
 */

/**
 * @param {HTMLTableElement} node
 * @param {ColumnDefinition[]} columns
 * @param {TypeHelpers.RecursivePartial<defaultConfig>} [options]
 * @todo agregar la data a adjuntar cómo parte de cada fila en `DataTableWrapper.addRows`. Actualmente se adjunta a la primera columna usando `CustomDataTableCellData`. Internamente se podría seguir usando la data de la primera columna como proxy, pero sería transparente para el usuario.
 */
export const DataTableWrapper = function (node, columns, options) {

    const self = this;

    const defaultConfig = {
        language: $.extend({}, dataTableHelper.lenguajeES, {
            "emptyTable": "No se encontraron datos"
        }),
        hoverStyleEnabled: {
            /** default = true */
            enabled: true,
            /** default = true */
            useAlternativeStyle: true // left-bar-hover
        },
        exportButtons: {
            csv: false,
            excel: false,
            /** @type {Function} */
            actionAll: undefined
        },
        /** @todo usar plugin */
        columnFilters: {
            enabled: false,
            /**
             * Si no se especifica un arreglo de números o el arreglo está vacío, se activa en todas las columnas.
             * @type {number[]}
             */
            columns: []
        },
        /** @type {DataTables.Settings} */
        dataTableOptions: {}
    };

    /** @type {defaultConfig} */
    const config = $.extend(true, {}, defaultConfig, options);

    /**
     * @param {ColumnDefinition} column
     */
    const headerColumnTPL = ({ title, classes, style }) => {
        var html = '';
        html += `<th ${classes ? `class="${classes}"` : ''} ${style ? `style="${style}"` : ''}>${title}</th>`;
        return html;
    };

    const localState = {
        /** @type {DataTables.Api} */
        dataTable: undefined,
        /** @type {JQuery<HTMLTableRowElement> | HTMLTableRowElement | Node} */
        lastFlaggedRow: undefined
    };

    /** @type {number} */
    const colspan = columns.length;

    const initDataTable = function () {

        const headerNode = $(node).find('thead > tr');
        for (let i = 0, l = columns.length; i < l; i++) {
            const headerColumnHTML = headerColumnTPL(columns[i]);
            headerNode.append($(headerColumnHTML));
        }

        if (config.hoverStyleEnabled.enabled) {
            $(node).addClass(config.hoverStyleEnabled.useAlternativeStyle ? 'left-bar-hover' : 'table-hover');
        }

        let dom = "B<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6'f>>" +
            "<'row'<'col-sm-12'tr>>" +
            "<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>";

        let buttonCommon = {
            title: 'reporte',
            exportOptions: {
                columns: [...Array(columns.length).keys()],
                orthogonal: 'export'
            },
            action() {
                if (typeof config.exportButtons.actionAll === 'function') {
                    config.exportButtons.actionAll();
                }
            },
            className: 'btn btn-outline-primary mr-1 no-animation disabled' // clases adicionales a las predeterminadas
        };
        let buttons = [];
        if (config.exportButtons.excel) {
            buttons.push($.extend(true, {}, buttonCommon, {
                extend: 'excel',
                titleAttr: 'Descargar Excel'
            }));

        }
        if (config.exportButtons.csv) {
            buttons.push($.extend(true, {}, buttonCommon, {
                extend: 'csv',
                titleAttr: 'Descargar CSV'
            }));
        }

        /** @type {DataTables.Settings} */
        const defaultDataTableOptions = {
            language: config.language,
            dom: dom,
            order: [], // no ordenar inicialmente; mostrar en el orden recibido
            ordering: true,
            orderCellsTop: true,
            autoWidth: false,
            // @ts-ignore
            buttons: {
                buttons: buttons
            }
        };

        const dataTableOptions = $.extend(true, {}, defaultDataTableOptions, options?.dataTableOptions);

        const columnsWithCustomDataTableCellData = [];
        for (let i = 0, l = columns.length; i < l; i++) {
            if (columns[i].useCustomCellData) {
                columnsWithCustomDataTableCellData.push(i);
            }
        }
        if (columnsWithCustomDataTableCellData.length) {
            /**
             * No aplicará el render proporcionado por el usuario para la primera columna.
             * @todo buscar alternativa que no sobre-escriba el método render de ninguna columna.
             */
            dataTableOptions.columnDefs.push({
                /** @param {CustomDataTableCellData} data */
                render(data, type/*, row*/) {
                    if (type === 'sort' || type === 'type') {
                        return typeof data.sortData !== 'undefined' ? data.sortData : data.showData;
                    } if (type === 'export' && typeof data.exportData !== 'undefined') {
                        return data.exportData;
                    } else {
                        return data.showData;
                    }
                },
                targets: columnsWithCustomDataTableCellData
            });
        }

        /**
         * Se sobre-escribe cualquier "createdRow" proporcionado al wrapper
         * @todo implementar mediante hook transparente al usuario del wrapper
         */
        dataTableOptions.createdRow = function (row, data) {
            if (!(data instanceof Array) || !data.length) return;
            // verificar si se asignó "attachData" a la primera celda
            if (typeof data[0] === "object" && data[0].attachData) {
                $(row).prop('attachData', data[0].attachData);
            }
            // asignar "cellTitle" (si existe) a las celdas correspondientes y aplicar .popover en caso de que se haya activado la opción.
            // asignar "cellExtraStyles"
            for (let i = 0, l = data.length; i < l; i++) {
                if (typeof data[i] === 'object' && Object.prototype.hasOwnProperty.call(data[i], 'showData')) {
                    const customData = /** @type {CustomDataTableCellData} */(data[i]);
                    customData.cellTitle && $(row["cells"][i]).attr('title', customData.cellTitle);
                    if (customData.applyPopover) {
                        $(row["cells"][i]).css('position', 'relative');
                        $(row["cells"][i]).find('[role="popover"]').popover({
                            container: row["cells"][i]
                        });
                    }
                    if (customData.cellExtraStyles) {
                        $(row["cells"][i]).addClass(customData.cellExtraStyles);
                    }
                    if (typeof customData.onCreateCell === 'function') {
                        customData.onCreateCell(row["cells"][i]);
                    }
                }
            }
        };

        /**
         * Se sobre-escribe cualquier "initComplete" proporcionado al wrapper
         * @todo implementar mediante hook transparente al usuario del wrapper
         */
        dataTableOptions.initComplete = function () {
            // @ts-ignore
            const dt = /** @type {DataTables.Api} */(this.api());
            const tableContainer = dt.table().container();
            $(tableContainer).find('.buttons-excel').prepend('<i class="fa fa-download mr-1"></i>');
            if (!config.columnFilters.enabled) return;
            const thead = $(node).find('thead');
            $(node).find('tr:first').clone(false).addClass('filters').appendTo(thead);
            dt.columns().eq(0).each(colIdx => {
                // Set the header cell to contain the input element
                const cell = $('.filters th').eq(
                    $(dt.column(colIdx).header()).index()
                );
                cell.removeClass('sorting');
                if (config.columnFilters.columns instanceof Array && config.columnFilters.columns.length && config.columnFilters.columns.indexOf(colIdx) === -1) {
                    cell.html('');
                    return;
                }
                const title = $(cell).text();
                cell.html('<input type="text" placeholder="' + title + '" />');
                // On every keypress in this input
                $(
                    'input',
                    $('.filters th').eq($(dt.column(colIdx).header()).index())
                )
                    .off('keyup change')
                    .on('change', function () {
                        const input = /** @type {HTMLInputElement} */(this);
                        // Get the search value
                        $(this).attr('title', input.value);
                        const regexr = '({search})';
                        // Search the column for that value
                        dt
                            .column(colIdx)
                            .search(
                                input.value !== '' ? regexr.replace('{search}', '(((' + input.value + ')))') : '',
                                input.value !== '',
                                input.value === ''
                            )
                            .draw();
                    })
                    .on('keyup', function (e) {
                        e.stopPropagation();
                        $(this).trigger('change');
                        $(this).focus();
                    });
            });
        };

        return $(node).DataTable(dataTableOptions);
    };

    this.removeCustomLoaderOrMessage = function () {
        const customTBody = $(node).find('tbody[role^="custom-"]');
        customTBody.length && customTBody.remove();
        $(node).find('tbody').first().removeClass('d-none'); // mostrar tbody original
    };

    /**
     * @param {string} message - puede ser HTML
     */
    this.showCustomMessage = function (message) {
        $(node).find('tbody[role="custom-loading-message"]').remove(); // en caso de que exista un custom-loading-message
        const customLoader = $(node).find('tbody[role="custom-text-message"]');
        if (customLoader.length) {
            customLoader.find('[role="message"]').html(message);
        } else {
            const tableBody = $(node).find('tbody').first();
            tableBody.addClass('d-none'); // ocultar tbody original
            let html = '';
            html += '<tbody role="custom-text-message">';
            html +=     '<tr class="odd">';
            html +=         `<td colspan="${colspan}" class="dataTables_empty" valign="top">`; // dejar "dataTables_empty" (y en general la misma estructura) para que apliquen los mismos estilos usados por el mensaje de DataTables
            html +=             `<span role="message" style="text-transform: none;">${message}</span>`;
            html +=         '</td>';
            html +=     '</tr>';
            html += '</tbody>';
            $(html).insertAfter(tableBody);
        }
    };

    /**
     * @param {string} [mensaje]
     */
    this.showCustomLoader = function (mensaje) {
        const customLoader = $(node).find('tbody[role="custom-loading-message"]');
        if (customLoader.length) return; // no volver a crear
        $(node).find('tbody[role="custom-text-message"]').remove(); // en caso de que exista un custom-text-message
        const tableBody = $(node).find('tbody').first();
        tableBody.addClass('d-none'); // ocultar tbody original
        let html = '';
        html += '<tbody role="custom-loading-message">';
        html +=     '<tr class="odd">';
        html +=         `<td colspan="${colspan}" class="dataTables_empty" valign="top">`; // dejar "dataTables_empty" (y en general la misma estructura) para que apliquen los mismos estilos usados por el mensaje de DataTables
        html +=             '<span>';
        html +=                 '<span class="spinner-border spinner-border-sm mr-1" style="opacity: 0.5;"></span>';
        html +=                 `<span>${mensaje || 'Cargando datos'}...</span>`;
        html +=             '</span>';
        html +=         '</td>';
        html +=     '</tr>';
        html += '</tbody>';
        $(html).insertAfter(tableBody);
    };

    this.clear = function () {
        localState.dataTable && localState.dataTable.clear();
    };

    /**
     * @param {Array} rows
     */
    this.addRows = function (rows) {
        localState.dataTable.rows.add(rows);
    };

    /**
     * @param {JQuery<HTMLTableRowElement>[] | HTMLTableRowElement[]} rows
     */
    this.removeRows = function (rows) {
        rows.forEach(row => {
            localState.dataTable.row(row).remove();
        });
    };

    /**
     * @param {JQuery<HTMLTableRowElement> | HTMLTableRowElement} row
     * @param {number} cellIndex
     * @param {*} data
     */
    this.updateCellData = function (row, cellIndex, data) {
        const cell = localState.dataTable.cell(row, cellIndex);
        cell.data(data).draw();
    };

    /**
     * @param {boolean} [resetState=true] - default = true
     */
    this.draw = function (resetState) {
        if (typeof resetState !== 'boolean') {
            resetState = true;
        }
        localState.dataTable.draw(resetState);
    };

    this.clearSearchBox = function () {
        localState.dataTable && localState.dataTable.search('');
    };

    this.clearColumSearch = function () {
        if (localState.dataTable) {
            $(node).find('.filters input').val('').attr('title', '');
            localState.dataTable.columns().search('');
        }
    };

    this.clearAll = function () {
        self.clear();
        self.clearSearchBox();
        self.clearColumSearch();
    };

    this.resetOrder = function () {
        localState.dataTable && localState.dataTable.order([]);
    };

    this.init = function () {
        !localState.dataTable && (localState.dataTable = initDataTable());
    };

    /**
     * @param {JQuery<HTMLTableRowElement> | HTMLTableRowElement | Node} row
     */
    this.flagRow = function (row) {
        if (localState.lastFlaggedRow) {
            $(localState.lastFlaggedRow).removeClass('flagged-row');
        }
        $(row).addClass('flagged-row');
        localState.lastFlaggedRow = row;
    };

    this.unFlagRow = function () {
        if (localState.lastFlaggedRow) {
            $(localState.lastFlaggedRow).removeClass('flagged-row');
            localState.lastFlaggedRow = undefined;
        }
    };

    this.enableExportButtons = function () {
        $('.buttons-excel, .buttons-csv').removeClass('disabled');
    };

    this.disableExportButtons = function () {
        $('.buttons-excel, .buttons-csv').addClass('disabled');
    };

    /**
     * @param {HTMLTableRowElement} row
     * @returns {*}
     * @todo permitir especificar la fila o alguna de sus celdas.
     * @todo definir tipo de dato (mediante template) al crear el Wrapper. Sería el tipo de dato retornado aquí.
     */
    this.getRowData = function (row) {
        return row["attachData"];
    };

    /** @type {DataTables.Api} */
    this.api = undefined;
    Object.defineProperty(this, 'api', {
        /** @returns {DataTables.Api} */
        get() {
            return localState.dataTable;
        }
    });

};

export const dataTableHelper = {
    lenguajeES: {
        "processing":     "Procesando...",
        "lengthMenu":     "Mostrar _MENU_ registros",
        "zeroRecords":    "No se encontraron resultados",
        "emptyTable":     "Ningún dato disponible en esta tabla",
        "info":           "Mostrando del _START_ al _END_ de un total de _TOTAL_ registros",
        "infoEmpty":      "Mostrando del 0 al 0 de un total de 0 registros",
        "infoFiltered":   "(filtrado de un total de _MAX_ registros)",
        "infoPostFix":    "",
        "search":         "Buscar:",
        "url":            "",
        "thousands":  ",",
        "loadingRecords": "Cargando...",
        "paginate": {
            "first":    "Primero",
            "last":     "Último",
            "next":     "Siguiente",
            "previous": "Anterior"
        },
        "oAria": {
            "sSortAscending":  ": Activar para ordenar la columna de manera ascendente",
            "sSortDescending": ": Activar para ordenar la columna de manera descendente"
        }
    }
};

export default {
    DataTableWrapper
};
