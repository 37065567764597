import React, { useEffect, useState, useContext } from "react";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router";
import ReactPlayer from "react-player";
import "react-dropzone-uploader/dist/styles.css";
import Dropzone from "react-dropzone-uploader";
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Grid from '@material-ui/core/Grid';
import Alert from '@material-ui/lab/Alert';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuList from '@material-ui/core/MenuList';
import DeleteForever from "@material-ui/icons/DeleteForever";
import Tooltip from '@material-ui/core/Tooltip';
import { Hidden } from "@material-ui/core";
import GetAppIcon from '@material-ui/icons/GetApp';
import ClearIcon from '@material-ui/icons/Clear';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { useSnackbar } from 'notistack';
import { useIsFirstRender } from "usehooks-ts";
import axios from 'axios';
import _ from 'lodash';
import JSZip from "jszip";

import LoadingPanel from "../componentes/LoadingPanel";
import DialogTitle from '../componentes/DialogTitle';
import AuthContext from "../AuthContext";
import { BotonContext } from "../BotonContext";

import { strings } from "../lib/i18n";
import { clearSession } from "../util/session";
import api, { detectarTipoClase } from "../lib/api";
import { TIPO_CLASE, TIPO_EVALUATION, TIPO_PREGUNTA } from "../lib/constants";
import ButtonCircularProgress from "../componentes/ButtonCircularProgress";

const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9'
    }
}))(Tooltip);

const fixEstilosImportantes = makeStyles({
    // fue necesario porque en App.css están todos los estilos sobre-escritos con !importante usando las clases base de Material-UI. Lo que hace imposible usar estilos inline o cualquier técnica que no involucre también "!important".
    fixAlertaInfo: {
        backgroundColor: 'rgb(232, 244, 253) !important',
        border: '1px solid #349ff3bd',
        '& .MuiAlert-icon': {
            color: '#2196f3 !important'
        },
        '& .MuiAlert-message': {
            color: 'rgb(13, 60, 97) !important'
        },
        '& .MuiAlert-action': {
            color: 'rgb(13, 60, 97) !important'
        }
    },
    fixAlertaSuccess: {
        backgroundColor: 'rgb(237, 247, 237) !important',
        border: '1px solid #5bb65f',
        '& .MuiAlert-icon': {
            color: '#5bb65f !important'
        },
        '& .MuiAlert-message': {
            color: 'rgb(30, 70, 32) !important'
        },
        '& .MuiAlert-action': {
            color: 'rgb(13, 60, 97) !important'
        }
    }
});

const estiloCondicionalErrorLabels = makeStyles({
    globalContainer: {
        '& .conditionalError': {
            fontSize: 12,
            color: "red",
            paddingLeft: '0.4rem',
            /** @param {{ guardarPressed: boolean }} props */
            lineHeight: ({ guardarPressed }) => {
                return guardarPressed ? 'unset' : '0';
            }
        }
    }
});

const estiloResponsiveRecurso = makeStyles(theme => ({
    containerRecurso: {
        display: 'flex',
        alignItems: 'center',
        gap: '0.5rem',
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column'
        }
    }
}));

/**
 * @param {Object} params
 * @param {api.Recurso} params.recurso
 * @param {api.ClaseInstructor} params.clase
 * @param {() => void} params.handleDeleteClick
 * @returns {JSX.Element}
 */
const ListaRecursosExistentes = function ({ recurso, clase, handleDeleteClick }) {
    const style = estiloResponsiveRecurso();
    const resourceUrl = recurso.raiz + recurso.url;
    const saveAs = `recurso_clase_${clase.id}.${recurso.ext || 'zip'}`; // NOTA: no funciona ya que solo es "honored" para recursos dentro del mismo origen (la URL del recurso no está en el mismo origen del sitio web).
    return (<>
        <div className={style.containerRecurso}>
            <span>Recurso actualmente adjunto: </span>
            <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                <a href={resourceUrl} target='_self' download={saveAs} >
                    <span>recurso comprimido (.zip)</span>
                </a>
                <button style={{ borderRadius: '4px', display: 'flex', padding: '2px 4px' }} onClick={handleDeleteClick} title={"Eliminar"} className="button_basurero"><ClearIcon /></button>
            </div>
        </div>
    </>);
};

const estiloResponsiveVideo = makeStyles(theme => ({
    containerVideo: {
        width: '50%',
        maxWidth: '1920px',
        [theme.breakpoints.down('md')]: {
            width: '75%'
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        }
    }
}));

/**
 * @param {Object} params
 * @param {api.Recurso} params.recurso
 * @param {api.ClaseInstructor} params.clase
 * @param {() => void} params.handleDeleteClick
 * @returns {JSX.Element}
 */
const VideoVistaEditar = function ({ recurso, clase, handleDeleteClick }) {
    const resourceUrl = recurso.raiz + recurso.url;
    const singleFileVersion = recurso.raiz + `/carpetaEcampus/FileGroup1/${clase.id_curso}_${clase.id}_Mp4_Avc_Aac_16x9_1920x1080p_24Hz_8.5Mbps_qvbr.mp4`;
    const style = estiloResponsiveVideo();
    return (<>
        <div className={style.containerVideo}>
            <form style={{ marginBottom: '0.5rem' }}>  {/* <form> solo por los estilos */}
                <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                    <h3 className="title_section" style={{ display: 'inline' }}>Video: </h3>
                    <a href={singleFileVersion} download target='_blank' rel="noreferrer noopener" title="Descargar video">
                        <button style={{ pointerEvents: 'none', borderRadius: '4px', display: 'flex', padding: '2px 4px' }}><GetAppIcon /></button>
                    </a>
                    <button style={{ borderRadius: '4px', display: 'flex', padding: '2px 4px' }} onClick={handleDeleteClick} title={"Eliminar"} className="button_basurero"><ClearIcon /></button>
                </div>
            </form>
            <ReactPlayer
                url={resourceUrl}
                width='100%'
                height='' // si se especifica 100% el alto no se ajusta correctamente al video
                controls
                playing={false}
                volume={0.2}
                style={{ maxHeight: '640px' }}
            />
        </div>
    </>);
};

export default function AddClass(props) {

    const isFirst = useIsFirstRender();
    const courseID = /\d+/.test(props.match.params.courseId) ? parseInt(props.match.params.courseId) : null;
    const classID = /\d+/.test(props.match.params.classId) ? parseInt(props.match.params.classId) : null;
    const location = useLocation();
    const isClassEdit = /edit-class/.test(location.pathname);
    const [claseData, setClaseData] = useState(/** @type {api.ClaseInstructor} */(location.state));
    if (claseData && claseData.id !== classID) {
        throw new Error('aborting, data inconsistency detected');
    }
    const moduleTitle = isClassEdit ? "Editar Clase" : "Agregar Clase";

    const { data_user, token } = useContext(BotonContext);
    const { isAuth, isAuthTypeTutor } = useContext(AuthContext);
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();

    const [showLoading, setShowLoading] = useState(isClassEdit && !claseData);
    const [showMensajeTematica, setShowMensajeTematica] = useState(true);
    const [guardarPressed, setGuardarPressed] = useState(false); // flag usado para evitar que se muestren los errores de validación (mensajes) antes de presionar guardar

    const clasesFixEstilos = fixEstilosImportantes();
    const clasesLabelsError = estiloCondicionalErrorLabels({ guardarPressed: guardarPressed });

    const [openModal, setOpenModal] = useState(false);
    const [tipoModal, setTipoModal] = useState(/** @type {TipoModal} */('publicar'));
    const [showButtonSpinnerModal, setShowButtonSpinnerModal] = useState(false);
    const [videoSubidoExitosamente, setVideoSubidoExitosamente] = useState(false);
    const [closeAlertaVideoSubidoExitosamente, setCloseAlertaVideoSubidoExitosamente] = useState(false);

    const ValidarAuth = () => {
        if (!isAuth || !isAuthTypeTutor) {
            clearSession();
            window.location.href = "#/login";
            window.location.reload();
        }
    };
    ValidarAuth();

    /**
     * @param {number} evaluationId
     */
    const reconstruirTest = async function (evaluationId) {
        if (!evaluationId && evaluationId !== 0) {
            isClassEdit && setEvaluationEliminada(true);
            return;
        }
        const respuesta = await api.evaluacionesInstructor.getEvaluacion({ token, evaluationId });
        if (!respuesta.success) {
            enqueueSnackbar('Error al descargar los datos de la evaluación', {
                variant: 'error'
            });
            return;
        }
        const preguntasInstructor = Object.values(respuesta.data.preguntas);
        if (!preguntasInstructor.length) {
            setEvaluationEliminada(true);
        }
        /** @type {api.PreguntaEdición[]} */
        const preguntas = preguntasInstructor.map(p => {
            /** @type {api.PreguntaEdición} */
            return { ...p, 'nombre_tipo': 'N/A' }; // `nombre_tipo` es el único valor que hay en `PreguntaEdición` que no está en `PreguntaInstructor`.
        });
        setRows(preguntas);
    };

    /**
     * @param {api.ClaseInstructor} clase
     */
    const updateStateData = function (clase) {
        if (clase.param_tipo === null) {
            clase.param_tipo = detectarTipoClase(clase);
        }
        // common state (todos los tipos de clase)
        setClaseTitle(clase.titulo);
        setDescriptionClase(clase.descripcion);
        setUnidadId(typeof clase.id_unidad === 'number' ? clase.id_unidad : null);
        const tipoClase = clase.param_tipo === null ? TIPO_CLASE.VIDEO : clase.param_tipo; // hay clases viejas en la BD con tipo null
        setTipoClase(tipoClase);
        setErrorTipoClase('');
        setTematica(clase.id_tematica);
        setClaseData(clase);

        switch (clase.param_tipo) {
            case TIPO_CLASE.VIDEO:
                setVideoYaAdjunto(clase.recursos);
                break;
            case TIPO_CLASE.ZIP:
                setRecursoYaAdjunto(clase.recursos);
                break;
            case TIPO_CLASE.TEST:
            case TIPO_CLASE.TEST_FORMATIVO:
                setTestTitle(clase.evaluacion?.nombre);
                reconstruirTest(clase.evaluacion?.id_evaluacion);
                break;
        }
    };

    if (isClassEdit && isFirst) {
        window.scrollTo(0, 0);
        if (!claseData) {
            api.instructor.getClasesInstructor({ token, courseId: courseID, userId: data_user.id_usuario })
                .then((data) => {
                    if (data.success) {
                        // ubicar clase dentro de la respuesta
                        // TODO: api para descargar datos de una clase específica solamente.
                        /** @type {api.ClaseInstructor} */
                        let downloadedClassData = null;
                        let found = false;
                        Object.values(data.data.unidades).some(unidad => {
                            unidad.clases.some(clase => {
                                if (clase.id === classID) {
                                    found = true;
                                    downloadedClassData = clase;
                                }
                                return found;
                            });
                            return found; // break loop if found = true
                        });
                        if (!found) {
                            throw new Error('error downloading class data');
                        }
                        updateStateData(downloadedClassData);
                    } else {
                        throw new Error(data.mensaje);
                    }
                })
                .catch((e) => {
                    enqueueSnackbar('Error cargando los datos del curso', {
                        variant: 'error'
                    });
                    console.log(e);
                })
                .finally(() => {
                    setShowLoading(false);
                });
        }
    }

    useEffect(() => {
        if (claseData) {
            updateStateData(claseData); // TODO: si ya se tienen los datos, inicializar las variables de estado directamente al ejecutar useState().
        }
    }, []);

    const showGenericSuccessNotification = function () {
        enqueueSnackbar('Acción ejecutada con éxito', {
            variant: 'success'
        });
    };

    /**
     * @typedef ArchivoPorAdjuntar
     * @property {string} base64
     * @property {string} nombre
     */

    const [filesZip, setFilesZip] = useState(/** @type {ArchivoPorAdjuntar[]} */([]));
    const [nameAdjunto, setNameAdjunto] = useState('');
    const [recursoYaAdjunto, setRecursoYaAdjunto] = useState(/** @type {api.Recurso} */(null));
    const [videoYaAdjunto, setVideoYaAdjunto] = useState(/** @type {api.Recurso} */(null));

    /**
     * @param {FileList} archivos
     */
    const convertToBase64 = function (archivos) {
        /** @type {ArchivoPorAdjuntar[]} */
        const arrayArchivos = [];
        let nombreFile = '';

        Array.from(archivos).forEach(archivo => {
            const reader = new FileReader();
            reader.readAsDataURL(archivo);

            reader.onload = function () {
                let arrayAuxiliar = [];
                const base64 = reader.result.toString();
                arrayAuxiliar = base64.split(',');
                arrayArchivos.push({ base64: arrayAuxiliar[1], nombre: archivo.name });
                nombreFile += archivo.name + ", ";
                setNameAdjunto('Se ha adjuntado ' + arrayArchivos.length + ' archivos (' + nombreFile.substring(0, nombreFile.length - 2) + ')');
            };
        });
        setFilesZip(arrayArchivos);
    };

    /**
     * @param {React.ChangeEvent<HTMLInputElement>} e
     */
    const handleResourceFileSelectionChange = function (e) {
        convertToBase64(e.target.files);
    };

    /**
     * @param {React.MouseEvent<HTMLLabelElement>} e
     */
    const handleResourceAttachClick = function (e) {
        if (isClassEdit && recursoYaAdjunto?.url) {
            e.preventDefault();
            enqueueSnackbar('Antes de adjuntar un nuevo recurso, debe eliminar el existente', {
                variant: 'warning'
            });
        }
    };

    /** Componente UploaderVideo.js **/

    const Layout = ({ input, previews, submitButton, dropzoneProps, files, extra: { maxFiles } }) => {
        return (
            <div>
                <div {...dropzoneProps}>
                    {previews}
                    {files.length < maxFiles && input}
                </div>
                {files.length > 0 && submitButton}
            </div>
        );
    };

    const getUploadParams = () => ({ url: 'https://httpbin.org/post' });

    //End_Point de API con Bucket inicial creado en S3
    //const API_ENDPOINT = "https://nm70sgsln2.execute-api.us-east-1.amazonaws.com/default/getSubirVideoS3";

    //End_Point de API con Bucket fuente (bucket origen S3) creado en arquitectura base VoD
    const API_ENDPOINT = "https://hiwq0zr3re.execute-api.us-east-1.amazonaws.com/default/Put_VoD_In_S3";

    //End_Point de API con Bucket fuente (bucket origen S3) creado para recursos Zip
    const API_ENDPOINT_2 = "https://2hllvgplai.execute-api.us-east-1.amazonaws.com/default/Put_Zip_In_S3";

    const [fileComplete, setFileComplete] = useState(null);
    const [video, setVideo] = useState(/** @type {{ archivo_video: File }} */(null));
    const [video_name, setVideo_name] = useState(/** @type {{ archivo_video_name: string }} */(null));
    const [tiempo, setTiempo] = useState(null);
    const [videoStatus, setVideoStatus] = useState(null);
    const [disableReproductor, setDisableReproductor] = useState(true);
    const [hiddenReproductor, setHiddenReproductor] = useState(true);
    const [url_preview, setUrlPreview] = useState('');
    const [openUploadingStatusPanel, setOpenUploadingStatusPanel] = useState(false);
    const [progress, setProgress] = useState(10);

    const showSuccessAlertClaseGuardada = function () {
        enqueueSnackbar('La clase ha sido guardada satisfactoriamente', {
            variant: 'success'
        });
    };

    const showErrorAlertGuardadoClase = function () {
        enqueueSnackbar('Error al intentar guardar la clase', {
            variant: 'error'
        });
    };

    const handleChangeStatus = (file, status) => {

        if (file.meta.type != 'video/mp4') {
            enqueueSnackbar('Debe adjuntar el video con formato .mp4', {
                variant: 'warning'
            });
            if (status === 'uploading') {
                file.remove();
            }
            file.cancel();
        }
        if (file.meta.type == 'video/mp4') {
            setHiddenReproductor(false);
            setIsLoadingVideo(true);
            const reader = new FileReader();
            reader.onloadend = () => {
                setUrlPreview(reader.result.toString());
            };
            reader.readAsDataURL(file.file);
            if (status == 'done') {
                archivo_video = file.file;
                archivo_video_name = file.file.name;
                time_video = file.meta.duration;
                setIsLoadingVideo(false);
            }
            setVideo({ ...video, archivo_video });
            setVideo_name({ ...video_name, archivo_video_name });
            setTiempo({ ...tiempo, time_video });
            setVideoStatus({ ...videoStatus, status });
            setFileComplete({ ...fileComplete, file });
        }

        if (status == 'removed') {
            setDisableReproductor(false);
            setHiddenReproductor(true);
        }

    };

    const [listaTematicas, setListaTematicas] = useState(/** @type {api.Tematica[]} */([]));
    const [tematica, setTematica] = useState(/** @type {number} */(undefined));
    const [errorTematica, setErrorTematica] = useState('');

    const [unidadId, setUnidadId] = useState(/** @type {number} */(undefined));
    const [errorUnidad, setErrorUnidad] = useState("");

    const [claseTitle, setClaseTitle] = useState("");
    const [errorClaseTitle, setErrorClaseTitle] = useState("");

    const [errorVideo, setErrorVideo] = useState("");

    const [errorFilesZip, setErrorFilesZip] = useState("");

    const [descriptionClase, setDescriptionClase] = useState("");
    const [errorDescription, setErrorDescription] = useState("");

    const [unidadApi, setUnidadApi] = useState(/** @type {api.UnidadGetUnidadesInstructor[]} */([]));

    //Test
    const [tipoClase, setTipoClase] = useState(/** @type {number} */(undefined));
    const [errorTipoClase, setErrorTipoClase] = useState("");
    const [testTitle, setTestTitle] = useState('');
    const [errorTestTitle, setErrorTestTitle] = useState("");
    const [rows, setRows] = useState(/** @type {api.PreguntaEdición[]} */([]));

    const [isSavingUnidad, setIsSavingUnidad] = useState(false);
    const [isSavingClase, setIsSavingClase] = useState(false);
    const [isLoadingVideo, setIsLoadingVideo] = useState(false);
    const [evaluationEliminada, setEvaluationEliminada] = useState(false);
    const [isEditingTest, setIsEditingTest] = useState(false);
    const [preguntaBeingEdited, setPreguntaBeingEdited] = useState(/** @type {{ pregunta: api.PreguntaInstructor, index: number }} */(null));
    const showTestEditingUI = (isClassEdit && isEditingTest) || (isClassEdit && evaluationEliminada) || !rows.length || !isClassEdit;
    const [isEditingQuestion, setIsEditingQuestion] = useState(false);

    useEffect(() => {

        api.instructor.getUnidades({ courseId: courseID, token })
            .then(({ data, status }) => {
                if (data.success) {
                    setUnidadApi(data.data);
                } else {
                    // FIX(api): el API no debería devolver error 500 cuando no consigue unidades. Debe devolver success = true y un arreglo vacío. Se verifica el texto del mensaje como workaround.
                    if (/No se encontraron unidades/i.test(data?.mensaje)) {
                        enqueueSnackbar('No existen unidades, es necesario crear una unidad', {
                            variant: 'warning'
                        });
                    } else if (status === 500) {
                        throw new Error();
                    } else if (data?.data === null) {
                        ValidarAuth();
                    }
                }
            })
            .catch(() => {
                enqueueSnackbar('Error al descargar unidades', {
                    variant: 'error'
                });
            });

        api.instructor.getListaTematicas()
            .then(data => {
                if (data.success) {
                    setListaTematicas(data.data);
                } else {
                    throw new Error();
                }
            })
            .catch(() => {
                enqueueSnackbar('Error al descargar lista de temáticas', {
                    variant: 'error'
                });
            });

    }, []);

    const limpiarCargaRecurso = function () {
        setFilesZip([]);
        setErrorFilesZip('');
        setNameAdjunto('');
    };

    const limpiarCargaVideo = function () {
        setVideo(null);
        setVideo_name(null);
        setTiempo(null);
        setVideoStatus(null);
        setDisableReproductor(true);
        setHiddenReproductor(true);
        setUrlPreview('');
        setErrorVideo('');
    };

    /**
     * Se limpia luego de agregar exitosamente una clase. Para no tener que regresar a la vista anterior y volver a presionar agregar clase si se quieren agregar varias.
     * @todo buscar manera de simplificar/automatizar esto. Por ejemplo, inicializando los estados mediante un customHook y luego ejecutando un método que vuelva todos a su estado inicial. Otra manera podría ser manejar todo el estado en una sola estructura y guardar la estructura inicial para restablecerla toda junta.
     */
    const LimpiarForm = function () {
        setGuardarPressed(false);

        // Selector temática
        setTematica(undefined);
        setErrorTematica('');

        // Selector unidad
        setUnidadId(undefined);
        setErrorUnidad("");

        // Título de la clase
        setClaseTitle("");
        setErrorClaseTitle("");

        // Descripción de la clase
        setDescriptionClase("");
        setErrorDescription("");

        // Tipo de clase
        setTipoClase(undefined);
        setErrorTipoClase("");

        // Modal crear unidad
        setOpenAgregarUnidad(false);

        // Sección de subida de video / recurso
        limpiarCargaVideo();
        limpiarCargaRecurso();
        setErrorVideo('');
        setErrorFilesZip('');

        // Evaluaciones
        setTestTitle('');
        setErrorTestTitle("");

        setRows([]);

        setPreguntaSeleccion("");
        setMensPreguntaSeleccion("");
        setHiddenPreguntaSeleccion(true);

        setPreguntasTrueFalse("");
        setMensPreguntasTrueFalse("");
        setHiddenPreguntasTrueFalse(true);

        setJustificacionSel("");
        setMensJustificacionSel("");

        setJustificacionTrue("");
        setMensJustificacionTrue("");

        setValueVT('');

        setListaAlternativas([{ descripcion: "", correcto: 0, observacion: null }]);
        setRadioValor(null);
        setOpenPlus(false);
    };

    /**
     * @returns {boolean}
     */
    const validarForm = function () {

        if (!tematica && tematica !== 0) {
            setErrorTematica("Se requiere seleccionar una temática");
            document.getElementById('select-tematica').focus();
            return false;
        }

        if (!unidadId && unidadId !== 0) {
            setErrorUnidad("Se requiere seleccionar una unidad");
            document.getElementById('select-und').focus();
            return false;
        }

        if (!claseTitle) {
            setErrorClaseTitle("Se requiere ingresar el título");
            document.getElementById('class_title').focus();
            return false;
        }

        if (!descriptionClase) {
            setErrorDescription("Se requiere ingresar descripción");
            document.getElementById('description').focus();
            return false;
        }

        if (!tipoClase && tipoClase !== 0) {
            setErrorTipoClase("Se requiere seleccionar un tipo de clase.");
            document.getElementById('description').focus();
            return false;
        }

        if (tipoClase == TIPO_CLASE.VIDEO && !videoYaAdjunto?.url) {

            if (!video?.archivo_video) {
                setErrorVideo("Se requiere adjuntar video");
                document.getElementById('select-tipoclass-label').focus();
                return false;
            }

            if (videoStatus.status != 'done' && !!video?.archivo_video) {
                setErrorVideo("Por favor espere el video se está preparando para poder subirlo...");
                document.getElementById('select-tipoclass-label').focus();
                return false;
            }

            if (!tiempo?.time_video) {
                setErrorVideo("Este video presenta problemas para ser cargado.");
                document.getElementById('select-tipoclass-label').focus();
                return false;
            }

        }
        if (tipoClase == TIPO_CLASE.ZIP) {
            if (!filesZip?.length && !recursoYaAdjunto?.url) {
                setErrorFilesZip("Se requiere adjuntar recursos");
                document.getElementById('recurso').focus();
                return false;
            }
        }
        if (tipoClase == TIPO_CLASE.TEST) {

            if (!testTitle) {
                setErrorTestTitle("Se requiere ingresar el título del test");
                document.getElementById('test_title').focus();
                return false;
            }

            if (rows.length < 3) {
                enqueueSnackbar('El mínimo de preguntas para un test es de 3!', {
                    variant: 'warning'
                });
                document.getElementById('test_title').focus();
                return false;
            }

        }
        if (tipoClase == TIPO_CLASE.TEST_FORMATIVO) {

            if (!testTitle) {
                setErrorTestTitle("Se requiere ingresar el título para la evaluación");
                document.getElementById('test_title').focus();
                return false;
            }

            if (rows.length < 5) {
                enqueueSnackbar('El mínimo de preguntas para una evaluación es de 5!', {
                    variant: 'warning'
                });
                document.getElementById('test_title').focus();
                return false;
            }

        }
        return true;
    };

    const guardarClase = function () {
        switch (tipoClase) {
            case TIPO_CLASE.VIDEO:
                saveClaseVideo();
                break;
            case TIPO_CLASE.ZIP:
                saveClaseRecurso();
                break;
            case TIPO_CLASE.TEST:
                saveClaseEvaluation(TIPO_EVALUATION.TEST);
                break;
            case TIPO_CLASE.TEST_FORMATIVO:
                saveClaseEvaluation(TIPO_EVALUATION.TEST_FINAL);
                break;
        }
    };

    const handleGuardarButton = function () {
        setGuardarPressed(true);
        if (validarForm()) {
            guardarClase();
        }
    };

    /**
     * @param {1 | 2} tipoEvaluation
     */
    const saveClaseEvaluation = function (tipoEvaluation) {

        setIsSavingClase(true);

        /** @type {api.DataCrearClaseEvaluation} */
        const datosPost = {
            "id_curso": courseID,
            "creador": data_user.id_usuario,
            "titulo": claseTitle,
            "orden": null,
            "descripcion": descriptionClase,
            "id_unidad": unidadId,
            "param_tipo": tipoEvaluation === TIPO_EVALUATION.TEST ? TIPO_CLASE.TEST : TIPO_CLASE.TEST_FORMATIVO,
            "duracion": null,
            "fecha_creacion": '0000-00-00 00:00:00',
            "tipo": tipoEvaluation,
            "nombre": testTitle,
            "preguntas": [rows],
            "id_tematica": tematica
        };

        const promesa = (() => {
            if (isClassEdit) {
                if (rows?.length && evaluationEliminada) {
                    // el api `autoriaClases.actualizarClase` (/clase/<id>) no sirve para agregar evaluación a una clase existente. Aparentemente el API ignora `preguntas`.
                    // TODO: poder usar un solo API para actualizar los datos de la clase y crear la nueva aplicación.
                    // TODO: simplificar o extraer a método propio.
                    const promiseActualizarClase = api.autoriaClases.actualizarClase({ token, claseId: claseData.id, payload: datosPost });
                    const promiseCreateEval = api.evaluacionesInstructor.crearEvaluationClaseExistente({ token, payload: { ...datosPost, id_clase: claseData.id } });
                    return new Promise((resolve, reject) => {
                        Promise.all([promiseActualizarClase, promiseCreateEval])
                            .then(() => {
                                promiseActualizarClase.then((respuestaActualizarClase) => {
                                    resolve(respuestaActualizarClase); // para que retorne lo mismo que en el caso de que no se guarda evaluación
                                });
                                promiseCreateEval.then(async (respuestaCrearEval) => {
                                    const newEvaluationId = respuestaCrearEval.data.id_evaluacion;
                                    try {
                                        await reconstruirTest(newEvaluationId);
                                        setEvaluationEliminada(false); // esperar a que se refresquen los datos. De lo contrario se mostraría el botón de eliminar evaluación y no estaría el id para eliminarla.
                                    } catch (error) {
                                        enqueueSnackbar('Error al refrescar datos de evaluación, intente recargando la página', {
                                            variant: 'error'
                                        });
                                    }
                                });
                            })
                            .catch(() => {
                                reject();
                            });
                    });
                } else {
                    // TODO?: poder actualizar con una sola petición?
                    /** @type {Promise[]} */
                    const promesas = [];
                    const promiseActualizarClase = api.autoriaClases.actualizarClase({ token, claseId: claseData.id, payload: datosPost });
                    promesas.push(promiseActualizarClase);
                    if (testTitle !== claseData.evaluacion.nombre) {
                        const promiseActualizarEval = api.evaluacionesInstructor.updateEvaluation({ token, evaluationId: claseData.evaluacion.id_evaluacion, nombre: testTitle });
                        promesas.push(promiseActualizarEval);
                    }
                    return new Promise((resolve, reject) => {
                        Promise.all(promesas)
                            .then(() => {
                                promiseActualizarClase.then((respuestaActualizarClase) => {
                                    resolve(respuestaActualizarClase); // para que retorne lo mismo que en el caso de que no se guarda evaluación
                                });
                            })
                            .catch(() => {
                                reject();
                            });
                    });
                }
            }
            if (tipoEvaluation === TIPO_EVALUATION.TEST) {
                return api.autoriaClases.crearClaseEvaluacion({ token, payload: datosPost });
            }
            if (tipoEvaluation === TIPO_EVALUATION.TEST_FINAL) {
                return api.autoriaClases.crearClaseEvaluacionFormativa({ token, payload: datosPost });
            }
        })();

        promesa.then(data => {
            if (data.success) {
                !isClassEdit && LimpiarForm();
                showSuccessAlertClaseGuardada();
            } else {
                throw new Error(data?.mensaje);
            }
        })
            .catch(e => {
                console.log(e);
                showErrorAlertGuardadoClase();
            })
            .finally(() => {
                setIsSavingClase(false);
            });

    };

    /** @type {File} */
    let archivo_video;
    let archivo_video_name;
    let time_video;

    const saveClaseVideo = async function () {

        const uploadNewVideo = !isClassEdit || (isClassEdit && !videoYaAdjunto?.url);
        setIsSavingClase(true);
        const id_curso = courseID;
        /** @type {number} */
        let duration;

        if (uploadNewVideo) {
            setOpenUploadingStatusPanel(true);
            duration = parseInt(tiempo.time_video);               //En segundos pasados a entero
            //duration = commonIO.convertMS(tiempo.time_video);   //En segundos pasados a formato: 00:00:00
            //duration = tiempo.time_video;                       //En segundos tipo float
            setProgress(20);
            if (!video?.archivo_video) {
                setIsSavingClase(false);
                return;
            }
            setProgress(40);
        }

        /** @type {api.DataCrearClase} */
        const ClassUpload = {
            "id_curso": id_curso,
            "titulo": claseTitle,
            "orden": null,
            "descripcion": descriptionClase,
            "id_unidad": unidadId,
            "param_tipo": 1,
            "duracion": uploadNewVideo ? duration : null,
            "fecha_creacion": '0000-00-00 00:00:00',
            "id_tematica": tematica
        };

        try {
            const promesa = isClassEdit
                ? api.autoriaClases.actualizarClase({ token, claseId: claseData.id, payload: ClassUpload })
                : api.autoriaClases.crearClase({ token, payload: ClassUpload });

            const responseClase = await promesa;

            if (!responseClase.success) {
                setOpenUploadingStatusPanel(false);
                showErrorAlertGuardadoClase();
                return;
            }

            if (!uploadNewVideo) {
                showSuccessAlertClaseGuardada();
                return; // no hay mas nada que hacer
            }

            const id_clase = responseClase.data.id;
            const name = video_name.archivo_video_name;
            const path_splitted = name.split('.');
            const ext = path_splitted.pop();
            setProgress(60);

            const new_name = id_curso + "_" + id_clase + "." + ext.toLowerCase(); // asegurar que la extensión esté en minúsculas. De lo contrario, no será procesado el video en AWS.

            //  GET request: presigned URL - Video
            const responseGetURL = await axios({
                method: "GET",
                url: API_ENDPOINT + '?filename=' + new_name
            });

            //  PUT request: upload file to S3 - Video
            const responseUpload = await fetch(responseGetURL.data.uploadURL, {
                method: "PUT",
                body: video.archivo_video
            });

            setProgress(80);

            if (responseUpload.status !== 200) {
                throw new Error('Error al subir video');
            }
            const UrlVideoUpload = {
                "id_clase": id_clase,
                "param_tipo": 1,
                "orden": 1,
                "fecha_creacion": '0000-00-00 00:00:00',
                "raiz": "https://d1d3qgjugd41mc.cloudfront.net",
                "url": "/carpetaEcampus/AppleHLS1/" + id_curso + "_" + id_clase + ".m3u8",
                "ext": "m3u8",
                "status": 0
            };

            const responseRegistrarRecurso = await api.autoriaClases.registrarRecurso({ token, payload: UrlVideoUpload });

            setProgress(100);
            if (isClassEdit) {
                limpiarCargaVideo();
                setVideoYaAdjunto(responseRegistrarRecurso.data);
                setVideoSubidoExitosamente(true);
            } else {
                LimpiarForm();
            }
            setOpenUploadingStatusPanel(false);
            showSuccessAlertClaseGuardada();

        } catch (error) {
            console.log("Error:", error);
            setOpenUploadingStatusPanel(false);
            showErrorAlertGuardadoClase();
        } finally {
            setIsSavingClase(false);
        }

    };

    const saveClaseRecurso = async function () {

        const uploadNewFile = !isClassEdit || (isClassEdit && !recursoYaAdjunto?.url);
        setIsSavingClase(true);
        uploadNewFile && setOpenUploadingStatusPanel(true);

        /** @type {api.DataCrearClase} */
        const ClassUpload = {
            "id_curso": courseID,
            "titulo": claseTitle,
            "orden": null,
            "descripcion": descriptionClase,
            "id_unidad": unidadId,
            "param_tipo": 2,
            "duracion": null,
            "fecha_creacion": '0000-00-00 00:00:00',
            "id_tematica": tematica
        };

        /** @type {Blob} */
        let zipContents;
        if (uploadNewFile) {
            const zip = JSZip();
            setProgress(20);
            if (!filesZip?.length) {
                setIsSavingClase(false);
                return;
            }
            filesZip.map(archivo => {
                zip.file(archivo.nombre, archivo.base64, { base64: true });
            });
            zipContents = await zip.generateAsync({ type: 'blob' });
            setProgress(30);
        }

        try {
            const promesa = isClassEdit
                ? api.autoriaClases.actualizarClase({ token, claseId: claseData.id, payload: ClassUpload })
                : api.autoriaClases.crearClase({ token, payload: ClassUpload });

            const responseClase = await promesa;
            if (!responseClase.success) {
                throw new Error(responseClase?.mensaje);
            }

            if (!uploadNewFile) {
                showSuccessAlertClaseGuardada();
                return; // no hay mas nada que hacer
            }

            setProgress(40);
            const id_clase = responseClase.data.id;
            const ext_2 = "zip";
            const new_name_2 = ClassUpload.id_curso + "_" + id_clase + "_zip" + "." + ext_2;

            setProgress(60);
            //  GET request: presigned URL - Zip
            const responseGetUploadURL = await axios({
                method: "GET",
                url: API_ENDPOINT_2 + '?filename=' + new_name_2
            });

            //  PUT request: upload file to S3 - Zip
            const responseUpload = await fetch(responseGetUploadURL.data.uploadURL, {
                method: "PUT",
                body: zipContents
            });

            setProgress(80);
            if (responseUpload.status !== 200) {
                throw new Error('Error al subir archivo');
            }

            const UrlRecursoUpload = {
                "id_clase": id_clase,
                "param_tipo": 2,
                "orden": 2,
                "fecha_creacion": '0000-00-00 00:00:00',
                "raiz": "https://d1d3qgjugd41mc.cloudfront.net",
                "url": "/RecursosZip/" + new_name_2,
                "ext": ext_2,
                "status": 0
            };
            const responseRegisterResource = await api.autoriaClases.registrarRecurso({ token, payload: UrlRecursoUpload });

            setProgress(100);
            if (isClassEdit) {
                limpiarCargaRecurso();
                setRecursoYaAdjunto(responseRegisterResource.data);
            } else {
                LimpiarForm();
            }
            setOpenUploadingStatusPanel(false);
            showSuccessAlertClaseGuardada();

        } catch (error) {
            console.log("Error:", error);
            setOpenUploadingStatusPanel(false);
            showErrorAlertGuardadoClase();

        } finally {
            setIsSavingClase(false);
        }

    };

    const eliminarEvaluation = function () {
        setShowButtonSpinnerModal(true);
        api.evaluacionesInstructor.eliminarEvaluation({
            token,
            evaluationId: claseData.evaluacion.id_evaluacion
        })
            .then((data) => {
                if (data.success) {
                    setRows([]);
                    setEvaluationEliminada(true);
                    setTestTitle('');
                    setIsEditingTest(false);
                    enqueueSnackbar('Operación ejecutada con éxito', {
                        variant: 'success'
                    });
                    closeModal();
                } else {
                    throw new Error();
                }
            })
            .catch(() => {
                setShowButtonSpinnerModal(false);
                enqueueSnackbar('Error al eliminar evaluación', {
                    variant: 'error'
                });
            });
    };

    const handleEliminarEvaluationClick = function () {
        setTipoModal('eliminar-evaluation');
        abrirModal();
    };

    const handleEditarEvaluationClick = function () {
        setIsEditingTest(!isEditingTest);
    };

    const [openAgregarUnidad, setOpenAgregarUnidad] = useState(false);

    const handleButtonAgregarUnidad = () => {
        setOpenAgregarUnidad(true);
    };

    const handleCloseCrearUnidad = () => {
        setOpenAgregarUnidad(false);
    };

    const handleGuardarUnidad = () => {

        const title = /** @type {HTMLInputElement} */(document.getElementById("title-unit")).value;
        const descriptionUnidad = /** @type {HTMLInputElement} */(document.getElementById("desc-unit")).value;
        if (!title) {
            enqueueSnackbar('Debe especificar un título para la unidad', {
                variant: 'warning'
            });
            return;
        }

        setIsSavingUnidad(true);

        const unit = {
            "id_curso": courseID,
            "nombre": title,
            "descripcion": descriptionUnidad
        };

        api.autoriaUnidades.crearUnidad({ courseId: courseID, token, unidad: unit })
            .then((dataCrear) => {
                if (dataCrear.success) {
                    showGenericSuccessNotification();
                    // Refrescar unidades del curso
                    // TODO: agregar usando la respuesta de la creación. Sin necesidad de obtener toda la lista de nuevo.
                    api.instructor.getUnidades({ courseId: courseID, token })
                        .then(({ data: dataGet }) => {
                            setUnidadApi(dataGet.data);
                        })
                        .catch(() => {
                            enqueueSnackbar('Error al refrescar unidades', {
                                variant: 'warning'
                            });
                        })
                        .finally(() => {
                            setOpenAgregarUnidad(false); // cerrar modal aunque no se logre refrescar
                            setIsSavingUnidad(false);
                        });
                    setUnidadId(dataCrear.data.id);
                } else {
                    throw new Error('Error al crear unidad');
                }
            }).catch(() => {
                enqueueSnackbar('Error al crear unidad', {
                    variant: 'error'
                });
                setIsSavingUnidad(false);
            });

    };

    /** Selects **/

    const useStyles = makeStyles((theme) => ({
        formControl: {
            margin: theme.spacing(1),
            minWidth: '100%'
        },
        formControlReactPlayer: {
            minWidth: '50%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            '& video': {
                // en Chrome no funciona asignar max-size "640px" al contenedor. Hay que asignar el estilo a <video> directamente.
                maxHeight: '640px'
            }
        },
        selectEmpty: {
            marginTop: theme.spacing(2)
        },
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        },
        paper: {
            backgroundColor: theme.palette.background.paper,
            borderRadius: '5px',
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3)
        }
    }));

    const useStyles3 = makeStyles((theme) => ({
        root: {
            display: 'flex',
            '& > * + *': {
                marginLeft: theme.spacing(2)
            }
        }
    }));

    const classes = useStyles();
    const classes3 = useStyles3();

    /** Fin Selects **/

    useEffect(() => {
        document.title = moduleTitle;
    });

    const cancel = () => {
        history.push("/my-courses-tutor");
    };

    const [preguntaSeleccion, setPreguntaSeleccion] = useState("");
    const [mensPreguntaSeleccion, setMensPreguntaSeleccion] = useState("");
    const [hiddenPreguntaSeleccion, setHiddenPreguntaSeleccion] = useState(true);

    const [mensDescriptionSel, setMensDescriptionSel] = useState("");

    const [justificacionSel, setJustificacionSel] = useState("");
    const [mensJustificacionSel, setMensJustificacionSel] = useState("");

    const [preguntasTrueFalse, setPreguntasTrueFalse] = useState("");
    const [mensPreguntasTrueFalse, setMensPreguntasTrueFalse] = useState("");
    const [hiddenPreguntasTrueFalse, setHiddenPreguntasTrueFalse] = useState(true);

    const [mensCheckedTrueFalse, setMensCheckedTrueFalse] = useState("");

    const [justificacionTrue, setJustificacionTrue] = useState("");
    const [mensJustificacionTrue, setMensJustificacionTrue] = useState("");

    const [valueVT, setValueVT] = useState('');

    /**
     * @param {React.ChangeEvent<HTMLInputElement>} event
     */
    const handleChangeVT = (event) => {
        setValueVT(event.target.value);
    };

    /**
     * @param {api.PreguntaEdición} data
     */
    const guardarNuevaPregunta = async function (data) {
        try {
            const response = await api.evaluacionesInstructor.agregarPregunta({ token, evaluationId: claseData.evaluacion.id_evaluacion, payload: data });
            if (response.success) {
                // al final, limpiar la forma de agregar:
                LimpiarFormPreguntasSeleccion();
                LimpiarFormPreguntasTrueFalse();
                // y concatenar con la estructura local. NOTA: debe ser de tipo `api.PreguntaInstructor` (con id pregunta y otros detalles).
                const preguntas = Object.values(response.data.preguntas);
                const preguntasNormalizadas = preguntas.map(p => {
                    return { ...p, id: p.id_pregunta }; // normalizar estructura de "PreguntaInstructor" y "PreguntaInstructor2"
                });
                setRows(rows.concat(...preguntasNormalizadas));
            } else {
                throw new Error();
            }
        } catch (error) {
            console.log(error);
            enqueueSnackbar('Error al agregar nueva pregunta', {
                variant: 'error'
            });
        }
    };

    const handleAddPreguntaSeleccionClick = () => {

        if (tipoClase === TIPO_CLASE.TEST && rows.length == 5 && !isEditingQuestion) {
            enqueueSnackbar('El número de preguntas no puede ser superior a 5!', {
                variant: 'warning'
            });
            document.getElementById('test_title').focus();
            return;
        }
        if (tipoClase === TIPO_CLASE.TEST_FORMATIVO && rows.length == 10 && !isEditingQuestion) {
            enqueueSnackbar('El número de preguntas no puede ser superior a 10!', {
                variant: 'warning'
            });
            document.getElementById('test_title').focus();
            return;
        }

        if (!preguntaSeleccion) {
            setMensPreguntaSeleccion("Se requiere ingresar un enunciado de la pregunta.");
            document.getElementById('preguntaseleccion').focus();
            return;
        }

        const controles = listaAlternativas;
        for (let i = 0; i < controles.length; i++) {
            let control = controles[i].descripcion;
            if (control == '') {
                setMensDescriptionSel("Se requiere ingresar todas las alternativas.");
                document.getElementById('descripcionsel').focus();
                return;
            }
        }
        if (typeof radioValor !== 'number') {
            setMensDescriptionSel("Debe seleccionar una alternativa como correcta.");
            document.getElementById('descripcionsel').focus();
            return;
        }

        if (!justificacionSel) {
            setMensJustificacionSel("Se requiere ingresar la justificación de la pregunta.");
            document.getElementById('justificacionsel').focus();
            return;
        }

        /** @type {api.PreguntaEdición} */
        let jsonNuevaPregunta = {
            tipo: 2,
            nombre_tipo: "Selección",
            enunciado: preguntaSeleccion,
            justificacion: justificacionSel,
            alternativas: listaAlternativas
        };

        // TODO: mejorar este flujo
        if (isEditingQuestion) {
            if (!isEditingTest || evaluationEliminada) {
                aplicarModificacionPreguntaLocal(jsonNuevaPregunta);
            } else {
                aplicarModificacionPreguntaDB(jsonNuevaPregunta);
            }
        } else {
            if (!isEditingTest || evaluationEliminada) {
                // agregar solo a la estructura local
                setRows(rows.concat(jsonNuevaPregunta));
                LimpiarFormPreguntasSeleccion();
            } else {
                // guardar directamente a la BD
                guardarNuevaPregunta(jsonNuevaPregunta);
            }
        }

    };

    const handleAddPreguntaTrueFalseClick = () => {

        if (tipoClase == 3 && rows.length == 5) {
            enqueueSnackbar('El número de preguntas no puede ser superior a 5!', {
                variant: 'warning'
            });
            document.getElementById('test_title').focus();
            return;
        }
        if (tipoClase == 4 && rows.length == 10) {
            enqueueSnackbar('El número de preguntas no puede ser superior a 10!', {
                variant: 'warning'
            });
            document.getElementById('test_title').focus();
            return;
        }
        if (!preguntasTrueFalse) {
            setMensPreguntasTrueFalse("Se requiere ingresar un enunciado de la pregunta.");
            document.getElementById('preguntastruefalse').focus();
            return;
        }
        if (!valueVT) {
            setMensCheckedTrueFalse("Debe seleccionar una alternativa como correcta.");
            document.getElementById('preguntastruefalse').focus();
            return;
        }

        if (!justificacionTrue) {
            setMensJustificacionTrue("Se requiere ingresar la justificación de la pregunta.");
            document.getElementById('justificaciontrue').focus();
            return;
        }

        /** @type {api.PreguntaEdición} */
        const jsonNuevaPregunta = {
            tipo: 1,
            nombre_tipo: "Verdadero o Falso",
            enunciado: preguntasTrueFalse,
            justificacion: justificacionTrue,
            alternativas: [{
                descripcion: "Verdadero",
                correcto: (/** @type {HTMLInputElement} */(document.getElementById("radio_V")).checked == true) ? 1 : 0,
                observacion: null
            },
            {
                descripcion: "Falso",
                correcto: (/** @type {HTMLInputElement} */(document.getElementById("radio_F")).checked == true) ? 1 : 0,
                observacion: null
            }]
        };

        // TODO: mejorar este flujo
        if (isEditingQuestion) {
            if (!isEditingTest || evaluationEliminada) {
                aplicarModificacionPreguntaLocal(jsonNuevaPregunta);
            } else {
                aplicarModificacionPreguntaDB(jsonNuevaPregunta);
            }
        } else {
            if (!isEditingTest || evaluationEliminada) {
                // agregar solo a la estructura local
                setRows(rows.concat(jsonNuevaPregunta));
                LimpiarFormPreguntasTrueFalse();
            } else {
                // guardar directamente a la BD
                guardarNuevaPregunta(jsonNuevaPregunta);
            }
        }

    };

    const [listaAlternativas, setListaAlternativas] = useState(/** @type {api.Alternativa[]} */([{ descripcion: "", correcto: 0, observacion: null }]));
    const [radioValor, setRadioValor] = useState(/** @type {number} */(null));

    const handleInputChange = (e, index) => {
        const { name, value } = e.target;
        const list = [...listaAlternativas];
        list[index][name] = value;
        setListaAlternativas(list);
    };

    const handleInputChangeRadio = (event, index) => {
        let vec = [...listaAlternativas];
        vec.map((e) => {
            return (e.correcto = 0);
        });
        vec[index].correcto = 1;
        setListaAlternativas(vec);
    };

    const handleRemoveClick = index => {
        const list = [...listaAlternativas];
        list.splice(index, 1);
        setListaAlternativas(list);
    };

    const handleAgregarAlternativaClick = () => {
        setListaAlternativas([...listaAlternativas, { descripcion: "", correcto: 0, observacion: null }]);
    };

    const formPreguntasSeleccion = [];

    formPreguntasSeleccion.push(

        <div key="formpreuntasseleccion">

            <Grid container>

                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                    <div style={{ marginLeft: '-8px', marginTop: '20px' }} className="section section-b content" >
                        <FormControl variant="outlined" className={classes.formControl}>
                            <TextField id="preguntaseleccion" label="Ingrese enunciado de la pregunta..." variant="outlined" className={classes.formControl}
                                fullWidth
                                InputProps={{
                                    className: "InputText"
                                }}
                                InputLabelProps={{
                                    className: "InputText"
                                }}
                                style={{ marginLeft: '0px' }}
                                error={!!mensPreguntaSeleccion}
                                onBlur={() => {
                                    if (!preguntaSeleccion) {
                                        setMensPreguntaSeleccion("Este campo es obligatorio");
                                    } else if (preguntaSeleccion.length > 350) {
                                        setMensPreguntaSeleccion("El máximo permitido de carácteres es de 350");
                                    } else {
                                        setMensPreguntaSeleccion("");
                                    }
                                }}
                                onChange={(e) => {
                                    setPreguntaSeleccion(e.target.value);
                                }}
                                type="text"
                                value={preguntaSeleccion}
                            />
                            <p style={{ fontSize: 12, color: "red", visibility: mensPreguntaSeleccion ? "visible" : "hidden" }} >
                                {mensPreguntaSeleccion}
                            </p>
                        </FormControl>
                    </div>

                    {/*Radios*/}

                    <h2 style={{ marginLeft: '0px', marginTop: '-10px' }} className="s18 title_section c333"><b>Alternativas:</b></h2>
                    <div style={{ marginTop: '20px' }}></div>

                    {/*Radios dinámicos*/}

                    {listaAlternativas.map((x, i) => {
                        return (
                            <div key={i}>
                                <Grid container direction="row" item >
                                    <FormControl fullWidth>

                                        <RadioGroup aria-label="gender" name="gender1" value={radioValor} onChange={e => handleInputChangeRadio(e, i)} >

                                            <Grid item xs={2} sm={2} md={2} lg={1} xl={1} >
                                                <FormControlLabel id="correcto" name="correcto" style={{ float: 'right', marginLeft: '15px', marginTop: '15px' }} value={i + 1} control={<Radio id={"radio" + i + 1} onChange={() => setRadioValor(i + 1)} checked={x.correcto == 1 ? true : false} />} label="" />
                                            </Grid>

                                            <Grid item xs={8} sm={8} md={8} lg={10} xl={10} >
                                                <TextField id="descripcionsel" name="descripcion" label="Ingrese alternativa..." variant="outlined"
                                                    fullWidth className="InputText"
                                                    InputProps={{
                                                        className: "InputText"
                                                    }}
                                                    InputLabelProps={{
                                                        className: "InputText"
                                                    }}
                                                    maxRows="4"
                                                    minRows="2"
                                                    multiline={true}
                                                    error={!!mensDescriptionSel}
                                                    onBlur={() => {
                                                        const input = /** @type {HTMLInputElement} */(document.getElementById("descripcionsel"));
                                                        const value = input.value;
                                                        if (!value) {
                                                            setMensDescriptionSel("Este campo es obligatorio");
                                                        } else if (value.length > 350) {
                                                            setMensDescriptionSel("El máximo permitido de caracteres es de 350");
                                                        } else {
                                                            setMensDescriptionSel("");
                                                        }
                                                    }}
                                                    onChange={e => handleInputChange(e, i)}
                                                    type="text"
                                                    value={x.descripcion}
                                                />
                                                <p style={{ fontSize: 12, color: "red", visibility: mensDescriptionSel ? "visible" : "hidden" }} >
                                                    {mensDescriptionSel}
                                                </p>

                                            </Grid>
                                            <Grid item xs={2} sm={2} md={2} lg={1} xl={1} >
                                                {listaAlternativas.length !== 1 &&
                                                    <Button type="button" onClick={() => handleRemoveClick(i)} style={{ marginLeft: '5px', marginTop: '15px' }} variant="contained" color="secondary" className="button_accept"><DeleteForever /></Button>
                                                }
                                            </Grid>

                                        </RadioGroup>
                                        <br></br><br></br>
                                    </FormControl>
                                </Grid>

                            </div>
                        );
                    })}

                    <h2 style={{ marginLeft: '0px' }} className="s14 title_section c333"><b>Nota:</b> debe seleccionar la alternativa correcta.</h2>
                    {/*Radios dinámicos*/}

                    <div style={{ marginTop: '10px' }}></div>
                    <Button type="button" onClick={handleAgregarAlternativaClick} variant="contained" color="secondary" style={{ float: 'left', marginLeft: '0px' }} className="button_accept">Añadir alternativa &nbsp;&nbsp;<AddCircleIcon /></Button>

                </Grid>
                <Grid item xs={12}>
                    <div style={{ marginLeft: '-8px' }} className="section section-b content" >
                        <FormControl variant="outlined" className={classes.formControl}>
                            <TextField id="justificacionsel" label="Ingrese justificación..." className="InputText"
                                InputProps={{
                                    className: "InputText"
                                }}
                                InputLabelProps={{
                                    className: "InputText"
                                }}
                                maxRows="10"
                                minRows="6" variant="outlined" fullWidth
                                multiline={true}
                                error={!!mensJustificacionSel}
                                onBlur={() => {
                                    if (justificacionSel) {
                                        setMensJustificacionSel("");
                                    } else {
                                        setMensJustificacionSel("Este campo es obligatorio");
                                    }
                                }}
                                onChange={(e) => {
                                    setJustificacionSel(e.target.value);
                                }}
                                type="text"
                                value={justificacionSel}
                            />
                            <p style={{ fontSize: 12, color: "red", visibility: mensJustificacionSel ? "visible" : "hidden" }} >
                                {mensJustificacionSel}
                            </p>
                        </FormControl>
                    </div>
                </Grid>
            </Grid>

            <br></br>

            <div style={{ display: 'flex', justifyContent: 'end', gap: '1.5rem' }}>
                {isEditingQuestion &&
                    <Button
                        type="button"
                        onClick={ocultarPanelModification}
                        variant="contained"
                        color="secondary"
                        style={{ marginRight: '-7px' }}
                        className="button_cancel"
                    >
                        Cancelar
                    </Button>
                }
                <Button type="button" onClick={handleAddPreguntaSeleccionClick} variant="contained" color="secondary" style={{ marginRight: '-7px' }} className="button_accept">
                    {isEditingQuestion ? "Aplicar" : "Añadir pregunta"}
                    {!isEditingQuestion && <AddCircleIcon />}
                </Button>
            </div>

            <br></br><br></br><br></br>
            <hr></hr>
            <br></br>
        </div>
    );

    const LimpiarFormPreguntasSeleccion = () => {
        setPreguntaSeleccion("");
        setMensPreguntaSeleccion("");
        setHiddenPreguntaSeleccion(true);

        setJustificacionSel("");
        setMensJustificacionSel("");

        setRadioValor(null);
        setListaAlternativas([{ descripcion: "", correcto: 0, observacion: null }]);
    };

    let formPreguntasTrueFalse = [];

    formPreguntasTrueFalse.push(

        <div key="formpreuntastruefalse">

            <Grid container >

                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                    <div style={{ marginLeft: '-8px', marginTop: '20px' }} className="section section-b content" >
                        <FormControl variant="outlined" className={classes.formControl}>
                            <TextField id="preguntastruefalse" label="Ingrese enunciado de la pregunta..." variant="outlined" className={classes.formControl}
                                fullWidth
                                InputProps={{
                                    className: "InputText"
                                }}
                                InputLabelProps={{
                                    className: "InputText"
                                }}
                                style={{ marginLeft: '0px' }}
                                error={!!mensPreguntasTrueFalse}
                                onBlur={() => {
                                    if (!preguntasTrueFalse) {
                                        setMensPreguntasTrueFalse("Este campo es obligatorio");
                                    } else {
                                        setMensPreguntasTrueFalse("");
                                    }
                                }}
                                onChange={(e) => {
                                    setPreguntasTrueFalse(e.target.value);
                                }}
                                type="text"
                                value={preguntasTrueFalse}
                            />
                            <p style={{ fontSize: 12, color: "red", visibility: mensPreguntasTrueFalse ? "visible" : "hidden" }} >
                                {mensPreguntasTrueFalse}
                            </p>
                        </FormControl>
                    </div>

                    {/*Radios*/}

                    <h2 style={{ marginLeft: '0px', marginTop: '-10px' }} className="s18 title_section c333"><b>Alternativas:</b></h2>
                    <div style={{ marginTop: '20px' }}></div>

                    <FormControl fullWidth>
                        <RadioGroup aria-label="gender" name="gender1" value={valueVT} onChange={handleChangeVT} style={{ float: 'left', marginLeft: '0px' }}>
                            <Grid item xs={12}>
                                <FormControlLabel style={{ float: 'left', marginLeft: '15px' }} value="1" control={<Radio id="radio_V" checked={valueVT == '1' ? true : false} onChange={() => setMensCheckedTrueFalse("")} />} label="Verdadero" />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControlLabel style={{ float: 'left', marginLeft: '15px' }} value="2" control={<Radio id="radio_F" checked={valueVT == '2' ? true : false} onChange={() => setMensCheckedTrueFalse("")} />} label="Falso" />
                            </Grid>
                        </RadioGroup>
                        <div style={{ marginTop: '-10px' }}></div>
                        <p style={{ float: 'left', marginLeft: '0px', fontSize: 12, color: "red", visibility: mensCheckedTrueFalse ? "visible" : "hidden" }} >
                            {mensCheckedTrueFalse}
                        </p>
                        <h2 style={{ marginLeft: '0px' }} className="s14 title_section c333"><b>Nota:</b> debe seleccionar la alternativa correcta.</h2>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <div style={{ marginLeft: '-8px' }} className="section section-b content" >
                        <FormControl variant="outlined" className={classes.formControl}>
                            <TextField id="justificaciontrue" label="Ingrese justificación..." className="InputText"
                                InputProps={{
                                    className: "InputText"
                                }}
                                InputLabelProps={{
                                    className: "InputText"
                                }}
                                maxRows="10"
                                minRows="6" variant="outlined" fullWidth
                                multiline={true}
                                error={!!mensJustificacionTrue}
                                onBlur={() => {
                                    if (justificacionTrue) {
                                        setMensJustificacionTrue("");
                                    } else {
                                        setMensJustificacionTrue("Este campo es obligatorio");
                                    }
                                }}
                                onChange={(e) => {
                                    setJustificacionTrue(e.target.value);
                                }}
                                type="text"
                                value={justificacionTrue}
                            />
                            <p style={{ fontSize: 12, color: "red", visibility: mensJustificacionTrue ? "visible" : "hidden" }} >
                                {mensJustificacionTrue}
                            </p>
                        </FormControl>
                    </div>
                </Grid>
            </Grid>

            <br></br>

            <div style={{ display: 'flex', justifyContent: 'end', gap: '1.5rem' }}>
                {isEditingQuestion &&
                    <Button
                        type="button"
                        onClick={ocultarPanelModification}
                        variant="contained"
                        color="secondary"
                        style={{ marginRight: '-7px' }}
                        className="button_cancel"
                    >
                        Cancelar
                    </Button>
                }
                <Button type="button" onClick={handleAddPreguntaTrueFalseClick} variant="contained" color="secondary" style={{ float: 'right', marginLeft: '15px' }} className="button_accept">
                    {isEditingQuestion ? "Aplicar" : "Añadir pregunta"}
                    {!isEditingQuestion && <AddCircleIcon />}
                </Button>
            </div>

            <br></br><br></br><br></br>
            <hr></hr>
            <br></br>
        </div>
    );

    const LimpiarFormPreguntasTrueFalse = () => {
        setPreguntasTrueFalse("");
        setMensPreguntasTrueFalse("");
        setHiddenPreguntasTrueFalse(true);

        setJustificacionTrue("");
        setMensJustificacionTrue("");

        setValueVT('');
    };

    const handleOnAddFormSeleccion = () => {
        setHiddenPreguntasTrueFalse(true);
        setHiddenPreguntaSeleccion(false);
    };

    const handleOnAddFormTrueOrFalso = () => {
        setHiddenPreguntaSeleccion(true);
        setHiddenPreguntasTrueFalse(false);

    };

    /**
     * @param {number} index
     */
    const deletePreguntaFromLocalDataset = function (index) {
        const copyRows = [...rows];
        copyRows.splice(index, 1);
        setRows(copyRows);
    };

    const deleteFromDB = async function () {
        try {
            setShowButtonSpinnerModal(true);
            const { pregunta, index } = preguntaBeingEdited;
            const response = await api.evaluacionesInstructor.eliminarPregunta({ token, preguntaId: pregunta.id });
            if (response.success) {
                showGenericSuccessNotification();
                deletePreguntaFromLocalDataset(index);
                closeModal();
            } else {
                throw new Error();
            }
        } catch (error) {
            enqueueSnackbar('Error al eliminar pregunta', {
                variant: 'error'
            });
        } finally {
            setShowButtonSpinnerModal(false);
        }
    };

    /**
     * @param {number} index
     * @param {api.PreguntaInstructor} pregunta
     * @todo manejar mejor los tipos. Para garantizar que se pase el tipo de pregunta correcto.
     */
    const handleOnRemove = function (index, pregunta) {
        if (!isEditingTest || evaluationEliminada) {
            deletePreguntaFromLocalDataset(index);
        } else {
            setPreguntaBeingEdited({
                index: index,
                pregunta: pregunta
            });
            setTipoModal('eliminar-pregunta');
            abrirModal();
        }
    };

    /**
     * @param {api.PreguntaEdición} newData
     * @todo verificar si no cambiaron las alternativas (fastDeepEqual). Si no cambiaron, ejecutar un API que solo cambie los detalles de la pregunta.
     */
    const aplicarModificacionPreguntaDB = async function (newData) {
        try {
            const { index, pregunta } = preguntaBeingEdited;
            await api.evaluacionesInstructor.remplazarAlternativas({
                token,
                evaluationId: claseData.evaluacion.id_evaluacion,
                enunciado: newData.enunciado,
                preguntaId: preguntaBeingEdited.pregunta.id,
                justificacion: newData.justificacion,
                nuevasAlternativas: newData.alternativas,
                tipo: newData.tipo
            });
            const preguntaUpdated = { ...pregunta, ...newData };
            rows.splice(index, 1, preguntaUpdated);
            setRows([...rows]);
            ocultarPanelModification();
        } catch (error) {
            console.log(error);
            enqueueSnackbar('Error al guardar pregunta');
        }
    };

    /**
     * @param {api.PreguntaEdición} newData
     */
    const aplicarModificacionPreguntaLocal = function (newData) {
        const { index } = preguntaBeingEdited;
        rows.splice(index, 1, newData);
        setRows([...rows]);
        ocultarPanelModification();
    };

    function ocultarPanelModification() {
        LimpiarFormPreguntasSeleccion();
        LimpiarFormPreguntasTrueFalse();
        setIsEditingQuestion(false);
    }

    /**
     * @param {api.PreguntaEdición} pregunta
     * @todo ¿mostrar panel de edición sobre la pregunta que se está editando?
     */
    const mostrarPanelModification = function (pregunta) {
        if (pregunta.tipo === TIPO_PREGUNTA.SELECCION_MULTIPLE) {
            setPreguntaSeleccion(pregunta.enunciado);
            setJustificacionSel(pregunta.justificacion);
            setListaAlternativas(pregunta.alternativas);
            // determinar el index de la opción correcta, y marcar el radio
            for (let i = 0, l = pregunta.alternativas.length; i < l; i++) {
                const a = pregunta.alternativas[i];
                if (a.correcto === 1) {
                    setRadioValor(i + 1);
                    break;
                }
            }
            handleOnAddFormSeleccion();
        } else if (pregunta.tipo === TIPO_PREGUNTA.VERDADERO_FALSO) {
            setPreguntasTrueFalse(pregunta.enunciado);
            setJustificacionTrue(pregunta.justificacion);
            let correctoVerdadero = false;
            pregunta.alternativas.forEach(a => {
                switch (a.descripcion) {
                    case 'Verdadero':
                        correctoVerdadero = (a.correcto === 1);
                        break;
                    case 'Falso':
                        correctoVerdadero = (a.correcto !== 1);
                        break;
                }
            });
            setValueVT(correctoVerdadero ? '1' : '2');
            handleOnAddFormTrueOrFalso();
        }
        setTimeout(() => {
            document.getElementById('boton-agregar-nueva').scrollIntoView();
        }, 0);
    };

    /**
     * @param {number} index
     * @param {api.PreguntaInstructor} pregunta
     * @todo manejar mejor los tipos. Para garantizar que se pase el tipo de pregunta correcto.
     */
    const handleModificarPregunta = function (index, pregunta) {
        const cloned = _.cloneDeep(pregunta);
        mostrarPanelModification(cloned);
        setIsEditingQuestion(true);
        setPreguntaBeingEdited({
            index: index,
            pregunta: pregunta
        });
    };

    {/* Listado de respuestas del profesor */ }

    const Respuestas = [];

    if (rows) {

        for (let i = rows.length - 1; i >= 0; i--) {

            Respuestas.push(

                <div key={rows[i].enunciado}>

                    <Grid container>

                        <Grid item xs={1} sm={1} md={1} lg={1} xl={1} > </Grid>
                        <Grid item xs={10} sm={10} md={10} lg={10} xl={10}  >
                            <div className="s18 title_section fontb c333" >
                                <h4>
                                    Pregunta número {i + 1} de {rows.length}
                                </h4>
                            </div>
                            <div className="card-body jtx">
                                <div>{rows[i].enunciado}</div>

                                {rows[i].alternativas.map((e, index) => {
                                    let check_value = (e.correcto == 1) ? true : false;
                                    return (

                                        <div key={index} className="d-flex" style={{ marginLeft: '30px' }}>
                                            <RadioGroup aria-label="gender" color="primary" name={"gender1" + index} value={check_value} >
                                                <FormControlLabel value={check_value} control={<Radio color="primary" id={"radio" + index} checked={check_value} />} label={e.descripcion} />
                                            </RadioGroup>

                                        </div>

                                    );
                                })}

                                <div className="s18 title_section fontb c333"> Justificación: </div>
                                <br></br>
                                <div className="card-body jtx"> {rows[i].justificacion} </div>

                            </div>

                        </Grid>
                        <Grid item xs={1} sm={1} md={1} lg={1} xl={1} > </Grid>
                    </Grid>

                    {showTestEditingUI && <>

                        <div style={{ display: 'flex', justifyContent: 'end' }}>
                            <div style={{ textAlign: 'right', marginBottom: '1rem' }}>
                                <Button type="button" onClick={() => handleModificarPregunta(i, rows[i])} variant="contained" color="secondary" style={{ marginLeft: '15px' }} className="button_cancel">Modificar</Button>
                            </div>
                            <div style={{ textAlign: 'right', marginBottom: '1rem' }}>
                                <Button type="button" onClick={() => handleOnRemove(i, rows[i])} variant="contained" color="secondary" style={{ marginLeft: '15px' }} className="button_cancel">Eliminar pregunta<DeleteForever /></Button>
                            </div>
                        </div>

                    </>}
                    <hr></hr>
                </div>
            );

        }

    }

    const [openPlus, setOpenPlus] = useState(false);
    const anchorRef = React.useRef(null);

    const handleToggle = () => {
        setOpenPlus((prevOpen) => !prevOpen);
    };

    const handleCloseplus = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpenPlus(false);
    };

    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpenPlus(false);
        }
    }

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = React.useRef(openPlus);
    React.useEffect(() => {
        if (prevOpen.current === true && openPlus === false) {
            anchorRef.current.focus();
        }
        prevOpen.current = openPlus;
    }, [openPlus]);

    function handleClickMisCursosTutor(event) {
        event.preventDefault();
        history.push("/my-courses-tutor");
    }

    const closeModal = function () {
        setOpenModal(false);
    };

    const abrirModal = function () {
        setShowButtonSpinnerModal(false);
        setOpenModal(true);
    };

    const eliminarVideo = function () {
        setShowButtonSpinnerModal(true);
        api.autoriaClases.eliminarRecurso({
            token,
            id_clase: classID,
            id_curso: courseID,
            id_usuario: data_user.id_usuario,
            tipo: 'video'
        })
            .then(({ data, status }) => {
                if (data.success) {
                    setVideoYaAdjunto(null);
                    enqueueSnackbar('Operación ejecutada con éxito', {
                        variant: 'success'
                    });
                    closeModal();
                    setCloseAlertaVideoSubidoExitosamente(false);
                    setVideoSubidoExitosamente(false);
                } else {
                    if (status === 200) {
                        enqueueSnackbar(data.mensaje || data.message, {
                            variant: 'warning'
                        });
                    } else {
                        throw new Error();
                    }
                }
            })
            .catch(() => {
                setShowButtonSpinnerModal(false);
                enqueueSnackbar('Error al eliminar recurso', {
                    variant: 'error'
                });
            });
    };

    const eliminarRecurso = function () {
        setShowButtonSpinnerModal(true);
        api.autoriaClases.eliminarRecurso({
            token,
            id_clase: classID,
            id_curso: courseID,
            id_usuario: data_user.id_usuario,
            tipo: 'zip'
        })
            .then(({ data, status }) => {
                if (data.success) {
                    setRecursoYaAdjunto(null);
                    enqueueSnackbar('Operación ejecutada con éxito', {
                        variant: 'success'
                    });
                    closeModal();
                } else {
                    if (status === 200) {
                        enqueueSnackbar(data.mensaje || data.message, {
                            variant: 'warning'
                        });
                    } else {
                        throw new Error();
                    }
                }
            })
            .catch(() => {
                setShowButtonSpinnerModal(false);
                enqueueSnackbar('Error al eliminar recurso', {
                    variant: 'error'
                });
            });
    };

    /**
     * @typedef {'eliminar-video' | 'eliminar-recurso' | 'eliminar-evaluation' | 'eliminar-pregunta'} TipoModal
     */

    const ModalContent = function () {
        if (tipoModal === 'eliminar-recurso') {
            return <>
                <div>
                    <p>¿Está seguro que desea eliminar el archivo asociado a esta clase?</p>
                    <p>Esta acción es definitiva.</p>
                </div>
            </>;
        } else if (tipoModal === 'eliminar-video') {
            return <>
                <p>¿Está seguro que desea eliminar el video asociado a esta clase?</p>
                <p>Esta acción es definitiva.</p>
            </>;
        } else if (tipoModal === 'eliminar-evaluation') {
            return <>
                <p>¿Está seguro que desea eliminar la evaluación asociada a esta clase?</p>
                <p>Esta acción es definitiva y no puede ser revertida.</p>
            </>;
        } else if (tipoModal === 'eliminar-pregunta') {
            return <>
                <p>¿Está seguro que desea eliminar la pregunta seleccionada?</p>
                <p>Esta acción es definitiva y no puede ser revertida.</p>
            </>;
        }
    };

    /**
     * @param {Object} params
     * @param {TipoModal} params.tipo
     * @returns {JSX.Element}
     */
    const ModalActions = function ({ tipo }) {
        if (tipo === 'eliminar-recurso') {
            return <>
                <Button onClick={closeModal} variant="contained" color="primary" className="button_cancel" disabled={showButtonSpinnerModal} >
                    Cancelar
                </Button>
                <Button onClick={eliminarRecurso} variant="contained" color="primary" className="button_delete" disabled={showButtonSpinnerModal} >
                    {showButtonSpinnerModal && <ButtonCircularProgress />}
                    Eliminar
                </Button>&nbsp;
            </>;
        } else if (tipo === 'eliminar-video') {
            return <>
                <Button onClick={closeModal} variant="contained" color="primary" className="button_cancel" disabled={showButtonSpinnerModal} >
                    Cancelar
                </Button>
                <Button onClick={eliminarVideo} variant="contained" color="primary" className="button_delete" disabled={showButtonSpinnerModal} >
                    {showButtonSpinnerModal && <ButtonCircularProgress />}
                    Eliminar
                </Button>&nbsp;
            </>;
        } else if (tipo === 'eliminar-evaluation') {
            return <>
                <Button onClick={closeModal} variant="contained" color="primary" className="button_cancel" disabled={showButtonSpinnerModal} >
                    Cancelar
                </Button>
                <Button onClick={eliminarEvaluation} variant="contained" color="primary" className="button_delete" disabled={showButtonSpinnerModal} >
                    {showButtonSpinnerModal && <ButtonCircularProgress />}
                    Eliminar
                </Button>&nbsp;
            </>;
        } else if (tipo === 'eliminar-pregunta') {
            return <>
                <Button onClick={closeModal} variant="contained" color="primary" className="button_cancel" disabled={showButtonSpinnerModal} >
                    Cancelar
                </Button>
                <Button onClick={deleteFromDB} variant="contained" color="primary" className="button_delete" disabled={showButtonSpinnerModal} >
                    {showButtonSpinnerModal && <ButtonCircularProgress />}
                    Eliminar
                </Button>&nbsp;
            </>;
        }
    };

    /**
     * @returns {string}
     */
    const getModalTitle = function () {
        switch (tipoModal) {
            case 'eliminar-recurso':
                return "Eliminar Archivo";
            case 'eliminar-video':
                return "Eliminar Video";
            case 'eliminar-evaluation':
                return "Eliminar Evaluación";
            case 'eliminar-pregunta':
                return "Eliminar Evaluación";
            default:
                return '';
        }
    };

    return (

        <div className="App rel">

            <Breadcrumbs aria-label="breadcrumb" className="breadcrumbs_link_inneret">

                <Link color="inherit" onClick={handleClickMisCursosTutor} className="breadcrumbs_link_inneret">
                    {strings.MIS_TUTORIAS}
                </Link>

                <Link color="inherit" onClick={() => { history.push('/edit-course/' + courseID); }} className="breadcrumbs_link_inneret">
                    Datos del Curso (Edición)
                </Link>

                <Typography color="textPrimary" className="breadcrumbs_link">
                    {moduleTitle}
                </Typography>

            </Breadcrumbs>

            <div className="tituloInicial rel">
                <h1 className="title_section s24 fontb c333">{moduleTitle}</h1>
            </div>
            <div>

                <div className="rel">
                    <div className={["rel", clasesLabelsError.globalContainer].join(' ')}>

                        {showMensajeTematica &&
                            <Alert severity="info" color="info" className={clasesFixEstilos.fixAlertaInfo} onClose={() => { setShowMensajeTematica(false); }} style={{
                                marginTop: '-30px', cursor: 'default', margin: '10px 8px', minHeight: '4rem', display: 'flex', alignItems: 'center'
                            }}>
                                <span>Tu curso debe ser compatible con las categorías y temáticas disponibles en e-napsis</span>
                            </Alert>
                        }

                        <br></br>
                        <br></br>

                        <div id="form_izq" className="espacio-addclass">
                            <Grid container item xs={12}>

                                <Grid item xs={12}  >
                                    <div className="section content">

                                        <div className="section content">
                                            <Grid item xs={12}>
                                                <div style={{ marginTop: '-25px' }}>

                                                    <FormControl variant="outlined" className={classes.formControl}>
                                                        <InputLabel id="select-tematica-label" className="InputTextApp">Seleccione una temática</InputLabel>
                                                        <Select
                                                            labelId="select-tematica-label"
                                                            id="select-tematica"
                                                            value={typeof tematica === 'number' ? tematica : ''}
                                                            label="Seleccione Temática"
                                                            error={!!errorTematica && guardarPressed}
                                                            onBlur={() => {
                                                                if (!tematica) {
                                                                    setErrorTematica("Este campo es obligatorio");
                                                                } else {
                                                                    setErrorTematica('');
                                                                }
                                                            }}
                                                            onChange={(e) => {
                                                                const value = /** @type {number} */(e.target.value);
                                                                setTematica(value);
                                                            }}
                                                            required
                                                            MenuProps={{
                                                                disableScrollLock: true
                                                            }}
                                                        >
                                                            <MenuItem value="" >
                                                                <em>Seleccionar</em>
                                                            </MenuItem>
                                                            {listaTematicas.map(t =>
                                                                <MenuItem key={t.id} value={t.id}>{t.nombre}</MenuItem>
                                                            )}
                                                        </Select>
                                                        <p className="conditionalError" style={{ fontSize: 12, color: "red", visibility: errorTematica && guardarPressed ? "visible" : "hidden" }} >
                                                            {errorTematica}
                                                        </p>
                                                    </FormControl>

                                                    <FormControl variant="outlined" className={classes.formControl}>
                                                        <InputLabel id="select-und-label" className="InputTextApp">Seleccione Unidad</InputLabel>
                                                        <Select
                                                            labelId="select-und-label"
                                                            id="select-und"
                                                            value={typeof unidadId === 'number' ? unidadId : ''}
                                                            label="Seleccione Unidad"
                                                            error={!!errorUnidad && guardarPressed}
                                                            onBlur={() => {
                                                                if (!unidadId && unidadId !== 0) {
                                                                    setErrorUnidad("Este campo es obligatorio");
                                                                } else {
                                                                    setErrorUnidad("");
                                                                }
                                                            }}
                                                            onChange={(e) => {
                                                                const value = /** @type {number} */(e.target.value);
                                                                setUnidadId(value);
                                                            }}
                                                            required
                                                            MenuProps={{
                                                                disableScrollLock: true
                                                            }}
                                                        >
                                                            <MenuItem value="" >
                                                                <em>Seleccionar</em>
                                                            </MenuItem>
                                                            {unidadApi.map(unidadValues =>
                                                                <MenuItem key={unidadValues.id} value={unidadValues.id}>{unidadValues.nombre}</MenuItem>
                                                            )}
                                                        </Select>
                                                        <p className="conditionalError" style={{ fontSize: 12, color: "red", visibility: errorUnidad && guardarPressed ? "visible" : "hidden" }} >
                                                            {errorUnidad}
                                                        </p>
                                                    </FormControl>

                                                    <FormControl variant="outlined" className={classes.formControl}>
                                                        {/*
                                                        // @ts-ignore */}
                                                        <Hidden smUp>
                                                            <Button fullWidth style={{ float: 'right', marginTop: '-15px' }} variant="outlined" color="primary" onClick={handleButtonAgregarUnidad} >
                                                                Crear unidad &nbsp;&nbsp;
                                                                <AddCircleIcon />
                                                            </Button>
                                                        </Hidden>
                                                    </FormControl>
                                                    {/*
                                                    // @ts-ignore */}
                                                    <Hidden xsDown>
                                                        <Button style={{ marginLeft: '10px', marginTop: '-15px' }} variant="outlined" color="primary" onClick={handleButtonAgregarUnidad} >
                                                            Crear unidad &nbsp;&nbsp;
                                                            <AddCircleIcon />
                                                        </Button>
                                                    </Hidden>

                                                    <Dialog open={openAgregarUnidad} onClose={handleCloseCrearUnidad} aria-labelledby="customized-dialog-title" disableScrollLock={true}>
                                                        <DialogTitle id="form-dialog-title" onClose={handleCloseCrearUnidad} className="modal_title">Crear unidad</DialogTitle>
                                                        <DialogContent dividers className="modal_content">
                                                            <TextField
                                                                autoFocus
                                                                id="title-unit"
                                                                label="Titulo"
                                                                type="text"
                                                                fullWidth
                                                                variant="outlined"
                                                                className="InputText"
                                                                InputProps={{
                                                                    className: "InputText"
                                                                }}
                                                                InputLabelProps={{
                                                                    className: "InputText"
                                                                }}
                                                            />
                                                            &nbsp; &nbsp;  &nbsp; &nbsp;

                                                            <TextField
                                                                id="desc-unit"
                                                                label="Descripción"
                                                                type="text"
                                                                fullWidth
                                                                variant="outlined"
                                                                className="InputText"
                                                                InputProps={{
                                                                    className: "InputText"
                                                                }}
                                                                InputLabelProps={{
                                                                    className: "InputText"
                                                                }}
                                                            />
                                                        </DialogContent>
                                                        <DialogActions className="modal_footer">
                                                            <Button onClick={handleCloseCrearUnidad} variant="contained" color="primary" className="button_cancel">
                                                                Cancelar
                                                            </Button>
                                                            <Button onClick={handleGuardarUnidad} variant="contained" color="primary" className="button_accept" disabled={isSavingUnidad || !openAgregarUnidad}>
                                                                {isSavingUnidad && <ButtonCircularProgress />}
                                                                Guardar
                                                            </Button>&nbsp;
                                                        </DialogActions>
                                                    </Dialog>
                                                </div>
                                            </Grid>
                                        </div>

                                        <div></div>

                                        <br></br>
                                        <Grid item xs={12}>
                                            <div style={{ marginTop: '10px' }} >
                                                <FormControl variant="outlined" className={classes.formControl}>
                                                    <TextField id="class_title" label="Título de la clase" variant="outlined" fullWidth className="InputText"
                                                        InputProps={{
                                                            className: "InputText"
                                                        }}
                                                        InputLabelProps={{
                                                            className: "InputText"
                                                        }}
                                                        error={!!errorClaseTitle && guardarPressed}
                                                        onBlur={() => {
                                                            if (!claseTitle) {
                                                                setErrorClaseTitle("Este campo es obligatorio");
                                                            } else {
                                                                setErrorClaseTitle("");
                                                            }
                                                        }}
                                                        onChange={(e) => {
                                                            setClaseTitle(e.target.value);
                                                        }}
                                                        type="text"
                                                        value={claseTitle}
                                                        required
                                                    />
                                                    <p className="conditionalError" style={{ fontSize: 12, color: "red", visibility: errorClaseTitle && guardarPressed ? "visible" : "hidden" }} >
                                                        {errorClaseTitle}
                                                    </p>

                                                </FormControl>
                                            </div>

                                        </Grid>

                                        <Grid item xs={12}>
                                            <div style={{ marginTop: '0px' }} >
                                                <FormControl variant="outlined" className={classes.formControl}>
                                                    <TextField id="description" label="Descripción" className="InputText"
                                                        InputProps={{
                                                            className: "InputText"
                                                        }}
                                                        InputLabelProps={{
                                                            className: "InputText"
                                                        }}
                                                        maxRows="10"
                                                        minRows="6" variant="outlined" fullWidth
                                                        multiline={true}

                                                        error={!!errorDescription && guardarPressed}
                                                        onBlur={() => {
                                                            if (descriptionClase) {
                                                                setErrorDescription("");
                                                            } else {
                                                                setErrorDescription("Este campo es obligatorio");
                                                            }
                                                        }}
                                                        onChange={(e) => {
                                                            setDescriptionClase(e.target.value);
                                                        }}
                                                        type="text"
                                                        value={descriptionClase}
                                                        required
                                                    />
                                                    <p className="conditionalError" style={{ fontSize: 12, color: "red", visibility: errorDescription && guardarPressed ? "visible" : "hidden" }} >
                                                        {errorDescription}
                                                    </p>
                                                </FormControl>
                                            </div>
                                        </Grid>

                                        <div>
                                            <Grid item xs={12}>
                                                <FormControl variant="outlined" className={classes.formControl} style={{ marginTop: '0px' }}>
                                                    <InputLabel id="select-tipoclass-label" className="InputTextApp">Tipo de clase</InputLabel>
                                                    <Select
                                                        labelId="select-tipoclass-label"
                                                        id="select-tipoclass"
                                                        value={typeof tipoClase === 'number' ? tipoClase : ''}
                                                        label="Seleccione Unidad"
                                                        error={!!errorTipoClase && guardarPressed}
                                                        onClick={() => {
                                                            isClassEdit && enqueueSnackbar('El tipo de prueba no puede ser cambiado para una clase existente', {
                                                                variant: 'info'
                                                            });
                                                        }}
                                                        onBlur={() => {
                                                            if (!tipoClase && tipoClase !== 0) {
                                                                setErrorTipoClase("Este campo es obligatorio");
                                                            } else {
                                                                setErrorTipoClase("");
                                                            }
                                                        }}
                                                        onChange={(e) => {
                                                            const value = /** @type {number} */(e.target.value);
                                                            setTipoClase(value);
                                                            setErrorTipoClase('');
                                                        }}
                                                        className={isClassEdit ? 'cursor-not-allowed' : ''}
                                                        disabled={isClassEdit}
                                                        MenuProps={{
                                                            disableScrollLock: true
                                                        }}
                                                    >
                                                        <MenuItem value="" >
                                                            <em>Seleccionar</em>
                                                        </MenuItem>
                                                        <MenuItem value={1}>Video</MenuItem>
                                                        <MenuItem value={2}>Recursos</MenuItem>
                                                        <MenuItem value={3}>Test</MenuItem>
                                                        <MenuItem value={4}>Evaluación final</MenuItem>
                                                    </Select>
                                                    <p className="conditionalError" style={{ fontSize: 12, color: "red", visibility: errorTipoClase && guardarPressed ? "visible" : "hidden" }} >
                                                        {errorTipoClase}
                                                    </p>
                                                </FormControl>
                                            </Grid>
                                        </div>

                                        <div hidden={[TIPO_CLASE.TEST, TIPO_CLASE.TEST_FORMATIVO].indexOf(tipoClase) === -1}>
                                            <h2 className="s22 title_section fontb c333">Datos de evaluación:</h2>
                                            <Grid item xs={12} style={{ marginTop: '1rem' }}>
                                                <div>
                                                    <FormControl variant="outlined" className={classes.formControl} >
                                                        <TextField id="test_title" variant="outlined" fullWidth className="InputText"
                                                            label={tipoClase === TIPO_CLASE.TEST ? "Título del test" : "Título de la evaluación final"}
                                                            InputProps={{
                                                                className: "InputText"
                                                            }}
                                                            InputLabelProps={{
                                                                className: "InputText"
                                                            }}
                                                            error={!!errorTestTitle}
                                                            onBlur={() => {
                                                                if (!testTitle) {
                                                                    setErrorTestTitle("Este campo es obligatorio");
                                                                } else {
                                                                    setErrorTestTitle("");
                                                                }
                                                            }}
                                                            onChange={(e) => {
                                                                setTestTitle(e.target.value);
                                                            }}
                                                            type="text"
                                                            value={testTitle}
                                                            required
                                                        />
                                                        <p style={{ fontSize: 12, color: "red", visibility: errorTestTitle ? "visible" : "hidden" }} >
                                                            {errorTestTitle}
                                                        </p>
                                                    </FormControl>
                                                </div>
                                            </Grid>
                                        </div>

                                        <div style={{ marginLeft: '10px', cursor: 'default' }} hidden={[TIPO_CLASE.TEST, TIPO_CLASE.TEST_FORMATIVO].indexOf(tipoClase) === -1}>

                                            {/* sección con el botón agregar pregunta */}
                                            {showTestEditingUI && <>

                                                <h2 style={{ marginLeft: '0px' }} className="s22 title_section fontb c333">{isEditingTest && !evaluationEliminada ? "Nueva pregunta" : "Sección de preguntas"}:</h2>
                                                <div id="boton-agregar-nueva" style={{ marginTop: '1rem' }}>
                                                    <HtmlTooltip
                                                        title={
                                                            <React.Fragment>
                                                                <Typography color="inherit">Agregar pregunta</Typography>
                                                                <em>{"Presione "}</em> <b>{'Click'}</b> <em>{"para agregar una pregunta de selección o pregunta de verdadero o falso"}</em>.
                                                            </React.Fragment>
                                                        }
                                                    >
                                                        <Fab
                                                            ref={anchorRef}
                                                            aria-controls={openPlus ? 'menu-list-grow' : undefined}
                                                            aria-haspopup="true"
                                                            onClick={handleToggle}
                                                        >
                                                            <AddIcon />
                                                        </Fab>
                                                    </HtmlTooltip>

                                                    <Popper open={openPlus} anchorEl={anchorRef.current} role={undefined} transition /*disablePortal*/>
                                                        {({ TransitionProps, placement }) => (
                                                            <Grow
                                                                {...TransitionProps}
                                                                style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                                                            >
                                                                <Paper className="botonflotando">
                                                                    <ClickAwayListener onClickAway={handleCloseplus}>
                                                                        <MenuList autoFocusItem={openPlus} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                                                                            <MenuItem onClick={handleOnAddFormSeleccion}>Pregunta de selección</MenuItem>
                                                                            <MenuItem onClick={handleOnAddFormTrueOrFalso}>Pregunta verdadero o falso</MenuItem>
                                                                        </MenuList>
                                                                    </ClickAwayListener>
                                                                </Paper>
                                                            </Grow>
                                                        )}
                                                    </Popper>
                                                </div>

                                            </>}

                                            <div hidden={hiddenPreguntaSeleccion}>
                                                {formPreguntasSeleccion}
                                            </div>

                                            <div hidden={hiddenPreguntasTrueFalse}>
                                                {formPreguntasTrueFalse}
                                            </div>

                                            {!!Respuestas &&
                                                <div>
                                                    <h2 className="s22 title_section fontb c333" style={{ margin: '2rem 0', display: 'flex' }}>
                                                        {(!isClassEdit || !rows?.length || evaluationEliminada)
                                                            ? "Listado de preguntas"
                                                            : <>
                                                                <span>Evaluación previamente guardada</span>
                                                                <div style={{ marginLeft: '0.5rem', display: 'flex', alignItems: 'center', color: 'var(--primary-with-transparency)' }}>
                                                                    <button style={{ borderRadius: '4px', display: 'flex', padding: '2px 4px', marginTop: '2px', marginBottom: '-2px' }} onClick={handleEliminarEvaluationClick} title={"Eliminar evaluación"} className="button_basurero">
                                                                        <ClearIcon />
                                                                    </button>
                                                                    <span>(eliminar evaluación)</span>
                                                                    <button style={{ borderRadius: '4px', display: 'flex', padding: '2px 4px', marginLeft: '0.5rem' }} onClick={handleEditarEvaluationClick} title={"Eliminar evaluación"} className="button_basurero">
                                                                        <EditOutlinedIcon />
                                                                    </button>
                                                                    <span>(editar)</span>
                                                                </div>
                                                            </>
                                                        }
                                                    </h2>
                                                </div>
                                            }
                                            {Respuestas}
                                        </div>

                                        {!videoYaAdjunto?.url &&
                                            <div className="section content" id="id_dropzon" hidden={tipoClase !== TIPO_CLASE.VIDEO}>
                                                <HtmlTooltip
                                                    title={
                                                        <React.Fragment>
                                                            <Typography color="inherit">Adjuntar video</Typography>
                                                            <em>{"Puede adjuntar un solo video por clase, debe ser en formato"}</em> <b>{'mp4'}</b>.
                                                        </React.Fragment>
                                                    }
                                                >
                                                    <FormControl variant="outlined" className={classes.formControl} >

                                                        <Dropzone
                                                            timeout={3600000}
                                                            onChangeStatus={handleChangeStatus}
                                                            //accept="video/mp4"
                                                            getUploadParams={getUploadParams}
                                                            LayoutComponent={Layout}
                                                            maxFiles={1}
                                                            multiple={false}
                                                            canCancel={false}
                                                            canRemove={true}
                                                            inputContent={<span style={{ color: 'var(--color-text-form-label)' }} key="etiqueta-drop-zone">Adjuntar video</span>}
                                                            styles={{
                                                                dropzone: { width: '100%', height: 50, alignItems: 'center' },
                                                                dropzoneActive: { borderColor: "green" }
                                                            }}
                                                        />
                                                        <p style={{ fontSize: 12, color: "red", visibility: errorVideo ? "visible" : "hidden" }} >
                                                            {errorVideo}
                                                        </p>

                                                    </FormControl>
                                                </HtmlTooltip>
                                            </div>
                                        }

                                        {tipoClase === TIPO_CLASE.VIDEO &&
                                            <Alert severity="info" color="info" className={clasesFixEstilos.fixAlertaInfo} style={{
                                                marginTop: '-30px', cursor: 'default', margin: '10px 8px', minHeight: '4rem', display: 'flex', alignItems: 'center', minWidth: '100%', marginBottom: '20px'
                                            }}>
                                                <span>INFORMACIÓN: cualquier cambio o carga de video que haga en la clase, tomará tiempo en ser aplicado y procesado. Y el tiempo dependerá del tamaño del video subido.</span>
                                            </Alert>
                                        }

                                        {tipoClase === TIPO_CLASE.VIDEO && videoSubidoExitosamente && !closeAlertaVideoSubidoExitosamente &&
                                            <Alert severity="success" color="success" className={clasesFixEstilos.fixAlertaSuccess} onClose={() => { setCloseAlertaVideoSubidoExitosamente(true); }} style={{
                                                marginTop: '-30px', cursor: 'default', margin: '10px 8px', minHeight: '4rem', display: 'flex', alignItems: 'center', minWidth: '100%', marginBottom: '20px'
                                            }}>
                                                <span>Su video ha sido subido exitosamente!<br />NOTA: el video puede demorar un tiempo en ser procesado y estar disponible para su visualización. Sin embargo, ya ha sido subido y continuará siendo procesado aunque salga de esta página.</span>
                                            </Alert>
                                        }

                                        {!videoYaAdjunto?.url && videoStatus != 'removed' && !!video?.archivo_video &&
                                            <div hidden={tipoClase !== TIPO_CLASE.VIDEO}>
                                                <div className="player-wrapper" hidden={hiddenReproductor}>
                                                    <br></br>
                                                    <br></br>
                                                    <h3 >&nbsp;&nbsp;Previsualización de video:</h3>
                                                    <div style={{ marginTop: '30px' }}></div>
                                                    <Grid item xs={12}>
                                                        <div>
                                                            <FormControl variant="outlined" className={classes.formControlReactPlayer}>
                                                                <ReactPlayer
                                                                    url={url_preview}
                                                                    width='50%'
                                                                    height='50%'
                                                                    controls
                                                                    playing={disableReproductor}
                                                                    volume={0.2}
                                                                    style={{ maxHeight: '640px' }}
                                                                />
                                                            </FormControl>
                                                        </div>
                                                    </Grid>
                                                    <div style={{ marginTop: '20px' }}></div>
                                                </div>
                                            </div>
                                        }

                                        {videoYaAdjunto?.url &&
                                            <div style={{ display: 'flex', justifyContent: 'center', marginRight: '-8px', marginLeft: '8px', marginBottom: '2rem' /** margenes izquierdo y derecho como workaround a problemas de estilo en el layout (mientras se mejora de fondo) */ }}>
                                                <VideoVistaEditar recurso={videoYaAdjunto} clase={claseData} handleDeleteClick={() => {
                                                    setTipoModal('eliminar-video');
                                                    abrirModal();
                                                }} />
                                            </div>
                                        }

                                        <Grid item xs={12} style={{ cursor: 'default' }} >
                                            <div className="section content" hidden={tipoClase !== TIPO_CLASE.ZIP}>
                                                <FormControl variant="outlined" className={classes.formControl}>
                                                    <h3 className="title_section" >
                                                        Adjuntar recursos:
                                                    </h3>
                                                    <div style={{ marginTop: '20px' }} >
                                                        <HtmlTooltip
                                                            title={
                                                                <React.Fragment>
                                                                    <Typography color="inherit">Adjuntar archivos</Typography>
                                                                    <em>{"Puede adjuntar varios archivos presionando"}</em> <b>{'CTR + Click'}</b> <u>{'en cada archivo'}</u>.
                                                                </React.Fragment>
                                                            }
                                                        >
                                                            <Button
                                                                variant="contained"
                                                                component="label"
                                                                className="button_accept"
                                                                id="recurso"
                                                                onBlur={() => {
                                                                    setErrorFilesZip("");
                                                                }}
                                                                onClick={handleResourceAttachClick}
                                                            >
                                                                <AttachFileIcon style={{ marginRight: '0.3rem' }} />
                                                                <input hidden type="file" multiple onChange={handleResourceFileSelectionChange}
                                                                    style={{ color: "white", backgroundColor: "transparent" }}
                                                                />
                                                                Adjuntar archivos
                                                            </Button>
                                                        </HtmlTooltip>

                                                    </div>
                                                    <div style={{ marginTop: '5px' }}>
                                                        {nameAdjunto}
                                                    </div>
                                                    <p className="conditionalError" style={{ fontSize: 12, color: "red", visibility: errorFilesZip ? "visible" : "hidden" }} >
                                                        {errorFilesZip}
                                                    </p>
                                                </FormControl>

                                                {recursoYaAdjunto?.url &&
                                                    <div style={{ padding: '1rem', paddingTop: 0, paddingLeft: '0.5rem' }}>
                                                        <ListaRecursosExistentes recurso={recursoYaAdjunto} clase={claseData} handleDeleteClick={() => {
                                                            setTipoModal('eliminar-recurso');
                                                            abrirModal();
                                                        }} />
                                                    </div>
                                                }
                                            </div>
                                        </Grid>

                                        {/* panel modal con status de carga (al subir archivos y videos) */}
                                        <div id="cargando" className={classes3.root} >
                                            <Dialog
                                                open={openUploadingStatusPanel}
                                                aria-labelledby="alert-dialog-title"
                                                aria-describedby="alert-dialog-description"
                                            >
                                                <DialogContent className="CentrarLoading">
                                                    <Box position="relative" display="inline-flex">
                                                        <CircularProgress className="CircularProgress" />
                                                        <Box top={0} left={0} bottom={0} right={0} position="absolute" display="flex" alignItems="center" justifyContent="center" >
                                                            <Typography variant="caption" component="div" color="textPrimary">{`${Math.round(progress)}%`}</Typography>
                                                        </Box>
                                                    </Box>
                                                </DialogContent>
                                            </Dialog>
                                        </div>

                                        <FormControl variant="outlined" className={classes.formControl}>
                                            {/*
                                            // @ts-ignore */}
                                            <Hidden smUp>
                                                <div className="section section-b content" style={{ marginTop: '-10px', display: 'flex', gap: '15px', flexDirection: 'column' }} >
                                                    <Button fullWidth type="button" onClick={cancel} variant="contained" color="secondary" className="button_cancel">
                                                        Cancelar
                                                    </Button>
                                                    <Button fullWidth id="save_class" type="button" disabled={isSavingClase || isSavingUnidad || isLoadingVideo} onClick={handleGuardarButton} variant="contained" color="primary" className={isSavingClase || isSavingUnidad || isLoadingVideo ? 'button_accept_disable' : 'button_accept'}>
                                                        {isSavingClase && <ButtonCircularProgress />}
                                                        {isLoadingVideo ? "Cargando video..." : "Guardar clase"}
                                                    </Button>
                                                </div>
                                            </Hidden>
                                            {/*
                                            // @ts-ignore */}
                                            <Hidden xsDown>
                                                <div className="section section-b content" style={{ marginTop: '0px' }}>
                                                    <Button id="save_class" type="button" disabled={isSavingClase || isSavingUnidad || isLoadingVideo} onClick={handleGuardarButton} variant="contained" color="primary" style={{ float: 'right', marginLeft: '15px' }} className={isSavingClase || isSavingUnidad || isLoadingVideo ? 'button_accept_disable' : 'button_accept'}>
                                                        {isSavingClase && <ButtonCircularProgress />}
                                                        {isLoadingVideo ? "Cargando video..." : "Guardar clase"}
                                                    </Button>
                                                    &nbsp;&nbsp;&nbsp;
                                                    <Button type="button" onClick={cancel} variant="contained" color="secondary" style={{ float: 'right', marginLeft: '15px' }} className="button_cancel">Cancelar</Button>
                                                    &nbsp;&nbsp;
                                                </div>
                                            </Hidden>
                                        </FormControl>

                                    </div>

                                </Grid>

                            </Grid>
                        </div>

                    </div>

                    {/* modal multi-propósito */}
                    <div>
                        <Dialog open={openModal} onClose={closeModal} aria-labelledby="customized-dialog-title" disableScrollLock={true} style={{ cursor: 'default' }}>
                            <DialogTitle id="form-dialog-title" onClose={closeModal} className="modal_title" >{getModalTitle() || <span>&nbsp;</span>}</DialogTitle>
                            <DialogContent className="modal_content">
                                <ModalContent />
                            </DialogContent>
                            <DialogActions className="modal_footer">
                                <ModalActions tipo={tipoModal} />
                            </DialogActions>
                        </Dialog>
                    </div>

                    {showLoading &&
                        <LoadingPanel />
                    }

                </div>

            </div>

        </div>

    );
}
