import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Pagination from '@material-ui/lab/Pagination';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { useSnackbar } from "notistack";

import TarjetaEcampus from "../componentes/TarjetaEcampus";
import ModalVideoPlayer from "../componentes/ModalVideoPlayer";

import * as CustomErrors from '../util/custom.errors';
import commonIO from '../util/common.io';
import { theme as tema } from "../util/theme";
import api, { isStructureUpdated } from "../lib/api";
import i18n from "../lib/i18n";
import utils from "../util/utils";
import { TagStyles } from "../lib/constants";
import routeHelper from "../util/routeHelper";

const config = {
    /** @type {'pages' | 'load-more'} */
    paginationMode: 'load-more'
};

const useStyles = makeStyles({
    "category": {
        cursor: 'pointer',
        whiteSpace: 'nowrap',
        padding: '0.1rem 0.5rem',
        "&:hover": {
            backgroundColor: '#80808036'
        }
    },
    "selected": {
        backgroundColor: '#d8721873',
        boxShadow: '-4px 0px 0px 0px #d87218',
        "&:hover": {
            backgroundColor: '#d8721873'
        }
    }
});

/**
 * @param {Object} params
 * @param {number} params.initialCategory
 * @param {(categoryId: number) => void} params.onCategoryChange
 */
const CategoryPanel = ({ onCategoryChange, initialCategory }) => {

    const { enqueueSnackbar } = useSnackbar();
    const [categories, setCategories] = useState(/** @type {api.Category[]} */([]));
    const [selected, setSelected] = useState(/** @type {number} */(initialCategory));
    const classes = useStyles();

    useEffect(() => {
        api.getCategories()
            .then(data => {
                setCategories(data.data);
                if (!data.success) {
                    throw new Error(data.mensaje || data.message);
                }
            })
            .catch(function (error) {
                console.log("Error: ", error);
                enqueueSnackbar("Error en la descarga de categorías", {
                    variant: 'error'
                });
            });
    }, []);

    /**
     * @param {number} categoryId
     */
    const handleCategoryClick = function (categoryId) {
        if (categoryId === selected) return;
        setSelected(categoryId);
        onCategoryChange(categoryId);
    };

    return (<>
        <div style={{ cursor: 'default', padding: '0.7rem' }}>
            <h3>Categorías</h3>
            <hr style={{ opacity: 0.5 }} />
            {categories && categories.map(cat => {
                return <>
                    <div
                        key={cat.id}
                        className={classes.category + (cat.id === selected ? ' ' + classes.selected : '')}
                        onClick={() => {
                            handleCategoryClick(cat.id);
                        }}
                    >
                        {cat.nombre}
                    </div>
                </>;
            })}
        </div>
    </>);
};

/** @type {string} */
let lastRequest;

export default function Courses(props) {
    /** @type {number} */
    let cateId = null;
    try {
        cateId = parseInt(props.match.params["cateid"]);
        if (!Number.isSafeInteger(cateId)) cateId = null;
    } catch {/* empty */}
    const initialCategory = cateId;
    const { enqueueSnackbar } = useSnackbar();
    const theme = useTheme();
    const smallScreen = useMediaQuery(theme.breakpoints.down('xs'));

    const [cursos, setCursos] = useState(/** @type {api.Curso[]} */([]));
    const [isLoading, setIsLoading] = useState(true);
    const [totalPaginas, setTotalPaginas] = useState(0);
    const [paginaActual, setPaginaActual] = useState(0);
    const [cursoVideoModal, setCursoVideoModal] = useState(/** @type {api.Curso} */(null));
    const currentCategory = useRef(initialCategory);
    const history = useHistory();

    /**
     * @param {api.Curso} curso
     */
    const handleShowVideo = function (curso) {
        if (!curso?.video) return;
        setCursoVideoModal(curso);
    };

    useEffect(() => {
        document.title = "Búsqueda de cursos - e-Napsis";
        getCursosFiltrados(currentCategory.current, 1);
    }, []);

    const [checked/*, setChecked*/] = useState(tema.current === "dark" ? true : false); // para tema oscuro

    useEffect(() => {
        document
            .getElementsByTagName("HTML")[0]
            .setAttribute("data-theme", tema.current);
    }, [checked]);

    /**
     * @param {number} categoryId
     * @param {number} nPag
     */
    const getCursosFiltrados = function (categoryId, nPag) {
        setIsLoading(true);
        const request = "a" + nPag;
        lastRequest = request;
        /** @type {Promise<api.SuccessResponse<api.CursosPaginados>>} */
        let promise;
        if (categoryId === null) {
            promise = api.getCursosFiltrados({
                query: "a", nPag,
                id_pais: i18n.getCountry().id_pais
            });
        } else {
            promise = api.getCursosCategory({
                categoryId, nPag,
                id_pais: i18n.getCountry().id_pais
            });
        }
        promise.then(data => {
                if (categoryId !== currentCategory.current) return;
                if (request !== lastRequest) {
                    throw new CustomErrors.ObsoleteRequest();
                }
                if (data.success === true) {
                    let cursosNuevos = data.data.cursos;
                    if (config.paginationMode === 'load-more' && nPag > 1) {
                        setCursos([...cursos, ...cursosNuevos]);
                    } else {
                        setCursos(cursosNuevos);
                    }
                    setPaginaActual(data.data.pagina_actual);
                    setTotalPaginas(data.data.numero_de_paginas);
                } else {
                    setCursos([]);
                    setPaginaActual(0);
                    setTotalPaginas(0);
                    if (data.success === false) {
                        // FIX(api): cuando no hay datos no trae "success". Debería ser success = true.
                        console.log("Error: ", data.mensaje);
                        enqueueSnackbar("Error en la descarga de cursos", {
                            variant: 'error'
                        });
                    }
                }
                setIsLoading(false);
            })
            .catch(error => {
                if (error instanceof CustomErrors.ObsoleteRequest) return;
                enqueueSnackbar('No se encontraron datos', {
                    variant: 'error'
                });
                setIsLoading(false);
                setPaginaActual(0);
                setTotalPaginas(0);
            });
    };

    const loadingAnimation =
        <div key='loading' style={{ minHeight: '25rem', paddingTop: '7rem', width: '100%', textAlign: 'center' }}>
            <CircularProgress className="CircularProgress" />
        </div>;

    const mensajeNoHayCursos =
        <div key='mensaje_no_cursos' className='title_section s24' style={{ minHeight: '25rem', paddingTop: '7rem', width: '100%', textAlign: 'center' }}>
            No se encontraron cursos
        </div>;

    const courseList = [];
    if (cursos && cursos.length) {
        for (let i = 0; i < cursos.length; i++) {
            courseList.push(
                <Grid container item xs={12} sm={12} md={6} lg={4} xl={4} direction="column" key={cursos[i].id} className='coursehome tarjeta-grid-item' >
                    <Grid container alignContent="center" alignItems="center" className='tarjeta-ecampus-wrapper'>
                        <TarjetaEcampus
                            image={utils.getImagenCurso(cursos[i])}
                            text1={commonIO.shortenText(cursos[i].titulo, smallScreen ? 60 : 72)}
                            text2={cursos[i].nombre_instructor}
                            href={"/course/" + cursos[i].id + "/" + "Inicio"}
                            price={cursos[i].precio}
                            showTag={isStructureUpdated(cursos[i])}
                            tagOptions={TagStyles.TAG_ACTUALIZADO}
                            curso={cursos[i]}
                            hasVideo={!!cursos[i].video}
                            handleVideoPreview={() => {
                                handleShowVideo(cursos[i]);
                            }}
                            showBuyButtons={true}
                        />
                    </Grid>
                </Grid>
            );
        }
    } else {
        courseList.push(mensajeNoHayCursos);
    }

    const handlePageChange = (event, newPage) => {
        setPaginaActual(newPage);
        getCursosFiltrados(currentCategory.current, newPage);
    };

    const handleLoadMore = () => {
        setPaginaActual(paginaActual + 1);
        getCursosFiltrados(currentCategory.current, paginaActual + 1);
    };

    /**
     * @param {number} categoryId
     */
    const handleCategoryChange = function (categoryId) {
        currentCategory.current = categoryId;
        setPaginaActual(1);
        getCursosFiltrados(categoryId, 1);
        history.push(routeHelper.rutaCursosCategory(categoryId));
    };

    return (<>
        <div style={{ padding: '0 2rem' }}>

            <div style={{ marginTop: '90px' }}>

                <div className="home-page">
                    <div className="extra-block">
                        <h2 className="title_section s24 fontb">Cursos</h2>

                        <div style={{ display: 'flex' }}>

                            <CategoryPanel initialCategory={initialCategory} onCategoryChange={handleCategoryChange} />

                            <div className="courses" style={{ marginBottom: '4rem', marginTop: '0', flexGrow: 1 }}>

                                <Grid container item direction="row" style={{ minHeight: '6rem' }}>
                                    {isLoading && (paginaActual < 2 || config.paginationMode === 'pages') && loadingAnimation || courseList}
                                </Grid>

                                {!isLoading && config.paginationMode === 'pages' &&
                                    <Grid container item direction="row" >
                                        <Grid container item direction="column" alignItems="center" justifyContent="center" key="paginacion">
                                            <Pagination variant="outlined" color="primary" count={totalPaginas} page={paginaActual} onChange={handlePageChange} />
                                        </Grid>
                                    </Grid>
                                }

                                {!isLoading && config.paginationMode === 'load-more' && totalPaginas > paginaActual &&
                                    <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '1rem' }}>
                                        <button
                                            style={{ display: 'flex', alignItems: 'center', paddingRight: '0.6rem' }}
                                            onClick={() => {
                                                handleLoadMore();
                                            }}
                                        >
                                            <KeyboardArrowDownIcon />Ver más
                                        </button>
                                    </div>
                                }

                                {isLoading && config.paginationMode === 'load-more' && paginaActual > 1 &&
                                    <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '1rem' }}>
                                        <CircularProgress className="CircularProgress" size={30} />
                                    </div>
                                }

                            </div>

                        </div>

                    </div>
                </div>

            </div>

            <ModalVideoPlayer video={cursoVideoModal?.video} open={!!cursoVideoModal} curso={cursoVideoModal} handleClose={() => { setCursoVideoModal(null); }} />

        </div>
    </>);
}
