import React, { useEffect, useState } from "react";
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { useHistory } from "react-router";
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import { Hidden } from "@material-ui/core";
import Pagination from '@material-ui/lab/Pagination';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import DialogContent from '@material-ui/core/DialogContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import Container from '@material-ui/core/Container';

import TarjetaEcampus from "../componentes/TarjetaEcampus.js";

import api, { isStructureUpdated } from "../lib/api";
import i18n, { strings } from "../lib/i18n";
import utils from "../util/utils.js";
import { TagStyles } from "../lib/constants.js";


const useStyles3 = makeStyles((theme) => ({
    root: {
        display: 'flex',
        '& > * + *': {
            marginLeft: theme.spacing(2)
        }
    }
}));

function CategoryPage(props) {

    const cateID = props.match.params.cateid;
    const catename = props.match.params.catename;

    const data_user = JSON.parse(sessionStorage.getItem("user_data"));
    const token = sessionStorage.getItem("token");

    var [disableLoading, setDisableLoading] = useState(false);

    useEffect(() => {
        document.title = "Categorías";
    });

    useEffect(() => {


        if (data_user) {

            const userID = data_user.id_usuario;

            api.getUserSubscription(userID, token)
                .then(data => {
                    if (data.success === true) {
                        const data_usuario = data.data.usuario;
                        sessionStorage.setItem("user_data", JSON.stringify(data_usuario));
                    }
                    if (data.success === false && data.data === null) {
                        sessionStorage.setItem("token", null);
                        sessionStorage.setItem("user_data", null);
                        //ValidarAuth();
                    }
                    if (data.success === false) {
                        //setOpenSnackbarWarningUnidad(true);
                        sessionStorage.setItem("token", null);
                        sessionStorage.setItem("user_data", null);
                    }
                })
                .catch(() => {
                    //setOpenSnackbarError(true);
                });

        }

    }, [data_user, token]);

    const history = useHistory();
    const classes3 = useStyles3();
    const [openSnackbarWarning, setOpenSnackbarWarning] = React.useState(false);

    const [openSnackbarWarningDatos, setOpenSnackbarWarningDatos] = React.useState(false);
    const [openSnackbarError, setOpenSnackbarError] = React.useState(false);

    const handleCloseSnackbarWarning = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenSnackbarWarning(false);
    };

    const handleCloseSnackbarWarningDatos = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenSnackbarWarningDatos(false);
    };

    const handleCloseSnackbarError = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenSnackbarError(false);
    };

    function enterPressed(event) {
        var code = event.keyCode || event.which;
        if (code === 13) { //13 is the enter keycode
            getCursosFiltrados(1, event.target.value);
        }
    }

    const searchBarOnChange = (event, value) => {
        //setPage(value);
        enterPressed(event)
        //getCursosFiltrados(null); //null
    };

    const SearchBar = ({ keyword, setKeyword }) => {
        const BarStyling = { width: "100%", padding: "0.5rem" };
        return (
            <input
                style={BarStyling}
                value={keyword}
                placeholder={"Busca cualquier cosa"}
                className={"round"}
                onKeyPress={searchBarOnChange}
            />
        );
    }

    //Nota revisar Api de busqueda por categoria no busca "2" ni "5"
    var getCursosFiltrados = (nPag, query) => {
        api.getCursosFiltrados({
            query: query,
            category: cateID,
            nPag: nPag,
            id_pais: i18n.getCountry().id_pais
        })
            .then(data => {
                if (data.success === true) {
                    setCursos(data.data.cursos)
                } else {
                    setOpenSnackbarWarning(true);
                }
                //actualizaPagina(data.data.pagina_actual)
                //actualizaTotalPaginas(data.data.numero_de_paginas)
            })
            .catch(() => {
                setOpenSnackbarError(true);
            });
    };

    function limitarcaracteres(value) {
        if (value.length > 72) {
            value = value.slice(0, 72) + '...';
            return value;
        } else {
            return value;
        }
    }

    function limitarcaracteres28(value) {
        if (value.length > 28) {
            value = value.slice(0, 28) + '...';
            return value;
        } else {
            return value;
        }
    }

    const [cursos, setCursos] = useState([])
    const [paginaActual, actualizaPagina] = useState(1)
    const [totalPaginas, actualizaTotalPaginas] = useState(1)
    const [mens, setMens] = useState(false);

    useEffect(() => {
        getCursos(1);
    }, []);

    var courseList = [];
    for (let i = 0; i < cursos.length; i++) {

        courseList.push(
            <Grid container item xs={12} sm={12} md={6} lg={4} xl={4} spacing={0} direction="column" alignItems="center" justifyContent="center" key={cursos[i].id}>
                <Snackbar open={openSnackbarWarning} autoHideDuration={5000} onClose={handleCloseSnackbarWarning} /*style = {styles.snakbar}*/ anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
                    <Alert severity="warning" variant="filled" >
                        No existen coincidencias con la búsqueda.
                    </Alert>
                </Snackbar>

                <Hidden smUp>

                    <div className="course-small">

                        <Container maxWidth="sm">

                            <Grid container spacing={3} alignContent="center" alignItems="center">

                                <TarjetaEcampus
                                    image={utils.getImagenCurso(cursos[i])}
                                    text1={limitarcaracteres28(cursos[i].titulo)}
                                    text2={cursos[i].nombre_instructor}
                                    href={"/course/" + cursos[i].id + "/" + catename}
                                    price={cursos[i].precio}
                                    small={true}
                                    showTag={isStructureUpdated(cursos[i])}
                                    tagOptions={TagStyles.TAG_ACTUALIZADO}
                                    curso={cursos[i]}
                                />

                            </Grid>
                        </Container>

                    </div>

                </Hidden>

                <Hidden xsDown>
                    <div className="course rel">

                        <Container maxWidth="md">

                            <Grid container spacing={3} alignContent="center" alignItems="center">

                                <TarjetaEcampus
                                    image={utils.getImagenCurso(cursos[i])}
                                    text1={limitarcaracteres(cursos[i].titulo)}
                                    text2={cursos[i].nombre_instructor}
                                    href={"/course/" + cursos[i].id + "/" + catename}
                                    price={cursos[i].precio}
                                    showTag={isStructureUpdated(cursos[i])}
                                    tagOptions={TagStyles.TAG_ACTUALIZADO}
                                    curso={cursos[i]}
                                />

                            </Grid>
                        </Container>

                    </div>
                </Hidden>

            </Grid>
        );
    }

    function handleClickCategorias(event) {
        event.preventDefault();
        history.push("/cates");
    }

    const irAPagina = (nPag) => {
        getCursos(nPag);
    };

    const getCursos = (nPag) => {
        api.getCursosCategory({
            categoryId: cateID,
            nPag,
            id_pais: i18n.getCountry().id_pais
        })
            .then(data => {
                if (data.success === true) {
                    setCursos(data.data.cursos);
                    actualizaPagina(data.data.pagina_actual);
                    actualizaTotalPaginas(data.data.numero_de_paginas);
                }
                if (data.success === false) {
                    setOpenSnackbarWarning(true);
                }

                setDisableLoading(true);

                if (data.mensaje === "No hay cursos que mostrar.") {
                    setMens(true);
                }
            })
            .catch(() => {
                setOpenSnackbarError(true);
                setDisableLoading(true);
            });
    };

    const pageNumbers = [];
    for (let i = 1; i <= totalPaginas; i++) {
        pageNumbers.push(i);
    }

    //const [page, setPage] = React.useState(1);
    const handleChange = (event, value) => {
        //setPage(value);
        actualizaPagina(value);
        irAPagina(value);
    };

    // var paginas = "<nav><ul className='pagination'>";
    // for (let i = 0; i < totalPaginas; i++) {
    //     var paginas = paginas + "<li key={i} className='page-item'><a onClick={irAPagina}></a></li>"
    // }
    // var paginas = paginas + "</ul></nav>";

    return (
        <div>
            <div className="App rel">

                    <Breadcrumbs aria-label="breadcrumb" className="breadcrumbs_link_inneret">
                        <Link color="inherit" onClick={handleClickCategorias} className="breadcrumbs_link_inneret">
                            Categorías
                        </Link>
                        <Typography color="textPrimary" className="breadcrumbs_link">
                            {catename}
                        </Typography>
                    </Breadcrumbs>

                    <Snackbar open={openSnackbarWarning} autoHideDuration={5000} onClose={handleCloseSnackbarWarning} /*style = {styles.snakbar}*/ anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
                        <Alert severity="warning" variant="filled" >
                            No existen coincidencias con la búsqueda.
                        </Alert>
                    </Snackbar>

                    <Snackbar open={openSnackbarWarningDatos} autoHideDuration={5000} onClose={handleCloseSnackbarWarningDatos} /*style = {styles.snakbar}*/ anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
                        <Alert severity="warning" variant="filled" >
                            No se encontraron datos.
                        </Alert>
                    </Snackbar>

                    <Snackbar open={openSnackbarError} autoHideDuration={5000} onClose={handleCloseSnackbarError} /*style = {styles.snakbar}*/ anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
                        <Alert severity="error" variant="filled" >
                            No se encontraron datos.
                        </Alert>
                    </Snackbar>

                    <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} spacing={1} /*direction="column" alignItems="center" justifyContent="center" key="home"*/>

                        {/*<SearchBar />*/}
                    </Grid>


                <div className="tituloInicial rel">
                <h1 className="title_section s24 fontb c333">{catename}</h1>
                </div>
                <div style={{ height: '50px' }}></div>
                <div className="courses">
                    {disableLoading != true &&
                        <div id="cargando" className={classes3.root}>

                            <DialogContent className="CentrarLoadingSinModal">
                                <CircularProgress className="CircularProgress" style={{ alignSelf: 'center' }} />
                            </DialogContent>
                        </div>
                    }
                    <Grid container spacing={1} item xs={12} direction="row" >

                        {courseList}

                    </Grid>



                    {mens === true &&

                    <Grid container spacing={3}>


                        <Grid item xs={8} sm={10} md={8} lg={6} xl={6}>

                            <div style={{ height: '100px' }}></div>
                            <div style={{ display: 'flex', justifyContent: 'center' }} className="title_section s30 fontb c333">¡ No hay cursos que mostrar !</div>
                            <div style={{ height: '100px' }}></div>

                        </Grid>


                    </Grid>

                    }

                    <div>
                        <Grid container spacing={1} item xs={12} direction="row" >
                            <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} spacing={0} direction="column" alignItems="center" justifyContent="center" key="paginacion">
                                <Pagination variant="outlined" color="primary" count={totalPaginas} page={paginaActual} onChange={handleChange} />
                            </Grid>
                        </Grid>
                    </div>

                </div>

            </div>

        </div>
    )
}

export default CategoryPage;