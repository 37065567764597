export const BASE_API_URL = process.env.REACT_APP_URL_API;

/**
 * @param {string[]} params
 * @returns {string}
 */
export  const buildParamString = function (params) {
    let result = params.join('&');
    if (result) {
        return '?' + result;
    } else {
        return result;
    }
};
