import { useHistory } from "react-router";

export const useRouteHelper = function () {

    const history = useHistory();

    const gotoLogin = function () {
        history.push(routeHelper.login);
    };

    return {
        gotoLogin
    };
};

/**
 * @todo renombrar a "routes" y mover funciones a useRouteHelper.
 */
const routeHelper = new (function () {

    /**
     * @param {number} courseId
     */
    this.rutaAgregarClase = function (courseId) {
        return '/edit-course/add-class/' + courseId;
    };

    /**
     * @param {number} categoryId
     */
    this.rutaCursosCategory = function (categoryId) {
        return '/courses/' + categoryId;
    };

    this.cart = '/add-cart';
    this.nosotros = '/nosotros';
    this.multiusuario = '/multiuser';
    this.formaContactoPrograma = '/contact/Programa';
    this.misPlanes = '/myplans';
    this.login = '/login';

})();
Object.freeze(routeHelper);
export default routeHelper;
