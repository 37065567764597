import React, { useState, useEffect, useContext } from "react";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import SchoolIcon from '@material-ui/icons/School';
import GetAppIcon from '@material-ui/icons/GetApp';
import ShareIcon from '@material-ui/icons/Share';
import Container from '@material-ui/core/Container';
import { useHistory } from "react-router";
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import Carousel from "react-elastic-carousel";
import { useSnackbar } from "notistack";

import { BotonContext } from "../BotonContext";
import AuthContext from "../AuthContext";
import TarjetaEcampus from "../componentes/TarjetaEcampus";
import commonIO from '../util/common.io';
import utils from "../util/utils";
import { useSession } from "../util/session";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        '& > * + *': {
            marginLeft: theme.spacing(2)
        }
    }
}));

/**
 * @param {import("react-router").RouteComponentProps} props
 * @returns {JSX.Element}
 */
export default function OwnCertifiedPage(props) {

    const { data_user, token } = useContext(BotonContext);
    const session = useSession();
    const courseID = props.match.params.certifiedid;
    const section = props.match.params.section;
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();

    const [openLoad, setOpenLoad] = useState(true);
    const handleCloseLoad = () => {
        setOpenLoad(false);
    };
    const userID = data_user.id_usuario;
    const { isAuth } = useContext(AuthContext);
    const [openModalCertificado, setOpenModalCertificado] = useState(false);

    const classes = useStyles();

    const ValidarAuth = () => {
        if (!isAuth) {
            session.handleNotAuthorized();
        }
    };
    ValidarAuth();

    useEffect(() => {
        document.title = "Mi programa";
    });

    const [curso, setCurso] = useState([]);
    const [ruta, setRuta] = useState([]);

    useEffect(() => {
        fetch(process.env.REACT_APP_URL_API + '/alumno/' + userID + '/diplomado/' + courseID + '/ruta_aprendizaje', {
            method: 'get',
            headers: {
                'token': token,
                'Content-Type': 'application/json'
            }
        })
            .then(response => response.json()
                .then(data => {
                    if (data.success === true) {
                        setCurso(data.data);
                        setRuta(data.data.ruta_aprendizaje);
                        setOpenLoad(false);
                    } else {
                        enqueueSnackbar(data.mensaje, { variant: 'error' });
                        setOpenLoad(false);
                        const intervalId = setInterval(() => {
                            history.goBack();
                            clearInterval(intervalId);
                        }, 2000);
                    }
                })
            );
    }, []);


    const { cart, setCart } = useContext(BotonContext);
    let newCart = (cart !== null) ? cart.slice(0,) : [];
    let carting;

    const [open, setOpen] = React.useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        const storage = localStorage.getItem('cart')
        setCart((storage !== null) ? JSON.parse(storage) : [])
    }, []);

    // Boton de carrito que suma cantidad total - Form abajo
    const [pathname, setPathname] = useState(window.location.pathname.slice(18,));
    const [cartQuantity, setCartQuantity] = useState();
    useEffect(() => {
        const inputVal = pathname.split('-').join(' ')
        let quantity = 0;
        carting = newCart;
        if (carting !== null && carting.length > 0) {
            carting.map(course => quantity += course.quantity)
        }
        setCartQuantity(quantity);
        if (window.location.pathname.includes('search')) {
            document.getElementsByTagName('input').item(0).setAttribute('value', inputVal);
            document.getElementsByTagName('input').item(1).setAttribute('value', inputVal);
        }
    });

    function handleClickCategorias(event) {
        event.preventDefault();
        history.push("/cates");
    }

    function handleClickCursoSection(event) {
        event.preventDefault();
        history.goBack();
    }

    const styles = (theme) => ({
        root: {
            margin: 0,
            padding: theme.spacing(2)
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500]
        }
    });

    const DialogTitle = withStyles(styles)((props) => {
        const { children, classes, onClose, ...other } = props;
        return (
            <MuiDialogTitle disableTypography className={classes.root} {...other}>
                <Typography variant="h6">{children}</Typography>
                {onClose ? (
                    <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                ) : null}
            </MuiDialogTitle>
        );
    });

    const breakPoints = [
        { width: 1, itemsToShow: 1 },
        { width: 600, itemsToShow: 2 },
        { width: 960, itemsToShow: 3 },
        { width: 1280, itemsToShow: 4 },
        { width: 1920, itemsToShow: 5 }
    ];

    let new_ruta = Object.entries(ruta);

    const diplomadosList = [];

    if (ruta && ruta != '' && ruta !== undefined && ruta != null) {

        if (new_ruta.length) {
            diplomadosList.push(
                <hr size="1" style={{ marginTop: '4rem' }} key={new_ruta.length} />
            );
        }

        for (let i = 0; i < new_ruta.length; i++) {
            diplomadosList.push(
                <div key={new_ruta[i][1].id + 'a'} className="hide-on-xxs">
                    <h2 className="title_section s24 fontb sub-section">Ruta de aprendizaje -  {new_ruta[i][1]['titulo']}</h2>

                    <div className="home-page rel">
                        <div className="extra-block rel">
                            <div className="courses rel flex">
                                <Grid container spacing={1} direction="row">
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}  >
                                        <Carousel breakPoints={breakPoints} enableAutoPlay={true} autoPlaySpeed={3000}>

                                            {/* <!-- tarjetas cursos --> */}
                                            {new_ruta[i][1].cursos.map(item => {

                                                return <Container className="carrusel-contenedor-tarjeta" key={item['id']} >
                                                    <Grid container spacing={3} alignContent="center" alignItems="center">

                                                        <TarjetaEcampus
                                                            image={utils.getImagenCurso(item)}
                                                            text1={commonIO.shortenText(item['titulo'], 50)}
                                                            text2={item['instructor']}
                                                            href={"/owncourse/" + item['id'] + "/" + "Mi programa" + "/" + courseID}
                                                            price={item['precio']}
                                                        />

                                                    </Grid>
                                                </Container>;

                                            })}
                                        </Carousel>
                                    </Grid>
                                </Grid>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    }

    const descargarCertificado = () => {
        let Certificado = {
            "id_usuario": userID,
            "id_diplomado": courseID
        };
        fetch(process.env.REACT_APP_URL_API + '/certificado-diplomado', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'token': token
            },
            body: JSON.stringify(Certificado)
        })
            .then(async res => ({
                filename: 'Certificado.pdf',
                blob: await res.blob()
            }))
            .then(resObj => {
                // It is necessary to create a new blob object with mime-type explicitly set for all browsers except Chrome, but it works for Chrome too.
                const newBlob = new Blob([resObj.blob], { type: 'application/pdf' });
                // MS Edge and IE don't allow using a blob object directly as link href, instead it is necessary to use msSaveOrOpenBlob
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(newBlob);
                } else {
                    // For other browsers: create a link pointing to the ObjectURL containing the blob.
                    const objUrl = window.URL.createObjectURL(newBlob);
                    let link = document.createElement('a');
                    link.href = objUrl;
                    link.download = resObj.filename;
                    link.click();
                    // For Firefox it is necessary to delay revoking the ObjectURL.
                    setTimeout(() => { window.URL.revokeObjectURL(objUrl); }, 250);
                }
            })
            .catch(e => {
                console.log(e);
            });
    };

    const compartirLinkedIn = () => {
        fetch(process.env.REACT_APP_URL_API + '/compartir-pdf/' + userID + '/diplomado/' + courseID, {
            method: 'get',
            headers: {
                'token': token,
                'Content-Type': 'application/json'
            }
        })
            .then(response => response.json()
                .then(data => {
                    if (data.success === true) {
                        window.open(data.data);
                    }
                    if (data.success === false) {
                        enqueueSnackbar(data.mensaje, { variant: 'error' });
                    }
                })
            )
            .catch(e => {
                console.log(e);
            });
    };

    function handleClickCursoSectionMisDiplomados(event) {
        event.preventDefault();
        history.push("/my-certified");
    }

    const handleClickOpenModalCertificado = () => {
        setOpenModalCertificado(true);
    };

    const handleCloseModalCertificado = () => {
        setOpenModalCertificado(false);
    };

    let botonesDiplomado;
    if (curso.estado == 2) {
        botonesDiplomado = <Button onClick={handleClickOpenModalCertificado} fullWidth variant="contained" className="button_accept" style={{ marginTop: '8px' }}><SchoolIcon size="small" />&nbsp; &nbsp;Obtener certificado</Button>;
    } else {
        botonesDiplomado = <Button disabled={true} fullWidth variant="contained" className="button_accept_disable" style={{ marginTop: '8px' }}><SchoolIcon size="small" />&nbsp; &nbsp;Obtener certificado</Button>;
    }

    const imagenDiplomado = <img width="100%" src={utils.getImagenCurso(curso.imagen)} alt="imagen curso" />;

    return (
        <div className="App rel">
            <Breadcrumbs aria-label="breadcrumb" className="breadcrumbs_link_inneret">

                {section != "Inicio" && section != "Mis programas" &&
                    <Link color="inherit" onClick={handleClickCategorias} className="breadcrumbs_link_inneret">
                        Categorías
                    </Link>
                }
                {section == "Mis programas" &&
                    <Link color="inherit" onClick={handleClickCursoSectionMisDiplomados} className="breadcrumbs_link_inneret">
                        {section}
                    </Link>
                }
                {section != "Inicio" && section != "Mis programas" &&
                    <Link color="inherit" onClick={handleClickCursoSection} className="breadcrumbs_link_inneret">
                        {section}
                    </Link>
                }

                <Typography color="textPrimary" className="breadcrumbs_link">
                    Mi programa
                </Typography>
            </Breadcrumbs>

            <Grid container className="primary-column">

                <h1 className="title_section s24 fontb c333 hide-on-xs">Mi programa</h1>
                <h2 className="title_section sub-title only-on-xs always-colored">{curso.titulo}</h2>
                <Grid item xs={12} sm={8} md={8} lg={8} xl={9} >
                    <div className="section" >
                        <div className="extras-block">
                            <div className="only-on-xs">{imagenDiplomado}</div>
                            <div className="only-on-xs">{botonesDiplomado}</div>
                            <div className="tutor rel aic flex">
                                <p className="contenido-label-detalle">
                                    <span className="content-highlighted-label">Instructor: </span><span style={{ fontStyle: "italic" }}>{curso.instructor || 'N/A'}</span>
                                </p>
                            </div>

                            <div className="course-meta">
                                <h2 className="title_section sub-title hide-on-xs always-colored">{curso.titulo}</h2>
                                <p className="content-description jtx" dangerouslySetInnerHTML={{ __html: curso.descripcion || 'Programa sin descripción.' }} />
                                {diplomadosList}
                            </div>
                        </div>
                    </div>
                </Grid>

                <Grid item sm={4} md={4} lg={4} xl={3} className="secondary-column" >

                    <div className="section" style={{ marginTop: '-25px' }}>
                        <div className="extras-block">
                            {imagenDiplomado}
                            <div className="section stacked-block">
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                                    {botonesDiplomado}
                                </Grid>
                                <Dialog
                                    open={open}
                                    onClose={handleClose}
                                    aria-labelledby="customized-dialog-title"
                                >
                                    <DialogTitle id="alert-dialog-title" onClose={handleClose} className="modal_title">{"Advertencia!"}</DialogTitle>
                                    <DialogContent dividers className="modal_content">

                                        <Typography gutterBottom>
                                            Este programa ya se encuentra en su carro de compras.
                                        </Typography>

                                    </DialogContent>

                                    <DialogActions className="modal_footer">
                                        <Button onClick={handleClose} variant="contained" className="button_cancel">
                                            Cancelar
                                        </Button>
                                    </DialogActions>
                                </Dialog>

                            </div>
                        </div>
                    </div>


                </Grid>
            </Grid>

            {/* Modal con botones de certificados */}

            <Dialog open={openModalCertificado} onClose={handleCloseModalCertificado} aria-labelledby="customized-dialog-title" >
                <DialogTitle id="form-dialog-titles" onClose={handleCloseModalCertificado} className="modal_title"><div className="modal_title">Obtener certificado</div></DialogTitle>
                <DialogContent dividers className="modal_content">

                    <Grid container spacing={3}>

                        <Grid item xs={2} sm={2} md={2} lg={2} xl={2} > </Grid>
                        <Grid item xs={8} sm={8} md={8} lg={8} xl={8}  >
                            <Button onClick={descargarCertificado} variant="contained" color="primary" className="button_accept" fullWidth>
                                <GetAppIcon size="small" /> &nbsp; Descargar
                            </Button>

                            <br></br> <br></br>
                            <Button onClick={compartirLinkedIn} variant="contained" color="primary" className="button_accept" fullWidth>
                                <ShareIcon size="small" /> &nbsp; &nbsp;LinkedIn
                            </Button>
                        </Grid>
                        <Grid item xs={2} sm={2} md={2} lg={2} xl={2} > </Grid>
                    </Grid>

                </DialogContent>
                <DialogActions className="modal_footer">

                    <Button onClick={handleCloseModalCertificado} variant="contained" color="primary" className="button_cancel">
                        Cancelar
                    </Button>

                </DialogActions>
            </Dialog>

            {/* Modal con botones de certificados*/}

            <div id="cargando" className={classes.root}>
                <Dialog
                    open={openLoad}
                    onClose={handleCloseLoad}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent className="CentrarLoading">
                        <CircularProgress className="CircularProgress" />
                    </DialogContent>
                </Dialog>
            </div>

        </div>
    );

}
