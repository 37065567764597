import React, { useState, useEffect, useContext } from "react";
import { QuestionBox, QuestionBoxTest } from "../componentes";
import { useHistory } from "react-router-dom";
import ReactPlayer from "react-player";
import Box from '@material-ui/core/Box';
import Tab from '@material-ui/core/Tab';
import TabList from '@material-ui/lab/TabList';
import { TabContext } from '@material-ui/lab';
import TabPanel from '@material-ui/lab/TabPanel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import LinearProgress from '@material-ui/core/LinearProgress';
import axios from 'axios';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import TextField from '@material-ui/core/TextField';
import Avatar from '@material-ui/core/Avatar';
import SchoolIcon from '@material-ui/icons/School';
import { Hidden } from "@material-ui/core";
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

//EDITOR DE TEXTO
import ReactQuill from "react-quill";
import EditorToolbar, { modules, formats } from "../componentes/EditorToolbar";
import "react-quill/dist/quill.snow.css";

//MODAL MATERIAL UI
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

//Modal
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import GetAppIcon from '@material-ui/icons/GetApp';
import ShareIcon from '@material-ui/icons/Share';

import { ListaAcordeonesUnidad } from "../componentes/AccordionUnidad";
import api from "../lib/api";
import { TIPO_CLASE } from "../lib/constants";
import { CourseRatingEditor } from "../componentes/CourseRating";
import { useSnackbar } from "notistack";
import { BotonContext } from "../BotonContext";
import { useSession } from "../util/session";
import { useApiClase } from "../lib/api.clase";
import { useCommonStyles } from "../util/common.styles";
import CircularProgress from "../componentes/ButtonCircularProgress";

const DISABLE_MANUAL_STATUS_CHANGE = true;

const useStyles = makeStyles({
    progress: {
        height: 14,
        borderRadius: 15
    }
});

const OPCION_PREGUNTA = "pregunta o comentario";
const OPCION_RESPUESTA = "respuesta";
const OPCION_TITULO_PREGUNTA = "Todas las preguntas y comentarios de la clase:";
const OPCION_TITULO_RESPUESTA = "Todas las respuestas de esta pregunta o comentario:";

/**
 * @param {import("react-router").RouteComponentProps} props
 */
function CourseViewPage(props) {

    const { data_user, token } = useContext(BotonContext);
    const session = useSession();
    const { updateEstadoClase } = useApiClase();
    const { enqueueSnackbar } = useSnackbar();
    const [paramTipoClase, setParamTipoClase] = useState(0);
    const [evaluationIDtest, setEvaluationIDtest] = useState(0);
    const [evaluationID, setEvaluationID] = useState(0);

    const history = useHistory();
    const unidadID = props.match.params.unitid;
    const classIdParam = props.match.params.classid;
    const courseID = props.match.params.courseid;
    const section = props.match.params.section;
    const certifiedID = props.match.params.certifiedid;

    const [textValue, setTextValue] = useState("");
    const [courseTitle, setCourseTitle] = useState("");
    const [errorCourseTitle, setErrorCourseTitle] = useState(false);
    const [mensCourseTitle, setMensCourseTitle] = useState("");
    const [errorTextValue, setErrorTextValue] = useState(false);
    const [mensTextValue, setMensTextValue] = useState("");
    const [botonStatus, setBotonStatus] = useState("button_accept_disable");
    const [disabledAddClass, setDisabledAddClass] = useState(true);
    const [questionList, setQuestionList] = useState(/** @type {api.Pregunta[]} */([]));
    const [answerList, setAnswerList] = useState([]);
    const [opcion, setOpcion] = useState(OPCION_PREGUNTA);
    const [opcionTitulo, setOpcionTitulo] = useState(OPCION_TITULO_PREGUNTA);
    const [hiddenQuestions, setHiddenQuestions] = useState(false);
    const [hiddenAnswer, setHiddenAnswer] = useState(true);
    const [idPadre, setIdPadre] = useState("");
    const [actualizarPreguntas, setActualizarPreguntas] = useState([]);
    const [actualizarRespuestas, setActualizarRespuestas] = useState([]);
    const [idClase, setIdClase] = useState(classIdParam);
    const [updatingClassState, setUpdatingClassState] = useState(false);
    const [finalizandoCurso, setFinalizandoCurso] = useState(false);
    /** @type {number} */
    let classIdParamParsed;
    try {
        classIdParamParsed = parseInt(classIdParam);
    } catch {/** invalid classId param */}

    /* Modal*/
    const [textValueModal, setTextValueModal] = useState("");
    const [courseTitleModal, setCourseTitleModal] = useState("");
    const [errorCourseTitleModal, setErrorCourseTitleModal] = useState(false);
    const [mensCourseTitleModal, setMensCourseTitleModal] = useState("");
    const [errorTextValueModal, setErrorTextValueModal] = useState(false);
    const [mensTextValueModal, setMensTextValueModal] = useState("");
    const [botonStatusModal, setBotonStatusModal] = useState("button_accept_disable");
    const [disabledAddClassModal, setDisabledAddClassModal] = useState(true);

    function setIdpadrefunc(id) {
        setIdPadre(id);
        setActualizarRespuestas(id);
        Responder();
    }

    const handleClaseClick = (val2) => {
        setParamTipoClase(0);
        if (!val2.length) {
            setIdClase(val2);
            setActualizarPreguntas(val2);
        }
    };

    const Responder = () => {
        setOpcion(OPCION_RESPUESTA);
        setHiddenQuestions(true);
        setOpcionTitulo(OPCION_TITULO_RESPUESTA);
        setHiddenAnswer(false);
    };

    const ResponderCancel = () => {
        setHiddenAnswer(true);
        setOpcion(OPCION_PREGUNTA);
        setHiddenQuestions(false);
        setOpcionTitulo(OPCION_TITULO_PREGUNTA);
    };

    {/* Modal */ }

    const ValidarFormModal = () => {

        if (!courseTitleModal || courseTitleModal == '' || courseTitleModal == undefined) {
            setErrorCourseTitleModal(true);
            setMensCourseTitleModal("Se requiere ingresar el título");
            document.getElementById('question-title-modal').focus();
            return;
        }

        if (courseTitleModal.length > 250) {
            setErrorCourseTitleModal(true);
            setMensCourseTitleModal("No se permite mas de 250 carácteres para este campo.");
            document.getElementById('question-title-modal').focus();
            return;
        }
        if (!textValueModal || textValueModal == '' || textValueModal == undefined || textValueModal === 'null' || textValueModal === null) {
            setErrorTextValueModal(true);
            setMensTextValueModal("Se requiere ingresar la descripción");
            document.getElementById('question-detalle').focus();
            return;
        }
        saveModal();

    };

    const LimpiarFormModal = () => {
        setCourseTitleModal("");
        setErrorCourseTitleModal(false);
        setMensCourseTitleModal("");

        setTextValueModal("");
        setErrorTextValueModal(false);
        setMensTextValueModal("");

        setBotonStatusModal("button_accept_disable");
        setDisabledAddClassModal(true);
    };

    const saveModal = () => {

        let Comentario = {
            "id_usuario": data_user.id_usuario,
            "id_curso": courseID,
            "texto": textValueModal,
            "titulo": /** @type {HTMLInputElement} */(document.getElementById('question-title-modal')).value
        };

        axios.post(process.env.REACT_APP_URL_API + '/curso/' + courseID + '/comentario', Comentario, {
            headers: {
                'Content-Type': 'application/json',
                'token': token
            }
        })
            .then(response => {
                if (response.status === 401 || response.status === 403) {
                    session.handleNotAuthorized();
                    return;
                }
                if (response.data?.success) {
                    LimpiarFormModal();
                    handleCloseRating();
                    enqueueSnackbar(response.data.mensaje, { variant: 'success' });
                } else {
                    response.data?.mensaje === "Ya has comentado este curso" && handleCloseRating(); // colocar error 200 en el backend
                    enqueueSnackbar(response.data?.mensaje || "Error al guardar comentario", { variant: 'error' });
                }
            }).catch(e => {
                console.error(e);
            });

    };

    {/* Fin Modal */ }

    const ValidarForm = () => {

        if (!courseTitle || courseTitle == '' || courseTitle == undefined) {
            setErrorCourseTitle(true);
            setMensCourseTitle("Se requiere ingresar el título");
            document.getElementById('question-title').focus();
            return;
        }
        if (courseTitle.length > 250) {
            setErrorCourseTitle(true);
            setMensCourseTitle("No se permite mas de 250 carácteres para este campo.");
            document.getElementById('question-title').focus();
            return;
        }
        if (!textValue || textValue == '' || textValue == undefined || textValue === 'null' || textValue === null || textValue === '<p><br></p>') {
            setErrorTextValue(true);
            setMensTextValue("Se requiere ingresar la descripción");
            document.getElementById('quill_descripcion').focus();
            return;
        }
        save();

    };

    const save = () => {

        if (opcion == OPCION_RESPUESTA) {

            let Respuesta = {
                "id_usuario": data_user.id_usuario,
                "id_clase": classIdParam,
                "id_padre": idPadre,
                "param_tipo": 2,
                "texto": textValue,
                "titulo": document.getElementById('question-title').value
            };

            axios.post(process.env.REACT_APP_URL_API + '/pregunta-respuesta', Respuesta, {
                headers: {
                    'Content-Type': 'application/json',
                    'token': token
                }
            })
                .then(response => {
                    if (response.status === 401 || response.status === 403) {
                        session.handleNotAuthorized();
                        return;
                    }
                    if (response.data?.success) {
                        if (actualizarRespuestas === "0") {
                            setActualizarRespuestas("1");
                        } else {
                            setActualizarRespuestas("0");
                        }
                        LimpiarFormQA();
                        enqueueSnackbar(`La ${opcion} se ha añadido correctamente.`, { variant: 'success' });
                    } else {
                        enqueueSnackbar("Error al intentar guardar", { variant: 'warning' });
                    }
                }).catch(e => {
                    console.error(e);
                });

        } else {

            const Pregunta = {
                "id_usuario": data_user.id_usuario,
                "id_clase": classIdParam,
                "param_tipo": opcionSelected,
                "texto": textValue,
                "titulo": document.getElementById('question-title').value
            };

            axios.post(process.env.REACT_APP_URL_API + '/pregunta-respuesta', Pregunta, {
                headers: {
                    'Content-Type': 'application/json',
                    'token': token
                }
            })
                .then(response => {
                    if (response.status === 401 || response.status === 403) {
                        session.handleNotAuthorized();
                        return;
                    }
                    if (response.data?.success) {
                        if (actualizarPreguntas === "0") {
                            setActualizarPreguntas("1");
                        } else {
                            setActualizarPreguntas("0");
                        }
                        LimpiarFormQA();
                        enqueueSnackbar(`La ${opcion} se ha añadido correctamente.`, { variant: 'success' });
                    } else {
                        enqueueSnackbar("Error al intentar guardar", { variant: 'warning' });
                    }
                }).catch(e => {
                    console.error(e);
                });

        }

    };

    const LimpiarFormQA = () => {
        setCourseTitle("");
        setErrorCourseTitle(false);
        setMensCourseTitle("");

        setTextValue(null);
        setErrorTextValue(false);
        setMensTextValue("");

        setBotonStatus("button_accept_disable");
        setDisabledAddClass(true);
    };

    const userID = data_user?.id_usuario;
    const classes = useStyles();
    const commonClases = useCommonStyles();

    const [course, setCourse] = useState(/** @type {{ [id_unidad: string]: api.MyUnidad }} */({}));
    const [total, setTotal] = useState(/** @type {api.ListaMisClases} */(null));
    const [actualizar, setActualizar] = useState(null);
    const [elVideo, setElVideo] = useState(/** @type {string} */(''));
    const [recursoUrl, setRecursoUrl] = useState(/** @type {string} */(''));
    const [descriptionClass, setDescriptionClass] = useState('');

    const [nextIdClass, setNextIdClass] = useState(/** @type {number} */(null));
    const [nextUnidadID, setNextUnidadID] = useState(/** @type {number} */(null));
    const [mensajeSinDatos, setMensajeSinDatos] = useState("");
    const [open, setOpen] = useState(false);

    const [openRating, setOpenRating] = useState(false);
    const [valueRating, setValueRating] = useState(0);
    const [actualizarValoracion, setActualizarValoracion] = useState([]);
    const [valueCursoComentado, setValueCursoComentado] = useState(1);
    const [valueCursoEstado, setValueCursoEstado] = useState(1);
    const [actualizarCursoEstado, setActualizarCursoEstado] = useState([]);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    function handleClickOpenRating() {
        setOpenRating(true);
    }

    const handleCloseRating = () => {
        setOpenRating(false);
    };

    useEffect(() => {
        // Obtener estado del curso
        fetch(process.env.REACT_APP_URL_API + '/alumno/' + data_user.id_usuario + '/curso/' + courseID + '/estado', {
            method: 'GET',
            headers: {
                'token': token,
                'Content-Type': 'application/json'
            }
        })
            .then(response => {
                if (response.status === 401 || response.status === 403) {
                    session.handleNotAuthorized();
                    return;
                }
                response.json()
                    .then(data => {
                        if (data?.success === true) {
                            setValueCursoEstado(data.data.estado);
                        } else {
                            console.error(data?.mensaje || data);
                            enqueueSnackbar(data?.mensaje || "Error al obtener estado del curso", { variant: data?.mensaje ? 'warning' : 'error' });
                        }
                    });
            })
            .catch(e => {
                console.error(e);
            });
    }, [actualizarCursoEstado]);

    useEffect(() => {
        // Obtener comentarios del alumno del curso
        fetch(process.env.REACT_APP_URL_API + '/alumno/' + data_user.id_usuario + '/curso/' + courseID + '/comentario', {
            method: 'get',
            headers: {
                'token': token,
                'Content-Type': 'application/json'
            }
        })
            .then(response => {
                if (response.status === 401 || response.status === 403) {
                    session.handleNotAuthorized();
                    return;
                }
                response.json()
                    .then(data => {
                        if (data?.success) {
                            setValueCursoComentado(0);
                        }
                    });
            })
            .catch(e => {
                console.error(e);
            });
    }, []);

    useEffect(() => {
        // Obtener valoración del curso
        fetch(process.env.REACT_APP_URL_API + '/valoracion?id_curso=' + courseID + '&id_usuario=' + data_user.id_usuario, {
            method: 'get',
            headers: {
                'token': token,
                'Content-Type': 'application/json'
            }
        })
            .then(response => {
                if (response.status === 401 || response.status === 403) {
                    session.handleNotAuthorized();
                    return;
                }
                response.json()
                    .then(data => {
                        if (data?.success) {
                            setValueRating(data.data.param_valoracion);
                        }
                    });
            })
            .catch(e => {
                console.error(e);
            });
    }, [actualizarValoracion]);

    /**
    * @param {number} newValue
    */
    function handleSendRating(newValue) {
        setValueRating(newValue);
        const SendRating = {
            "id_curso": courseID,
            "id_usuario": data_user.id_usuario,
            "valoracion": newValue
        };

        axios.post(process.env.REACT_APP_URL_API + '/valoracion', SendRating, {
            headers: {
                'Content-Type': 'application/json',
                'token': token
            }
        })
            .then(response => {
                if (response.status === 401 || response.status === 403) {
                    session.handleNotAuthorized();
                    return;
                }
                if (response.data?.success) {
                    enqueueSnackbar(response.data.mensaje, { variant: 'success' });
                    setValueRating(newValue);
                    setActualizarValoracion(newValue);
                } else {
                    handleUpdateRating(newValue);
                }
            }).catch(e => {
                console.error(e);
            });
    }

    const handleUpdateRating = (newValue) => {
        const putUpdateRating = {
            "id_curso": courseID,
            "id_usuario": data_user.id_usuario,
            "valoracion": newValue
        };
        fetch(process.env.REACT_APP_URL_API + '/valoracion', {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'token': token
            },
            body: JSON.stringify(putUpdateRating)
        })
            .then(resp => {
                if (resp.status === 401 || resp.status === 403) {
                    session.handleNotAuthorized();
                    return;
                }
                resp.json()
                    .then(data => {
                        if (data?.success) {
                            enqueueSnackbar("Su valoración ha sido actualizada", { variant: 'success' });
                            setValueRating(newValue);
                            setActualizarValoracion(newValue);
                        } else {
                            enqueueSnackbar(data?.mensaje || "Error al actualizar valoración", { variant: 'error' });
                        }
                    });
            })
            .catch(e => {
                console.error(e);
            });
    };

    function finalizarCurso() {
        if (finalizandoCurso) return;
        setFinalizandoCurso(true);
        const putFinalizarCurso = {
            "id_usuario": userID,
            "id_curso": courseID,
            "id_diplomado": certifiedID
        };
        fetch(process.env.REACT_APP_URL_API + '/curso-finalizado', {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'token': token
            },
            body: JSON.stringify(putFinalizarCurso)
        })
            .then(resp => {
                if (resp.status === 401 || resp.status === 403) {
                    session.handleNotAuthorized();
                    return;
                }
                resp.json()
                    .then(data => {
                        if (data?.success) {
                            setValueCursoEstado(2);
                            handleClickOpenRating();
                        }
                    });
            })
            .catch(e => {
                console.error(e);
                enqueueSnackbar("Error al finalizar curso", { variant: 'error' });
            })
            .finally(() => {
                setFinalizandoCurso(false);
            });
    }

    useEffect(() => {
        api.getMisClases(token, userID, courseID)
            .then(data => {
                if (!data?.success || data?.data === null) {
                    console.error("Error al obtener mis clases");
                    return;
                }
                setCourse(data.data.unidades);
                setTotal(data.data);
            });
    }, [actualizar]);


    const estadisticasCurso = [];
    if (total) {

        const porcentaje_completado = /** @type {string} */(total.porcentaje_avance.toString()).replace(/%$/, ''); // remover "%" en caso de que esté presente
        const avance = parseInt(porcentaje_completado);

        let porc_certificado = 99;

        if (avance > porc_certificado && valueCursoEstado === 1) {
            finalizarCurso();
        }

        estadisticasCurso.push(

            <div key="estadistica" style={{ width: '100%' }}>

                <Card style={{ width: '100%' }} elevation={5} className="stacked-block">

                    <Box sx={{ minWidth: '90%' }} style={{ marginLeft: '5px', marginBottom: '0px' }} >
                        <CardHeader title="Mi valoración" />
                    </Box>
                    <div style={{ marginBottom: '15px', display: 'flex', justifyContent: 'center' }}>
                        <CourseRatingEditor value={valueRating} handleRatingChange={handleSendRating} />
                    </div>

                </Card>

                {avance > porc_certificado &&
                    <Button onClick={handleClickOpen} fullWidth variant="contained" className="button_accept stacked-block" style={{ marginTop: '8px' }}><SchoolIcon size="small" />&nbsp; &nbsp;Obtener certificado</Button>
                }
                {avance <= porc_certificado &&
                    <Button disabled={true} fullWidth variant="contained" className="button_accept_disable stacked-block" style={{ marginTop: '8px' }}><SchoolIcon size="small" />&nbsp; &nbsp;Obtener certificado</Button>
                }

                <Card style={{ width: '100%' }} elevation={5} className="stacked-block" >

                    <Box sx={{ minWidth: '90%' }} style={{ marginLeft: '5px', marginBottom: '0px' }}>
                        <CardHeader title="Progreso" />
                    </Box>
                    <Box sx={{ width: '55%', mr: 1 }} style={{ float: 'right', marginRight: '15px', marginBottom: '10px' }}>
                        <LinearProgress className={classes.progress} style={{ marginTop: '3px' }} size="large" color="secondary" variant="determinate" value={avance} />
                    </Box>
                    <Box sx={{ minWidth: 35 }} style={{ float: 'right', marginRight: '15px', marginBottom: '10px' }}>
                        <Typography variant="body2" >{porcentaje_completado}%</Typography>
                    </Box>

                </Card>

            </div>
        );

    }

    /**
    * @param {boolean} currentValue
    * @param {api.Clase} clase
    */
    const handleClassCheckboxClick = function (currentValue, clase) {
        if (clase.param_tipo != TIPO_CLASE.VIDEO || DISABLE_MANUAL_STATUS_CHANGE) return; // solo permitir cambiar manualmente estado de videos (no de pruebas)
        const newValue = !currentValue;
        const estado = newValue ? 1 : 0;
        updateEstadoClase({
            // TODO: manejar errores
            token,
            userId: userID,
            classId: clase.id,
            estado
        });
        course[clase.id_unidad].clases.forEach(c => {
            if (clase.id === c.id) {
                c.estado = estado;
            }
        });
        setCourse({ ...course });
    };

    const cargarVideoRecursoActual = () => {
        if (!course || !Object.keys(course).length) return;

        /** @type {api.MyClase[]} */
        const clases = [];
        Object.values(course).forEach(unidad => {
            clases.push(...unidad.clases);
        });

        for (let i = 0; i < clases.length - 1; i++) {
            if (clases[i].id == classIdParam) {
                setNextIdClass(clases[i + 1].id);
                setNextUnidadID(clases[i + 1].id_unidad);
            }
        }

        const result = course[unidadID].clases.filter(obj => obj.id == classIdParam);
        const clase = result[0];
        const tipoClase = clase.param_tipo;

        setDescriptionClass(clase.descripcion);

        if (tipoClase === TIPO_CLASE.VIDEO) {
            const video = clase.recursos;
            if (video) {
                const curso_video = video.raiz + video.url;
                setElVideo(curso_video);
                setParamTipoClase(TIPO_CLASE.VIDEO);
            }
        }

        if (tipoClase === TIPO_CLASE.ZIP) {
            const zip = clase.recursos;
            if (zip) {
                const curso_adjunto = zip.raiz + zip.url;
                setRecursoUrl(curso_adjunto);
                setParamTipoClase(TIPO_CLASE.ZIP);
            }
        }

        if (tipoClase === TIPO_CLASE.TEST) {
            const evaluation = clase.evaluacion;
            if (evaluation) {
                setEvaluationIDtest(evaluation.id_evaluacion);
                setParamTipoClase(TIPO_CLASE.TEST);
            }
        }

        if (tipoClase === TIPO_CLASE.TEST_FORMATIVO) {
            const evaluation = clase.evaluacion;
            if (evaluation) {
                setEvaluationID(evaluation.id_evaluacion);
                setParamTipoClase(TIPO_CLASE.TEST_FORMATIVO);
            }
        }

    };

    useEffect(() => {
        cargarVideoRecursoActual();
    });

    /** @type {JSX.Element} */
    let acordeonesUnidades;
    if (course && Object.keys(course).length) {
        const unidades = Object.values(course);
        acordeonesUnidades = <ListaAcordeonesUnidad
            publicAccordion={false}
            unidades={unidades}
            certifiedId={certifiedID}
            courseId={courseID}
            section={section}
            handleClaseClick={handleClaseClick}
            handleCheckboxClick={handleClassCheckboxClick}
            defaultExpanded={true}
            disabledCheckboxes={DISABLE_MANUAL_STATUS_CHANGE}
            idSelectedClass={classIdParamParsed}
        />;
    }

    useEffect(() => {
        document.title = "Clase";
    });

    const [valueTab, setValueTab] = React.useState('1');

    const handleChange = (event, newValue) => {
        setValueTab(newValue);
    };


    const classDescription = [];

    classDescription.push(
        <div key="descriptionclass">
            <div className="id s18">
                {descriptionClass}
            </div>
            <br />
        </div>
    );


    /**Preguntas y respuestas**/

    useEffect(() => {

        fetch(process.env.REACT_APP_URL_API + '/clase/' + idClase + '/preguntas', {
            method: 'get',
            headers: {
                'token': token,
                'Content-Type': 'application/json'
            }
        })
            .then(response => {
                if (response.status === 401 || response.status === 403) {
                    session.handleNotAuthorized();
                    return;
                }
                response.json()
                    .then(data => {
                        if (data?.success) {
                            if (data.data.n_preguntas > 0) {
                                setQuestionList(data.data.preguntas);
                                setHiddenAnswer(true);
                                setOpcion(OPCION_PREGUNTA);
                                setHiddenQuestions(false);
                                setOpcionTitulo(OPCION_TITULO_PREGUNTA);
                                setMensajeSinDatos("");
                            }
                        }
                        if (data.mensaje === "No se encontró el recurso solicitado") {
                            setHiddenQuestions(true);
                            setOpcion(OPCION_PREGUNTA);
                            setOpcionTitulo(OPCION_TITULO_PREGUNTA);
                            setHiddenAnswer(true);
                            setMensajeSinDatos("No se encontró preguntas o comentarios para esta clase.");
                        }
                        if (!data?.success) {
                            setHiddenQuestions(true);
                            setOpcion(OPCION_PREGUNTA);
                            setOpcionTitulo(OPCION_TITULO_PREGUNTA);
                            setHiddenAnswer(true);
                        }
                    });
            })
            .catch(e => {
                console.error(e);
            });
    }, [actualizarPreguntas]);


    /**
    * @param {string} value
    * @param {number} length
    * @returns {string}
    */
    function limitarCaracteres(value, length) {
        if (value.length > length) {
            value = value.slice(0, length) + '...';
            return value;
        } else {
            return value;
        }
    }

    /** @type {JSX.Element[]} */
    const listadoPreguntas = [];
    if (questionList && questionList instanceof Array && questionList.length) {

        Object.keys(questionList).forEach(key => {

            if (questionList[key].param_tipo === 1 || questionList[key].param_tipo === 3) {

                let el_texto = '';
                if (questionList[key].texto === null || questionList[key].texto === 'null') {
                    el_texto = questionList[key].texto;
                } else {
                    el_texto = limitarCaracteres(questionList[key].texto, 250);
                }

                listadoPreguntas.push(

                    <Grid container key={questionList[key].id}>

                        <Grid item xs={12}>

                            <div className="CardEfect" hidden={hiddenQuestions}>
                                <CardContent id={questionList[key].id} className="CardEfect" >
                                    <CardHeader
                                        className="Pointer"
                                        onClick={() => setIdpadrefunc(questionList[key].id)}
                                        avatar={
                                            <Avatar aria-label="recipe" className="MuiAvatar-colorForced">
                                                {questionList[key].nombres.charAt(0)}
                                            </Avatar>
                                        }
                                        title={limitarCaracteres(questionList[key].titulo, 50)}
                                        subheader={questionList[key].nombres + " " + questionList[key].apellidos + ". " + questionList[key].fecha_creacion}
                                        style={{ height: '17%' }}
                                    />

                                    <p style={{ marginLeft: '80px', marginRight: '80px', marginBottom: '15px', marginTop: '0px' }} className="s15 subtitle_title jtx" dangerouslySetInnerHTML={{ __html: el_texto }}></p>

                                </CardContent>

                            </div>

                        </Grid>
                    </Grid>

                );

            }

        });
    }

    useEffect(() => {
        if (!idPadre) return;
        fetch(process.env.REACT_APP_URL_API + '/pregunta/' + idPadre + '/respuestas', {
            method: 'get',
            headers: {
                'token': token,
                'Content-Type': 'application/json'
            }
        })
            .then(response => {
                if (response.status === 401 || response.status === 403) {
                    session.handleNotAuthorized();
                    return;
                }
                response.json()
                    .then(data => {
                        if (data?.success) {
                            if (data.data.n_preguntas > 0) {
                                setAnswerList(data.data.respuestas);
                            }
                            if (data.data.n_preguntas === 0) {
                                setAnswerList(data.data.respuestas);
                            }
                        } else {
                            // asumir data success = true pero vacía // TODO: normalizar en el back (debe venir success = true aunque no hayan registros)
                            setHiddenQuestions(true);
                            setOpcion(OPCION_PREGUNTA);
                            setOpcionTitulo(OPCION_TITULO_PREGUNTA);
                            setHiddenAnswer(true);
                        }
                    });
            })
            .catch(e => {
                console.error(e);
            });
    }, [actualizarRespuestas]);

    /** @type {JSX.Element[]} */
    const listadoRespuestas = [];
    if (questionList && questionList instanceof Array && questionList.length > 0) {

        Object.keys(questionList).forEach(key => {

            if ((questionList[key].param_tipo === 1 || questionList[key].param_tipo === 3) && questionList[key].id === idPadre) {

                listadoRespuestas.push(
                    <Grid container key={questionList[key].id}>

                        <Grid item xs={12}>

                            <div className="CardEfect" hidden={hiddenAnswer}>
                                <CardContent id={questionList[key].id} className="CardEfect" >
                                    <CardHeader
                                        avatar={
                                            <Avatar aria-label="recipe" className="MuiAvatar-colorForced">
                                                {questionList[key].nombres.charAt(0)}
                                            </Avatar>
                                        }
                                        title={questionList[key].titulo}
                                        subheader={questionList[key].nombres + " " + questionList[key].apellidos + ". " + questionList[key].fecha_creacion}
                                        style={{ height: '17%' }}
                                    />

                                    <p style={{ marginLeft: '80px', marginRight: '80px', marginBottom: '15px', marginTop: '0px' }} className="s15 subtitle_title_accordion jtx" dangerouslySetInnerHTML={{ __html: questionList[key].texto }}></p>

                                </CardContent>

                            </div>

                        </Grid>
                    </Grid>
                );

            }

        });
    }

    if (answerList?.length > 0) {

        Object.keys(answerList).forEach(key => {
            if (answerList[key].param_tipo === 2 && answerList[key].id_padre === idPadre) {
                listadoRespuestas.push(
                    <Grid container key={answerList[key].id}>

                        <Grid item xs={1}>
                            <div className="CardEfect" hidden={hiddenAnswer} >
                            </div>
                        </Grid>

                        <Grid item xs={11}>

                            <div className="CardEfect" hidden={hiddenAnswer} >
                                <CardContent id={answerList[key].id} className="CardEfect" >
                                    <CardHeader
                                        avatar={
                                            <Avatar aria-label="recipe" className="MuiAvatar-colorForced">
                                                {answerList[key].nombres.charAt(0)}
                                            </Avatar>
                                        }
                                        title={answerList[key].titulo}
                                        subheader={answerList[key].nombres + " " + answerList[key].apellidos + ". " + answerList[key].fecha_creacion}
                                        style={{ height: '17%' }}
                                    />
                                    <p style={{ marginLeft: '80px', marginRight: '80px', marginBottom: '15px', marginTop: '0px' }} className="s15 subtitle_title_accordion jtx" dangerouslySetInnerHTML={{ __html: answerList[key].texto }}></p>
                                </CardContent>

                            </div>

                        </Grid>
                    </Grid>
                );
            }
        });
    }

    const [opcionSelect, setOpcionSelect] = useState([]);
    const [opcionSelected, setOpcionSelected] = useState(1);

    useEffect(() => {
        const jsonSelect = {
            data: [
                {
                    "id": 1,
                    "nombre": "Pregunta"
                },
                {
                    "id": 3,
                    "nombre": "Comentario"
                }
            ]
        };
        setOpcionSelect(jsonSelect.data);
    }, []);

    const classQA = [];
    classQA.push(
        <div key="QA0">
            <div className="id s18 fontn cfff"></div>
            <div className="meta rel">

                <Grid container>

                    <Grid item xs={12}>
                        <div className="section" >
                            <h1 className="s18 lbl title_section fontb c333">Agregar {opcion}:</h1>
                            <br></br><br></br>
                            {hiddenAnswer &&
                                <div>
                                    <FormControl variant="outlined">
                                        <InputLabel id="demo-simple-select-outlined-label" className="InputText">Opción:</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-outlined-label"
                                            id="demo-simple-select-outlined"
                                            value={opcionSelected}
                                            onChange={(e) => {
                                                setOpcionSelected(e.target.value);
                                            }}
                                            label="Opción"
                                            className="InputText"
                                            style={{ minWidth: '10rem' }}
                                        >
                                            {opcionSelect.map(Values =>
                                                <MenuItem value={Values.id} key={Values.id}>{Values.nombre}</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                    <br></br><br></br>
                                </div>
                            }

                            <TextField className="InputText" id="question-title" label="Título o resumen" variant="outlined" fullWidth
                                InputProps={{
                                    className: "InputText"
                                }}
                                InputLabelProps={{
                                    className: "InputText",
                                }}
                                error={errorCourseTitle}
                                //errormessage={errorCoursetitle}
                                onBlur={() => {
                                    if (courseTitle || courseTitle != '' || courseTitle != undefined) {
                                        setErrorCourseTitle(false);
                                        setMensCourseTitle("");
                                    } else {
                                        setErrorCourseTitle(true);
                                        setMensCourseTitle("Este campo es obligatorio");

                                    }
                                }}
                                onChange={(e) => {
                                    setCourseTitle(e.target.value);
                                    if (e.target.value != '' && e.target.value.length < 251
                                        && textValue && textValue != '' && textValue != undefined && textValue != 'null' && textValue != null && textValue != '<p><br></p>') {
                                        setBotonStatus("button_accept");
                                        setDisabledAddClass(false);
                                    } else {
                                        setBotonStatus("button_accept_disable");
                                        setDisabledAddClass(true);
                                    }
                                }}
                                helperText={mensCourseTitle}
                                type="text"
                                value={courseTitle}
                            />
                        </div>

                        <br />
                        <h3 className="title_section">Detalles:</h3>
                        <br />
                        <EditorToolbar shortId="Descripcion" />
                        <ReactQuill
                            id="quill_descripcion"
                            key="1"
                            item="item1"
                            theme="snow"
                            value={textValue}
                            placeholder={"Ingrese los detalles."}
                            modules={modules("Descripcion")}
                            formats={formats}
                            error={errorTextValue}
                            //errormessage={errorTextValue}
                            onBlur={() => {
                                if (!textValue || textValue == '' || textValue == undefined || textValue === null || textValue === '<p><br></p>') {
                                    setErrorTextValue(true);
                                    setMensTextValue("Este campo es obligatorio");
                                } else {
                                    setErrorTextValue(false);
                                    setMensTextValue("");
                                }
                            }}
                            onChange={(e) => {
                                setTextValue(e);
                                if (courseTitle != '' && courseTitle.length < 251
                                    && e && e != '' && e != undefined && e != null && e != '<p><br></p>') {
                                    setBotonStatus("button_accept");
                                    setDisabledAddClass(false);
                                } else {
                                    setBotonStatus("button_accept_disable");
                                    setDisabledAddClass(true);
                                }
                            }}
                            helperText={mensTextValue}
                            type="text"
                        />
                        <div >
                            {opcion == OPCION_RESPUESTA &&
                                <Button type="button" variant="contained" style={{ float: 'right', marginLeft: '15px', marginTop: '15px' }} className="button_cancel" onClick={ResponderCancel}>
                                    Volver
                                </Button>
                            }
                            <Button type="button" disabled={disabledAddClass} onClick={ValidarForm} variant="contained" color="primary" style={{ float: 'right', marginLeft: '15px', marginTop: '15px' }} className={botonStatus} /*onClick={save}*/>
                                Publicar {opcion}
                            </Button>
                        </div>
                    </Grid>
                </Grid>

            </div>
            <h1 className="s13 dur fontn c777"></h1>
        </div>
    );

    //Listado preguntas y respuestas
    classQA.push(
        <div key="QA1">
            <div className="id s18 fontn cfff"></div>
            <div className="meta rel">
                <h1 className="s18 lbl title_section fontb c333">{opcionTitulo}</h1>
                <br></br><br></br>
                <div className="rel">

                    {mensajeSinDatos != "" && mensajeSinDatos}

                    <Grid container>
                        <Grid item xs={12}>
                            {listadoPreguntas}
                            {listadoRespuestas}
                        </Grid>
                    </Grid>

                </div>
                <h1 className="s13 dur fontn c777"></h1>
            </div>
        </div>
    );

    function handleUpdateCheckClass() {
        setActualizar(idClase);
    }

    function marcarClaseCompletada() {
        setUpdatingClassState(true);
        updateEstadoClase({
            token,
            userId: userID,
            classId: classIdParam,
            estado: 1
        }).then(() => {
            if (nextIdClass) {
                history.push('/class/' + courseID + '/' + nextIdClass + '/' + nextUnidadID + '/' + section + '/' + certifiedID);
                handleClaseClick(nextIdClass);
                setParamTipoClase(0);
            } else {
                history.push('/class/' + courseID + '/' + classIdParam + '/' + unidadID + '/' + section + '/' + certifiedID);
                handleClaseClick(classIdParam);
                setParamTipoClase(0);
            }
            if (actualizar === "0") {
                setActualizar("1");
            } else {
                setActualizar("0");
            }
        }).catch(() => {
            enqueueSnackbar("Error al finalizar clase", { variant: 'error' });
        }).finally(() => {
            setUpdatingClassState(false);
        });
    }

    const descargarCertificado = () => {

        let Certificado = {
            "id_usuario": userID,
            "id_curso": courseID
        };

        fetch(process.env.REACT_APP_URL_API + '/certificado', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'token': token
            },
            body: JSON.stringify(Certificado)
        })
            .then(async res => ({
                filename: 'Certificado.pdf',
                blob: await res.blob()
            }))
            .then(resObj => {
                // It is necessary to create a new blob object with mime-type explicitly set for all browsers except Chrome, but it works for Chrome too.
                const newBlob = new Blob([resObj.blob], { type: 'application/pdf' });
                // MS Edge and IE don't allow using a blob object directly as link href, instead it is necessary to use msSaveOrOpenBlob
                if (window.navigator?.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(newBlob);
                } else {
                    // For other browsers: create a link pointing to the ObjectURL containing the blob.
                    const objUrl = window.URL.createObjectURL(newBlob);
                    let link = document.createElement('a');
                    link.href = objUrl;
                    link.download = resObj.filename;
                    link.click();
                    // For Firefox it is necessary to delay revoking the ObjectURL.
                    setTimeout(() => { window.URL.revokeObjectURL(objUrl); }, 250);
                }
            })
            .catch(e => {
                console.error(e);
            });

    };

    const compartirLinkedIn = () => {

        fetch(process.env.REACT_APP_URL_API + '/compartir-pdf/' + userID + '/curso/' + courseID, {
            method: 'get',
            headers: {
                'token': token,
                'Content-Type': 'application/json'
            }
        })
            .then(response => {
                if (response.status === 401 || response.status === 403) {
                    session.handleNotAuthorized();
                    return;
                }
                response.json()
                    .then(data => {
                        if (data?.success) {
                            window.open(data.data);
                        } else {
                            enqueueSnackbar(data?.mensaje || "Error al compartir certificado", { variant: 'error' });
                        }
                    });
            })
            .catch(e => {
                console.error(e);
            });

    };

    const styles = (theme) => ({
        root: {
            margin: 0,
            padding: theme.spacing(2),
            minWidth: '100%'
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500]
        }
    });

    const DialogTitle = withStyles(styles)((props) => {
        const { children, classes, onClose, ...other } = props;
        return (
            <MuiDialogTitle disableTypography className={classes.root} {...other}>
                <Typography variant="h6">{children}</Typography>
                {onClose ? (
                    <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                ) : null}
            </MuiDialogTitle>
        );
    });

    function handleClickMisCursos(event) {
        event.preventDefault();
        history.push("/my-courses");
    }

    function handleClickCurso(event) {
        event.preventDefault();
        history.push('/owncourse/' + courseID + '/Mis cursos/' + 0);
    }

    function handleClickCursoSectionMisDiplomados(event) {
        event.preventDefault();
        history.push("/my-certified");
    }

    function handleClickCursoMiDiplomado(event) {
        event.preventDefault();
        history.push("/owncertified/" + certifiedID + "/Mis programas");
    }

    function handleClickCursoDiplomado(event) {
        event.preventDefault();
        history.push('/owncourse/' + courseID + '/Mi programa/' + certifiedID);
    }

    return (
        <div className="App rel">
            <Breadcrumbs aria-label="breadcrumb" className="breadcrumbs_link_inneret">

                {section == "Mi programa" &&
                    <Link color="inherit" onClick={handleClickCursoSectionMisDiplomados} className="breadcrumbs_link_inneret">
                        Mis programas
                    </Link>
                }
                {section == "Mi programa" &&
                    <Link color="inherit" onClick={handleClickCursoMiDiplomado} className="breadcrumbs_link_inneret">
                        Mi programa
                    </Link>
                }
                {section == "Mi programa" &&
                    <Link color="inherit" onClick={handleClickCursoDiplomado} className="breadcrumbs_link_inneret">
                        Curso
                    </Link>
                }

                {section != "Mi programa" &&
                    <Link color="inherit" onClick={handleClickMisCursos} className="breadcrumbs_link_inneret">
                        {section}
                    </Link>
                }
                {section != "Mi programa" &&
                    <Link color="inherit" onClick={handleClickCurso} className="breadcrumbs_link_inneret">
                        Curso
                    </Link>
                }

                <Typography color="textPrimary" className="breadcrumbs_link">
                    Clase
                </Typography>

            </Breadcrumbs>

            <br></br>

            <Grid container>

                <Grid item xs={12} sm={12} md={8} lg={8} xl={9}  >

                    {elVideo && paramTipoClase == TIPO_CLASE.VIDEO &&
                        <div className="course-page rel flex video" >
                            <ReactPlayer
                                url={elVideo}
                                width='100%'
                                height='100%'
                                controls
                                playing={true}
                                volume={0.2}
                                muted={false}
                                playbackRate={1}
                                className="react-player"
                                onPlay={handleUpdateCheckClass}
                                onEnded={marcarClaseCompletada}
                            />
                        </div>
                    }

                    {recursoUrl && paramTipoClase == TIPO_CLASE.ZIP &&

                        <Grid container spacing={3}>

                            <Grid item xs={1} sm={2} md={3} lg={4} xl={4} >
                                <br></br>
                                <h2 className="s24 title_section fontb c333">Descargar:</h2>
                            </Grid>
                            <Grid item xs={10} sm={8} md={6} lg={4} xl={4} >

                                <div className="course-page rel flex" style={{ marginTop: '200px', marginBottom: '0px' }}>
                                    <Button color="default" fullWidth variant="contained" href={recursoUrl} >DESCARGAR RECURSOS</Button>
                                </div>
                                <br></br><br></br>
                                <div className="course-page rel flex" style={{ marginTop: '0px', marginBottom: '200px' }}>
                                    <Button onClick={() => {
                                        marcarClaseCompletada();
                                    }}
                                        fullWidth variant="contained" color="primary"
                                        className={["button_accept", commonClases.alternateDisabled].join(' ')}
                                        disabled={updatingClassState}
                                    >
                                        <CircularProgress style={{ display: updatingClassState ? 'unset' : 'none' }}/>
                                        Finalizar clase
                                    </Button>
                                </div>

                            </Grid>
                            <Grid item xs={1} sm={2} md={3} lg={4} xl={4} >
                                <div className="course-page rel flex" style={{ float: 'right', marginBottom: '10px', marginTop: '400px' }}>
                                </div>
                            </Grid>
                        </Grid>
                    }

                    {paramTipoClase == TIPO_CLASE.TEST && <QuestionBoxTest evaluationID={evaluationIDtest} marcarClaseCompletada={marcarClaseCompletada} updatingClassState={updatingClassState} />}

                    {paramTipoClase == TIPO_CLASE.TEST_FORMATIVO && <QuestionBox evaluationID={evaluationID} marcarClaseCompletada={marcarClaseCompletada} updatingClassState={updatingClassState} />}

                    {/* Modal con botones de certificados*/}

                    <Dialog open={open} onClose={handleClose} aria-labelledby="customized-dialog-title" >
                        <DialogTitle id="form-dialog-titles" onClose={handleClose} className="modal_title"><div className="modal_title">Obtener certificado</div></DialogTitle>
                        <DialogContent dividers className="modal_content">

                            <Grid container spacing={3}>

                                <Grid item xs={2} sm={2} md={2} lg={2} xl={2} > </Grid>
                                <Grid item xs={8} sm={8} md={8} lg={8} xl={8}  >
                                    <Button onClick={descargarCertificado} variant="contained" color="primary" className="button_accept" fullWidth>
                                        <GetAppIcon size="small" /> &nbsp; Descargar
                                    </Button>

                                    <br></br> <br></br>
                                    <Button onClick={compartirLinkedIn} variant="contained" color="primary" className="button_accept" fullWidth>
                                        <ShareIcon size="small" /> &nbsp; &nbsp;LinkedIn
                                    </Button>
                                </Grid>
                                <Grid item xs={2} sm={2} md={2} lg={2} xl={2} > </Grid>
                            </Grid>

                        </DialogContent>
                        <DialogActions className="modal_footer">

                            <Button onClick={handleClose} variant="contained" color="primary" className="button_cancel">
                                Cancelar
                            </Button>

                        </DialogActions>
                    </Dialog>

                    {/* Modal con botones de certificados*/}

                    {/* Modal valoración */}

                    <Dialog open={openRating} onClose={handleCloseRating} aria-labelledby="customized-dialog-title" /*style={{padding:'1em', minWidth:'700px'}}*/>

                        <DialogTitle id="form-dialog-rating" onClose={handleCloseRating} className="modal_title"><div className="modal_title">Calificar curso</div></DialogTitle>
                        <DialogContent dividers className="modal_content" >

                            <Grid container spacing={3}>

                                <Grid item xs={2} sm={3} md={3} lg={3} xl={3} > </Grid>
                                <Grid item xs={9} sm={6} md={6} lg={6} xl={6}  >
                                    <div>
                                        &nbsp;&nbsp;&nbsp;<h3>¿Cómo valoras este curso?</h3>
                                    </div>
                                    <br></br><br></br>
                                    <CourseRatingEditor value={valueRating} handleRatingChange={handleSendRating} />
                                </Grid>
                                <Grid item xs={1} sm={3} md={3} lg={3} xl={3} > </Grid>
                            </Grid>
                            <br></br> <br></br>
                            {valueCursoComentado === 1 &&
                                <>
                                    <div>
                                        <br></br>
                                        <TextField className="InputText" id="question-title-modal" label="Título o resumen" variant="outlined" fullWidth
                                            InputProps={{
                                                className: "InputText"
                                            }}
                                            InputLabelProps={{
                                                className: "InputText",
                                            }}

                                            error={errorCourseTitleModal}
                                            //errormessage={errorCoursetitlemodal}
                                            onBlur={(e) => {
                                                if (courseTitleModal || courseTitleModal != '' || courseTitleModal != undefined) {
                                                    setErrorCourseTitleModal(false);
                                                    setMensCourseTitleModal("");
                                                } else {
                                                    setErrorCourseTitleModal(true);
                                                    setMensCourseTitleModal("Este campo es obligatorio");
                                                }
                                            }}
                                            onChange={(e) => {
                                                setCourseTitleModal(e.target.value);
                                                if (e.target.value != '' && e.target.value.length < 251
                                                    && textValueModal && textValueModal != '' && textValueModal != undefined && textValueModal != 'null' && textValueModal != null && textValueModal != '<p><br></p>') {
                                                    setBotonStatusModal("button_accept");
                                                    setDisabledAddClassModal(false);
                                                } else {
                                                    setBotonStatusModal("button_accept_disable");
                                                    setDisabledAddClassModal(true);
                                                }
                                            }}
                                            helperText={mensCourseTitleModal}
                                            type="text"
                                            value={courseTitleModal}
                                        />
                                    </div>

                                    <br />
                                    <TextField
                                        id="question-detalle"
                                        label="Descripción"
                                        type="text"
                                        fullWidth
                                        size="medium"
                                        variant="outlined"
                                        multiline
                                        rows={4}
                                        className="InputText"
                                        InputProps={{
                                            className: "InputText"
                                        }}
                                        InputLabelProps={{
                                            className: "InputText"
                                        }}
                                        value={textValueModal}
                                        //placeholder={"Ingrese descripción..."}
                                        error={errorTextValueModal}
                                        //errormessage={errorTextValue}
                                        onBlur={() => {
                                            if (!textValueModal || textValueModal == '' || textValueModal == undefined || textValueModal === null) {
                                                setErrorTextValueModal(true);
                                                setMensTextValueModal("Este campo es obligatorio");
                                            } else {
                                                setErrorTextValueModal(false);
                                                setMensTextValueModal("");
                                            }
                                        }}
                                        onChange={(e) => {
                                            setTextValueModal(e.target.value);
                                            if (courseTitleModal != '' && courseTitleModal.length < 251
                                                && e.target.value && e.target.value != '' && e.target.value != undefined && e.target.value != null) {
                                                setBotonStatusModal("button_accept");
                                                setDisabledAddClassModal(false);
                                            } else {
                                                setBotonStatusModal("button_accept_disable");
                                                setDisabledAddClassModal(true);
                                            }
                                        }}
                                        helperText={mensTextValueModal}
                                    />
                                    <br></br><br></br>
                                </>
                            }
                        </DialogContent>

                        <DialogActions className="modal_footer">
                            {valueCursoComentado === 1 &&
                                <Button onClick={ValidarFormModal} disabled={disabledAddClassModal} variant="contained" color="primary" className={botonStatusModal}>
                                    Publicar
                                </Button>
                            }
                            &nbsp;
                            <Button onClick={handleCloseRating} variant="contained" color="primary" className="button_cancel">
                                Cancelar
                            </Button>

                        </DialogActions>
                    </Dialog>

                    {/* Modal valoracion*/}

                    <Hidden smDown>
                        <Box sx={{ width: '100%', typography: 'body1' }}>
                            <TabContext value={valueTab}>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    <TabList onChange={handleChange} variant="fullWidth" aria-label="lab API tabs example">
                                        <Tab label="DESCRIPCIÓN" value="1" />
                                        <Tab label="PREGUNTAS Y RESPUESTAS" value="2" />
                                    </TabList>
                                </Box>
                                <TabPanel value="1">{classDescription}</TabPanel>
                                <TabPanel value="2">{classQA}</TabPanel>
                            </TabContext>
                        </Box>
                    </Hidden>

                    <Hidden mdUp>
                        <Box sx={{ width: '100%', typography: 'body1' }}>
                            <TabContext value={valueTab}>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>

                                    <TabList onChange={handleChange} variant="fullWidth" aria-label="lab API tabs example">
                                        <Tab label="Detalles del curso" value="1" />
                                        <Tab label="DESCRIPCIÓN" value="2" />
                                        <Tab label="PREGUNTAS Y RESPUESTAS" value="3" />
                                    </TabList>

                                </Box>
                                <TabPanel value="1">{estadisticasCurso}
                                    <div className="stack-acordeones stacked-block2" style={{ pointerEvents: updatingClassState || finalizandoCurso ? 'none' : 'unset' }} >
                                        {acordeonesUnidades}
                                    </div>
                                </TabPanel>

                                <TabPanel value="2">{classDescription}</TabPanel>
                                <TabPanel value="3">{classQA}</TabPanel>

                            </TabContext>
                        </Box>
                    </Hidden>

                </Grid>

                <Grid item xs={1} sm={1} md={4} lg={4} xl={3} style={{ paddingLeft: '1rem' }} >
                    <Hidden smDown>
                        <div className="section" style={{ marginRight: '10px' }}>
                            {estadisticasCurso}
                            <h2 className="title_section s24 fontb">Detalles del curso</h2>
                            <div className="course-videos aic flex">
                                <div className="stack-acordeones stacked-block" style={{ pointerEvents: updatingClassState || finalizandoCurso ? 'none' : 'unset' }} >
                                    {acordeonesUnidades}
                                </div>
                            </div>
                        </div>
                    </Hidden>
                </Grid>

            </Grid>

        </div>

    );
}

export default CourseViewPage;
