import React from "react";
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    "common": {
        "& svg": {
            opacity: '0.4'
        }
    },
    "main": {
        "&:hover": {
            cursor: props => {
                return props['hasVideo'] ? 'pointer' : '';
            }
        },
        "&:hover svg": {
            color: '#e4e4e4',
            opacity: '1'
        }
    },
    "onlySvg": {
        "& svg:hover": {
            color: '#e4e4e4',
            opacity: '1',
            cursor: props => {
                return props['hasVideo'] ? 'pointer' : '';
            }
        }
    }
});

/**
 * @param {Object} params
 * @param {string} params.image
 * @param {string} [params.alt]
 * @param {string} [params.className]
 * @param {boolean} [params.hasVideo]
 * @param {() => void} [params.handleShowVideo]
 * @param {boolean} [params.fullAreaSelect=true] - default = true
 * @param {React.CSSProperties} [params.style]
 * @returns {JSX.Element}
 */
export default function ImagePreview({ image, alt, className, hasVideo, handleShowVideo, fullAreaSelect = true, style }) {
    const onClick = hasVideo && typeof handleShowVideo === 'function' ? (e) => { handleShowVideo(); e.preventDefault(); e.stopPropagation(); } : null;
    const classes = useStyles({ hasVideo: true });
    const prevTitle = "mostrar vista previa";
    return (<>
        <div onClick={ fullAreaSelect ? onClick : null } style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'absolute', ...style }} className={ [className, classes["common"], fullAreaSelect ? classes["main"] : classes["onlySvg"] ].join(' ') }>
            {hasVideo &&
            <>
                <div title={ prevTitle } style={{ background: 'linear-gradient(180deg,rgba(28,29,31,0) 0%,rgba(28,29,31,0.29) 100%)', width: '100%', height: '100%', position: 'absolute' }}></div>
                <div style={{ position: 'absolute', color: 'white' }} title={ prevTitle } onClick={ fullAreaSelect ? null : onClick }>
                    <PlayCircleFilledIcon style={{ width: '5rem', height: '5rem' }}/>
                </div>
            </>
            }
        </div>
        <img src={ image } alt={ alt } style={{ width: '100%' }}/>
    </>);
}
