import React, { useState, useEffect, useContext, useRef } from 'react';
import { useHistory } from "react-router";
import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { ButtonGroup } from "@material-ui/core";
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '../componentes/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import CardContent from '@material-ui/core/CardContent';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import RemoveShoppingCartIcon from '@material-ui/icons/RemoveShoppingCart';
import CircularProgress from '@material-ui/core/CircularProgress';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import axios from 'axios';
import { useSnackbar } from "notistack";

import { BotonContext } from "../BotonContext";

import CouponCampo from '../componentes/CouponCampo';
import CouponFormulario from '../componentes/CouponFormulario';
import commonIO from '../util/common.io';
import i18n from '../lib/i18n';
import utils from '../util/utils';
import { useSession } from '../util/session';
import api from '../lib/api';
import { useCart } from '../util/Cart';

const EmptyCart = styled.div``;
const Row = styled.div``;
const Col = styled.div``;
const Col2 = styled.div``;
const Col4 = styled.div``;
const ImgM = styled.img``;

/**
 * @param {api.ItemCarrito[]} cart
 */
const updateCartLocalStorage = function (cart) {
  if (cart instanceof Array && cart.length) {
    localStorage.setItem('cart', JSON.stringify(cart));
  } else {
    localStorage.removeItem('cart');
  }
};

/**
 * @typedef OrganizationData
 * @property {string} nombre
 * @property {string} identificador
 * @property {string} correo
 * @property {string} tlf
 * @property {number} country
 * @property {boolean} facturacion
 */

/**
 * @param {Object} obj
 * @param {(data: OrganizationData) => void} obj.onChange
 * @returns {JSX.Element}
 */
const OrganizationForm = function ({ onChange }) {

  const [nombre, setNombre] = useState('');
  const [identificador, setIdentificador] = useState('');
  const [correo, setCorreo] = useState('');
  const [tlf, setTlf] = useState('');
  const [country, setCountry] = useState(/** @type {number} */(null));
  const [facturacion, setFacturacion] = useState(false);
  const { countryList } = useContext(BotonContext);
  onChange({ nombre, identificador, correo, tlf, country, facturacion });

  return (<>
    <div style={{ display: 'flex', border: 'thin solid gray', marginTop: '1rem', padding: '1rem', cursor: 'default', flexDirection: 'column', gap: '1rem' }}>

      <TextField id="nombre" label="Nombre institución"
        InputProps={{ className: "InputText" }}
        InputLabelProps={{ className: "InputText" }}
        maxRows={12} minRows={7} variant="outlined" fullWidth
        onChange={(e) => { setNombre(e.target.value); }}
        type="text"
        value={nombre}
        required
      />

      <TextField id="identificador" label="Identificador"
        InputProps={{ className: "InputText" }}
        InputLabelProps={{ className: "InputText" }}
        maxRows={12} minRows={7} variant="outlined" fullWidth
        onChange={(e) => { setIdentificador(e.target.value); }}
        type="text"
        value={identificador}
        required
      />

      <TextField id="correo" label="Correo"
        InputProps={{ className: "InputText" }}
        InputLabelProps={{ className: "InputText" }}
        maxRows={12} minRows={7} variant="outlined" fullWidth
        onChange={(e) => { setCorreo(e.target.value); }}
        type="text"
        value={correo}
        required
      />

      <TextField id="tlf" label="Teléfono"
        InputProps={{ className: "InputText" }}
        InputLabelProps={{ className: "InputText" }}
        maxRows={12} minRows={7} variant="outlined" fullWidth
        onChange={(e) => { setTlf(e.target.value); }}
        type="text"
        value={tlf}
        required
      />

      <FormControl variant="outlined">
        <InputLabel id="demo-simple-select-outlined-label" className="InputText">País</InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          value={country}
          onChange={(e) => setCountry(e.target.value)}
          label="País"
          MenuProps={{ disableScrollLock: true }}
        >
          <MenuItem value="" ><em>Seleccionar</em></MenuItem>
          {countryList.map(p =>
            <MenuItem value={p.id_pais} key={p.id_pais}>{p.nombre}</MenuItem>
          )}
        </Select>
      </FormControl>

      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Checkbox
          className="checkbox"
          checked={facturacion}
          onChange={(e) => { setFacturacion(e.target.checked); }}
          color="primary"
          inputProps={{ 'aria-label': 'primary checkbox' }}
        />
        <div>Facturación</div>
      </div>

      <div style={{ textAlign: 'center', marginTop: '-1rem' }}>Agregó un plan multipersona, debe completar esta información.</div>

    </div>
  </>);

};

export default function Cart() {

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    document.title = "Detalle de compra";
  });

  const history = useHistory();
  const { isLogged } = useSession();
  const { cart, setCart, data_user, token } = useContext(BotonContext);

  const [subtotal, setSubtotal] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [total, setTotal] = useState(0);
  const [validatingCoupon, setValidatingCoupon] = useState(false);
  const [dataCoupon, setDataCoupon] = useState(/** @type {{data: api.DataCoupon, formSubmitted: boolean}} */(null));
  const hasAppliedCoupon = !!dataCoupon && dataCoupon.formSubmitted;
  const { hasItemPlan } = useCart();
  const organizationData = useRef(/** @type {OrganizationData} */({}));

  const showOrganizationForm =  hasItemPlan();

  function navigate(id_curso, ruta, param) {
    window.location.assign(`#/${ruta}/${id_curso}/${param}`);
  }

  function deleteArticle(id, tipo) {
    const newCart = cart.slice(); // clonar
    newCart.map((course, i) => {
      if (course.id === id && course.tipo === tipo) {
        newCart.splice(i, 1);
      }
    });
    setCart(newCart);
    updateCartLocalStorage(newCart);
  }

  function modifyQuantity(id, operator) {
    const newCart = cart.slice(); // clonar
    newCart.map((course) => {
      if (course.id === id) {
        if (operator === '-' && course.quantity > 1) {
          course.quantity -= 1;
        }
        if (operator === '+') {
          course.quantity += 1;
        }
      }
    });
    setCart(newCart);
    updateCartLocalStorage(newCart);
  }

  const setTotals = function () {
    let subtotalVar = 0;
    let discountVar = 0;
    cart && cart.map(course => {
      subtotalVar += parseFloat(course.price) * course.quantity;
      if (course.discount) {
        //discountVar += course.price * course.discount * course.quantity
      }
    });
    setSubtotal(subtotalVar);
    setDiscount(discountVar);
    setTotal(subtotalVar - discountVar);
  };

  useEffect(() => {
    // TODO?: manejar caso en que ya se haya aplicado cupón.
    setTotals();
  }, [cart]);

  const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      '& > *': {
        marginBottom: theme.spacing(2)
      },
      '& .MuiBadge-root': {
        marginRight: theme.spacing(4)
      }
    }
  }));

  const useStyles3 = makeStyles((theme) => ({
    root: {
      display: 'flex',
      '& > * + *': {
        marginLeft: theme.spacing(2)
      }
    }
  }));

  const classes = useStyles();
  const classes3 = useStyles3();

  //Modal ventana emergente
  const [open, setOpen] = React.useState(false);
  const [openload, setOpenLoad] = React.useState(false);
  const [openrepeat, setOpenRepeat] = React.useState(false);
  const [opennan, setOpenNan] = React.useState(false);
  const [courserepeat, setOpenCourseRepeat] = useState([]); // arreglo de cursos comprados

  const emptyCart = !cart || !(cart instanceof Array) || !cart.length;

  const handleClickContinuar = async () => {
    if (emptyCart) return;
    if (showOrganizationForm && !validateOrganizationForm()) return;
    if (isNaN(total) || isNaN(subtotal) || isNaN(discount)) {
      handleClickOpenNan();
    }
    if (total === 0) {
      handleClickFree();
    }
    else {
      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpenLoad = async () => {
    setOpenLoad(true);
  };

  const handleCloseLoad = () => {
    setOpenLoad(false);
  };

  const handleClickOpenRepeat = async () => {
    setOpenRepeat(true);
  };

  const handleCloseRepeat = () => {
    setOpenRepeat(false);
  };

  const handleClickOpenNan = async () => {
    setOpenNan(true);
  };

  const handleCloseNan = () => {
    setOpenNan(false);
  };

  const removerCoupon = function () {
    setDataCoupon(null);
    setTotals();
  };

  /**
   * @param {api.ItemDetallePago[]} items
   */
  const actualizarPrecios = function (items) {
    const updated = items.filter(item => !!item.actualizado);
    /** @type {{[id: number]: api.ItemDetallePago}} */
    const indexed = {};
    updated.forEach(item => {
      indexed[item.id_producto] = item; // NOTA: se genera el diccionario de esta manera (en lugar de usar `reduce` u otras técnicas) para garantizar verificación de tipos en tiempo de desarrollo (TS).
    });
    cart.forEach(itemCart => {
      const id = itemCart.id;
      if (Object.prototype.hasOwnProperty.call(indexed, id)) {
        itemCart.price = indexed[id].precio.toString();
      }
    });
    setCart([ ...cart ]);
    updateCartLocalStorage(cart);
    // remover cupón en caso de que se haya aplicado uno. Habrá que re-aplicar manualmente.
    if (hasAppliedCoupon) {
      removerCoupon();
      enqueueSnackbar("Cupón removido por cambio de precios. Debe aplicarlo nuevamente.", {
        variant: 'warning',
        autoHideDuration: 4000
      });
    }
  };

  /**
   * @param {import('axios').AxiosResponse<*>} response
   */
  const handleDetallesPagosSuccess = function (response) {
    const data = /** @type {api.SuccessResponse<api.ItemDetallePago[]> & {url?: string}} */(response.data);
    const url = data.url;
    handleCloseLoad();
    if (data.success) {
      window.location.href = url;
    } else {
      if (url == 'curso_comprado') {
        setOpenCourseRepeat(data.data);
        handleClickOpenRepeat();
      } else {
        const message = data.mensaje || data['message'];
        enqueueSnackbar(message, {
          variant: 'warning',
          autoHideDuration: 4000
        });
        if (data.data instanceof Array && data.data.length) {
          actualizarPrecios(data.data);
        }
      }
    }
  };

  const handleDetallesPagosError = function (error) {
    if (error.response.status === 401) {
      history.push("/login");
      return;
    }
    handleCloseLoad();
    console.log("Error:", error);
  };

  /**
   * @param {string} [coupon]
   */
  const buildDetallePago = function (coupon) {
    //Pago
    const descripcion = "Venta de cursos e-napsis";
    const total_precio = i18n.roundPrice(subtotal);
    const total_dcto = i18n.roundPrice(discount);
    const total_pagar = i18n.roundPrice(total);
    const tipo_moneda = i18n.getCurrencyCode();

    //Body
    const columnas_body = cart.map(course => {
      const propiedades = {
        "tipo_producto": course.tipo,
        "id_producto": course.id,
        "col_1": course.name,
        "col_2": 1,
        "col_3": i18n.roundPrice(course.price),
        "col_4": total_dcto
      };
      return propiedades;
    });

    const json_payload = {
      "sistema": 2,
      "email_destino": data_user.email,
      "id_pais": i18n.getCountry().id_pais,
      "pago": {
        "descripcion": descripcion,
        "total_precio": total_precio,
        "total_dcto": total_dcto,
        "total_pagar": total_pagar,
        "tipo_moneda": tipo_moneda
      },
      "detalle": {
        "columns": {
          "tipo_producto": "Tipo",
          "id_producto": "Código",
          "col_1": "Detalle",
          "col_2": "Cantidad",
          "col_3": "Precio",
          "col_4": "Descuento"
        },
        "body": columnas_body
      }
    };

    const fullStructure = {
      "id_usuario": data_user.id_usuario,
      "id_estado_pago": 1, // status de pago (Ejemplo--> 1 = Pendiente)
      "numero_pago": null,
      "payload": json_payload,
      "comprobante_pago_data": null,
      "data_productos": columnas_body,
      "created_at": '0000-00-00 00:00:00',
      "updated_at": '0000-00-00 00:00:00',
      "cupon": coupon || ''
    };

    if (showOrganizationForm) {
      const currentDataOrg = organizationData.current;
      fullStructure["organizacion"] = {
        "razon_social": currentDataOrg.nombre,
        "identificador": currentDataOrg.identificador,
        "id_pais": currentDataOrg.country,
        "email": currentDataOrg.correo,
        "telefono": currentDataOrg.tlf,
        "facturacion": currentDataOrg.facturacion ? 1 : 0
      };
    }

    return fullStructure;

  };

  const forceLogin = function () {
    sessionStorage.clear();
    window.location.href = "#/login";
    window.location.reload();
  };

  /**
   * @return {boolean}
   */
  const validateOrganizationForm = function () {
    const data = organizationData.current;
    if (!data.correo || !data.nombre || !data.tlf || !data.identificador || typeof data.country !== 'number' ) {
      enqueueSnackbar("Debe especificar todos los datos de la organización", {
        variant: 'warning'
      });
      return false;
    }
    if (!commonIO.isValidEmail(data.correo)) {
      enqueueSnackbar("Debe especificar un correo válido", {
        variant: 'warning'
      });
      return false;
    }
    return true;
  };

  const handleClickFree = async () => {
    handleClickOpenLoad();

    if (!isLogged()) {
      forceLogin();
      return;
    }

    const DetallePago = buildDetallePago();
    axios.post(process.env.REACT_APP_URL_API + '/detallepagosgratuito', DetallePago, {
      headers: {
        'Content-Type': 'application/json',
        'token': token
      }
    })
      .then(handleDetallesPagosSuccess)
      .catch(handleDetallesPagosError);
  };

  const handleClickPay = async () => {
    if (!isLogged()) {
      forceLogin();
      return;
    }

    handleClose();
    handleClickOpenLoad();
    const DetallePago = buildDetallePago(dataCoupon?.formSubmitted ? dataCoupon.data.cupon : undefined);
    axios.post(process.env.REACT_APP_URL_API + '/detallepago', DetallePago, {
      headers: {
        'Content-Type': 'application/json',
        'token': token
      }
    })
      .then(handleDetallesPagosSuccess)
      .catch(handleDetallesPagosError);

  };

  function CleanCart() {
    setCart([]);
    localStorage.removeItem('cart');
  }

  const misCursosComprados = [];
  for (let i = 0; i < courserepeat.length; i++) {
    const curso_comprado = courserepeat[i];
    misCursosComprados.push(
      <span className={classes.root} key={i}>
        {<li>{curso_comprado}</li>}
      </span>
    );
  }

  const handleModalCouponSuccess = async function () {
    setDataCoupon({ ...dataCoupon, formSubmitted: true });
    const dc = dataCoupon.data;
    setSubtotal(dc.monto_base);
    setDiscount(dc.monto_descuento);
    setTotal(dc.monto_pagar);
  };

  const handleModalCouponCancel = async function () {
    setDataCoupon(null);
  };

  const handleCouponApply = async function (coupon) {
    if (!coupon || emptyCart) return;
    setValidatingCoupon(true);
    setDataCoupon(null);
    try {
      const payload = buildDetallePago(coupon);
      const { data, status } = await api.pagos.validarCupon({ token, payload });
      if (data.success) {
        setDataCoupon({
          data: data.data.data,
          formSubmitted: false // NOTA: esta asignación causa que se muestre el modal
        });
      } else {
        let mensaje = 'No es posible aplicar el cupón especificado';
        if (status === 404) {
          mensaje = "El cupón especificado no existe";
        } else if (status === 200) {
          mensaje = data.mensaje || data.message;
          if (data.data instanceof Array) {
            actualizarPrecios(data.data);
          }
        }
        enqueueSnackbar(mensaje, {
          variant: 'warning'
        });
      }
    } catch (error) {
      enqueueSnackbar("Error al aplicar cupón", {
        variant: 'error'
      });
      console.log(error);
    } finally {
      setValidatingCoupon(false);
    }
  };

  return (

    <div className="App rel" style={{ paddingBottom: '20px' }}>
      <Breadcrumbs aria-label="breadcrumb" className="breadcrumbs_link_inneret">
        <Typography color="textPrimary" className="breadcrumbs_link">
          Detalle de compra
        </Typography>
      </Breadcrumbs>

      <h1 className="title_section s24 fontb c333">Detalle de compra   </h1>

      <div id="module-content" className='flex-content-centered' style={{ minHeight: '10rem' }}>
        <div style={{ maxWidth: '1200px', width: '100%' }}>
          <Row >
            <Col4 id='limpiar-carro'>
              {!emptyCart &&
                <Button variant="contained" color="primary" className="button_cancel" onClick={() => CleanCart()} >
                  <RemoveShoppingCartIcon />Limpiar
                </Button>
              }
            </Col4>
          </Row>

          {emptyCart && <EmptyCart className="title_section s24 fontb c333" style={{ textAlign: 'center' }}>¡ Tu carrito está vacío !</EmptyCart>}

          <div id="item-list">

            {!emptyCart && cart.map((course, i) => {

              return <React.Fragment key={i}>
                <Row style={{ borderBottom: "thin solid gray" }} className="cart-item">

                  <div className='cart-columna-detalles-item'>
                    <ImgM src={utils.getImagenCurso(course.img)} alt='Imagen no disponible' onClick={() => navigate(course.id, course.ruta, course.param)} />
                    <div className="title_section" onClick={() => navigate(course.id, course.ruta, course.param)} style={{ cursor: 'pointer' }}>

                      {course.name}

                      {course.listado && course.listado != null && course.listado != '' && course.listado != undefined &&
                        <Typography >
                          Curso(s):
                        </Typography>
                      }
                      <Typography>
                        {course.listado && course.listado != null && course.listado != '' && course.listado != undefined && course.listado.map((lista) => {
                          return <li key={lista['id']} className="curso">{lista['titulo']}. {'Duración'}: {lista['duracion']} min.</li>;
                        })}
                      </Typography>
                    </div>
                  </div>

                  <div className='cart-columna-precio-item'>
                    <Col2>
                      <Button variant="outlined" className="border_bisque">
                        <div className="textDecorationLineNormal">{i18n.getCurrencySymbol()}{commonIO.agregarSeparadorMilesExt(course.price, '.', ',')}</div>
                      </Button>
                    </Col2>

                    <Col2 hidden={true}>
                      {course.quantity} Unidad &nbsp; &nbsp;  &nbsp; &nbsp;
                    </Col2>

                    <Col2 hidden={true}>

                      <ButtonGroup >
                        <Button disabled={true} aria-label="reduce" onClick={() => modifyQuantity(course.id, '-')} >
                          <RemoveIcon fontSize="small" />
                        </Button>
                        <Button hidden={true} disabled={true} aria-label="increase" onClick={() => modifyQuantity(course.id, '+')} >
                          <AddIcon fontSize="small" />
                        </Button>
                      </ButtonGroup>

                    </Col2>

                    <Col>
                      <Button variant="contained" color="secondary" className="button_delete" onClick={() => deleteArticle(course.id, course.tipo)}>
                        <span className='hide-on-xs'>Eliminar</span>
                        <div className='ico icon-trash icon-over-color only-on-xs' style={{ color: 'white', 'padding': 0, margin: 0, 'minHeight': '22px', fontSize: '20px' }}></div>
                      </Button>
                    </Col>
                  </div>

                </Row>
              </React.Fragment>;
            })}
          </div>

          {showOrganizationForm && <OrganizationForm onChange={data => {
            organizationData.current = data;
          }} />}

          <div style={{ cursor: 'default' }}>

            <div style={{ display: 'flex', alignItems: 'center' }}>

              {!!cart?.length && isLogged() &&
                <CouponCampo
                  showSpinner={validatingCoupon}
                  applied={dataCoupon?.formSubmitted}
                  onCouponApply={handleCouponApply}
                  onCouponClear={removerCoupon}
                />
              }

              <div className="TitleCart rel" style={{ flexGrow: 1 }}>

                {!emptyCart && <>
                  <CardContent id="totales-contenedor">

                    <div className='totales-monto'>
                      <Typography variant="h5" component="h1" className="title_price">
                        <span>Subtotal:</span>
                      </Typography>
                      <Typography variant="h5" component="h1" className="mount_price">
                        {i18n.getCurrencyLabel()} {i18n.formatPrice(subtotal)}
                      </Typography>
                    </div>

                    {!!discount &&
                      <div className='totales-monto'>
                        <Typography variant="h5" component="h1" className="title_price">
                          <span>Descuento:</span>
                        </Typography>
                        <Typography variant="h5" component="h1" className="mount_price_discount">
                          <span>{i18n.getCurrencyLabel()} {i18n.formatPrice(discount)}</span>
                        </Typography>
                      </div>
                    }
                    <div className='totales-monto'>
                      <Typography variant="h5" component="h1" className="title_price">
                        <span>Total a pagar:</span>
                      </Typography>
                      <Typography variant="h5" component="h1" className="mount_price">
                        {i18n.getCurrencyLabel()} {i18n.formatPrice(total)}
                      </Typography>
                    </div>

                  </CardContent>

                  <CardContent>
                    <Button onClick={handleClickContinuar} variant="contained" color="primary" className="button_accept">Continuar</Button>
                  </CardContent>

                </>}

              </div>

            </div>

            <div id="ventana_confirmacion_pago">

              <Dialog open={open} onClose={handleClose} aria-labelledby="customized-dialog-title">
                <DialogTitle id="alert-dialog-title" onClose={handleClose} className="modal_title">{"Desea proceder con el pago?"}</DialogTitle>
                <DialogContent dividers className="modal_content">
                  <Typography gutterBottom>
                    Si desea realizar el pago presione el botón &quot;PAGAR&quot;, de lo contrario presione &quot;CANCELAR&quot;.
                  </Typography>
                </DialogContent>
                <DialogActions className="modal_footer">

                  <Button onClick={handleClose} variant="contained" className="button_cancel">
                    Cancelar
                  </Button>
                  <Button onClick={handleClickPay} variant="contained" color="primary" autoFocus className="button_accept">
                    Pagar
                  </Button>

                </DialogActions>
              </Dialog>

            </div>

            <div id="cargando" className={classes3.root}>
              <Dialog open={openload} onClose={handleCloseLoad} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" >
                <DialogContent className="CentrarLoading">
                  <CircularProgress className="CircularProgress" />
                </DialogContent>

              </Dialog>
            </div>

            <div id="valida_curso_comprado">

              <Dialog open={openrepeat} onClose={handleCloseRepeat} aria-labelledby="customized-dialog-title" >
                <DialogTitle id="alert-dialog-title" onClose={handleCloseRepeat} className="modal_title">{"¡Advertencia!"}</DialogTitle>
                <DialogContent dividers className="modal_content">

                  <Typography gutterBottom>
                    Usted ya compró:
                  </Typography>
                  <Typography gutterBottom>
                    {misCursosComprados}
                  </Typography>
                  <Typography gutterBottom>
                    <b>Nota:</b> Debe eliminarlo(s) del carro para continuar su compra
                  </Typography>

                </DialogContent>
                <DialogActions className="modal_footer">
                  <Button onClick={handleCloseRepeat} variant="contained" autoFocus className="button_cancel">
                    Cancelar
                  </Button>
                </DialogActions>
              </Dialog>

            </div>

            <div id="valida_curso_Nan">

              <Dialog open={opennan} onClose={handleCloseNan} aria-labelledby="customized-dialog-title" >
                <DialogTitle id="alert-dialog-title" onClose={handleClose} className="modal_title">{"¡Advertencia!"}</DialogTitle>
                <DialogContent dividers className="modal_content">
                  <Typography gutterBottom>
                    Al menos uno de los cursos aún no posee monto:
                  </Typography>

                  <Typography gutterBottom>
                    <b>Nota:</b> Debe eliminarlo(s) del carro para continuar su compra
                  </Typography>

                </DialogContent>
                <DialogActions className="modal_footer">
                  <Button onClick={handleCloseNan} variant="contained" autoFocus className="button_cancel">
                    Cancelar
                  </Button>

                </DialogActions>
              </Dialog>

            </div>

            {!!data_user && isLogged() && dataCoupon &&
              <CouponFormulario onSuccess={handleModalCouponSuccess} onCancel={handleModalCouponCancel} open={!!dataCoupon && !dataCoupon?.formSubmitted} dataCoupon={dataCoupon?.data} />
            }

          </div>

        </div>
      </div>

    </div>

  );
}
