import React, { useState, useContext } from "react";
import { makeStyles } from '@material-ui/core/styles';
import CheckIcon from '@material-ui/icons/Check';
import ButtonCircularProgress from './ButtonCircularProgress';
import { useCart } from "../util/Cart";
import { BotonContext } from "../BotonContext";
import { TIPO_PRODUCTO } from "../lib/constants";

const useStyles = makeStyles({
    "common": {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    "add": {
        borderColor: 'var(--color-border-form-butons) !important'
    },
    "inCart": {
        color: 'white !important',
        backgroundColor: '#016201ab !important',
        borderColor: '#016201ab !important'
    },
    "disabled":{
        backgroundColor: 'var(--color-background-form-butons-acept-disable) !important',
        color: 'var(--color-text-form-butons-acept-disable) !important',
        borderColor: '1px solid var(--color-background-form-butons-acept-disable) !important'
    }
});

/**
 * @param {Object} params
 * @param {string} params.text
 * @param {api.Curso} [params.course]
 * @param {api.Plan} [params.plan]
 * @param {boolean} [params.checkCart=true] - default = true
 * @param {string} [params.textInCart]
 * @param {React.CSSProperties} [params.style]
 * @param {boolean} [params.disabled=false]
 * @returns {JSX.Element}
 */
export default function AddToCartButton({ text, course, plan, checkCart = true, textInCart = "En carrito", style, disabled = false}) {

    const { addCourse, hasItem, addPlan } = useCart();
    const classes = useStyles();
    const [adding, setAdding] = useState(false);

    let disabledButtonCart = false;
    let inCart = false;
    if (checkCart) {
        inCart = hasItem(plan || course);
        disabledButtonCart = (inCart);
        if (plan){
            disabledButtonCart = (!inCart && disabled);
        }
    }

    const classList = ["button_cancel", classes.common];
    !inCart && classList.push("MuiButton-contained");
    if(plan && disabledButtonCart){
        classList.push(classes.disabled);
    } else {
        classList.push(inCart || adding ? classes.inCart : classes.add);
    }


    const addCallback = function () {
        if (course) {
            addCourse(course);
        } else if (plan) {
            addPlan(plan);
        }
        setAdding(false);
    };

    const { cart, setCart } = useContext(BotonContext);

    /**
     * @param {api.ItemCarrito[]} cart
     */
    const updateCartLocalStorage = function (cart) {
        if (cart instanceof Array && cart.length) {
        localStorage.setItem('cart', JSON.stringify(cart));
        } else {
        localStorage.removeItem('cart');
        }
    };

    function deleteArticle(id, tipo) {
        const newCart = cart.slice(); // clonar
        newCart.map((course, i) => {
          if (course.id === id && course.tipo === tipo) {
            newCart.splice(i, 1);
          }
        });
        setCart(newCart);
        updateCartLocalStorage(newCart);
    }

    return (
        <button style={style} className={classList.join(' ')} disabled={(disabledButtonCart)} onClick={() => {
            if (inCart){
                if (plan){ 
                    deleteArticle(plan.id, TIPO_PRODUCTO.PLAN); 
                } else {
                    return;
                }
            } else {
                setAdding(true);
                setTimeout(addCallback, 600);
            }            
        }}>{inCart
            ? <>
                <CheckIcon style={{ marginLeft: '-0.9rem', height: '1rem' }}/>{textInCart}
            </>
            : (
                adding ? <ButtonCircularProgress color='white' style={{ height: '1rem', width: '1rem', marginLeft: 0 }} />
                : text
            )
        }</button>
    );
}
