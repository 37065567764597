import React from "react";
import './styles.css';

/**
 * SweetAlert2: [https://sweetalert2.github.io/] [https://github.com/sweetalert2/sweetalert2]
 * Standalone tick: [https://codepen.io/gkiely/pen/ZvvZrW] <- based on this
 * @returns {JSX.Element}
 */
export default function SweetAlertSuccessTick () {
    return (
        <div className="swal-icon swal-icon--success">
            <span className="swal-icon--success__line swal-icon--success__line--long"></span>
            <span className="swal-icon--success__line swal-icon--success__line--tip"></span>
            <div className="swal-icon--success__ring"></div>
            <div className="swal-icon--success__hide-corners"></div>
        </div>
    );
}
