import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import { useSnackbar } from 'notistack';

import { BotonContext } from '../BotonContext';
import DialogTitle from '../componentes/DialogTitle';
import ButtonCircularProgress from './ButtonCircularProgress';
import api from '../lib/api';
import { isValidEmail } from '../util/common.io';

const OPCIONES_PREGUNTA = ["Redes sociales", "Mail", "Páginas de napsis", "Recomendación de otra persona", "Otro"];

const opcionesPregunta = OPCIONES_PREGUNTA.map((option, i) =>
    <MenuItem value={i} key={i}>{option}</MenuItem>
);

/**
 * @typedef DataCouponForm
 * @property {string} email
 * @property {string} nombre
 * @property {string} apellido
 * @property {string} identification
 * @property {number} countryId
 * @property {string} headAbout
 */

const fixEstilosImportantes = makeStyles({
    fixAlertaInfo: {
        backgroundColor: 'rgb(232, 244, 253) !important',
        border: '1px solid #349ff3bd',
        '& .MuiAlert-icon': {
            color: '#2196f3 !important'
        },
        '& .MuiAlert-message': {
            color: 'rgb(13, 60, 97) !important'
        },
        '& .MuiAlert-action': {
            color: 'rgb(13, 60, 97) !important'
        }
    }
});

const AlertaInfo = function () {
    const clasesFixEstilos = fixEstilosImportantes();
    return (
        <Alert severity="info" color="info" className={clasesFixEstilos.fixAlertaInfo}
            style={{ marginTop: '-30px', cursor: 'default', display: 'flex', alignItems: 'center', margin: '0.8rem 0' }}>
            <span>Los datos acá registrados no reemplazarán los entregados en el registro de usuario de e-napsis.</span>
        </Alert>
    );
};

/**
 * @param {Object} params
 * @param {boolean} params.open
 * @param {api.DataCoupon} params.dataCoupon
 * @param {() => void} params.onSuccess
 * @param {() => void} params.onCancel
  * @returns {JSX.Element}
 */
export default function CouponFormulario({ open, dataCoupon, onSuccess, onCancel }) {

    const { enqueueSnackbar } = useSnackbar();
    const { data_user, token, countryList } = useContext(BotonContext);

    const [nombre, setNombre] = useState(data_user.nombres || '');
    const [apellido, setApellido] = useState(data_user.apellidos || '');
    const [email, setEmail] = useState(data_user.email || '');
    const [identification, setIdentification] = useState(data_user.identificacion || '');
    const [countryId, setCountryId] = useState(data_user.id_pais || undefined);
    const [respuesta, setRespuesta] = useState(/** @type {number} */(undefined));
    const [detallesRespuesta, setDetallesRespuesta] = useState('');

    const [posting, setPosting] = useState(false);
    const [acceptPressed, setAcceptPressed] = useState(false);

    useEffect(() => {
        !open && setAcceptPressed(false); // reset valor al ocultar formulario
    }, [open]);

    /**
     * @returns {boolean}
     */
    const validate = function () {
        let mensaje = '';
        if (!mensaje && !nombre || !apellido || !email || !identification || typeof respuesta !== 'number' || !countryId) {
            mensaje = 'Todos los campos son obligatorios para la aplicación del cupón';
        }
        if (!mensaje && !isValidEmail(email)) {
            mensaje = 'Debe especificar un correo válido';
        }
        if (!mensaje && (respuesta === OPCIONES_PREGUNTA.length-1) && !detallesRespuesta) {
            mensaje = `Debe especificar los detalles para la opción "${OPCIONES_PREGUNTA[respuesta]}"`;
        }
        if (mensaje) {
            enqueueSnackbar(mensaje, {
                variant: 'warning'
            });
            return false;
        } else {
            return true;
        }
    };

    const handleAccept = async function () {
        setAcceptPressed(true);
        if (!validate()) return;
        try {
            setPosting(true);
            /** @type {api.DataFormularioCoupon} */
            const apiPayload = {
                id_usuario: data_user.id_usuario,
                nombre: nombre,
                apellido: apellido,
                email: email,
                id_pais: countryId,
                identificacion: identification,
                respuesta_pregunta: OPCIONES_PREGUNTA[respuesta],
                cupon: dataCoupon.cupon,
                cupon_descripcion: dataCoupon.descripcion,
                id_cupon_transaccion: dataCoupon.id_cupon_transaccion,
                detalle: (respuesta === OPCIONES_PREGUNTA.length-1) ? detallesRespuesta : ''
            };
            const response = await api.pagos.enviarFormularioCupon({ token, payload: apiPayload });
            if (response.success) {
                typeof onSuccess === 'function' && onSuccess();
            } else {
                throw new Error(response?.mensaje);
            }
            // NOTA: no desactivar `posting` en success para permitir que el modal se oculte sin remover el spinner
        } catch (error) {
            enqueueSnackbar('Error al enviar formulario de cupón', {
                variant: 'error'
            });
            console.log(error);
            setPosting(false);
        }
    };

    const handleCancel = function () {
        if (posting) return;
        typeof onCancel === 'function' && onCancel();
    };

    /** @type {'small' | 'medium'} */
    const formFieldsSize = 'small';

    return <>
        <Dialog open={open} onClose={handleCancel} aria-labelledby="customized-dialog-title" disableScrollLock={true} style={{ cursor: 'default' }}>
            <DialogTitle id="form-dialog-title" onClose={handleCancel} className="modal_title" >Cupón de descuento</DialogTitle>
            <DialogContent className="modal_content">

                <AlertaInfo />

                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '1.1rem',
                    margin: '0.8rem 0px',
                    overflow: 'hidden', // overflow hidden resuelve un problema al usar campos de MUI donde hay un elemento oculto con un alto mayor al del propio campo
                    paddingTop: '0.3rem' // evitar que se oculte parte del primer label debido al overflow hidden
                }}>
                    {/* Nombre */}
                    <TextField className="InputText" label="Nombre" variant="outlined" fullWidth
                        InputProps={{
                            className: "InputText"
                        }}
                        InputLabelProps={{
                            className: "InputText"
                        }}
                        onChange={(e) => {
                            setNombre(e.target.value);
                        }}
                        type="text"
                        value={nombre}
                        size={formFieldsSize}
                        required={true}
                        error={!nombre && acceptPressed}
                    />

                    {/* Apellido */}
                    <TextField className="InputText" label="Apellido" variant="outlined" fullWidth
                        InputProps={{
                            className: "InputText"
                        }}
                        InputLabelProps={{
                            className: "InputText"
                        }}
                        onChange={(e) => {
                            setApellido(e.target.value);
                        }}
                        type="text"
                        value={apellido}
                        size={formFieldsSize}
                        required={true}
                        error={!apellido && acceptPressed}
                    />

                    {/* Email */}
                    <TextField className="InputText" label="Correo electrónico" variant="outlined" fullWidth
                        InputProps={{
                            className: "InputText"
                        }}
                        InputLabelProps={{
                            className: "InputText"
                        }}
                        onChange={(e) => {
                            setEmail(e.target.value);
                        }}
                        type="email"
                        value={email}
                        size={formFieldsSize}
                        required={true}
                        error={!email && acceptPressed}
                    />

                    {/* Identificación */}
                    <TextField className="InputText" label="Identificación" variant="outlined" fullWidth
                        InputProps={{
                            className: "InputText"
                        }}
                        InputLabelProps={{
                            className: "InputText"
                        }}
                        onChange={(e) => {
                            const value = e.target.value;
                            /^[\d.\-K]*$/i.test(value) && setIdentification(value);
                        }}
                        type="text"
                        value={identification}
                        size={formFieldsSize}
                        required={true}
                        error={!identification && acceptPressed}
                    />

                    {/* País */}
                    <FormControl variant="outlined">
                        <InputLabel id="demo-simple-select-outlined-label" className="InputText">País *</InputLabel>
                        <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            value={countryId}
                            error={!countryId && acceptPressed}
                            onChange={(e) => {
                                const value = /** @type {number} */(e.target.value);
                                setCountryId(value);
                            }}
                            label="País *"
                            MenuProps={{ disableScrollLock: true }}
                            required={true}
                        >
                            <MenuItem value="" >
                                <em>Seleccionar</em>
                            </MenuItem>
                            {countryList.map(country =>
                                <MenuItem value={country.id_pais} key={country.id_pais}>{country.nombre}</MenuItem>
                            )}
                        </Select>
                    </FormControl>

                    {/* Pregunta */}
                    <FormControl variant="outlined">
                        <InputLabel id="demo-simple-select-outlined-label-2" className="InputText">¿Cómo te enteraste de e-napsis? *</InputLabel>
                        <Select
                            labelId="demo-simple-select-outlined-label-2"
                            id="demo-simple-select-outlined-2"
                            value={respuesta}
                            error={respuesta < 0 && acceptPressed}
                            onChange={(e) => {
                                const value = /** @type {number} */(e.target.value);
                                setRespuesta(value);
                            }}
                            label="¿Cómo te enteraste de e-napsis? *"
                            MenuProps={{ disableScrollLock: true }}
                            required={true}
                        >
                            <MenuItem value="" >
                                <em>Seleccionar</em>
                            </MenuItem>
                            {opcionesPregunta}
                        </Select>
                    </FormControl>
                    {(respuesta === OPCIONES_PREGUNTA.length-1) &&
                        <TextField className="InputText" label={OPCIONES_PREGUNTA[OPCIONES_PREGUNTA.length-1] + ' (detalles)'} variant="outlined" fullWidth
                            InputProps={{
                                className: "InputText"
                            }}
                            InputLabelProps={{
                                className: "InputText"
                            }}
                            onChange={(e) => {
                                setDetallesRespuesta(e.target.value);
                            }}
                            type="text"
                            multiline={true}
                            minRows={3}
                            value={detallesRespuesta}
                            size={formFieldsSize}
                            required={true}
                            error={!detallesRespuesta && acceptPressed}
                        />
                    }
                </div>

            </DialogContent>
            <DialogActions className="modal_footer">
                <Button onClick={handleCancel} variant="contained" color="primary" className="button_cancel" disabled={posting} >
                    Cancelar
                </Button>
                <Button onClick={handleAccept} variant="contained" color="primary" className="button_accept" disabled={posting} >
                    {posting && <ButtonCircularProgress />}
                    Aplicar descuento
                </Button>
            </DialogActions>
        </Dialog>
    </>;

}
