import React, { useEffect, useState, useContext } from "react";
import { makeStyles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import { Hidden } from "@material-ui/core";
import Container from '@material-ui/core/Container';
import DialogContent from '@material-ui/core/DialogContent';
import CircularProgress from '@material-ui/core/CircularProgress';

import AuthContext from "../AuthContext";
import TarjetaEcampus from "../componentes/TarjetaEcampus";

import utils from "../util/utils";


const useStyles3 = makeStyles({
    root: {
        display: 'flex'
    }
});


export default function MyCertifiedPage () {

    const data_user = JSON.parse(sessionStorage.getItem("user_data"));
    const token = sessionStorage.getItem("token");


    useEffect(() => {
        document.title = "Mis programas";
    });

    const [myCourses, setMyCourses] = useState([]);
    const classes3 = useStyles3();
    const [mens, setMens] = useState(false);
    const { isAuth, isAuthTypeTutor } = useContext(AuthContext);

    var [disableLoading, setDisableLoading] = useState(false);

    const ValidarAuth = () => {

        const data_user = JSON.parse(sessionStorage.getItem("user_data"));
        const token = sessionStorage.getItem("token");

        if (isAuth && (token === null || token === 'null' || token === "" || token === undefined
            || data_user === null || data_user === 'null' || data_user === "" || data_user === undefined)) {
            //setOpenmensnoauth(true);
            localStorage.removeItem("cart");
            sessionStorage.clear();
            window.location.href = "#/login";
            window.location.reload();
        }
    };


    ValidarAuth();
    var userID = data_user.id_usuario;  //La fecha de inscripcion del curso no puede ser: 0000-00-00 00:00:00

    useEffect(() => {

        fetch(process.env.REACT_APP_URL_API + '/alumno/' + userID + '/diplomados', {
            method: 'get',
            headers: {
                'token': token,
                'Content-Type': 'application/json'
            }
        })
            .then(response => response.json()
                .then(data => {
                    if (data !== null) {

                        if (data.success === true) {
                            if (data.data.diplomados.length > 0) {
                                setMyCourses(data.data.diplomados);
                            } else {
                                setMens(true);
                            }
                        }
                        if (data.success === false && data.data === null) {
                            sessionStorage.setItem("token", null);
                            sessionStorage.setItem("user_data", null);
                            ValidarAuth();
                        }
                    }
                    if (data === null) {
                        setMens(true);
                    }
                    setDisableLoading(true);
                })
            )

    }, []);


    function limitarcaracteres(value) {
        if (value.length > 72) {
            value = value.slice(0, 72) + '...';
            return value;
        } else {
            return value;
        }
    }

    function limitarcaracteres28(value) {
        if (value.length > 28) {
            value = value.slice(0, 28) + '...';
            return value;
        } else {
            return value;
        }
    }

    var myCourseList = [];
    var nCursosAlumno = 0
    if (myCourses) {
        nCursosAlumno = myCourses.length
    }
    for (let i = 0; i < nCursosAlumno; i++) {

        myCourseList.push(

            <Grid container item xs={12} sm={12} md={6} lg={4} xl={3} spacing={0} direction="column" alignItems="center" justifyContent="center" key={myCourses[i].id}>

                <Hidden smUp>
                    <div className="course-small rel">

                        <Container maxWidth="sm">

                            <Grid container spacing={3} alignContent="center" alignItems="center">

                                <TarjetaEcampus
                                    image={utils.getImagenCurso(myCourses[i])}
                                    text1={limitarcaracteres28(myCourses[i].titulo)}
                                    text2={myCourses[i].instructor}
                                    href={"/owncertified/" + myCourses[i].id + "/" + "Mis programas"}
                                    price={myCourses[i].precio}
                                    small={true}
                                    curso={myCourses[i]}
                                />

                            </Grid>
                        </Container>

                    </div>
                </Hidden>

                <Hidden xsDown>
                    <div className="course rel">

                        <Container maxWidth="md">

                            <Grid container spacing={3} alignContent="center" alignItems="center" >

                                <TarjetaEcampus
                                    image={utils.getImagenCurso(myCourses[i])}
                                    text1={limitarcaracteres(myCourses[i].titulo)}
                                    text2={myCourses[i].instructor}
                                    href={"/owncertified/" + myCourses[i].id + "/" + "Mis programas"}
                                    price={myCourses[i].precio}
                                    curso={myCourses[i]}
                                />

                            </Grid>
                        </Container>

                    </div>
                </Hidden>
            </Grid>

        );
    }



    return (
        <div className="App rel">

            <Breadcrumbs aria-label="breadcrumb" className="breadcrumbs_link_inneret">
                <Typography color="textPrimary" className="breadcrumbs_link">
                    Mis programas
                </Typography>
            </Breadcrumbs>

            <div className="tituloInicial rel">
            <h1 className="title_section s24 fontb c333">Mis programas</h1>
            </div>
            <div className="section section-b rel">
                {disableLoading != true &&
                    <div id="cargando" className={classes3.root}>

                        <DialogContent className="CentrarLoadingSinModal">
                            <CircularProgress className="CircularProgress" style={{ alignSelf: 'center' }} />
                        </DialogContent>
                    </div>
                }
                <div className="courses rel flex">

                    {myCourseList && myCourseList != null && myCourseList != '' && myCourseList != undefined &&
                        <Grid container spacing={1} item xs={12} xl={12} direction="row" >



                            {myCourseList}

                        </Grid>
                    }

                    {mens === true &&

                        <Grid container spacing={1} item xs={12} alignContent="center" alignItems="center">



                            <Grid item xs={1} sm={1} md={2} lg={3} xl={3}  >


                            </Grid>

                            <Grid item xs={12} sm={10} md={8} lg={6} xl={6}>

                                <div style={{ height: '100px' }}></div>
                                <div style={{ display: 'flex', justifyContent: 'center', justifyItems: 'center' }} className="title_section s30 fontb c333">¡ Aún no estas inscrito en un programa !</div>
                                <div style={{ height: '100px' }}></div>

                            </Grid>

                            <Grid item xs={1} sm={1} md={2} lg={3} xl={3}  >


                            </Grid>

                        </Grid>

                    }

                </div>
            </div>

        </div>

    );
}
