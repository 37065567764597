import React, { useContext, useEffect, useState } from "react";
import Grid from '@material-ui/core/Grid';
import { useHistory } from "react-router";
import Carousel from "react-elastic-carousel";
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import Fab from '@material-ui/core/Fab';
import { Hidden } from "@material-ui/core";
import CircularProgress from '@material-ui/core/CircularProgress';
import Container from '@material-ui/core/Container';
import { useSnackbar } from "notistack";

import TarjetaEcampus from "../componentes/TarjetaEcampus";
import ModalVideoPlayer from "../componentes/ModalVideoPlayer";

import { theme as tema } from "../util/theme";
import api, { isStructureUpdated } from "../lib/api";
import i18n, { strings } from "../lib/i18n";
import utils from "../util/utils";
import env from "../util/env";
import Banner, { BANNER_POSITION } from "../componentes/Banner";
import PanelCursosCategory from "../componentes/PanelCursosCategory";
import { BotonContext } from "../BotonContext";

// IMÁGENES
import banner_principal from '../ui/banner-principal.png';
import banner_annual_subscription from '../ui/banner-annual-subscription-big.jpg';
import banner_annual_subscription_md from '../ui/banner-annual-subscription-md.jpg';
import logoEnapsisColor from '../ui/logo-enapsis-color.png';

import bannerCajaComp from "../ui/banners/banner_central_CAJACOMPENSACION.jpg";
import bannerPromo50 from "../ui/banners/promo50.jpg";
import bannerPromo50Colombia from "../ui/banners/promo50_col.jpg";
import bannerPromo50Argentina from "../ui/banners/promo50_arg_uru.jpg";
import { useSession } from "../util/session";
import { FLAGS, TagStyles } from "../lib/constants";

const USE_BANNER_API = true;

const promesaBanners = USE_BANNER_API ? api.banners(i18n.getCountry().id_pais) : undefined;
promesaBanners && promesaBanners.catch(() => {}); // evitar "Uncaught (in promise)" en caso de que falle antes de asignar el handler dentro de useEffect().

/**
 * @typedef BannerData
 * @property {string} image
 * @property {Date} [startDate]
 * @property {Date} [endDate]
 * @property {boolean} [enabled]
 * @property {Function | string} [link]
 * @property {number} [tipo] - default = BANNER_TYPE.imagen
 * @todo: soporte de Function en parámetro `image`. Recibiría el id_pais y retornaría el URL de la imagen.
 */

/** @type {Partial<{[location in keyof BANNER_POSITION]: BannerData}>} */
const DEFAULT_BANNERS = {
    top: {
        image: banner_annual_subscription,
        link: "/subscription/Inicio"
    },
    middle: {
        image: bannerCajaComp,
        link: process.env.REACT_APP_LINK_EDUCATION_CATEGORY
    },
    bottom: {
        image: banner_annual_subscription_md,
        link: '/subscription/Inicio'
    }
};
const fallbackBanners = DEFAULT_BANNERS;

/** @type {Partial<{[location in keyof BANNER_POSITION]: BannerData}>} */
const TEMP_BANNERS = {
};
Object.keys(TEMP_BANNERS).forEach(key => {
    /** @type {BannerData} */
    const banner = TEMP_BANNERS[key];
    const now = Date.now();
    if ((banner.startDate && now < banner.startDate.valueOf()) || (banner.endDate && now > banner.endDate.valueOf())) {
        delete TEMP_BANNERS[key];
    } else {
        fallbackBanners[key] = banner;
    }
});

/**
 * @type {{[country_iso_code: string]: BannerData}}
 */
const BANNERS_COUNTRY = {
    [i18n.ISO_CODES.Colombia]: {
        image: bannerPromo50Colombia,
        link: process.env.REACT_APP_LINK_EDUCATION_CATEGORY
    },
    [i18n.ISO_CODES.Argentina]: {
        image: bannerPromo50Argentina,
        link: process.env.REACT_APP_LINK_EDUCATION_CATEGORY
    },
    [i18n.ISO_CODES.Uruguay]: {
        image: bannerPromo50Argentina,
        link: process.env.REACT_APP_LINK_EDUCATION_CATEGORY
    },
    'default': {
        image: bannerPromo50,
        link: process.env.REACT_APP_LINK_EDUCATION_CATEGORY
    }
};
const bannersCountry = new Proxy(BANNERS_COUNTRY, {
    get(target, prop) {
        return Object.prototype.hasOwnProperty.call(target, prop) ? target[prop] : target.default;
    }
});

const ACTIVAR_BANNERS_POR_PAIS = true;
if (ACTIVAR_BANNERS_POR_PAIS) {
    fallbackBanners['top'] = bannersCountry[i18n.getCountryIsoCode()];
}

const hideDiplomados = env.flags.HIDE_DIPLOMADOS || FLAGS.DISABLE_PROGRAMS;
const END_OF_PAGE_TEXT  = '<b>e-napsis</b> es nuestro portal de formación de docentes para docentes, desarrollado por <b>Napsis</b>.\nSus principales características se encuentran en la <b>calidad de los cursos</b>, los que se ocupan del desarrollo de <b>habilidades para el siglo XXI</b>; la <b>facilidad en el uso</b> de la plataforma y el <b>respaldo de un equipo experto</b> que ha cuidado cada detalle para que tu experiencia sea la más satisfactoria.';

const SubscriptionButton = function ({ handleClick }) {
    return <>
        <Grid item xs={4} >
            <Fab onClick={handleClick} variant="extended" color="default" aria-label="add" className="btn-flotante MuiFab-fixed" style={{ float: "right", backgroundColor: "#d87218", color: "white" }}>
                <NotificationsActiveIcon style={{ marginRight: '0' }} />
                &nbsp;&nbsp; Suscribirse
            </Fab>
        </Grid>;
    </>;
};

/**
 * Envuelve cada línea en un tag <p>.
 * @param {string} text
 * @param {boolean} [allowHtml] - default = false. Usar con cuidado. No usar para texto provisto por el usuario o de la BD.
 * @returns {JSX.Element[]}
 * @todo sanitize `text` al usar `allowHtml`.
 */
const wrapParagraphs = function (text, allowHtml) {
    const lines = text.split('\n');
    return lines.map((line, i) => {
        if (allowHtml) {
            return <p key={i} dangerouslySetInnerHTML={{ __html: line }}></p>;
        } else {
            return <p key={i}>{line}</p>;
        }
    });
};

const scroll2Top = function () {
    document.body.scrollTop = document.documentElement.scrollTop = 0; // TODO: animar
};

export default function HomePage() {

    const isStandaloneDeployment = /true/i.test(process.env.REACT_APP_IS_STANDALONE_DEPLOYMENT);
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();
    const { data_user, token } = useContext(BotonContext);
    const { isLogged } = useSession();

    const [diplomados, setDiplomados] = useState([]);
    const [cursosPopulares, setCursosPopulares] = useState([]);
    const [misCursos, setMisCursos] = useState([]);
    const [disableLoadingCursos, setDisableLoadingCursos] = useState(false);
    const [disableLoadingDiplomados, setDisableLoadingDiplomados] = useState(false);
    const [banners, setBanners] = useState(/** @type {Partial<{[location in keyof BANNER_POSITION]: BannerData}>} */(USE_BANNER_API ? {} : fallbackBanners));
    const [cursoVideoModal, setCursoVideoModal] = useState(/** @type {api.Curso} */(null));
    const [cursosHome, setCursosHome] = useState(/** @type {api.CursosCategory[]} */(null));

    /**
     * @param {api.Curso} curso
     */
    const handleShowVideo = function (curso) {
        if (!curso?.video) return;
        setCursoVideoModal(curso);
    };

    useEffect(() => {
        if (!USE_BANNER_API) return;
        /** @type {(keyof BANNER_POSITION)[]} */
        const positionKeys = ['top', 'middle', 'bottom']; // TODO: usar número directamente como llave de la lista de banners.
        promesaBanners
            .then((response) => {
                if (!response.success || !(response.data instanceof Array) || !response.data.length) throw new Error();
                const bannerList = fallbackBanners;
                response.data.forEach((b) => {
                    bannerList[positionKeys[b.posicion - 1]] = {
                        image: b.elemento,
                        link: b.enlace,
                        tipo: b.tipo
                    };
                });
                setBanners(bannerList);
            })
            .catch(() => {
                setBanners(fallbackBanners);
            });
        api.getCursosCategoryHome({
            id_usuario: isLogged() ? data_user.id_usuario : undefined
        })
            .then(res => {
                if (!res.success) throw new Error(res.message || res.mensaje);
                setCursosHome(res.data.categoria);
            })
            .catch(() => {
                console.log("Error al descargar cursos");
                setCursosHome([]); // para ocultar el spinner de carga
            });
    }, []);

    const getDiplomados = function (userId) {
        api.getDiplomados(userId, undefined, i18n.getCountry().id_pais)
            .then(data => {
                setDiplomados(data.data.diplomados);
                if (data.success === false) {
                    setMensajeBackend(data.mensaje);
                    setOpenMensBackend(true);
                }
                setDisableLoadingDiplomados(true);
            })
            .catch(() => {
                setOpenSnackbarError(true);
                setDisableLoadingDiplomados(true);
            });
    };

    const getCursosPopulares = function (userId) {
        const countryId = i18n.getCountry().id_pais;
        api.getCursosPopulares(userId, countryId)
            .then(data => {
                setCursosPopulares(data.data.cursos);
                if (data.success === false) {
                    setMensajeBackend(data.mensaje);
                    setOpenMensBackend(true);
                }
                setDisableLoadingCursos(true);
            })
            .catch(() => {
                setOpenSnackbarError(true);
                setDisableLoadingCursos(true);
            });
    };

    const getMisCursos = function (userId) {
        api.getMisCursos(userId)
            .then(data => {
                setMisCursos(data.data);
                if (data.success === false) {
                    setMensajeBackend(data.mensaje);
                    setOpenMensBackend(true);
                }
                setDisableLoadingCursos(true);
            })
            .catch(() => {
                setOpenSnackbarError(true);
                setDisableLoadingCursos(true);
            });
    };

    useEffect(() => {

        if (data_user) {

            const userID = data_user.id_usuario;

            api.getUserSubscription(userID, token)
                .then(data => {
                    if (data.success === true) {
                        const data_usuario = data.data.usuario;
                        sessionStorage.setItem("user_data", JSON.stringify(data_usuario));
                    }
                    if (data.success === false && data.data === null) {
                        sessionStorage.setItem("token", null);
                        sessionStorage.setItem("user_data", null);
                    }
                    if (data.success === false) {
                        //setOpenSnackbarWarningUnidad(true);
                        sessionStorage.setItem("token", null);
                        sessionStorage.setItem("user_data", null);
                    }
                })
                .catch(() => {
                    //setOpenSnackbarError(true);
                });

            !hideDiplomados && getDiplomados(userID);
            getCursosPopulares(userID);
            getMisCursos(userID);

        } else {
            const userID = '';
            !hideDiplomados && getDiplomados(userID);
            getCursosPopulares(userID);
        }

    }, []);

    useEffect(() => {
        document.title = "Inicio";
    });

    //// Código para tema oscuro ***/
    const [checked, setChecked] = useState(tema.current === "dark" ? true : false);
    const [openSnackbarWarning, setOpenSnackbarWarning] = React.useState(false);
    const [openSnackbarWarningDatos, setOpenSnackbarWarningDatos] = React.useState(false);
    const [openSnackbarError, setOpenSnackbarError] = React.useState(false);

    const [openmensbackend, setOpenMensBackend] = React.useState(false);
    const [mensajebackend, setMensajeBackend] = React.useState("");


    const handleCloseMens = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenMensBackend(false);
    };

    const handleCloseSnackbarError = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenSnackbarError(false);
    };

    const handleCloseSnackbarWarning = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenSnackbarWarning(false);
    };

    const handleCloseSnackbarWarningDatos = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenSnackbarWarningDatos(false);
    };

    useEffect(() => {
        document
            .getElementsByTagName("HTML")[0]
            .setAttribute("data-theme", tema.current);
    }, [checked]);

    function enterPressed(event) {
        var code = event.keyCode || event.which;
        if (code === 13) { //13 is the enter keycode
            getCursosFiltrados(null, event.target.value);
        }
    }

    const searchBarOnChange = (event, value) => {
        //setPage(value);
        enterPressed(event);
        //getCursosFiltrados(null); //null
    };

    const SearchBar = ({ keyword, setKeyword }) => {
        const BarStyling = { width: "100%", padding: "0.5rem" };
        return (
            <input
                style={BarStyling}
                value={keyword}
                placeholder={"Busca cualquier cosa"}
                className={"round"}
                //onChange={searchBarOnChange}
                onKeyPress={searchBarOnChange}
            />
        );
    };

    var getCursosFiltrados = (nPag, query) => {
        api.getCursosFiltrados({
            query: query,
            nPag: nPag,
            id_pais: i18n.getCountry().id_pais
        })
            .then(data => {
                if (data.success === true) {
                    setCursosPopulares(data.data.cursos);
                } else {
                    setOpenSnackbarWarning(true);
                    setMensajeBackend(data.mensaje);
                }
            })
            .catch(() => {
                setOpenSnackbarError(true);
            });
    };

    function limitarcaracteres(value) {
        if (value.length > 72) {
            value = value.slice(0, 72) + '...';
            return value;
        } else {

            return value;
        }
    }

    function limitarcaracteres28(value) {
        if (value.length > 28) {
            value = value.slice(0, 28) + '...';
            return value;
        } else {

            return value;
        }
    }

    //Live Tutors List
    var tutorList = [];
    for (let i = 0; i < 8; i++) {
        tutorList.push(
            <button className="tutor rel" key={"tutor-live-" + i}>
                <img src={"http://placeimg.com/100/100/people?" + i} className="bl" />
            </button>
        );
    }

    var mycourseList = [];
    if (misCursos) {
      for (let i = 0; i < misCursos.length; i++) {
        mycourseList.push(
            <div className="coursehome rel" key={misCursos[i].id} >
                {/*
                // @ts-ignore */}
                <Hidden smUp>
                    <Container maxWidth="sm">
                        <Grid container spacing={3} alignContent="center" alignItems="center">
                            <TarjetaEcampus
                                image={utils.getImagenCurso(misCursos[i])}
                                text1={limitarcaracteres28(misCursos[i].titulo)}
                                text2={misCursos[i].nombre_instructor}
                                href={"/course/" + misCursos[i].id + "/" + "Inicio"}
                                price={false}
                                small={true}
                                showTag={isStructureUpdated(misCursos[i])}
                                tagOptions={TagStyles.TAG_ACTUALIZADO}
                                curso={misCursos[i]}
                                hasVideo={!!misCursos[i].video}
                                handleVideoPreview={() => {
                                    handleShowVideo(misCursos[i]);
                                }}
                                showBuyButtons={false}
                            />
                        </Grid>
                    </Container>
                </Hidden>
                {/*
                // @ts-ignore */}
                <Hidden xsDown>
                    <Container maxWidth="md">
                        <Grid container alignContent="center" alignItems="center">
                            <TarjetaEcampus
                                image={utils.getImagenCurso(misCursos[i])}
                                text1={limitarcaracteres(misCursos[i].titulo)}
                                text2={misCursos[i].nombre_instructor}
                                href={"/course/" + misCursos[i].id + "/" + "Inicio"}
                                price={false}
                                showTag={isStructureUpdated(misCursos[i])}
                                tagOptions={TagStyles.TAG_ACTUALIZADO}
                                curso={misCursos[i]}
                                hasVideo={!!misCursos[i].video}
                                handleVideoPreview={() => {
                                    handleShowVideo(misCursos[i]);
                                }}
                                showBuyButtons={false}
                            />
                        </Grid>
                    </Container>
                </Hidden>
                <br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br>
            </div>
        );
      }
    }

    var courseList = [];
    if (cursosPopulares) {

        for (let i = 0; i < cursosPopulares.length; i++) {
            // let duration;
            // if (cursosPopulares[i].duration == null) {
            //     duration = "-"
            // } else {
            //     duration = cursos[i].duration
            // }

            courseList.push(
                <div className="coursehome rel" key={cursosPopulares[i].id} >
                    {/*
                    // @ts-ignore */}
                    <Hidden smUp>

                        <Container maxWidth="sm">

                            <Grid container spacing={3} alignContent="center" alignItems="center">

                                <TarjetaEcampus
                                    image={utils.getImagenCurso(cursosPopulares[i])}
                                    text1={limitarcaracteres28(cursosPopulares[i].titulo)}
                                    text2={cursosPopulares[i].nombre_instructor}
                                    href={"/course/" + cursosPopulares[i].id + "/" + "Inicio"}
                                    price={cursosPopulares[i].precio}
                                    small={true}
                                    showTag={isStructureUpdated(cursosPopulares[i])}
                                    tagOptions={TagStyles.TAG_ACTUALIZADO}
                                    curso={cursosPopulares[i]}
                                    hasVideo={!!cursosPopulares[i].video}
                                    handleVideoPreview={() => {
                                        handleShowVideo(cursosPopulares[i]);
                                    }}
                                    showBuyButtons={true}
                                />

                            </Grid>
                        </Container>

                    </Hidden>
                    {/*
                    // @ts-ignore */}
                    <Hidden xsDown>

                        <Container maxWidth="md">

                            <Grid container alignContent="center" alignItems="center">
                                <TarjetaEcampus
                                    image={utils.getImagenCurso(cursosPopulares[i])}
                                    text1={limitarcaracteres(cursosPopulares[i].titulo)}
                                    text2={cursosPopulares[i].nombre_instructor}
                                    href={"/course/" + cursosPopulares[i].id + "/" + "Inicio"}
                                    price={cursosPopulares[i].precio}
                                    showTag={isStructureUpdated(cursosPopulares[i])}
                                    tagOptions={TagStyles.TAG_ACTUALIZADO}
                                    curso={cursosPopulares[i]}
                                    hasVideo={!!cursosPopulares[i].video}
                                    handleVideoPreview={() => {
                                        handleShowVideo(cursosPopulares[i]);
                                    }}
                                    showBuyButtons={true}
                                />
                            </Grid>
                        </Container>

                    </Hidden>
                    <br></br>         <br></br><br></br>         <br></br><br></br>         <br></br><br></br>         <br></br>
                </div>

            );
        }

    }


    var diplomadosList = [];
    for (let i = 0; i < diplomados.length; i++) {
        // let duration;
        // if (cursosPopulares[i].duration == null) {
        //     duration = "-"
        // } else {
        //     duration = cursos[i].duration
        // }
        diplomadosList.push(
            <div className="coursehome rel" key={diplomados[i].id} >
                {/*
                // @ts-ignore */}
                <Hidden smUp>

                    <Container maxWidth="sm">
                        <Grid container spacing={3} alignContent="center" alignItems="center">

                            <TarjetaEcampus
                                image={utils.getImagenCurso(diplomados[i])}
                                text1={limitarcaracteres28(diplomados[i].titulo)}
                                text2={diplomados[i].instructor}
                                href={"/detail-certified/" + diplomados[i].id + "/" + "Inicio"}
                                price={diplomados[i].precio}
                                small={true}
                                showTag={isStructureUpdated(diplomados[i])}
                                tagOptions={TagStyles.TAG_ACTUALIZADO}
                                curso={diplomados[i]}
                            />

                        </Grid>
                    </Container>

                </Hidden>
                {/*
                // @ts-ignore */}
                <Hidden xsDown>

                    <Container maxWidth="md">
                        <Grid container spacing={1} alignContent="center" alignItems="center">

                            <TarjetaEcampus
                                image={utils.getImagenCurso(diplomados[i])}
                                text1={limitarcaracteres(diplomados[i].titulo)}
                                text2={diplomados[i].instructor}
                                href={"/detail-certified/" + diplomados[i].id + "/" + "Inicio"}
                                price={diplomados[i].precio}
                                showTag={isStructureUpdated(diplomados[i])}
                                tagOptions={TagStyles.TAG_ACTUALIZADO}
                                curso={diplomados[i]}
                            />

                        </Grid>
                    </Container>

                </Hidden>

            </div>

        );
    }

    const breakPoints = [
        { width: 1, itemsToShow: 1 },
        { width: 300, itemsToShow: 1 },
        { width: 600, itemsToShow: 2 },
        { width: 960, itemsToShow: 3 },
        { width: 1280, itemsToShow: 4 },
        { width: 1920, itemsToShow: 5 }
    ];

    const gotoCategories = function () {
        history.push('/cates');
        scroll2Top();
    };

    const gotoDiplomados = function () {
        history.push('/certified');
        scroll2Top();
    };

    const gotoMyCourses = function () {
        history.push('/my-courses');
        scroll2Top();
    };

    const handleClickSubscription = function (event) {
        event.preventDefault();
        history.push("/subscription/Inicio");
        scroll2Top();
    };

    return (

        <>
            <Container maxWidth='xl' className='fix-position-of-first-page-item'>

                {/** TODO: simplificar, unificar y modularizar banners */}
                {isStandaloneDeployment &&
                    <div>
                        <img src={banner_principal} style={{ width: '100%', cursor: 'pointer' }} onClick={gotoCategories} />
                    </div>
                }
                {!isStandaloneDeployment &&
                    (() => {
                        if (banners['top']) {
                            const banner = banners['top'];
                            return <Banner image={banner.image} link={banner.link} />;
                        }
                    })()
                }


                {/*BEGIN MIS CURSOS*/}
                {(!!mycourseList.length) &&
                <Container maxWidth='lg' className='fix-position-of-first-page-item'>
                  <div className="home-page rel" style={{ marginTop: '35px', marginBottom: '1rem' }}>
                      <div className="extra-block rel">

                          <h2 className="title_section s24 fontb" style={{ marginBottom: '1rem' }}>Mis cursos <a onClick={gotoMyCourses} target="_blank" color="textSecondary" align='center' style={{ color: 'rgb(117, 117, 117)', fontSize: '14px' }}> Ir a mis cursos →</a></h2>
                          <div className="courses rel flex" style={{ marginLeft: '-68px', marginRight: '-68px' }}>
                            {/*
                            // @ts-ignore */}
                            <Hidden smUp>
                                <Carousel breakPoints={breakPoints} enableAutoPlay={false} autoPlaySpeed={3000} showArrows={false} >
                                    {mycourseList}
                                    {disableLoadingCursos != true &&
                                        <CircularProgress className="CircularProgress" style={{ alignSelf: 'center' }} />
                                    }
                                </Carousel>
                            </Hidden>
                            {/*
                            // @ts-ignore */}
                            <Hidden xsDown>
                                <Carousel breakPoints={breakPoints} enableAutoPlay={false} autoPlaySpeed={3000}>
                                    {mycourseList}
                                    {disableLoadingCursos != true &&
                                        <CircularProgress className="CircularProgress" style={{ alignSelf: 'center' }} />
                                    }
                                </Carousel>
                            </Hidden>
                          </div>
                      </div>
                  </div>
                </Container>
                }
                {/*END MIS CURSOS*/}

                <Container maxWidth='lg' className='fix-position-of-first-page-item'>

                    <div className="home-page rel" style={{ marginTop: '35px', marginBottom: '1rem' }}>
                        <div className="extra-block rel">
                            <h2 className="title_section s24 fontb" style={{ marginBottom: '1rem' }}>Cursos populares</h2>
                            <div className="courses rel flex" style={{ marginLeft: '-68px', marginRight: '-68px' }}>
                                {/*
                                // @ts-ignore */}
                                <Hidden smUp>
                                    <Carousel breakPoints={breakPoints} enableAutoPlay={true} autoPlaySpeed={3000} showArrows={false} >
                                        {courseList}
                                        {disableLoadingCursos != true &&
                                            <CircularProgress className="CircularProgress" style={{ alignSelf: 'center' }} />
                                        }
                                    </Carousel>
                                </Hidden>
                                {/*
                                // @ts-ignore */}
                                <Hidden xsDown>
                                    <Carousel breakPoints={breakPoints} enableAutoPlay={true} autoPlaySpeed={3000}>
                                        {courseList}
                                        {disableLoadingCursos != true &&
                                            <CircularProgress className="CircularProgress" style={{ alignSelf: 'center' }} />
                                        }
                                    </Carousel>
                                </Hidden>
                            </div>
                        </div>
                    </div>

                    {cursosHome instanceof Array && cursosHome.length > 0 &&
                        cursosHome.map(ch => <div key={ch.id_categoria} style={{ marginBottom: '1rem' }} >
                            <h2 className="title_section s24 fontb" style={{ marginBottom: '1rem' }}>{ch.nombre_categoria}</h2>
                            <PanelCursosCategory categoryId={ch.id_categoria} handleShowVideo={handleShowVideo} requestCursos={false} cursosExternos={ch.curso} />
                        </div>)
                    }
                    {cursosHome === null &&
                        <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '1rem' }}>
                            <CircularProgress className="CircularProgress" style={{ alignSelf: 'center' }} />
                        </div>
                    }

                    {!isStandaloneDeployment && banners['middle'] &&
                        (() => {
                            const banner = banners['middle'];
                            return <Banner image={banner.image} link={banner.link} style={{ marginTop: '30px' }} />;
                        })()
                    }

                    {!hideDiplomados &&
                        <div className="home-page rel" style={{ marginTop: '2rem' }}>

                            <h2 className="title_section s24 fontb">Programas</h2>
                            <div className="courses rel flex" style={{ marginTop: '10px' }}>
                                {/*
                                // @ts-ignore */}
                                <Hidden smUp>

                                    <Carousel breakPoints={breakPoints} enableAutoPlay={true} autoPlaySpeed={3000} showArrows={false}>
                                        {diplomadosList}

                                        {disableLoadingDiplomados != true &&
                                            <CircularProgress className="CircularProgress" style={{ alignSelf: 'center' }} />
                                        }
                                    </Carousel>

                                </Hidden>
                                {/*
                                // @ts-ignore */}
                                <Hidden xsDown>

                                    <Carousel breakPoints={breakPoints} enableAutoPlay={true} autoPlaySpeed={3000}>
                                        {diplomadosList}

                                        {disableLoadingDiplomados != true &&
                                            <CircularProgress className="CircularProgress" style={{ alignSelf: 'center' }} />
                                        }
                                    </Carousel>

                                </Hidden>

                            </div>

                            <Button type="button" onClick={gotoDiplomados} variant="contained" color="primary" className="button_accept" fullWidth style={{ marginTop: '2rem' }}>
                                Ver más
                            </Button>

                        </div>
                    }
                </Container>

            </Container>


            <Container maxWidth='lg' style={{ marginTop: '80px' }}>

                {!isStandaloneDeployment && banners['bottom'] &&
                    (() => {
                        const banner = banners['bottom'];
                        return <Banner image={banner.image} link={banner.link} />;
                    })()
                }

                <div style={{ marginTop: '90px' }} /*className="App rel"*/>

                    <div className="section rel">

                        <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} spacing={3} >
                            {/*<SubscriptionButton handleClick={handleClickSubscription}/>*/}
                        </Grid>

                        <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} spacing={1} >
                            {/* <SearchBar />*/}
                        </Grid>
                    </div>


                    <Snackbar open={openSnackbarWarning} autoHideDuration={5000} onClose={handleCloseSnackbarWarning} /*style = {styles.snakbar}*/ anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
                        <Alert severity="warning" variant="filled" >
                            {mensajebackend}
                        </Alert>
                    </Snackbar>
                    <Snackbar open={openSnackbarWarningDatos} autoHideDuration={5000} onClose={handleCloseSnackbarWarningDatos} /*style = {styles.snakbar}*/ anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
                        <Alert severity="warning" variant="filled" >
                            No se encontraron datos.
                        </Alert>
                    </Snackbar>
                    <Snackbar open={openSnackbarError} autoHideDuration={5000} onClose={handleCloseSnackbarError} /*style = {styles.snakbar}*/ anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
                        <Alert severity="error" variant="filled" >
                            No se encontraron datos.
                        </Alert>
                    </Snackbar>
                    <Snackbar open={openmensbackend} autoHideDuration={3000} onClose={handleCloseMens} /*style = {styles.snakbar}*/ anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
                        <Alert onClose={handleCloseMens} severity="error" variant="filled">
                            {mensajebackend}
                        </Alert>
                    </Snackbar>


                    <div className="home-page rel">
                        <div className="extra-block rel" style={{ paddingBottom: '3rem' }}>


                            <Grid container spacing={1} item xs={12} direction="column" justifyContent="center" alignItems="center" >

                                <Grid container spacing={1} item xs={12} direction="row" justifyContent="center" alignItems="center">

                                    <Grid item xs={12} sm={12} md={6} lg={4} xl={4} >
                                        {/*
                                        // @ts-ignore */}
                                        <Hidden xsDown smDown  >
                                            <div align='center' style={{ backgroundColor: 'white', borderRadius: '70px' }}>
                                                <a href="#" className="logo-pagina-principal bl" style={{ pointerEvents: 'none' }} >
                                                    <img src={logoEnapsisColor} className="logo-pagina-principal bl" />
                                                </a>
                                            </div>
                                        </Hidden>
                                        {/*
                                        // @ts-ignore */}
                                        <Hidden mdUp>
                                            <br></br><br></br>
                                            <div align='center' className="jtx">
                                                <div style={{ whiteSpace: 'pre-line' }}>{wrapParagraphs(END_OF_PAGE_TEXT, true)}</div>
                                            </div>
                                        </Hidden>

                                    </Grid>

                                    <Grid item xs={12} sm={12} md={6} lg={8} xl={8} >
                                        {/*
                                        // @ts-ignore */}
                                        <Hidden xsDown smDown  >

                                            <div align='center' className="jtx" style={{ justifyContent: 'center', padding: '30px' }} >
                                                <div style={{ whiteSpace: 'pre-line' }}>{wrapParagraphs(END_OF_PAGE_TEXT, true)}</div>
                                            </div>
                                        </Hidden>
                                        {/*
                                        // @ts-ignore */}
                                        <Hidden mdUp>
                                            <div align='center' >
                                                <a href="#" className="logocel-pagina-principal bl" style={{ backgroundColor: 'white', borderRadius: '70px', pointerEvents: 'none' }}>
                                                    <img src={logoEnapsisColor} className="logocel-pagina-principal bl" />
                                                </a>
                                            </div>
                                        </Hidden>

                                    </Grid>

                                </Grid>

                            </Grid>

                        </div>

                    </div>

                </div>

            </Container>
            <ModalVideoPlayer video={cursoVideoModal?.video} open={!!cursoVideoModal} curso={cursoVideoModal} handleClose={() => { setCursoVideoModal(null); }} />
        </>
    );
}
