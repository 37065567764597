import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import OndemandVideoIcon from '@material-ui/icons/OndemandVideo';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import HelpIcon from '@material-ui/icons/Help';
import RemoveIcon from '@material-ui/icons/Remove';
import PostAddIcon from '@material-ui/icons/PostAdd';
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import Dropdown from 'react-bootstrap/Dropdown';
import { TIPO_CLASE } from "../lib/constants";
import { theme as tema } from "../util/theme";
import { useSnackbar } from "notistack";
import { detectarTipoClase } from "../lib/api";

const useStyles = makeStyles((theme) => ({
    /** @param {{ fixedWidth?: boolean }} props */
    mainContainer: ({ fixedWidth = true }) => {
        const style = {
            width: '100%',
            minWidth: fixedWidth ? '24rem' : undefined,
            maxWidth: fixedWidth ? '24rem' : undefined,
            borderStyle: 'solid',
            borderWidth: '1px',
            borderColor: 'var(--color-border-form-div-input)',
            padding: '0.5rem',
            cursor: 'default',
            [theme.breakpoints.down('md')]: {
                minWidth: fixedWidth ? '30%' : undefined
            },
            '& .not-allowed': {
                cursor: 'not-allowed !important'
            }
        };
        if (!fixedWidth) {
            /** ocultar borde (separador) para la última unidad */
            style['& .unit-container:last-child'] = {
                // @ts-ignore
                borderBottomWidth: '0'
            };
        }
        return style;
    },
    unitContainer: {
        display: 'flex',
        flexDirection: 'column',
        borderBottomStyle: 'solid',
        borderBottomWidth: '2px',
        borderBottomColor: 'var(--color-border-form-div-input)',
        padding: '0.5rem',
        paddingTop: '0.8rem',
        paddingBottom: '0.8rem',
        '& > .unit-title': {
            display: 'flex',
            justifyContent: 'space-between',
            color: 'var(--color-always-colored)',
            padding: '0.5rem'
        }
    },
    clase: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '0.5rem',
        paddingRight: '35px', // para dejar espacio para el botón editar (posicionado absolutamente)
        backgroundColor: tema.isLight ? '#faf7f036 !important' : '#faf7f017 !important',
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: 'gray',
        margin: '0.2rem',
        borderRadius: '5px',
        /* ocultar flecha del dropdown */
        '& .dropdown-toggle::after': {
            display: 'none'
        },
        '& .dropdown': {
            position: 'absolute',
            left: 'calc(100% - 54px)',
            backgroundColor: 'transparent'
        },
        '& .btn': {
            display: 'flex',
            padding: '0 1px',
            backgroundColor: 'transparent',
            color: 'var(--primary-color)',
            borderColor: 'transparent',
            opacity: '0.9'
        },
        '& .btn:hover': {
            borderColor: tema.isLight ? '#282c3475' : '#ffffff70',
            opacity: '1'
        }
    },
    topActionPanel: {
        display: 'flex',
        gap: '0.5rem',
        borderBottomWidth: '1px',
        borderBottomStyle: 'solid',
        borderBottomColor: 'var(--color-border-form-div-input)',
        paddingBottom: '0.4rem',
        '& button': {
            display: 'flex',
            alignItems: 'center'
        },
        '& button svg': {
            mraginRight: '0.2rem'
        }
    }
}));

/**
 * @param {Object} obj
 * @param {boolean} obj.disabled
 * @param {() => void} obj.handleAgregarClase
 * @param {() => void} obj.handleReordenar
 * @returns {JSX.Element}
 */
const TopActionPanel = function ({ disabled, handleAgregarClase, handleReordenar }) {
    const classes = useStyles({});
    return (
        // NOTA: '<form>' se agrega solo para que apliquen ciertos estilos ya definidos para los botones en App.css
        <form>
            <div className={classes.topActionPanel}>
                <button onClick={handleReordenar} disabled={disabled} >
                    <FormatListNumberedIcon />
                    <span>Reordenar</span>
                </button>
                <button onClick={handleAgregarClase} disabled={disabled} >
                    <PostAddIcon />
                    <span>Agregar clase</span>
                </button>
            </div>
        </form>
    );
};

/**
 * @param {Object} obj
 * @param {number} obj.tipo
 * @returns {JSX.Element}
 */
export const IconoClase = function ({ tipo }) {
    switch (tipo) {
        case TIPO_CLASE.VIDEO:
        case null: // hay clases de video con param_tipo = null, posiblemente agregados mal durante desarrollo.
            return <OndemandVideoIcon />;
        case TIPO_CLASE.ZIP:
            return <AttachFileIcon />;
        case TIPO_CLASE.TEST:
            return <HelpOutlineIcon />;
        case TIPO_CLASE.TEST_FORMATIVO:
            return <HelpIcon />;
        default:
            return <RemoveIcon />;
    }
};

/**
 * @param {Object} obj
 * @param {() => void} obj.handleEditar
 * @param {() => void} obj.handleRenombrar
 * @param {() => void} obj.handleEliminar
 * @returns {JSX.Element}
 */
const DropdownClase = function ({ handleEditar, handleRenombrar, handleEliminar }) {
    return (<>
        <Dropdown>
            <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                <EditOutlinedIcon />
            </Dropdown.Toggle>
            <Dropdown.Menu renderOnMount={true} /* para evitar el flicker que ocurre porque inicialmente se muestra en una posición incorrecta */>
                <Dropdown.Item eventKey="1" onClick={handleEditar}>Editar</Dropdown.Item>
                <Dropdown.Item eventKey="2" onClick={handleRenombrar}>Renombrar</Dropdown.Item>
                <Dropdown.Item eventKey="3" onClick={handleEliminar}>Eliminar</Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    </>);
};

/**
 * @param {Object} obj
 * @param {(event: React.MouseEvent<HTMLElement>) => void} obj.handleRenombrar
 * @param {(event: React.MouseEvent<HTMLElement>) => void} obj.handleEliminar
 * @param {boolean} obj.first
 * @returns {JSX.Element}
 */
const DropdownUnidad = function ({ handleRenombrar, handleEliminar, first }) {
    return (<>
        <Dropdown>
            <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                <EditOutlinedIcon />
            </Dropdown.Toggle>
            <Dropdown.Menu renderOnMount={true} /* para evitar el flicker que ocurre porque inicialmente se muestra en una posición incorrecta */>
                <Dropdown.Item eventKey="1" onClick={handleRenombrar}>Renombrar Unidad</Dropdown.Item>
                <Dropdown.Item eventKey="2" onClick={handleEliminar} style={{ pointerEvents: 'auto' }} className={ first ? 'disabled not-allowed' : '' }>Eliminar Unidad</Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    </>);
};

/**
 * @param {Object} obj
 * @param {api.ClaseInstructor} obj.clase
 * @param {(clase: api.ClaseInstructor)  => void} obj.handleEditar
 * @param {(clase: api.ClaseInstructor) => void} obj.handleRenombrar
 * @param {(clase: api.ClaseInstructor) => void} obj.handleEliminar
 * @returns {JSX.Element}
 */
const Clase = function ({ clase, handleEditar, handleRenombrar, handleEliminar }) {
    const classes = useStyles({});
    const tipo = clase.param_tipo !== null ? clase.param_tipo : detectarTipoClase(clase);
    return (<>
        <div className={classes.clase}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ marginRight: '0.4rem', display: 'flex' }}>
                    <IconoClase tipo={tipo} />
                </div>
                <span>{clase.titulo}</span>
            </div>
            <DropdownClase
                handleEditar={() => { handleEditar(clase); }}
                handleRenombrar={() => { handleRenombrar(clase); }}
                handleEliminar={() => { handleEliminar(clase); }}
            />
        </div>
    </>);
};

/**
 * @param {Object} obj
 * @param {api.Unidad} obj.unidad
 * @param {boolean} obj.first
 * @param {(clase: api.Clase) => void} obj.handleEditarClase
 * @param {(clase: api.Clase) => void} obj.handleRenombrarClase
 * @param {(clase: api.Clase) => void} obj.handleEliminarClase
 * @param {(unidad: api.Unidad) => void} obj.handleRenombrar
 * @param {(unidad: api.Unidad) => void} obj.handleEliminar
 * @returns {JSX.Element}
 */
const Unidad = function ({ unidad, first, handleEditarClase, handleRenombrarClase, handleEliminarClase, handleRenombrar, handleEliminar }) {
    const clases = unidad.clases;
    const classes = useStyles({});
    const { enqueueSnackbar } = useSnackbar();
    const nodosClases = clases.map(clase => {
        return <Clase clase={clase} key={clase.id} handleEditar={handleEditarClase} handleRenombrar={handleRenombrarClase} handleEliminar={handleEliminarClase}  />;
    });
    /**
     * @param {React.MouseEvent<HTMLElement>} event
     */
    const alertaEliminarPrimeraUnidad = function (event) {
        event.preventDefault();
        event.stopPropagation();
        enqueueSnackbar('La primera unidad de un curso no puede ser eliminada', {
            variant: 'warning'
        } );
    };
    return (
        <div className={[classes.unitContainer, 'unit-container'].join(' ')}>
            <div className="unit-title">
                <span>{unidad.nombre_unidad}</span>
                <DropdownUnidad
                    first={first}
                    handleRenombrar={() => { handleRenombrar(unidad); }}
                    handleEliminar={first ? alertaEliminarPrimeraUnidad : () => {handleEliminar(unidad); }}
                />
            </div>
            {nodosClases}
        </div>
    );
};

/**
 * @param {Object} obj
 * @param {api.ListaClases} obj.curso
 * @param {boolean} [obj.loading]
 * @param {boolean} [obj.disabled]
 * @param {boolean} [obj.fixedWidth=true] - default = true
 * @param {() => void} obj.handleAgregarClase
 * @param {() => void} obj.handleReordenar
 * @param {(clase: api.Clase) => void} obj.handleEditarClase
 * @param {(clase: api.Clase) => void} obj.handleRenombrarClase
 * @param {(clase: api.Clase) => void} obj.handleEliminarClase
 * @param {(unidad: api.Unidad) => void} obj.handleRenombrarUnidad
 * @param {(unidad: api.Unidad) => void} obj.handleEliminarUnidad
 * @returns {JSX.Element}
 */
export default function ListadoUnidades({ curso, loading, disabled, fixedWidth = true, handleAgregarClase, handleReordenar, handleEditarClase, handleRenombrarClase, handleEliminarClase, handleRenombrarUnidad, handleEliminarUnidad }) {

    const classes = useStyles({ fixedWidth });
    /** @type {JSX.Element[]} */
    let nodosUnidades;

    if (curso?.n_unidades > 0) {
        const unidades = Object.values(curso.unidades);
        nodosUnidades = unidades.map((unidad, index) => {
            return <Unidad
                unidad={unidad}
                first={index === 0}
                key={unidad.id_unidad}
                handleEditarClase={handleEditarClase}
                handleRenombrarClase={handleRenombrarClase}
                handleEliminarClase={handleEliminarClase}
                handleRenombrar={handleRenombrarUnidad}
                handleEliminar={handleEliminarUnidad}
            />;
        });

    }
    return (
        <div className={[classes.mainContainer, 'has-bootstrap'].join(' ')}>
            <TopActionPanel disabled={disabled} handleAgregarClase={handleAgregarClase} handleReordenar={handleReordenar} />
            {nodosUnidades
                ? nodosUnidades
                : <>
                    <div style={{ padding: '1rem', color: 'var(--primary-color)', opacity: '0.5', fontSize: 'medium' }}>
                        { loading ? "Cargando..." : "Curso vacío" }
                    </div>
                </>
            }
        </div>
    );

}
