import React, { useContext, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import { FormControlLabel, useMediaQuery } from "@material-ui/core";
import { useSnackbar } from 'notistack';
import { useIsFirstRender } from "usehooks-ts";
import CancelIcon from '@material-ui/icons/Cancel';
import AttachFile from '@material-ui/icons/AttachFile';
import imageSubir from '../ui/subir-img-icon.png';

import { BotonContext } from "../BotonContext";
import AuthContext from "../AuthContext";
import SweetAlertSuccessTick from "../componentes/SweetAlertSuccessTick";
import api from "../lib/api";
import { clearSession, useSession } from "../util/session";
import { rutHelper } from "../util/utils";
import commonIO from "../util/common.io";
import { strings } from "../lib/i18n";
import ButtonCircularProgress from "../componentes/ButtonCircularProgress";

const TERMS_AND_CONDITIONS_HTML = "/CondicionesdelSocioRelator.html";
const STRING_CAMPO_OBLIGATORIO = "Este campo es obligatorio";
const VALIDATE_ROOT = false;

const useStyles = makeStyles(theme => ({
    formControl: {
        margin: '8px',
        /** @type {React.CSSProperties} */
        [theme.breakpoints.down('xs')]: {
            paddingLeft: '0.5rem',
            paddingRight: '0.5rem',
            margin: 0
        },
        '& .fieldInfoLabel': {
            fontSize: 12,
            color: "red",
            paddingLeft: '0.4rem',
            /** @param {{ nextPressed: boolean }} props */
            lineHeight: ({ nextPressed }) => {
                return nextPressed ? 'unset' : '0';
            }
        }
    }
}));

const useResponsiveStyles = makeStyles(theme => ({
    card: {
        /** @type {React.CSSProperties} */
        [theme.breakpoints.down('xs')]: {
            borderWidth: 0
        }
    },
    cardContent: {
        /** @type {React.CSSProperties} */
        [theme.breakpoints.down('xs')]: {
            padding: '0 !important',
            paddingTop: '1rem !important'
        }
    }
}));

/**
 * @param {Object} params
 * @param {boolean} params.nextPressed
 * @param {React.ReactNode} params.children
 * @param {string} [params.maxWidth] - default = 550px
 * @returns {JSX.Element}
 */
const StepFormContainer = function ({ nextPressed, children, maxWidth = '550px' }) {
    const classes = useStyles({ nextPressed });
    const classesResponsive = useResponsiveStyles();
    return (<>
        <form id="form" style={{ maxWidth: '100%' }}>
            <Card className={classesResponsive.card} variant="outlined" style={{ width: '100%', display: 'flex', justifyContent: 'center', borderTopWidth: 0 }}>
                <CardContent className={["registercard registercard-bottom", classesResponsive.cardContent].join(' ')} style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '2rem' }} >
                    <FormControl variant="outlined" className={classes.formControl} style={{ maxWidth, width: '100%' }}>
                        {children}
                    </FormControl>
                </CardContent>
            </Card>
        </form>
    </>);
};

/**
 * @typedef DataStep1
 * @property {boolean} valid
 * @property {string} nombres
 * @property {string} apellidos
 * @property {string} identification
 * @property {string} correo
 */

/**
 * @param {Object} params
 * @param {(data: DataStep1) => void} params.handleData
 * @param {boolean} params.nextPressed
 * @param {DataStep1} [params.initialValues]
 * @returns {JSX.Element}
 */
const Step1 = function ({ handleData, nextPressed, initialValues }) {

    const isFirst = useIsFirstRender();
    const [nombres, setNombres] = useState(initialValues?.nombres || '');
    const [errorNombres, setErrorNombres] = useState('');
    const [apellidos, setApellidos] = useState(initialValues?.apellidos || '');
    const [errorApellidos, setErrorApellidos] = useState('');
    const [correo, setCorreo] = useState(initialValues?.correo || '');
    const [errorCorreo, setErrorCorreo] = useState('');
    const [identification, setIdentification] = useState(initialValues?.identification || '');
    const [errorIdentification, setErrorIdentification] = useState('');

    /**
     * @param {string} value
     * @param {boolean} required
     * @returns {string} - mensaje de error, null si es válido
     */
    const validateText = function (value, required) {
        if (!value && required) {
            return STRING_CAMPO_OBLIGATORIO;
        } else if (value && value.length > 250) {
            return "No debe pasar de 250 caracteres";
        } else {
            return '';
        }
    };

    /**
     * @param {string} newValue
     * @returns {boolean}
     */
    const validateNombres = function (newValue) {
        let error = validateText(newValue, true);
        setErrorNombres(error);
        return !error;
    };

    /**
     * @param {string} newValue
     * @returns {boolean}
     */
    const validateApellidos = function (newValue) {
        let error = validateText(newValue, true);
        setErrorApellidos(error);
        return !error;
    };

    /**
     * @param {string} newValue
     * @returns {boolean}
     */
    const validateIdentification = function (newValue) {
        let error = '';
        if (!newValue) {
            error = STRING_CAMPO_OBLIGATORIO;
        } else if (VALIDATE_ROOT && !rutHelper.validar(newValue)) {
            error = "Su identificación no es válida. El formato es con dígito verificador y sin puntos. Ejemplo: 11111111-1";
        }
        setErrorIdentification(error);
        return !!error;
    };

    /**
     * @param {string} newValue
     * @returns {boolean}
     */
    const validateEmail = function (newValue) {
        let error = '';
        if (!newValue) {
            error = STRING_CAMPO_OBLIGATORIO;
        } else if (!commonIO.isValidEmail(newValue)) {
            error = 'debe especificar un correo válido';
        }
        setErrorCorreo(error);
        return !!error;
    };

    if (isFirst) {
        validateNombres(nombres);
        validateApellidos(apellidos);
        validateIdentification(identification);
        validateEmail(correo);
    }

    const valid = !errorNombres && !errorApellidos && !errorIdentification && !errorCorreo;

    if (typeof handleData === 'function') {
        handleData({
            valid, nombres, apellidos, correo, identification
        });
    }

    return (<>
        <StepFormContainer nextPressed={nextPressed}>
            {/* NOMBRES */}
            <TextField id="name" label="Nombres"
                InputProps={{
                    className: "InputText"
                }}
                InputLabelProps={{
                    className: "InputText"
                }}
                maxRows={12}
                minRows={7} variant="outlined" fullWidth
                error={!!errorNombres && nextPressed}
                onChange={(e) => {
                    const value = e.target.value;
                    setNombres(value);
                    validateNombres(value);
                }}
                type="text"
                value={nombres}
                required
            />
            <p className="fieldInfoLabel" style={{ visibility: errorNombres && nextPressed ? "visible" : "hidden" }}>
                {errorNombres}
            </p>

            {/* APELLIDOS */}
            <TextField id="lastName" label="Apellidos"
                InputProps={{
                    className: "InputText"
                }}
                InputLabelProps={{
                    className: "InputText"
                }}
                maxRows={12}
                minRows={7} variant="outlined" fullWidth
                error={!!errorApellidos && nextPressed}
                onChange={(e) => {
                    const value = e.target.value;
                    setApellidos(value);
                    validateApellidos(value);
                }}
                type="text"
                value={apellidos}
                required
            />
            <p className="fieldInfoLabel" style={{ visibility: errorApellidos && nextPressed ? "visible" : "hidden" }}>
                {errorApellidos}
            </p>

            {/* IDENTIFICACIÓN */}
            <TextField id="rut" label="Identificación"
                InputProps={{
                    className: "InputText"
                }}
                InputLabelProps={{
                    className: "InputText"
                }}
                maxRows={12}
                minRows={7} variant="outlined" fullWidth
                error={!!errorIdentification && nextPressed}
                onChange={(e) => {
                    const value = e.target.value;
                    // solo permitir dígitos, punto (.), guión (-) y la letra "K"
                    if (!value || /^[\d.\-K]+$/i.test(value)) {
                        setIdentification(value);
                        validateIdentification(value);
                    }
                }}
                type="text"
                value={identification}
                required
            />
            <p className="fieldInfoLabel" style={{ visibility: errorIdentification && nextPressed ? "visible" : "hidden" }}>
                {errorIdentification}
            </p>

            {/* CORREO */}
            <TextField id="email" label="Correo"
                InputProps={{
                    className: "InputText"
                }}
                InputLabelProps={{
                    className: "InputText"
                }}
                maxRows={12}
                minRows={7} variant="outlined" fullWidth
                error={!!errorCorreo && nextPressed}
                onChange={(e) => {
                    const value = e.target.value;
                    setCorreo(value);
                    validateEmail(value);
                }}
                type="email"
                value={correo}
                required
            />
            <p className="fieldInfoLabel" style={{ visibility: errorCorreo && nextPressed ? "visible" : "hidden" }}>
                {errorCorreo}
            </p>
        </StepFormContainer>
    </>);

};

/**
 * @typedef DataStep2
 * @property {boolean} valid
 * @property {string} description
 * @property {string} imageDataURL
 * @property {string} imageB64
 */

/**
 * @param {Object} params
 * @param {(data: DataStep2) => void} params.handleData
 * @param {boolean} params.nextPressed
 * @param {DataStep2} [params.initialValues]
 * @returns {JSX.Element}
 */
const Step2 = function ({ handleData, nextPressed, initialValues }) {

    const isFirst = useIsFirstRender();
    const [description, setDescription] = useState(initialValues?.description || '');
    const [errorDescription, setErrorDescription] = useState('');
    const [imageDataURL, setImageDataURL] = useState(/** @type {string} */(initialValues?.imageDataURL || ''));
    const [imageB64, setImageB64] = useState(/** @type {string} */(initialValues?.imageB64 || ''));
    const [errorImage, setErrorImage] = useState('');

    /**
     * @param {string} newValue
     * @returns {boolean}
     */
    const validateDescription = function (newValue) {
        let error = '';
        if (!newValue) {
            error = STRING_CAMPO_OBLIGATORIO;
        } else if (newValue.length > 500) {
            error = "No debe pasar de 500 caracteres";
        }
        setErrorDescription(error);
        return !error;
    };

    /**
     * @param {Object} obj
     * @param {string} obj.newImageDataURL
     * @param {Object} obj.newImageB64
     * @returns {boolean}
     */
    const validateImage = function ({ newImageDataURL, newImageB64 }) {
        let error = '';
        if (!newImageDataURL || !newImageB64) {
            error = "Debe adjuntar una imagen";
        }
        setErrorImage(error);
        return !error;
    };

    if (isFirst) {
        validateImage({ newImageDataURL: imageDataURL, newImageB64: imageB64 });
        validateDescription(description);
    }

    const valid = !errorImage && !errorDescription;

    if (typeof handleData === 'function') {
        handleData({
            valid, description, imageDataURL, imageB64
        });
    }

    /**
     * @param {React.ChangeEvent<HTMLInputElement>} event
     */
    const handleImageChange = function (event) {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = () => {
                const dataURL = reader.result.toString();
                const b64String = commonIO.getBinaryB64FromImageDataURL(dataURL);
                setImageDataURL(dataURL);
                setImageB64(b64String);
                validateImage({ newImageB64: b64String, newImageDataURL: dataURL });
            };
            reader.readAsDataURL(file);
        } else {
            setImageDataURL('');
            setImageB64('');
        }
    };

    const handleRemoveImage = function () {
        setImageB64('');
        setImageDataURL('');
        validateImage({ newImageB64: '', newImageDataURL: '' });
    };

    return (<>
        <StepFormContainer nextPressed={nextPressed}>
            {/* FOTO / IMAGEN */}
            <Grid item xs={12}>
                <div className="section title_section content" style={{ marginTop: '-20px' }}>

                    <h4 className="title_section" style={{ marginBottom: '0.8rem' }}>Foto de perfil: </h4>

                    {/* vista previa */}
                    <div style={{ display: 'flex', gap: '2rem', marginBottom: '0.8rem' }}>

                        <label htmlFor="tutor-image">
                            <Card className="themed-border" style={{ maxWidth: '330px', cursor: 'pointer' }} elevation={2} >
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        backgroundImage: `url(${imageSubir})`,
                                        backgroundRepeat: 'no-repeat',
                                        backgroundPosition: 'center',
                                        backgroundColor: '#ddd',
                                        height: !imageDataURL ? '10rem' : '',
                                        width: !imageDataURL ? '10rem' : ''
                                    }}
                                >
                                    <img src={imageDataURL ? imageDataURL : ''} style={{ maxWidth: '100%', display: 'block' }} className="checkerboard-background"></img>
                                </div>
                            </Card>
                        </label>

                    </div>

                    <div>
                        {/* botón adjuntar */}
                        <Button variant="contained" component="label" className="button_accept">
                            <AttachFile/>
                            <input hidden type="file" id="tutor-image" accept="image/png, image/jpeg, image/jpg"
                                onChange={handleImageChange}
                                style={{ color: "white", backgroundColor: "transparent" }}
                            />
                            Adjuntar imagen
                        </Button>

                        {/* botón remover */}
                        {!!imageDataURL &&
                            <Button variant="contained" component="label" className="button_accept" size="small" style={{ marginLeft: '5px', marginTop: '0px', height: '39px' }} onClick={handleRemoveImage} >
                                <CancelIcon/>
                            </Button>
                        }
                    </div>

                    <p style={{ fontSize: 12, color: "red", visibility: errorImage && nextPressed ? "visible" : "hidden" }} >
                        {errorImage}
                    </p>
                </div>
            </Grid>

            {/* DESCRIPCIÓN */}
            <TextField id="description" label="Descripción"
                InputProps={{
                    className: "InputText"
                }}
                InputLabelProps={{
                    className: "InputText"
                }}
                maxRows={12}
                minRows={7} variant="outlined" fullWidth
                error={!!errorDescription && nextPressed}
                onChange={(e) => {
                    const value = e.target.value;
                    setDescription(value);
                    validateDescription(value);
                }}
                type="text"
                value={description}
                required
                multiline
                style={{ marginTop: '0.3rem' }}
            />
            <p className="fieldInfoLabel" style={{ visibility: errorDescription && nextPressed ? "visible" : "hidden" }}>
                {errorDescription}
            </p>
        </StepFormContainer>
    </>);
};

/**
 * @typedef DataStep3
 * @property {boolean} valid
 */

/**
 * @param {Object} params
 * @param {(data: DataStep3) => void} params.handleData
 * @param {boolean} params.nextPressed
 * @param {DataStep3} [params.initialValues]
 * @param {boolean} params.isSaving
 * @returns {JSX.Element}
 */
const Step3 = function ({ handleData, nextPressed, initialValues, isSaving }) {

    const isFirst = useIsFirstRender();
    const theme = useTheme();
    const smallScreen = useMediaQuery(theme.breakpoints.down('xs'));
    const [accepted, setAccepted] = useState(!!initialValues?.valid);
    const [errorAccepted, setErrorAccepted] = useState('');

    /**
     * @param {boolean} newValue
     * @returns {boolean}
     */
    const validateCheckbox = function (newValue) {
        let error = '';
        if (!newValue) {
            error = "Se requiere aceptar los términos y condiciones para continuar";
        }
        setErrorAccepted(error);
        return !error;
    };

    if (isFirst) {
        validateCheckbox(accepted);
    }

    const valid = !errorAccepted;

    if (typeof handleData === 'function') {
        handleData({
            valid
        });
    }

    /**
     * @param {React.ChangeEvent<HTMLInputElement>} e
     */
    const handleChangeAccept = function (e) {
        const checked = e.target.checked;
        setAccepted(checked);
        validateCheckbox(checked);
    };

    return (<>
        <StepFormContainer nextPressed={nextPressed} maxWidth="800px" >
            <div style={{ fontSize: '20px', marginBottom: '0.4rem', marginTop:  !smallScreen ? '-35px' : null }}>Términos y condiciones</div>
            <div style={{ height: '26rem', filter: 'brightness(97%)', borderWidth: '1px', borderStyle: 'solid' }}>
                <iframe src={TERMS_AND_CONDITIONS_HTML} seamless style={{ width: '100%', height: '100%', border: 0 }}></iframe>
            </div>
            <FormControlLabel
                control={
                    <Checkbox
                        className="checkbox"
                        checked={accepted}
                        onChange={handleChangeAccept}
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                        disabled={isSaving}
                    />
                }
                label="Acepto los términos y condiciones"
                labelPlacement="end"
                disabled={isSaving}
            />
            <p className="fieldInfoLabel" style={{ visibility: errorAccepted && nextPressed ? "visible" : "hidden" }}>
                {errorAccepted}
            </p>
        </StepFormContainer>
    </>);
};

/** @type {DataStep1} */
let dataStep1 = null;
/** @type {DataStep2} */
let dataStep2 = null;
/** @type {DataStep3} */
let dataStep3 = null;
let currentStepValid = false;

/**
 * @returns {JSX.Element}
 */
export default function TutorRegistration() {

    const { isAuth, isAuthTypeTutor } = useContext(AuthContext);
    const { data_user, token, setDataUser } = useContext(BotonContext);
    const { enableTutorFlag } = useSession();
    const theme = useTheme();
    const smallScreen = useMediaQuery(theme.breakpoints.down('xs'));
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();

    const [nextPressed, setNextPressed] = useState(false);
    const [activeStep, setActiveStep] = useState(0);
    const [isSaving, setIsSaving] = useState(false);

    const ValidarAuth = () => {
        if (!isAuth) {
            clearSession();
            window.location.href = "#/login";
            window.location.reload();
            return;
        }
        if (isAuthTypeTutor) {
            gotoTutorias();
        }
    };

    useEffect(() => {
        ValidarAuth();
    }, []);

    const gotoTutorias = function () {
        history.push("/my-courses-tutor");
    };

    /**
     * @param {api.RespuestaRegistroInstructor} instructorData
     */
    const handleSuccess = function (instructorData) {
        setActiveStep(prevActiveStep => prevActiveStep + 1);
        window.scrollTo(0, 0);
        enableTutorFlag({
            dataUserInstructor: instructorData
        });
    };

    const registerInstructor = function () {
        setIsSaving(true);
        api.registrarInstructor({
            requestData: {
                id_usuario: data_user.id_usuario,
                nombre: dataStep1.nombres,
                apellido: dataStep1.apellidos,
                identificacion: dataStep1.identification,
                email: dataStep1.correo,
                descripcion: dataStep2.description,
                imagen: dataStep2.imageB64
            },
            token: token
        })
            .then(response => {
                if (response.success) {
                    handleSuccess(response.data);
                } else {
                    setIsSaving(false);
                    enqueueSnackbar('Error al registrar como tutor', {
                        variant: 'error'
                    });
                }
            })
            .catch(() => {
                setIsSaving(false);
                enqueueSnackbar('Error al registrar como tutor', {
                    variant: 'error'
                });
            });
    };

    const handleNext = function () {
        if (currentStepValid) {
            setNextPressed(false);
            if (isFinalStep) {
                registerInstructor();
            } else {
                setActiveStep(prevActiveStep => prevActiveStep + 1);
            }
        } else {
            setNextPressed(true);
        }
    };

    const handleBack = function () {
        setNextPressed(false);
        setActiveStep(prevActiveStep => prevActiveStep - 1);
    };

    /** @type {{ title: string, content: string | JSX.Element }[]} */
    const steps = [
        {
            title: 'Datos personales',
            content: <Step1 nextPressed={nextPressed} initialValues={dataStep1} handleData={data => {
                dataStep1 = data;
                currentStepValid = data.valid;
            }} />
        },
        {
            title: 'Información adicional',
            content: <Step2 nextPressed={nextPressed} initialValues={dataStep2} handleData={data => {
                dataStep2 = data;
                currentStepValid = data.valid;
            }} />
        },
        {
            title: 'Términos y condiciones',
            content: <Step3 nextPressed={nextPressed} initialValues={dataStep3} isSaving={isSaving} handleData={data => {
                dataStep3 = data;
                currentStepValid = data.valid;
            }} />
        }
    ];

    const isFinalStep = activeStep === steps.length - 1;

    return (<>
        <div className="Login_register rel" style={{ cursor: 'default', paddingTop: '4rem' }} >

            <div className="title_section s24 fontb c333" style={{ fontSize: '24px', marginLeft: '24px' }}>
                Registrar como instructor
            </div>

            <div style={{ display: 'flex', justifyContent: 'center' }}>

                <div style={{ width: '100%' }}>

                    <Stepper activeStep={activeStep} orientation={smallScreen ? 'vertical' : 'horizontal'}>
                        {steps.map((step) => {
                            const stepProps = {};
                            const labelProps = {};
                            return (
                                <Step key={step.title} {...stepProps}>
                                    <StepLabel {...labelProps}>{step.title}</StepLabel>
                                </Step>
                            );
                        })}
                    </Stepper>

                    <div>
                        {activeStep === steps.length ? (
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', cursor: 'default' }}>
                                <SweetAlertSuccessTick/>
                                <span style={{ padding: '0.5rem' }}>Registro exitoso!</span>
                                <span style={{ padding: '0.5rem' }}>Sus antecedentes han sido recepcionados con éxito.</span>
                                <Button onClick={gotoTutorias} style={{ margin: '2rem' }} className="button_accept">
                                    <span>Ir a &quot;{strings.MIS_TUTORIAS}&quot;</span>
                                </Button>
                            </div>
                        ) : (
                            <div>
                                {/* contenido pasos */}
                                {steps[activeStep].content}

                                {/* botones control stepper */}
                                <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                                    <div style={{ display: 'flex', justifyContent: 'right', width: '100%', maxWidth: activeStep === 2 ? '750px' : '550px', padding: '1rem', gap: '1rem' }}>
                                        <Button onClick={handleBack} className="button_cancel" style={{ visibility: activeStep > 0 ? "visible" : "hidden" }} disabled={activeStep === 0 || isSaving} >
                                            Atrás
                                        </Button>
                                        <Button variant="contained" color="primary" onClick={handleNext} className="button_accept" disabled={isSaving} >
                                            {isSaving && <ButtonCircularProgress />}
                                            {isFinalStep ? 'Enviar y finalizar' : 'Siguiente'}
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>

                </div>

            </div>
        </div>
    </>);
}
