import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import { useSnackbar } from "notistack";

import { BotonContext } from "../BotonContext";
import i18n from "../lib/i18n";
import utils from "../util/utils";

function DetailSubscriptionPage(props) {

  const { enqueueSnackbar } = useSnackbar();
  const courseID = props.match.params.subscriptionid;
  const section = props.match.params.section;

  useEffect(() => {
    document.title = "Suscripción";
  });

  const history = useHistory();
  //const data_user = JSON.parse(sessionStorage.getItem("user_data"));
  //const token = sessionStorage.getItem("token");

  const data_user = JSON.parse(sessionStorage.getItem("user_data"));
  const token = sessionStorage.getItem("token");

  var userID;
  var userSuscrito;
  if (data_user && data_user !== '' && data_user !== undefined && data_user !== null) {
    var userID = data_user.id_usuario;
    var userSuscrito = data_user.suscrito;
  }

  const [curso, setCurso] = useState([])

  useEffect(() => {
    fetch(process.env.REACT_APP_URL_API + '/suscripcion/' + courseID + '?id_pais=' + i18n.getCountry().id_pais, {
      method: 'get',
      headers: {
        'token': token,
        'Content-Type': 'application/json'
      },
      //body: JSON.stringify(data),
    })
      .then(response => response.json()
        .then(data => {
          if (data.success === true) {
            setCurso(data.data)
            //setRuta(data.data.ruta_aprendizaje);
          } else {
            history.push("/login");
          }
        })
      )
  }, []);


  /********** Agregado para funcion del boton agregar al carrito   ***********/

  //const [cart, setCart] = useState([]);               //Reemplazado por linea inferior
  const { cart, setCart } = useContext(BotonContext);     //PR: Mantendra actualizado el react Context
  //    o BotonContext desde boton addCart()

  let newCart = (cart !== null) ? cart.slice(0,) : [];
  let carting;

  function addCart(id) {

    let newCart = (cart !== null) ? cart.slice(0,) : [];

    if (cart !== null && cart.filter(course => course.id === curso.id).length > 0 && cart.filter(course => course.tipo === 3).length > 0) {
      // item ya existente (aumentar cantidad)
      newCart.map((course, i) => {
        if (course.id === curso.id) {
          newCart[i].quantity += 0; //1
          handleClickOpen();
        }
      });
    } else {
      // item nuevo
      curso.length = 1;
      for (let i = 0; i < curso.length; i++) {
        if (id === id) {
          newCart.push({
              id: curso.id,
              name: curso.titulo,
              price: curso.precio,
              img: curso.imagen,
              discount: curso.precio,
              quantity: 1,
              tipo: 3,              //añadido nuevo para clasificar producto por tipo
              ruta: 'detail-subscription', //añadido nuevo para redirigir por la imagen de la suscripcion
              param: 'Suscripciones',          //añadido nuevo para redirigir por el titulo de la suscripcion
          });
        }
      }
      enqueueSnackbar('Item agregado al carro de compras', {
        variant: 'success'
      });
    }
    setCart(newCart);
    localStorage.setItem('cart', JSON.stringify(newCart))
  }

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  useEffect(() => {

    const storage = localStorage.getItem('cart')

    setCart((storage !== null) ? JSON.parse(storage) : [])


  }, [])

  //Boton de carrito que suma cantidad total - Form abajo
  const [pathname, setPathname] = useState(window.location.pathname.slice(18,));

  const [cartQuantity, setCartQuantity] = useState();

  useEffect(() => {

    const inputVal = pathname.split('-').join(' ')

    let quantity = 0;
    //localStorage.removeItem('cart')
    carting = newCart;
    //let carting = [];
    //const carting = [{id:1,name:'Curso ALgebra',price:300,discount:'',quantity:2,img:'Imagen1'},
    //                 {id:2,name:'Curso Matematica',price:500,discount:100,quantity:2,img:'Imagen1'}]; //props.match.params.courseid;

    if (carting !== null && carting.length > 0) {

      carting.map(course => quantity += course.quantity)

    }

    setCartQuantity(quantity);

    if (window.location.pathname.includes('search')) {

      document.getElementsByTagName('input').item(0).setAttribute('value', inputVal);
      document.getElementsByTagName('input').item(1).setAttribute('value', inputVal);

    }

  })
  //FIN Boton de carrito que suma cantidad total - Form abajo

  /********** FIN Agregado para funcion del boton agregar al carrito   ***********/

  const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

  const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });


  /**Fin Comentarios del curso**/


  /*Ruta de aprendizaje*/


  function handleClickContacto(event) {
    event.preventDefault();
    history.push("/contact/Subscripción")
    //href={"#/my-courses"}
  }

  function handleClickCategorias(event) {
    event.preventDefault();
    history.push("/cates")
    //href={"#/my-courses"}
  }

  function handleClickCursoSectionInicio(event) {

    event.preventDefault();
    history.push("/home");
  }

  function handleClickSuscripciones(event) {
    event.preventDefault();
    history.push("/subscription/Inicio");
  }

  const botones = <div>
    {userSuscrito != 1 &&
      <>
        <Button variant="contained" style={{ float: 'right', width: '100%' }} color="secondary" className="button_accept aic" onClick={() => addCart(courseID)}>
          <b>Agregar suscripción</b>
          <AddShoppingCartIcon />
        </Button>
      </>
    }
    <Button onClick={handleClickContacto} fullWidth className="button_accept" style={{ marginTop: '8px' }}><b>Plan multi usuarios contacte aquí</b>
    </Button>
  </div>;

  const imagen = <img width="100%" src={utils.getImagenCurso(curso.imagen)} alt="imagen curso" />;

  return (

    <div className="App rel">
      <Breadcrumbs aria-label="breadcrumb" className="breadcrumbs_link_inneret">

        <Link color="inherit" onClick={handleClickCursoSectionInicio} className="breadcrumbs_link_inneret">
          Inicio
        </Link>
        {section == "Suscripciones" &&
          <Link color="inherit" onClick={handleClickSuscripciones} className="breadcrumbs_link_inneret">
            {section}
          </Link>
        }

        <Typography color="textPrimary" className="breadcrumbs_link">
          Suscripción
        </Typography>

      </Breadcrumbs>

      <Grid container className="primary-column" >

        <h1 className="title_section s24 fontb c333 hide-on-xs">Suscripción</h1>
        <Grid item xs={12} sm={8} md={8} lg={8} xl={9}  >
          <div className="section" >
            <div className="extras-block">

              <div className="only-on-xs">{ imagen }</div>
              <div className="only-on-xs">{ botones }</div>

              <div className="course-meta">
                <h2 className="title_section sub-title always-colored">{curso.titulo}</h2>
                <p className="content-description jtx" dangerouslySetInnerHTML={{ __html: curso.descripcion }} />
              </div>

            </div>
          </div>
        </Grid>


        <Grid item sm={4} md={4} lg={4} xl={3} className="secondary-column" >

          <div className="section" style={{ marginTop: '-25px' }}>
            <div className="extras-block">
              {imagen}
              <div className="section stacked-block">
                {botones}

                <Dialog
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="customized-dialog-title"
                >
                  <DialogTitle id="alert-dialog-title" onClose={handleClose} className="modal_title">{"Advertencia!"}</DialogTitle>
                  <DialogContent dividers className="modal_content">

                    <Typography gutterBottom>
                      Esta suscripción ya se encuentra en su carro de compras.
                    </Typography>

                  </DialogContent>

                  <DialogActions className="modal_footer">
                    <Button onClick={handleClose} variant="contained" className="button_cancel">
                      Cancelar
                    </Button>
                  </DialogActions>
                </Dialog>

              </div>
            </div>
          </div>

        </Grid>
      </Grid>

    </div>

  )

}

export default DetailSubscriptionPage;
