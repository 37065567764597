import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import Grid from '@material-ui/core/Grid';
import CardContent from '@material-ui/core/CardContent';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import CircularProgress from '@material-ui/core/CircularProgress';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Avatar from '@material-ui/core/Avatar';
import CardHeader from '@material-ui/core/CardHeader';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import { Hidden } from "@material-ui/core";
import WorkOutlineOutlinedIcon from '@material-ui/icons/WorkOutlineOutlined';
import axios from 'axios';
//EDITOR DE TEXTO
import ReactQuill from "react-quill";
import EditorToolbar, { modules, formats } from "../componentes/EditorToolbar";
import "react-quill/dist/quill.snow.css";
import { useSnackbar } from "notistack";

//REACT CONTEXT
import { BotonContext } from "../BotonContext";
import AuthContext from "../AuthContext";

import ImagePreview from "../componentes/ImagePreview";
import ModalVideoPlayer from "../componentes/ModalVideoPlayer";
import commonIO from '../util/common.io';
import i18n from "../lib/i18n";
import utils from "../util/utils";
import api from "../lib/api";
import CourseRating from "../componentes/CourseRating";
import { ListaAcordeonesUnidad } from "../componentes/AccordionUnidad";
import { useCart } from '../util/Cart';

const useStyles3 = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > * + *': {
      marginLeft: theme.spacing(2)
    }
  }
}));

/**
 * @param {Object} params
 * @param {api.Curso} params.curso
 * @returns
 */
const TitleDetallesCurso = function ({ curso }) {
  return <>
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <h2 className="title_section s24 fontb">Detalles del curso</h2>
      {!!curso.n_valoracion &&
        <div style={{ marginTop: '1rem' }}>
          <CourseRating value={curso.valoracion} count={curso.n_valoracion} />
        </div>
      }
    </div>
  </>;
};

export default function CoursePage(props) {

  const { enqueueSnackbar } = useSnackbar();
  const courseID = props.match.params.courseid;
  const section = props.match.params.section;

  const { addCourse } = useCart();

  useEffect(() => {
    document.title = "Curso - " + curso.titulo;
  });

  const history = useHistory();
  const data_user = JSON.parse(sessionStorage.getItem("user_data"));
  const token = sessionStorage.getItem("token");

  var userID;
  var userSuscrito;
  if (data_user && data_user !== '' && data_user !== undefined && data_user !== null) {
    var userID = data_user.id_usuario;
    var userSuscrito = data_user.suscrito;
  }

  const classes3 = useStyles3();

  var [disableLoadingDetalles, setDisableLoadingDetalles] = React.useState(false);
  var [disableLoading, setDisableLoading] = useState(false);

  const [course, setCourse] = useState(/** @type {{ [id_unidad: string]: api.Unidad }} */({}));

  useEffect(() => {
    api.getClases(token, courseID)
      .then(data => {
        setDisableLoadingDetalles(true);
        setCourse(data.unidades);
      });
  }, [courseID, token]);

  /** @type {JSX.Element} */
  let acordeonesUnidades;
  if (course && Object.keys(course).length) {
    const unidades = Object.values(course);
    acordeonesUnidades = <ListaAcordeonesUnidad
      publicAccordion={true}
      unidades={unidades}
      courseId={courseID}
      section={section}
      disabledCheckboxes={true}
    />;
  }

  const [curso, setCurso] = useState(/** @type {api.Curso} */({}));

  useEffect(() => {
    api.getCurso({
      courseId: courseID,
      id_pais: i18n.getCountry().id_pais,
      token
    })
      .then(data => {
        if (data.success) {
          setCurso(data.data);
        }
        setDisableLoading(true);
      });
  }, [courseID, token]);

  /********** Agregado para funcion del boton agregar al carrito   ***********/

  const { cart, setCart } = useContext(BotonContext);

  const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')));
  const dbUrl = (user !== null) ? `#/add-cart/${user.localId}.json` : '';

  let newCart = (cart !== null) ? cart.slice(0,) : [];
  let carting;

  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {

    const storage = localStorage.getItem('cart');
    /*
        if (user !== null) {

          fetch(dbUrl, { method: 'GET' }).then(resp => resp.json()).then(resp => {

            if (resp !== null) {

              setCart(resp);

              localStorage.removeItem('cart');

              localStorage.setItem('cart', JSON.stringify(resp));

            } else {

              fetch(dbUrl, { method: 'PUT', body: storage }).then(data => data.json()).then(data => {

                setCart((storage !== null) ? data : []);

              })
            }
          })

        } else {
    */
    setCart((storage !== null) ? JSON.parse(storage) : [])

    /*
        }
    */
  }, []);

  //Boton de carrito que suma cantidad total - Form abajo
  const [pathname, setPathname] = useState(window.location.pathname.slice(18,));

  const [cartQuantity, setCartQuantity] = useState();

  useEffect(() => {

    const inputVal = pathname.split('-').join(' ')

    let quantity = 0;
    carting = newCart;

    if (carting !== null && carting.length > 0) {

      carting.map(course => quantity += course.quantity)

    }

    setCartQuantity(quantity);

    if (window.location.pathname.includes('search')) {
      document.getElementsByTagName('input').item(0).setAttribute('value', inputVal);
      document.getElementsByTagName('input').item(1).setAttribute('value', inputVal);
    }

  });

  const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2)
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500]
    }
  });

  const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });


  /**Comentarios del curso**/

  const [textValue, setTextValue] = useState(null);

  const [coursetitle, setCoursetitle] = useState("");
  const [errorCoursetitle, setErrorCoursetitle] = useState("false");
  const [mensCoursetitle, setMensCoursetitle] = useState("");

  const [errorTextValue, setErrorTextValue] = useState(false);
  const [mensTextValue, setMensTextValue] = useState("");

  const [openSnackbarSuccess, setOpenSnackbarSuccess] = React.useState(false);
  const [openSnackbarWarning, setOpenSnackbarWarning] = React.useState(false);
  const [mensopenSnackbarWarning, setMensOpenSnackbarWarning] = useState("Error al intentar guardar.");

  const [botonstatus, setBotonstatus] = useState("button_accept_disable");
  const [questionList, setQuestionList] = useState([]);
  const [opcionpregunta, setOpcionpregunta] = useState("comentario");
  const [opcionrespuesta, setOpcionrespuesta] = useState("respuesta");
  const [opcion, setOpcion] = useState(opcionpregunta);
  const [opciontitulopregunta, setOpciontitulopregunta] = useState("Todos los comentarios del curso:");
  const [opciontitulo, setOpciontitulo] = useState(opciontitulopregunta);
  const [hiddenquestions, setHiddenQuestions] = useState(false);
  const [hiddenasnwer, setHiddenAnswer] = useState(true);
  const [actualizarpreguntas, setActualizarpreguntas] = useState([]);
  const { isAuth, isAuthTypeTutor } = useContext(AuthContext);
  const [mensajesindatos, setMensajesindatos] = useState("");

  const [openVideoModal, setOpenVideoModal] = useState(false);

  const handleCloseSnackbarSuccess = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbarSuccess(false);
  };

  const handleCloseSnackbarWarning = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbarWarning(false);
  };

  const handleChangeTextValue = (value) => {
    setTextValue(value);
  };

  const ResponderCancel = () => {
    setHiddenAnswer(true);
    setOpcion(opcionpregunta);
    setHiddenQuestions(false);
    setOpciontitulo(opciontitulopregunta);
  };


  const ValidarForm = () => {

    if (!coursetitle || coursetitle == '' || coursetitle == undefined) {

      setErrorCoursetitle("true");
      setMensCoursetitle("Se requiere ingresar el título");
      document.getElementById('question-title').focus();
      return;
    }

    /*  if(!textValue || textValue == '' || textValue == undefined || textValue === null || textValue ==='<p><br></p>'){
          setErrorTextValue(true);
          setMensTextValue("Se requiere ingresar la descripción");
          document.getElementById('quill_descripcion').focus();
          return;
      }
    */
    save();

  };


  const save = e => {

    let Comentario = {

      "id_usuario": parseInt(data_user.id_usuario),
      "id_curso": courseID,
      "texto": textValue,
      "titulo": document.getElementById('question-title').value
      //"fecha_creacion": "21-01-2022 10:42:15"
    }

    axios.post(process.env.REACT_APP_URL_API + '/curso/' + courseID + '/comentario', Comentario, {
      headers: {
        'Content-Type': 'application/json',
        'token': token
      }
    })
      .then(response => {
        if (response.data.success === true) {

          if (actualizarpreguntas === "0") {
            setActualizarpreguntas("1");
          } else {
            setActualizarpreguntas("0");
          }
          LimpiarFormQA();
          setOpenSnackbarSuccess(true).show();

        }
        if (response.data.success === false) {

          setOpenSnackbarWarning(true);
          setMensOpenSnackbarWarning("Error al intentar guardar.");
        }
        if (response.data.success === false && response.data.mensaje === "Ya has comentado este curso") { //colocar error 200 en el backend

          setOpenSnackbarWarning(true);
          setMensOpenSnackbarWarning(response.data.mensaje);
        }

      })
      .catch(e => {
        console.log(e);
      });


    //setQuestionList(Pregunta);
  }

  const LimpiarFormQA = () => {

    setCoursetitle("");
    setErrorCoursetitle(false);
    setMensCoursetitle("");

    setTextValue(null);
    setErrorTextValue(false);
    setMensTextValue("");

  };

  useEffect(() => {

    fetch(process.env.REACT_APP_URL_API + '/curso/' + courseID + '/comentario', {
      method: 'get',
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(response => response.json()
        .then(data => {
          if (data.success === true) {
            if (data.data.n_comentarios > 0) {
              setQuestionList(data.data.comentarios);
              setHiddenAnswer(true);
              setOpcion(opcionpregunta);
              setHiddenQuestions(false);
              setOpciontitulo(opciontitulopregunta);
            }
            if (data.data.n_comentarios === 0) {
              setHiddenQuestions(true);
              setOpcion(opcionpregunta);
              setOpciontitulo(opciontitulopregunta);
              setHiddenAnswer(true);
              setMensajesindatos(data.mensaje);
            }
          }
          if (data.success === false && data.data === null) {
            //setMens(true);
            sessionStorage.setItem("token", null);
            sessionStorage.setItem("user_data", null);
            //ValidarAuth();
          }
          if (data.success === false) {
            setHiddenQuestions(true);
            setOpcion(opcionpregunta);
            setOpciontitulo(opciontitulopregunta);
            setHiddenAnswer(true);
          }

        })
      )
  }, [actualizarpreguntas]);

  let listadoPreguntas = [];
  if (questionList && questionList instanceof Array && questionList.length) {

    Object.keys(questionList).forEach(key => {

      listadoPreguntas.push(

        <Grid container key={questionList[key].id}>



          <Grid item xs={12}>

            <div className="CardEfect" /*raised={true}*/ hidden={hiddenquestions}>
              <CardContent id={questionList[key].id} className="CardEfect" >
                <CardHeader
                  //className="Pointer"
                  //onClick={() => setIdpadrefunc(questionList[key].id)}
                  avatar={
                    <Avatar aria-label="recipe" className="MuiAvatar-colorForced">
                      {questionList[key].nombres.charAt(0)}
                    </Avatar>
                  }
                  title={commonIO.shortenText(questionList[key].titulo, 250)}
                  subheader={questionList[key].nombres + " " + questionList[key].apellidos + ". " + questionList[key].fecha_creacion}
                  style={{ height: '17%' }}
                />


                <p style={{ marginLeft: '80px', marginRight: '80px', marginBottom: '15px', marginTop: '0px' }} className="s15 subtitle_title jtx" dangerouslySetInnerHTML={{ __html: questionList[key].texto }}></p>


              </CardContent>


            </div>


          </Grid>
        </Grid>

      );

    })
  }


  var classQA = [];
  if (isAuth && (token != null || token != 'null' || token != "" || token != undefined
    || data_user != null || data_user != 'null' || data_user != "" || data_user != undefined)) {
    // for(let i = 0; i < course.videos.length; i++){
    classQA.push(
      <div key="QA">
        <div className="id s18 fontn cfff"></div>
        <div className="meta rel">

          <h1 className="s18 lbl title_section fontb c333">{opciontitulo}</h1>
          <div className="rel">

            {mensajesindatos != "" && mensajesindatos}

            <Grid container>
              <Snackbar open={openSnackbarSuccess} autoHideDuration={5000} onClose={handleCloseSnackbarSuccess} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
                <Alert severity="success" variant="filled" >
                  El {opcion} se ha añadido correctamente.
                </Alert>
              </Snackbar>

              <Snackbar open={openSnackbarWarning} autoHideDuration={5000} onClose={handleCloseSnackbarWarning} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
                <Alert severity="warning" variant="filled" >
                  {mensopenSnackbarWarning}
                </Alert>
              </Snackbar>

              <Grid item xs={12}>

                {listadoPreguntas}
                {/*listadoRespuestas*/}

                <div className="section section-b " >

                  <h1 className="s18 lbl title_section fontb c333">Agregar {opcion}:</h1>

                  <TextField className="InputText" id="question-title" label="Título o resumen" variant="outlined" fullWidth
                    InputProps={{
                      className: "InputText"
                    }}
                    InputLabelProps={{
                      className: "InputText",
                    }}

                    //error={errorCoursetitle}
                    errormessage={errorCoursetitle}
                    onBlur={(e) => {
                      if (coursetitle || coursetitle != '' || coursetitle != undefined) {
                        setErrorCoursetitle("false");
                        setMensCoursetitle("");
                      } else {
                        setErrorCoursetitle("true");
                        setMensCoursetitle("Este campo es obligatorio");

                      }
                    }}
                    onChange={(e) => {
                      setCoursetitle(e.target.value)
                      if (e.target.value != '') { setBotonstatus("button_accept"); } else { setBotonstatus("button_accept_disable"); }
                    }}
                    helperText={mensCoursetitle}
                    type="text"
                    value={coursetitle}
                  />
                </div>

                <br />
                <h3 className="title_section">Detalles (opcional)</h3>
                <br />
                <EditorToolbar shortId="Descripcion" />
                <ReactQuill
                  id="quill_descripcion"
                  key="1"
                  item="item1"
                  theme="snow"
                  value={textValue}
                  onChange={handleChangeTextValue}
                  placeholder={"Ingrese los detalles."}
                  modules={modules("Descripcion")}
                  formats={formats}

                  //error={errorTextValue}
                  errormessage={errorTextValue}
                  /*onBlur = {(e) => {
                      if(!textValue || textValue == '' || textValue == undefined || textValue === null || textValue ==='<p><br></p>'){
                          setErrorTextValue(true);
                          setMensTextValue("Este campo es obligatorio");
                      }else{

                          setErrorTextValue(false);
                          setMensTextValue("");
                      }
                  }}
                  */
                  helperText={mensTextValue}
                  type="text"
                />

                <div >
                  {opcion == opcionrespuesta &&
                    <Button type="button" variant="contained" style={{ float: 'right', marginLeft: '15px', marginTop: '15px' }} className="button_cancel" onClick={ResponderCancel}>
                      Volver
                    </Button>

                  }

                  <Button type="button" /*disabled={disabledAddClass}*/ onClick={ValidarForm} variant="contained" color="primary" style={{ float: 'right', marginLeft: '15px', marginTop: '15px' }} className={botonstatus} /*onClick={save}*/>
                    Publicar {opcion}
                  </Button>


                </div>

              </Grid>
            </Grid>

          </div>
          <h1 className="s13 dur fontn c777"></h1>
        </div>
      </div>
    );

  }

  /**Fin Comentarios del curso**/

  function addMisCursos(id) {

    const agregarCurso = {
      "id_usuario": userID,
      "id_curso": parseInt(id)
    };

    axios.post(process.env.REACT_APP_URL_API + '/alumno-curso-suscripcion', agregarCurso, {
      headers: {
        'Content-Type': 'application/json',
        'token': token
      }
    })
      .then(response => {
        if (response.data.success) {
          enqueueSnackbar(response.data.mensaje, {
            variant: 'success'
          });
          let intervalId = setInterval(() => {
            history.push("/my-courses");
            clearInterval(intervalId);
          }, 2000);
        } else {
          enqueueSnackbar(response.data.mensaje, {
            variant: 'error'
          });
        }
      })
      .catch(e => {
        console.log(e);
      });

  }

  function handleClickCategorias(event) {
    event.preventDefault();
    history.push("/cates");
  }

  function handleClickCursoSectionInicio(event) {

    event.preventDefault();
    history.push("/home");
  }

  function handleClickCursoSection(event) {
    event.preventDefault();
    history.goBack();
  }

  function handleClickDiplomados(event) {
    event.preventDefault();
    history.push("/certified");
  }

  const handleShowVideo = function () {
    setOpenVideoModal(true);
  };

  return (<>
    <div className="App rel">
      <Breadcrumbs aria-label="breadcrumb" className="breadcrumbs_link_inneret">

        {/* LA PAGINA CATEGORIA SE INACTIVO: 
        {section != "Inicio" && section != "Mis cursos" && section != "Programa" && section != "Plan multiusuario" &&
          <Link color="inherit" onClick={handleClickCategorias} className="breadcrumbs_link_inneret">
            Categorías
          </Link>
        } */}

        {section == "Inicio" &&
          <Link color="inherit" onClick={handleClickCursoSectionInicio} className="breadcrumbs_link_inneret">
            {section}
          </Link>
        }
        {section != "Inicio" && section != "Mis cursos" && section != "Programa" &&
          <Link color="inherit" onClick={handleClickCursoSection} className="breadcrumbs_link_inneret">
            {section}
          </Link>
        }
        {section == "Programa" &&
          <Link color="inherit" onClick={handleClickDiplomados} className="breadcrumbs_link_inneret">
            Programas
          </Link>
        }
        {section == "Programa" &&
          <Link color="inherit" onClick={handleClickCursoSection} className="breadcrumbs_link_inneret">
            {section}
          </Link>
        }
        <Typography color="textPrimary" className="breadcrumbs_link">
          {curso.titulo}
        </Typography>
      </Breadcrumbs>

      {disableLoading != true &&
        <div id="cargando" className={classes3.root}>
          <DialogContent className="CentrarLoadingSinModal">
            <CircularProgress className="CircularProgress" style={{ alignSelf: 'center' }} />
          </DialogContent>
        </div>
      }
      {/*
      // @ts-ignore */}
      <Hidden mdUp>
        <Grid container>

          <Grid item xs={12} sm={12} md={4} lg={4} xl={3} >

            <div className="sidebar rel">

              <ImagePreview image={utils.getImagenCurso(curso.imagen)} alt="imagen curso" className="stacked-block" hasVideo={!!curso.video} handleShowVideo={handleShowVideo} />

              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}  >

                {userSuscrito != 1 &&
                  <>
                    <Button variant="contained" style={{ width: '100%', marginTop: '0px' }} color="secondary" className="button_accept aic" onClick={() => addCourse(curso)}>
                      <b>Agregar curso</b>&nbsp; &nbsp;
                      <AddShoppingCartIcon />
                    </Button>
                  </>
                }
                {userSuscrito == 1 &&
                  <>
                    <Button variant="contained" style={{ width: '100%', marginTop: '0px', backgroundColor: "green" }} color="secondary" className="" onClick={() => addMisCursos(courseID)}>
                      <b>Agregar a mis cursos</b>&nbsp; &nbsp;
                      <WorkOutlineOutlinedIcon />
                    </Button>
                  </>
                }

              </Grid>

              <div className="flex stacked-block">
                <p className="contenido-label-detalle">
                  <span className="content-highlighted-label">Instructor: </span><span style={{ fontStyle: "italic" }}>{curso.nombre_instructor || 'N/A'}</span>
                </p>
              </div>

              <div className="course-meta">
                <h2 className="title_section sub-title always-colored">{curso.titulo}</h2>
                <p className="content-description jtx" dangerouslySetInnerHTML={{ __html: curso.descripcion || 'Curso sin descripción.' }} />
              </div>

              <div className="section rel" style={{ cursor: 'default' }} >

                <Dialog
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="customized-dialog-title"
                >
                  <DialogTitle id="alert-dialog-title" onClose={handleClose} className="modal_title">{"Advertencia!"}</DialogTitle>
                  <DialogContent dividers className="modal_content">

                    <Typography gutterBottom>
                      Este curso ya se encuentra en su carro de compras.
                    </Typography>

                  </DialogContent>

                  <DialogActions className="modal_footer">
                    <Button onClick={handleClose} variant="contained" className="button_cancel">
                      Cancelar
                    </Button>
                  </DialogActions>
                </Dialog>
                <TitleDetallesCurso curso={curso} />
                <div className="course-videos aic flex">
                  {disableLoadingDetalles != true &&
                    <CircularProgress className="CircularProgress" style={{ alignSelf: 'center' }} />
                  }
                  <div className="stack-acordeones">
                    {acordeonesUnidades}
                  </div>
                </div>

                {listadoPreguntas.length > 0 &&
                  <div>
                    <h1 className="s18 lbl title_section fontb c333">{opciontitulo}</h1>
                    {listadoPreguntas}
                  </div>
                }

              </div>

            </div>
          </Grid>

        </Grid>
      </Hidden>
      {/*
      // @ts-ignore */}
      <Hidden smDown>

        <Grid container spacing={3}>

          <Grid item xs={6} sm={6} md={8} lg={8} xl={9} >

            <h1 className="title_section s24 fontb c333">{curso.titulo}</h1>

            <div className="section" >
              <div className="extras-block">
                <div className="tutor rel aic flex">
                  <p className="contenido-label-detalle">
                    <span className="content-highlighted-label">Instructor: </span><span style={{ fontStyle: "italic" }}>{curso.nombre_instructor || 'N/A'}</span>
                  </p>
                </div>

                <div className="course-meta">
                  <p className="content-description jtx" dangerouslySetInnerHTML={{ __html: curso.descripcion || 'Curso sin descripción.' }} />

                  {listadoPreguntas.length > 0 &&
                    <>
                      <hr size="1" style={{ marginTop: '4rem' }} />
                      <div>
                        <h1 className="s18 lbl title_section fontb c333">{opciontitulo}</h1>
                        {listadoPreguntas}
                      </div>
                    </>
                  }

                </div>
              </div>
            </div>

          </Grid>

          <Grid item xs={6} sm={6} md={4} lg={4} xl={3} className="secondary-column" style={{ cursor: 'default' }} >

            <div className="section" style={{ marginTop: '25px' }}>
              <div className="extras-block">
                <ImagePreview image={utils.getImagenCurso(curso.imagen)} alt="imagen curso" hasVideo={!!curso.video} handleShowVideo={handleShowVideo} />
                <div className="section rel" style={{ marginTop: '0.8rem' }} >
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}  >

                    {userSuscrito != 1 &&
                      <>
                        <Button variant="contained" style={{ width: '100%', marginTop: '0px' }} color="secondary" className="button_accept aic" onClick={() => addCourse(curso)}>
                          <b>Agregar curso</b>&nbsp; &nbsp;
                          <AddShoppingCartIcon />
                        </Button>
                      </>
                    }
                    {userSuscrito == 1 &&
                      <>
                        <Button variant="contained" style={{ width: '100%', marginTop: '0px', backgroundColor: "green" }} color="secondary" className="" onClick={() => addMisCursos(courseID)}>
                          <b>Agregar a mis cursos</b>&nbsp; &nbsp;
                          <WorkOutlineOutlinedIcon />
                        </Button>
                      </>
                    }
                  </Grid>

                  <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="customized-dialog-title"
                  >
                    <DialogTitle id="alert-dialog-title" onClose={handleClose} className="modal_title">{"Advertencia!"}</DialogTitle>
                    <DialogContent dividers className="modal_content">

                      <Typography gutterBottom>
                        Este curso ya se encuentra en su carro de compras.
                      </Typography>

                    </DialogContent>

                    <DialogActions className="modal_footer">
                      <Button onClick={handleClose} variant="contained" className="button_cancel">
                        Cancelar
                      </Button>
                    </DialogActions>
                  </Dialog>
                  <TitleDetallesCurso curso={curso} />
                  <div className="course-videos aic flex" style={{ marginTop: '0.5rem' }}>
                    {disableLoadingDetalles != true &&
                      <CircularProgress className="CircularProgress" style={{ alignSelf: 'center' }} />
                    }
                    <div className="stack-acordeones">
                      {acordeonesUnidades}
                    </div>
                  </div>

                </div>
              </div>
            </div>

          </Grid>
        </Grid>
      </Hidden>
    </div>
    <ModalVideoPlayer video={curso?.video} open={openVideoModal} curso={curso} handleClose={() => { setOpenVideoModal(false); }} />
  </>
  );

}
