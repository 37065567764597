import React, { createContext, useState, useContext, useEffect } from "react";
//REACT CONTEXT
import { BotonContext } from "./BotonContext";
import { TIPO_USUARIO } from "./lib/constants";

/**
 * NOTA: no se están exponiendo los setters en el contexto.
 * @typedef TAuthContext
 * @property {boolean} isAuth
 * @property {boolean} isAuthTypeTutor
 */
const AuthContext = /** @type {React.Context<TAuthContext>} */( createContext({}) );

/**
 * @param {Object} obj
 * @param {string | JSX.Element | JSX.Element[]} obj.children
 * @returns {JSX.Element}
 */
const AuthProvider = ({ children }) => {

    //Autorización:
    const [isAuth, setIsAuth] = useState(false);
    const [isAuthTypeTutor, setIsAuthTypeTutor] = useState(false);
    const { data_user, token } = useContext(BotonContext);

    useEffect(() => {
        if (token && data_user) {
            setIsAuth(true);
            switch (data_user.tipo_usuario) {
                case TIPO_USUARIO.USER:
                    setIsAuthTypeTutor(false);
                    break;
                case TIPO_USUARIO.AUTHOR:
                    setIsAuthTypeTutor(true);
                    break;
            }
        } else {
            setIsAuthTypeTutor(false);
        }
    }, [data_user, token]);

    return (
        <AuthContext.Provider value={{ isAuth, isAuthTypeTutor }}>
            {children}
        </AuthContext.Provider>
    );

};

export { AuthProvider };
export default AuthContext;
