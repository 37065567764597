import React, { useState } from "react";
import Rating from '@material-ui/lab/Rating';
import Box from '@material-ui/core/Box';
import { withStyles } from "@material-ui/core";

const StyledRating = withStyles({
    iconEmpty: {
        color: '#bdbdbd'
    }
})(Rating);

const labels = {
    "0.5": 'Muy Malo',
    1: 'Malo',
    "1.5": 'Muy Pobre',
    2: 'Pobre',
    "2.5": 'Poco Regular',
    3: 'Regular',
    "3.5": 'Bueno',
    4: 'Bueno',
    "4.5": 'Excelente',
    5: 'Excelente'
};

/**
 * @param {Object} params
 * @param {number} params.value
 * @param {number} params.count
 * @returns {JSX.Element}
 */
export default function CourseRating({ value, count }) {
    const numericScore = value.toFixed(1);
    const stars = (value % 1) >= 0.4 ? Math.floor(value) + 0.5 : Math.floor(value);
    return (<>
        <div style={{ whiteSpace: 'nowrap', display: 'flex', alignItems: 'center', gap: '0.2rem', cursor: 'default' }}>
            <span style={{ color: 'var(--color-colored-only-on-light)', filter: 'brightness(85%)', marginRight: '0.1rem' }}>{numericScore}</span>
            <StyledRating
                size='small'
                value={stars}
                precision={0.5}
                readOnly={true}
            />
            <span style={{ color: 'var(--primary-with-transparency)', fontSize: '0.75rem' }}>({count})</span>
        </div>
    </>);
}

/**
 * @param {Object} params
 * @param {number} params.value
 * @param {(newValue: number) => void} params.handleRatingChange
 * @param {boolean} [params.readOnly] - default = false
 * @returns {JSX.Element}
 */
export function CourseRatingEditor({ value, handleRatingChange, readOnly = false }) {
    const [hoverRating, setHoverRating] = useState(-1);
    return (<>
        <div style={{ display: 'flex', alignItems: 'center', cursor: 'default' }}>
            <Rating
                size="large"
                name="hover-feedback"
                value={value}
                precision={1}
                onChange={(event, newValue) => {
                    handleRatingChange(newValue);
                }}
                onChangeActive={(event, newHover) => {
                    setHoverRating(newHover);
                }}
                readOnly={readOnly}
            />
            {value !== null && value > 0 && <Box ml={1} style={{ minWidth: '5em' }}>{labels[hoverRating !== -1 ? hoverRating : value]}</Box>}
        </div>
    </>);
}
