import React, { useContext } from "react";
import { Route, Redirect } from "react-router";
import AuthContext from "../AuthContext";

function ProtectedRouteTutor({ component: Component, ...rest }) {

    const { isAuth, isAuthTypeTutor } = useContext(AuthContext);

    return (
        <Route
            {...rest}
            render={(props) => {
                if (isAuth && isAuthTypeTutor) {
                    return <Component {...props} />;
                } else {
                    return (
                        <Redirect to={{ pathname: "/login", state: { from: props.location } }} />
                    );
                }
            }}
        />
    );

}

export default ProtectedRouteTutor;
