import React, { useRef, useState } from "react";
import { useDrag, useDrop, DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useIsFirstRender } from "usehooks-ts";
import _ from "lodash";
import { IconoClase } from "../componentes/ListadoUnidades";
import { theme as tema } from "../util/theme";

const ItemTypes = {
    CLASE: 'clase',
    UNIDAD: 'unidad'
};

/**
 * @param {Array} arr
 * @param {number} fromIndex
 * @param {number} toIndex
 */
function arrayMove(arr, fromIndex, toIndex) {
    var element = arr[fromIndex];
    arr.splice(fromIndex, 1);
    arr.splice(toIndex, 0, element);
}

/**
 * @typedef IndexClase
 * @property {number} index
 * @property {api.Clase} clase
 */

/**
 * @param {api.ListaClases} estructura
 * @param {IndexClase} source
 * @param {IndexClase} target
 */
const moveClase = function (estructura, source, target) {
    if (target.clase.id_unidad === source.clase.id_unidad) {
        const unidad = estructura.unidades[source.clase.id_unidad];
        arrayMove(unidad.clases, source.index, target.index);
    } else {
        const unidad1 = estructura.unidades[source.clase.id_unidad];
        const unidad2 = estructura.unidades[target.clase.id_unidad];
        const clase1 = unidad1.clases.splice(source.index, 1)[0]; // extraer de la unidad origen
        unidad2.clases.splice(target.index, 0, clase1); // insertar en la unidad destino
        clase1.id_unidad = target.clase.id_unidad;
    }
};

/**
 * @param {api.ListaClases} estructura
 * @param {IndexClase} indexClase
 * @param {api.Unidad} unidad
 */
const moveClaseToEmptyUnit = function (estructura, indexClase, unidad) {
    const unidadOrigen = estructura.unidades[indexClase.clase.id_unidad];
    const unidadDestino = unidad;
    unidadOrigen.clases.splice(indexClase.index, 1); // remover de la unidad origen
    unidadDestino.clases.push(indexClase.clase); // insertar en la unidad destino
    indexClase.clase.id_unidad = unidad.id_unidad;
};

/**
 * @param {Object} params
 * @param {api.Clase} params.clase
 * @param {number} params.index
 * @param {(dragIndex: IndexClase, hoverIndex: IndexClase) => void} params.moverClase
 * @returns {JSX.Element}
 */
const Clase = function ({ clase, index, moverClase }) {

    const ref = useRef(null);
    const [{ handlerId }, drop] = useDrop({
        accept: ItemTypes.CLASE,
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId()
            };
        },
        /**
         * @param {IndexClase} item
         */
        hover(item, monitor) {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;
            // Don't replace items with themselves
            if (dragIndex === hoverIndex && clase.id_unidad === item.clase.id_unidad) {
                return;
            }
            // Determine rectangle on screen
            const hoverBoundingRect = ref.current?.getBoundingClientRect();
            // Get vertical middle
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            // Determine mouse position
            const clientOffset = monitor.getClientOffset();
            // Get pixels to the top
            const hoverClientY = clientOffset.y - hoverBoundingRect.top;
            // Only perform the move when the mouse has crossed half of the items height
            // When dragging downwards, only move when the cursor is below 50%
            // When dragging upwards, only move when the cursor is above 50%
            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }
            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }
            // Time to actually perform the action
            moverClase({ index: dragIndex, clase: item.clase }, { index: hoverIndex, clase: clase });
            item.index = hoverIndex; // `clase` se mantiene igual, solo cambia el index
        }
    });

    const [{ isDragging }, drag] = useDrag({
        type: ItemTypes.CLASE,
        item: () => {
            return { index, clase };
        },
        isDragging(monitor) {
            return monitor.getItem().clase.id === clase.id;
        },
        collect: (monitor) => {
            return {
                isDragging: !!monitor.isDragging()
            };
        }
    });

    /** @type {React.CSSProperties} */
    const estiloTextFadeout = {
        backgroundColor: 'var(--second-background)',
        position: 'absolute',
        top: 0,
        right: 0,
        width: '35px',
        height: '100%',
        background: 'linear-gradient(90deg, rgba(255,255,255,0) 0%, var(--secondary-color) 82%, var(--secondary-color) 100%)'
    };

    /** @type {React.CSSProperties} */
    const estiloClase = {
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: tema.isDark ? '#ffffff8f' : null,
        color: tema.isDark ? '#ffffffd9' : null,
        margin: '0.5rem',
        padding: '0.2rem 0.5rem',
        opacity: isDragging ? 0 : 1,
        fontSize: '14px',
        cursor: 'move',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        gap: '6px'
    };

    drag(drop(ref));
    return (
        <div
            ref={ref}
            style={estiloClase}
            data-handler-id={handlerId}
        >
            <IconoClase tipo={clase.param_tipo} />
            <span>{clase.titulo}</span>
            <div style={estiloTextFadeout}></div>
        </div>
    );
};

/**
 * @param {Object} params
 * @param {api.Unidad} params.unidad
 * @param {(dragIndex: IndexClase, hoverIndex: IndexClase) => void} params.moverClase
 * @param {(dragIndex: IndexClase) => void} params.moverClaseToUnidad
 * @returns {JSX.Element}
 */
const Unidad = function ({ unidad, moverClase, moverClaseToUnidad }) {
    const ref = useRef(null);

    const [{ handlerId }, drop] = useDrop({
        accept: ItemTypes.CLASE,
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId()
            };
        },
        /**
         * @param {IndexClase} item
         */
        hover(item/*, monitor*/) {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            if (unidad.clases.length > 0) return; // solo aceptar clases si la unidad está vacía
            moverClaseToUnidad({ index: dragIndex, clase: item.clase });
            item.index = 0;
        }
    });
    drop(ref);
    return <>
        <div
            ref={ref}
            style={{
                borderWidth: '1px',
                borderStyle: 'solid',
                borderColor: tema.isDark ? '#ffffff8f' : null,
                margin: '0.5rem',
                padding: '0.5rem',
                fontSize: 15,
                cursor: 'default'
            }}
            data-handler-id={handlerId}
        >
            <div style={{ color: 'var(--color-always-colored)', paddingLeft: '0.3rem' }}>{unidad.nombre_unidad}</div>
            <div>{
                unidad.clases.map((clase, indexClase) => {
                    return <Clase
                        key={clase.id}
                        clase={clase}
                        index={indexClase}
                        moverClase={moverClase}
                    />;
                })
            }
            </div>
        </div>
    </>;
};

/**
 * @param {Object} params
 * @param {api.ListaClases} params.estructuraCurso - no se modifica directamente, se clona antes de reordenar.
 * @param {(changedStructure: api.ListaClases) => void} params.onChange - recibe la copia modificada de la estructura
 * @returns {JSX.Element}
 */
export default function PanelOrdenarClases({ estructuraCurso, onChange }) {
    const isFirst = useIsFirstRender();
    /** @type {api.ListaClases} */
    let clonEstructura;
    if (isFirst) {
        clonEstructura = _.cloneDeep(estructuraCurso);
    }
    const [estructura, setEstructura] = useState(clonEstructura);
    const unidades = estructura.unidades;
    const valorUnidades = Object.values(unidades);
    return (
        <DndProvider backend={HTML5Backend}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <>{
                    valorUnidades.map((unidad) => {
                        return <Unidad
                            key={unidad.id_unidad}
                            moverClase={(dragIndex, hoverIndex) => {
                                moveClase(estructura, dragIndex, hoverIndex);
                                setEstructura({ ...estructura });
                                onChange(estructura);
                            }}
                            moverClaseToUnidad={(dragIndex) => {
                                moveClaseToEmptyUnit(estructura, dragIndex, unidad);
                                setEstructura({ ...estructura });
                                onChange(estructura);
                            }}
                            unidad={unidad}
                        />;
                    })
                }</>
            </div>
        </DndProvider>
    );
}
