import { useSession } from "../util/session";
import { BASE_API_URL } from "./api.common";

export const useApiClase = function () {

    const session = useSession();

    /**
     * @param {Object} obj
     * @param {string} obj.token
     * @param {number} obj.userId
     * @param {number} obj.classId
     * @param {number} obj.estado
     * @returns {Promise<api.SuccessResponse<*>>}
     */
    const updateEstadoClase = function ({ token, userId, classId, estado }) {
        const requestData = {
            "id_usuario": userId,
            "id_clase": classId,
            "estado": estado,
            "fecha_termino": "01-12-2021 12:50:12" // FIX: remover (el back determina la fecha, no toma esta).
        };
        return new Promise((resolve, reject) => {
            fetch(BASE_API_URL + '/alumno-clase', {
                method: 'PUT',
                headers: {
                    'token': token,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(requestData)
            })
                .then(response => {
                    if (response.status === 401 || response.status === 403) {
                        session.handleNotAuthorized();
                        reject();
                        return;
                    }
                    return response.json();
                })
                .then(data => {
                    resolve(data);
                })
                .catch(error => {
                    console.error(error);
                    reject();
                });
        });
    };

    return {
        updateEstadoClase
    };

};
