import React, { useEffect, useState } from "react";
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Pagination from '@material-ui/lab/Pagination';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useSnackbar } from "notistack";

import TarjetaEcampus from "../componentes/TarjetaEcampus";
import ModalVideoPlayer from "../componentes/ModalVideoPlayer";

import * as CustomErrors from '../util/custom.errors';
import commonIO from '../util/common.io';
import { theme as tema } from "../util/theme";
import api, { isStructureUpdated } from "../lib/api";
import i18n, { strings } from "../lib/i18n";
import utils from "../util/utils";
import { TagStyles } from "../lib/constants";

/**
 * @param {Object} params
 * @param {string} params.initialValue
 * @param {(input: string) => void} params.handleEnter
 */
const SearchBar = ({ initialValue, handleEnter }) => {

    const [input, setInput] = useState(initialValue);

    const onChange = (e) => {
        setInput(e.target.value);
    };

    /**
     * @param {React.KeyboardEvent<HTMLInputElement>} e
     */
    const onKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleEnter(input);
        }
    };

    return (
        <input
            style={{ width: "100%", padding: "0.5rem" }}
            value={input}
            placeholder={"Buscar cursos…"}
            className={"round ml-0"}
            onChange={onChange}
            onKeyDown={onKeyDown}
        />
    );
};

/** @type {string} */
let lastRequest;

export default function SearchCourses(props) {

    const { enqueueSnackbar } = useSnackbar();
    const theme = useTheme();
    const smallScreen = useMediaQuery(theme.breakpoints.down('xs'));

    const initialQuery = props.match.params["query"];
    const [activeQuery, setActiveQuery] = useState(initialQuery);
    const [cursos, setCursos] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [totalPaginas, setTotalPaginas] = useState(0);
    const [paginaActual, setPaginaActual] = useState(0);
    const [cursoVideoModal, setCursoVideoModal] = useState(/** @type {api.Curso} */(null));

    /**
     * @param {api.Curso} curso
     */
    const handleShowVideo = function (curso) {
        if (!curso?.video) return;
        setCursoVideoModal(curso);
    };

    useEffect(() => {
        document.title = "Búsqueda de cursos - e-Napsis";
        getCursosFiltrados(initialQuery, 1);
    }, []);

    const [checked/*, setChecked*/] = useState(tema.current === "dark" ? true : false); // para tema oscuro

    useEffect(() => {
        document
            .getElementsByTagName("HTML")[0]
            .setAttribute("data-theme", tema.current);
    }, [checked]);

    /**
     * @param {string} queryString
     * @param {number} nPag
     */
    const getCursosFiltrados = function (queryString, nPag) {
        setIsLoading(true);
        const request = queryString + nPag;
        lastRequest = request;
        api.getCursosFiltrados({
            query: queryString,
            nPag: nPag,
            id_pais: i18n.getCountry().id_pais
        })
            .then(data => {
                if (request !== lastRequest) {
                    throw new CustomErrors.ObsoleteRequest();
                }
                if (data.success === true) {
                    setCursos(data.data.cursos);
                    setPaginaActual(data.data.pagina_actual);
                    setTotalPaginas(data.data.numero_de_paginas);
                } else {
                    setCursos([]);
                    setPaginaActual(0);
                    setTotalPaginas(0);
                    enqueueSnackbar(data.mensaje, {
                        variant: 'warning'
                    });
                }
                setIsLoading(false);
            })
            .catch(error => {
                if (error instanceof CustomErrors.ObsoleteRequest) return;
                enqueueSnackbar('No se encontraron datos', {
                    variant: 'error'
                });
                setIsLoading(false);
                setPaginaActual(0);
                setTotalPaginas(0);
            });
    };

    const loadingAnimation =
        <div key='loading' style={{ minHeight: '25rem', paddingTop: '7rem', width: '100%', textAlign: 'center' }}>
            <CircularProgress className="CircularProgress" />
        </div>;

    const mensajeNoHayCursos =
        <div key='mensaje_no_cursos' className='title_section s24' style={{ minHeight: '25rem', paddingTop: '7rem', width: '100%', textAlign: 'center' }}>
            No se encontraron cursos
        </div>;

    const courseList = [];
    if (cursos && cursos.length) {
        for (let i = 0; i < cursos.length; i++) {
            courseList.push(
                <Grid container item xs={12} sm={12} md={6} lg={4} xl={4} direction="column" key={cursos[i].id} className='coursehome tarjeta-grid-item' >
                    <Grid container alignContent="center" alignItems="center" className='tarjeta-ecampus-wrapper'>
                        <TarjetaEcampus
                            image={utils.getImagenCurso(cursos[i])}
                            text1={commonIO.shortenText(cursos[i].titulo, smallScreen ? 60 : 72)}
                            text2={cursos[i].nombre_instructor}
                            href={"/course/" + cursos[i].id + "/" + "Inicio"}
                            price={cursos[i].precio}
                            showTag={isStructureUpdated(cursos[i])}
                            tagOptions={TagStyles.TAG_ACTUALIZADO}
                            curso={cursos[i]}
                            hasVideo={!!cursos[i].video}
                            handleVideoPreview={() => {
                                handleShowVideo(cursos[i]);
                            }}
                            showBuyButtons={true}
                        />
                    </Grid>
                </Grid>
            );
        }
    } else {
        courseList.push(mensajeNoHayCursos);
    }

    /**
     * @param {string} input
     */
    const handleSearch = (input) => {
        if (input) {
            setActiveQuery(input);
            getCursosFiltrados(input, 1);
        }
    };

    const handlePageChange = (event, newPage) => {
        setPaginaActual(newPage);
        getCursosFiltrados(activeQuery, newPage);
    };

    return (
        <Container maxWidth='lg'>

            <div style={{ marginTop: '90px' }}>

                <div>
                    <Grid container item >
                        <SearchBar initialValue={initialQuery} handleEnter={handleSearch} />
                    </Grid>
                </div>

                <div className="home-page">
                    <div className="extra-block">
                        <h2 className="title_section s24 fontb">Cursos</h2>
                        <div className="courses" style={{ marginBottom: '4rem', marginTop: '2.5rem' }}>

                            <Grid container item direction="row" style={{ minHeight: '6rem' }}>
                                {isLoading && loadingAnimation || courseList}
                            </Grid>

                            <Grid container item direction="row" >
                                <Grid container item direction="column" alignItems="center" justifyContent="center" key="paginacion">
                                    <Pagination variant="outlined" color="primary" count={totalPaginas} page={paginaActual} onChange={handlePageChange} />
                                </Grid>
                            </Grid>

                        </div>
                    </div>
                </div>

            </div>

            <ModalVideoPlayer video={cursoVideoModal?.video} open={!!cursoVideoModal} curso={cursoVideoModal} handleClose={() => { setCursoVideoModal(null); }} />

        </Container>
    );
}
