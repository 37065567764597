import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';
import Link from '@material-ui/core/Link';
import { Hidden } from "@material-ui/core";
import { useHistory } from "react-router";
import axios from 'axios';
import { useSnackbar } from "notistack";

import { BotonContext } from "../BotonContext";
import commonIO from "../util/common.io";
import api from "../lib/api";
import i18n from "../lib/i18n";
import CircularProgress from "../componentes/ButtonCircularProgress";
import { useCommonStyles } from "../util/common.styles";
import { useSession } from "../util/session";

/** @typedef {'recover'} LoginAction */

/**
 * @param {import("react-router").RouteComponentProps} props
 */
function Login(props) {

    const { action } = /** @type {{ action?: LoginAction }} */(useParams());
    const { enqueueSnackbar } = useSnackbar();

    const recover = action === 'recover';
    const queryParams = new URLSearchParams(props.location.search);
    const redir = queryParams.get('redir');

    useEffect(() => {
        document.title = "Login";
    });

    const history = useHistory();
    const { setCountryId } = useContext(BotonContext);  // PR: Mantendrá actualizado el react Context
    const session = useSession();

    const [email, setEmail] = useState("");
    const [errorEmail, setErrorEmail] = useState(false);
    const [mensEmail, setMensEmail] = useState("");
    const [errorEmailRecovery, setErrorEmailRecovery] = useState(false);
    const [mensEmailRecovery, setMensEmailRecovery] = useState("");

    const [password, setPassword] = useState("");
    const [errorPassword, setErrorPassword] = useState(false);
    const [mensPassword, setMensPassword] = useState("");

    const [waitingPasswordResetResponse, setWaitingPasswordResetResponse] = useState(false);
    const [loggingIn, setLoggingIn] = useState(false);

    function handleRegister() {
        history.push("/register");
    }

    /**
     * @returns {boolean}
     */
    const validateInputs = function () {
        if (!email) {
            setErrorEmail(true);
            setMensEmail("Se requiere ingresar Correo");
            return false;
        }
        if (!commonIO.isValidEmail(email)) {
            setErrorEmail(true);
            setMensEmail("Por favor introduzca un correo válido");
            return false;
        } else {
            setErrorEmail(false);
            setMensEmail("");
        }
        if (!password) {
            setErrorPassword(true);
            setMensPassword("Se requiere ingresar Contraseña");
            return false;
        }
        return true;
    };

    /**
     * @param {Event} e
     */
    const handleLoginAction = (e) => {
        e.preventDefault();
        if (!validateInputs()) return;
        const json_login = {
            "email": email,
            "password": password
        };
        setLoggingIn(true);
        axios.post(process.env.REACT_APP_URL_API + '/login', json_login)
            .then((response) => {
                if (response.data.success) {
                    const data_usuario = response.data.data.usuario;
                    session.setData({ token: response.data.data.token, data_user: data_usuario });
                    try {
                        setCountryId(parseInt(data_usuario.id_pais));
                    } catch (error) {
                        setCountryId(i18n.DEFAULT_COUNTRY.id_pais);
                        console.error(error);
                    }
                    if (redir) {
                        history.push(redir);
                    } else {
                        history.push("/home");
                    }
                } else {
                    enqueueSnackbar(response.data.mensaje || "Error al iniciar sesión", { variant: 'error' });
                }
            })
            .catch(function () {
                enqueueSnackbar("Los datos de sesión son incorrectos. Inténtelo nuevamente.", { variant: 'error' });
            }).finally(() => {
                setLoggingIn(false);
            });
    };

    const useStyles = makeStyles((theme) => ({
        root: {
            minWidth: 280,
            maxWidth: 398
        },
        formControl: {
            margin: theme.spacing(1)
        },
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        }
    }));

    const classes = useStyles();
    const commonClases = useCommonStyles();

    const recovery = {

        handle() {
            if (waitingPasswordResetResponse) return;

            if (email) {
                if (!commonIO.isValidEmail(email)) {
                    setErrorEmailRecovery(true);
                    setMensEmailRecovery("Por favor introduzca un correo válido");
                    return;
                }
            } else {
                setErrorEmailRecovery(true);
                setMensEmailRecovery("Debe especificar el correo");
                return;
            }

            setErrorEmailRecovery(false);
            setMensEmailRecovery("");

            setWaitingPasswordResetResponse(true);
            api.recoverPassword(email)
                .then((response) => {
                    if (response.success) {
                        recovery.showSuccess(response.mensaje);
                    } else {
                        enqueueSnackbar(response.mensaje || "No se pudo recuperar la contraseña", { variant: 'warning' });
                    }
                })
                .catch(() => {
                    enqueueSnackbar("Error al recuperar contraseña", { variant: 'error' });
                })
                .finally(() => {
                    setWaitingPasswordResetResponse(false);
                });
        },

        goBack() {
            history.goBack();
            setErrorEmailRecovery(false);
            setMensEmailRecovery("");
        },

        /**
         * @param {string} message
         */
        showSuccess(message) {
            enqueueSnackbar(message || "Acción ejecutada con éxito", { variant: 'success' });
        }

    };

    const recoverForm = (
        <FormControl variant="outlined" className={classes.formControl}>
            <h3 style={{ marginTop: '-0.7rem' }}>RECUPERAR CLAVE</h3>
            <div style={{ paddingTop: '1rem', paddingBottom: '1.5rem' }}>Indique su usuario y le llegara un email con una nueva clave.</div>
            <TextField id="email" name="email" label="Email"
                InputProps={{ className: "InputText" }}
                InputLabelProps={{ className: "InputText" }}
                variant="outlined" fullWidth
                error={errorEmailRecovery}
                onChange={(e) => setEmail(e.target.value)}
                helperText={mensEmailRecovery}
                type="mail"
                value={email}
                required
            />
            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '50px' }}>
                <Button type="button" onClick={recovery.goBack} variant="contained" color="primary" className="button_cancel" style={{ minWidth: '125px' }}>
                    Regresar
                </Button>
                <Button type="button" onKeyPress={recovery.handle} onClick={recovery.handle} variant="contained" color="primary" className="form__custom-button" style={{ minWidth: '125px' }}>
                    Enviar
                </Button>
            </div>
        </FormControl>
    );

    const loginForm = (
        <FormControl variant="outlined" className={classes.formControl}>
            <TextField id="email" name="email" label="Usuario"
                InputProps={{ className: "InputText" }}
                InputLabelProps={{ className: "InputText" }}
                maxRows={12}
                minRows={7} variant="outlined" fullWidth
                error={errorEmail}
                onBlur={(e) => {
                    if (email) {
                        if (commonIO.isValidEmail(e.target.value)) {
                            setErrorEmail(false);
                            setMensEmail("");
                        } else {
                            setErrorEmail(true);
                            setMensEmail("Por favor introduzca un correo válido");
                        }
                        setEmail(e.target.value);
                    } else {
                        setErrorEmail(true);
                        setMensEmail("Este campo es obligatorio");
                    }
                }}
                //onBlur={(e) => handleBlurEmail(e.target.value)}
                onChange={(e) => setEmail(e.target.value)}
                helperText={mensEmail}
                type="mail"
                value={email}
                required
                disabled={loggingIn}
            />
            <div style={{ height: '30px' }}></div>
            <TextField id="password" name="password" label="Contraseña"
                InputProps={{ className: "InputText" }}
                InputLabelProps={{ className: "InputText" }}
                maxRows={12}
                minRows={7} variant="outlined" fullWidth
                error={errorPassword}
                onBlur={() => {
                    if (password) {
                        setErrorPassword(false);
                        setMensPassword("");
                    } else {
                        setErrorPassword(true);
                        setMensPassword("Este campo es obligatorio");
                    }
                }}
                onChange={(e) => setPassword(e.target.value)}
                helperText={mensPassword}
                type="password"
                value={password}
                required
                disabled={loggingIn}
            />
            <Link href="#/login/recover" align="right">¿Olvidaste la contraseña?</Link>
            <div className="section section-b content" style={{ marginTop: '30px' }}>
                <Button type="button" onClick={handleLoginAction} variant="contained" color="primary" className={["form__custom-button", commonClases.alternateDisabled].join(' ')} fullWidth disabled={loggingIn}>
                    <CircularProgress style={{ display: loggingIn ? 'unset' : 'none' }}/>
                    Iniciar sesión
                </Button>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <Button type="button" onClick={handleRegister} variant="contained" color="primary" className="button_cancel" fullWidth disabled={loggingIn}>
                    Crear cuenta
                </Button>
            </div>

        </FormControl>);

    return (
        <div className="Login_register rel">

            <form className={classes.modal}>

                <Hidden smUp>
                    {recover ? recoverForm : loginForm}
                </Hidden>

                <Hidden xsDown>
                    <Card className={classes.root} variant="outlined">
                        <CardContent className="logincard logincard-bottom">
                            {recover ? recoverForm : loginForm}
                        </CardContent>
                    </Card>
                </Hidden>

            </form>

        </div>
    );
}

export default Login;
