import React from "react";
import { CircularProgress as _CircularProgress } from "@material-ui/core";

/**
 * @param {Object} params
 * @param {string} [params.color] - default = "white"
 * @param {React.CSSProperties} [params.style]
 * @returns {JSX.Element}
 */
export default function CircularProgress({ color = "white", style }) {
    return <_CircularProgress style={{ color: color, height: '20px', width: '20px', marginRight: '8px', marginLeft: '-4px', backgroundColor: 'transparent', ...style }} />;
}
