import React, { useContext, useEffect } from 'react';
import AppContext from "./AppContext";
import "./css/uifont.css";
import "./css/props.css";
import "./css/App.css";
import { Route, HashRouter, Redirect } from "react-router-dom";
import { Hidden } from "@material-ui/core";

//templates
import AppBar from "./screens/appbar";
import Footer from '../src/screens/footer';
import logoEnapsisColor from './ui/logo-enapsis-color.png';

//Screen
import HomePage from "./screens/home";
import CoursePage from "./screens/course";
import OwnCoursePage from "./screens/owncourse";
import CategoriesPage from "./screens/categories";
import MyCoursesPage from "./screens/mycourses";
import UploadPage from "./screens/courseupload";
import MyCoursesTutorPage from "./screens/mycourses-tutor";
import AddClass from "./screens/addclass";
import CourseViewPage from "./screens/courseview";
import CategoryPage from "./screens/category";
import Cart from "./screens/cart";
import SuccessShop from "./screens/success-shop";
import Login from "./screens/login";
import ProfileEdit from './screens/profile-edit';
import MyShopping from "./screens/myshopping";
import Register from "./screens/register";
import SearchCourses from "./screens/search-courses";
import ProtectedRoute from "./componentes/ProtectedRoute";
import ProtectedRouteTutor from "./componentes/ProtectedRouteTutor";
import CertifiedPage from "./screens/certified";
import DetailCertifiedPage from "./screens/detailcertified";
import DetailMultiuserPage from "./screens/detailmultiuser";
import Contact from "./screens/contact";
import SubscriptionPage from "./screens/subscription";
import DetailSubscriptionPage from "./screens/detailsubscription";
import TutorRegistration from './screens/tutor-registration';
import MyCertifiedPage from "./screens/mycertified";
import OwnCertifiedPage from "./screens/owncertified";
import Courses from './screens/courses';
import Nosotros from './screens/nosotros';
import MultiUser from './screens/multiuser';

import { BotonContext } from "./BotonContext";
import { AuthProvider } from './AuthContext';
import firebase from "firebase/compat/app";
import lodash from 'lodash';
import i18n from './lib/i18n';
import api from './lib/api';
import { useSession } from './util/session';
import routeHelper from './util/routeHelper';
import MyPlans from './screens/myplans';
import PlanManager from './screens/plan-manager';

global.fire = {
    ID: null
};
const firebaseConfig = {
    apiKey: "YOUR_API_KEY",
    authDomain: "YOUR_AUTH_DOMAIN",
    databaseURL: "YOUR_DB_URL",
    projectId: "YOUR_PROJECT_ID",
    storageBucket: "YOUR_STORAGE_BUCKET",
    messagingSenderId: "YOUR_SENDER_ID",
    appId: "YOUR_APP_ID"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

/** @type {{ [id_pais: number]: api.PaísPasarela }} */
let indexedCountryList = {};

export default function AppLoader() {

    const { countryId, setCountryList, setCart, token, data_user } = useContext(BotonContext);
    const { isLogged } = useSession();

    // obtener lista de países a ser usados en toda la aplicación (mediante contexto)
    useEffect(() => {
        api.getPaisesPasarela()
            .then(data => {
                if (data.success === true) {
                    indexedCountryList = lodash.keyBy(data.data, o => o.id_pais);
                    setCountryList(data.data);
                }
            });
    }, []);

    // reaccionar y guardar cambios de país
    useEffect(() => {
        const country = indexedCountryList[countryId];
        if (!country) return;
        const savedCountry = i18n.getCountry();
        if (savedCountry.id_pais !== countryId) {
            const saveToBackend = !!isLogged() && (countryId !== data_user.id_pais);
            i18n.setCountry(country, saveToBackend ? token : undefined);
            setCart([]);
            localStorage.removeItem('cart');
            const locationHash = window.location.hash;
            if (!/^#\/login/.test(locationHash)) {
                window.location.reload();
            }
        }
    }, [countryId]);

    const splash = () => {
        return (<>
            {/*
            // @ts-ignore */}
            <Hidden smUp>
                <div className="App flex">
                    <div className="splash-small abs abc">
                        <img src={logoEnapsisColor} className="bl" />
                    </div>
                </div>
            </Hidden>

            {/*
            // @ts-ignore */}
            <Hidden xsDown>
                <div className="App flex">
                    <div className="splash-medium abs abc">
                        <img src={logoEnapsisColor} className="bl" />
                    </div>
                </div>
            </Hidden>
        </>);
    };

    return (
        <AuthProvider>
            <AppContext.Consumer>
                {
                    context => {
                        return (
                            context.appLoaded() ?
                                <div className="MenuBar flex">
                                    <HashRouter>
                                        {/*<Sidebar />*/}
                                        <AppBar />
                                        <div className="app-content">
                                            <Route exact path="/">
                                                <Redirect to="/home" />
                                            </Route>
                                            <Route exact path="/login/:action?" component={Login} />
                                            <Route exact path="/profile-edit" component={ProfileEdit} />
                                            <Route exact path="/register" component={Register} />
                                            <Route exact path="/home" component={HomePage} />
                                            <Route exact path="/course/:courseid/:section" component={CoursePage} />
                                            <Route exact path="/cates" component={CategoriesPage} />
                                            <Route exact path="/category/:cateid/:catename" component={CategoryPage} />
                                            <Route exact path="/courses/:cateid?" component={Courses} />
                                            <Route exact path="/add-cart" component={Cart} />
                                            <Route exact path="/success-shop" component={SuccessShop} />
                                            <Route exact path="/certified" component={CertifiedPage} />
                                            <Route exact path="/detail-certified/:certifiedid/:section" component={DetailCertifiedPage} />
                                            <Route exact path="/detail-multiuser/:certifiedid/:section" component={DetailMultiuserPage} />
                                            <Route exact path="/contact/:section" component={Contact} />
                                            <Route exact path="/subscription/:section" component={SubscriptionPage} />
                                            <Route exact path="/detail-subscription/:subscriptionid/:section" component={DetailSubscriptionPage} />
                                            <Route exact path="/search-courses/:query" render={
                                                (props) => {
                                                    const query = props.match.params["query"];
                                                    return <SearchCourses {...props} key={query} />;
                                                }
                                            } />
                                            <Route exact path="/tutor-registration" component={TutorRegistration} />
                                            <Route exact path={routeHelper.nosotros} component={Nosotros} />
                                            <Route exact path={routeHelper.multiusuario} component={MultiUser} />
                                            <Route exact path={"/manage-plan/:planId/:planName"} component={PlanManager} />
                                            <Route exact path={routeHelper.misPlanes} component={MyPlans} />
                                            <ProtectedRoute exact path="/my-certified" component={MyCertifiedPage} />
                                            <ProtectedRoute exact path="/owncertified/:certifiedid/:section" component={OwnCertifiedPage} />
                                            <ProtectedRoute exact path="/my-courses" component={MyCoursesPage} />
                                            <ProtectedRoute exact path="/owncourse/:courseid/:section/:certifiedid" component={OwnCoursePage} />
                                            <ProtectedRoute exact path="/class/:courseid/:classid/:unitid/:section/:certifiedid" component={CourseViewPage} />
                                            <ProtectedRoute exact path="/my-shopping" component={MyShopping} />
                                            <ProtectedRouteTutor exact path="/my-courses-tutor" component={MyCoursesTutorPage} />
                                            <ProtectedRouteTutor exact path="/course-upload" component={UploadPage} />
                                            <ProtectedRouteTutor exact path="/edit-course/:courseId" component={UploadPage} />
                                            <ProtectedRouteTutor exact path="/edit-course/add-class/:courseId" component={AddClass} />
                                            <ProtectedRouteTutor exact path="/edit-course/edit-class/:courseId/:classId" component={AddClass} />
                                            <Footer />
                                        </div>
                                    </HashRouter>
                                </div>
                                :
                                <AppContext.Consumer>
                                    {
                                        (context) => {
                                            setTimeout(() => {
                                                context.setAppLoaded(true);
                                            }, 500);
                                            return splash();
                                        }
                                    }
                                </AppContext.Consumer>
                        );
                    }
                }

            </AppContext.Consumer>

        </AuthProvider>
    );

}
