import React, { useEffect, useState, useContext } from "react";
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import { useHistory } from "react-router";

import { BotonContext } from "../BotonContext";

import api from "../lib/api";
import commonIO from "../util/common.io";
import { useSession } from "../util/session";
import { rutHelper } from "../util/utils";

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: '8px',
    /** @type {React.CSSProperties} */
    [theme.breakpoints.down('xs')]: {
      paddingLeft: '0.5rem',
      paddingRight: '0.5rem',
      margin: 0
    },
    '& .fieldInfoLabel': {
      fontSize: 12,
      color: "red",
      paddingLeft: '0.4rem',
      /** @param {{ summitPressed: boolean }} params */
      lineHeight: ({ summitPressed }) => {
        return summitPressed ? 'unset' : '0';
      }
    }
  }
}));

const useResponsiveStyles = makeStyles(theme => ({
  card: {
    /** @type {React.CSSProperties} */
    [theme.breakpoints.down('xs')]: {
      borderWidth: 0
    }
  },
  cardContent: {
    /** @type {React.CSSProperties} */
    [theme.breakpoints.down('xs')]: {
      padding: '0 !important'
    }
  }
}));

export default function Register () {

  useEffect(() => {
    document.title = "Registrarse";
  });

  const history = useHistory();
  const { isLogged } = useSession();
  const { setDataUser, token, setToken } = useContext(BotonContext);

  const [nombre, setNombre] = useState("");
  const [errorNombre, setErrorNombre] = useState(false);
  const [mensNombre, setMensNombre] = useState("");

  const [apellido, setApellido] = useState("");
  const [errorApellido, setErrorApellido] = useState(false);
  const [mensApellido, setMensApellido] = useState("");

  const [rut, setRut] = useState("");
  const [errorRut, setErrorRut] = useState(false);
  const [mensRut, setMensRut] = useState("");

  const [f_nac, setFnac] = useState("");
  const [errorFnac, setErrorFnac] = useState(false);
  const [mensFnac, setMensFnac] = useState("");

  const [sexo, setSexo] = useState("");
  const [errorSexo, setErrorSexo] = useState(false);
  const [mensSexo, setMensSexo] = useState("");

  const [pais, setPais] = useState("");
  const [errorPais, setErrorPais] = useState(false);
  const [mensPais, setMensPais] = useState("");

  const [email, setEmail] = useState("");
  const [errorEmail, setErrorEmail] = useState(false);
  const [mensEmail, setMensEmail] = useState("");

  const [password, setPassword] = useState("");
  const [errorPassword, setErrorPassword] = useState(false);
  const [mensPassword, setMensPassword] = useState("");

  const [re_password, setRepassword] = useState("");
  const [errorRePassword, setErrorRePassword] = useState(false);
  const [mensRePassword, setMensRePassword] = useState("");

  const [checked, setChecked] = useState(true);
  const [errorChecked, setErrorChecked] = useState(false);
  const [mensChecked, setMensChecked] = useState("");

  const [open, setOpen] = React.useState(false);
  const [openmensbackend, setOpenmensbackend] = useState(false);
  const [mensajebackend, setMensajebackend] = useState("");

  const [paisesApi, setPaisesApi] = useState(/** @type {api.PaísPasarela[]} */([]));
  const [summitPressed, setSummitPressed] = useState(false);

  useEffect(() => {
    api.getPaisesPasarela()
      .then(data => {
        if (data.success) {
          setPaisesApi(data.data);
        }
      });
  }, []);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const handleCloseMens = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenmensbackend(false);
  };

  const handleChangeTerminos = (e) => {
    setChecked(e.target.checked);
  };

  function handleLogin() {
    history.push("/login");
  }

  /**
   * @returns {boolean}
   */
  const validarFormulario = function () {

    if (!nombre || nombre == '' || nombre == undefined) {
      setErrorNombre(true);
      setMensNombre("Se requiere ingresar Nombre");
      return false;
    }

    if (nombre.length > 250) {
      setErrorNombre(true);
      setMensNombre("El nombre no debe pasar de 250 caracteres");
      return false;
    } else {
      setErrorNombre(false);
      setMensNombre("");
    }

    if (!apellido || apellido == '' || apellido == undefined) {
      setErrorApellido(true);
      setMensApellido("Se requiere ingresar Apellido");
      return false;
    }

    if (apellido.length > 250) {
      setErrorApellido(true);
      setMensApellido("El apellido no debe pasar de 250 caracteres");
      return false;
    } else {
      setErrorApellido(false);
      setMensApellido("");
    }

    if (!f_nac || f_nac == '' || f_nac == undefined) {
      setErrorFnac(true);
      setMensFnac("Se requiere ingresar Fecha de nacimiento");
      return false;
    }

    if (sexo || sexo != '' || sexo == undefined) {
      setErrorSexo(false);
      setMensSexo("");
    } else {
      setErrorSexo(true);
      setMensSexo("Este campo es obligatorio");
    }

    if (pais) {
      setErrorPais(false);
      setMensPais("");
    } else {
      setErrorPais(true);
      setMensPais("Este campo es obligatorio");
    }

    if (!email) {
      setErrorEmail(true);
      setMensEmail("Se requiere ingresar Correo");
      return false;
    }

    const hasErrorEmail = !commonIO.isValidEmail(email);
    if (!hasErrorEmail) {
      setErrorEmail(false);
      setMensEmail("");
    } else {
      setErrorEmail(true);
      setMensEmail("Por favor introduzca un correo válido");
      return false;
    }

    if (!password || password == '' || password == undefined) {
      setErrorPassword(true);
      setMensPassword("Se requiere ingresar Contraseña");
      return false;
    }

    if (password.length >= 6) {
      setErrorPassword(false);
      setMensPassword("");
    } else {
      setErrorPassword(true);
      setMensPassword("Su contraseña debe poseer un mínimo de 6 caracteres.");
      return false;
    }

    if (!re_password || re_password == '' || re_password == undefined) {
      setErrorRePassword(true);
      setMensRePassword("Se requiere Confirmar Contraseña");
      return false;
    }

    if (re_password != password) {
      setErrorRePassword(true);
      setMensRePassword("Las contraseñas no coinciden, por favor verificar.");
      return false;
    } else {
      setErrorRePassword(false);
      setMensRePassword("");
    }

    if (!checked) {
      setErrorChecked(true);
      setMensChecked("Se requiere aceptar los términos y condiciones para continuar.");
      return false;
    }

    return true;
  };

  const handleSubmit = () => {
    setSummitPressed(true);
    if (!validarFormulario()) return;
    /** @type {api.DatosRegistroUsuario} */
    const datosRegistro = {
      "nombres": nombre,
      "apellidos": apellido,
      "identificacion": rut,
      "fecha_nacimiento": f_nac,
      "sexo": sexo,
      "id_pais": pais,
      "email": email,
      "password": password,
      "tipo": 1,
      "estado": 1
    };

    api.registrarUsuario({ datosRegistro, token })
      .then(response => {

        if (response.success) {
          const data_usuario = response.data.usuario;
          sessionStorage.setItem("user_data", JSON.stringify(data_usuario));
          sessionStorage.setItem("token", response.data.token);
          setToken(response.data.token);
          setDataUser(data_usuario);
          history.push("/home");

        } else {
          setMensajebackend(response.mensaje);
          setOpenmensbackend(true);
        }

      });

  };

  /** @type {import("react").KeyboardEventHandler} */
  const handleRegisterButtonKeyPress = function (e) {
    if (/Enter/i.test(e.key)) {
      e.preventDefault();
      handleSubmit();
    }
  };

  const classes = useStyles({ summitPressed: summitPressed });
  const classesResponsive = useResponsiveStyles();

  if (isLogged()) {
    history.push("/home");
  }

  return (

    <div className="Login_register rel" >
      <div style={{ display: 'flex', justifyContent: 'center' }}>

        <Snackbar open={open} autoHideDuration={3000} onClose={handleClose} /*style = {styles.snakbar}*/ anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
          <Alert onClose={handleClose} severity="error" variant="filled">
            No se pudo registrar el usuario. Inténtelo nuevamente.
          </Alert>
        </Snackbar>

        <Snackbar open={openmensbackend} autoHideDuration={3000} onClose={handleCloseMens} /*style = {styles.snakbar}*/ anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
          <Alert onClose={handleCloseMens} severity="error" variant="filled">
            {mensajebackend}
          </Alert>
        </Snackbar>

        <form id="form" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', maxWidth: '100%' }}>

          <Card className={classesResponsive.card} variant="outlined" style={{ width: '100%' }} >
            <CardContent className={["registercard registercard-bottom", classesResponsive.cardContent].join(' ')}>

              <FormControl variant="outlined" className={classes.formControl} style={{ width: '450px', maxWidth: '100%' }}>
                <TextField id="nombre" label="Nombre"
                  InputProps={{
                    className: "InputText"
                  }}
                  InputLabelProps={{
                    className: "InputText"
                  }}
                  maxRows={12}
                  minRows={7} variant="outlined" fullWidth
                  error={!!errorNombre && summitPressed}
                  onBlur={() => {
                    if (nombre || nombre != '' || nombre == undefined) {
                      setErrorNombre(false);
                      setMensNombre("");
                    } else {
                      setErrorNombre(true);
                      setMensNombre("Este campo es obligatorio");
                    }
                  }}
                  onChange={(e) => {
                    setNombre(e.target.value);
                    if (nombre.length > 250) {
                      setErrorNombre(true);
                      setMensNombre("El nombre no debe pasar de 250 caracteres");
                    } else {
                      setErrorNombre(false);
                      setMensNombre("");
                    }
                  }}
                  type="text"
                  value={nombre}
                  required
                />
                <p id="nombresID" className="fieldInfoLabel" style={{ visibility: mensNombre && summitPressed ? "visible" : "hidden" }}>
                  {mensNombre}
                </p>
                <br></br>
                <TextField id="apellido" label="Apellido"
                  InputProps={{
                    className: "InputText"
                  }}
                  InputLabelProps={{
                    className: "InputText"
                  }}
                  maxRows={12}
                  minRows={7} variant="outlined" fullWidth
                  error={!!errorApellido && summitPressed}
                  onBlur={() => {
                    if (apellido || apellido != '' || apellido == undefined) {
                      setErrorApellido(false);
                      setMensApellido("");
                    } else {
                      setErrorApellido(true);
                      setMensApellido("Este campo es obligatorio");
                    }
                  }}
                  onChange={(e) => {
                    setApellido(e.target.value);
                    if (apellido.length > 250) {
                      setErrorApellido(true);
                      setMensApellido("El apellido no debe pasar de 250 caracteres");
                    } else {
                      setErrorApellido(false);
                      setMensApellido("");
                    }
                  }}
                  type="text"
                  value={apellido}
                  required
                />
                <p id="apellidosID" className="fieldInfoLabel" style={{ visibility: mensApellido && summitPressed ? "visible" : "hidden" }}>
                  {mensApellido}
                </p>

                {1 != 1 &&

                  <TextField id="rut" label="Identificación"
                    InputProps={{
                      className: "InputText"
                    }}
                    InputLabelProps={{
                      className: "InputText"
                    }}
                    maxRows={12}
                    minRows={7} variant="outlined" fullWidth
                    error={!!errorRut && summitPressed}
                    onBlur={(e) => {

                      if (rut || rut != '' || rut == undefined) {

                        const hasError = rutHelper.validar(e.target.value);
                        if (!hasError) {
                          setErrorRut(true);
                          setMensRut("Su identificación no es válida. El formato es con dígito verificador y sin puntos. Ejemplo: 11111111-1");
                        } else {
                          setErrorRut(false);
                          setMensRut("");
                        }
                        setRut(e.target.value);
                      } else {
                        setErrorRut(true);
                        setMensRut("Este campo es obligatorio");
                      }
                    }}
                    onChange={(e) => setRut(e.target.value)}
                    type="text"
                    value={rut}
                    required
                  />
                }

                <br></br>
                <TextField id="f_nac" label="Fecha nacimiento"
                  InputProps={{
                    className: "InputText input-date-label-fix"
                  }}
                  InputLabelProps={{
                    className: "InputText"
                  }}
                  maxRows={12}
                  minRows={7} variant="outlined" fullWidth
                  error={!!errorFnac && summitPressed}
                  onBlur={() => {

                    if (f_nac || f_nac != '' || f_nac == undefined) {
                      setErrorFnac(false);
                      setMensFnac("");
                    } else {
                      setErrorFnac(true);
                      setMensFnac("Este campo es obligatorio");
                    }
                  }}
                  onChange={(e) => setFnac(e.target.value)}
                  type="date"
                  value={f_nac}
                  required
                />
                <p id="fechanacID" className="fieldInfoLabel" style={{ visibility: mensFnac && summitPressed ? "visible" : "hidden" }}>
                  {mensFnac}
                </p>
                <br></br>
                <FormControl variant="outlined">
                  <InputLabel id="demo-simple-select-outlined-label" className="InputText">Sexo</InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={sexo}
                    error={!!errorSexo && summitPressed}
                    onBlur={() => {
                      if (sexo || sexo != '' || sexo == undefined) {
                        setErrorSexo(false);
                        setMensSexo("");
                      } else {
                        setErrorSexo(true);
                        setMensSexo("Este campo es obligatorio");
                      }
                    }}
                    onChange={(e) => setSexo(e.target.value)}
                    label="Sexo"
                    MenuProps={{ disableScrollLock: true }}
                  >
                    <MenuItem value="" >
                      <em>Seleccionar</em>
                    </MenuItem>
                    <MenuItem value={1}>Masculino</MenuItem>
                    <MenuItem value={2}>Femenino</MenuItem>
                    <MenuItem value={3}>Otro</MenuItem>
                  </Select>
                  <p id="sexosID" className="fieldInfoLabel" style={{ visibility: mensSexo && summitPressed ? "visible" : "hidden" }}>
                    {mensSexo}
                  </p>
                </FormControl>

                <FormControl variant="outlined">
                  <InputLabel id="demo-simple-select-outlined-label" className="InputText">País</InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={pais}
                    error={!!errorPais && summitPressed}
                    onBlur={() => {
                      if (pais) {
                        setErrorPais(false);
                        setMensPais("");
                      } else {
                        setErrorPais(true);
                        setMensPais("Este campo es obligatorio");
                      }
                    }}
                    onChange={(e) => setPais(e.target.value)}
                    label="País"
                    MenuProps={{ disableScrollLock: true }}
                  >
                    <MenuItem value="" >
                      <em>Seleccionar</em>
                    </MenuItem>
                    {paisesApi.map(paisesValues =>
                      <MenuItem value={paisesValues.id_pais} key={paisesValues.id_pais}>{paisesValues.nombre}</MenuItem>
                    )}
                  </Select>
                  <p id="passConfirmID" className="fieldInfoLabel" style={{ visibility: mensPais && summitPressed ? "visible" : "hidden" }}>
                    {mensPais}
                  </p>
                </FormControl>
                <TextField id="email" name="email" label="Correo"
                  InputProps={{
                    className: "InputText"
                  }}
                  InputLabelProps={{
                    className: "InputText"
                  }}
                  maxRows={12}
                  minRows={7} variant="outlined" fullWidth
                  error={!!errorEmail && summitPressed}
                  onBlur={(e) => {

                    if (email) {
                      const hasError = !commonIO.isValidEmail(e.target.value);
                      if (!hasError) {
                        setErrorEmail(false);
                        setMensEmail("");
                      } else {
                        setErrorEmail(true);
                        setMensEmail("Por favor introduzca un correo válido");
                      }
                      setEmail(e.target.value);
                    } else {
                      setErrorEmail(true);
                      setMensEmail("Este campo es obligatorio");
                    }
                  }}
                  //onBlur={(e) => handleBlurEmail(e.target.value)}
                  onChange={(e) => setEmail(e.target.value)}
                  type="mail"
                  value={email}
                  required
                />
                <p id="correoID" className="fieldInfoLabel" style={{ visibility: mensEmail && summitPressed ? "visible" : "hidden" }}>
                  {mensEmail}
                </p>
                <br></br>
                <TextField id="password" name="password" label="Contraseña"
                  InputProps={{
                    className: "InputText"
                  }}
                  InputLabelProps={{
                    className: "InputText"
                  }}
                  maxRows={12}
                  minRows={7} variant="outlined" fullWidth
                  error={!!errorPassword && summitPressed}
                  onBlur={(e) => {

                    if (password || password != '' || password == undefined) {
                      if (password.length >= 6) {
                        setErrorPassword(false);
                        setMensPassword("");
                      } else {
                        setErrorPassword(true);
                        setMensPassword("Su contraseña debe poseer un mínimo de 6 caracteres.");
                      }
                      setPassword(e.target.value);
                    } else {
                      setErrorPassword(true);
                      setMensPassword("Este campo es obligatorio");
                    }
                  }}
                  onChange={(e) => setPassword(e.target.value)}
                  type="password"
                  value={password}
                  required
                />
                <p id="passwordID" className="fieldInfoLabel" style={{ visibility: mensPassword && summitPressed ? "visible" : "hidden" }}>
                  {mensPassword}
                </p>
                <br></br>
                <TextField id="re_password" label="Confirmar contraseña"
                  InputProps={{
                    className: "InputText"
                  }}
                  InputLabelProps={{
                    className: "InputText"
                  }}
                  maxRows={12}
                  minRows={7} variant="outlined" fullWidth
                  error={!!errorRePassword && summitPressed}
                  onBlur={(e) => {
                    const propOwn = Object.getOwnPropertyNames(password);
                    let concat_pass = '';
                    for (let i = 0; i < propOwn.length - 1; i++) {
                      concat_pass += password[i];
                    }
                    if (re_password || re_password != '' || re_password == undefined) {
                      if (e.target.value != concat_pass) {
                        setRepassword(e.target.value);
                        setErrorRePassword(true);
                        setMensRePassword("Las contraseñas no coinciden, por favor verificar.");
                      } else {
                        setRepassword(e.target.value);
                        setErrorRePassword(false);
                        setMensRePassword("");
                      }
                    } else {
                      setErrorRePassword(true);
                      setMensRePassword("Este campo es obligatorio");
                    }
                  }}
                  onChange={(e) => setRepassword(e.target.value)}
                  type="password"
                  value={re_password}
                  required
                />
                <p id="passwordID" className="fieldInfoLabel" style={{ visibility: mensRePassword && summitPressed ? "visible" : "hidden" }}>
                  {mensRePassword}
                </p>
                <br></br>
                <div>
                  <Checkbox
                    className="checkbox"
                    checked={checked}
                    onChange={handleChangeTerminos}
                    color="primary"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                    onBlur={() => {
                      if (!checked) {
                        setErrorChecked(true);
                        setMensChecked("Se requiere aceptar los términos y condiciones para continuar.");
                      } else {
                        setErrorChecked(false);
                        setMensChecked("");
                      }
                    }}
                  />
                  <a href="https://napsis.com/enapsis-terminos-y-condiciones/" target="_blank" rel="noreferrer noopener">Acepto los términos y condiciones</a>
                  <p id="passConfirmID" className="fieldInfoLabel" style={{ visibility: mensChecked && summitPressed ? "visible" : "hidden" }}>
                    {mensChecked}
                  </p>
                </div>
                <div className="section section-b content" style={{ marginTop: '0', display: 'flex', flexDirection: 'column', gap: '20px' }}>

                  <Button type="button" id="Guardar" onKeyPress={handleRegisterButtonKeyPress} onClick={handleSubmit} variant="contained" color="primary" className="form__custom-button" fullWidth>
                    Registrarse
                  </Button>

                  <Button type="button" onKeyPress={handleLogin} onClick={handleLogin} variant="contained" color="primary" className="button_cancel" fullWidth>
                    Cancelar
                  </Button>

                </div>

              </FormControl>

            </CardContent>
          </Card>

        </form>

      </div>
    </div>
  );
}
