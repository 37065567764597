import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useHistory } from "react-router";

//Breadcrumbs
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';

import AuthContext from "../AuthContext";

//MODAL MATERIAL UI
import DialogContent from '@material-ui/core/DialogContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import commonIO from '../util/common.io';

const useStyles3 = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
  },
}));

function MyShopping(props) {

  useEffect(() => {
    document.title = "Mis Compras";
  });

  //const history = useHistory();
  const data_user = JSON.parse(sessionStorage.getItem("user_data"));
  const token = sessionStorage.getItem("token");
  const { isAuth, isAuthTypeTutor } = useContext(AuthContext);

  const ValidarAuth = () => {

    const data_user = JSON.parse(sessionStorage.getItem("user_data"));
    const token = sessionStorage.getItem("token");

    if (isAuth && (token === null || token === 'null' || token === "" || token === undefined
      || data_user === null || data_user === 'null' || data_user === "" || data_user === undefined)) {
      localStorage.removeItem("cart");
      sessionStorage.clear();
      window.location.href = "#/login";
      window.location.reload();
    }

  };

  ValidarAuth();

  const useStyles = makeStyles({
    root: {
      width: '100%',
    },
  });

  const classes = useStyles();
  const classes3 = useStyles3();

  /*** Agregado ****/

  const [myShopping, setMyShopping] = useState([]);
  const [mens, setMens] = useState(false);

  var [disableLoading, setDisableLoading] = useState(false);


  //const id_usuario_sesion = data_user.id_usuario;
  //Obtener todos los registros de mis compras con id_estado_pago = 3 = Correo enviado
  useEffect(() => {

    let id_usuario_sesion = data_user.id_usuario;

    fetch(process.env.REACT_APP_URL_API + '/miscompras/' + id_usuario_sesion, {
      method: 'get',
      headers: {
        'token': token
      },
    })
      .then(response => response.json()
        .then(data => {

          if (data.success === true) {
            if (data.data.length > 0) {
              setMyShopping(data.data)
            } else {
              setMens(true);
            }

          }
          if (data.success === false && data.data === null) {
            //setMens(true);
            sessionStorage.setItem("token", null);
            sessionStorage.setItem("user_data", null);
            ValidarAuth();
          }
          setDisableLoading(true);

        })
      )

  }, []);

  var myShoppingList = [];
  for (let i = 0; i < myShopping.length; i++) {

    const detalles = {
      "id_producto": "Código",
      "nombre_producto": "Detalle",
      "cantidad": "Cantidad",
      "precio": "Precio",
      "descuento": "Descuento"
    }
    const json_payload = myShopping[i][4]

    const json_pago = json_payload.replace(/'/g, "\"")
    const json_ = JSON.parse(json_pago)

    const body = json_['detalle']['body']
    const total_pagado = json_['pago']['total_pagar']
    const fecha_compra = myShopping[i][8]
    const fecha_format = new Date(fecha_compra)
    const fecha_formated = fecha_format.toLocaleString()
    const moneda = json_['pago']['tipo_moneda'];

    myShoppingList.push(

      <div className={classes.root + " accordion-compra"} key={i}>
        <Accordion className="InputText" elevation={5}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon className="InputText" />}
            aria-label="Expand"
            aria-controls="additional-actions1-content"
            id="additional-actions1-header"
          >
            <FormControlLabel
              aria-label="Acknowledge"
              onClick={(event) => event.stopPropagation()}
              onFocus={(event) => event.stopPropagation()}
              control={<Checkbox className="checkbox" />}
              label={
                <div>
                  <span style={{ marginLeft: '0.5rem' }}>Compra Número {i + 1} . Fecha: { fecha_formated }.</span>
                  <span style={{ marginLeft: '0.5rem', whiteSpace: 'nowrap' }}>
                    <span> Total pagado: </span>
                    <span style={{ minWidth: '7rem', display: 'inline-block', textAlign: 'end', opacity: '0.8' }}>{ commonIO.agregarSeparadorMiles(total_pagado) } <span>{ moneda }</span></span>
                  </span>
                </div>
              }
            />
          </AccordionSummary>
          <AccordionDetails >

            <Typography >
              Detalle:
            </Typography>

            <Typography >
              {body.map(item => {
                const key = item['id_producto'] + item['nombre_producto'];
                return <li key={key}>{ item['nombre_producto'] || 'Producto sin nombre' }. { detalles.descuento }: ${ item['descuento'] || '0' }. { detalles.precio }: ${ item['precio'] || '0' }.</li>;
              })}
            </Typography>
          </AccordionDetails>
        </Accordion>

      </div>
    );
  }

  return (

    <div className="App rel">
      <Breadcrumbs aria-label="breadcrumb" className="breadcrumbs_link_inneret">
        <Typography color="textPrimary" className="breadcrumbs_link">
          Mis compras
        </Typography>
      </Breadcrumbs>
      <h1 className="title_section s24 fontb c333">Mis Compras</h1>

      <div id="module-content" className='flex-content-centered'>

      <div className="section section-b rel" style={{ width: '100%' }}>
        {disableLoading != true &&
          <div id="cargando" className={classes3.root}>

            <DialogContent className="CentrarLoadingSinModal">
              <CircularProgress className="CircularProgress" style={{ alignSelf: 'center' }} />
            </DialogContent>

          </div>
        }
        <div className="courses rel">

          {myShoppingList && myShoppingList != null && myShoppingList != '' && myShoppingList != undefined &&
            <div className="courses rel">
              {myShoppingList}
            </div>
          }

          {mens === true &&
            <div>
              <div style={{ display: 'flex', justifyContent: 'center' }} className="title_section s30 fontb c333 fullpage-mensaje">¡ Aún no se registran compras. !</div>
            </div>
          }

        </div>
      </div>

      </div>

    </div>
  );
}

export default MyShopping;
