const DEFAULT_THEME = 'light';
const VALID_THEMES = ['light', 'dark'];

/**
 * @returns {Theme}
 */
const getCurrentTheme = function () {
    const val = localStorage.getItem('theme');
    return /** @type {'light' | 'dark'} */(VALID_THEMES.includes(val) ? val : DEFAULT_THEME);
};

/**
 * @param { 'light' | 'dark' } theme
 */
export const saveTheme = function (theme) {
    localStorage.setItem("theme", theme);
};

const theme = new (function Theme () {

    const self = this;

    /** @type { 'light' | 'dark' } */
    this.current = undefined;
    Object.defineProperty(this, 'current', {
        get() {
            return getCurrentTheme();
        }
    });

    /** @type {boolean} */
    this.isLight = undefined;
    Object.defineProperty(this, 'isLight', {
        /** @returns {boolean} */
        get() {
            return self.current === 'light';
        }
    });

    /** @type {boolean} */
    this.isDark = undefined;
    Object.defineProperty(this, 'isDark', {
        /** @returns {boolean} */
        get() {
            return self.current === 'dark';
        }
    });

})();

export { theme };
