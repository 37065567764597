import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import { Hidden } from "@material-ui/core";
import logoEnapsisBlanco from '../ui/logo-enapsis-blanco.png';
import logoEnapsisColor from '../ui/logo-enapsis-color.png';
import { grey } from '@material-ui/core/colors';


import InstagramIcon from '@material-ui/icons/Instagram';
import FacebookIcon from '@material-ui/icons/Facebook';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import YouTubeIcon from '@material-ui/icons/YouTube';
import TwitterIcon from '@material-ui/icons/Twitter';
import { theme as tema } from "../util/theme";


function Copyright() {
    return (
        <Typography variant="body2" style={{ color: tema.current === "light" ? '#d87218' : '#ffffff' }} align="center">
            {new Date().getFullYear()}
            {' e-napsis by Napsis Capacitación. '}
            <Link href="https://napsis.com/" style={{ color: 'inherit' }}>
                Todos los derechos reservados
            </Link>
        </Typography>
    );
}

const useStyles = makeStyles((theme) => ({
    footer: {
        backgroundColor: tema.current === "light" ? '#dedede' : '#444444',
        padding: theme.spacing(1, 0)
    },
}));



export default function Footer() {
    const classes = useStyles();

    return (
        <footer className={classes.footer}>
            <Container maxWidth="xl">
                <Grid container direction="column" justifyContent="center" alignItems="center" >

                    <Grid container direction="row" justifyContent="center" alignItems="center">



                        <Grid item xs={12} sm={12} md={3} lg={3} xl={3} >


                            <Hidden xsDown smDown  >
                                <div align='center' style={{ marginTop: '0px' }}>
                                    <a href="#" className="logo-footer"  >
                                        <img src={tema.current === "light" ? logoEnapsisColor : logoEnapsisBlanco} className="logo-footer" />
                                    </a>
                                </div>
                            </Hidden>




                        </Grid>

                        <Grid item xs={12} sm={12} md={3} lg={3} xl={3} >



                            <br></br><br></br><br></br><br></br>
                            <div align='center' >


                                <Typography variant="h6" color="inherit" align='center' style={{ color: tema.current === "light" ? '#d87218' : '#d87218' }}>
                                    Sobre e-napsis
                                </Typography>

                            </div>
                            <br></br><br></br>

                            <div align='center'  >

                                <Typography variant="inherit" color="textSecondary" align='center' style={{ color: grey[600] }}>
                                    <Link href="https://napsis.com/enapsis-terminos-y-condiciones/" target="_blank" color="textSecondary" align='center' style={{ color: grey[600] }} >
                                        Términos y condiciones
                                    </Link>
                                </Typography>

                            </div>
                            <br></br>
                            <div align='center' >

                                <Typography variant="inherit" color="textSecondary" align='center' style={{ color: grey[600] }}>
                                    <Link href="https://napsis.com/enapsis-politica-de-privacidad/" target="_blank" color="textSecondary" align='center' style={{ color: grey[600] }} >
                                        Políticas de privacidad
                                    </Link>
                                </Typography>

                            </div>
                            <br></br>
                            <div align='center' >

                                <Typography variant="inherit" color="textSecondary" align='center' style={{ color: grey[600] }}>
                                    <Link href="https://napsis.com/enapsis-aviso-legal/" target="_blank" color="textSecondary" align='center' style={{ color: grey[600] }} >
                                        Aviso Legal
                                    </Link>
                                </Typography>

                            </div>




                        </Grid>

                        <Grid item xs={12} sm={12} md={3} lg={3} xl={3} >

                            <br></br><br></br><br></br>
                            <div align='center' >

                                <Typography variant="h6" color="inherit" align='center' style={{ color: tema.current === "light" ? '#d87218' : '#d87218' }}>
                                    Contacto
                                </Typography>

                            </div>
                            <div align='center' style={{ display: 'none' }} >

                                <Typography variant="inherit" color="textSecondary" align='center' style={{ color: grey[600] }}>
                                    (2) 1234 5678
                                </Typography>

                            </div>
                            <div align='center' >

                                <Typography variant="inherit" color="textSecondary" align='center' style={{ color: grey[600] }}>
                                    <Link href="#" color="textSecondary" align='center' style={{ color: grey[600], pointerEvents: 'none' }} >
                                    hablemos@e-napsis.com
                                    </Link>
                                </Typography>

                            </div>
                            <br></br>

                        </Grid>

                        <Grid item xs={12} sm={12} md={3} lg={3} xl={3} >



                            <br></br><br></br><br></br>
                            <div align='center' >

                                <Typography variant="h6" color="inherit" align='center' style={{ color: tema.current === "light" ? '#d87218' : '#d87218' }}>
                                    Redes sociales
                                </Typography>

                            </div>
                            <br></br><br></br>
                            <div align='center' >

                                <Typography variant="inherit" color="textSecondary" align='center' style={{ color: grey[600] }}>
                                    <Link href="https://www.instagram.com/napsischile/" target="_blank" color="textSecondary" align='center' style={{ color: grey[600] }} >
                                        <InstagramIcon style={{ marginRight: '7px' }} />
                                    </Link>
                                    <Link href="https://www.facebook.com/NapsisChile" target="_blank" color="textSecondary" align='center' style={{ color: grey[600] }} >
                                        <FacebookIcon style={{ marginRight: '7px' }} />
                                    </Link>
                                    <Link href="https://www.linkedin.com/company/napsis/" target="_blank" color="textSecondary" align='center' style={{ color: grey[600] }} >
                                        <LinkedInIcon style={{ marginRight: '7px' }} />
                                    </Link>
                                    <Link href="https://www.youtube.com/c/NapsisChile" target="_blank" color="textSecondary" align='center' style={{ color: grey[600] }} >
                                        <YouTubeIcon style={{ marginRight: '7px' }} />
                                    </Link>
                                    <Link href="#" target="_blank" color="textSecondary" align='center' style={{ color: grey[600] }} hidden={true} >
                                        <TwitterIcon style={{ marginRight: '7px' }} />
                                    </Link>
                                </Typography>

                            </div>
                            <br></br>
                            <div align='left' >

                                <Typography variant="inherit" color="textSecondary" align='left' style={{ color: grey[600] }}>

                                </Typography>

                            </div>
                            <br></br>


                            <Hidden mdUp  >
                                <br></br><br></br>
                                <div align='center' style={{ marginTop: '0px' }}>
                                    <a href="#" className="logo-footer"  >
                                        <img src={tema.current === "light" ? logoEnapsisColor : logoEnapsisBlanco} className="logo-footer" />
                                    </a>
                                </div>
                            </Hidden>

                        </Grid>




                    </Grid>

                </Grid>

                <br></br><br></br><br></br><br></br>
                <Copyright />
                <br></br><br></br>
            </Container>
        </footer>
    );
}
