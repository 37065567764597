import React from "react";
import { useHistory } from "react-router";
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import { CardActionArea } from "@material-ui/core";
import FavoriteIcon from '@material-ui/icons/Favorite';

import i18n from "../lib/i18n";
import { theme as tema } from "../util/theme";
import commonIO from "../util/common.io";

import CourseRating from "./CourseRating";
import ImagePreview from "./ImagePreview";
import AddToCartButton from "./AddToCartButton";
import { useCart } from '../util/Cart';
import routeHelper from "../util/routeHelper";

/**
  * @param {string} text
  * @returns {JSX.Element}
 */
const toVerticalText = function (text) {
    return <>{
        text.split('').map(char => {
            return <><div>{char}</div></>;
        })
    }</>;
};

/**
 * @typedef TagOptions
 * @property {string} [text]
 * @property {import("csstype").Property.Color} [color]
 * @property {import("csstype").Property.Color} [textColor]
 * @property {boolean} [verticalText]
 * @property {import("csstype").Property.FontSize} [fontSize] - default='small'
 * @property {React.CSSProperties} [style]
 * @property {number} [width] en pixeles
 */

const useStyles = makeStyles({
    ribbon: {
        '&::after': {
            /** @param {{ width: number }} params */
            borderLeftWidth: ({ width }) => {
                return width / 2;
            },
            /** @param {{ width: number }} params */
            borderRightWidth: ({ width }) => {
                return width / 2;
            }
        }
    }
});

/**
 * @param {Object} params
 * @param {string} params.ribbonClass
 * @param {boolean} [params.shadow]
 * @param {TagOptions} params.options
 * @returns {JSX.Element}
 */
const Ribbon = function ({ ribbonClass, shadow, options }) {
    const text = options.text || '';
    const fontSize = options.fontSize || 'small';
    const textNode = (options.verticalText && text) ? toVerticalText(text) : text;
    const classes = useStyles({ width: options.width });
    return (<>
        <div
            className={ribbonClass + (options.width ? ' ' + classes.ribbon : '')}
            style={/** @type {React.CSSProperties} */({
                pointerEvents: 'none',
                '--ribbon-color': options.color ? options.color : null,
                width: options.width
            })}>
            <span
                style={{
                    fontSize: fontSize,
                    color: options.textColor ? options.textColor : null,
                    ...options.style
                }}
                className={options.verticalText ? 'vertical-ribbon-text' : null}
            >
                {textNode}
            </span>
        </div>
        {shadow &&
            <div className={[ribbonClass, 'shadow'].join(' ')} style={{ pointerEvents: 'none' }}>
                <span style={{ fontSize: fontSize, color: 'transparent' }} className={options.verticalText ? 'vertical-ribbon-text' : null}>
                    {textNode}
                </span>
            </div>
        }
    </>);
};

/**
 * @param {Object} params
 * @param {string | false} params.image
 * @param {string | false} [params.text1]
 * @param {string | false} [params.text2]
 * @param {string} [params.href]
 * @param {number} [params.price]
 * @param {api.Curso} [params.curso] - TODO: manejar las distintas estructuras (hay APIs donde no cumple con `api.Curso`).
 * @param {api.Plan} [params.plan]
 * @param {boolean} [params.small] - default = false
 * @param {number} [params.elevation] - mientras mayor sera el valor, mayor será la sombra de la tarjeta
 * @param {boolean} [params.showRibbon]
 * @param {TagOptions} [params.ribbonOptions]
 * @param {boolean} [params.showTag]
 * @param {TagOptions} [params.tagOptions]
 * @param {string | string[]} [params.className]
 * @param {JSX.Element} [params.customActionArea]
 * @param {boolean} [params.hasVideo]
 * @param {() => void} [params.handleVideoPreview]
 * @param {boolean} [params.showBuyButtons]
 * @param {string | JSX.Element | JSX.Element[]} [params.children]
 * @returns {JSX.Element}
 * @todo usar directamente `curso` en lugar de `image`, `text1`, `text2`, `href` y `price` (y posiblemente otros parámetros). No se ha hecho aún porque la estructura de datos no está normalizada entre los distintos APIs (y aún no se define en `api.d.ts` para todos los casos; para asistir en el manejo de las diferencias).
 */
export default function TarjetaEcampus({ image, text1, text2, href, price, curso, plan, small, elevation, showRibbon, ribbonOptions = {}, showTag, tagOptions = {}, className, customActionArea, hasVideo, handleVideoPreview, showBuyButtons, children }) {

    const history = useHistory();
    const { addCourse, hasItem, addPlan, hasItemPlan } = useCart();
    const inCart = hasItem(plan || curso);
    const classNameBuyNow = ["button_accept MuiButton-contained"];

    let hasAnyPlanInCart = false;
    if (plan) {
        hasAnyPlanInCart = hasItemPlan();
        if (!inCart && hasAnyPlanInCart) {
            classNameBuyNow.push('button_accept_disable');
        }
    }

    const cardContent = (<>

        {image !== false &&
            <div className="wrapper-imagen-tarjeta">
                <div className="imagen-tarjeta">
                    {hasVideo
                        ?
                        <ImagePreview image={image} alt="imagen curso" hasVideo={hasVideo} handleShowVideo={handleVideoPreview} style={{ height: '216px' }} fullAreaSelect={false} />
                        :
                        <img src={image}></img>
                    }
                </div>
            </div>
        }

        <CardContent className="description-tarjeta">

            {text1 !== false &&
                <Typography variant="body2" style={{ color: tema.isLight ? '#d87218' : '#ffffff', lineHeight: '16px' }}>
                    {text1}
                </Typography>
            }

            {text2 !== false &&
                <Typography variant="subtitle2" style={{ color: tema.isLight ? '#282c34' : '#cecece', marginTop: '3px' }}>
                    {text2}
                </Typography>
            }

        </CardContent>

    </>);

    const classNames = ["tarjeta-ecampus"];
    if (className) {
        if (typeof className === 'string') {
            classNames.push(className);
        } else if (className instanceof Array) {
            classNames.push(...className);
        }
    }

    /** @type {JSX.Element} */
    let rating;
    if (curso?.n_valoracion) {
        rating = <div style={{ marginLeft: '20px', position: 'absolute', bottom: '45px' }}>
            <CourseRating value={curso.valoracion} count={curso.n_valoracion} />
        </div>;
    }

    return (
        <Card
            style={(() => {
                /** @type {React.CSSProperties} */
                const style = { width: '330px', overflow: 'unset', zIndex: 0 };
                if (small) {
                    style.maxWidth = '260px';
                } else {
                    style.minWidth = '260px';
                }
                return style;
            })()}
            className={classNames.join(' ')}
            elevation={typeof elevation === 'number' ? elevation : undefined}
        >
            {href
                ?
                <CardActionArea style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'start' }} onClick={() => {
                    history.push(href);
                }}>
                    {cardContent}
                </CardActionArea>
                :
                <div style={{ height: '290px', cursor: 'default' }}>
                    {cardContent}
                </div>
            }

            {rating}

            <CardActions className="panel-inferior-tarjeta" style={{ visibility: !!customActionArea || commonIO.isNumeric(price) ? 'visible' : 'hidden' }}>
                {!customActionArea ?
                    <Button className="MuiButton-root-MisPrecioTarjetas" aria-label="Añadir a favoritos" size="small">
                        <FavoriteIcon /> &nbsp;&nbsp;&nbsp; <b>{i18n.formatPrice(price)} {i18n.getCurrencyLabel()}</b>
                    </Button>
                    :
                    customActionArea
                }
            </CardActions>

            {showBuyButtons &&
                <div style={{ display: 'flex', flexDirection: 'column', padding: '0.5rem', gap: '0.3rem', width: '100%', paddingLeft: '55%' }}>
                    <AddToCartButton text="Agregar al carrito" course={curso} plan={plan} textInCart={plan && "Quitar del carrito"} disabled={hasAnyPlanInCart} />
                    <button className={classNameBuyNow.join(' ')} disabled={!inCart && hasAnyPlanInCart} onClick={() => {
                        if (plan) {
                            !inCart && addPlan(plan, false);
                        } else if (curso) {
                            !inCart && addCourse(curso, false);
                        }
                        history.push(routeHelper.cart);
                    }}>Comprar ahora</button>
                </div>
            }

            {children &&
                children
            }

            {showRibbon &&
                <Ribbon ribbonClass="ribbon ribbon-bottom-right" options={ribbonOptions} />
            }

            {showTag &&
                <Ribbon ribbonClass="ribbon2" shadow={true} options={tagOptions} />
            }

        </Card>
    );
}
