import React, { useEffect, useState, useContext } from "react";
import "./styles.css";
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import { Alert } from '@material-ui/lab';
import axios from 'axios';
import { useSnackbar } from "notistack";

import AuthContext from "../../AuthContext";
import { BotonContext } from "../../BotonContext";
import { useSession } from "../../util/session";
import { useCommonStyles } from "../../util/common.styles";
import CircularProgress from "../ButtonCircularProgress";

const useStyles = makeStyles({
    root: {
        height: 14,
        borderRadius: 15
    }
});

/**
 * @param {Object} obj
 * @param {number} obj.evaluationID
 * @param {() => void} obj.marcarClaseCompletada
 * @param {boolean} obj.updatingClassState
 * @returns {JSX.Element}
 */
export const QuestionBox = ({ evaluationID, marcarClaseCompletada, updatingClassState }) => {

    const { data_user, token } = useContext(BotonContext);
    const { isAuth } = useContext(AuthContext);
    const session = useSession();
    const { enqueueSnackbar } = useSnackbar();
    const json_resp = sessionStorage.getItem("json_respuesta_alumno");
    const [total, setTotal] = useState([]);
    const [repetir, setRepetir] = useState([]);
    const [indexQuestion, setIndexQuestion] = useState(0);
    const [score, setScore] = useState(0);
    const [error, setError] = useState(0);
    const [done, setDone] = useState(false);
    const [presentacion, setPresentacion] = useState(false);
    const [amount, setAmount] = useState(false);
    const [currentQuestions, setCurrentQuestions] = useState({});
    const [disableAnteriorRespuesta, setDisableAnteriorRespuesta] = useState(true);
    const [botonstatus, setBotonstatus] = useState("button_accept_disable");
    const [dataSolucion, setDataSolucion] = useState(null);
    const [porcentajeAprobacion, setPorcentajeAprobacion] = useState(0);
    const [correctas, setCorrectas] = useState(0);
    const [incorrectas, setIncorrectas] = useState(0);
    const [omitidas, setOmitidas] = useState(0);
    const classes = useStyles();
    const commonClases = useCommonStyles();

    const increaseScore = (value) => {
        setScore(score + value);
    };
    const restarScore = (value) => {
        setScore(score - value);
    };
    const increaseErrorScore = (value) => {
        setError(error + value);
    };

    const ValidarAuth = () => {
        if (!isAuth) {
            session.handleNotAuthorized();
        }
    };
    ValidarAuth();

    const NextIndexQuestion = () => {
        if (indexQuestion + 1 < amount + 1 && indexQuestion + 1 > 0) {
            setIndexQuestion(indexQuestion + 1);
        }
    };

    const AnteriorIndexQuestion = () => {
        if (indexQuestion > 0) {
            setIndexQuestion(indexQuestion - 1);
        }
        if (indexQuestion == 0) {
            setPresentacion(false);
        }
    };

    /* Fin useData */

    {/* Obtener resultados evaluacion final */ }

    const handleSubmitAnteriorRespuestas = (e) => {
        e.preventDefault();
        currentQuestions.map((e) => {
            if (!e.selected && !e.value) {
                restarScore(1);
            }
        });
        setTotal(JSON.parse(json_resp));
        AnteriorIndexQuestion();
        currentQuestions.map((e, index) => {
            document.getElementById(`radio${index}`).checked = false;
        });
        if (indexQuestion <= 1) {
            setDisableAnteriorRespuesta(true);
            setBotonstatus("button_accept_disable");
        }

    };

    const handleSubmitRespuestas = (e) => {
        e.preventDefault();
        currentQuestions.map((e) => {
            if (e.selected && e.value) {
                increaseScore(1);
            }
            if (e.selected && !e.value) {
                increaseErrorScore(1);
            }
        });
        NextIndexQuestion();
        currentQuestions.map((e, index) => {
            document.getElementById(`radio${index}`).checked = false;
        });
        if (indexQuestion == amount - 1) {
            setFinish(true);
            setResultados(false);
        }

        setDisableAnteriorRespuesta(false);
        setBotonstatus("button_accept");
    };

    const Respuestas = [];
    let number_resp = indexQuestion + 1;

    if (indexQuestion < amount) {

        if (dataSolucion) {

            let resp_evaluacion = Object.entries(dataSolucion.preguntas);

            /** Porcentaje */
            let percentage_resp = (indexQuestion * 100) / amount;

            Respuestas.push(

                <div key={resp_evaluacion[indexQuestion][0]}>

                    <br></br>
                    <h2 className="s24 title_section fontb c333">{dataSolucion.nombre}</h2>
                    <br></br>
                    <div className="s22 title_section fontb c333" >
                        <h4>
                            &nbsp;&nbsp;&nbsp;&nbsp;Resultados:
                        </h4>
                    </div>
                    <br></br>

                    <Grid container spacing={3}>

                        <Grid item xs={1} sm={1} md={1} lg={1} xl={1} > </Grid>
                        <Grid item xs={10} sm={10} md={10} lg={10} xl={10}  >

                            <div className="s18 title_section fontb c333" >
                                <h4>
                                    Pregunta número {number_resp} de {amount}
                                </h4>
                            </div>
                            <br></br><br></br>
                            <div className="card-body jtx">
                                <div>{resp_evaluacion[indexQuestion][1].enunciado}</div>

                                {resp_evaluacion[indexQuestion][1].alternativas.map((e, index) => {
                                    return (
                                        <div key={index} className="d-flex">
                                            <br></br><br></br>
                                            <input
                                                className="form-check-input me-2"
                                                type="radio"
                                                name="flexRadioDefault"
                                                value={resp_evaluacion[indexQuestion][1].alternativas[index].selected}
                                                //onChange={handleChange(index)}
                                                readOnly={true}
                                                id={"radio" + index}
                                                checked={resp_evaluacion[indexQuestion][1].alternativas[index].selected}
                                            ></input>
                                            &nbsp;&nbsp;{e.descripcion}
                                        </div>
                                    );
                                })}
                                <br></br><br></br>
                                {resp_evaluacion[indexQuestion][1].respuesta == "Incorrecta" &&
                                    <Alert style={{ maxWidth: '180px' }} className="Alert-Error" severity="error">Respuesta Incorrecta!</Alert>
                                }
                                {resp_evaluacion[indexQuestion][1].respuesta == "Correcta" &&
                                    <Alert style={{ maxWidth: '180px' }} className="Alert-Success" severity="success">Respuesta Correcta!</Alert>
                                }
                                {resp_evaluacion[indexQuestion][1].respuesta == "Omitida" &&
                                    <Alert style={{ maxWidth: '180px' }} className="Alert-Warning" severity="warning">Respuesta Omitida!</Alert>
                                }
                                <br></br>
                                <div className="s18 title_section fontb c333"> Justificación: </div>
                                <br></br>
                                <div className="card-body jtx"> {resp_evaluacion[indexQuestion][1].justificacion} </div>
                                <br></br><br></br><br></br>
                            </div>

                        </Grid>
                        <Grid item xs={1} sm={1} md={1} lg={1} xl={1} > </Grid>
                    </Grid>

                    <Box sx={{ width: '95%', mr: 1 }} style={{ marginRight: '15px', marginLeft: '15px', marginBottom: '10px' }}>
                        <LinearProgress className={classes.root} style={{ marginTop: '5px' }} size="large" color="primary" variant="determinate" value={percentage_resp} />
                        <br></br><br></br>
                        <Button onClick={handleSubmitAnteriorRespuestas} disabled={disableAnteriorRespuesta} variant="contained" color="primary" className={botonstatus} style={{ float: 'left', marginRight: '15px', marginLeft: '15px', marginBottom: '10px' }}>
                            Anterior
                        </Button>

                        <Button onClick={handleSubmitRespuestas} variant="contained" color="primary" className="button_accept" style={{ float: 'right', marginRight: '15px', marginLeft: '15px', marginBottom: '10px' }}>
                            Siguiente
                        </Button>
                    </Box>
                    <br></br><br></br><br></br><br></br>

                </div>
            );

        }
    }

    {/* Fin Obtener resultados evaluacion final */ }

    {/* Obtener evaluacion final */ }

    useEffect(() => {
        fetch(process.env.REACT_APP_URL_API + '/evaluacion/' + evaluationID, {
            method: 'get',
            headers: {
                'token': token,
                'Content-Type': 'application/json'
            }
        })
            .then(response => {
                if (response.status === 401 || response.status === 403) {
                    session.handleNotAuthorized();
                    return;
                }
                response.json()
                    .then(data => {
                        if (data.success === true) {
                            setTotal(data.data);
                            let preg_evaluacion = Object.entries(data.data.preguntas);
                            let temp = preg_evaluacion[indexQuestion][1].alternativas;
                            setCurrentQuestions(temp);
                            setAmount(data.data.n_preguntas);
                            setRepetir(1);
                        }
                        if (data.success === false && data.data === null) {
                            ValidarAuth();
                        }
                        if (data.success === false) {
                            enqueueSnackbar(data.mensaje, { variant: 'error' });
                        }
                    });
            });
    }, [repetir]);

    const handleChange = (index) => () => {
        try {
            let vec = [...currentQuestions];
            vec.map((e) => {
                return (e.selected = false);
            });
            vec[index].selected = !vec[index].selected;
            const json_resp_alumno = total;
            sessionStorage.setItem("json_respuesta_alumno", JSON.stringify(json_resp_alumno));
            setCurrentQuestions(vec);
        } catch (error) { }
    };

    const handleSubmitAnterior = (e) => {
        e.preventDefault();
        currentQuestions.map((e) => {
            if (!e.selected && !e.value) {
                restarScore(1);
            }
        });
        setTotal(JSON.parse(json_resp));
        AnteriorIndexQuestion();
        currentQuestions.map((e, index) => {
            document.getElementById(`radio${index}`).checked = false;
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        let vec = [...currentQuestions];
        vec.map((e) => {
            if (e.selected) {

                return (e.selected = true);
            }
            if (!e.selected) {
                return (e.selected = false);
            }
        });
        setCurrentQuestions(vec);
        increaseScore(1);
        const json_resp_alumno = total;
        sessionStorage.setItem("json_respuesta_alumno", JSON.stringify(json_resp_alumno));
        NextIndexQuestion();
        if (indexQuestion == amount - 1) {
            setFinish(true);
        }
    };

    const Preguntas = [];
    let number = indexQuestion + 1;

    if (indexQuestion < amount) {

        if (total && total != '' && total !== undefined && total != null) {

            let preg_evaluacion = (JSON.parse(json_resp) != null || JSON.parse(json_resp) != undefined) ? Object.entries(JSON.parse(json_resp).preguntas) : Object.entries(total.preguntas);

            /** Porcentaje */
            let percentage = (indexQuestion * 100) / amount;

            Preguntas.push(
                <div key={preg_evaluacion[indexQuestion][0]}>

                    <br></br>
                    <h2 className="s24 title_section fontb c333">{total.nombre}</h2>
                    <br></br><br></br><br></br>

                    <Grid container spacing={3}>

                        <Grid item xs={1} sm={1} md={1} lg={1} xl={1} > </Grid>
                        <Grid item xs={10} sm={10} md={10} lg={10} xl={10}  >

                            <div className="s18 title_section fontb c333" >
                                <h4>
                                    Pregunta número {number} de {amount}
                                </h4>
                            </div>
                            <br></br><br></br>
                            <div className="card-body jtx">
                                <div>{preg_evaluacion[indexQuestion][1].enunciado}</div>

                                {preg_evaluacion[indexQuestion][1].alternativas.map((e, index) => {

                                    return (
                                        <div key={index} className="d-flex">
                                            <br></br><br></br>
                                            <input
                                                className="form-check-input me-2"
                                                type="radio"
                                                name="flexRadioDefaultpreg"
                                                value={preg_evaluacion[indexQuestion][1].alternativas[index].selected || false}
                                                onChange={handleChange(index)}
                                                id={"radio" + index}
                                                checked={preg_evaluacion[indexQuestion][1].alternativas[index].selected || false}

                                            ></input>
                                            &nbsp;&nbsp;{e.descripcion}
                                        </div>
                                    );
                                })}

                                <br></br><br></br><br></br><br></br><br></br><br></br>

                            </div>

                        </Grid>
                        <Grid item xs={1} sm={1} md={1} lg={1} xl={1} > </Grid>
                    </Grid>

                    <Box sx={{ width: '95%', mr: 1 }} style={{ marginRight: '15px', marginLeft: '15px', marginBottom: '10px' }}>
                        <LinearProgress className={classes.root} style={{ marginTop: '5px' }} size="large" color="primary" variant="determinate" value={percentage} />
                        <br></br><br></br>
                        <Button onClick={handleSubmitAnterior} variant="contained" color="primary" className="button_accept" style={{ float: 'left', marginRight: '15px', marginLeft: '15px', marginBottom: '10px' }}>
                            Anterior
                        </Button>

                        <Button onClick={handleSubmit} variant="contained" color="primary" className="button_accept" style={{ float: 'right', marginRight: '15px', marginLeft: '15px', marginBottom: '10px' }}>
                            Siguiente
                        </Button>

                    </Box>
                    <br></br><br></br><br></br><br></br>

                </div>
            );

        }
    }
    {/* Fin Obtener evaluacion final */ }

    const [finish, setFinish] = useState(false);
    const [resultados, setResultados] = useState(false);

    const handleIniciar = () => {
        const json_resp_alumno = total;
        sessionStorage.setItem("json_respuesta_alumno", JSON.stringify(json_resp_alumno));
        setPresentacion(true);
    };

    useEffect(() => {
        if (indexQuestion < amount) {
            let preg_evaluacion = Object.entries(total.preguntas);
            let vec = preg_evaluacion[indexQuestion][1].alternativas;
            vec.map((e) => {
                if (e.selected) {
                    return (e.selected = true);
                }
                if (!e.selected) {
                    return (e.selected = false);
                }
            });
            setCurrentQuestions(vec);
        }
    }, [indexQuestion]);

    function saveRespuestas() {

        let json = JSON.parse(json_resp);

        const json_respuestas = {
            "id_evaluacion": json.id,
            "id_usuario": data_user.id_usuario,
            "fecha_creacion": '0000-00-00 00:00:00',
            "detalle_respuesta": json
        };

        axios.post(process.env.REACT_APP_URL_API + '/evaluacion/' + evaluationID + '/alumno/' + data_user.id_usuario + '/solucion', json_respuestas, {
            headers: {
                'Content-Type': 'application/json',
                'token': token
            }
        })
            .then(response => {
                if (response.status === 401 || response.status === 403) {
                    session.handleNotAuthorized();
                    return;
                }
                if (response.data.success) {
                    setDataSolucion(response.data.data);
                    setPorcentajeAprobacion(response.data.data.porcentaje_aprobacion);
                    setCorrectas(response.data.data.cant_correctas);
                    setIncorrectas(response.data.data.cant_incorrectas);
                    setOmitidas(response.data.data.cant_omitidas);
                    enqueueSnackbar(response.data.mensaje, { variant: 'success' });
                } else {
                    enqueueSnackbar(response.data.mensaje, { variant: 'error' });
                }
            })
            .catch(e => {
                enqueueSnackbar("Error al descargar datos de evaluación", { variant: 'error' });
                console.log(e);
            });

    }

    return (
        <div >
            <div className="container p-0">
                <div className="row">
                    <div className="card col-md-8 col-lg-9 p-0 ps-0">
                        {!resultados ? (
                            <div>
                                {presentacion ? (
                                    <div>
                                        {!done ? (
                                            <div>
                                                {!finish ? (
                                                    <div>
                                                        {Preguntas}
                                                    </div>
                                                ) : (

                                                    <div>

                                                        <br></br>
                                                        <h2 className="s24 title_section fontb c333">Evaluación final</h2>
                                                        <br></br><br></br><br></br>

                                                        <Grid container spacing={3}>

                                                            <Grid item xs={1} sm={1} md={1} lg={1} xl={1} > </Grid>
                                                            <Grid item xs={12} sm={10} md={10} lg={10} xl={10}  >

                                                                <div className="s24 title_section fontb c333">
                                                                </div>
                                                                <br></br>
                                                                <div className="card-body">
                                                                    <div></div>

                                                                    <div className="s24 title_section fontb c333" style={{ textAlign: 'center' }}>
                                                                        <br></br><br></br><br></br><br></br>
                                                                        <h2 className="s40 title_section fontb c333">
                                                                            Ha completado la evaluación final.
                                                                        </h2>
                                                                    </div>

                                                                    <br></br><br></br><br></br><br></br><br></br><br></br>

                                                                </div>

                                                            </Grid>
                                                            <Grid item xs={1} sm={1} md={1} lg={1} xl={1} > </Grid>
                                                        </Grid>
                                                        <br></br><br></br><br></br>

                                                        <Button onClick={() => {
                                                            setIndexQuestion(0);
                                                            setFinish(false);
                                                            setResultados(false);
                                                            setDone(false);
                                                            setScore(0);
                                                            setError(0);
                                                            setRepetir(0);
                                                            sessionStorage.setItem("json_respuesta_alumno", null);
                                                        }} variant="contained" color="primary" className="button_cancel"
                                                            style={{ float: 'right', marginRight: '45px', marginLeft: '5px', marginBottom: '10px' }}>
                                                            Repetir evaluación
                                                        </Button>

                                                        <Button
                                                            onClick={() => {
                                                                setDone(true); saveRespuestas();
                                                            }}
                                                            variant="contained" color="primary" className="button_accept"
                                                            style={{ float: 'right', marginRight: '5px', marginLeft: '15px', marginBottom: '10px' }}>
                                                            Guardar y enviar
                                                        </Button>
                                                        <br></br><br></br><br></br>

                                                        {/* Form-Modal Aprobacion evaluacion*/}

                                                    </div>
                                                )}
                                            </div>
                                        ) : (

                                            <div>

                                                {/* Form-Modal Aprobacion evaluacion*/}

                                                <br></br>
                                                <h2 className="s24 title_section fontb c333">Evaluación final</h2>
                                                <br></br><br></br><br></br><br></br><br></br>

                                                <Grid container spacing={3}>

                                                    <Grid item xs={1} sm={1} md={1} lg={1} xl={1} > </Grid>
                                                    <Grid item xs={10} sm={10} md={10} lg={10} xl={10}  >
                                                        <br></br><br></br><br></br><br></br>
                                                        <div className="s24 title_section fontb c333" style={{ textAlign: 'center' }}>
                                                            <h3>
                                                                Su nota fue de:{" "}
                                                                <span className="text-muted">{porcentajeAprobacion}%</span>
                                                            </h3>
                                                        </div>
                                                        <br></br>
                                                        <div className="s20 title_section fontb c333">
                                                            <div></div>
                                                            {porcentajeAprobacion < 60 &&
                                                                <div>
                                                                    <div className="container mt-5 d-flex justify-content-center" style={{ textAlign: 'center' }}>
                                                                        <div>
                                                                            <h3>El porcentaje de logro no alcanzó el 60%</h3>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            }
                                                            {porcentajeAprobacion >= 60 &&
                                                                <div>
                                                                    <div className="container mt-5 d-flex justify-content-center" style={{ textAlign: 'center' }}>
                                                                        <div>
                                                                            <h3 className="s30 title_section fontb c333">Felicitaciones ha aprobado la evaluación!</h3>

                                                                        </div>
                                                                        <br></br>
                                                                        <div>
                                                                            <h5>Puede presionar el botón "FINALIZAR EVALUACIÓN" para avanzar a la siguiente clase.</h5>

                                                                        </div>
                                                                    </div>
                                                                    <br></br>
                                                                </div>
                                                            }
                                                            {/** ProgressBar */}

                                                            <div className="ProgressBarDiv">
                                                                <div className="card">

                                                                    {done && (
                                                                        <div className="s18 title_section fontb c333" style={{ textAlign: 'center' }}>
                                                                            <br></br>
                                                                            <h3>Respuestas correctas: {correctas}</h3>
                                                                            <h3>Respuestas incorrectas: {incorrectas}</h3>
                                                                            <h3>Respuestas omitidas: {amount - correctas - incorrectas}</h3>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                            {/** ProgressBar */}

                                                        </div>

                                                    </Grid>
                                                    <Grid item xs={1} sm={1} md={1} lg={1} xl={1} > </Grid>

                                                </Grid>
                                                <br></br><br></br><br></br>

                                                {porcentajeAprobacion >= 60 &&
                                                    <Button
                                                        onClick={() => {
                                                            marcarClaseCompletada();
                                                        }}
                                                        variant="contained" color="primary"
                                                        className={["button_accept", commonClases.alternateDisabled].join(' ')}
                                                        style={{ float: 'right', marginRight: '55px', marginLeft: '5px', marginBottom: '10px' }}
                                                        disabled={updatingClassState}
                                                    >
                                                        <CircularProgress style={{ display: updatingClassState ? 'unset' : 'none' }}/>
                                                        Finalizar evaluación
                                                    </Button>
                                                }

                                                <Button
                                                    onClick={() => {
                                                        setIndexQuestion(0);
                                                        setFinish(false);
                                                        setResultados(false);
                                                        setDone(false);
                                                        setScore(0);
                                                        setError(0);
                                                        setRepetir(0);
                                                        sessionStorage.setItem("json_respuesta_alumno", null);
                                                    }}
                                                    variant="contained" color="primary"
                                                    className="button_cancel"
                                                    style={{ float: 'right', marginRight: '5px', marginLeft: '5px', marginBottom: '10px' }}
                                                    disabled={updatingClassState}
                                                >
                                                    Repetir evaluación
                                                </Button>

                                                <Button
                                                    onClick={() => {
                                                        setIndexQuestion(0);
                                                        setFinish(true);
                                                        setResultados(true);
                                                        setDone(true);
                                                    }}
                                                    variant="contained" color="primary"
                                                    className={["button_accept", commonClases.alternateDisabled].join(' ')}
                                                    style={{ float: 'right', marginRight: '5px', marginLeft: '15px', marginBottom: '10px' }}
                                                    disabled={updatingClassState}
                                                >
                                                    Ver resultados
                                                </Button>

                                                <br></br><br></br><br></br>

                                            </div>
                                        )}

                                    </div>) : (
                                    <div>
                                        {/* Form-Modal Presentacion */}

                                        <br></br>
                                        <h2 className="s24 title_section fontb c333">Evaluación final</h2>
                                        <br></br><br></br><br></br><br></br><br></br><br></br><br></br>
                                        <Grid container spacing={3}>

                                            <Grid item xs={1} sm={1} md={1} lg={1} xl={1} > </Grid>
                                            <Grid item xs={10} sm={10} md={10} lg={10} xl={10}  >
                                                <div className="s20 title_section fontb c333">
                                                    <h4>
                                                        Instruciones:
                                                    </h4>
                                                </div>
                                                <br></br><br></br>
                                                <div className="card-body jtx">
                                                    <div>La evaluación consta de un serie de preguntas de selección simple,
                                                        debe seleccionar sólo la opción que usted considere correcta en cada pregunta.
                                                    </div>
                                                    <br></br><br></br>
                                                </div>
                                                <div className="s20 title_section fontb c333">
                                                    <h4>
                                                        Criterios de la evaluación:
                                                    </h4>
                                                </div>
                                                <br></br><br></br>
                                                <div className="card-body jtx" style={{ lineHeight: '25px' }}>
                                                    <div>Si el porcentaje de logro es igual o superior al 60% aprobará la evaluación,
                                                        en caso de ser inferior al 60 % no aprobará pero podrá revisar las preguntas y
                                                        repetir la evaluación un número ilimitado de veces.
                                                    </div>
                                                    <br></br><br></br>
                                                </div>
                                            </Grid>
                                            <Grid item xs={1} sm={1} md={1} lg={1} xl={1} > </Grid>
                                        </Grid>

                                        <Button onClick={handleIniciar} variant="contained" color="primary" className="button_accept" style={{ float: 'right', marginRight: '55px' }}>
                                            Iniciar evaluación
                                        </Button>
                                        <br></br><br></br><br></br>

                                        {/* Fin Form-Modal Presentacion */}
                                    </div>
                                )}

                            </div>

                        ) : (
                            <div>
                                {Respuestas}
                            </div>
                        )}
                    </div>
                    <div className="col-md-4 col-lg-3 p-0">
                        <div className=" m-md-0 mx-md-1">
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
};
