import styled from "styled-components";

export const SwitchContainer = styled.div`
    width: 85%;
    background-color: ${({ theme}) => theme.bgc};
    transition: 0.5s all easy-in;
`;

export const SwitchWrapper = styled.div`
    width: 100%;
    max-width: 1300px;
    margin: auto;
    padding-top: 1rem;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row-reverse;
`;

// export const SwitchBGC = styled.div`
//     position: relative;
//     width: 90px;
//     height: 40px;
//     border-radius: 30px;
//     background-color: ${({ theme}) => theme.toggle};
//     transition: 1s all easy-in;
// `;


export const SwitchBGC = styled.div`
    position: relative;
    width: 90px;
    height: 30px;
    border-radius: 30px;
    background-color: #bdbdbd;
    transition: 1s all easy-in;
`;

//Original: 65px : 5px
export const ButtonSwitch = styled.button`
    position: absolute;
    cursosr: pointer;
    bottom: 5px;
    left: ${({position}) => (position ? '65px' : '5px')};
    width: 20px;
    height: 20px;
    border: none;
    border-radius: 50%;
    background-color: #fff;
    transition: 0.4s all easy-in;
    outline: none;

`;