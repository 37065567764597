
/**
 * @param {string} fullText
 * @param {number} targetLength - aproximado. No se corta en medio de palabras, por lo que el tamaño puede ser mayor o menor. NOTA: sin incluir la `elipsis`.
 * @param {Object} options
 * @param {string} [options.elipsis] - default = "...". NOTA: no se agrega si se retorna el texto original (no se resume).
 * @param {number} [options.maxDelta] - default = 10. Max delta deseado respecto de `targetLength`. El método devolverá el resumen que minimize el delta. NOTA: puede ser necesario que el delta sea mayor a este valor. En dichos casos el resumen se hará más corto (nunca más largo que `targetLength` + `maxDelta`).
 * @todo optimizar
 */
export const shortenText = function (fullText, targetLength, { elipsis = '...', maxDelta = 10 } = {}) {
    const originalLength = fullText.length;
    if (originalLength <= targetLength + maxDelta) return fullText;
    let summary = '';
    let lastDifferenceFromTarget = Number.MAX_SAFE_INTEGER;
    const regexInicial = new RegExp(`(^[\\s\\S]{${ targetLength - maxDelta }}[\\s\\S]*?)(\\W[\\s\\S]*$)`);
    let match = regexInicial.exec(fullText);
    while (match) {
        const differenceFromTarget = Math.abs(targetLength - (summary.length + match[1].length));
        if (differenceFromTarget < lastDifferenceFromTarget && differenceFromTarget <= maxDelta) {
            summary += match[1];
            lastDifferenceFromTarget = differenceFromTarget;
            const remainingText = match[2];
            match = lastDifferenceFromTarget === 0 ? undefined : /(^[\s\S]+?)(\W[\s\S]*$)/.exec(remainingText); // detener loop si `lastDifferenceFromTarget` = 0 (se alcanzó el target exactamente)
        } else {
            match = undefined; // break loop
        }
    }
    if (!summary) {
        // ocurre si no se logra cortar el texto dentro del límite impuesto por targetLength +- maxDelta
        // salir del límite impuesto por maxDelta haciendo el resumen más corto (delta > maxDelta).
        const substring = fullText.substring(0, targetLength + maxDelta);
        match = new RegExp(`(^[\\s\\S]{${ Math.floor(substring.length / 2) }}[\\s\\S]*\\w)\\W$`).exec(substring);
        if (match) {
            summary = match[1];
        }
    }
    if (!summary) {
        // en este punto simplemente cortar sin importar que sea en medio de una palabra
        summary = fullText.substring(0, targetLength);
    }
    return summary.trim() + elipsis;
};

/**
 * @param {string | number} value
 * @todo agregar parámetro para soportar separadores decimales distintos de punto.
 */
export const isNumeric = function (value) {
    if (typeof value === 'number') {
        return !isNaN(value);
    } else if (typeof value === 'string') {
        // @ts-ignore
        return !isNaN(value) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
            !isNaN(parseFloat(value)); // ...and ensure strings of whitespace fail
    } else {
        return false;
    }
};

/**
 * @param {string} email
 * @returns {boolean}
 */
export const isValidEmail = function (email) {
    return /^(?:[^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*|"[^\n"]+")@(?:[^<>()[\].,;:\s@"]+\.)+[^<>()[\]\.,;:\s@"]{2,63}$/i.test(email);
    //return /^[-\w.%+]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(email);
    //return /^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(email);
};

/**
 * agrega separador de miles a un número
 * @param {number | string} value
 * @param {string} dSeparator
 * @param {string} tSeparator
 */
export const agregarSeparadorMilesExt = function (value, dSeparator = '.', tSeparator = ',') {
    const regex = new RegExp(`\\d(?=(\\d{3})+(?:\\${dSeparator}|$))`, 'g');
    const replaceBy = `$&${tSeparator}`;
    return value.toString().replace(regex, replaceBy);
};

/**
 * agrega separador de miles a un número
 * @param {number | string} value
 * @todo opción para remover parte decimal.
 */
export const agregarSeparadorMiles = function (value) {
    return value.toString().replace(/\d(?=(\d{3})+(?:\.|$))/g, '$&.');
};

/**
 * Genera un string con el formato "HH:mm:ss" a partir de una cantidad de segundos.
 * @param {number} s - duración en segundos
 * @returns {string}
 */
export const convertMS = function (s) {
    let d, h, m;
    m = Math.floor(s / 60);
    s = Math.floor(s % 60);
    h = Math.floor(m / 60);
    m = m % 60;
    d = Math.floor(h / 24);
    h = h % 24;
    h += d * 24;

    if (h < 10) { h = "0" + h; }
    if (m < 10) { m = "0" + m; }
    if (s < 10) { s = "0" + s; }
    return h + ":" + m + ":" + s;
};

/**
 * @param {string} imageDataURL
 * @returns {string}
 * basado en https://stackoverflow.com/a/39803468/1761327
 */
export const getBinaryB64FromImageDataURL = function (imageDataURL) {
    return imageDataURL.split(',')[1];
};

/**
 * @param {string} imageBinaryB64
 * @returns {string}
 */
export const getDataURLFromImageBinaryB64 = function (imageBinaryB64) {
    return 'data:image/png;base64,' + imageBinaryB64; // TODO?: detectar mime type? aparentemente funciona sin problemas usando siempre png (el explorador detecta el formato real).
};

const commonIO = { shortenText, isNumeric, isValidEmail, agregarSeparadorMiles, agregarSeparadorMilesExt, convertMS, getBinaryB64FromImageDataURL, getDataURLFromImageBinaryB64 };

export default commonIO;
