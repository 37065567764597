import React from "react";
import { useTheme } from '@material-ui/core/styles';
import { useMediaQuery } from "usehooks-ts";
import Grid from '@material-ui/core/Grid';

import TarjetaEcampus from "./TarjetaEcampus";
import { isStructureUpdated } from "../lib/api";
import commonIO from "../util/common.io";
import utils from "../util/utils";
import { strings } from "../lib/i18n";
import { TagStyles } from "../lib/constants";

const mensajeNoHayCursos =
    <div key='mensaje_no_cursos' className='title_section s24' style={{ minHeight: '25rem', paddingTop: '7rem', width: '100%', textAlign: 'center' }}>
        No se encontraron cursos
    </div>;

/**
 * @param {Object} params
 * @param {api.Curso[]} params.cursos
 * @param {(curso: api.Curso) => void} params.handleShowVideo
 * @returns {JSX.Element}
 */
export default function PanelCursos({ cursos, handleShowVideo }) {

    const theme = useTheme();
    const smallScreen = useMediaQuery(theme.breakpoints.down('xs'));

    const courseList = [];
    if (cursos && cursos.length) {
        for (let i = 0; i < cursos.length; i++) {
            courseList.push(
                <Grid container item xs={12} sm={12} md={6} lg={4} xl={4} direction="column" key={cursos[i].id} className='coursehome tarjeta-grid-item' >
                    <Grid container alignContent="center" alignItems="center" className='tarjeta-ecampus-wrapper'>
                        <TarjetaEcampus
                            image={utils.getImagenCurso(cursos[i])}
                            text1={commonIO.shortenText(cursos[i].titulo, smallScreen ? 60 : 72)}
                            text2={cursos[i].nombre_instructor}
                            href={"/course/" + cursos[i].id + "/" + "Inicio"}
                            price={cursos[i].precio}
                            showTag={isStructureUpdated(cursos[i])}
                            tagOptions={TagStyles.TAG_ACTUALIZADO}
                            curso={cursos[i]}
                            hasVideo={!!cursos[i].video}
                            handleVideoPreview={() => {
                                handleShowVideo(cursos[i]);
                            }}
                            showBuyButtons={true}
                        />
                    </Grid>
                </Grid>
            );
        }
    } else if (cursos instanceof Array && cursos.length === 0) {
        courseList.push(mensajeNoHayCursos);
    }

    return (<>
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            {courseList}
        </div>
    </>);
}
